import React, { FC, PropsWithChildren, useContext, useState } from 'react';
import { CorText } from '@e-reteta/react-design-system';
import { T, useTranslate } from '@tolgee/react';

import { TextVariant } from '../../enums/design-system.enum';
import Menu from '../../icons/Menu';
import Close from '../../icons/Close';
import useWindowWidth from '../../hooks/use-window-width';
import { AuthContext } from '../../context/context';
import { LeftSideMenuProps } from './sidebar.interface';
import SideNav from './SideNav';

const LeftSideManu: FC<PropsWithChildren<LeftSideMenuProps>> = ({ children, items }) => {
  const { t } = useTranslate();
  const { user } = useContext(AuthContext);
  const speciality = user?.activeCompany?.speciality?.replaceAll('_', ' ');
  const [mobileMenu, setMobileMenu] = useState(false);
  const { isMobile } = useWindowWidth();

  const specialtyKeyName = `sidebar.${
    user?.activeCompany?.speciality ? user?.activeCompany?.speciality : 'snoSpeciality'
  }`;

  const handleMenuItemOnMobile = () => {
    if (mobileMenu && isMobile) {
      setMobileMenu(() => false);
    }

    return;
  };

  const sideMenu = () => {
    return (
      <SideNav
        firstName={user?.firstName}
        lastName={user?.lastName}
        subtitle={t(specialtyKeyName, speciality)}
        items={items}
        onMobileClick={handleMenuItemOnMobile}
      />
    );
  };

  return (
    <main className="flex absolute inset-0 lg:relative lg:h-screen lg:max-h-screen box-border bg-background-secondary p-4 sm:p-0 lg:p-4">
      <div className="hidden sm:flex sm:w-[112px] sm:max-w-[112px] lg:w-[240px] lg:max-w-[240px]">
        {sideMenu()}
      </div>
      <div className="flex flex-col flex-1 overflow-auto overflow-y-auto">
        <section className="flex sm:hidden items-center justify-between pb-4 sm:pb-6">
          <div className="flex flex-col">
            <div className="flex flex-row gap-2 truncate">
              <CorText variant={TextVariant.HEADING_3_BOLD}>
                <span>{user?.firstName}</span>
              </CorText>
              <CorText variant={TextVariant.HEADING_3_BOLD}>
                <span>{user?.lastName}</span>
              </CorText>
            </div>
            <div className="truncate flex gap-3">
              <CorText variant={TextVariant.LABEL_2_MEDIUM}>
                <span title={speciality} className="capitalize text-content-muted">
                  <T keyName={specialtyKeyName}>{speciality}</T>
                </span>
              </CorText>
            </div>
          </div>
          <button
            onClick={() => setMobileMenu((prev) => !prev)}
            className="bg-transparent border-none outline-none cursor-pointer p-0 bg-red"
          >
            {!mobileMenu ? <Menu width={40} height={40} /> : <Close width={40} height={40} />}
          </button>
        </section>
        {mobileMenu && isMobile ? (
          <div className="sm:hidden h-full w-full">{sideMenu()}</div>
        ) : (
          children
        )}
      </div>
    </main>
  );
};

export default LeftSideManu;
