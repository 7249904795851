/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  CorBox,
  CorButton,
  CorDropdown,
  CorIcon,
  CorInput,
  CorText,
} from '@e-reteta/react-design-system';
import React, {
  ChangeEvent,
  FC,
  memo,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';
import { isAxiosError } from 'axios';
import { T, useTranslate } from '@tolgee/react';
import hash from 'object-hash';

import { PatientProfileProps, PrescriptionsPaginationData } from './interface';
import useWindowWidth from '../../hooks/use-window-width';
import { useGetPatient } from '../../hooks/use-get-patient';
import { OpenedTab, PrescriptionListStatus } from './enums';
import {
  DoctorPrescriptionsServices,
  QueryParams,
} from '../../services/doctorsPrescription.services';
import { InsuredStatusEnums, PatientCardType } from '../PatientCard/enums';
import { Gender } from '../../enums/Gender';
import { InputIconDisplay, TextVariant } from '../../enums/design-system.enum';
import { IconColor, IconName, IconSize } from '../../enums/design-system-icons.enum';
import PatientProfileCard from './PatientProfileCard';
import Tabs from '../Tabs';
import FilterChip from '../FilterChip';
import {
  getPrescriptionStatusTranslation,
  getPrescriptionStatusTranslationKey,
} from '../../lib/getPrescriptionStatusTranslation';
import {
  DropDownReceiptStatus,
  DropDownReceiptStatuses,
  ReceiptStatus,
} from '../../enums/ReceiptStatus';
import CorDropdownOption from '../CorDropdownOption';
import PrescriptionsTable from './PrescriptionsTable';
import PrescriptionsTableMobile from './PrescriptionTableMobile';
import NotFoundBlock from '../NotFoundBlock';
import { NotFoundType } from '../NotFoundBlock/notFoundBlock.enum';
import { Prescription } from '../../types/Prescription';
import { Disease } from '../../types/Disease';
import Pagination from '../Pagination';
import { DEFAULT_PAGINATION_DATA } from './Pagination/constants';
import { DiagnosticServices } from '../../services/diagnostics.services';
import { PatientServices } from '../../services/patient.services';
import { AuthContext } from '../../context/context';
import { handleError } from '../../utils/handleRequestErrors';
import { renderResponsiveClasses } from '../../utils/render-responsive-classes.utils';
import { SpecialityService } from '../../services/speciality.service';
import { Patient } from '../../types/Patient';
import { LoaderContext } from '../../context/loader/context';
import useDebounce from '../../hooks/useDebounce';
import Check from '../../icons/Check';

const PatientProfile: FC<PatientProfileProps> = ({ onClose }) => {
  const { t } = useTranslate();
  const { isTablet, isMobile, isSmallTablet } = useWindowWidth();
  const navigate = useNavigate();
  const { idnp } = useParams();
  const { role, user } = useContext(AuthContext);
  const { setLoading, loading } = useContext(LoaderContext);

  const [paginationData, setPaginationData] =
    useState<PrescriptionsPaginationData>(DEFAULT_PAGINATION_DATA);
  const [count, setCount] = useState<number>(0);
  const ref = useRef<HTMLDivElement>(null);
  const [filterByStatus, setFilterByStatus] = useState<string | null>(null);
  const [filterByCimId, setFilterByCimId] = useState<string | null>(null);
  const [prescriptions, setPrescriptions] = useState<Prescription[]>([]);
  const [allDiagnostics, setAllDiagnostics] = useState<Disease[]>([]);
  const [isVisible, setVisible] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [patientData, _, isPatientDataLoading] = useGetPatient({
    idnp: role === 'doctor' ? idnp : undefined,
  });
  const [openedTab, setOpenedTab] = useState<OpenedTab>(OpenedTab.ALL_RECEITS);
  const [patientCardData, setPatientCardData] = useState<Patient | null>(null);
  const [allowedDiseases, setAllowedDiseases] = useState<string[]>([]);
  const [prescriptionListStatus, setPrescriptionListStatus] = useState<PrescriptionListStatus>(
    PrescriptionListStatus.NO_DATA,
  );
  const [search, setSearch] = useState<string | null>();
  const searchQuery = useDebounce(search, 800);

  const getCodesForFilters = useCallback(async () => {
    const query: any = {
      limit: 20,
      search: searchQuery,
    };

    if (role === 'doctor') {
      try {
        const response = await DiagnosticServices.getDiagnostics(role, query);
        setAllDiagnostics(response?.data?.items ?? []);
      } catch (error) {
        isAxiosError(error) && handleError(error);
      }
    }
  }, [role, searchQuery]);

  useEffect(() => {
    getCodesForFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  const getRePrescribedDiseases = async () => {
    if (user?.activeCompany?.speciality) {
      const response = await SpecialityService.getByName(user?.activeCompany?.speciality!);
      const rePrescribedDiseases: string[] =
        response?.data?.rePrescribedDisease?.map((item: any) => item.code) || [];
      const prescribedDisease: string[] =
        response?.data?.prescribedDisease?.map((item: any) => item.code) || [];
      setAllowedDiseases([...rePrescribedDiseases, ...prescribedDisease]);
    }
  };

  useEffect(() => {
    if (user) {
      getRePrescribedDiseases();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const resetFilters = useCallback(() => {
    setFilterByCimId(null);
    setFilterByStatus(null);
  }, []);

  const newPrescription = () => {
    navigate(`/patient/${idnp}/prescription/new`);
  };

  const getPrescriptionsCallback = useCallback(async () => {
    setLoading(true);
    try {
      const query: QueryParams = {
        patientId: idnp,
        limit: paginationData.itemsPerPage,
        skip: paginationData.page * paginationData.itemsPerPage,
        orderBy: {
          createdAt: 'DESC',
        },
      };

      if (filterByStatus) {
        query.statuses = [filterByStatus];
      } else {
        query.statuses = [
          DropDownReceiptStatus.PARTIAL_RELEASED,
          DropDownReceiptStatus.RELEASED,
          DropDownReceiptStatus.SIGNED,
        ];
      }

      if (filterByCimId) {
        query.diagnosticCodes = [filterByCimId];
      }

      if (role === 'pharmacy') {
        const { data } = await PatientServices.getPatient(role, query);
        setPrescriptions(data?.prescriptions ?? []);
        setCount(data?.count || 0);
        setPrescriptionListStatus(
          !!data?.count
            ? PrescriptionListStatus.HAS_PRESCRIPTIONS
            : PrescriptionListStatus.NO_PRESCRIPTIONS,
        );
        setPatientCardData({
          ...data,
          birthday: data.birthday ? new Date(data.birthday) : null,
          gender: data.gender === 'm' ? Gender.MALE : Gender.FEMALE,
          idno: data.id || null,
        });
      }
      if (role === 'doctor' && patientData) {
        const { data } =
          openedTab === OpenedTab.ALL_RECEITS
            ? await DoctorPrescriptionsServices.getPrescriptions({
                ...query,
                doctorIDNP: user?.idnp,
                isOnlyPrescribedByDoctor: true,
                doctorSpeciality: user?.activeCompany?.speciality,
              })
            : await DoctorPrescriptionsServices.getMyPrescriptions({
                ...query,
                statuses:
                  query?.statuses?.length > 1
                    ? [...query.statuses, DropDownReceiptStatus.PRESCRIBED]
                    : query.statuses,
              });
        setPrescriptions(data?.items ?? []);
        setCount(data?.count || 0);
        setPrescriptionListStatus(
          !!data?.count
            ? PrescriptionListStatus.HAS_PRESCRIPTIONS
            : PrescriptionListStatus.NO_PRESCRIPTIONS,
        );
      }
      setLoading(false);
    } catch (error) {
      isAxiosError(error) && handleError(error);

      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    paginationData.page,
    paginationData.itemsPerPage,
    patientData,
    openedTab,
    filterByStatus,
    filterByCimId,
  ]);

  useEffect(() => {
    setPaginationData((data) => ({
      ...data,
      totalItems: count,
      totalPages: Math.ceil(count / paginationData.itemsPerPage),
    }));
  }, [count, paginationData.itemsPerPage]);

  // const checkIfHasPrescriptions = useCallback(async () => {
  //   if (patientData && typeof patientData === 'object' && 'id' in patientData) {
  //     if (role === 'pharmacy' && patientData) {
  //       const query: QueryParams = {
  //         patientId: String(patientData?.id),
  //       };
  //       const result = await PatientServices.getPatient(role, query);
  //       setPrescriptionListStatus(
  //         !!result?.data?.count
  //           ? PrescriptionListStatus.HAS_PRESCRIPTIONS
  //           : PrescriptionListStatus.NO_PRESCRIPTIONS,
  //       );
  //     }
  //     if (role === 'doctor') {
  //       const query: QueryParams = {
  //         patientId: String(patientData?.id),
  //         limit: 0,
  //       };
  //       const result = await DoctorPrescriptionsServices.getPrescriptions(query);
  //       setPrescriptionListStatus(
  //         !!result?.data?.count
  //           ? PrescriptionListStatus.HAS_PRESCRIPTIONS
  //           : PrescriptionListStatus.NO_PRESCRIPTIONS,
  //       );
  //     }
  //   }
  // }, [patientData, role]);

  const handleTabChange = useCallback(
    (tab: string) => {
      setOpenedTab(tab as OpenedTab);
      setPaginationData((data) => ({ ...data, page: 0 }));
      resetFilters();
    },
    [setPaginationData, resetFilters],
  );

  useEffect(() => {
    if (!isPatientDataLoading && patientData) {
      const updatedPatientData = (() => {
        if (typeof patientData === 'object') {
          const patient: Patient = patientData as Patient;
          return {
            ...patient,
            type: PatientCardType.DEFAULT,
            birthday: patient.birthday ? new Date(patient.birthday) : null,
            gender: patient.gender === 'm' ? Gender.MALE : Gender.FEMALE,
            status: patient.isInsured ? InsuredStatusEnums.INSURED : InsuredStatusEnums.UNINSURED,
            idno: patient.id || null,
          };
        }
        return null;
      })();

      setPatientCardData(updatedPatientData);
    }
  }, [isPatientDataLoading, patientData]);

  useEffect(() => {
    getPrescriptionsCallback();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filterByCimId,
    filterByStatus,
    paginationData.page,
    paginationData.itemsPerPage,
    openedTab,
    patientData,
  ]);

  useEffect(() => {
    setPaginationData((data) => ({ ...data, page: 0 }));
  }, [filterByCimId, filterByStatus]);

  const renderResponsiveTable = (isMobile: boolean) => {
    if (role) {
      if (isMobile) {
        return (
          <div className="pb-4">
            <PrescriptionsTableMobile role={role} prescriptions={prescriptions} medicData={user} />
          </div>
        );
      } else {
        return (
          <div className="table pb-6">
            <PrescriptionsTable role={role} prescriptions={prescriptions} medicData={user} />
          </div>
        );
      }
    }
  };

  const renderPagination = () => {
    if (prescriptions && prescriptions.length > 0) {
      return <Pagination paginationData={paginationData} setPaginationData={setPaginationData} />;
    }
  };

  const renderPharmacyContainers = () => {
    if (role !== 'pharmacy') {
      return null;
    }
    if (prescriptionListStatus === PrescriptionListStatus.HAS_PRESCRIPTIONS) {
      return (
        <>
          <div className="flex flex-col items-start gap-4 mt-6 mb-2 sm:mb-3">
            <CorText
              variant={
                isMobile || isTablet ? TextVariant.HEADING_4_BOLD : TextVariant.HEADING_3_BOLD
              }
            >
              <p>
                <T keyName="patientProfile.prescription">Retete</T>
              </p>
            </CorText>
          </div>
          {renderResponsiveTable(isTablet || isSmallTablet || isMobile)}
          {renderPagination()}
        </>
      );
    }
  };

  useEffect(() => {
    if (isPatientDataLoading) {
      setLoading(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPatientDataLoading]);

  const onInputChange = (value: string) => {
    setSearch(value);
  };

  const setDiagnostic = (data: Disease) => {
    setFilterByCimId(data.code);
    setSearch('');
    setVisible(false);
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setVisible(false);
    }
  };

  return (
    <CorBox boxStyle="none" boxColor="none" className="p-0 sm:p-4 sm:pl-0 lg:p-0">
      <header className="flex flex-row items-center justify-between">
        <div className="flex flex-row items-center justify-start">
          <button
            onClick={onClose}
            className="flex items-center justify-center border-none outline-none cursor-pointer bg-transparent p-0 mr-2"
          >
            <CorIcon name="arrow-left" size={IconSize.LG} />
          </button>
          <CorText
            variant={renderResponsiveClasses(
              isMobile,
              isTablet,
              TextVariant.HEADING_4_BOLD,
              TextVariant.HEADING_3_BOLD,
              TextVariant.HEADING_2_BOLD,
            )}
          >
            <span className="font-semibold">
              <T keyName="patientProfile.pacientDetails">Detalii Pacient</T>
            </span>
          </CorText>
        </div>
        {role === 'doctor' ? (
          <CorButton onClick={newPrescription}>
            <button className="flex flex-row items-center gap-2 justify-center">
              <CorIcon name="plus" size={IconSize.SM} color={IconColor.WHITE} />
              <T keyName="patientProfile.newPrescription">Reteta Noua</T>
            </button>
          </CorButton>
        ) : null}
      </header>
      <PatientProfileCard
        gender={patientCardData?.gender}
        idno={patientCardData?.idno}
        birthday={patientCardData?.birthday}
        firstName={patientCardData?.firstName}
        lastName={patientCardData?.lastName}
        isInsured={patientCardData?.isInsured}
      />
      {role === 'doctor' && prescriptionListStatus === PrescriptionListStatus.HAS_PRESCRIPTIONS ? (
        <>
          <nav className="flex flex-col 2md:flex-row items-start 2md:items-center justify-between mt-8 mb-4 gap-4">
            <Tabs
              values={[OpenedTab.ALL_RECEITS, OpenedTab.MY_RECEIPTS]}
              onChange={handleTabChange}
            />
            <div className="flex flex-col-reverse sm:flex-row 2md:items-center justify-end gap-4">
              {filterByCimId || filterByStatus ? (
                <div className="flex flex-row items-center justify-start 2md:justify-end gap-2">
                  {filterByCimId && (
                    <FilterChip
                      text={filterByCimId}
                      onClose={() => {
                        setFilterByCimId(null);
                      }}
                    />
                  )}
                  {filterByStatus && (
                    <FilterChip
                      text={t(
                        getPrescriptionStatusTranslationKey(filterByStatus as ReceiptStatus),
                        getPrescriptionStatusTranslation(filterByStatus as ReceiptStatus),
                      )}
                      onClose={() => setFilterByStatus(null)}
                    ></FilterChip>
                  )}
                </div>
              ) : null}
              <div className="flex flex-row items-center justify-end gap-2">
                <div className="relative" ref={ref}>
                  <CorInput iconName={IconName.SEARCH} iconDisplay={InputIconDisplay.LEFT}>
                    <input
                      autoComplete="off"
                      type="text"
                      name="diagnostic"
                      placeholder={t('patientProfile.diagnosticPlaceholder', 'Diagnostic')}
                      value={search || ''}
                      onClick={() => setVisible(true)}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        onInputChange(event.target.value);
                      }}
                    />
                  </CorInput>
                  {isVisible && allDiagnostics && allDiagnostics?.length ? (
                    <div className="absolute w-max max-w-xl max-h-[296px] overflow-y-scroll z-50 mt-1 py-2 rounded-[8px] border border-solid border-border-default bg-decorative-neutral-1 right-0">
                      {allDiagnostics.map((item: Disease) => {
                        return (
                          <div
                            className="relative flex gap-2 justify-between w-full px-4 py-2 cursor-pointer group hover:bg-surface-hover"
                            key={hash(item)}
                            onClick={() => {
                              setDiagnostic(item);
                            }}
                          >
                            <CorText variant={TextVariant.LABEL_2_REGULAR}>
                              <p className="text-content-muted group-hover:text-content-default">
                                {item.code} - {item.name}
                              </p>
                            </CorText>
                            {filterByCimId === item.code ? <Check /> : null}
                          </div>
                        );
                      })}
                    </div>
                  ) : null}
                </div>
                <CorDropdown
                  className={classNames({ disabled: loading })}
                  searchEnabled={false}
                  isButton={true}
                  placeholder={t('patientProfile.statusPlaceholder', 'Statut')}
                  disabled={loading}
                  aria-disabled={loading}
                  callback={(event: CustomEvent) => setFilterByStatus(event.detail.value)}
                  isRight={true}
                >
                  {DropDownReceiptStatuses.map((value) => (
                    <CorDropdownOption
                      key={value}
                      value={value}
                      label={t(
                        getPrescriptionStatusTranslationKey(value),
                        getPrescriptionStatusTranslation(value as DropDownReceiptStatus),
                      )}
                      selected={filterByStatus === value}
                    />
                  ))}
                </CorDropdown>
              </div>
            </div>
          </nav>
          {prescriptions && prescriptions.length > 0 ? (
            <>
              {isTablet || isSmallTablet || isMobile ? (
                <div className="pb-4">
                  <PrescriptionsTableMobile
                    role={role}
                    prescriptions={prescriptions}
                    medicData={user}
                    allowedCodesForCloning={allowedDiseases}
                  />
                </div>
              ) : (
                <div className="table pb-6">
                  <PrescriptionsTable
                    role={role}
                    prescriptions={prescriptions}
                    medicData={user}
                    allowedCodesForCloning={allowedDiseases}
                  />
                </div>
              )}
            </>
          ) : null}
          {prescriptions && prescriptions.length > 0 ? (
            <Pagination paginationData={paginationData} setPaginationData={setPaginationData} />
          ) : null}
        </>
      ) : null}
      {role === 'doctor' && !prescriptions?.length && (
        <NotFoundBlock
          text={t('profilePatient.notFoundPrescription', 'Nu este nici o reteta')}
          type={NotFoundType.RECIPE}
        />
      )}

      {renderPharmacyContainers()}

      {role == 'pharmacy' && prescriptionListStatus === PrescriptionListStatus.NO_PRESCRIPTIONS && (
        <NotFoundBlock
          text={t('profilePatient.notFoundPrescription', 'Nu este nici o reteta')}
          type={NotFoundType.RECIPE}
        />
      )}
    </CorBox>
  );
};

export default memo(PatientProfile);
