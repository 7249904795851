import { array, number, object, string } from 'yup';

export const drugsValidationSchema = object().shape({
  internationalName: string().required('Denumirea comuna internationala este camp obligator'),
  dose: string().required('Doza este camp obligator'),
  form: object().shape({
    name: string().required('Forma farmaceutică este camp obligator'),
  }),
  cimCodes: array()
    .min(1, 'Diagnostice este camp obligator')
    .required('Diagnostice este camp obligator'),
  group: object().shape({
    name: string().required('Grupa farmacoterapeutică este camp obligator'),
  }),
  atcCod: string().required('Cod ATC este camp obligator'),
  treatmentDuration: string().required('Durata tratamentului este camp obligator'),
  intersection: array().optional(),
  expiration: string().required('Valabilitatea retetei este camp obligator'),
  commercialCod: string()
    .matches(/^\d{9,10}$/, 'CodDC este de format gresit, trebuie sa contina 9 sau 10 cifre')
    .required('CodDC este camp obligator'),
  commercialName: string().required('Denumirea comerciala este camp obligator'),
  division: string().required('Divizarea este camp obligator'),
  compensatedStatusAdult: string().required(
    'Statut de compensare pentru adulti este camp obligator',
  ),
  compensatedStatusChildren: string().required(
    'Statut de compensare pentru copii este camp obligator',
  ),
});

export const devicesValidationSchema = object().shape({
  producer: object().shape({
    name: string()
      .required('Producatorul este camp obligator')
      .matches(/^(.*?),\s*([A-Za-z\u0080-\u024F\s]+)$/i, 'Dupa virgula scrieti tara producatoare'),
  }),
  commercialCod: string()
    .matches(/^1\d{6}$/, 'DC este de format gresit')
    .required('Cod DC este camp obligator'),
  cimCodes: array()
    .required('Diagnostice este camp obligator')
    .min(1, 'Diagnostice este camp obligator'),
  dividing: string().required('Divizare producator este camp obligator'),
  measurementUnit: string().required('Unitatea de masura este camp obligator'),
});

export const diagnosticsValidationSchema = object().shape({
  name: string().required('Denumirea este camp obligator'),
  code: string()
    .required('Codul este camp obligator')
    .matches(/^([A-z]\d+)$/, 'Formatul pentru cod este o litera si cifre'),
  group: object().shape({
    name: string().required('Clasa este camp obligator'),
    group: object().shape({ name: string().required('Subclasa este camp obligator') }),
  }),
});

export const specialitiesValidationSchema = object().shape({
  name: string().required('Specialitatea este camp obligator'),
  prescribedDiseasesCodes: array()
    .required('Diagnostice pentru prescriere este camp obligator')
    .min(1, 'Diagnostice pentru prescriere este camp obligator'),
});

export const clinicsValidationSchema = object().shape({
  codIms: string().required('Cod Ims este camp obligator'),
  idno: number().required('Idno este camp obligator'),
  imsp: string().required('Imsp este camp obligator'),
  codAt: string().required('Cod At este camp obligator'),
});

export const pharmaciesValidationSchema = object().shape({
  code: string().required('Codul farmaciei este camp obligator'),
  idno: number().required('Idno este camp obligator'),
  atAscribed: string().required('AT arondată este camp obligator'),
  name: string().required('Denumirea PSF este camp obligator'),
  district: string().required('Raionul este camp obligator'),
  address: string().required('Adresa amplasării este camp obligator'),
  series: string().required('Seria, Nr. licenței este camp obligator'),
  licenseValidity: string().required('Valabilitatea licentei este camp obligator'),
  type: string().required('Tipul farmaciei este camp obligator'),
});

export const integrationsValidationSchema = object().shape({
  idno: string().required('IDNO este camp obligator'),
  pullProductsURL: string().required(
    'Link de extragere medicamente/dispozitive este camp obligator',
  ),
  pushDispensesURL: string().required('Link de trimitere eliberări este camp obligator'),
});
