import React, { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { isAxiosError } from 'axios';
import { useTranslate } from '@tolgee/react';

import { AlertStatus } from '../../components/Alert/enum';
import { Alert, ModalWindow, Payment } from '../../components';
import { PharmacyService, Recipe } from '../../services/pharmacy.services';
import useQuery from '../../hooks/use-query';
import { handleError } from '../../utils/handleRequestErrors';
import eventBus from '../../events/event-bus';
import { SidebarItems } from '../../enums/sidebar-items.enum';
import { DispensesStatusEnum } from '../../enums/dispenses-status.enum';
import ConfirmModal from '../../components/ConfirmModal';
import { TargetUrlEnum } from '../../enums/target-url.enum';
import classNames from 'classnames';

const useLongPolling = (dispenseId: string, cancelFlag = false) => {
  const [data, setData] = useState<Recipe>();
  const [error, setError] = useState<any>(null);

  useEffect(() => {
    let intervalId: any;

    const fetchData = async () => {
      try {
        const { data } = await PharmacyService.getDispenseById(dispenseId);
        setData(data);
        if (data?.status !== 'pending') {
          clearInterval(intervalId);
        }
      } catch (error) {
        setError(error);
        clearInterval(intervalId);
      }
    };

    if (!cancelFlag) {
      fetchData();
      intervalId = setInterval(fetchData, 5000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [dispenseId, cancelFlag]);

  return [data, error];
};

const CloseDispenses: FC = () => {
  const query = useQuery();
  const { t } = useTranslate();
  const { idnp = '', dispenseId = '' } = useParams();

  const [showModalAlert, setShowModalAlert] = useState<boolean>(false);
  const [showErrorAlert, setShowErrorAlert] = useState<boolean>(false);
  const [showModalConfirm, setShowModalConfirm] = useState<boolean>(false);
  const [voucher, setHouseVoucher] = useState<string | undefined>();
  const [cash, setCashRegister] = useState<string | undefined>();
  const [cancelPolling, setCancelPolling] = useState(false);
  const [dispense] = useLongPolling(dispenseId, cancelPolling);
  const [isWaitingForIntegration, setIsWaitingForIntegration] = useState<boolean>(false);
  const [hasIntegrationErrors, setHasIntegrationErrors] = useState<boolean>(false);
  const [isCanceled, setIsCanceled] = useState<boolean>(false);
  const [countDown, setCountDown] = useState<number>(0);

  useEffect(() => {
    if (!dispense) {
      return;
    }
    setIsWaitingForIntegration(dispense?.status === DispensesStatusEnum.PENDING);
    if (dispense?.status === DispensesStatusEnum.PENDING) {
      setIsWaitingForIntegration(dispense?.status === DispensesStatusEnum.PENDING);
      setCountDown(30);
      const intervalId = setInterval(() => {
        setCountDown((prevCountDown) => {
          if (prevCountDown === 1) {
            clearInterval(intervalId);
          }
          return prevCountDown - 1;
        });
      }, 1000);
    }
    setHasIntegrationErrors(dispense?.status === DispensesStatusEnum.ERROR);
    setIsCanceled(dispense?.status === DispensesStatusEnum.CANCELED);
  }, [dispense?.status]);

  const navigate = useNavigate();

  const openSignModal = (cashRegister: string, houseVoucher: string) => {
    setHouseVoucher(houseVoucher);
    setCashRegister(cashRegister);
    setShowModalConfirm(true);
  };

  const closeCheckSign = () => {
    setShowModalConfirm(false);
  };

  const doSign = async () => {
    try {
      await PharmacyService.updateDispenses({
        id: dispenseId!,
        cashRegister: cash,
        houseVoucher: voucher,
      });
      const resp = await PharmacyService.signDispenses({
        items: [dispenseId],
        targetUrl: TargetUrlEnum.CLOSE_URL,
      });
      if (resp?.data?.redirectUrl) {
        window?.location?.replace(resp?.data?.redirectUrl);
      }
    } catch (error) {
      isAxiosError(error) && handleError(error);
    }
  };

  const signLater = async (cashRegister?: string, houseVoucher?: string) => {
    try {
      await PharmacyService.closeDispense({
        id: dispenseId!,
        cashRegister: cashRegister || cash,
        houseVoucher: houseVoucher || voucher,
      });
      eventBus.dispatch('updateCount', {
        detail: SidebarItems.UNSIGNED_DISPENSES,
      });
      navigate(`/patient/${idnp}`);
    } catch (error) {
      isAxiosError(error) && handleError(error);
    }
  };
  const changeDispenseStatus = async (status: DispensesStatusEnum) => {
    try {
      await PharmacyService.updateDispenses({
        id: dispenseId!,
        status: status,
      });
    } catch (error) {
      isAxiosError(error) && handleError(error);
    }
  };

  useEffect(() => {
    if (query && query.get('sign-status') === 'Success') {
      setShowModalAlert(true);
    }
    if (query && query.get('sign-status') === 'Error') {
      setShowErrorAlert(true);
    }
  }, [query]);

  const goBack = () => {
    navigate(`/patient/${idnp}/dispenses/${dispense?.prescription.id}`);
  };

  return (
    <div className="flex flex-col overflow-auto hide-scrollbar sm:px-4 relative h-full">
      <Payment
        cashRegisterVal={dispense?.cashRegister}
        houseVoucherVal={dispense?.houseVoucher}
        onGoBack={goBack}
        onSuccessSign={async (cashRegister: string, houseVoucher: string) =>
          openSignModal(cashRegister, houseVoucher)
        }
        signLater={(cashRegister: string, houseVoucher: string) =>
          signLater(cashRegister, houseVoucher)
        }
        showErrorAlert={showErrorAlert}
      />
      {showModalAlert ? (
        <ModalWindow>
          <div className="h-fit w-[520px] p-4">
            <Alert
              label={t('closeDispenses.signedSuccess', 'Semnarea a fost efectuata cu succes')}
              text={t('closeDispenses.naviagateToNext', 'Navigheaza in continuare la')}
              firstButton={t('closeDispenses.patientPageText', 'Pagina pacientului')}
              secondButton={t('closeDispenses.sarchPageText', 'Pagina de cautare')}
              status={AlertStatus.SUCCESS}
              withButton
              firstButtonClick={() => navigate(`/patient/${idnp}`)}
              secondButtonClick={() => navigate('/pharmacy/search')}
            />
          </div>
        </ModalWindow>
      ) : null}
      {isWaitingForIntegration && (
        <ModalWindow>
          <div
            className={classNames('h-fit w-[520px] p-4', {
              'animate-pulse pointer-events-none cursor-not-allowed': countDown > 0,
            })}
          >
            <Alert
              label={t('closeDispenses.waitingAnswerLabel', 'Se asteapta raspuns')}
              text={t(
                'closeDispenses.waitingAnswerText',
                'Se astepata raspuns de la aparatul de casa. Daca nu doriti sa asteptati introduceti manual datele despre bon',
              )}
              secondButton={
                countDown > 0
                  ? t('closeDispenses.waitingAnswer', 'Se astapta raspuns', { countDown })
                  : t('closeDispenses.closeManualy', 'Continua manual')
              }
              status={AlertStatus.WARNING}
              withButton
              secondButtonClick={() => {
                setIsWaitingForIntegration(false);
                setCancelPolling(true);
                changeDispenseStatus(DispensesStatusEnum.DRAFT);
              }}
            />
          </div>
        </ModalWindow>
      )}
      {hasIntegrationErrors && (
        <ModalWindow>
          <div className="h-fit w-[520px] p-4">
            <Alert
              label={t('closeDispenses.errorMessageLabel', 'A avut loc o eraore')}
              text={t(
                'closeDispenses.alertText',
                'Nupoate fi completata informatia automatizat, doriti sa continuati mai departe in mod manual',
              )}
              firstButton={t('closeDispenses.cancelDispensing', 'Anuleaza eliberarea')}
              status={AlertStatus.DANGER}
              withButton
              firstButtonClick={async () => {
                setHasIntegrationErrors(false);
                await changeDispenseStatus(DispensesStatusEnum.DRAFT);
                navigate(`/patient/${idnp}`);
              }}
            />
          </div>
        </ModalWindow>
      )}
      {isCanceled && (
        <ModalWindow>
          <div className="h-fit w-[520px] p-4">
            <Alert
              label={t('closeDispenses.canceledMessageLabel', 'Eliberarae a fost anulata')}
              text={t('closeDispenses.cacneledText', 'Eliberarea a fost anulata')}
              firstButton={t('closeDispenses.confirm', 'Am ineles')}
              status={AlertStatus.WARNING}
              withButton
              firstButtonClick={async () => {
                navigate(`/patient/${idnp}/dispenses/${dispense?.prescription.id}`);
              }}
            />
          </div>
        </ModalWindow>
      )}
      {showModalConfirm && (
        <ConfirmModal
          title={t('closeDispenses.confirmModalTitle', 'Atentie')}
          description={t('closeDispenses.confirmSigning', 'Ești sigur că semnezi rețeta?')}
          onClose={closeCheckSign}
          onAccept={() => doSign()}
        />
      )}
    </div>
  );
};

export default CloseDispenses;
