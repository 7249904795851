import { useEffect, useState } from 'react';

import { useApiFacade } from './use-api-facade';
import { Patient } from '../types/Patient';

export interface UseGetProductsQuery {
  idnp?: string;
  ignoreRegionCheck?: boolean;
}

export const useGetPatient = (params: UseGetProductsQuery) => {
  const facade = useApiFacade();
  // TODO: implement patient interface
  const [data, setData] = useState<Patient | null>();
  const [error, setError] = useState<Error | null>();
  const [isLoading, setIsLoading] = useState(false);
  const { idnp, ignoreRegionCheck } = params;

  useEffect(() => {
    if (!idnp) {
      return;
    }
    setIsLoading(true);
    facade.client
      .get(`/v1/doctor/patients/${idnp}`, { params: { ignoreRegionCheck } })
      .then((response) => {
        setData(response.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setError(err);
        setData(null);
        setIsLoading(false);
      });
  }, [idnp, ignoreRegionCheck, facade]);

  return [data, error, isLoading];
};
