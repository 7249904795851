import { FC } from 'react';
import Barcode from 'react-jsbarcode';

interface Props {
  medicalInstitution: string;
  prescriptionId: string;
}

const PrescriptionDetails: FC<Props> = ({ medicalInstitution, prescriptionId }) => {
  return (
    <div className="flex justify-between items-center pt-2 pb-6">
      <div className="flex flex-col gap-1">
        <span className="text-content-default text-base font-bold">Prescris:</span>
        <div className="flex-flex-col">
          <p className="text-content-muted text-sm m-0">Denumire Instituție Medicală</p>
          <p className="text-content-default text-sm font-medium m-0">{medicalInstitution}</p>
        </div>
      </div>
      <div className="h-[60px]">
        <Barcode
          value={prescriptionId}
          options={{ format: 'code128', width: 0.75, height: 40, fontSize: 12 }}
        />
      </div>
    </div>
  );
};

export default PrescriptionDetails;
