import { FC, SVGProps } from 'react';

const User: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        opacity="0.3"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99935 9.16667C8.1584 9.16667 6.66602 7.67428 6.66602 5.83333C6.66602 3.99238 8.1584 2.5 9.99935 2.5C11.8403 2.5 13.3327 3.99238 13.3327 5.83333C13.3327 7.67428 11.8403 9.16667 9.99935 9.16667Z"
        fill="#343841"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.50054 16.8326C2.82355 12.8553 6.05159 10.8333 9.98612 10.8333C13.976 10.8333 17.2541 12.7442 17.4983 16.8333C17.508 16.9961 17.4983 17.4999 16.8722 17.4999C13.7843 17.4999 9.1956 17.4999 3.10625 17.4999C2.89726 17.4999 2.48295 17.0492 2.50054 16.8326Z"
        fill="#343841"
      />
    </svg>
  );
};

export default User;
