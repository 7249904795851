import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslate } from '@tolgee/react';
import { CorText } from '@e-reteta/react-design-system';

import { TextVariant } from '../../enums/design-system.enum';
import { TabsProps } from './interface';
import { OpenedTab } from '../PatientProfile/enums';
import { LoaderContext } from '../../context/loader/context';

const Tabs: FC<TabsProps> = (props) => {
  const { t } = useTranslate();
  const { values, onChange } = props;
  const { selected = values[0] } = props;
  const [toggled, setToggled] = useState<string>(selected);
  const { loading } = useContext(LoaderContext);

  const translationKey = (value: string) => {
    return value === OpenedTab.ALL_RECEITS ? 'tabsEnum.all' : 'tabsEnum.my';
  };

  const handleToggle = useCallback(
    (active: string) => {
      setToggled(active);
      if (onChange) {
        onChange(active);
      }
    },
    [toggled, onChange],
  );

  useEffect(() => {
    setToggled(selected);
  }, [selected]);
  return (
    <div
      className={`flex flex-row items-start h-fit w-full sm:w-auto ${
        loading ? 'pointer-events-none' : ''
      }`}
    >
      {values.map((value) => {
        return (
          <div
            className={`flex flex-col flex-1 xs:flex-none items-start h-full relative ${
              toggled ? 'cursor-pointer' : 'cursor-default'
            }`}
            onClick={() => handleToggle(value)}
            key={`tab_${value}`}
          >
            <CorText variant={TextVariant.LABEL_1_MEDIUM}>
              <span
                className={`flex flex-row items-center py-1 px-2 ${
                  toggled === value ? 'text-content-default' : 'text-content-disabled'
                }`}
              >
                {t(translationKey(value), value)}
              </span>
            </CorText>
            <div
              className={`w-full absolute bottom-0 ${
                toggled === value ? 'h-0.5 bg-border-success-default' : 'h-px bg-border-default'
              }`}
            />
          </div>
        );
      })}
    </div>
  );
};

export default Tabs;
