import React, { FC, useEffect, useState } from 'react';
import { isAxiosError } from 'axios';
import { CorInput } from '@e-reteta/react-design-system';

import { ManagementToolSearchProps } from './interface';
import { InputIconDisplay } from '../../../enums/design-system.enum';
import { IconColor, IconName } from '../../../enums/design-system-icons.enum';
import { handleError } from '../../../utils/handleRequestErrors';
import { useParams } from 'react-router-dom';

const ManagementToolSearch: FC<ManagementToolSearchProps> = ({
  placeholderValue,
  query,
  setQuery,
}) => {
  const { type } = useParams();
  const [, setDebouncedValue] = useState(query);

  useEffect(() => {
    let delayInputTimeout: NodeJS.Timeout;

    if (query !== '') {
      delayInputTimeout = setTimeout(async () => {
        try {
          setDebouncedValue(query);
        } catch (error) {
          isAxiosError(error) && handleError(error);
        }
      }, 500);
    }

    return () => {
      clearTimeout(delayInputTimeout);
    };
  }, [query]);

  useEffect(() => {
    setQuery('');
  }, [type]);

  return (
    <CorInput
      className="flex-1 flex flex-row items-center justify-center relative"
      iconDisplay={InputIconDisplay.LEFT}
      iconName={IconName.SEARCH}
      iconColor={IconColor.CONTENT_MUTED}
    >
      <input
        type="text"
        value={query}
        placeholder={placeholderValue}
        onChange={(e) => setQuery(e.target.value)}
      />
    </CorInput>
  );
};

export default ManagementToolSearch;
