import { SVGProps } from 'react';

const Plus = (props: SVGProps<any>) => {
  const { color = 'black' } = props;
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M3.16663 8.00033C3.16663 7.63214 3.4651 7.33366 3.83329 7.33366H13.1666C13.5348 7.33366 13.8333 7.63214 13.8333 8.00033C13.8333 8.36852 13.5348 8.66699 13.1666 8.66699H3.83329C3.4651 8.66699 3.16663 8.36852 3.16663 8.00033Z"
        fill={color}
      />
      <path
        d="M8.49996 2.66699C8.86815 2.66699 9.16663 2.96547 9.16663 3.33366V12.667C9.16663 13.0352 8.86815 13.3337 8.49996 13.3337C8.13177 13.3337 7.83329 13.0352 7.83329 12.667V3.33366C7.83329 2.96547 8.13177 2.66699 8.49996 2.66699Z"
        fill={color}
      />
    </svg>
  );
};

export default Plus;
