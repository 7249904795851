export enum OpenedTab {
  ALL_RECEITS = 'Toate Rețetele',
  MY_RECEIPTS = 'Rețetele Mele',
}

export enum PrescriptionListStatus {
  NO_DATA,
  NO_PRESCRIPTIONS,
  HAS_PRESCRIPTIONS,
}
