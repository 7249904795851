import React, { memo, useContext, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { CorBox, CorText } from '@e-reteta/react-design-system';

import { TextVariant } from '../../enums/design-system.enum';
import { AuthContext } from '../../context/context';
import { Alert } from '../../components';
import { AlertStatus } from '../../components/Alert/enum';
import { T, useTranslate } from '@tolgee/react';
import { Form, Formik, FormikProps } from 'formik';
import Accordion from '../../components/Reports/Accordion';
import { ReportType } from '../../components/Reports/report-type.enum';
import Annex6 from '../../components/Reports/Annex6';
import Annex11 from '../../components/Reports/Annex11';
import Report126PsfHead from '../../components/Reports/Report126/Report126PsfHead';
import Annex8ForPharmacyHead from '../../components/Reports/Annex8ForPharmacy/Annex8ForPharmacyHead';

const HeadPharmacyReports = () => {
  const [accessExpired, setAccessExpired] = useState(false);
  const { t } = useTranslate();
  const formikRef = useRef<FormikProps<{}> | null>(null);

  const { user } = useContext(AuthContext);
  useEffect(() => {
    if (!user) {
      return;
    }
    const expiresIn = user?.activeCompany?.licenceExpiresInDays || 0;
    if (expiresIn <= 0) {
      setAccessExpired(true);
      return;
    }
    setAccessExpired(false);
  }, [user]);

  const handleSave = async () => {
    formikRef.current?.resetForm();
  };

  return (
    <Formik
      onSubmit={handleSave}
      innerRef={formikRef}
      enableReinitialize={true}
      validateOnBlur={false}
      validateOnChange={false}
      validateOnMount={false}
      initialValues={{}}
    >
      <Form className="flex flex-col flex-1 overflow-auto" id="management-tool-form">
        <div className={classNames('flex-1 flex flex-col overflow-auto sm:ml-6')}>
          <div className="flex flex-col flex-1 overflow-auto" id="management-tool-form">
            <div className="flex justify-between items-center gap-4">
              <CorText variant={TextVariant.HEADING_2_BOLD}>
                <h2>
                  <T keyName={'reports.reportsText'}>Reports</T>
                </h2>
              </CorText>
            </div>

            <div className="mt-4 overflow-auto">
              <CorBox className="bg-white p-2 xs:p-6 h-[calc(100vh-88px)] overflow-auto">
                {accessExpired ? (
                  <Alert
                    label={t(
                      'PharmacyReports.accessExpired',
                      'Contractul filialei in care activati este expirat. La moment aveti acces doar la modulul de rapoarte.',
                    )}
                    className="mb-4"
                    status={AlertStatus.DANGER}
                  />
                ) : null}
                <Accordion
                  report="report126PsfHead"
                  name={t('managementToolReports.report126', 'Raport 1/26')}
                  type={ReportType.REPORT126_PER_PSF}
                >
                  <Report126PsfHead />
                </Accordion>
                <Accordion
                  report="annex6"
                  name={t(
                    'managementToolReports.annex6',
                    'Raport descifrat per farmacii conform prevederii Legii 1585',
                  )}
                  type={ReportType.ANNEX6_PER_PSF}
                >
                  <Annex6 />
                </Accordion>
                <Accordion
                  report="annex8ForPharmacy"
                  name={t('managementToolReports.annex8ForPharmacy', 'Raport descifrat')}
                  type={ReportType.ANNEX8_FOR_PHARMACY_HEAD}
                >
                  <Annex8ForPharmacyHead />
                </Accordion>
                <Accordion
                  report="annex11"
                  name={t('managementToolReports.annex11', 'Raport per DC selectat (Eliberare)')}
                  type={ReportType.ANNEX11_PER_PHARMACY}
                >
                  <Annex11 />
                </Accordion>
              </CorBox>
            </div>
          </div>
        </div>
      </Form>
    </Formik>
  );
};

export default memo(HeadPharmacyReports);
