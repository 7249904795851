import { memo, useContext, useEffect } from 'react';

import Table from '../Table';
import { ManagementTableContext } from '../context';
import { ManagementTableProps } from '../interface';
import { Pagination } from '../../index';

const ManagementTableMainComponent = (props: ManagementTableProps) => {
  const { fetchItems, paginationData, setPaginationData, items } =
    useContext(ManagementTableContext);

  useEffect(() => {
    fetchItems();
  }, [paginationData.page, paginationData.itemsPerPage]);

  return (
    <div className="flex flex-col justify-start gap-4 max-h-full overflow-auto">
      <Table {...props} />
      {items && items?.length >= 1 ? (
        <Pagination paginationData={paginationData} setPaginationData={setPaginationData} />
      ) : null}
    </div>
  );
};

export default memo(ManagementTableMainComponent);
