export const renderResponsiveClasses = (
  isMobile: boolean,
  isTablet: boolean,
  mobileClasses: string,
  tableClasses: string,
  desktopClasses: string,
) => {
  if (isMobile) {
    return mobileClasses;
  }
  if (isTablet) {
    return tableClasses;
  }
  return desktopClasses;
};
