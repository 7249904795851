import { T, useTranslate } from '@tolgee/react';
import { CorButton, CorIcon, CorInput } from '@e-reteta/react-design-system';
import { useEffect, useState } from 'react';
import hash from 'object-hash';

import { ChevronDown } from '../../../icons';
import { IconColor, IconName } from '../../../enums/design-system-icons.enum';
import { SearchInputProps } from './search-input.interface';
import { DropdownEnum } from './dropdown.enum';
import { ButtonVariant } from '../../../enums/design-system.enum';

const SearchInput = (props: SearchInputProps) => {
  const { onClick, selected, setSelected } = props;
  const { t } = useTranslate();
  const [disabled, setDisabled] = useState<boolean>(true);
  const [openedDropdown, setOpenedDropdown] = useState<boolean>(false);
  const [query, setQuery] = useState<string>('');

  useEffect(() => {
    if (query?.length <= 3) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [query]);

  const handleClick = () => {
    if (!disabled) {
      onClick(query);
    }
  };

  const handelChange = (dropdown: DropdownEnum) => {
    setSelected(dropdown);
    setOpenedDropdown(false);
  };

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      if (!disabled) {
        onClick(query);
      }
    }
  };

  useEffect(() => {
    document.addEventListener('keypress', handleKeyDown);

    return () => {
      document.removeEventListener('keypress', handleKeyDown);
    };
  }, [query]);

  return (
    <div className="flex w-full gap-6">
      <div className="flex w-full relative">
        <div
          className="flex border font-semibold border-solid border-border-default rounded-l-full border-r-0 items-center
          justify-center pl-5 pr-4 text-sm text-content-success gap-2.5 bg-[#F2F3F5] cursor-pointer"
          onClick={() => setOpenedDropdown((prev) => !prev)}
        >
          {selected && selected !== DropdownEnum.NONE
            ? selected
            : t('einsteinDrawer.allValuesDropdown', 'Toate')}
          <ChevronDown className="fill-content-muted w-4 h-4" />
        </div>
        {openedDropdown ? (
          <div className="flex flex-col w-[145px] border border-solid border-border-default absolute top-11 py-2 rounded-lg bg-white">
            {Object.values(DropdownEnum).map((dropdown) =>
              dropdown !== DropdownEnum.NONE ? (
                <div
                  className="px-4 py-2 flex justify-between text-sm text-content-muted cursor-pointer"
                  onClick={() => handelChange(dropdown)}
                  key={hash(dropdown)}
                >
                  <div>
                    <T keyName={`einsteinDrawer.${dropdown}`}>{dropdown}</T>
                  </div>
                  {selected === dropdown ? <CorIcon name={IconName.CHECK} /> : null}
                </div>
              ) : null,
            )}
          </div>
        ) : null}
        <CorInput className="w-full">
          <input
            className="rounded-r-full w-full h-10"
            type="text"
            value={query}
            placeholder={t('searchInputQA.placeholder', 'Scrie intrbarea')}
            onChange={(event) => setQuery(event.target.value)}
          />
        </CorInput>
      </div>
      <CorButton variant={ButtonVariant.PRIMARY}>
        <button
          onClick={handleClick}
          disabled={disabled}
          className="h-[40px] w-[40px] disabled:bg-action-neutral-default"
        >
          <CorIcon name={IconName.SEARCH} color={IconColor.WHITE} />
        </button>
      </CorButton>
    </div>
  );
};

export default SearchInput;
