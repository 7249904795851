export enum PatientCardType {
  DEFAULT = 'Default (Patient)',
  PRESCRIPTION = 'Prescription',
  HOLOGRAPHIC_RECEIPT = 'Holographic Receipt',
}

export enum InsuredStatusEnums {
  INSURED = 'Asigurat',
  UNINSURED = 'Neasigurat',
}
