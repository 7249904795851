import { FC } from 'react';
import { CorInput, CorText } from '@e-reteta/react-design-system';

import { TextVariant } from '../../enums/design-system.enum';
import { InputWithAmountProps } from './interface';

const InputWithAmount: FC<InputWithAmountProps> = (props) => {
  const {
    label,
    isDisabled,
    isError,
    type,
    error,
    value,
    placeholder,
    name,
    onChange,
    onWheel,
    onKeyDown,
    currency = 'Lei',
  } = props;

  return (
    <div className="flex flex-col">
      <CorText variant={TextVariant.LABEL_2_REGULAR}>
        <span className="text-content-muted">{label}</span>
      </CorText>
      <div className="relative">
        <CorInput disabled={isDisabled} className="group relative" error={!!isError}>
          <input
            type={type}
            name={name}
            placeholder={placeholder}
            value={value}
            onChange={(event) => (onChange ? onChange(event) : null)}
            onWheel={(event) => (onWheel ? onWheel(event) : null)}
            onKeyDown={(event) => (onKeyDown ? onKeyDown(event) : null)}
          />
          <div className="flex justify-center absolute top-0 right-0 bg-surface-default w-[40px] h-[40px] rounded-tr-2.5xl rounded-br-2.5xl">
            <div className="mt-[9px]">
              <CorText variant={TextVariant.BODY_1_REGULAR}>
                <span className="text-content-muted">{currency}</span>
              </CorText>
            </div>
          </div>
          {!!isError ? <span className="bottom-helper">{error}</span> : null}
        </CorInput>
      </div>
    </div>
  );
};

export default InputWithAmount;
