import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import jwtDecode from 'jwt-decode';

import { AuthContext } from './context';
import { AuthProviderProps } from './context.types';
import Api from '../axios/api';
import { Company, User } from '../services/auth.services';
import { getActiveCompanyUtil } from '../utils/getActiveCompany.util';
import { ClinicRolesEnum, PharmacyRolesEnum } from '../constants/roles.enum';
import { useOpenReplay } from '../hooks/use-openreplay';

export const getMainRoute = (activeCompany?: Company): string => {
  if (!activeCompany) {
    return '/';
  }

  switch (activeCompany.type) {
    case 'admin':
      return '/management-tool/reports';
    case 'clinic':
      if (
        activeCompany.roles.includes(ClinicRolesEnum.IMS_HEAD) ||
        activeCompany.roles.includes(ClinicRolesEnum.IMS_BRANCH)
      ) {
        return '/clinic/institutional-reports';
      }
      return '/doctor/search';
    case 'pharmacy': {
      if (activeCompany.roles.includes(PharmacyRolesEnum.PSF_TECH)) {
        return '/pharmacy/integrations';
      }

      if (activeCompany.roles.includes(PharmacyRolesEnum.PSF_HEAD)) {
        return '/pharmacy/head-reports';
      }

      if (activeCompany.roles.includes(PharmacyRolesEnum.PSF_BRANCH)) {
        return '/pharmacy/branch-reports';
      }

      const licenceExpiresIn = activeCompany?.licenceExpiresInDays || 0;

      if (licenceExpiresIn <= 0) {
        return '/pharmacy/reports';
      }
      return '/pharmacy/search';
    }
  }

  return '/';
};

export const AuthProvider = ({ children }: AuthProviderProps): JSX.Element => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const location = useLocation();

  const [user, setUser] = useState<User>();
  const [mainRoute, setMainRoute] = useState<string>();
  const [role, setRole] = useState<string>();

  // useOpenReplay(user);

  const hasRole = (role: string): boolean | undefined => {
    return user?.activeCompany?.roles?.includes(role);
  };

  const initUser = useCallback(async () => {
    const token = localStorage.getItem('access_token');
    if (!token) {
      logout(false);
      return;
    }

    const currentUser: User = jwtDecode(token);
    currentUser.activeCompany = getActiveCompanyUtil(currentUser);
    setUser(currentUser);
    setMainRoute(getMainRoute(currentUser.activeCompany));
    Api.defaults.headers['Authorization'] = `Bearer ${token}`;

    // const userRoles: string[] = currentUser.activeCompany?.roles as string[];
    const speciality = currentUser.activeCompany?.speciality;
    switch (speciality) {
      case 'PHARMACIST':
        setRole('pharmacy');
        break;
      case '':
        setRole('admin');
        break;
      default:
        setRole('doctor');
    }
  }, []);

  useEffect(() => {
    if (location.pathname === '/login-success' && search) {
      const params = new URLSearchParams(search);
      const accessToken = params.get('access_token');
      const refreshToken = params.get('refresh_token');

      if (accessToken && refreshToken) {
        localStorage.setItem('access_token', accessToken);
        Api.defaults.headers['Authorization'] = `Bearer ${accessToken}`;
        localStorage.setItem('refresh_token', refreshToken);
      }
    }
    initUser().catch(console.error);
  }, [location.pathname, initUser, search]);

  useEffect(() => {
    if (!user?.activeCompany) {
      return;
    }
    if (location.pathname !== '/') {
      return;
    }

    navigate(getMainRoute(user.activeCompany));
  }, [navigate, mainRoute, user?.activeCompany]);

  const logout = useCallback((ssoLogout: boolean) => {
    const token = localStorage.getItem('access_token');
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('role');
    localStorage.removeItem('last_active_tab');
    setUser(undefined);
    setRole(undefined);
    setMainRoute('/');
    if (ssoLogout) {
      window?.location?.replace(`${process.env.REACT_APP_API_URL}v1/auth/logout?token=${token}`);
    }
    navigate('/');
  }, []);

  const value = useMemo(
    () => ({
      user,
      setUser,
      role,
      setRole,
      logout,
      hasRole,
      mainRoute,
    }),
    [logout, user, mainRoute],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

const GlobalProvider = ({ children }: { children: ReactNode }) => {
  return <AuthProvider>{children}</AuthProvider>;
};

export default GlobalProvider;
