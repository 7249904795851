import { CorRadioButton, CorText } from '@e-reteta/react-design-system';
import { T } from '@tolgee/react';
import { useFormikContext } from 'formik';

import { Annex6FormikProps } from './annex6.interface';
import { TextVariant } from '../../../enums/design-system.enum';
import { ReportSearchType } from '../report-search-type.enum';

const Annex6Branch = () => {
  const formik = useFormikContext<Annex6FormikProps>();

  return (
    <div className="flex flex-col xs:px-4 mb-4">
      <CorText variant={TextVariant.LABEL_1_MEDIUM} className="mb-4">
        <div className="text-content-default">
          <T keyName={'anex2.medicalProduct'}>Produsul Medical</T>
        </div>
      </CorText>
      <div className="flex gap-8 mb-4">
        <CorRadioButton
          isChecked={ReportSearchType.DRUGS === formik?.values?.annex6MedicalProductType}
          disabled={false}
        >
          <label className="whitespace-nowrap">
            <T keyName={'annex2.drugs'}>{ReportSearchType.DRUGS}</T>
          </label>
          <input
            type="radio"
            name="annex6MedicalProductType"
            value={ReportSearchType.DRUGS}
            checked={ReportSearchType.DRUGS === formik?.values?.annex6MedicalProductType}
            onChange={formik.handleChange}
            className="bg-background-secondary"
          />
        </CorRadioButton>
        <CorRadioButton
          isChecked={ReportSearchType.DEVICES === formik?.values?.annex6MedicalProductType}
          disabled={false}
        >
          <label className="whitespace-nowrap">
            <T keyName={'annex2.devices'}>{ReportSearchType.DEVICES}</T>
          </label>
          <input
            type="radio"
            name="annex6MedicalProductType"
            value={ReportSearchType.DEVICES}
            checked={ReportSearchType.DEVICES === formik?.values?.annex6MedicalProductType}
            onChange={formik.handleChange}
            className="bg-background-secondary"
          />
        </CorRadioButton>
      </div>
    </div>
  );
};

export default Annex6Branch;
