import { FC, SVGProps } from 'react';

const Search: FC<SVGProps<SVGSVGElement>> = (props) => {
  const { fill = '#343841' } = props;
  return (
    <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        opacity="0.3"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.52827 11.1381C9.26792 10.8777 9.26792 10.4556 9.52827 10.1953C9.78862 9.93491 10.2107 9.93491 10.4711 10.1953L13.1377 12.8619C13.3981 13.1223 13.3981 13.5444 13.1377 13.8047C12.8774 14.0651 12.4553 14.0651 12.1949 13.8047L9.52827 11.1381Z"
        fill={fill}
        fillOpacity="0.8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.33366 10.6667C9.17461 10.6667 10.667 9.17436 10.667 7.33341C10.667 5.49247 9.17461 4.00008 7.33366 4.00008C5.49271 4.00008 4.00033 5.49247 4.00033 7.33341C4.00033 9.17436 5.49271 10.6667 7.33366 10.6667ZM7.33366 12.0001C4.75633 12.0001 2.66699 9.91074 2.66699 7.33341C2.66699 4.75609 4.75633 2.66675 7.33366 2.66675C9.91099 2.66675 12.0003 4.75609 12.0003 7.33341C12.0003 9.91074 9.91099 12.0001 7.33366 12.0001Z"
        fill={fill}
        fillOpacity="0.8"
      />
    </svg>
  );
};

export default Search;
