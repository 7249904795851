import { CorChips, CorText } from '@e-reteta/react-design-system';

import { ChipSize, ChipVariant, TextVariant } from '../../../../enums/design-system.enum';
import { RenderChipProps } from './render-chip.interface';
import { ReactNode } from 'react';

const RenderChip = (props: RenderChipProps) => {
  const { name, keyName, index } = props;

  return (
    <div className={`chipRef-${keyName}-${index}`}>
      <CorChips size={ChipSize.SM} variant={ChipVariant.OUTLINE}>
        <CorText variant={TextVariant.BODY_1_MEDIUM}>
          <span>{name as ReactNode}</span>
        </CorText>
      </CorChips>
    </div>
  );
};

export default RenderChip;
