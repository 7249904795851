import React, { FC, useState, KeyboardEvent, useEffect, useRef } from 'react';
import { CorBox, CorButton, CorInput } from '@e-reteta/react-design-system';
import { twMerge } from 'tailwind-merge';
import { T, useTranslate } from '@tolgee/react';

import type { SearchInputProps } from './interface';
import InputSwitch from '../InputSwitch';
import { InputIconDisplay } from '../../enums/design-system.enum';
import { IconColor, IconName } from '../../enums/design-system-icons.enum';
import Search from '../../icons/Search';
import { SwitchEnum } from '../../enums/switch.enum';

const SearchInput: FC<SearchInputProps> = ({
  onSubmit,
  className = '',
  withButton = false,
  withSwitch = false,
  switchValue1,
  switchValue2,
  placeholder1,
  placeholder2,
  onChange,
  translationKey1,
  translationKey2,
}) => {
  const [placeholderValue, setPlaceholderValue] = useState<string | undefined>(placeholder1);
  const [activeTranslationKey, setActiveTranslationKey] = useState<string | undefined>(
    translationKey1,
  );
  const [inputValue, setInputValue] = useState('');
  const [selected, setSelected] = useState<SwitchEnum>(switchValue1 || SwitchEnum.PATIENT);
  const [disabled, setDisabled] = useState(true);
  const { t } = useTranslate();
  const inputRef = useRef<HTMLInputElement>(null);

  const changeSwitchInput = (value: SwitchEnum) => {
    setPlaceholderValue(value === switchValue1 ? placeholder1 : placeholder2);
    setActiveTranslationKey(value === switchValue1 ? translationKey1 : translationKey2);

    if ((inputValue.length === 8 || inputValue.length === 10) && value === switchValue2) {
      setDisabled(false);
    } else if (inputValue.length >= 9 && inputValue.length <= 13 && value === switchValue1) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
    setSelected(value);
    if (onChange) {
      onChange(value);
    }
    inputRef?.current?.focus();
  };

  const handleClick = () => {
    if (selected === SwitchEnum.PATIENT && switchValue1) {
      onSubmit(inputValue, switchValue1);
    } else {
      onSubmit(inputValue, selected);
    }
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Enter' && !disabled) {
      handleClick();
    }
  };

  const handleOnChange = (value: string) => {
    const isDisabled =
      (selected === SwitchEnum.PATIENT && value.length >= 9 && value.length <= 13) ||
      (selected === SwitchEnum.PRESCRIPTION && (value.length === 8 || value.length === 10));
    setDisabled(!isDisabled);
    setInputValue(value);
  };

  useEffect(() => {
    const inputElement = document.getElementById('#switch-input');
    const handleFocus = (event: any) => {
      if (event.key === 'Enter')
        if (selected === SwitchEnum.PATIENT && switchValue1) {
          onSubmit(inputValue, switchValue1);
        } else {
          onSubmit(inputValue, selected);
        }
    };
    inputElement?.addEventListener('keypress', (event) => handleFocus(event));
    return () => {
      inputElement?.removeEventListener('keypress', handleFocus);
    };
  }, []);

  return (
    <>
      {withSwitch && switchValue1 !== undefined && switchValue2 !== undefined ? (
        <div className="xs:hidden">
          <InputSwitch
            value1={switchValue1}
            value2={switchValue2}
            selectedValue={selected}
            onChange={(value) => changeSwitchInput(value)}
          />
        </div>
      ) : null}
      <CorBox
        className={twMerge('flex flex-row justify-between gap-2 lg:gap-6 border-none', className)}
      >
        <CorInput
          className="flex-1 flex flex-row items-center justify-center relative"
          iconDisplay={InputIconDisplay.LEFT}
          iconName={IconName.SEARCH}
          iconColor={IconColor.CONTENT_MUTED}
        >
          <input
            ref={inputRef}
            id="switch-input"
            type="text"
            placeholder={t(activeTranslationKey!, placeholderValue)}
            onChange={(e) => handleOnChange(e.target.value)}
            onKeyDown={handleKeyDown}
          />
          {withSwitch && switchValue1 !== undefined && switchValue2 !== undefined ? (
            <div className="hidden xs:flex absolute right-1">
              <InputSwitch
                value1={switchValue1}
                value2={switchValue2}
                selectedValue={selected}
                onChange={(value) => changeSwitchInput(value)}
              />
            </div>
          ) : null}
        </CorInput>
        {withButton ? (
          <CorButton className="self-end">
            <button
              className="h-[40px] w-[40px] max-w-[40px] sm:w-[112px] sm:max-w-[112px] lg:w-[200px] lg:max-w-[200px]"
              onClick={handleClick}
              disabled={disabled}
            >
              <div className="hidden sm:flex">
                <T keyName="searchInput.search">Cauta</T>
              </div>
              <div className="flex sm:hidden items-center justify-center">
                <Search width={16} height={16} fill="white" />
              </div>
            </button>
          </CorButton>
        ) : null}
      </CorBox>
    </>
  );
};

export default SearchInput;
