import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import dayjs from 'dayjs';
import 'react-toastify/dist/ReactToastify.css';
import { init as initApm } from '@elastic/apm-rum';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/ro';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const active =
  typeof process.env.REACT_APP_ELASTIC_APM_ACTIVE === 'boolean'
    ? process.env.REACT_APP_ELASTIC_APM_ACTIVE
    : process.env.REACT_APP_ELASTIC_APM_ACTIVE === 'true';
if (active) {
  initApm({
    serviceName: 'E-reteta',
    serverUrl: process.env.REACT_APP_ELASTIC_APM_SERVER_URL
      ? process.env.REACT_APP_ELASTIC_APM_SERVER_URL
      : 'http://127.0.0.1:8200',
    environment: process.env.REACT_APP_ELASTIC_APM_ENVIRONMENT,
  });
}
dayjs.extend(relativeTime);
dayjs.locale('ro');

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
if (process.env.REACT_APP_ENV !== 'production') {
  const body = document.getElementsByTagName('body')[0] as HTMLElement;
  body.className = 'test-mode';
}

root.render(
  <React.StrictMode>
    <BrowserRouter basename="/">
      <App />
    </BrowserRouter>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
