import { CorBox, CorButton, CorDropdown, CorText } from '@e-reteta/react-design-system';
import hash from 'object-hash';
import React, { useContext } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { T, useTolgee, useTranslate } from '@tolgee/react';

import { ButtonVariant, TextVariant } from '../../enums/design-system.enum';
import { CorDropdownOption } from '../../components';
import { DropdownEvent } from '../../types/CommonTypes';
import { EInvoiceFormikProps } from './e-invoice.interface';
import { EInvoiceQuery, ReportService } from '../../services/report.service';
import { LoaderContext } from '../../context/loader/context';

const MONTHS = [
  'Ianuarie',
  'Februarie',
  'Martie',
  'Aprilie',
  'Mai',
  'Iunie',
  'Iulie',
  'August',
  'Septembrie',
  'Octombrie',
  'Noiembrie',
  'Decembrie',
];

const MONTHS_EN = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const MONTHS_RU = [
  'Январь',
  'Февраль',
  'Mарт',
  'Aпрель',
  'Mай',
  'Июнь',
  'Июль',
  'Aвгуст',
  'Cентябрь',
  'Oктябрь',
  'Hоябрь',
  'Mдекабрьай',
];

const EInvoice = () => {
  const { t } = useTranslate();
  const tolgee = useTolgee(['pendingLanguage']);
  const { setLoading } = useContext(LoaderContext);

  const changeMonthByLocale = () => {
    switch (tolgee.getPendingLanguage()) {
      case 'ro':
        return MONTHS;
      case 'ru':
        return MONTHS_RU;
      case 'en':
        return MONTHS_EN;
      default:
        return MONTHS;
    }
  };

  const handleFormSubmit = async (values: EInvoiceFormikProps) => {
    const query: EInvoiceQuery = {};
    if (values?.month) {
      query.month = Number(values?.month);
    }
    if (values?.year) {
      query.year = Number(values?.year);
    }
    setLoading(true);
    try {
      const resp = await ReportService.downloadEInvoice(query);
      download(resp);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      month: '',
      year: '',
    },
    validationSchema: Yup.object({
      month: Yup.string().required('month is required'),
      year: Yup.string().required('year is required'),
    }),
    onSubmit: (values: EInvoiceFormikProps) => {
      handleFormSubmit(values);
    },
    enableReinitialize: true,
  });

  const onSelectDropdown = async (e: DropdownEvent, id: string) => {
    await formik.setFieldValue(id, e?.detail?.value);
  };

  const renderYears = () => {
    const currentYear = new Date(Date.now()).getFullYear();
    const years = [currentYear];
    for (let i = 1; i < 7; i++) {
      years.push(currentYear - i);
    }
    return years;
  };

  const download = (response: any) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;

    link.setAttribute('download', `e-factura.xml`);

    document.body.appendChild(link);
    link.click();

    link?.parentNode?.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  return (
    <div className="flex flex-col flex-1 overflow-auto ml-6">
      <div className="flex justify-between items-center gap-4 mb-4">
        <CorText variant={TextVariant.HEADING_2_BOLD}>
          <h2>
            <T keyName={'eInvoice.pageTitle'}>E-Factura</T>
          </h2>
        </CorText>
      </div>
      <CorBox className="bg-background-primary p-6 grid xl:grid-cols-2">
        <form onSubmit={formik.handleSubmit}>
          <CorText variant={TextVariant.LABEL_1_MEDIUM}>
            <div className="text-content-default">
              <T keyName={'eInvoice.month'}>Luna</T>
            </div>
          </CorText>
          <CorDropdown
            placeholder={t('eInvoice.selectMonthPlaceholder', 'Selectati luna')}
            hasSelectedValue={false}
            searchEnabled={false}
            callback={(e) => onSelectDropdown(e, 'month')}
            isFlipped={false}
            className="mb-6"
            error={!!formik?.errors?.month}
          >
            {changeMonthByLocale().map((month, index) => (
              <CorDropdownOption
                key={hash(month)}
                label={month}
                selected={Number(formik?.values?.month) === index + 1}
                value={index + 1}
              />
            ))}
          </CorDropdown>
          {formik?.errors?.month ? (
            <CorText variant={TextVariant.BODY_1_REGULAR}>
              <span className="text-content-critical">
                <T keyName={'eInvoice.monthError'}>{formik.errors.month}</T>
              </span>
            </CorText>
          ) : null}
          <CorText variant={TextVariant.LABEL_1_MEDIUM} className="mt-6">
            <div className="text-content-default">
              <T keyName={'eInvoice.year'}>Anul</T>
            </div>
          </CorText>
          <CorDropdown
            placeholder={t('eInvoice.selectYearPlaceholder', 'Selectati anul')}
            hasSelectedValue={false}
            searchEnabled={false}
            callback={(e) => onSelectDropdown(e, 'year')}
            isFlipped={false}
            error={!!formik?.errors?.year}
          >
            {renderYears().map((year) => (
              <CorDropdownOption
                key={hash(year)}
                label={year}
                selected={Number(formik?.values?.year) === year}
                value={year}
              />
            ))}
          </CorDropdown>
          {formik?.errors?.year ? (
            <CorText variant={TextVariant.BODY_1_REGULAR}>
              <span className="text-content-critical">
                <T keyName={'eInvoice.yearError'}>{formik.errors.year}</T>
              </span>
            </CorText>
          ) : null}
          <CorButton variant={ButtonVariant.PRIMARY} className="mt-10">
            <button type="submit">
              <T keyName={'eInvoice.download'}>Descarca</T>
            </button>
          </CorButton>
        </form>
      </CorBox>
    </div>
  );
};

export default EInvoice;
