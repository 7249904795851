import Flag from 'react-world-flags';
import { components } from 'react-select';
import { DropDownSingle } from '../../types/CommonTypes';

export const DropDownSingleValue = (props: DropDownSingle) => {
  const { SingleValue } = components;

  return (
    <SingleValue {...props} className="flex gap-2 h-5 items-center">
      <Flag code={props.data.value} className="h-5 w-5 rounded-full object-cover" />
      <div
        className={`text-sm truncate ${
          props.data.label ? 'text-content-default' : 'text-content-muted'
        }`}
      >
        {props.data.label ? props.data.label : props.selectProps.placeholder}
      </div>
    </SingleValue>
  );
};
