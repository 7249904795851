import React, { FC, memo } from 'react';
import { CorBox, CorButton, CorIcon, CorText } from '@e-reteta/react-design-system';
import { T } from '@tolgee/react';

import { ButtonVariant, TextVariant } from '../../enums/design-system.enum';
import type { HandwrittenPrescriptionsProps } from './interface';
import { HandwrittenTable, Pagination } from '../index';
import useWindowWidth from '../../hooks/use-window-width';
import HandwrittenTableMobile from '../HandwrittenTableMobile';
import { renderResponsiveClasses } from '../../utils/render-responsive-classes.utils';

const HandwrittenPrescriptions: FC<HandwrittenPrescriptionsProps> = ({
  prescription,
  setOpenNewReceiptModal,
  paginationData,
  setPaginationData,
}) => {
  const { isMobile, isTablet } = useWindowWidth();

  return (
    <CorBox boxStyle="none" className="flex gap-4 sm:gap-6 p-0 sm:p-4 sm:pl-0 lg:p-0">
      <header className="flex items-center justify-between">
        <CorText variant={TextVariant.HEADING_2_BOLD}>
          <span>
            <T keyName="hanwrittenPrescriptions.hanwrittenPrescriptions">Retete Olografe</T>
          </span>
        </CorText>
        <CorButton variant={ButtonVariant.PRIMARY}>
          <button
            className="flex items-center justify-center p-3 gap-2"
            onClick={() => setOpenNewReceiptModal(true)}
          >
            <CorIcon name="plus" color="white" />
            {!isMobile ? (
              <T keyName="hanwrittenPrescriptions.newHanwrittenPrescription">
                Reteta noua olografa
              </T>
            ) : null}
          </button>
        </CorButton>
      </header>
      {prescription?.length ? (
        <>
          <div className="hidden md:flex">
            <HandwrittenTable recipes={prescription} />
          </div>
          <div className="grid md:hidden w-full">
            <HandwrittenTableMobile recipes={prescription} />
          </div>
          <div className="flex flex-row justify-between items-center">
            <Pagination paginationData={paginationData} setPaginationData={setPaginationData} />
          </div>
        </>
      ) : (
        <div className={`w-full h-full justify-center items-center p-4 rounded-2xl mt-6 flex`}>
          <div className="flex flex-col gap-8 w-[301px] sm:w-[401px] items-center text-center">
            <CorText
              variant={renderResponsiveClasses(
                isMobile,
                isTablet,
                TextVariant.HEADING_4_BOLD,
                TextVariant.HEADING_3_BOLD,
                TextVariant.HEADING_2_BOLD,
              )}
            >
              <span className="text-content-default text-center">
                <T keyName="hanwrittenPrescriptions.notFoundPrescription">
                  Nu aveti nici o reteta olografa
                </T>
              </span>
            </CorText>
            <img className="w-[200px] sm:w-[400px]" src="/search.png" alt="search" />
          </div>
        </div>
      )}
    </CorBox>
  );
};

export default memo(HandwrittenPrescriptions);
