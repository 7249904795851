import dayjs from 'dayjs';
import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { CorBox, CorText } from '@e-reteta/react-design-system';
import { T } from '@tolgee/react';
import { useTranslate } from '@tolgee/react';

import type { PatientMedicCardProps } from './interface';
import { TextVariant } from '../../enums/design-system.enum';
import PrescribingDoctor from '../PrescribedDoctor';
import useWindowWidth from '../../hooks/use-window-width';
import { Doctor } from '../../types/Doctor';
import { Keys } from '../../enums/Keys';
import PrescribingPharmacist from '../PrescribingPharmacist';
import { InsuredStatusEnums } from '../PatientCard/enums';
import { formatDate } from '../../utils/formatDate';

const PatientMedicCard: FC<PatientMedicCardProps> = ({
  user,
  doctor,
  patientIdentifier,
  patient,
  role,
}) => {
  const location = useLocation();
  const { isMobile } = useWindowWidth();
  const { t } = useTranslate();

  const patientFields = {
    [t(
      'patientMedicCard.nameSurname',
      Keys.nameSurname,
    )]: `${patient?.firstName} ${patient?.lastName}`,
    [t('patientMedicCard.patientIdentifier', 'IDNP')]: patientIdentifier,
    [t('patientMedicCard.dateOfBirth', Keys.dateOfBirth)]: patient?.birthday
      ? (() => {
          const date = formatDate(patient.birthday);
          const now = dayjs();
          const parsedBd = dayjs(patient.birthday);
          const diff = dayjs(now.diff(parsedBd));
          const years = Math.abs(1970 - diff.year());

          return `${date} | ${years} ${t('patientMedicCard.age', 'ani')}`;
        })()
      : 'N/A',
  };
  const patientExtraFields = {
    [t('patientMedicCard.patientStatus', 'Statutul')]: patient?.isInsured
      ? t('patientMedicCard.statusInsured', InsuredStatusEnums.INSURED)
      : t('patientMedicCard.statusUninsured', InsuredStatusEnums.UNINSURED),
    [t('prescribedDoctor.medicalUnit', Keys.medicalUnit)]: patient?.medicalUnit,
  };

  return (
    <CorBox
      boxStyle="none"
      px="lg"
      py="md"
      className="flex flex-col gap-4 bg-white rounded-2xl p-4 sm:py-5 sm:px-6 mb-4 h-fit"
    >
      <section className="flex flex-col items-start gap-3 pb-4 border-b border-x-0 border-t-0 border-solid border-border-inverted-selected">
        <header>
          <CorText variant={isMobile ? TextVariant.LABEL_1_BOLD : TextVariant.HEADING_4_BOLD}>
            <span>
              <T keyName="patientMedicCard.patientData">Date pacient</T>
            </span>
          </CorText>
        </header>
        <div className="flex flex-col w-full xs:grid xs:grid-cols-2 sm:grid-cols-3 gap-3">
          {Object.entries(patientFields).map(([key, value]) =>
            value ? (
              <div className="grid grid-cols-2 xs:flex xs:flex-col" key={key}>
                <CorText variant={TextVariant.LABEL_2_REGULAR}>
                  <span className="text-content-muted">{key}</span>
                </CorText>
                <CorText variant={TextVariant.LABEL_2_MEDIUM}>
                  <span className="flex justify-end xs:justify-start text-content-default">
                    {value}
                  </span>
                </CorText>
              </div>
            ) : null,
          )}
          {role === 'doctor'
            ? Object.entries(patientExtraFields).map(([key, value]) =>
                value ? (
                  <div className="grid grid-cols-2 xs:flex xs:flex-col" key={key}>
                    <CorText variant={TextVariant.LABEL_2_REGULAR}>
                      <span className="text-content-muted">{key}</span>
                    </CorText>
                    <CorText variant={TextVariant.LABEL_2_MEDIUM}>
                      <span className="flex justify-end xs:justify-start text-content-default">
                        {value}
                      </span>
                    </CorText>
                  </div>
                ) : null,
              )
            : null}
        </div>
      </section>
      {doctor ? (
        <PrescribingDoctor doctor={doctor} />
      ) : (
        <PrescribingDoctor doctor={user as Doctor} />
      )}
      {role === 'pharmacy' && location?.pathname?.includes('dispenses') ? (
        <section className="border-t border-x-0 border-b-0 pt-4 border-solid border-border-inverted-selected">
          <PrescribingPharmacist pharmacist={user} />
        </section>
      ) : null}
    </CorBox>
  );
};

export default PatientMedicCard;
