import { CorRadioButton, CorText } from '@e-reteta/react-design-system';
import React, { useEffect } from 'react';
import { T } from '@tolgee/react';
import { useFormikContext } from 'formik';

import { TextVariant } from '../../../enums/design-system.enum';
import { Annex7FormikProps } from './annex7.interface';
import { ReportSearchType } from '../report-search-type.enum';

const Annex7 = () => {
  const formik = useFormikContext<Annex7FormikProps>();

  useEffect(() => {
    formik.setFieldValue('annex7reportType', ReportSearchType.TOTALIZER_REPORT);
  }, []);

  const renderReportType = () => {
    return (
      <>
        <CorText variant={TextVariant.LABEL_1_MEDIUM} className="px-4">
          <div className="text-content-default">
            <T keyName={'annex.reportType'}>Tip raport</T>
          </div>
        </CorText>
        <div className="flex flex-col px-4 pt-4">
          <div className="flex gap-8">
            <CorRadioButton
              isChecked={ReportSearchType.TOTALIZER_REPORT === formik?.values?.annex7reportType}
              disabled={false}
            >
              <label className="whitespace-nowrap">
                <T keyName={'annex.totalizerReport'}>{ReportSearchType.TOTALIZER_REPORT}</T>
              </label>
              <input
                type="radio"
                name="annex7reportType"
                value={ReportSearchType.TOTALIZER_REPORT}
                checked={ReportSearchType.TOTALIZER_REPORT === formik?.values?.annex7reportType}
                onChange={formik.handleChange}
                className="bg-background-secondary"
              />
            </CorRadioButton>
            <CorRadioButton
              isChecked={ReportSearchType.DRUGS_REPORT === formik?.values?.annex7reportType}
              disabled={false}
            >
              <label className="whitespace-nowrap">
                <T keyName={'annex.drugsReport'}>{ReportSearchType.DRUGS_REPORT}</T>
              </label>
              <input
                type="radio"
                name="annex7reportType"
                value={ReportSearchType.DRUGS_REPORT}
                checked={ReportSearchType.DRUGS_REPORT === formik?.values?.annex7reportType}
                onChange={formik.handleChange}
                className="bg-background-secondary"
              />
            </CorRadioButton>
            <CorRadioButton
              isChecked={ReportSearchType.DEVICES_REPORT === formik?.values?.annex7reportType}
              disabled={false}
            >
              <label className="whitespace-nowrap">
                <T keyName={'annex.devicesReport'}>{ReportSearchType.DEVICES_REPORT}</T>
              </label>
              <input
                type="radio"
                name="annex7reportType"
                value={ReportSearchType.DEVICES_REPORT}
                checked={ReportSearchType.DEVICES_REPORT === formik?.values?.annex7reportType}
                onChange={formik.handleChange}
                className="bg-background-secondary"
              />
            </CorRadioButton>
          </div>
        </div>
        <div className="w-full border-0 border-t border-solid border-border-disabled mx-4 my-6" />
      </>
    );
  };

  return <div className="flex flex-col">{renderReportType()}</div>;
};

export default Annex7;
