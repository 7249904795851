import { SVGProps } from 'react';

const HardDrive = (props: SVGProps<any>) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.66667 10.8334H18.3333V14.6667C18.3333 15.7713 17.4379 16.6667 16.3333 16.6667H3.66667C2.5621 16.6667 1.66667 15.7713 1.66667 14.6667V10.8334ZM15.4167 15C16.107 15 16.6667 14.4404 16.6667 13.75C16.6667 13.0597 16.107 12.5 15.4167 12.5C14.7263 12.5 14.1667 13.0597 14.1667 13.75C14.1667 14.4404 14.7263 15 15.4167 15ZM12.5 13.75C12.5 14.4404 11.9404 15 11.25 15C10.5596 15 10 14.4404 10 13.75C10 13.0597 10.5596 12.5 11.25 12.5C11.9404 12.5 12.5 13.0597 12.5 13.75Z"
      />
      <path
        opacity="0.3"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.07925 7.12164C4.26371 6.83784 4.57922 6.66663 4.91769 6.66663H15.0823C15.4208 6.66663 15.7363 6.83784 15.9208 7.12164L18.3333 10.8333H1.66667L4.07925 7.12164Z"
      />
    </svg>
  );
};

export default HardDrive;
