import axios from 'axios';
import { onReject } from './on-reject';
import { injectToken } from './inject-token';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.request.use(injectToken, (error) => Promise.reject(error));

api.interceptors.response.use((response) => response, onReject(api));

export default api;
