export const searchPatientLocations = [
  '/patient/:idnp',
  '/patient/:idnp/prescription/:prescriptionId',
  '/patient/:idnp/dispenses/:prescriptionId',
  '/patient/:idnp/payment/:prescriptionId',
  '/patient/:idnp/dispenses/:dispenseId/close',
  '/patient/:idnp/prescription/new',
  '/patient/:idnp/prescription/:prescriptionId/clone',
  '/patient/:idnp/prescription/:prescriptionId/edit',
  '/holographic-recipe/:recipeId',
];

export const unsignedDispensesLocations = [
  '/patient/:idnp/prescription/:prescriptionId/sign-dispense',
];

export const handwrittenLocations = ['/patient/:idnp/prescription/:prescriptionId/sign'];

export const variableLocations = [
  '/patient/:idnp/prescription/:prescriptionId/clone',
  '/patient/:idnp/prescription/:prescriptionId/edit',
];
