import { FC, memo, useState } from 'react';
import { CorBadge, CorBox, CorIcon, CorText } from '@e-reteta/react-design-system';
import classNames from 'classnames';
import { useTranslate } from '@tolgee/react';

import { ChevronDown } from '../../icons';
import { BadgeColor, BadgeSize, TextVariant } from '../../enums/design-system.enum';
import { AccordionProps } from './interface';
import { IconColor } from '../../enums/design-system-icons.enum';
import { useLocation } from 'react-router-dom';

const Accordion: FC<AccordionProps> = ({
  name,
  hasBorderBottom,
  quantity,
  count,
  children,
  totallyDispensed,
  expired,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const { t } = useTranslate();
  // TODO use a state to prevent use location magic and props drilling
  const location = useLocation();

  const renderClasses = (
    defaultState: string,
    releasedState: string,
    partialReleasedState: string,
    expiredState: string,
  ) => {
    if (expired) {
      return expiredState;
    }
    if (count === Number(quantity)) {
      return defaultState;
    }
    if (totallyDispensed) {
      return releasedState;
    } else {
      return partialReleasedState;
    }
  };

  const renderBadge = () => {
    let text: string;

    if (expired) {
      text = t('accordion.expired', 'Expirat');
    } else {
      if (totallyDispensed) {
        text = t('accordion.totallyDispensed', 'Complet eliberat');
      } else {
        text = t('accordion.leftToBeDispensed', 'Ramas spre eliberare') + ': ' + count;
      }
    }
    return (
      <CorBadge
        color={renderClasses(BadgeColor.GREY, BadgeColor.GREEN, BadgeColor.ORANGE, BadgeColor.RED)}
        size={BadgeSize.SM}
      >
        <span>{text}</span>
      </CorBadge>
    );
  };

  return (
    <div
      className={classNames(
        'border-solid border-t border-0 border-border-inverted-selected pt-3 pl-2 pb-4 2md:pt-4 2md:p-0',
        { 'border-b': hasBorderBottom },
      )}
    >
      <CorBox boxStyle="none" className="relative overflow-hidden">
        <CorBox
          boxStyle="none"
          flexDirection="row"
          className="justify-between items-center cursor-pointer pb-4"
          onClick={() => setIsOpen((value) => !value)}
        >
          <div className="flex flex-row gap-4 items-start sm:items-center w-full">
            <div
              className={`w-5 sm:w-8 h-5 sm:h-8 ${renderClasses(
                'bg-surface-default',
                'bg-surface-success-default',
                'bg-surface-warning-default',
                'bg-surface-critical-default',
              )} flex justify-center items-center rounded-xl sm:rounded-lg`}
            >
              <CorIcon
                name={expired ? 'close' : 'check-circle'}
                color={renderClasses(
                  IconColor.GRAY,
                  IconColor.GREEN,
                  IconColor.ORANGE,
                  IconColor.RED,
                )}
              />
            </div>
            <div className="flex flex-1 flex-col sm:flex-row items-start sm:items-center justify-between">
              <CorText variant={TextVariant.LABEL_1_BOLD}>
                <span>{name}</span>
              </CorText>
              {!location.pathname.includes('sign-dispense') && (
                <div className="flex flex-1 justify-end mr-4 whitespace-nowrap">
                  {renderBadge()}
                </div>
              )}
            </div>
          </div>
          <ChevronDown
            width={24}
            height={24}
            className={classNames('duration-100 ease-in-out', { 'transform rotate-180': isOpen })}
          />
        </CorBox>
        {isOpen && children}
      </CorBox>
    </div>
  );
};

export default memo(Accordion);
