import { CorButton, CorIcon, CorInput, CorText, CorToggle } from '@e-reteta/react-design-system';
import { memo } from 'react';
import { useFormik } from 'formik';
import { T, useTranslate } from '@tolgee/react';

import { IntegrationFormikProps, IntegrationProps } from './integration.interface';
import disableScrollOnInput from '../../utils/disableScrollOnInput';
import { ButtonVariant, TextVariant } from '../../enums/design-system.enum';
import { SettingsStatusEnum } from '../../enums/settings-status.enum';
import { IconColor, IconName } from '../../enums/design-system-icons.enum';
import { PharmaciesSettingsServices } from '../../services/pharmacy-settings.services';

const IntegrationModal = (props: IntegrationProps) => {
  const { integration, setDisplayModal } = props;
  const { t } = useTranslate();

  const update = async (values: IntegrationFormikProps) => {
    await PharmaciesSettingsServices.pharmacyUpdate({
      idno: values.idno,
      pullProductsURL: values.pullProductsURL,
      pushDispensesURL: values.pushDispensesURL,
      integrationStatus: values.integration
        ? SettingsStatusEnum.ACTIVE
        : SettingsStatusEnum.INACTIVE,
    });
  };

  const handleFormSubmit = async (values: IntegrationFormikProps) => {
    await update(values);
    setDisplayModal(false);
  };

  const formik = useFormik({
    initialValues: {
      idno: integration?.idno || '',
      isActive: Boolean(integration?.isActive),
      pullProductsURL: integration?.pullProductsURL || '',
      pushDispensesURL: integration?.pushDispensesURL || '',
      integrationStatus: integration?.integrationStatus || SettingsStatusEnum.ACTIVE,
      integration: integration?.integrationStatus === SettingsStatusEnum.ACTIVE,
    },
    onSubmit: (values: IntegrationFormikProps) => {
      handleFormSubmit(values);
    },
    enableReinitialize: true,
  });

  const handleToggle = async (id: string, value: boolean) => {
    await formik.setFieldValue(id, value);
  };

  return (
    <form
      onSubmit={formik.handleSubmit}
      className="fixed inset-0 h-screen w-full bg-modal-background z-50"
    >
      <div className="bg-primary mx-auto max-w-[800px] my-12 rounded-3xl flex flex-col max-h-[calc(100vh-96px)]">
        <div className="flex justify-between px-8 mt-7 mb-8">
          <CorText variant={TextVariant.HEADING_2_BOLD}>
            <h2>
              <T keyName={'integrationModal.edit'}>Editare</T>
            </h2>
          </CorText>
          <div className={`flex gap-2 ${formik?.isSubmitting ? 'pointer-events-none' : ''}`}>
            <CorButton variant={ButtonVariant.SECONDARY} onClick={() => setDisplayModal(false)}>
              <button type="submit">
                <T keyName={'integrationModal.cancel'}>Anulare</T>
                <CorIcon name={IconName.CLOSE} color={IconColor.CONTENT_SUCCESS}></CorIcon>
              </button>
            </CorButton>
            <CorButton variant={ButtonVariant.PRIMARY}>
              <button type="submit">
                <T keyName={'integrationModal.save'}>Salveaza</T>
                <CorIcon name={IconName.EDIT} color={IconColor.WHITE}></CorIcon>
              </button>
            </CorButton>
          </div>
        </div>
        <div className="px-8 mb-6 overflow-auto pb-10">
          <div className="flex flex-col gap-4">
            <div className="grid grid-cols-2 items-end gap-4">
              <div className="flex flex-col gap-1">
                <CorText variant={TextVariant.LABEL_2_REGULAR}>
                  <div className="text-content-muted">
                    <T keyName={'integrationModal.id'}>IDNO</T>
                  </div>
                </CorText>
                <CorInput disabled={true}>
                  <input
                    autoComplete="off"
                    type="text"
                    name="idno"
                    placeholder={t('integrationModal.idPlaceholder', 'Introduceti IDNO farmacie')}
                    value={formik.values.idno}
                    onChange={formik.handleChange}
                  />
                </CorInput>
              </div>
              <div className="flex flex-col gap-1 mb-2">
                <CorToggle
                  label={t(
                    'integrationModal.isActive',
                    'Statutul Conexiunii (Conectat/Dezactivat)',
                  )}
                  value={formik.values?.integration}
                  onClick={() => handleToggle('integration', !formik.values?.integration)}
                >
                  <input
                    autoComplete="off"
                    type="checkbox"
                    value={String(formik.values?.integration)}
                    disabled={true}
                  />
                </CorToggle>
              </div>
            </div>

            <div className="flex flex-col gap-1">
              <CorText variant={TextVariant.LABEL_2_REGULAR}>
                <div className="text-content-muted">
                  <T keyName={'integrationModal.pullProductsURL'}>
                    Link de extragere medicamente/dispozitive
                  </T>
                </div>
              </CorText>
              <CorInput>
                <input
                  autoComplete="off"
                  type="text"
                  name="pullProductsURL"
                  placeholder={t(
                    'integrationModal.pullProductsURLPlaceholder',
                    'Introduceti link de extragere medicamente/dispozitive',
                  )}
                  value={formik.values?.pullProductsURL}
                  onChange={formik.handleChange}
                  onWheel={(e) => disableScrollOnInput(e)}
                />
              </CorInput>
            </div>
            <div className="flex flex-col gap-1">
              <CorText variant={TextVariant.LABEL_2_REGULAR}>
                <div className="text-content-muted">
                  <T keyName={'integrationModal.pushDispensesURL'}>Link de trimitere eliberări</T>
                </div>
              </CorText>
              <CorInput>
                <input
                  autoComplete="off"
                  type="text"
                  name="pushDispensesURL"
                  placeholder={t(
                    'integrationModal.pushDispensesURLPlaceholder',
                    'Introduceti link de trimitere eliberări',
                  )}
                  value={formik.values?.pushDispensesURL}
                  onChange={formik.handleChange}
                  onWheel={(e) => disableScrollOnInput(e)}
                />
              </CorInput>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default memo(IntegrationModal);
