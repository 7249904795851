/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useLocation, useParams } from 'react-router-dom';
import React, { memo, useCallback, useContext, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { CorButton, CorIcon, CorText } from '@e-reteta/react-design-system';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { Form, Formik, FormikProps } from 'formik';
import { T, useTranslate } from '@tolgee/react';
import { AxiosError, isAxiosError } from 'axios';

import { NavigationEnum } from '../../enums/navigation.enum';
import { ButtonVariant, TextVariant } from '../../enums/design-system.enum';
import { IconColor, IconName } from '../../enums/design-system-icons.enum';
import { ChevronDown, UploadFolder } from '../../icons';
import ExportMenu from '../../components/ExportMenu';
import DrugModal from '../../components/ManagementTool/ModalContents/DrugModal';
import Modal from '../../components/ManagementTool/ManagementToolModal';
import { DrugService } from '../../services/drug.service';
import { CurrentTableRef } from './interface';
import DeviceModal from '../../components/ManagementTool/ModalContents/DeviceModal';
import { DeviceService } from '../../services/device.service';
import { DiagnosticServices } from '../../services/diagnostics.services';
import { InstitutionDto, InstitutionService } from '../../services/institution.service';
import InstitutionModal from '../../components/ManagementTool/ModalContents/InstitutionModal';
import SpecialityModal from '../../components/ManagementTool/ModalContents/SpecialityModal';
import DiagnosticModal from '../../components/ManagementTool/ModalContents/DiagnosticModal';
import { DiseaseDto, SpecialityService } from '../../services/speciality.service';
import ManagementTable from '../../components/ManagementToolTable';
import ManagementToolSearch from '../../components/ManagementTool/ManagementToolSearch';
import {
  clinicsValidationSchema,
  devicesValidationSchema,
  diagnosticsValidationSchema,
  drugsValidationSchema,
  integrationsValidationSchema,
  pharmaciesValidationSchema,
  specialitiesValidationSchema,
} from './validation-schemas';
import { toast, ToastContainer } from 'react-toastify';
import PharmacyModal from '../../components/ManagementTool/ModalContents/PharmacyModal';
import {
  GetInstitutionsResponse,
  PharmaciesServices,
  PharmacyDto,
} from '../../services/pharmacies.services';
import dayjs from 'dayjs';
import { handleError } from '../../utils/handleRequestErrors';
import { AuthContext } from '../../context/context';
import { JSX } from 'react/jsx-runtime';
import { ModalContentProps } from '../../components/ManagementTool/ModalContents/interface';
import { DrugData, DeviceData, SpecialityData } from '../../types/CommonTypes';
import { Disease } from '../../types/Disease';
import {
  isClinicValues,
  isDeviceValues,
  isDiagnosticValues,
  isDrugValues,
  isIntegrationValues,
  isPharmacyValues,
  isSpecialityValues,
} from '../../utils/checkTableData';
import {
  PharmaciesSettingsServices,
  PharmacySettingsDto,
} from '../../services/pharmacy-settings.services';
import IntegrationModal from '../../components/ManagementTool/ModalContents/IntegrationModal';
import { SettingsStatusEnum } from '../../enums/settings-status.enum';
import useDebounce from '../../hooks/useDebounce';
import { Alert } from '../../components';
import { AlertStatus } from '../../components/Alert/enum';
import { LoaderContext } from '../../context/loader/context';

const ManagementTool = () => {
  const { type } = useParams();
  const { role } = useContext(AuthContext);
  const { setLoading } = useContext(LoaderContext);
  const { t } = useTranslate();
  const location = useLocation();

  const [name, setName] = useState(NavigationEnum.DRUGS);
  const [pharmacyLicenceWarning, setPharmacyLicenceWarning] = useState<number>(0);
  const [resetFilter, setRestFilter] = useState<boolean>(false);
  const [expandedMenu, setExpandedMenu] = useState(false);
  const [childElement, setChildElement] = useState<JSX.Element>();
  const [modalElement, setModalElement] = useState<JSX.Element>();
  const [item, setItem] = useState<
    | Disease
    | InstitutionDto
    | PharmacyDto
    | DrugData
    | DeviceData
    | SpecialityData
    | PharmacySettingsDto
    | null
    | undefined
  >();
  const [initialValues, setInitialValues] = useState<
    | Disease
    | InstitutionDto
    | PharmacyDto
    | DrugData
    | DeviceData
    | SpecialityData
    | PharmacySettingsDto
    | null
    | undefined
  >({});

  type validationType =
    | typeof drugsValidationSchema
    | typeof devicesValidationSchema
    | typeof diagnosticsValidationSchema
    | typeof specialitiesValidationSchema
    | typeof clinicsValidationSchema
    | typeof pharmaciesValidationSchema
    | typeof integrationsValidationSchema;

  const [validationSchema, setValidationSchema] = useState<
    validationType | (() => validationType) | ''
  >('');
  const [query, setQuery] = useState('');
  const searchQuery = useDebounce(query, 500);

  const closeMenu = useCallback(() => {
    if (setExpandedMenu) {
      setExpandedMenu(false);
    }
  }, []);

  const formikRef = useRef<FormikProps<{}> | null>(null);
  const bulkUploadInputRef = useRef<HTMLInputElement>(null);
  // Pass this ref to current shown table to get its functions like refetch
  const currentTableRef = useRef<CurrentTableRef>(null);
  const ref = useDetectClickOutside({ onTriggered: closeMenu });

  const openBulkUpload = () => {
    bulkUploadInputRef.current?.click();
  };

  const handleBulkUpload = useCallback(async () => {
    if (!bulkUploadInputRef.current) {
      return;
    }

    const files = bulkUploadInputRef.current.files;

    if (!files?.length) {
      return;
    }

    const file = files[0];
    const formData = new FormData();

    formData.set('file', file);

    setLoading(true);
    try {
      switch (type) {
        case 'drugs':
          await DrugService.bulkUpload(formData);
          break;
        case 'diagnostics':
          await DiagnosticServices.bulkUpload(formData);
          break;
        case 'devices':
          await DeviceService.bulkUpload(formData);
          break;
        case 'specialities':
          await SpecialityService.bulkUpload(formData);
          break;
        case 'clinics':
          await InstitutionService.bulkUpload(formData);
          break;
        case 'pharmacies':
          await PharmaciesServices.bulkUpload(formData);
          break;
      }

      await currentTableRef.current?.refetchFunction?.();
      toast.success('Datele au fost încărcate', {
        position: toast.POSITION.TOP_CENTER,
      });
    } catch (e: AxiosError | any) {
      toast.error(
        `Incarcarea fisierului a esuat. ${(e?.response?.data?.message || e)?.toString()}`,
        {
          position: toast.POSITION.TOP_CENTER,
          className: 'management-tool-error-message',
        },
      );
    } finally {
      setLoading(false);
    }
  }, [type]);

  const handleCancel = useCallback(() => {
    formikRef.current?.resetForm();
    setModalElement(<></>);
    setInitialValues({});
    setItem(null);
  }, []);

  useEffect(() => {
    handleCancel();
  }, [location.pathname]);

  const getModal = useCallback(
    (props: JSX.IntrinsicAttributes & ModalContentProps, url: string = 'drugs') => {
      switch (url) {
        case 'drugs': {
          setValidationSchema(drugsValidationSchema);
          return <DrugModal {...props} />;
        }
        case 'devices': {
          setValidationSchema(devicesValidationSchema);
          return <DeviceModal {...props} />;
        }
        case 'diagnostics': {
          setValidationSchema(diagnosticsValidationSchema);
          return <DiagnosticModal {...props} />;
        }
        case 'specialities': {
          setValidationSchema(specialitiesValidationSchema);
          return <SpecialityModal {...props} />;
        }
        case 'clinics': {
          setValidationSchema(clinicsValidationSchema);
          return <InstitutionModal {...props} />;
        }
        case 'pharmacies': {
          setValidationSchema(pharmaciesValidationSchema);
          return <PharmacyModal {...props} />;
        }
        case 'integrations': {
          setValidationSchema(integrationsValidationSchema);
          return <IntegrationModal {...props} />;
        }
        default: {
          break;
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [type],
  );

  const handleEdit = useCallback(
    (
      item:
        | Disease
        | InstitutionDto
        | PharmacyDto
        | DrugData
        | DeviceData
        | SpecialityData
        | PharmacySettingsDto
        | null
        | undefined,
      url: string,
    ) => {
      setItem(item);
      setInitialValues(item);
      setModalElement(
        <Modal onCancel={handleCancel} title="Editare" item={item}>
          {getModal({ isEdit: true }, url)}
        </Modal>,
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleCancel, type, item, getModal],
  );

  const onFitchPharmacies: (data: GetInstitutionsResponse) => void = ({ data }) => {
    setPharmacyLicenceWarning(data.expiringCount || 0);
  };
  useEffect(() => {
    switch (type) {
      case 'drugs': {
        setName(NavigationEnum.DRUGS);
        setChildElement(
          <ManagementTable
            key="drugs"
            ref={currentTableRef}
            editItem={handleEdit}
            searchQuery={searchQuery}
          />,
        );
        if (item && isDrugValues(item)) {
          setInitialValues({
            ...item,
            isAttentionAge: Boolean(item.fromAttentionAge && item.toAttentionAge),
            group: { name: item?.groups?.length ? item?.groups[0]?.name : '' },
          });
        }
        break;
      }
      case 'devices': {
        setName(NavigationEnum.DEVICES);
        setChildElement(
          <ManagementTable
            key="devices"
            ref={currentTableRef}
            editItem={handleEdit}
            searchQuery={searchQuery}
          />,
        );
        if (item && isDeviceValues(item)) {
          setInitialValues({
            ...item,
            producer: {
              name: `${item.producer.name}, ${item.country.name}`,
            },
          });
        }
        break;
      }
      case 'diagnostics': {
        setName(NavigationEnum.DIAGNOSTIC);
        setChildElement(
          <ManagementTable
            key="diagnostics"
            ref={currentTableRef}
            editItem={handleEdit}
            searchQuery={searchQuery}
          />,
        );
        if (item && isDiagnosticValues(item)) {
          setInitialValues(item);
        }
        break;
      }
      case 'specialities': {
        setName(NavigationEnum.SPECIALITIES);
        setChildElement(
          <ManagementTable
            key="specialities"
            ref={currentTableRef}
            editItem={handleEdit}
            searchQuery={searchQuery}
          />,
        );
        if (item && isSpecialityValues(item)) {
          setInitialValues({
            ...item,
            prescribedDiseasesCodes: item.prescribedDisease?.map(
              (disease: DiseaseDto) => disease.code,
            ),
            rePrescribedDiseasesCodes: item.rePrescribedDisease?.map(
              (disease: DiseaseDto) => disease.code,
            ),
          });
        }
        break;
      }
      case 'clinics': {
        setName(NavigationEnum.CLINICS);
        setChildElement(
          <ManagementTable
            key="clinics"
            ref={currentTableRef}
            editItem={handleEdit}
            searchQuery={searchQuery}
          />,
        );
        if (item && isClinicValues(item)) {
          setInitialValues(item);
        }
        break;
      }
      case 'pharmacies': {
        setName(NavigationEnum.PHARMACIES);
        setChildElement(
          <ManagementTable
            key="pharmacies"
            ref={currentTableRef}
            editItem={handleEdit}
            searchQuery={searchQuery}
            onFitchData={onFitchPharmacies}
          />,
        );
        if (item && isPharmacyValues(item)) {
          setInitialValues({
            ...item,
            licenseValidity: dayjs(item.licenseValidity).toISOString(),
            validUntilTheDate: dayjs(item.validUntilTheDate).toISOString(),
          });
        }
        break;
      }
      case 'system-configuration': {
        setName(NavigationEnum.SYSTEM_CONFIGURATION);
        setChildElement(<></>);
        break;
      }
      case 'integrations': {
        setName(NavigationEnum.INTEGRATIONS);
        setChildElement(
          <ManagementTable ref={currentTableRef} editItem={handleEdit} searchQuery={searchQuery} />,
        );
        if (item && isIntegrationValues(item)) {
          setInitialValues({
            ...item,
            integration: item?.integrationStatus === SettingsStatusEnum.ACTIVE,
          });
        }
        break;
      }
      default: {
        setName(NavigationEnum.DRUGS);
        setChildElement(
          <ManagementTable ref={currentTableRef} editItem={handleEdit} searchQuery={searchQuery} />,
        );
        break;
      }
    }
    if (!item) {
      setInitialValues({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, item, searchQuery]);

  const showExpiringPharmacies = () => {
    if (!resetFilter) {
      currentTableRef.current?.refetchFunction?.({ licenceExpiresInDays: 60 });
    } else {
      currentTableRef.current?.refetchFunction?.({});
    }
    setRestFilter(!resetFilter);
  };

  const handleSave = async (
    values:
      | Disease
      | InstitutionDto
      | PharmacyDto
      | DrugData
      | DeviceData
      | SpecialityData
      | PharmacySettingsDto
      | null
      | undefined,
  ) => {
    switch (type) {
      case 'drugs': {
        if (isDrugValues(values)) {
          const data = {
            ...values,
            groupsName: [values?.group?.name ? values?.group?.name : ''],
            formName: values?.form?.name,
            countryName: values?.country?.name,
            producerName: values?.producer?.name,
          };
          if (item) {
            await DrugService.update(values.id, data);
          } else {
            try {
              await DrugService.create(data);
            } catch (error) {
              isAxiosError(error) && handleError(error);
              return;
            }
          }
        }
        break;
      }
      case 'devices': {
        if (isDeviceValues(values)) {
          const data = {
            ...values,
            producerName: values?.producer.name.split(',')[0],
            countryName: values?.producer.name ? values.producer.name.split(',')[1] : '',
            commercialCod: values?.commercialCod,
          };
          if (item) {
            await DeviceService.update(values.id, data);
          } else {
            try {
              await DeviceService.create(data);
            } catch (error) {
              isAxiosError(error) && handleError(error);
              return;
            }
          }
        }
        break;
      }
      case 'diagnostics': {
        if (isDiagnosticValues(values)) {
          const data = {
            ...values,
          };
          if (item) {
            await DiagnosticServices.update(values?.code, {
              ...data,
              groupName: data?.group?.name,
              parentGroupName: data?.group?.group?.name,
            });
          } else {
            try {
              await DiagnosticServices.create({
                ...data,
                groupName: data?.group?.name,
                parentGroupName: data?.group?.group?.name,
              });
            } catch (error) {
              isAxiosError(error) && handleError(error);
              return;
            }
          }
        }
        break;
      }
      case 'specialities': {
        if (isSpecialityValues(values)) {
          const data = {
            ...values,
            prescribedDiseasesCodes: values?.prescribedDiseasesCodes,
            rePrescribedDiseasesCodes: values?.rePrescribedDiseasesCodes,
          };
          if (item) {
            await SpecialityService.update(values.name, data);
          } else {
            try {
              await SpecialityService.create(data);
            } catch (error) {
              isAxiosError(error) && handleError(error);
              return;
            }
          }
        }
        break;
      }
      case 'clinics': {
        if (isClinicValues(values)) {
          const data = {
            ...values,
            idno: values?.idno.toString(),
            type: 'clinic',
          };
          if (item) {
            await InstitutionService.update(values.codIms, data);
          } else {
            try {
              await InstitutionService.create(data);
            } catch (error) {
              isAxiosError(error) && handleError(error);
              return;
            }
          }
        }
        break;
      }
      case 'pharmacies': {
        if (isPharmacyValues(values)) {
          if (item) {
            await PharmaciesServices.update(values?.code!, values);
          } else {
            try {
              await PharmaciesServices.create(values);
            } catch (error) {
              isAxiosError(error) && handleError(error);
              return;
            }
          }
        }
        break;
      }
      case 'integrations': {
        if (isIntegrationValues(values)) {
          const data = {
            ...values,
            integrationStatus: values.integration
              ? SettingsStatusEnum.ACTIVE
              : SettingsStatusEnum.INACTIVE,
          };
          if (item) {
            await PharmaciesSettingsServices.update(data);
          } else {
            try {
              await PharmaciesSettingsServices.create(data);
            } catch (error) {
              isAxiosError(error) && handleError(error);
              return;
            }
          }
        }
        break;
      }
      default: {
        break;
      }
    }

    formikRef.current?.resetForm();
    setItem(null);
    setModalElement(<></>);
    setInitialValues({});
    await currentTableRef.current?.refetchFunction?.();
  };
  const addModal = useCallback(() => {
    setItem(null);
    setInitialValues({});
    setModalElement(
      <Modal
        title={t('managementTool.addNewRow', 'Adaugare rand nou')}
        item={item}
        onCancel={handleCancel}
      >
        {getModal({ isEdit: false }, type)}
      </Modal>,
    );
  }, [handleCancel, item, getModal, type]);

  useEffect(() => {
    const form = document.getElementById('management-tool-form');
    const handleFocus = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        event.preventDefault();
      }
    };
    form?.addEventListener('keypress', handleFocus);
    return () => {
      form?.removeEventListener('keypress', handleFocus);
    };
  }, []);

  const handleInputChange = (newQuery: string) => {
    setQuery(newQuery);
  };

  const checkRole = () => {
    if (role) {
      return ['pharmacy', 'doctor'].includes(role);
    }
    return false;
  };

  const renderManagementToolHeader = () => {
    if (type === 'reports') {
      return;
    }
    if (type === 'integrations') {
      return (
        <CorButton variant={ButtonVariant.PRIMARY}>
          <button type="button" onClick={() => addModal()}>
            <T keyName={'managementTool.addIntegration'}>Adaugă Integrare</T>{' '}
            <CorIcon name={IconName.PLUS} color={IconColor.WHITE} />
          </button>
        </CorButton>
      );
    }
    return (
      <>
        <ManagementToolSearch
          placeholderValue={t('managementTool.search', 'Cauta')}
          query={query}
          setQuery={handleInputChange}
        />
        <div className="flex gap-2">
          <div className="relative z-50" ref={ref}>
            <CorButton
              className="group"
              variant={ButtonVariant.SECONDARY}
              onClick={() => setExpandedMenu((prev) => !prev)}
            >
              <button type="button">
                <T keyName={'managementTool.download'}>Descarca</T>
                <ChevronDown
                  className={classNames(
                    'w-4 h-4 fill-content-success group-hover:fill-action-primary-hover',
                    {
                      'rotate-180': expandedMenu,
                    },
                  )}
                />
              </button>
            </CorButton>
            <ExportMenu expanded={expandedMenu} setExpanded={setExpandedMenu} />
          </div>
          <CorButton variant={ButtonVariant.SECONDARY} onClick={() => openBulkUpload()}>
            <button type="button">
              <T keyName={'managementTool.uploadBulk'}>Incarca Bulk</T>
              <UploadFolder className="w-4 h-4 fill-content-success" />
            </button>
          </CorButton>
          <CorButton variant={ButtonVariant.PRIMARY}>
            <button type="button" onClick={() => addModal()}>
              <T keyName={'managementTool.addNewRow'}>Adauga rand nou</T>
              <CorIcon name={IconName.PLUS} color={IconColor.WHITE} />
            </button>
          </CorButton>
        </div>
      </>
    );
  };

  return (
    <>
      <input
        ref={bulkUploadInputRef}
        onChange={handleBulkUpload}
        type="file"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        className="w-0 h-0 max-w-0 max-h-0 p-0 m-0 overflow-hidden opacity-0"
      />

      <div
        className={classNames('flex-1 flex flex-col overflow-auto', {
          'ml-6': type === 'reports' && checkRole(),
        })}
      >
        <Formik
          initialValues={initialValues!}
          onSubmit={handleSave}
          innerRef={formikRef}
          enableReinitialize={true}
          validationSchema={validationSchema}
          validateOnBlur={false}
          validateOnChange={false}
          validateOnMount={false}
        >
          <Form className="flex flex-col flex-1 overflow-auto" id="management-tool-form">
            <div className="flex justify-between items-center gap-4">
              <CorText variant={TextVariant.HEADING_2_BOLD}>
                <h2>
                  <T keyName={`managementTool.${name}`}>{name}</T>
                </h2>
              </CorText>
              {renderManagementToolHeader()}
            </div>

            {!!pharmacyLicenceWarning && (
              <Alert
                text={t(
                  'managementTool.hasExpiredContractsAlert',
                  `${pharmacyLicenceWarning} farmacii au contractele expirate sau vor expira în următoarele 60 de zile`,
                  {
                    count: pharmacyLicenceWarning,
                  },
                )}
                className="h-fit w-full mt-4 flex-row justify-between"
                status={AlertStatus.DANGER}
                firstButton={
                  resetFilter
                    ? t('managementTool.viewAll', 'Vezi toate famaciile')
                    : t('managementTool.viewExpiring', 'Vezi farmaciile ce expira')
                }
                withButton={'inline'}
                firstButtonClick={() => showExpiringPharmacies()}
              />
            )}
            <div className="mt-4 overflow-auto">{childElement}</div>
            <>{modalElement}</>
          </Form>
        </Formik>
        <ToastContainer />
      </div>
    </>
  );
};

export default memo(ManagementTool);
