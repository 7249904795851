import { CorCheckbox, CorText } from '@e-reteta/react-design-system';
import React, { FC, memo, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { T } from '@tolgee/react';
// import dayjs from 'dayjs';

import { HandwrittenCardProps } from './interface';
import { CheckboxSize, TextVariant } from '../../../enums/design-system.enum';
import { formatDate } from '../../../utils/formatDate';
// import { formatTimeDifference } from '../../../utils/formatTimeLeft';

const HandwrittenCard: FC<HandwrittenCardProps> = ({
  recipe,
  hasCheckbox,
  checkboxClick,
  isChecked,
  index,
}) => {
  const navigate = useNavigate();

  const prescription = useMemo(() => recipe, [recipe]);

  const navigateTo = () => {
    navigate(
      `/patient/${prescription.patientIdentifier}/prescription/${prescription.id}?${
        hasCheckbox
          ? 'print=true&clone=true&pharmacist=true&unsigned-recipe=true'
          : 'view=true&doctor=true'
      }`,
    );
  };

  // const formatedTime = formatTimeDifference(dayjs(prescription.createdAt).add(5, 'd'));

  return (
    <div
      key={prescription.id}
      className="flex flex-col w-full hover:bg-surface-hover gap-3 p-3 cursor-pointer bg-background-primary"
    >
      <section className="flex flex-col">
        <div className="flex justify-between">
          <CorText
            variant={TextVariant.LABEL_2_REGULAR}
            className="flex-1"
            onClick={() => navigateTo}
          >
            <span className="text-content-muted">
              <T keyName="handWrittenTable.patientName">Nume Prenume pacient</T>
            </span>
          </CorText>
          {hasCheckbox ? (
            <CorCheckbox className="pr-3" size={CheckboxSize.MD}>
              <input
                onChange={() => (checkboxClick ? checkboxClick(recipe.id) : null)}
                type="checkbox"
                id={index?.toString()}
                name="pers"
                checked={isChecked?.includes(recipe.id)}
              />
            </CorCheckbox>
          ) : null}
        </div>

        <CorText variant={TextVariant.LABEL_2_MEDIUM} onClick={() => navigateTo}>
          <span className="text-content-default">
            {prescription?.patient.lastName + ' ' + prescription?.patient.firstName}
          </span>
        </CorText>
      </section>
      <section className="flex flex-col" onClick={() => navigateTo}>
        <CorText variant={TextVariant.LABEL_2_REGULAR}>
          <span className="text-content-muted">
            <T keyName="handWrittenTable.diagnostic">Diagnosticul</T>
          </span>
        </CorText>
        <CorText variant={TextVariant.LABEL_2_MEDIUM}>
          <span className="text-content-default">{prescription?.diagnosticCode}</span>
        </CorText>
      </section>
      <section className="flex flex-col" onClick={() => navigateTo}>
        <CorText variant={TextVariant.LABEL_2_REGULAR}>
          <span className="text-content-muted">
            <T keyName="handWrittenTable.date">Data Introducerii in Sistem</T>
          </span>
        </CorText>
        <CorText variant={TextVariant.LABEL_2_MEDIUM}>
          <span className="text-content-default">{formatDate(prescription.createdAt)}</span>
        </CorText>
      </section>
      {/*<section className="flex flex-col" onClick={() => navigateTo}>*/}
      {/*  <CorText variant={TextVariant.LABEL_2_REGULAR}>*/}
      {/*    <span className="text-content-muted">*/}
      {/*      <T keyName="handWrittenTable.signTime">Timpul ramas de semnare</T>*/}
      {/*    </span>*/}
      {/*  </CorText>*/}
      {/*  <CorText variant={TextVariant.LABEL_2_MEDIUM}>*/}
      {/*    <span className="text-content-default">{formatedTime}</span>*/}
      {/*  </CorText>*/}
      {/*</section>*/}
    </div>
  );
};

export default memo(HandwrittenCard);
