import { FC } from 'react';

const COLUMNS = [
  {
    text: 'Nr',
    value: '1.',
    width: 3.491,
  },
  {
    text: 'Cod diagnostic',
    width: 10.224,
  },
  {
    text: 'Tratament',
    width: 9.476,
  },
  {
    text: 'DCI medicament/ dispozitiv medical',
    width: 16.957,
  },
  {
    text: 'Calea de administrare',
    width: 10.972,
  },
  {
    text: 'Doza',
    width: 7.73,
  },
  {
    text: 'Cantitatea UM',
    width: 9.725,
  },
  {
    text: 'Mod de administrare',
    width: 11.72,
  },
  {
    text: 'Durata tratament',
    width: 9.226,
  },
  {
    text: 'Valabilitatea prescripției',
    width: 11.097,
  },
];

const Table: FC = () => {
  return (
    <div
      className="flex border border-solid border-border-selected box-border h-[77px] max-h-[77px] text-center divide-x
        divide-y-0 divide-solid divide-border-selected my-6 text-xs w-full"
    >
      {COLUMNS.map(({ width, text, value }) => (
        <div
          key={text}
          style={{ width: `${width}%` }}
          className="flex flex-col items-center justify-center"
        >
          <div className="px-1 flex items-center justify-center h-10 max-h-10">
            <span className="text-content-default font-bold">{text}</span>
          </div>
          <div className="border-0 border-t border-solid border-t-border-selected h-[38px] px-1 flex items-center justify-center w-full">
            {value && <span className="text-content-default p-1">{value}</span>}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Table;
