import Api from '../axios/api';
import { Prescription } from '../types/Prescription';

export interface QueryParams {
  patientId?: string;
  diagnosticCodes?: string[];
  statuses?: string[];
  limit?: number;
  skip?: number;
  createdAtRangeInDays?: number;
  isOnlyPrescribedByDoctor?: boolean;
  doctorIDNP?: string;
  orderBy?: { createdAt: string };
  doctorSpeciality?: string;
}

interface GetDoctorPrescriptionResponse {
  data?: {
    items?: Prescription[];
    count: number;
  };
}

export class DoctorPrescriptionsServices {
  static getPrescriptions(query?: QueryParams): Promise<GetDoctorPrescriptionResponse> {
    return Api.get(`/v1/doctor/prescriptions`, { params: query });
  }
  static getMyPrescriptions(query?: QueryParams): Promise<GetDoctorPrescriptionResponse> {
    return Api.get(`/v1/doctor/prescriptions/my`, {
      params: query,
    });
  }
}
