import { SVGProps } from 'react';

const Help = (props: SVGProps<any>) => {
  return (
    <svg width="30" height="30" viewBox="0 0 43 42" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M22.6003 28.3425C22.1183 28.3685 21.975 28.2513 22.0011 27.7432C22.0401 26.5708 22.0141 23.8278 22.0141 22.6684C22.0141 21.483 22.0141 20.2846 22.0141 19.0991C22.0141 18.4218 21.7015 18.0961 21.0371 18.057C20.516 18.031 19.995 18.031 19.4739 18.057C19.1222 18.07 18.7965 18.2003 18.6272 18.552C18.3015 19.2294 18.7574 19.8417 19.6432 19.8156C20.2034 19.7896 20.3206 19.985 20.3076 20.506C20.2685 22.2386 20.2946 25.5417 20.2946 27.2743C20.2946 28.2252 20.2946 28.2252 19.3827 28.3164C18.8747 28.3685 18.562 28.6811 18.549 29.1501C18.536 29.6191 18.7835 30.0099 19.3176 30.0229C20.5551 30.0489 21.7926 30.0489 23.0171 30.0229C23.4731 30.0099 23.7076 29.6972 23.7206 29.2413C23.7466 28.629 23.3949 28.3034 22.6003 28.3425Z" />
      <path d="M21.1544 15.4913C22.0923 15.4913 22.8869 14.6836 22.9 13.7587C22.9 12.8469 22.0923 12.0262 21.1674 12.0001C20.2295 11.9871 19.3958 12.8339 19.4088 13.7848C19.4219 14.7227 20.2035 15.4913 21.1544 15.4913Z" />
    </svg>
  );
};

export default Help;
