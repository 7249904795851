import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { CorButton, CorRadioButton, CorText } from '@e-reteta/react-design-system';
import hash from 'object-hash';
import { T } from '@tolgee/react';
import classNames from 'classnames';

import { ButtonVariant, TextVariant } from '../../enums/design-system.enum';
import SignOut from '../../icons/SignOut';
import useWindowWidth from '../../hooks/use-window-width';
import Hospital from '../../icons/Hospital';
import { AuthServices, Company, SelectCompany, User } from '../../services/auth.services';
import { AuthContext } from '../../context/context';
import { LoaderContext } from '../../context/loader/context';
import Api from '../../axios/api';
import { LanguagePicker } from '../../components';
import { CompanyTypeEnum } from '../../enums/company-type.enum';
import { getMainRoute } from '../../context/provider';

const SelectInstitution: FC = () => {
  const navigate = useNavigate();
  const { isMobile } = useWindowWidth();
  const { user, logout } = useContext(AuthContext);
  const { setLoading } = useContext(LoaderContext);

  const [companies, setCompanies] = useState<Company[]>([]);
  const [selectedOption, setSelectedOption] = useState<SelectCompany>();

  const navigateToMainPage = useCallback(
    (route: string) => {
      if (!route) {
        return;
      }
      navigate(route);
    },
    [navigate],
  );

  const selectCompany = useCallback(
    async (selected?: SelectCompany) => {
      if (selected && !selectedOption) {
        return;
      }
      setLoading(true);
      const { data } = await AuthServices.setActiveCompany(selected || selectedOption!);
      if (data) {
        localStorage.setItem('access_token', data.accessToken);
        localStorage.setItem('refresh_token', data.refreshToken);
        Api.defaults.headers['Authorization'] = `Bearer ${data.accessToken}`;
        const user: User = jwtDecode(data.accessToken);
        const selectedCompany = user?.companies?.find((company) => company.isActive === true);

        if (selectedCompany) {
          localStorage.setItem(
            'is_active',
            `${selectedCompany.code}:${selectedCompany.speciality}`,
          );
          const route = getMainRoute(selectedCompany);
          navigateToMainPage(route);
          setTimeout(() => setLoading(false), 0);
          return;
        }
      }
      setLoading(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [navigateToMainPage, selectedOption, setLoading, getMainRoute],
  );

  const getCompaniesDetails = useCallback(async () => {
    if (!user) {
      return;
    }
    const codes = user?.companies?.map((company: Company) => company.code || company.idno) || [];
    const [institutions, pharmacies] = await Promise.all([
      AuthServices.getInstitutions(codes),
      AuthServices.getPharmacies(codes),
    ]);
    user?.companies?.forEach((company) => {
      if (
        company.roles.indexOf('OPERATOR_AT') !== -1 ||
        company.roles.indexOf('ADMIN_CNAM') !== -1
      ) {
        company.name = 'CNAM ADMIN';
        company.isEnabled = true;
        return;
      }
      if (institutions?.data?.items?.length) {
        const inst = institutions.data.items.find((item) => item?.codIms === company.code);
        if (inst?.imsp) {
          company.name = inst?.imsp;
          company.type = CompanyTypeEnum.CLINIC;
          company.isEnabled = true;
        }
      }

      if (pharmacies?.data?.items?.length) {
        const pharm = pharmacies?.data?.items.find((pharmacy) => pharmacy?.code === company.code);
        if (pharm?.name) {
          company.name = pharm?.name;
          company.address = pharm?.address;
          company.type = CompanyTypeEnum.PHARMACY;
          company.isEnabled = true;
        }
      }
    });

    setCompanies(user.companies);
  }, [user]);

  useEffect(() => {
    if (user?.companies?.length === 1) {
      selectCompany({
        code: user?.activeCompany?.code!,
        speciality: user?.activeCompany?.speciality!,
      });
    }
    const selectedCompany = user?.companies?.find((company) => company.isActive === true);
    if (selectedCompany) {
      localStorage.setItem('is_active', selectedCompany.code);
      const route = getMainRoute(selectedCompany);
      navigateToMainPage(route);
      return;
    }

    getCompaniesDetails();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCompaniesDetails, navigate, navigateToMainPage, selectCompany, user, getMainRoute]);

  useEffect(() => {
    const rowStoredCompany = localStorage.getItem('is_active');
    if (!rowStoredCompany) {
      return;
    }

    const companyParts = rowStoredCompany.split(':');
    const storedCompany: SelectCompany = {
      code: companyParts[0],
    };

    if (companyParts.length === 2) {
      storedCompany.speciality = companyParts[1];
    }
    const someCompany = user?.companies?.some?.(
      (company) =>
        company.code === storedCompany.code && company.speciality === storedCompany.speciality,
    );
    if (someCompany) {
      setSelectedOption(storedCompany);
    }
  }, [setLoading, setSelectedOption, user?.companies]);

  const isChecked = (code: string, speciality: string) =>
    selectedOption?.code === code && selectedOption?.speciality === speciality;
  return (
    <div className="flex flex-col bg-background-secondary absolute sm:relative inset-0 sm:h-screen overflow-auto hide-scrollbar gap-6 sm:gap-8 p-4 sm:p-6 sm:pt-4">
      <div className="flex justify-between gap-4">
        <div className="flex flex-col">
          <div className="flex flex-row gap-2 truncate">
            <CorText variant={TextVariant.HEADING_3_BOLD}>
              <span className="text-2xl">{user?.firstName}</span>
            </CorText>
            <CorText variant={TextVariant.HEADING_3_BOLD}>
              <span className="text-2xl">{user?.lastName}</span>
            </CorText>
          </div>
        </div>

        <CorButton className="hidden sm:flex w-[200px] h-[48px]" variant={ButtonVariant.SECONDARY}>
          <button
            onClick={() => logout(true)}
            className="rounded-[24px] w-full h-full flex flex-row items-center justify-center gap-2 px-4 py-3"
          >
            <span>
              <T keyName="selectInstitutuion.signOut">Delogare</T>
            </span>{' '}
            <SignOut width={16} height={16} />
          </button>
        </CorButton>
      </div>
      <div className="flex flex-col gap-8 justify-between h-full">
        <div className="flex flex-col gap-4">
          <CorText variant={TextVariant.HEADING_4_BOLD}>
            <p className="text-content-default">
              <T keyName="selectInstitutuion.selectInstitutuion">Selecteaza Institutia</T>
            </p>
          </CorText>
          <div className="flex flex-col xs:flex-row gap-2 sm:gap-4 border-t-[1px] border-t-border-default border-solid border-0 pt-4 flex-wrap">
            {companies?.map(({ name, code, idno, address, speciality, type, isEnabled }) => {
              code = code || idno;
              return (
                <div
                  key={hash(`${code}+${speciality}`)}
                  className={classNames(
                    'flex flex-col gap-8 w-full xs:w-[240px] bg-primary border border-solid rounded-[18px] justify-between px-8 py-6 cursor-pointer',
                    {
                      'border-border-success-default hover:border-border-success-default':
                        isChecked(code, speciality),
                      'border-border-default hover:border-border-hover': !isChecked(
                        code,
                        speciality,
                      ),
                      'opacity-50 pointer-events-none': !isEnabled,
                    },
                  )}
                  onClick={() => setSelectedOption({ code, speciality })}
                >
                  <div className="flex justify-between items-center">
                    <Hospital />
                    <CorRadioButton isChecked={isChecked(code, speciality)} disabled={false}>
                      <input
                        type="radio"
                        name={name}
                        id={code}
                        checked={false}
                        onChange={() => setSelectedOption({ code, speciality })}
                      />
                    </CorRadioButton>
                  </div>
                  <div>
                    <CorText variant={TextVariant.HEADING_4_MEDIUM}>
                      <p className="text-content-default">{name}</p>
                    </CorText>
                    {address && type === CompanyTypeEnum.PHARMACY ? (
                      <CorText variant={TextVariant.LABEL_2_REGULAR}>
                        <div className="text-content-muted">{address && address}</div>
                      </CorText>
                    ) : null}
                    {speciality && type === CompanyTypeEnum.CLINIC ? (
                      <CorText variant={TextVariant.LABEL_2_REGULAR}>
                        <div className="text-content-muted">
                          <T keyName={`selectInstitution.${speciality}`}>{speciality}</T>
                        </div>
                      </CorText>
                    ) : null}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div
          className={classNames('flex gap-2', {
            'flex-col items-center': isMobile,
            'justify-between': !isMobile,
          })}
        >
          <LanguagePicker />
          <div className="flex sm:hidden h-full w-full items-end justify-center">
            <CorButton className="w-full xs:w-[300px] h-[48px]" variant={ButtonVariant.SECONDARY}>
              <button
                onClick={() => logout(true)}
                className="rounded-[24px] w-full h-full flex flex-row items-center justify-center gap-2 px-4 py-3"
              >
                <span>
                  <T keyName="selectInstitutuion.signOut">Delogare</T>
                </span>
                <SignOut width={16} height={16} />
              </button>
            </CorButton>
          </div>
          <CorButton
            className="w-full xs:w-[300px] h-[48px]"
            variant={ButtonVariant.PRIMARY}
            onClick={() => selectCompany()}
          >
            <button
              disabled={selectedOption?.code === ''}
              className="rounded-[24px] w-full h-full flex flex-row items-center gap-2 px-4 py-3"
            >
              <T keyName="selectInstitutuion.continue">Continua</T>
            </button>
          </CorButton>
        </div>
      </div>
    </div>
  );
};

export default SelectInstitution;
