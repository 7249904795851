import React, { memo, useContext, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { CorBox, CorText } from '@e-reteta/react-design-system';

import { TextVariant } from '../../enums/design-system.enum';
import Accordion from '../../components/Reports/Accordion';
import { ReportType } from '../../components/Reports/report-type.enum';
import Annex6 from '../../components/Reports/Annex6';
import Annex11 from '../../components/Reports/Annex11';
import { T, useTranslate } from '@tolgee/react';
import { Form, Formik, FormikProps } from 'formik';
import Annex1 from '../../components/Reports/Annex1';
import Annex7 from '../../components/Reports/Annex7';
import Annex2 from '../../components/Reports/Annex2';
import Annex8ForCNAM from '../../components/Reports/Annex8ForCNAM';
import Annex9 from '../../components/Reports/Annex9';
import Annex10DCI from '../../components/Reports/Annex10DCI';
import Annex10DC from '../../components/Reports/Annex10DC';
import Annex12 from '../../components/Reports/Annex12';
import Report126 from '../../components/Reports/Report126';
import Annex13 from '../../components/Reports/Annex13';
import { AuthContext } from '../../context/context';
import { AdminRolesEnum } from '../../constants/roles.enum';
import { LoaderContext } from '../../context/loader/context';
import Annex3 from '../../components/Reports/Annex3';

const AdminReports = () => {
  const formikRef = useRef<FormikProps<{}> | null>(null);
  const { t } = useTranslate();
  const { hasRole } = useContext(AuthContext);
  const { setLoading } = useContext(LoaderContext);

  const handleSave = async () => {
    formikRef.current?.resetForm();
  };

  useEffect(() => {
    setTimeout(() => setLoading(false), 0);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const adminReports = (
    <>
      <Accordion
        report="report126"
        name={t('managementToolReports.report126', 'Raport 1/26')}
        type={ReportType.REPORT126}
      >
        <Report126 />
      </Accordion>
      <Accordion
        report="annex1ForDrugs"
        name={t('managementToolReports.annex1ForDrugs', 'Raport 1 - Varianta pentru medicamente')}
        type={ReportType.ANNEX1_FOR_DRUGS}
      >
        <Annex1 report="annex1ForDrugs" />
      </Accordion>
      <Accordion
        report="annex1ForDevices"
        name={t('managementToolReports.annex1ForDevices', 'Raport 1 - Varianta pentru dispozitive')}
        type={ReportType.ANNEX1_FOR_DEVICES}
      >
        <Annex1 report="annex1ForDevices" />
      </Accordion>
      <Accordion
        report="annex2"
        name={t('managementToolReports.annex2', 'Raport per grup DCI/Denumire dispozitiv medical')}
        type={ReportType.ANNEX2}
      >
        <Annex2 />
      </Accordion>
      <Accordion
        report="annex3ForDrugs"
        name={t('managementToolReports.annex3ForDrugs', 'Raport DC Medicamente eliberate')}
        type={ReportType.ANNEX3_FOR_DRUGS}
      >
        <Annex3 report="annex3ForDrugs" />
      </Accordion>
      <Accordion
        report="annex3ForDevices"
        name={t(
          'managementToolReports.annex3ForDevices',
          'Raport DC Dispozitive Medicale eliberate',
        )}
        type={ReportType.ANNEX3_FOR_DEVICES}
      >
        <Annex3 report="annex3ForDevices" />
      </Accordion>
      <Accordion
        report="annex4"
        name={t('managementToolReports.annex4', 'Executarea fondurilor AOAM per IMS')}
        type={ReportType.ANNEX4}
      >
        <Annex1 report="annex4" />
      </Accordion>
      <Accordion
        report="annex5"
        name={t('managementToolReports.annex5', 'Raport pentru Ministerul Finantelor')}
        type={ReportType.ANNEX5}
      />
      <Accordion
        report="annex6"
        name={t(
          'managementToolReports.annex6',
          'Raport descifrat per farmacii conform prevederii Legii 1585',
        )}
        type={ReportType.ANNEX6}
      >
        <Annex6 />
      </Accordion>
      <Accordion
        report="annex7"
        name={t('managementToolReports.annex7', 'Raport generalizat per Medici prescriptori')}
        type={ReportType.ANNEX7}
      >
        <Annex7 />
      </Accordion>
      <Accordion
        report="annex8ForCnam"
        name={t('managementToolReports.annex8ForCnam', 'Raport descifrat')}
        type={ReportType.ANNEX8_FOR_CNAM}
      >
        <Annex8ForCNAM />
      </Accordion>
      <Accordion
        report="annex9"
        name={t(
          'managementToolReports.annex9',
          'Raport top pacienti beneficiari de medicamente si dispozitive medicale compensate',
        )}
        type={ReportType.ANNEX9}
      >
        <Annex9 />
      </Accordion>
      <Accordion
        report="annex10InternationalCode"
        name={t(
          'managementToolReports.annex10InternationalCode',
          'Raport per DCI selectat (Prescriere)',
        )}
        type={ReportType.ANNEX10_INTERNATIONAL_CODE}
      >
        <Annex10DCI />
      </Accordion>
      <Accordion
        report="annex10CommercialCode"
        name={t(
          'managementToolReports.annex10CommercialCode',
          'Raport per DC selectat (Prescriere) ',
        )}
        type={ReportType.ANNEX10_COMMERCIAL_CODE}
      >
        <Annex10DC />
      </Accordion>
      <Accordion
        report="annex11"
        name={t('managementToolReports.annex11', 'Raport per DC selectat (Eliberare)')}
        type={ReportType.ANNEX11}
      >
        <Annex11 />
      </Accordion>
      <Accordion
        report="annex12"
        name={t('managementToolReports.annex12', 'Raport dinamic')}
        type={ReportType.ANNEX12}
        datePickerPositionTop={true}
      >
        <Annex12 />
      </Accordion>
      <Accordion
        report="annex13"
        name={t('managementToolReports.annex13', 'Raport numar de pacienti noi')}
        type={ReportType.ANNEX13}
        datePickerPositionTop={true}
      >
        <Annex13 />
      </Accordion>
    </>
  );

  const demReports = (
    <>
      <Accordion
        report="annex4"
        name={t('managementToolReports.annex4', 'Executarea fondurilor AOAM per IMS')}
        type={ReportType.ANNEX4}
      >
        <Annex1 report="annex4" />
      </Accordion>
      <Accordion
        report="annex7"
        name={t('managementToolReports.annex7', 'Raport generalizat per Medici prescriptori')}
        type={ReportType.ANNEX7}
      >
        <Annex7 />
      </Accordion>
      <Accordion
        report="annex8ForCnam"
        name={t('managementToolReports.annex8ForCnam', 'Raport descifrat')}
        type={ReportType.ANNEX8_FOR_CNAM}
      >
        <Annex8ForCNAM />
      </Accordion>
      <Accordion
        report="annex9"
        name={t(
          'managementToolReports.annex9',
          'Raport top pacienti beneficiari de medicamente si dispozitive medicale compensate',
        )}
        type={ReportType.ANNEX9}
      >
        <Annex9 />
      </Accordion>
    </>
  );

  const controlUnitReports = (
    <>
      <Accordion
        report="annex1ForDrugs"
        name={t('managementToolReports.annex1ForDrugs', 'Raport 1 - Varianta pentru medicamente')}
        type={ReportType.ANNEX1_FOR_DRUGS}
      >
        <Annex1 report="annex1ForDrugs" />
      </Accordion>
      <Accordion
        report="annex1ForDevices"
        name={t('managementToolReports.annex1ForDevices', 'Raport 1 - Varianta pentru dispozitive')}
        type={ReportType.ANNEX1_FOR_DEVICES}
      >
        <Annex1 report="annex1ForDevices" />
      </Accordion>
      <Accordion
        report="annex4"
        name={t('managementToolReports.annex4', 'Executarea fondurilor AOAM per IMS')}
        type={ReportType.ANNEX4}
      >
        <Annex1 report="annex4" />
      </Accordion>
      <Accordion
        report="annex6"
        name={t(
          'managementToolReports.annex6',
          'Raport descifrat per farmacii conform prevederii Legii 1585',
        )}
        type={ReportType.ANNEX6}
      >
        <Annex6 />
      </Accordion>
    </>
  );

  return (
    <Formik
      onSubmit={handleSave}
      innerRef={formikRef}
      enableReinitialize={true}
      validateOnBlur={false}
      validateOnChange={false}
      validateOnMount={false}
      initialValues={{}}
    >
      <Form className="flex flex-col flex-1 overflow-auto" id="management-tool-form">
        <div className={classNames('flex-1 flex flex-col overflow-auto')}>
          <div className="flex flex-col flex-1 overflow-auto" id="management-tool-form">
            <div className="flex justify-between items-center gap-4">
              <CorText variant={TextVariant.HEADING_2_BOLD}>
                <h2>
                  <T keyName={'reports.reportsText'}>Reports</T>
                </h2>
              </CorText>
            </div>

            <div className="mt-4 overflow-auto">
              <CorBox className="bg-white p-2 xs:p-6 h-[calc(100vh-88px)] overflow-auto">
                {(hasRole(AdminRolesEnum.ADMIN_CNAM) || hasRole(AdminRolesEnum.OPERATOR_AT)) &&
                  adminReports}
                {hasRole(AdminRolesEnum.CNAM_DEM) && demReports}
                {hasRole(AdminRolesEnum.CNAM_CONTROL_UNIT) && controlUnitReports}
              </CorBox>
            </div>
          </div>
        </div>
      </Form>
    </Formik>
  );
};

export default memo(AdminReports);
