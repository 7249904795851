import { CorToggle } from '@e-reteta/react-design-system';

import { ToggleCellProps } from './interface';

const ToggleCell = (props: ToggleCellProps) => {
  const { value, widthType, width } = props;
  return (
    <div className="shrink-0 pointer-events-none" style={{ width: `${width}${widthType}` }}>
      <CorToggle value={value}>
        <input type="checkbox" value={String(value)} />
      </CorToggle>
    </div>
  );
};

export default ToggleCell;
