import { Outlet, Route } from 'react-router-dom';
import React, { FC, useContext } from 'react';
import { useTranslate } from '@tolgee/react';

import { Pharmacy, Prescription } from '../../pages';
import Patient from '../../pages/Patient';
import PharmacyGuardedRoute from '../Guards/PharmacyGuardedRoute';
import PharmacyReports from '../../pages/Reports/PharmacyReports';
import UnsignedDispenses from '../../pages/UnsignedDispenses';
import ViewPrescriptionPharmacist from '../../pages/ViewPrescription/Pharmacist';
import DispensePrescription from '../../pages/ViewPrescription/PharmacistDispensing';
import PaymentPage from '../../pages/PaymentPage';
import CloseDispenses from '../../pages/CloseDispenses';
import HolographicRecipe from '../../pages/HolographicRecipe';
import EInvoice from '../../pages/EInvoice';
import { MenuItemInterface } from './menuItem.interface';
import { IconName } from '../../enums/design-system-icons.enum';
import LeftSideManu from '../../components/LeftSideMenu';
import PharmacyIntegration from '../../pages/Integrations/PharmacyIntegrarion';
import { AuthContext } from '../../context/context';
import { PharmacyRolesEnum } from '../../constants/roles.enum';
import HeadPharmacyReports from '../../pages/Reports/HeadPharmacyReports';
import BranchPharmacyReports from '../../pages/Reports/BranchPharmacyReports';
import { searchPatientLocations, unsignedDispensesLocations } from '../../constants/locations';
import PharmacistSignDispense from '../../pages/ViewPrescription/PharmacistSignDispense';

const PharmacyWrapper: FC = () => {
  const { t } = useTranslate();
  const { hasRole } = useContext(AuthContext);
  const items: MenuItemInterface[] = [];
  if (hasRole(PharmacyRolesEnum.PHARMACIST_SUB) || hasRole(PharmacyRolesEnum.PHARMACIST)) {
    items.push(
      {
        path: '/pharmacy/search',
        name: t('pharmacyWrapper.search', 'Caută Pacient'),
        iconName: IconName.SEARCH,
        additionalPaths: searchPatientLocations,
      },
      {
        path: '/unsigned-dispenses',
        name: t('pharmacyWrapper.unsignedDispenses', 'Eliberări Nesemnate'),
        iconName: IconName.RECEIPT,
        hasBadge: true,
        additionalPaths: unsignedDispensesLocations,
      },
    );
  }
  if (!hasRole(PharmacyRolesEnum.PSF_BRANCH) && !hasRole(PharmacyRolesEnum.PSF_HEAD)) {
    items.push({
      path: '/pharmacy/reports',
      name: t('pharmacyWrapper.myReports', 'Rapoartele mele'),
      iconName: IconName.TIME_SCHEDULE,
    });
  }
  if (hasRole(PharmacyRolesEnum.PSF_BRANCH)) {
    items.push({
      path: '/pharmacy/branch-reports',
      name: t('pharmacyWrapper.reports', 'Rapoarte'),
      iconName: IconName.TIME_SCHEDULE,
    });
  }
  if (hasRole(PharmacyRolesEnum.PSF_HEAD)) {
    items.push({
      path: '/pharmacy/e-facture',
      name: t('pharmacyWrapper.eFacture', 'E-Factura'),
      iconName: IconName.INCOME,
    });
    items.push({
      path: '/pharmacy/head-reports',
      name: t('pharmacyWrapper.reports', 'Rapoarte'),
      iconName: IconName.TIME_SCHEDULE,
    });
  }
  if (hasRole(PharmacyRolesEnum.PSF_TECH)) {
    items.push({
      path: '/pharmacy/integrations',
      name: t('pharmacyWrapper.integrations', 'Integrări'),
      iconName: IconName.SETTINGS,
    });
  }
  return (
    <PharmacyGuardedRoute>
      <LeftSideManu items={items}>
        <Outlet />
      </LeftSideManu>
    </PharmacyGuardedRoute>
  );
};
export const PharmacyRoutes = (
  <Route path="" element={<PharmacyWrapper />}>
    <Route path="/pharmacy/reports" element={<PharmacyReports />} />
    <Route path="/pharmacy/head-reports" element={<HeadPharmacyReports />} />
    <Route path="/pharmacy/branch-reports" element={<BranchPharmacyReports />} />
    <Route path="/pharmacy/search" element={<Pharmacy />} />
    <Route path="/unsigned-dispenses" element={<UnsignedDispenses />} />
    <Route path="/patient/:idnp" element={<Patient />} />
    <Route path="/patient/:idnp/prescription/new" element={<Prescription />} />
    <Route
      path="/patient/:idnp/prescription/:prescriptionId"
      element={<ViewPrescriptionPharmacist dispense={true} />}
    />
    <Route
      path="/patient/:idnp/prescription/:prescriptionId/sign-dispense/:dispenseId"
      element={<PharmacistSignDispense />}
    />
    <Route path="/patient/:idnp/dispenses/:prescriptionId" element={<DispensePrescription />} />
    <Route path="/patient/:idnp/payment/:prescriptionId" element={<PaymentPage />} />
    <Route path="/patient/:idnp/dispenses/:dispenseId/close" element={<CloseDispenses />} />
    <Route path="/holographic-recipe/:recipeId" element={<HolographicRecipe />} />
    <Route path="/pharmacy/integrations" element={<PharmacyIntegration />} />
    <Route path="/pharmacy/e-facture" element={<EInvoice />} />
  </Route>
);
