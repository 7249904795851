import { WheelEvent } from 'react';

const disableScrollOnInput = (e: WheelEvent) => {
  // Prevent the input value change
  const target = e.target as HTMLInputElement;
  target.blur();

  // Prevent the page/container scrolling
  e.stopPropagation();

  // Refocus immediately, on the next tick (after the current
  setTimeout(() => {
    target.focus();
  }, 0);
};

export default disableScrollOnInput;
