import Api from '../axios/api';

export interface InstitutionDto {
  codIms: string;
  imsp: string;
  idno: string;
  type: string;
  codAt: number;
  licenseValidity: string;
  createdAt: string;
  updatedAt: string;
  district?: string;
}

export interface GetInstitutionsResponse {
  data: {
    items?: InstitutionDto[];
    count: number;
  };
}

export interface MessageResponse {
  message: string;
}

export interface InstitutionSearchQuery {
  codIms?: string;
  limit?: number;
  skip?: number;
  orderBy?: any;
}

export class InstitutionService {
  static getAll(
    target: string,
    query: InstitutionSearchQuery,
    search?: string,
  ): Promise<GetInstitutionsResponse> {
    return Api.get(`/v1/${target}/institutions`, {
      params: { ...query, search },
    });
  }

  static delete(codIms: string): Promise<MessageResponse> {
    return Api.delete(`/v1/admin/institutions/${codIms}`);
  }

  static bulkUpload(data: FormData): Promise<MessageResponse> {
    return Api.post(`/v1/admin/institutions/upload`, data, {
      params: { institutionType: 'clinic' },
    });
  }

  static create(body: InstitutionDto): Promise<MessageResponse> {
    return Api.post(`/v1/admin/institutions`, body);
  }

  static update(codIms: string, body: InstitutionDto): Promise<InstitutionDto> {
    return Api.patch(`/v1/admin/institutions/${codIms}`, body);
  }

  static download(type: string) {
    return Api.get(`/v1/admin/institutions/download.${type}`, {
      responseType: 'blob',
    });
  }
}
