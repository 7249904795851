import { FC, SVGProps } from 'react';

const Shield: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        opacity="0.3"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.33398 5.33341L15.6321 3.40252C15.8757 3.35685 16.1256 3.35685 16.3692 3.40252L26.6673 5.33341V17.9034C26.6673 21.696 24.7135 25.2212 21.4973 27.2313L16.5306 30.3355C16.2064 30.5382 15.7949 30.5382 15.4707 30.3355L10.504 27.2313C7.2878 25.2212 5.33398 21.696 5.33398 17.9034L5.33398 5.33341Z"
        fill="#343841"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.8997 19.6667C14.5803 19.6667 14.2609 19.5389 14.0053 19.2833L11.4497 16.7278C10.9386 16.2167 10.9386 15.45 11.4497 14.9389C11.9609 14.4278 12.7914 14.4278 13.2386 14.9389L14.8997 16.6L19.1164 12.3833C19.6275 11.8722 20.3942 11.8722 20.9053 12.3833C21.4164 12.8944 21.4164 13.6611 20.9053 14.1722L15.7942 19.2833C15.5386 19.5389 15.2192 19.6667 14.8997 19.6667Z"
        fill="#343841"
      />
    </svg>
  );
};

export default Shield;
