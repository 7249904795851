import { CorText } from '@e-reteta/react-design-system';
import { useNavigate } from 'react-router-dom';
import { T } from '@tolgee/react';
import { FC } from 'react';

import { NavigationItemProps } from './navigation-item.interface';
import { TextVariant } from '../../../../enums/design-system.enum';

const NavigationItem: FC<NavigationItemProps> = ({
  icon,
  itemKey,
  selected,
  setSelected,
  disabled,
  opened,
  url,
}) => {
  const navigate = useNavigate();

  const handleSelected = () => {
    if (!disabled) {
      setSelected && setSelected(itemKey);
      navigate(`/management-tool/${url}`);
    }
  };

  const renderClasses = (
    disabledClasses: string,
    selectedClasses: string,
    hoverClasses: string,
  ) => {
    if (disabled) {
      return disabledClasses;
    } else {
      if (selected) {
        return selectedClasses;
      } else {
        return hoverClasses;
      }
    }
  };

  return (
    <div
      className={`group flex items-center gap-3 px-4 ${
        opened ? 'py-3.5' : 'py-4 justify-center'
      } rounded ${renderClasses(
        'bg-background-secondary cursor-default',
        'bg-surface-success-default cursor-default',
        'hover:bg-surface-success-default cursor-pointer',
      )}
      `}
      onClick={handleSelected}
    >
      {icon}
      {opened ? (
        <CorText variant={TextVariant.LABEL_2_MEDIUM}>
          <div
            className={renderClasses(
              'text-content-disabled',
              'text-content-default',
              'text-surface-neutral-inverted-hover',
            )}
          >
            <T keyName={`navigationItem.${itemKey}`}>{itemKey}</T>
          </div>
        </CorText>
      ) : null}
    </div>
  );
};

export default NavigationItem;
