export const VIEW_PAGE_RANGE = 4;

export const DEFAULT_DROPDOWN_OPTION = 10;

export const dropdownOptions = [5, 10, 15, 20, 25];

export const DEFAULT_PAGINATION_DATA = {
  page: 0,
  totalPages: 1,
  itemsPerPage: DEFAULT_DROPDOWN_OPTION,
  totalItems: 0,
};
