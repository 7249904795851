export enum ButtonSize {
  TINY = 'tiny',
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export enum ButtonVariant {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
  LINK = 'link',
  SUBTITLE = 'subtitle',
  GRADIENT = 'gradient',
}

export enum TextVariant {
  HEADING_1_BOLD = 'heading-1-bold',
  HEADING_2_BOLD = 'heading-2-bold',
  HEADING_3_BOLD = 'heading-3-bold',
  HEADING_4_BOLD = 'heading-4-bold',
  HEADING_4_MEDIUM = 'heading-4-medium',
  LABEL_1_REGULAR = 'label-1-regular',
  LABEL_1_MEDIUM = 'label-1-medium',
  LABEL_1_BOLD = 'label-1-bold',
  LABEL_2_REGULAR = 'label-2-regular',
  LABEL_2_MEDIUM = 'label-2-medium',
  LABEL_2_BOLD = 'label-2-bold',
  LABEL_3_MEDIUM = 'label-3-medium',
  BODY_1_REGULAR = 'body-1-regular',
  BODY_1_MEDIUM = 'body-1-medium',
  BODY_1_BOLD = 'body-1-bold',
}

export enum CheckboxSize {
  MD = 'md',
  LG = 'lg',
}

export enum InputIconDisplay {
  LEFT = 'left',
  RIGHT = 'right',
  NONE = 'none',
}

export enum BadgeSize {
  SM = 'sm',
  MD = 'md',
}

export enum BadgeColor {
  GREY = 'grey',
  GREEN = 'green',
  ORANGE = 'orange',
  RED = 'red',
  YELLOW = 'yellow',
  BLUE = 'blue',
}

export enum ChipSize {
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
}

export enum ChipVariant {
  DEFAULT = 'default',
  OUTLINE = 'outline',
}

export enum NavItemVariant {
  DESKTOP = 'desktop',
  TABLET = 'tablet',
}

export enum BoxShadow {
  NONE = 'none',
  XXS = 'xxs',
  XS = 'xs',
  SM = 'sm',
}
