import classNames from 'classnames';

import { Drugs, Help, Question } from '../../../icons';
import { TabEnum } from './tab.enum';
import { TabProps } from './tab.interface';

const Tab = (props: TabProps) => {
  const { selectedTab, setSelectedTab } = props;

  return (
    <div className="flex w-full justify-between px-16 py-2">
      <Question
        onClick={() => setSelectedTab(TabEnum.QUESTION)}
        className={classNames('cursor-pointer', {
          'fill-action-primary-default': selectedTab === TabEnum.QUESTION,
          'fill-surface-neutral-inverted-default hover:fill-black':
            selectedTab !== TabEnum.QUESTION,
        })}
      />
      <Drugs
        onClick={() => setSelectedTab(TabEnum.DRUGS)}
        className={classNames('cursor-pointer', {
          'fill-action-primary-default': selectedTab === TabEnum.DRUGS,
          'fill-surface-neutral-inverted-default hover:fill-black': selectedTab !== TabEnum.DRUGS,
        })}
      />
      <Help
        onClick={() => setSelectedTab(TabEnum.HELP)}
        className={classNames('border-1.5 border-solid rounded-full cursor-pointer', {
          'fill-action-primary-default border-border-success-default': selectedTab === TabEnum.HELP,
          'fill-surface-neutral-inverted-default border-border-selected hover:fill-black hover:border-black':
            selectedTab !== TabEnum.HELP,
        })}
      />
    </div>
  );
};

export default Tab;
