import { FC, SVGProps } from 'react';

const SignOut: FC<SVGProps<SVGSVGElement>> = (props) => {
  const { color = 'currentColor' } = props;
  return (
    <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g opacity="0.3">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.33384 4.66659C9.33384 5.03478 9.63219 5.33325 10.0002 5.33325C10.3682 5.33325 10.6666 5.03478 10.6666 4.66659V3.99992C10.6666 2.52716 9.47321 1.33325 8.00109 1.33325H4.0034C2.53128 1.33325 1.33789 2.52716 1.33789 3.99992V11.9999C1.33789 13.4727 2.53128 14.6666 4.0034 14.6666H8.00571C9.47783 14.6666 10.6712 13.4727 10.6712 11.9999V11.3332C10.6712 10.9651 10.3729 10.6666 10.0048 10.6666C9.63682 10.6666 9.33847 10.9651 9.33847 11.3332V11.9999C9.33847 12.7363 8.74177 13.3333 8.00571 13.3333H4.0034C3.26734 13.3333 2.67065 12.7363 2.67065 11.9999V3.99992C2.67065 3.26354 3.26734 2.66659 4.0034 2.66659H8.00109C8.73715 2.66659 9.33384 3.26354 9.33384 3.99992V4.66659Z"
          fill={color}
        />
        <path
          d="M12.6666 7.33325C13.0348 7.33325 13.3333 7.63173 13.3333 7.99992C13.3333 8.36811 13.0348 8.66659 12.6666 8.66659H5.99993C5.63174 8.66659 5.33326 8.36811 5.33326 7.99992C5.33326 7.63173 5.63174 7.33325 5.99993 7.33325H12.6666Z"
          fill={color}
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5283 6.47149C11.2679 6.21114 11.2679 5.78903 11.5283 5.52868C11.7886 5.26833 12.2107 5.26833 12.4711 5.52868L14.4711 7.52868C14.7314 7.78903 14.7314 8.21114 14.4711 8.47149L12.4711 10.4715C12.2107 10.7318 11.7886 10.7318 11.5283 10.4715C11.2679 10.2111 11.2679 9.78903 11.5283 9.52868L13.0569 8.00008L11.5283 6.47149Z"
        fill={color}
      />
    </svg>
  );
};

export default SignOut;
