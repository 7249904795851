import React, {
  ChangeEvent,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { AxiosError, isAxiosError } from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import dayjs from 'dayjs';
import {
  CorBox,
  CorButton,
  CorCheckbox,
  CorIcon,
  CorInput,
  CorText,
} from '@e-reteta/react-design-system';
import { T, useTranslate } from '@tolgee/react';
import hash from 'object-hash';
import PhoneInput from 'react-phone-number-input';

import {
  ButtonVariant,
  CheckboxSize,
  InputIconDisplay,
  TextVariant,
} from '../../enums/design-system.enum';
import { PrescriptionFormProps } from './interface';
import { IconName, IconSize } from '../../enums/design-system-icons.enum';
import { Alert, DeviceAndDrugDropdown } from '../index';
import { DiagnosticServices } from '../../services/diagnostics.services';
import Check from '../../icons/Check';
import { DeviceOrDrug } from '../DeviceAndDrugDropdown/enum';
import {
  CreateDPrescriptions,
  DPrescriptions,
  GetPrescriptionResponse,
  PrescriptionServices,
  ThirdPerson,
} from '../../services/prescription.services';
import { useGetPatient } from '../../hooks/use-get-patient';
import useWindowWidth from '../../hooks/use-window-width';
import { SignServices } from '../../services/doctorsSign.services';
import ConfirmModal from '../ConfirmModal';
import { AlertStatus } from '../Alert/enum';
import { ReceiptStatus } from '../../enums/ReceiptStatus';
import useQuery from '../../hooks/use-query';
import EinsteinDrawer from '../EinsteinDrawer';
import { HolographPrescription } from '../../types/HolographPrescription';
import { AuthContext } from '../../context/context';
import { Doctor } from '../../types/Doctor';
import { handleError } from '../../utils/handleRequestErrors';
import {
  DiagnosticsResult,
  DoctorData,
  FormikPrescription,
  PaientData,
  PharmacistData,
} from '../../types/CommonTypes';
import { Disease } from '../../types/Disease';
import { Prescription } from '../../types/Prescription';
import { Patient } from '../../types/Patient';
import { PatientData } from '../PatientMedicCard/interface';
import { LoaderContext } from '../../context/loader/context';
import { E164Number } from 'libphonenumber-js/types.cjs';
import classNames from 'classnames';
import useLocalStorage from '../../hooks/use-localstorage';
import { object, string } from 'yup';
import { toast } from 'react-toastify';

const PrescriptionForm: FC<PrescriptionFormProps> = ({ role, limit = 1000 }) => {
  const { prescriptionId, action, idnp } = useParams();
  const query = useQuery();
  const navigate = useNavigate();
  const { isMobile } = useWindowWidth();
  const { user } = useContext(AuthContext);
  const { setLoading } = useContext(LoaderContext);
  const handDrawnPrescriptionId = query?.get('holographId')?.toString();

  const [isShow, setIsShow] = useState<boolean>(false);
  const [diagnosticsResult, setDiagnosticsResult] = useState<Disease[]>([]);
  const [diagnosticCode, setDiagnosticCode] = useState<string>('');
  const [selectedDevices, setSelectedDevices] = useState<string[]>([]);
  const [selectedDrugs, setSelectedDrugs] = useState<string[]>([]);
  const [addNew, setAddNew] = useState<boolean>(false);
  const [isVisible, setVisible] = useState<boolean>(false);
  const [diagnosticValue, setDiagnosticValue] = useState<string>('');
  const [isSelectedValue, setIsSelectedValue] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);
  const [patient] = useGetPatient({ idnp: idnp });
  const [doctor, setDoctor] = useState<Doctor>();
  const [prescription, setPrescription] = useState<Prescription>();
  const [signAfterSave, setSignAfterSave] = useState<boolean>(false);
  const [confirmSign, setConfirmSign] = useState<boolean>(false);
  const [errorMessage, setError] = useState<string>();
  const [signDisabled, setSignDisabled] = useState<boolean>(true);
  const [initialDPrescriptions, setInitialDPrescriptions] = useState<CreateDPrescriptions[]>();
  const [initialLoad, setInitialLoad] = useState<boolean>(false);
  const [addedAtLeastOneDeviceOrDrug, setAddedAtLeastOneDeviceOrDrug] = useState<boolean>(
    action === 'edit' || action === 'clone',
  );
  const [phoneNumber, setPhoneNumber] = useLocalStorage('doctorPhoneNumber');

  const [hPrescription, setHPrescription] = useState<HolographPrescription>();

  const { t } = useTranslate();

  const getHPrescription = async () => {
    const resp = await PrescriptionServices.getHolographPrescriptionById(
      'pharmacy',
      handDrawnPrescriptionId,
    );
    setHPrescription(resp.data);
  };

  useEffect(() => {
    if (handDrawnPrescriptionId) {
      getHPrescription();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handDrawnPrescriptionId]);

  useEffect(() => {
    if (prescriptionId && diagnosticsResult?.length) {
      getElements();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prescriptionId, diagnosticsResult]);

  useEffect(() => {
    if (!user) {
      return;
    }
    if (hPrescription && role === 'pharmacy') {
      setDoctor(hPrescription.doctor);
    } else {
      setDoctor({
        ...user,
        medicalUnit: user.activeCompany?.code!,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, hPrescription, role]);

  const signPrescription = useCallback(
    async (id: string) => {
      try {
        if (role && role === 'doctor') {
          const { data } = await SignServices.sign({ prescriptionId: id }, role);
          if (data?.redirectUrl) {
            window?.location?.replace(data?.redirectUrl);
          }
        }
        if (role && role === 'pharmacy') {
          const { data } = await SignServices.signPharmacy(id);
          if (data?.redirectUrl) {
            window?.location?.replace(data?.redirectUrl);
          }
        }
      } catch (error) {
        if (error instanceof Error || error instanceof AxiosError) {
          setError(error && error?.message);
        }
      }
    },
    [role],
  );

  const createPrescription = useCallback(
    async (
      code: string,
      dPrescriptions: CreateDPrescriptions[],
      thirdPerson: ThirdPerson,
      doctorData: DoctorData,
      patientData: PaientData,
      pharmacistData: PharmacistData,
    ) => {
      let prescriptionId = '';
      try {
        const dbPrescription = await PrescriptionServices.createPrescription(
          {
            doctor: doctorData,
            pharmacist: pharmacistData,
            patient: patientData,
            thirdPerson,
            diagnosticCode: code,
            dPrescriptions,
            hPrescriptionId: query?.get('holographId')?.toString(),
            prescribedAt: dayjs(query?.get('prescribedAt')?.toString()).toISOString(),
          },
          role,
        );
        prescriptionId = dbPrescription.data.id;
        if (signAfterSave) {
          await signPrescription(dbPrescription.data.id);
        } else {
          navigate(
            `/patient/${patientData?.patientIdentifier}/prescription/${dbPrescription.data.id}`,
          );
        }
      } catch (e: any) {
        if (e.response?.status === 409) {
          toast.error(
            t(
              'PrescriptionForm.conflictError',
              'Medicamnetul nu poate fi prescris din cauza unui conflict',
              {
                error: e?.response?.data?.message || e?.toString(),
              },
            ),
          );
          navigate(`/patient/${patientData?.patientIdentifier}`);
          return;
        }
        toast.error(
          t(
            'PrescriptionForm.error',
            'Reteta a fost salvata ca ciorna. Eroare la tnataiva se a semna',
            {
              error: e?.response?.data?.message || e?.toString(),
            },
          ),
        );
        navigate(`/patient/${patientData?.patientIdentifier}/prescription/${prescriptionId}`);
      } finally {
        setConfirmSign(false);
      }
    },
    [signAfterSave, signPrescription, navigate],
  );

  const updatePrescription = useCallback(
    async (
      code: string,
      dPrescriptions: Partial<DPrescriptions>[],
      thirdPerson: ThirdPerson,
      doctor: DoctorData,
    ) => {
      if (prescriptionId) {
        const prescriptionResp = await PrescriptionServices.updatePrescription(prescriptionId, {
          diagnosticCode: code,
          dPrescriptions: dPrescriptions.map((r) => {
            return {
              ...r,
              deviceName: r.deviceName || undefined,
              drugInternationalName: r.drugInternationalName || undefined,
              drugRouteOfAdministration: r.drugRouteOfAdministration || undefined,
              drugDose: r.drugDose || undefined,
            };
          }),
          thirdPerson,
          status:
            prescription?.status === ReceiptStatus.SIGNED
              ? ReceiptStatus.PRESCRIBED
              : prescription?.status,
          doctor,
        });

        setLoading(false);
        if (signAfterSave) {
          signPrescription(prescriptionResp.data.id);
        } else {
          navigate(
            `/patient/${(patient as Patient)?.id}/prescription/${
              prescriptionResp.data.id
            }?doctor=true`,
          );
        }
      }
    },
    [
      navigate,
      (patient as Patient)?.id,
      prescription?.status,
      prescriptionId,
      signAfterSave,
      signPrescription,
    ],
  );

  const handleFormSubmit = useCallback(
    async (values: FormikPrescription) => {
      setLoading(true);
      try {
        let dPrescriptions: Partial<DPrescriptions>[] = [];
        values.dPrescriptions?.forEach((dPrescription) => {
          if (
            (dPrescription.drugInternationalName !== undefined &&
              dPrescription.drugRouteOfAdministration !== undefined &&
              dPrescription.drugDose !== undefined) ||
            dPrescription.deviceName !== undefined
          ) {
            dPrescriptions.push({
              id: dPrescription.id,
              deviceName: dPrescription.deviceName,
              drugInternationalName: dPrescription.drugInternationalName,
              drugRouteOfAdministration: dPrescription.drugRouteOfAdministration,
              drugDose: dPrescription.drugDose,
              quantity: dPrescription.quantity,
              validity: dPrescription.validity,
              signature: dPrescription.signature,
              expiration: dPrescription.expiration,
            });
          }
        });
        let thirdPerson = {};
        if (values.thirdPersonFirstName && values.thirdPersonLastName && values.thirdPersonIDNP) {
          thirdPerson = {
            firstName: values.thirdPersonFirstName,
            lastName: values.thirdPersonLastName,
            idnp: values.thirdPersonIDNP,
          };
        }
        let doctorData = {
          firstName: user?.firstName,
          lastName: user?.lastName,
          medicalUnit: user?.activeCompany?.code,
          doctorIDNP: user?.idnp,
          speciality: user?.activeCompany?.speciality,
          phone: values?.phone,
        };
        let pharmacistData = {};
        if (hPrescription) {
          doctorData = {
            firstName: hPrescription.doctor?.firstName,
            lastName: hPrescription.doctor?.lastName,
            medicalUnit: hPrescription.doctor?.medicalUnit,
            doctorIDNP: hPrescription.doctor?.idnp,
            speciality: hPrescription.doctor?.speciality,
            phone: hPrescription?.doctor?.phone || '',
          };
          pharmacistData = {
            firstName: user?.firstName,
            lastName: user?.lastName,
            medicalUnit: user?.activeCompany?.code,
            idnp: user?.idnp,
          };
        }
        if (!prescriptionId || action === 'clone') {
          const patientData: PatientData = {
            firstName: values.patient?.firstName,
            lastName: values.patient?.lastName,
            institutionCode: values.patient?.institutionCode,
            medicalUnit: values.patient?.institutionCode,
            birthday: values.patient?.birthday?.toString(),
            isInsured: values.patient?.isInsured,
            patientIdentifier: values.patient?.id,
          };
          await createPrescription(
            values.diagnostic,
            dPrescriptions as CreateDPrescriptions[],
            thirdPerson,
            doctorData,
            patientData,
            pharmacistData,
          );
        } else {
          if (prescriptionId && values.diagnostic) {
            await updatePrescription(values.diagnostic, dPrescriptions, thirdPerson, doctorData);
          }
        }

        setPhoneNumber(values.phone);
      } catch (error: any) {
        let message = error && error?.response?.data?.message;
        if (error?.response?.status === 409) {
          message = t('prescriptionForm.conflict', 'Acesta prescrieire e in process de eliberare');
        }
        setError(message);
        toast.error(message);
      } finally {
        setLoading(false);
      }
    },
    [action, createPrescription, prescriptionId, updatePrescription],
  );

  const formik = useFormik({
    validationSchema: object().shape({
      thirdPersonIDNP: string().matches(
        /^[0-9]{13}$/,
        t(
          'prescriptionForm.thirdPartyIDNPError',
          'IDNP must be 13 digits and contain only numbers',
        ),
      ),
      phone: string().required('Numar de contact este obligatoriu'),
    }),
    initialValues: {
      thirdPersonIDNP: '',
      thirdPersonFirstName: '',
      thirdPersonLastName: '',
      diagnostic: '',
      patient: patient as Patient,
      doctor,
      phone: doctor?.phone || phoneNumber,
      dPrescriptions: [
        {
          id: '',
          deviceName: '',
          drugInternationalName: '',
          drugRouteOfAdministration: '',
          drugDose: '',
          validity: '',
          quantity: '',
          type: '',
          commercialName: '',
          remove: false,
          signature: '',
          treatmentDuration: '',
          expiration: 0,
        },
      ],
    },
    onSubmit: (values: FormikPrescription) => {
      handleFormSubmit(values);
    },
    enableReinitialize: true,
  });

  const verifySign = useCallback(async () => {
    setConfirmSign(false);
    if (signAfterSave) {
      handleFormSubmit(formik.values);
    } else {
      if (prescriptionId) {
        signPrescription(prescriptionId);
      }
    }
  }, [formik.values, handleFormSubmit, signAfterSave, signPrescription, prescriptionId]);

  const getElements = useCallback(async () => {
    let resp: GetPrescriptionResponse;
    if (prescriptionId && role && !initialLoad) {
      try {
        setLoading(true);
        resp = await PrescriptionServices.getPrescriptionById(prescriptionId, role);
        setPrescription({
          ...resp.data,
          thirdPerson: {
            firstName: resp.data?.thirdPerson?.firstName || '',
            lastName: resp.data?.thirdPerson?.lastName || '',
            idnp: resp.data?.thirdPerson?.idnp || '',
          },
        });
        formik.setFieldValue('thirdPersonIDNP', resp.data?.thirdPerson?.idnp || '');
        formik.setFieldValue('diagnostic', resp.data.diagnosticCode);
        formik.setFieldValue('phone', resp.data.doctor.phone);
        const diagnostic = diagnosticsResult?.find((r) => r.code === resp.data.diagnosticCode);
        setDiagnosticValue(
          diagnostic ? `${diagnostic?.code} - ${diagnostic?.name}` : resp.data.diagnosticCode,
        );
        setIsSelectedValue(false);
        setIsShow(!!resp.data.thirdPerson?.idnp);
        formik.setFieldValue(
          'dPrescriptions',
          resp.data.dPrescriptions?.map((r: DPrescriptions) => {
            return {
              id: r.id,
              deviceName: r.deviceName,
              validity: r.validity,
              quantity: r.quantity,
              type: r.drugInternationalName ? DeviceOrDrug.DRUG : DeviceOrDrug.DEVICE,
              drugInternationalName: r.drugInternationalName,
              drugRouteOfAdministration: r.drugRouteOfAdministration,
              drugDose: r.drugDose,
              remove: true,
              signature: r.signature,
              expiration: r.expiration,
            };
          }),
        );
        setInitialDPrescriptions(
          resp.data.dPrescriptions?.map((r: DPrescriptions) => {
            return {
              id: r.id,
              deviceName: r.deviceName,
              validity: r.validity,
              quantity: r.quantity,
              type: r.drugInternationalName ? DeviceOrDrug.DRUG : DeviceOrDrug.DEVICE,
              drugInternationalName: r.drugInternationalName,
              drugRouteOfAdministration: r.drugRouteOfAdministration,
              drugDose: r.drugDose,
              remove: true,
              signature: r.signature,
              expiration: r.expiration,
            };
          }),
        );
      } finally {
        setLoading(false);
        setInitialLoad(true);
      }
    }
  }, [prescriptionId, role, initialLoad, setLoading, formik, diagnosticsResult]);

  const handleCheckboxChange = useCallback(() => {
    setIsShow((prevShow) => !prevShow);
    if (!isShow) {
      formik.setFieldValue('thirdPersonFirstName', '');
    }
  }, [formik, isShow]);

  useEffect(() => {
    document.addEventListener('keypress', handlePressEnter);
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('keypress', handlePressEnter, true);
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const loadDiagnostics = async (value: string) => {
    const splitValue = value.split(' - ');
    if (role === 'doctor') {
      const diagnostics = await DiagnosticServices.getDiagnosticPartialData(role, {
        code: splitValue.length > 1 ? splitValue[0] : diagnosticValue,
        name: splitValue.length > 1 ? splitValue[1] : diagnosticValue,
      });
      setDiagnosticsResult(diagnostics.data.items);
    }
    if (role === 'pharmacy') {
      const diagnostics = await DiagnosticServices.getDiagnostics(role, {
        code: splitValue.length > 1 ? splitValue[0] : diagnosticValue,
        name: splitValue.length > 1 ? splitValue[1] : diagnosticValue,
        specialityName: doctor?.speciality || '',
      });
      setDiagnosticsResult(diagnostics.data.items);
    }
  };

  useEffect(() => {
    if (!diagnosticsResult?.length && diagnosticValue !== '') {
      loadDiagnostics(diagnosticValue);
    }
    const delayInputTimeoutId = setTimeout(async () => {
      try {
        await loadDiagnostics(diagnosticValue);
      } catch (error) {
        isAxiosError(error) && handleError(error);
      }
    }, 300);
    return () => clearTimeout(delayInputTimeoutId);
  }, [role, diagnosticValue, doctor]);

  const handleChangeDiagnosticInput = async (event: ChangeEvent<HTMLInputElement>) => {
    setDiagnosticValue(event.target.value);
    setIsSelectedValue(false);
    await formik.setFieldValue('dPrescriptions', [
      {
        deviceName: '',
        drugInternationalName: '',
        drugRouteOfAdministration: '',
        drugDose: '',
        validity: '',
        quantity: '',
        type: '',
        commercialName: '',
        remove: false,
        signature: '',
        expiration: 0,
      },
    ]);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setVisible(false);
    }
  };
  const handlePressEnter = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  const handleSearchDiagnostic = (code: string) => {
    formik.setFieldValue('diagnostic', code);
    if (code) {
      setDiagnosticCode(code);
    }
  };

  const checkDisabledButtons = () => {
    const dPrescriptions = formik?.values?.dPrescriptions?.filter((dp) => {
      if (dp.type === DeviceOrDrug.DRUG) {
        return (
          dp.quantity &&
          Number(dp.quantity) > 0 &&
          dp.validity &&
          dp.remove &&
          Number(dp.validity) > 0 &&
          Number(dp.validity) <= Number(dp.treatmentDuration) &&
          dp.drugDose !== '' &&
          dp.signature.length <= 50
        );
      }
      if (dp.type === DeviceOrDrug.DEVICE) {
        return dp.quantity && Number(dp.quantity) > 0 && dp.remove && dp.signature.length <= 50;
      }
    });

    if (isShow && formik?.errors?.thirdPersonIDNP) {
      return true;
    }

    if (role === 'pharmacy') {
      return dPrescriptions.length !== formik?.values?.dPrescriptions?.length;
    } else {
      return (
        dPrescriptions.length !== formik?.values?.dPrescriptions?.length || !formik.values.phone
      );
    }
  };

  const checkEditDisabledButtons = () => {
    const dPrescriptions = formik?.values?.dPrescriptions?.filter((dp) => {
      if (dp.type === DeviceOrDrug.DRUG) {
        return (
          dp.quantity &&
          Number(dp.quantity) > 0 &&
          dp.validity &&
          Number(dp.validity) > 0 &&
          Number(dp.validity) <= Number(dp.treatmentDuration)
        );
      }
      if (dp.type === DeviceOrDrug.DEVICE) {
        return dp.quantity && Number(dp.quantity) > 0 && dp.remove;
      }
    });

    if (isShow && formik?.errors?.thirdPersonIDNP) {
      return true;
    }

    return dPrescriptions.length !== formik?.values?.dPrescriptions?.length;
  };

  const checkDisabledAddDrugOrDevice = useMemo(() => {
    const dPrescription = formik?.values?.dPrescriptions?.at(
      formik?.values.dPrescriptions?.length - 1,
    );
    return dPrescription && (dPrescription?.validity || dPrescription?.quantity);
  }, [formik?.values.dPrescriptions]);

  useEffect(() => {
    if (!formik?.values?.dPrescriptions?.length) {
      formik.setFieldValue('dPrescriptions', [
        {
          deviceName: '',
          drugInternationalName: '',
          drugRouteOfAdministration: '',
          drugDose: '',
          validity: '',
          quantity: '',
          type: '',
          commercialName: '',
          remove: false,
          signature: '',
          expiration: 0,
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik?.values?.dPrescriptions?.length]);

  useEffect(() => {
    if (action === 'edit' || action === 'clone') {
      setAddNew(true);
    }
    setDiagnosticCode(formik?.values?.diagnostic);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik?.values?.diagnostic]);

  const handleAddNew = () => {
    if (checkDisabledAddDrugOrDevice) {
      updateSelectedOption();
      formik.setFieldValue(`dPrescriptions[${formik.values.dPrescriptions.length}]`, {
        deviceName: '',
        drugInternationalName: '',
        drugRouteOfAdministration: '',
        drugDose: '',
        validity: '',
        quantity: '',
        type: '',
        commercialName: '',
        remove: false,
        signature: '',
        expiration: 0,
      });
    }
    setAddNew(false);
  };

  const updateSelectedOption = () => {
    const drugInternationalNames: string[] = [];
    const deviceNames: string[] = [];
    formik?.values?.dPrescriptions?.map(({ drugInternationalName, deviceName }) => {
      if (drugInternationalName && drugInternationalName.length > 0) {
        drugInternationalNames.push(drugInternationalName);
      }
      if (deviceName && deviceName.toString().length > 0) {
        deviceNames.push(deviceName.toString());
      }
    });
    setSelectedDrugs(drugInternationalNames);

    setSelectedDevices(deviceNames);
  };

  useEffect(() => {
    updateSelectedOption();
  }, [formik?.values?.dPrescriptions]);

  const saveAndSign = () => {
    setSignAfterSave(true);
    setConfirmSign(true);
  };

  const closeCheckSign = () => {
    setError(undefined);
    setConfirmSign(false);
    setSignAfterSave(false);
  };

  const arraysAreEqual = <T extends Record<string, any>>(arr1: T[], arr2: T[]) => {
    if (arr1?.length !== arr2?.length) {
      return false;
    }

    for (let i = 0; i < arr1.length; i++) {
      const obj1 = arr1[i];
      const obj2 = arr2[i];

      if (typeof obj1 !== 'object' || typeof obj2 !== 'object') {
        return false;
      }

      const keys1 = Object.keys(obj1);
      const keys2 = Object.keys(obj2);

      if (keys1.length !== keys2.length) {
        return false;
      }

      for (const key of keys1) {
        if (obj1[key] !== obj2[key]) {
          return false;
        }
      }
    }
    return true;
  };

  const [thirdPartyPerson, thirdPartyPersonError, _] = useGetPatient({
    idnp: !formik.errors.thirdPersonIDNP ? formik?.values?.thirdPersonIDNP : '',
    ignoreRegionCheck: true,
  });

  useEffect(() => {
    if (thirdPartyPerson) {
      const data = thirdPartyPerson as Patient;
      formik.setFieldValue('thirdPersonFirstName', data.firstName || '');
      formik.setFieldValue('thirdPersonLastName', data.lastName || '');
      return;
    }
    if (thirdPartyPersonError) {
      formik.setFieldError(
        'thirdPersonIDNP',
        t('prescriptionForm.patientNotFound', 'Nu am gasit nici un paciant cu asa idnp'),
      );
    }
  }, [thirdPartyPerson, thirdPartyPersonError]);
  useEffect(() => {
    const isSame = arraysAreEqual<CreateDPrescriptions>(
      formik.values.dPrescriptions.map((value) => {
        return {
          quantity:
            typeof value.quantity === 'string'
              ? value.quantity
              : (value.quantity as number).toString(),
          validity:
            typeof value.validity === 'string'
              ? value.validity
              : (value.validity as number).toString(),
          deviceName: value.deviceName,
          type: value.drugInternationalName ? DeviceOrDrug.DRUG : DeviceOrDrug.DEVICE,
          drugInternationalName: value.drugInternationalName,
          drugRouteOfAdministration: value.drugRouteOfAdministration,
          drugDose: value.drugDose,
          remove: true,
          signature: value.signature,
        };
      }),
      initialDPrescriptions!,
    );
    const sameThirdPerson = prescription?.thirdPerson.idnp === formik?.values?.thirdPersonIDNP;
    if (isSame && sameThirdPerson) {
      setSignDisabled(true);
    } else if (!sameThirdPerson) {
      setSignDisabled(false);
    } else if (formik?.values?.dPrescriptions?.length) {
      if (formik?.values.dPrescriptions?.some((dp) => !dp.remove)) {
        setSignDisabled(true);
      } else {
        setSignDisabled(false);
      }
    }
  }, [initialDPrescriptions, formik?.values?.dPrescriptions, formik?.values?.thirdPersonIDNP]);

  const checkLoading = () => {
    if ((action === 'clone' || action === 'edit') && !prescription && !diagnosticValue) {
      setLoading(true);
    }
  };
  useEffect(() => {
    checkLoading();
  }, [action, prescription, diagnosticValue]);

  const setPhone = (phone?: E164Number) => {
    formik.setFieldValue('phone', phone);
  };

  const displayPhoneInput = () => {
    if (role === 'pharmacy') {
      return;
    }
    return (
      <div className="w-full flex flex-col">
        <CorText variant={TextVariant.LABEL_2_REGULAR}>
          <span className="text-content-muted">
            <T keyName="prescriptionForm.phoneNumber">Numar de contact</T>
          </span>
        </CorText>
        <PhoneInput
          className={classNames({ error: !!formik.errors.phone })}
          placeholder={t('prescriptionForm.phonePlaceholder', 'Introduceti numarul de telefon')}
          defaultCountry="MD"
          value={formik?.values?.phone}
          onChange={(e) => setPhone(e)}
          initialValueFormat="national"
        />
        {formik?.errors?.phone ? (
          <span className="bottom-helper">
            <T keyName="prescriptionForm.phoneError">{formik.errors.phone}</T>
          </span>
        ) : null}
      </div>
    );
  };

  return (
    <>
      {errorMessage?.length && (
        <Alert text={errorMessage} className="h-fit w-full mb-4" status={AlertStatus.DANGER} />
      )}
      <form
        onSubmit={formik.handleSubmit}
        className="flex flex-col company-information-editable-border pb-0 pt-4 sm:py-4 lg:pb-0 gap-6"
      >
        <CorBox
          boxStyle="none"
          className="bg-white rounded-2xl flex flex-col justify-between h-auto py-3 px-4 sm:py-5 sm:px-6"
          px="lg"
        >
          <div className="flex flex-col gap-5 sm:gap-6">
            <CorText variant={isMobile ? TextVariant.LABEL_1_BOLD : TextVariant.HEADING_4_BOLD}>
              <span>
                {!isMobile
                  ? t('prescriptionForm.recepieText', 'Reteta')
                  : t('prescriptionForm.recepieDetails', 'Detalii reteta')}
              </span>
            </CorText>
            <div className="w-full grid grid-cols-2 gap-4">
              <div className="flex flex-col w-full">
                <CorText variant={TextVariant.LABEL_2_REGULAR}>
                  <span className="text-content-muted">
                    <T keyName="prescriptionForm.searchDiagnostic">Cautare Diagnostic</T>
                  </span>
                </CorText>
                <div className="relative" ref={ref}>
                  <CorInput iconName={IconName.SEARCH} iconDisplay={InputIconDisplay.LEFT}>
                    <input
                      autoComplete="off"
                      type="text"
                      name="diagnostic"
                      placeholder={t(
                        'prescriptionForm.searchPlaceholder',
                        'Introduceti cod diagnostic',
                      )}
                      value={diagnosticValue}
                      onClick={() => setVisible(true)}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        handleChangeDiagnosticInput(event);
                      }}
                    />
                  </CorInput>
                  {isVisible && diagnosticsResult && diagnosticsResult?.length ? (
                    <div className="absolute w-full max-h-[296px] overflow-y-scroll z-50 mt-1 py-2 rounded-[8px] border border-solid border-border-default bg-decorative-neutral-1">
                      {diagnosticsResult.map((item: DiagnosticsResult) => {
                        return (
                          <div
                            className="relative flex gap-2 justify-between w-full px-4 py-2 cursor-pointer group hover:bg-surface-hover"
                            key={hash(item)}
                            onClick={() => {
                              setDiagnosticValue(`${item.code} - ${item.name}`);
                              setIsSelectedValue(true);
                              setVisible(false);
                              handleSearchDiagnostic(item.code);
                            }}
                          >
                            <CorText variant={TextVariant.LABEL_2_REGULAR}>
                              <p className="text-content-muted group-hover:text-content-default">
                                {item.code} - {item.name}
                              </p>
                            </CorText>
                            {diagnosticValue === item.code + ' - ' + item.name ? <Check /> : null}
                          </div>
                        );
                      })}
                    </div>
                  ) : null}
                </div>
              </div>
              {displayPhoneInput()}
            </div>
            <div className="flex flex-col gap-2">
              <div className="flex flex-row items-center gap-2">
                <CorText variant={TextVariant.LABEL_1_MEDIUM}>
                  <span className="text-content-muted">
                    <T keyName="prescriptionForm.thirdParty">Persoana Terta</T>
                  </span>
                </CorText>
                <CorCheckbox size={CheckboxSize.MD}>
                  <input
                    type="checkbox"
                    id="person"
                    name="person"
                    checked={isShow}
                    onChange={handleCheckboxChange}
                  />
                </CorCheckbox>
              </div>
              <div className={`flex flex-col md:flex-row gap-2 ${isShow ? 'block' : 'hidden'}`}>
                <div className="flex flex-col w-full gap-1">
                  <CorText variant={TextVariant.LABEL_2_REGULAR}>
                    <span className="text-action-neutral-pressed">
                      <T keyName="prescriptionForm.idnp">IDNP</T>
                    </span>
                  </CorText>
                  <CorInput error={!!formik.errors?.thirdPersonIDNP}>
                    <input
                      type="text"
                      name="thirdPersonIDNP"
                      placeholder={t(
                        'prescriptionForm.thirdPartyIDNP',
                        'Introduceti idnp persoanei terte',
                      )}
                      onChange={formik?.handleChange}
                      value={formik?.values.thirdPersonIDNP}
                    />
                    {formik.errors?.thirdPersonIDNP ? (
                      <span className="bottom-helper">{formik.errors?.thirdPersonIDNP}</span>
                    ) : null}
                  </CorInput>
                </div>
                <div className="flex flex-col w-full gap-1">
                  <CorText variant={TextVariant.LABEL_2_REGULAR}>
                    <span className="text-action-neutral-pressed">
                      <T keyName="prescriptionForm.thirdPartyLastName">Nume</T>
                    </span>
                  </CorText>
                  <CorInput>
                    <input
                      type="text"
                      name="thirdPersonLastName"
                      placeholder={t(
                        'prescriptionForm.thirdPlacholderPartyLastName',
                        'Introduceti numele persoanei terte',
                      )}
                      disabled={true}
                      value={formik?.values.thirdPersonLastName}
                    />
                  </CorInput>
                </div>
                <div className="flex flex-col w-full gap-1">
                  <CorText variant={TextVariant.LABEL_2_REGULAR}>
                    <span className="text-action-neutral-pressed">
                      <T keyName={'prescriptionForm.thirdPartyFirstName'}>Prenume</T>
                    </span>
                  </CorText>
                  <CorInput>
                    <input
                      type="text"
                      name="thirdPersonFirstName"
                      disabled={true}
                      placeholder={t(
                        'prescriptionForm.thirdPlacholderPartyFirstName',
                        'Introduceti prenumele persoanei terte',
                      )}
                      value={formik?.values.thirdPersonFirstName}
                    />
                  </CorInput>
                </div>
              </div>
            </div>
            {formik.values.dPrescriptions.length &&
            (action === 'edit' || action === 'clone' || isSelectedValue)
              ? formik.values.dPrescriptions.map((_: CreateDPrescriptions, index: number) => (
                  <DeviceAndDrugDropdown
                    key={hash(index)}
                    count={index}
                    selectedDevices={selectedDevices}
                    selectedDrugs={selectedDrugs}
                    setAddNew={setAddNew}
                    onRemove={updateSelectedOption}
                    setAddedAtLeastOneDeviceOrDrug={setAddedAtLeastOneDeviceOrDrug}
                    formik={formik}
                    patient={patient as Patient}
                    diagnosticCode={diagnosticCode}
                    role={role!}
                  />
                ))
              : null}
          </div>
          {((formik?.values.dPrescriptions?.length &&
            formik?.values.dPrescriptions?.filter((drug) => drug?.validity)?.length) ||
            (formik?.values.dPrescriptions?.length &&
              formik?.values.dPrescriptions?.filter((device) => device?.quantity)?.length) ||
            action === 'edit' ||
            action === 'clone') &&
          addNew &&
          addedAtLeastOneDeviceOrDrug &&
          formik.values.dPrescriptions.length < limit ? (
            <div className="flex flex-row items-center justify-between gap-6 h-5 w-full">
              <div className="bg-border-inverted-selected w-full h-px" />
              <div
                className={`flex flex-row items-center gap-2 whitespace-pre border-none ${
                  checkDisabledAddDrugOrDevice ? 'cursor-pointer' : 'cursor-not-allowed'
                }`}
                onClick={handleAddNew}
              >
                <CorIcon name="plus-circle" className="bg-red-900" size={IconSize.MD} />
                <CorText variant={TextVariant.LABEL_2_REGULAR}>
                  <div className="text-content-disabled">
                    <T keyName={'prescriptionForm.addNewDeviceOrDrug'}>
                      Adaugare medicament/dispozitiv nou
                    </T>
                  </div>
                </CorText>
              </div>
              <div className="bg-border-inverted-selected w-full h-px" />
            </div>
          ) : null}
        </CorBox>

        {action === 'edit' ? (
          <div className="flex justify-end gap-2">
            <div className="flex justify-end">
              <CorButton variant={ButtonVariant.SECONDARY}>
                <button disabled={signDisabled || checkEditDisabledButtons()} type="submit">
                  <T keyName={'prescriptionForm.saveButtonText'}>Salveaza</T>
                </button>
              </CorButton>
            </div>
            <div className="flex justify-end">
              <CorButton variant={ButtonVariant.PRIMARY} onClick={saveAndSign}>
                <button type="button" disabled={signDisabled || checkEditDisabledButtons()}>
                  <T keyName={'prescriptionForm.signButtonText'}>Semneaza</T>
                </button>
              </CorButton>
            </div>
          </div>
        ) : null}

        {action === 'clone' ? (
          <div className="flex justify-end gap-2">
            <div className="flex justify-end">
              <CorButton variant={ButtonVariant.SECONDARY}>
                <button disabled={checkEditDisabledButtons()} type="submit">
                  <T keyName={'prescriptionForm.saveButtonText'}>Salveaza</T>
                </button>
              </CorButton>
            </div>
            <div className="flex justify-end">
              <CorButton variant={ButtonVariant.PRIMARY} onClick={saveAndSign}>
                <button type="button" disabled={checkEditDisabledButtons()}>
                  <T keyName={'prescriptionForm.signButtonText'}>Semneaza</T>
                </button>
              </CorButton>
            </div>
          </div>
        ) : null}
        {!prescriptionId ? (
          <div className="flex justify-end gap-2">
            <div className="flex justify-end">
              <CorButton variant={ButtonVariant.PRIMARY} onClick={saveAndSign}>
                <button disabled={checkDisabledButtons()} type="button">
                  <T keyName={'prescriptionForm.signButtonText'}>Semneaza</T>
                </button>
              </CorButton>
            </div>
            {role === 'doctor' ? (
              <div className="flex justify-end">
                <CorButton variant={ButtonVariant.SECONDARY}>
                  <button disabled={checkDisabledButtons()} type="submit">
                    <T keyName={'prescriptionForm.saveButtonText'}>Salveaza</T>
                  </button>
                </CorButton>
              </div>
            ) : null}
          </div>
        ) : null}
      </form>
      {confirmSign && (
        <ConfirmModal
          title="Atentie"
          description={t('unsignedDispenses.confirmSign', 'Ești sigur că semnezi rețeta?')}
          onClose={closeCheckSign}
          onAccept={verifySign}
        />
      )}
      <EinsteinDrawer />
    </>
  );
};

export default PrescriptionForm;
