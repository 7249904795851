import { TolgeeInstance } from '@tolgee/react';

export const getLocale = (tolgee: TolgeeInstance) => {
  switch (tolgee.getPendingLanguage()) {
    case 'ro':
      return 'ro';
    case 'ru':
      return 'ru';
    case 'en':
      return 'en';
    default:
      return 'ro';
  }
};
