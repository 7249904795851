import { CorBadge, CorText } from '@e-reteta/react-design-system';
import React, { FC, memo, MouseEventHandler, useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import hash from 'object-hash';
import { T, useTranslate } from '@tolgee/react';
import { Tooltip } from 'react-tooltip';

import { IconName } from '../../../../enums/design-system-icons.enum';
import { BadgeSize, TextVariant } from '../../../../enums/design-system.enum';
import { ReceiptStatus } from '../../../../enums/ReceiptStatus';
import { getPrescriptionStatusColor } from '../../../../lib/getPrescriptionStatusColor';
import { getPrescriptionStatusTranslation } from '../../../../lib/getPrescriptionStatusTranslation';
import { LinkButton } from '../../../index';
import { PrescriptionCardProps } from './interface';
import { AuthContext } from '../../../../context/context';
import PrescriptionPrint from '../../../Prints/PrescriptionPrint';
import { formatDate } from '../../../../utils/formatDate';

const PrescriptionCard: FC<PrescriptionCardProps> = ({
  prescription,
  role,
  patientId,
  allowedCodesForCloning,
}) => {
  const { t } = useTranslate();
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);

  const handleButtonClick = useCallback(
    (to: string): MouseEventHandler => {
      return (event) => {
        event.stopPropagation();
        navigate(to);
      };
    },
    [navigate],
  );

  const getDrugDevice = () => {
    const drugAndDevices: string[] = [];
    prescription.dPrescriptions?.map(({ drugInternationalName, deviceName }) => {
      if (drugInternationalName) {
        drugAndDevices.push(drugInternationalName);
      }
      if (deviceName) {
        drugAndDevices.push(deviceName);
      }
    });
    return (
      <>
        {drugAndDevices.length > 1 ? (
          <div className="flex items-center gap-2">
            <CorText
              className="whitespace-nowrap text-ellipsis overflow-hidden"
              variant={TextVariant.LABEL_2_MEDIUM}
            >
              <p>{drugAndDevices[0]}</p>
            </CorText>
            <div
              className="flex justify-center items-center h-5 rounded-md text-white bg-action-neutral-default hover:bg-action-neutral-hover px-1.5 py-0.5"
              data-tooltip-id={`tooltip-${prescription.id}`}
            >
              <CorText variant={TextVariant.BODY_1_REGULAR}>
                <p>+{drugAndDevices.length - 1}</p>
              </CorText>
            </div>
            <Tooltip
              id={`tooltip-${prescription.id}`}
              style={{
                backgroundColor: 'white',
                borderRadius: '6px',
                outline: '1px solid #e6e6ea',
                color: 'rgb(0 0 0)',
              }}
              opacity="1"
            >
              <div className="bg-white flex flex-col gap-1">
                {drugAndDevices.map((item, index) => {
                  if (index != 0) {
                    return (
                      <CorText key={hash(item)} variant={TextVariant.BODY_1_REGULAR}>
                        <p>{item}</p>
                      </CorText>
                    );
                  }
                })}
              </div>
            </Tooltip>
          </div>
        ) : (
          drugAndDevices
        )}
      </>
    );
  };

  return (
    <div
      onClick={() => navigate(`/patient/${patientId}/prescription/${prescription.id}?doctor=true`)}
      key={prescription.id}
      className="flex flex-col w-full hover:bg-surface-hover gap-2 py-3 px-4 sm:p-4 cursor-pointer bg-background-primary"
    >
      {role === 'doctor' ? (
        <>
          <section className="flex flex-col">
            <CorText variant={TextVariant.LABEL_2_REGULAR}>
              <span className="text-content-muted">
                <T keyName="prescriptionsTable.doctorDiagnostic">Diagnosticul</T>
              </span>
            </CorText>
            <CorText variant={TextVariant.LABEL_2_MEDIUM}>
              <span className="text-content-default">
                {prescription.diagnosticCode}
                {prescription.diagnosticName ? ` - ${prescription.diagnosticName}` : null}
              </span>
            </CorText>
          </section>
          <section className="grid grid-cols-2 xs:grid-cols-3 items-center gap-2">
            <section className="flex flex-col">
              <CorText variant={TextVariant.LABEL_2_REGULAR}>
                <span className="text-content-muted">
                  <T keyName="prescriptionsTable.doctorDate">Data</T>
                </span>
              </CorText>
              <CorText variant={TextVariant.LABEL_2_MEDIUM}>
                <span className="text-content-default">{formatDate(prescription.createdAt)}</span>
              </CorText>
            </section>
            <section className="flex flex-col">
              <CorText variant={TextVariant.LABEL_2_REGULAR}>
                <span className="text-content-muted">
                  <T keyName="prescriptionsTable.doctorStatus">Statut</T>
                </span>
              </CorText>
              <CorBadge color={getPrescriptionStatusColor(prescription.status)} size={BadgeSize.SM}>
                <span className="capitalize">
                  {getPrescriptionStatusTranslation(prescription.status)}
                </span>
              </CorBadge>
            </section>
            <section className="flex flex-col">
              <CorText variant={TextVariant.LABEL_2_REGULAR}>
                <span className="text-content-muted">
                  <T keyName="prescriptionsTable.doctorPrecriberDoctor">Medicul prescriptor</T>
                </span>
              </CorText>
              <CorText variant={TextVariant.LABEL_2_MEDIUM}>
                <span className="text-content-default">{`${prescription?.doctor?.firstName} ${prescription?.doctor?.lastName}`}</span>
              </CorText>
            </section>
          </section>
          <section className="flex flex-row items-center gap-2">
            {[ReceiptStatus.PRESCRIBED, ReceiptStatus.SIGNED].includes(prescription.status) &&
            prescription.doctorIDNP === user?.idnp &&
            user.activeCompany?.speciality === prescription?.doctor?.speciality ? (
              <LinkButton
                onClick={handleButtonClick(
                  `/patient/${patientId}/prescription/${prescription.id}/edit?doctor=true`,
                )}
                text={t('prescriptionsTable.modify', 'Modifica')}
                iconName={IconName.EDIT}
              />
            ) : null}
            {allowedCodesForCloning?.includes(prescription.diagnosticCode) ? (
              <LinkButton
                onClick={handleButtonClick(
                  `/patient/${patientId}/prescription/${prescription.id}/clone?doctor=true`,
                )}
                text={t('prescriptionsTable.clone', 'Cloneaza')}
                iconName={IconName.DUPLICATE}
              />
            ) : null}
            {prescription?.status !== ReceiptStatus.PRESCRIBED ? (
              <PrescriptionPrint prescription={prescription} buttonForPrint={'table'} />
            ) : null}
          </section>
        </>
      ) : null}
      {role === 'pharmacy' ? (
        <>
          <section className="flex flex-col">
            <CorText variant={TextVariant.LABEL_2_REGULAR}>
              <span className="text-content-muted">
                <T keyName="prescriptionsTable.pharmacyDiagnostic">Diagnosticul</T>
              </span>
            </CorText>
            <CorText variant={TextVariant.LABEL_2_MEDIUM}>
              <span className="text-content-default">
                {prescription.diagnosticCode}
                {prescription.diagnosticName ? ` - ${prescription.diagnosticName}` : null}
              </span>
            </CorText>
          </section>
          <section className="flex flex-col sm:grid sm:grid-cols-2 gap-2">
            <section className="flex flex-col">
              <CorText variant={TextVariant.LABEL_2_REGULAR}>
                <span className="text-content-muted">
                  <T keyName="prescriptionsTable.pharmacyDate">Data</T>
                </span>
              </CorText>
              <CorText variant={TextVariant.LABEL_2_MEDIUM}>
                <span className="text-content-default">{formatDate(prescription.createdAt)}</span>
              </CorText>
            </section>
            <section className="flex flex-col">
              <CorText variant={TextVariant.LABEL_2_REGULAR}>
                <span className="text-content-muted">
                  <T keyName="prescriptionsTable.pharmacyStatus">Statut</T>
                </span>
              </CorText>
              <CorText variant={TextVariant.LABEL_2_MEDIUM}>
                <span className="text-content-default">
                  {getPrescriptionStatusTranslation(prescription.status)}
                </span>
              </CorText>
            </section>
          </section>
          <section className="flex flex-col">
            <CorText variant={TextVariant.LABEL_2_REGULAR}>
              <span className="text-content-muted">
                <T keyName="prescriptionsTable.pharmacyDrugsDevices">Medicamente/Dispozitive</T>
              </span>
            </CorText>
            <CorText
              variant={TextVariant.LABEL_2_MEDIUM}
              className="whitespace-nowrap text-ellipsis overflow-hidden"
            >
              <span className="text-content-default">{getDrugDevice()}</span>
            </CorText>
          </section>
        </>
      ) : null}
    </div>
  );
};

export default memo(PrescriptionCard);
