import { FC, memo } from 'react';

import type { HolographPrescriptionPrintFormProps } from './interface';
import Table from './form-parts/Table';
import PatientDetails from './form-parts/PatientDetails';
import PrescriptionDetails from './form-parts/PrescriptionDetails';
import Header from './form-parts/Header';
import Footer from './form-parts/Footer';

const HolographPrescriptionPrintForm: FC<HolographPrescriptionPrintFormProps> = ({
  prescription,
  medicalInstitution,
}) => {
  return (
    <main className="flex flex-col p-5 mb-[100vh] font-satoshi">
      <Header prescriptionCode={prescription.id} />
      <PrescriptionDetails
        medicalInstitution={medicalInstitution}
        prescriptionId={prescription.id}
      />
      <PatientDetails />
      <Table />
      <Footer
        medicName={`${prescription?.doctor?.lastName} ${prescription?.doctor?.firstName}`}
        speciality={prescription?.doctor?.speciality || ''}
      />
    </main>
  );
};

export default memo(HolographPrescriptionPrintForm);
