import { SVGProps } from 'react';

const Drugs = (props: SVGProps<any>) => {
  return (
    <svg width="30" height="30" viewBox="0 0 44 42" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M37.9061 8.79216C34.143 6.8772 29.7193 7.57631 26.7165 10.5323C21.5325 15.6237 16.3716 20.7455 11.2183 25.8672C9.35978 27.7138 8.46891 29.9707 8.40747 32.6076C8.42283 32.828 8.42283 33.094 8.45355 33.3599C8.81451 36.7871 10.4734 39.3936 13.5838 40.959C17.3546 42.8587 21.7706 42.1064 24.7657 39.158C29.8729 34.1122 34.9724 29.0589 40.0796 24.0131C42.3298 21.7638 43.3282 19.0737 42.9288 15.9353C42.5064 12.7133 40.8245 10.2816 37.9061 8.79216ZM39.3192 22.9568C36.9001 25.4341 34.4041 27.8354 31.9465 30.2747C31.7545 30.4647 31.6623 30.4343 31.4934 30.2595C30.8406 29.5984 30.1801 28.9449 29.512 28.2913C29.2816 28.0634 29.0128 27.9874 28.7132 28.147C28.4291 28.3065 28.3523 28.5649 28.4214 28.8613C28.4675 29.0361 28.6057 29.1652 28.7286 29.2868C29.3584 29.9175 29.9881 30.5483 30.6332 31.1638C30.7791 31.3082 30.8022 31.3918 30.6409 31.5513C28.3292 33.8311 26.0329 36.126 23.7059 38.3905C22.1085 39.9483 20.1578 40.6854 18.4682 40.7006C13.6836 40.7158 10.243 37.7294 9.74378 33.5651C9.4289 30.9282 10.2199 28.6333 12.1015 26.7411C14.3441 24.4842 16.625 22.2653 18.8752 20.016C19.1056 19.788 19.2131 19.8716 19.3898 20.0464C21.7859 22.4249 24.1821 24.7958 26.5859 27.1667C26.6704 27.2503 26.7472 27.3339 26.8393 27.3947C27.1158 27.5922 27.4 27.577 27.6457 27.3491C27.8915 27.1211 27.8992 26.8399 27.7225 26.5588C27.6611 26.46 27.5766 26.384 27.4998 26.3004C25.096 23.9143 22.6845 21.5358 20.2807 19.1497C20.1578 19.0281 19.9581 18.9445 20.1962 18.7089C22.7843 16.1708 25.3033 13.5492 27.9683 11.0871C32.1385 7.22676 39.0274 8.75417 41.1317 14.0051C42.4757 17.3335 41.8306 20.3883 39.3192 22.9568Z" />
      <path d="M12.0862 19.1801C16.5405 18.2074 19.7661 14.1951 19.6893 9.62047C19.72 8.10066 19.3284 6.55045 18.522 5.12183C15.8033 0.281226 9.94346 -1.39817 5.03598 1.24631C3.46159 2.0974 2.24048 3.32085 1.33425 4.84826C1.07313 5.2966 1.21905 5.72975 1.67216 5.85134C2.03312 5.95012 2.25584 5.75255 2.43248 5.47898C2.76272 4.96225 3.11599 4.47591 3.52303 4.01996C5.92685 1.3223 10.1355 0.471202 13.4225 2.01381C13.6836 2.1354 13.6759 2.20379 13.4839 2.39376C9.79754 6.03371 6.11117 9.67366 2.44016 13.3288C2.21744 13.5492 2.14064 13.5036 2.03312 13.2452C1.45713 11.923 1.17297 10.5476 1.30353 9.10373C1.34193 8.67819 1.42641 8.25264 1.45713 7.82709C1.48016 7.48513 1.26513 7.27996 0.942569 7.21917C0.627691 7.15837 0.397293 7.31036 0.259054 7.59152C0.212974 7.69031 0.189934 7.81189 0.174575 7.91828C0.0132957 8.89856 -0.0558238 9.87124 0.0747353 10.8667C0.481772 14.0127 2.09456 16.4064 4.77486 18.0706C7.02508 19.4537 9.51338 19.7424 12.0862 19.1801ZM4.58286 16.4368C4.00687 15.9657 3.48463 15.4565 3.02383 14.8714C2.88559 14.7042 2.91631 14.6206 3.05455 14.4763C6.90988 10.6691 10.7652 6.85441 14.6129 3.03968C14.7818 2.8725 14.8893 2.84211 15.0813 3.00169C15.6727 3.47283 16.2103 3.98957 16.6711 4.58989C16.7249 4.65828 16.7709 4.73427 16.8324 4.81786C16.7633 4.89385 16.7018 4.95465 16.6404 5.01544C13.9831 7.64471 11.3259 10.2816 8.66091 12.9109C7.48588 14.0735 6.30317 15.2362 5.13582 16.4064C4.93614 16.604 4.80558 16.6192 4.58286 16.4368ZM6.26477 17.0371C8.21548 15.1222 10.1585 13.192 12.1015 11.2695C13.8142 9.57487 15.5268 7.88788 17.224 6.18569C17.4775 5.93493 17.5543 5.96532 17.7002 6.27688C19.1901 9.49888 18.353 13.534 15.7111 15.9277C14.0446 17.4399 12.0862 18.1998 9.82826 18.215C8.61483 18.2226 7.47052 17.9566 6.36461 17.4855C6.07277 17.3563 6.00365 17.2955 6.26477 17.0371Z" />
    </svg>
  );
};

export default Drugs;
