import { SVGProps } from 'react';

const AddressBook = (props: SVGProps<any>) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g opacity="0.3">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15 1.66663H16.1667C17.8235 1.66663 19.1667 3.00977 19.1667 4.66663V15.3333C19.1667 16.9901 17.8235 18.3333 16.1667 18.3333H15V1.66663Z"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.33333 2.91662C3.33333 2.22627 3.89297 1.66663 4.58333 1.66663H12.9167C14.9877 1.66663 16.6667 3.34556 16.6667 5.41663V14.5833C16.6667 16.6544 14.9877 18.3333 12.9167 18.3333H4.58333C3.89297 18.3333 3.33333 17.7736 3.33333 17.0833V2.91662ZM9.99999 9.16663C9.07952 9.16663 8.33333 8.42043 8.33333 7.49996C8.33333 6.57948 9.07952 5.83329 9.99999 5.83329C10.9205 5.83329 11.6667 6.57948 11.6667 7.49996C11.6667 8.42043 10.9205 9.16663 9.99999 9.16663ZM9.99228 9.99996C7.80644 9.99996 6.01308 11.2638 5.83363 13.7495C5.82385 13.8849 6.05403 14.1666 6.17013 14.1666H13.8179C14.1657 14.1666 14.1711 13.8518 14.1657 13.75C14.03 11.1943 12.2089 9.99996 9.99228 9.99996Z"
      />
    </svg>
  );
};

export default AddressBook;
