import { FC } from 'react';
import hash from 'object-hash';

import { repeat } from '../../../../lib/repeat';

const PatientDetails: FC = () => {
  return (
    <div className="flex justify-between pt-2 border-x-0 border-b-0 border-solid border-t border-t-border-default">
      <div className="flex flex-col gap-1">
        <span className="text-content-default text-base font-bold">Date despre pacient:</span>
        <div className="flex gap-6">
          <div className="flex flex-col">
            <span className="text-content-muted text-sm">Nume, Prenume</span>
            <div className="h-5 w-full border-x-0 border-t-0 border-b border-solid border-b-border-hover" />
          </div>
          <div className="flex flex-col">
            <span className="text-content-muted text-sm">Vârstă</span>
            <div className="h-5 w-full border-x-0 border-t-0 border-b border-solid border-b-border-hover" />
          </div>
          <div className="flex flex-col">
            <span className="text-content-muted text-sm">IDNP</span>
            <div className="flex">
              {repeat(null, 13).map((_, index) => (
                <div
                  key={hash(index)}
                  className={`${
                    index === 0 ? '' : 'border-l-0'
                  } w-5 h-5 border border-solid border-content-default`}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col pl-12 border-y-0 border-r-0 border-solid border-l border-l-border-hover justify-start">
        <span className="text-content-muted pb-1 text-base font-bold">
          (Persoana imputernicită:)
        </span>
        <span className="text-content-default text-sm">IDNP</span>
        <div className="flex">
          {repeat(null, 13).map((_, index) => (
            <div
              key={hash(index)}
              className={`${
                index === 0 ? '' : 'border-l-0'
              } w-5 h-5 border  border-solid border-content-default`}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default PatientDetails;
