import Api from '../axios/api';
import { DispensesStatusEnum } from '../enums/dispenses-status.enum';
import { Patient } from '../types/Patient';
import { Dispense } from '../types/CommonTypes';

export interface QueryParams {
  idnp: string;
  limit?: number;
  skip?: number;
  basicOnly?: boolean;
}

export interface QuerySaveParams {
  prescriptionId?: string;
  prescriptionReleases?: PrescriptionRelease[];
  diagnosticCode: string;
  patient: Patient;
  cashRegister?: string;
  houseVoucher?: string;
  signature?: string;
  status?: string;
  addedBy?: string;
  institutionCode?: string;
}

export interface DispenseMyQueryParams {
  statuses: string[];
  limit?: number;
  skip?: number;
  orderBy?: { createdAt: string };
  expires?: number;
}

export interface DispenseAllQueryParams {
  limit?: number;
  skip?: number;
  ids?: string[];
  prescriptionIds?: string[];
  institutionCodes?: string[];
  authors?: string[];
  statuses?: string[];
}

export interface UpdateDispensesQueryParams {
  id: string;
  cashRegister?: string;
  houseVoucher?: string;
  status?: string;
}

export interface CloseDispensesParams {
  id: string;
  cashRegister?: string;
  houseVoucher?: string;
}

export interface PrescriptionRelease {
  id?: string;
  externalId?: string;
  prescriptionId: string;
  deviceCommercialCod?: number;
  drugCommercialCod?: string;
  quantity: string;
  additionalInfo?: string;
  compensatedAmount: string;
  commercialName?: string;
  drugCommercialName?: string;
  deviceCommercialName?: number;
  retailPrice: string;
  amountToBePaid: string;
  dPrescriptionId: string;
}

export interface Recipe {
  id: string;
  drugName: string;
  deviceName: string;
  drugCommercialCod: string;
  drugInternationalName: string;
  drugRouteOfAdministration: string;
  signature: string;
  status: DispensesStatusEnum;
  createdAt: Date;
  patientIdentifier: string;
  patient: {
    firstName: string;
    lastName: string;
  };
  prescription: {
    id: string;
  };
}

export interface RequestSign {
  items: string[];
  targetUrl?: string;
}

interface ResponseSign {
  data?: {
    redirectUrl: string;
  };
}

interface GetPatientResponse {
  data: Patient;
}

export interface GetPharmacyResponse {
  data: Pharmacy;
}

interface GetDispensesResponse {
  data?: {
    items: Recipe[];
  };
}

interface GetDispensesByIdResponse {
  data?: Recipe;
}

export interface Pharmacy {
  code: string;
  idno: string;
  atAscribed: string;
  name: string;
  district: string;
  address: string;
  series: string;
  noLicense?: string;
  licenseValidity: string;
  type: string;
  accreditationCertificate?: string;
  validUntilTheDate?: string;
  branchCategory?: string;
  internalCodeOfTheBranch?: string;
  branchManager?: string;
  workingHours?: string;
  phone?: string;
  email?: string;
  contractNr?: string;
  createdAt: string;
  updatedAt: string;
  vatPayer: boolean;
}

export interface QueryGetParams {
  limit?: number;
  skip?: number;
  ids?: string[];
  prescriptionIds?: string[];
  institutionCodes?: string[];
  authors?: string[];
  statuses?: string[];
  expires?: number;
}

export interface DispensesList {
  items: Dispense[];
  count: number;
}
export interface ResponseDispensesList {
  data: DispensesList;
}

export interface UpdateDispense {
  cashRegister?: string;
  houseVoucher?: string;
  signature?: string;
  status?: string;
}
export interface ResponseDispense {
  data: Dispense;
}
export class PharmacyService {
  static sign(prescriptionId: string): Promise<ResponseSign> {
    return Api.post(`/v1/pharmacy/prescriptions/${prescriptionId}/sign`);
  }

  static getPatient(query: QueryParams): Promise<GetPatientResponse> {
    return Api.get(`/v1/pharmacy/patients/${query.idnp}`, {
      params: { basicOnly: query.basicOnly, limit: query.limit, skip: query.skip },
    });
  }

  static getPharmacy(idno: string): Promise<GetPharmacyResponse> {
    return Api.get(`/v1/pharmacy/pharmacies/${idno}`);
  }

  static getMyDispenses(query: DispenseMyQueryParams): Promise<ResponseDispensesList> {
    return Api.get(`/v1/pharmacy/dispenses/my`, {
      params: {
        expires: query.expires,
        statuses: query.statuses,
        limit: query?.limit,
        skip: query?.skip,
        orderBy: query?.orderBy,
      },
    });
  }

  static saveDispenses(query: QuerySaveParams) {
    return Api.post(`/v1/pharmacy/prescriptions/${query.prescriptionId}/dispenses`, query);
  }

  static getDispensesByPrescriptionId(target: string, id: string) {
    return Api.get(`/v1/${target}/prescriptions/${id}/dispenses`);
  }

  static getDispenses(query: QueryGetParams): Promise<ResponseDispensesList> {
    return Api.get('/v1/pharmacy/dispenses', { params: query });
  }

  static editDispenses(id: string, data: UpdateDispense): Promise<ResponseDispense> {
    return Api.patch(`/v1/pharmacy/dispenses/${id}`, data);
  }

  static signDispenses(body: RequestSign): Promise<ResponseSign> {
    return Api.post(`/v1/pharmacy/dispenses/sign`, body);
  }

  static updateDispenses(query: UpdateDispensesQueryParams): Promise<GetDispensesResponse> {
    return Api.patch(`/v1/pharmacy/dispenses/${query.id}`, {
      cashRegister: query.cashRegister,
      houseVoucher: query.houseVoucher,
      status: query.status,
    });
  }

  static closeDispense(params: CloseDispensesParams): Promise<GetDispensesResponse> {
    return Api.post(`/v1/pharmacy/dispenses/${params.id}/close`, {
      cashRegister: params.cashRegister,
      houseVoucher: params.houseVoucher,
    });
  }

  static getDispenseById(id: string): Promise<GetDispensesByIdResponse> {
    return Api.get(`/v1/pharmacy/dispenses/${id}`);
  }
}
