export enum ReceiptStatus {
  RELEASED = 'released',
  SIGNED = 'signed',
  UNSIGNED = 'unsigned',
  CANCELED = 'cancelled',
  EXPIRED = 'expired',
  PARTIAL_RELEASED = 'partial_released',
  PRESCRIBED = 'prescribed',
  PRESCRIBED_BY_PHARMACIST = 'prescribed_by_pharmacist',
  SIGNED_BY_PHARMACIST = 'signed_by_pharmacist',
}

export enum DropDownReceiptStatus {
  SIGNED = 'signed',
  PARTIAL_RELEASED = 'partial_released',
  RELEASED = 'released',
  PRESCRIBED = 'prescribed',
}

export const DropDownReceiptStatuses = Object.values(DropDownReceiptStatus);
