import { CorInput, CorRadioButton, CorText } from '@e-reteta/react-design-system';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useFormikContext } from 'formik';
import { T, useTranslate } from '@tolgee/react';
import hash from 'object-hash';
import { isAxiosError } from 'axios';

import { InputIconDisplay, TextVariant } from '../../../enums/design-system.enum';
import { ReportSearchType } from '../report-search-type.enum';
import { Annex8CNAMFormikProps } from './annex8-cnam.interface';
import { IconName } from '../../../enums/design-system-icons.enum';
import Check from '../../../icons/Check';
import { handleError } from '../../../utils/handleRequestErrors';
import { InstitutionDto, InstitutionService } from '../../../services/institution.service';
import { PharmaciesServices, PharmacyDto } from '../../../services/pharmacies.services';
import GroupByContainer from '../GroupBy';

const Annex8ForCNAM = () => {
  const formik = useFormikContext<Annex8CNAMFormikProps>();
  const ref = useRef<HTMLDivElement>(null);
  const { t } = useTranslate();

  const [isVisible, setVisible] = useState<boolean>(false);
  const [institutions, setInstitutions] = useState<InstitutionDto[]>([]);
  const [pharmacies, setPharmacies] = useState<PharmacyDto[]>([]);

  const handleClickOutside = (event: any) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const getInstitutions = async () => {
    const d = await InstitutionService.getAll('admin', {
      codIms: formik?.values?.annex8InputValue,
    });
    setInstitutions(d?.data?.items || []);
  };

  const getPharmacies = async () => {
    let searchQuery = {};
    if (formik.values.annex8Institution === ReportSearchType.PHARMACY) {
      searchQuery = {
        idnos: formik?.values?.annex8InputValue ? [formik?.values?.annex8InputValue] : [],
      };
    }
    if (formik.values.annex8Institution === ReportSearchType.PHARMACY_BRANCH) {
      searchQuery = {
        codes: formik?.values?.annex8InputValue ? [formik?.values?.annex8InputValue] : [],
      };
    }
    const d = await PharmaciesServices.getAll('admin', searchQuery);
    let uniquePharmacies: PharmacyDto[] = [];

    const idnos: string[] = [];
    if (formik.values.annex8Institution === ReportSearchType.PHARMACY) {
      if (d?.data?.items?.length) {
        uniquePharmacies = d?.data?.items?.filter((item) => {
          if (item.idno) {
            if (!idnos.includes(item.idno)) {
              idnos.push(item.idno);
              return item;
            }
          }
        });
      }
    }
    if (formik.values.annex8Institution === ReportSearchType.PHARMACY_BRANCH) {
      if (d?.data?.items) {
        uniquePharmacies = d?.data?.items;
      }
    }
    setPharmacies(uniquePharmacies || []);
  };

  useEffect(() => {
    if (!(institutions?.length || pharmacies?.length) && formik?.values?.annex8InputValue !== '') {
      if (formik.values.annex8Institution === ReportSearchType.INSTITUTION) {
        setPharmacies([]);
        getInstitutions();
      } else {
        setInstitutions([]);
        getPharmacies();
      }
    }
    const delayInputTimeoutId = setTimeout(async () => {
      try {
        if (formik.values.annex8Institution === ReportSearchType.INSTITUTION) {
          setPharmacies([]);
          getInstitutions();
        } else {
          setInstitutions([]);
          getPharmacies();
        }
      } catch (error) {
        isAxiosError(error) && handleError(error);
      }
    }, 500);
    return () => clearTimeout(delayInputTimeoutId);
  }, [formik?.values?.annex8InputValue, formik?.values?.annex8Institution]);

  const handleSearch = (value: string) => {
    formik.setFieldValue('annex8InputValue', value);
  };

  const handleSelect = (value: string) => {
    formik.setFieldValue('annex8InputValue', value);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>, type?: string) => {
    formik.handleChange(event);
    if (type == 'annex8Institution') {
      formik.setFieldValue('annex8InputValue', undefined);
      setPharmacies([]);
      setInstitutions([]);
    }
    if (type == 'annex8ReportType') {
      formik.setFieldValue('annex8SearchInput', undefined);
    }
  };

  const renderDropdownPlaceholder = () => {
    if (formik?.values?.annex8Institution === ReportSearchType.INSTITUTION) {
      return 'Introduceti cod institutie';
    }
    if (formik?.values?.annex8Institution === ReportSearchType.PHARMACY) {
      return 'Introduceti idno farmacie';
    }
    if (formik?.values?.annex8Institution === ReportSearchType.PHARMACY_BRANCH) {
      return 'Introduceti cod filiala';
    }
  };

  const renderTranspationKeys = () => {
    if (formik?.values?.annex8Institution === ReportSearchType.INSTITUTION) {
      return 'annex8.INSTITUTION';
    }
    if (formik?.values?.annex8Institution === ReportSearchType.PHARMACY) {
      return 'annex8.PHARMACY';
    }
    if (formik?.values?.annex8Institution === ReportSearchType.PHARMACY_BRANCH) {
      return 'annex8.PHARMACY_BRANCH';
    }
  };

  const handleGroupByInstitution = (fields: string[]) => {
    const groupBy: Record<string, '0' | '1'> = {};
    groupByFields.forEach((field) => {
      groupBy[field] = fields.indexOf(field) === -1 ? '0' : '1';
    });
    formik.setFieldValue(`annex8GroupBy`, groupBy);
  };

  const groupByFields = ['id', 'internationalName'];
  const renderDropDown = () => {
    if (formik.values.annex8Institution === undefined) {
      return null;
    }
    return (
      <>
        <div className="px-4 mt-4 mb-4">
          <div className="relative md:w-1/2" ref={ref}>
            <CorInput iconName={IconName.SEARCH} iconDisplay={InputIconDisplay.LEFT}>
              <input
                autoComplete="off"
                type="text"
                name="annex8InputValue"
                placeholder={t(renderTranspationKeys()!, renderDropdownPlaceholder())}
                value={formik?.values?.annex8InputValue || ''}
                onClick={() => setVisible(true)}
                onChange={(event: any) => {
                  handleSearch(event?.target?.value);
                }}
              />
            </CorInput>
            {isVisible && institutions && institutions?.length ? (
              <div className="absolute w-full max-h-[296px] overflow-y-scroll z-50 mt-1 py-2 rounded-[8px] border border-solid border-border-default bg-decorative-neutral-1">
                {institutions.map((item: InstitutionDto) => {
                  return (
                    <div
                      className="relative flex gap-2 justify-between w-full px-4 py-2 cursor-pointer group hover:bg-surface-hover"
                      key={hash(item)}
                      onClick={() => {
                        handleSelect(item?.codIms || '');
                        setVisible(false);
                      }}
                    >
                      <CorText variant={TextVariant.LABEL_2_REGULAR}>
                        <p className="text-content-muted group-hover:text-content-default">
                          {item?.codIms}
                        </p>
                      </CorText>
                      {formik?.values?.annex8InputValue === item?.codIms ? <Check /> : null}
                    </div>
                  );
                })}
              </div>
            ) : null}
            {isVisible && pharmacies && pharmacies?.length ? (
              <div className="absolute w-full max-h-[296px] overflow-y-scroll z-50 mt-1 py-2 rounded-[8px] border border-solid border-border-default bg-decorative-neutral-1">
                {pharmacies.map((item: PharmacyDto) => {
                  return formik.values.annex8Institution === ReportSearchType.PHARMACY ? (
                    <div
                      className="relative flex gap-2 justify-between w-full px-4 py-2 cursor-pointer group hover:bg-surface-hover"
                      key={hash(item)}
                      onClick={() => {
                        handleSelect(item?.idno || '');
                        setVisible(false);
                      }}
                    >
                      <CorText variant={TextVariant.LABEL_2_REGULAR}>
                        <p className="text-content-muted group-hover:text-content-default">
                          {item.idno}
                        </p>
                      </CorText>
                      {formik?.values?.annex8InputValue === item.idno ? <Check /> : null}
                    </div>
                  ) : (
                    <div
                      className="relative flex gap-2 justify-between w-full px-4 py-2 cursor-pointer group hover:bg-surface-hover"
                      key={hash(item)}
                      onClick={() => {
                        handleSelect(item?.code || '');
                        setVisible(false);
                      }}
                    >
                      <CorText variant={TextVariant.LABEL_2_REGULAR}>
                        <p className="text-content-muted group-hover:text-content-default">
                          {item.code}
                        </p>
                      </CorText>
                      {formik?.values?.annex8InputValue === item.code ? <Check /> : null}
                    </div>
                  );
                })}
              </div>
            ) : null}
          </div>
        </div>
        <div className="w-full border-0 border-t border-solid border-border-disabled mx-4 my-6" />
      </>
    );
  };

  const renderReportType = () => {
    return (
      <>
        <CorText variant={TextVariant.LABEL_1_MEDIUM} className="px-4">
          <div className="text-content-default">
            <T keyName={'annex.reportType'}>Tip Raport</T>
          </div>
        </CorText>
        <div className="flex flex-col px-4 pt-4 mb-4">
          <div className="flex gap-8">
            <CorRadioButton
              isChecked={ReportSearchType.PER_DOCTOR === formik?.values?.annex8ReportType}
              disabled={false}
            >
              <label className="whitespace-nowrap">
                <T keyName={'annex8.perDoctor'}>{ReportSearchType.PER_DOCTOR}</T>
              </label>
              <input
                type="radio"
                name="annex8ReportType"
                value={ReportSearchType.PER_DOCTOR}
                checked={ReportSearchType.PER_DOCTOR === formik?.values?.annex8ReportType}
                onChange={(event) => handleChange(event, 'annex8ReportType')}
                className="bg-background-secondary"
              />
            </CorRadioButton>
            <CorRadioButton
              isChecked={ReportSearchType.PER_PHARMACIST === formik?.values?.annex8ReportType}
              disabled={false}
            >
              <label className="whitespace-nowrap">
                <T keyName={'annex8.perPharmacist'}>{ReportSearchType.PER_PHARMACIST}</T>
              </label>
              <input
                type="radio"
                name="annex8ReportType"
                value={ReportSearchType.PER_PHARMACIST}
                checked={ReportSearchType.PER_PHARMACIST === formik?.values?.annex8ReportType}
                onChange={(event) => handleChange(event, 'annex8ReportType')}
                className="bg-background-secondary"
              />
            </CorRadioButton>
            <CorRadioButton
              isChecked={ReportSearchType.PER_PATIENT === formik?.values?.annex8ReportType}
              disabled={false}
            >
              <label className="whitespace-nowrap">
                <T keyName={'annex8.perPatient'}>{ReportSearchType.PER_PATIENT}</T>
              </label>
              <input
                type="radio"
                name="annex8ReportType"
                value={ReportSearchType.PER_PATIENT}
                checked={ReportSearchType.PER_PATIENT === formik?.values?.annex8ReportType}
                onChange={(event) => handleChange(event, 'annex8ReportType')}
                className="bg-background-secondary"
              />
            </CorRadioButton>
            <CorRadioButton
              isChecked={ReportSearchType.PER_PRESCRIPTION === formik?.values?.annex8ReportType}
              disabled={false}
            >
              <label className="whitespace-nowrap">
                <T keyName={'annex8.perPrescription'}>{ReportSearchType.PER_PRESCRIPTION}</T>
              </label>
              <input
                type="radio"
                name="annex8ReportType"
                value={ReportSearchType.PER_PRESCRIPTION}
                checked={ReportSearchType.PER_PRESCRIPTION === formik?.values?.annex8ReportType}
                onChange={(event) => handleChange(event, 'annex8ReportType')}
                className="bg-background-secondary"
              />
            </CorRadioButton>
          </div>
        </div>
      </>
    );
  };

  const renderInputPlaceholder = () => {
    if (ReportSearchType.PER_DOCTOR === formik?.values?.annex8ReportType) {
      return 'Introduceti doctor idnp';
    }
    if (ReportSearchType.PER_PHARMACIST === formik?.values?.annex8ReportType) {
      return 'Introduceti idnp farmacistului';
    }
    if (ReportSearchType.PER_PATIENT === formik?.values?.annex8ReportType) {
      return 'Introduceti idnp pacient sau act provizoriu';
    }
    if (ReportSearchType.PER_PRESCRIPTION === formik?.values?.annex8ReportType) {
      return 'Introduceti id prescriere';
    }
  };

  const renderTranslationKeys = () => {
    if (ReportSearchType.PER_DOCTOR === formik?.values?.annex8ReportType) {
      return 'annex8.PER_DOCTOR';
    }
    if (ReportSearchType.PER_PHARMACIST === formik?.values?.annex8ReportType) {
      return 'annex8.PER_PHARMACIST';
    }
    if (ReportSearchType.PER_PATIENT === formik?.values?.annex8ReportType) {
      return 'annex8.PER_PATIENT';
    }
    if (ReportSearchType.PER_PRESCRIPTION === formik?.values?.annex8ReportType) {
      return 'annex8.PER_PRESCRIPTION';
    }
  };

  const renderInput = () => {
    if (formik?.values?.annex8ReportType === undefined) {
      return null;
    }
    return (
      <>
        <div className="grid grid-cols-2 xs:px-4 mb-4">
          <CorInput>
            <input
              type="text"
              placeholder={t(renderTranslationKeys()!, renderInputPlaceholder())}
              value={formik.values.annex8SearchInput}
              onChange={(event) => {
                formik.setFieldValue('annex8SearchInput', event.target.value);
              }}
            />
          </CorInput>
        </div>
        <div className="w-full border-0 border-t border-solid border-border-disabled mx-4 my-6" />
      </>
    );
  };

  const renderPrescriptionType = () => {
    return (
      <>
        <CorText variant={TextVariant.LABEL_1_MEDIUM} className="px-4">
          <div className="text-content-default">
            <T keyName={'annex.prescriptionType'}>Tip Retete</T>
          </div>
        </CorText>
        <div className="flex flex-col px-4 pt-4 mb-4">
          <div className="flex gap-8">
            <CorRadioButton
              isChecked={
                ReportSearchType.ALL_PRESCRIPTIONS === formik?.values?.annex8PrescriptionType
              }
              disabled={false}
            >
              <label className="whitespace-nowrap">
                <T keyName={'annex.allPrescriptions'}>{ReportSearchType.ALL_PRESCRIPTIONS}</T>
              </label>
              <input
                type="radio"
                name="annex8PrescriptionType"
                value={ReportSearchType.ALL_PRESCRIPTIONS}
                checked={
                  ReportSearchType.ALL_PRESCRIPTIONS === formik?.values?.annex8PrescriptionType
                }
                onChange={formik.handleChange}
                className="bg-background-secondary"
              />
            </CorRadioButton>
            <CorRadioButton
              isChecked={
                ReportSearchType.HOLOGRAPH_PRESCRIPTIONS === formik?.values?.annex8PrescriptionType
              }
              disabled={false}
            >
              <label className="whitespace-nowrap">
                <T keyName={'annex.holographicPrescriptions'}>
                  {ReportSearchType.HOLOGRAPH_PRESCRIPTIONS}
                </T>
              </label>
              <input
                type="radio"
                name="annex8PrescriptionType"
                value={ReportSearchType.HOLOGRAPH_PRESCRIPTIONS}
                checked={
                  ReportSearchType.HOLOGRAPH_PRESCRIPTIONS ===
                  formik?.values?.annex8PrescriptionType
                }
                onChange={formik.handleChange}
                className="bg-background-secondary"
              />
            </CorRadioButton>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="flex flex-col">
      <CorText variant={TextVariant.LABEL_1_MEDIUM} className="px-4">
        <div className="text-content-default">
          <T keyName={'annex.chooseInstitution'}>Alege Institutia</T>
        </div>
      </CorText>
      <div className="flex flex-col px-4 pt-4 mb-4">
        <div className="flex gap-8">
          <CorRadioButton
            isChecked={ReportSearchType.INSTITUTION === formik?.values?.annex8Institution}
            disabled={false}
          >
            <label className="whitespace-nowrap">
              <T keyName={'annex.searchByInstitution'}>{ReportSearchType.INSTITUTION}</T>
            </label>
            <input
              type="radio"
              name="annex8Institution"
              value={ReportSearchType.INSTITUTION}
              checked={ReportSearchType.INSTITUTION === formik?.values?.annex8Institution}
              onChange={(event) => handleChange(event, 'annex8Institution')}
              className="bg-background-secondary"
            />
          </CorRadioButton>
          <CorRadioButton
            isChecked={ReportSearchType.PHARMACY === formik?.values?.annex8Institution}
            disabled={false}
          >
            <label className="whitespace-nowrap">
              <T keyName={'annex.searchByPharmacy'}>{ReportSearchType.PHARMACY}</T>
            </label>
            <input
              type="radio"
              name="annex8Institution"
              value={ReportSearchType.PHARMACY}
              checked={ReportSearchType.PHARMACY === formik?.values?.annex8Institution}
              onChange={(event) => handleChange(event, 'annex8Institution')}
              className="bg-background-secondary"
            />
          </CorRadioButton>
          <CorRadioButton
            isChecked={ReportSearchType.PHARMACY_BRANCH === formik?.values?.annex8Institution}
            disabled={false}
          >
            <label className="whitespace-nowrap">
              <T keyName={'annex.searchByPharmacyBranch'}>{ReportSearchType.PHARMACY_BRANCH}</T>
            </label>
            <input
              type="radio"
              name="annex8Institution"
              value={ReportSearchType.PHARMACY_BRANCH}
              checked={ReportSearchType.PHARMACY_BRANCH === formik?.values?.annex8Institution}
              onChange={(event) => handleChange(event, 'annex8Institution')}
              className="bg-background-secondary"
            />
          </CorRadioButton>
        </div>
      </div>
      {renderDropDown()}
      {renderReportType()}
      {renderInput()}
      {renderPrescriptionType()}
      <div className="w-full border-0 border-t border-solid border-border-disabled mx-4 my-6" />
      <GroupByContainer
        name={t('annex8.groupBy', 'Grupeaza dupa')}
        fields={groupByFields.map((field) => {
          return {
            label: t(`annex8.${field}`, field),
            value: field,
          };
        })}
        preSelected={groupByFields}
        onChange={handleGroupByInstitution}
      />
      <div className="w-full border-0 border-t border-solid border-border-disabled mx-4 my-6" />
    </div>
  );
};

export default Annex8ForCNAM;
