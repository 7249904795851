import { FC, SVGProps } from 'react';

const Diagnostic: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        opacity="0.3"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.66732 2.5H5.33398C4.22941 2.5 3.33398 3.39543 3.33398 4.5V17.1667C3.33398 18.2712 4.22941 19.1667 5.33398 19.1667H14.6673C15.7719 19.1667 16.6673 18.2712 16.6673 17.1667V4.5C16.6673 3.39543 15.7719 2.5 14.6673 2.5H13.334V2.91667C13.334 3.60702 12.7743 4.16667 12.084 4.16667H7.91732C7.22696 4.16667 6.66732 3.60702 6.66732 2.91667V2.5Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8333 1.66659L12 1.66659C12.2761 1.66659 12.5 1.89044 12.5 2.16659V2.83325C12.5 3.10939 12.2761 3.33325 12 3.33325H8C7.72386 3.33325 7.5 3.10939 7.5 2.83325V2.16658C7.5 1.89044 7.72386 1.66659 8 1.66659L9.16667 1.66659C9.16667 1.20635 9.53976 0.833252 10 0.833252C10.4602 0.833252 10.8333 1.20635 10.8333 1.66659Z"
      />
      <rect opacity="0.3" x="8.33398" y="7.5" width="5.83333" height="1.66667" rx="0.833333" />
      <rect opacity="0.3" x="5.83398" y="7.5" width="1.66667" height="1.66667" rx="0.833333" />
      <rect opacity="0.3" x="5.83398" y="10.8333" width="1.66667" height="1.66667" rx="0.833333" />
      <rect opacity="0.3" x="8.33398" y="10.8333" width="5.83333" height="1.66667" rx="0.833333" />
      <rect opacity="0.3" x="5.83398" y="14.1667" width="1.66667" height="1.66667" rx="0.833333" />
      <rect opacity="0.3" x="8.33398" y="14.1667" width="5.83333" height="1.66667" rx="0.833334" />
    </svg>
  );
};

export default Diagnostic;
