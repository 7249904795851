import { FC } from 'react';

import { renderSpeciality } from '../../../../utils/render-speciality.utils';

interface Props {
  medicName: string;
  speciality: string;
}

const Footer: FC<Props> = ({ medicName, speciality }) => {
  return (
    <div className="flex flex-col w-full">
      <div className="flex justify-between pb-2 border-0 border-solid border-b border-b-conetnt-muted">
        <div className="flex flex-col">
          <p className="text-content-muted m-0 text-sm">
            Nume, Prenume {renderSpeciality(speciality)}
          </p>
          <span className="text-content-default text-sm font-medium">{medicName}</span>
        </div>

        <div className="flex flex-col self-end">
          <div className="w-full border-0 border-b border-solid border-b-content-muted h-px" />
          <span className="text-content-default text-xs">Semnătura / L.Ș</span>
        </div>
      </div>

      <div className="w-[87.281%] pt-6">
        <span className="text-content-default text-sm font-medium">
          Prezentul document medical contine date cu caracter personal prelucrate in conformitate cu
          Legea 133/2011 privind protectia datelor cu caracter personal. În caz de pierdere
          documentul poate fi restituit la adresa institutiei medicale indicate pe retetă.
        </span>
      </div>
    </div>
  );
};

export default Footer;
