import { forwardRef, Fragment, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import hash from 'object-hash';

import HolographicPrescriptionPrintForm from './form/index';
import type {
  HolographPrescriptionPrintPageRef,
  HolographPrescriptionPrintPageProps,
} from './interface';
import { InstitutionDto, InstitutionService } from '../../services/institution.service';

const HolographPrescriptionPrintPage = forwardRef<
  HolographPrescriptionPrintPageRef,
  HolographPrescriptionPrintPageProps
>((props, ref) => {
  const { prescriptions, user } = props;
  const [institution, setInstitution] = useState<InstitutionDto>();

  const printRef = useRef<HTMLDivElement>(null);
  const print = useReactToPrint({
    content: () => printRef.current,
    documentTitle: `Retete olografe ${user?.firstName} ${user?.lastName}`,
  });

  useImperativeHandle(
    ref,
    () => ({
      print,
    }),
    [print],
  );

  const getInstitution = async () => {
    const institution = await InstitutionService.getAll('doctor', {
      codIms: user?.activeCompany?.code,
    });
    if (institution.data.items?.length) {
      setInstitution(institution?.data?.items?.at(0));
    }
  };

  useEffect(() => {
    if (user && !institution) {
      getInstitution();
    }
  }, [institution]);

  return (
    <div ref={printRef}>
      {prescriptions && (
        <>
          <style type="text/css" media="print">
            {'@page { size: landscape; }'}
          </style>
          {prescriptions.map((recipe, index) => (
            <Fragment key={hash(index)}>
              <HolographicPrescriptionPrintForm
                prescription={recipe}
                medicalInstitution={institution?.imsp || ''}
              />
              <HolographicPrescriptionPrintForm
                prescription={recipe}
                medicalInstitution={institution?.imsp || ''}
              />
            </Fragment>
          ))}
        </>
      )}
    </div>
  );
});

export default HolographPrescriptionPrintPage;
