import { CorInput, CorText } from '@e-reteta/react-design-system';
import { T, useTranslate } from '@tolgee/react';
import { FC, memo } from 'react';
import { useFormikContext } from 'formik';

import { TextVariant } from '../../../../enums/design-system.enum';
import { ModalContentProps } from '../interface';
import disableScrollOnInput from '../../../../utils/disableScrollOnInput';
import { InstitutionDto } from '../../../../services/institution.service';

const InstitutionModal: FC<ModalContentProps> = ({ isEdit }) => {
  const { t } = useTranslate();
  const formik = useFormikContext<InstitutionDto>();

  return (
    <div className="px-8 mb-6 overflow-auto">
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-1">
          <CorText variant={TextVariant.LABEL_2_REGULAR}>
            <div className="text-content-muted">
              <T keyName={'institutionModal.code'}>Cod</T>
            </div>
          </CorText>
          <CorInput disabled={isEdit} error={!!formik.errors.codIms}>
            <input
              autoComplete="off"
              type="text"
              name="codIms"
              placeholder={t('institutionModal.codePlaceholder', 'Introduceti codul')}
              value={formik.values.codIms}
              onChange={formik.handleChange}
            />
            {formik.errors.codIms ? (
              <span className="bottom-helper">{formik.errors.codIms}</span>
            ) : null}
          </CorInput>
        </div>
        <div className="flex flex-col gap-1">
          <CorText variant={TextVariant.LABEL_2_REGULAR}>
            <div className="text-content-muted">
              <T keyName={'institutionModal.id'}>IDNO</T>
            </div>
          </CorText>
          <CorInput error={!!formik.errors?.idno}>
            <input
              autoComplete="off"
              type="number"
              min={0}
              name="idno"
              placeholder={t('institutionModal.idPlaceholder', 'Introduceti IDNO')}
              value={formik.values?.idno}
              onChange={formik.handleChange}
              onWheel={(e) => disableScrollOnInput(e)}
            />
            {formik.errors?.idno ? (
              <span className="bottom-helper">{formik.errors?.idno}</span>
            ) : null}
          </CorInput>
        </div>
        <div className="flex flex-col gap-1">
          <CorText variant={TextVariant.LABEL_2_REGULAR}>
            <div className="text-content-muted">
              <T keyName={'institutionModal.imsp'}>IMSP</T>
            </div>
          </CorText>
          <CorInput error={!!formik.errors.imsp}>
            <textarea
              autoComplete="off"
              placeholder={t('institutionModal.imspPlaceholder', 'Introduceti IMSP')}
              name="imsp"
              value={formik.values.imsp}
              onChange={formik.handleChange}
              rows={4}
            />
            {formik.errors.imsp ? (
              <span className="bottom-helper">{formik.errors.imsp}</span>
            ) : null}
          </CorInput>
        </div>

        <div className="flex flex-col gap-1">
          <CorText variant={TextVariant.LABEL_2_REGULAR}>
            <div className="text-content-muted">
              <T keyName={'institutionModal.codAt'}>Cod At</T>
            </div>
          </CorText>
          <CorInput error={!!formik.errors.codAt}>
            <input
              autoComplete="off"
              type="number"
              min={0}
              name="codAt"
              placeholder={t('institutionModal.codAtPlaceholder', 'Introduceti Cod At')}
              value={formik.values.codAt}
              onChange={formik.handleChange}
              step=".01"
              onWheel={(e) => disableScrollOnInput(e)}
            />
            {formik.errors.codAt ? (
              <span className="bottom-helper">{formik.errors.codAt}</span>
            ) : null}
          </CorInput>
        </div>
        <div className="flex flex-col gap-1">
          <CorText variant={TextVariant.LABEL_2_REGULAR}>
            <div className="text-content-muted">
              <T keyName={'institutionModal.district'}>Raion</T>
            </div>
          </CorText>
          <CorInput error={!!formik.errors.district}>
            <input
              autoComplete="off"
              name="district"
              placeholder={t('institutionModal.districtPlaceholder', 'Introduceti raionul')}
              value={formik.values.district}
              onChange={formik.handleChange}
            />
            {formik.errors.district ? (
              <span className="bottom-helper">{formik.errors.district}</span>
            ) : null}
          </CorInput>
        </div>
      </div>
    </div>
  );
};

export default memo(InstitutionModal);
