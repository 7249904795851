import React, { ReactElement, useContext, useEffect } from 'react';

import { AuthContext } from '../../context/context';
import { useNavigate } from 'react-router-dom';
import { PharmacyRolesEnum } from '../../constants/roles.enum';

interface GuardProps {
  children: ReactElement<any, any>;
}

const PharmacyGuardedRoute: React.FC<GuardProps> = ({ children }) => {
  const { user, hasRole, logout } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user?.activeCompany) {
      return;
    }
    if (user.activeCompany.type !== 'pharmacy') {
      logout(false);
    }
    const licenceExpiresIn = user.activeCompany?.licenceExpiresInDays || 0;

    if (
      licenceExpiresIn <= 0 &&
      (hasRole(PharmacyRolesEnum.PHARMACIST_SUB) || hasRole(PharmacyRolesEnum.PHARMACIST))
    ) {
      navigate('/pharmacy/reports');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, user]);

  return <>{children}</>;
};

export default PharmacyGuardedRoute;
