import { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { isAxiosError } from 'axios';

import { HandwrittenPrescriptions, ModalWindow, NewReceiptModal } from '../../components';
import type { PrescriptionsPaginationData } from '../../components/PatientProfile/interface';
import { DEFAULT_PAGINATION_DATA } from '../../components/PatientProfile/Pagination/constants';
import { HolographPrescriptionService } from '../../services/holograph-prescription.service';
import type { HolographPrescription } from '../../types/HolographPrescription';
import HolographPrescriptionPrintPage from '../../components/HolographPrescriptionPrintPage';
import { HolographPrescriptionPrintPageRef } from '../../components/HolographPrescriptionPrintPage/interface';
import { Prescription } from '../../types/Prescription';
import { GetPrescriptionsRequest } from '../../services/prescription.services';
import { AuthContext } from '../../context/context';
import { handleError } from '../../utils/handleRequestErrors';
import { LoaderContext } from '../../context/loader/context';

const DoctorHandwrite: FC = () => {
  const printRef = useRef<HolographPrescriptionPrintPageRef>(null);
  const { user } = useContext(AuthContext);
  const { setLoading } = useContext(LoaderContext);

  const [count, setCount] = useState<number>(0);
  const [openNewReceiptModal, setOpenNewReceiptModal] = useState(false);
  const [prescriptions, setPrescriptions] = useState<Prescription[]>([]);
  const [generatedPrescriptions, setGeneratedPrescriptions] = useState<
    HolographPrescription[] | null
  >(null);

  const [paginationData, setPaginationData] =
    useState<PrescriptionsPaginationData>(DEFAULT_PAGINATION_DATA);

  const getPrescriptionsCallback = async () => {
    setLoading(true);
    try {
      const query: GetPrescriptionsRequest = {
        isHolograph: true,
        isDoctorUnsigned: true,
        limit: paginationData.itemsPerPage,
        skip: paginationData.page * paginationData.itemsPerPage,
        createdAtRangeInDays: 5,
        orderBy: {
          createdAt: 'DESC',
        },
      };

      const resp = await HolographPrescriptionService.getMyHolographPrescriptions(query);

      setPrescriptions(resp.data?.items);
      setCount(resp.data?.count || 0);
    } catch (error) {
      isAxiosError(error) && handleError(error);
      setLoading(false);
    }
    setLoading(false);
  };

  useEffect(() => {
    setPaginationData((data) => ({
      ...data,
      totalItems: count,
      totalPages: Math.ceil(count / paginationData.itemsPerPage),
    }));
  }, [count, paginationData.itemsPerPage]);

  useEffect(() => {
    getPrescriptionsCallback();
  }, [paginationData.page, paginationData.itemsPerPage]);

  const createHandWriteRecipes = useCallback(
    async (numberOfPrescriptions: number, phone: string) => {
      const expiration = new Date();
      expiration.setMonth(expiration.getMonth() + 6);

      const result = await HolographPrescriptionService.generate({
        phone,
        numberOfPrescriptions,
        expiration: expiration.toISOString(),
      });

      setGeneratedPrescriptions(result.data.items);
      setOpenNewReceiptModal(false);
    },
    [],
  );

  useEffect(() => {
    if (generatedPrescriptions) {
      printRef.current?.print();
      setGeneratedPrescriptions(null);
    }
  }, [generatedPrescriptions]);

  return (
    <div className="flex flex-col overflow-auto h-full pl-0 sm:pl-4 lg:pl-6 overflow-y-auto">
      <div className="flex w-full h-full justify-center items-center rounded-2xl">
        <HandwrittenPrescriptions
          prescription={prescriptions}
          setOpenNewReceiptModal={setOpenNewReceiptModal}
          paginationData={paginationData}
          setPaginationData={setPaginationData}
        />
      </div>
      {openNewReceiptModal ? (
        <ModalWindow>
          <NewReceiptModal
            onSave={createHandWriteRecipes}
            onCancel={() => setOpenNewReceiptModal(false)}
          />
        </ModalWindow>
      ) : null}
      <div style={{ display: 'none' }}>
        <HolographPrescriptionPrintPage
          prescriptions={generatedPrescriptions}
          user={user}
          ref={printRef}
        />
      </div>
    </div>
  );
};

export default DoctorHandwrite;
