import { BadgeColor } from '../enums/design-system.enum';
import { ReceiptStatus } from '../enums/ReceiptStatus';

const colorMap: Record<string, BadgeColor> = {
  [ReceiptStatus.PRESCRIBED]: BadgeColor.GREY,
  [ReceiptStatus.SIGNED]: BadgeColor.BLUE,
  [ReceiptStatus.PARTIAL_RELEASED]: BadgeColor.YELLOW,
  [ReceiptStatus.RELEASED]: BadgeColor.GREEN,
};

export function getPrescriptionStatusColor(status: ReceiptStatus): BadgeColor {
  return colorMap[status];
}
