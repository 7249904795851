import Flag from 'react-world-flags';
import { Dropdown } from '../../types/CommonTypes';

export const DropDownOption = (props: Dropdown, current: string) => {
  const { innerProps, innerRef, data } = props;

  return (
    <div ref={innerRef} {...innerProps}>
      <div className="flex flex-row hover:bg-surface-default justify-start items-center w-full p-2 cursor-pointer gap-4 group">
        <Flag code={data.value} className="h-5 w-5 rounded-full object-cover" />
        <div
          className={`text-sm truncate group-hover:text-content-default ${
            data.label === current ? 'text-content-default' : 'text-content-muted'
          }`}
        >
          {data.label}
        </div>
      </div>
    </div>
  );
};
