import Api from '../axios/ai-api';

export interface Document {
  id: string;
  title: string;
  abs: string;
  source: string;
  country: string;
  pubdate: string;
  no_citations: number;
  keywords: string[];
  journal: string;
  index_name: string;
  authors: Author[];
}

export interface Author {
  affiliation: string;
  forename: string;
  identifier: string;
  initials: string;
  lastname: string;
}

export interface AiResponseDto {
  documents: Document[];
  answer: string;
}

export interface GetCheckDrugsResponse {
  data: AiResponseDto;
}

export interface AskQuery {
  query: string;
  dateFrom?: string;
  language?: string;
  orderByCitations?: boolean;
}

export interface CheckQuery {
  drugs: string[];
  dateFrom?: string;
  language?: string;
}

export class AiService {
  static check(params: CheckQuery): Promise<GetCheckDrugsResponse> {
    return Api.post(`/v1/ai/check`, { drugs: params.drugs, language: params.language });
  }
  static ask(params: AskQuery): Promise<GetCheckDrugsResponse> {
    return Api.post(`/v1/ai/ask`, {
      query: params.query,
      language: params.language,
      dateFrom: params.dateFrom,
      orderByCitations: params.orderByCitations,
    });
  }
}
