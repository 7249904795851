import { CorInput, CorText } from '@e-reteta/react-design-system';
import { FC, memo } from 'react';
import { T, useTranslate } from '@tolgee/react';
import { useFormikContext } from 'formik';

import { TextVariant } from '../../../../enums/design-system.enum';
import { ModalContentProps } from '../interface';
import { Diagnostic } from './diagnostic.interface';

const DiagnosticModal: FC<ModalContentProps> = ({ isEdit }) => {
  const { t } = useTranslate();
  const formik = useFormikContext<Diagnostic>();

  return (
    <div className="px-8 mb-6 overflow-auto">
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-1">
          <CorText variant={TextVariant.LABEL_2_REGULAR}>
            <div className="text-content-muted">
              <T keyName={'diagnosticModal.code'}>Cod</T>
            </div>
          </CorText>
          <CorInput disabled={isEdit} error={!!formik.errors.code}>
            <input
              autoComplete="off"
              type="text"
              placeholder={t('diagnosticModal.codePlaceholder', 'Introduceti cod')}
              name="code"
              value={formik.values.code}
              onChange={formik.handleChange}
            />
            {formik.errors.code ? (
              <span className="bottom-helper">{formik.errors.code}</span>
            ) : null}
          </CorInput>
        </div>

        <div className="flex flex-col gap-1">
          <CorText variant={TextVariant.LABEL_2_REGULAR}>
            <div className="text-content-muted">
              <T keyName={'diagnosticModal.name'}>Denumire</T>
            </div>
          </CorText>
          <CorInput error={!!formik.errors.name}>
            <textarea
              autoComplete="off"
              placeholder={t('diagnosticModal.namePlaceholder', 'Introduceti denumirea')}
              rows={4}
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
            />
            {formik.errors.name ? (
              <span className="bottom-helper">{formik.errors.name}</span>
            ) : null}
          </CorInput>
        </div>

        <div className="flex flex-col gap-1">
          <CorText variant={TextVariant.LABEL_2_REGULAR}>
            <div className="text-content-muted">
              <T keyName={'diagnosticModal.subClass'}>Subclasa</T>
            </div>
          </CorText>
          <CorInput error={!!formik.errors.group?.name}>
            <textarea
              autoComplete="off"
              placeholder={t('diagnosticModal.subClassPlaceholder', 'Introduceti subclasa')}
              rows={4}
              name="group.name"
              value={formik.values.group?.name}
              onChange={formik.handleChange}
            />
            {formik.errors.group?.name ? (
              <span className="bottom-helper">{formik.errors.group?.name}</span>
            ) : null}
          </CorInput>
        </div>

        <div className="flex flex-col gap-1 pb-6">
          <CorText variant={TextVariant.LABEL_2_REGULAR}>
            <div className="text-content-muted">
              <T keyName={'diagnosticModal.class'}>Clasa</T>
            </div>
          </CorText>
          <CorInput error={!!formik.errors.group?.group?.name}>
            <textarea
              autoComplete="off"
              placeholder={t('diagnosticModal.classPlaceholder', 'Introduceti clasa')}
              rows={4}
              name="group.group.name"
              value={formik.values.group?.group?.name}
              onChange={formik.handleChange}
            />
            {formik.errors.group?.group?.name ? (
              <span className="bottom-helper">{formik.errors.group?.group?.name}</span>
            ) : null}
          </CorInput>
        </div>
      </div>
    </div>
  );
};

export default memo(DiagnosticModal);
