import { CorInput, CorRadioButton, CorText } from '@e-reteta/react-design-system';
import hash from 'object-hash';
import { T } from '@tolgee/react';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useFormikContext } from 'formik';
import { isAxiosError } from 'axios';

import { InputIconDisplay, TextVariant } from '../../../enums/design-system.enum';
import { ReportSearchType } from '../report-search-type.enum';
import { Annex8PharmacyFormikProps } from './annex8-for-pharmacy.interface';
import { IconName } from '../../../enums/design-system-icons.enum';
import Check from '../../../icons/Check';
import { PharmaciesServices, PharmacyDto } from '../../../services/pharmacies.services';
import { handleError } from '../../../utils/handleRequestErrors';

const Annex8ForPharmacyHead = () => {
  const formik = useFormikContext<Annex8PharmacyFormikProps>();
  const ref = useRef<HTMLDivElement>(null);

  const [isVisible, setVisible] = useState<boolean>(false);
  const [value, setValue] = useState<string>();
  const [pharmacies, setPharmacies] = useState<PharmacyDto[]>([]);

  const handleClickOutside = (event: any) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const getPharmacies = async () => {
    const d = await PharmaciesServices.getAll('pharmacy', {
      address: formik?.values?.annex8PharmacyHeadInputValue
        ? formik?.values?.annex8PharmacyHeadInputValue
        : '',
    });
    if (d?.data?.items?.length) {
      setPharmacies(d?.data?.items);
    }
  };

  useEffect(() => {
    if (!pharmacies?.length && formik?.values?.annex8PharmacyHeadInputValue !== '') {
      getPharmacies();
    }
    const delayInputTimeoutId = setTimeout(async () => {
      try {
        getPharmacies();
      } catch (error) {
        isAxiosError(error) && handleError(error);
      }
    }, 500);
    return () => clearTimeout(delayInputTimeoutId);
  }, [
    formik?.values?.annex8PharmacyHeadInputValue,
    formik?.values?.annex8PharmacyHeadSelectInstitution,
  ]);

  const handleSearch = (value: string) => {
    formik.setFieldValue('annex8PharmacyHeadInputValue', value);
    formik.setFieldValue('annex8PharmacyHeadCode', undefined);
    setValue(undefined);
  };

  const handleSelect = (value: string, pharmacyCode?: string) => {
    formik.setFieldValue('annex8PharmacyHeadInputValue', value);
    setValue(value);
    if (pharmacyCode) {
      formik.setFieldValue('annex8PharmacyHeadCode', pharmacyCode);
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>, type?: string) => {
    formik.handleChange(event);
    if (type == 'annex8PharmacyHeadSelectInstitution') {
      formik.setFieldValue('annex8PharmacyHeadInputValue', undefined);
      setPharmacies([]);
      setValue(undefined);
    }
    if (type == 'annex8PharmacyHeadReportType') {
      formik.setFieldValue('annex8PharmacyHeadSearchInput', undefined);
    }
  };

  const renderDropdownPlaceholder = () => {
    if (formik?.values?.annex8PharmacySelectInstitution === ReportSearchType.PHARMACY_BRANCH) {
      return 'introduceti adresa farmacie';
    }
  };

  const renderDropDown = () => {
    if (
      formik.values.annex8PharmacyHeadSelectInstitution === undefined ||
      formik.values.annex8PharmacyHeadSelectInstitution === ReportSearchType.ALL_BRANCHES
    ) {
      return null;
    }
    return (
      <>
        <div className="px-4 mt-4 mb-4">
          <div className="relative md:w-1/2" ref={ref}>
            <CorInput iconName={IconName.SEARCH} iconDisplay={InputIconDisplay.LEFT}>
              <input
                autoComplete="off"
                type="text"
                name="annex8PharmacyHeadInputValue"
                placeholder={renderDropdownPlaceholder()}
                value={formik?.values?.annex8PharmacyHeadInputValue || ''}
                onClick={() => setVisible(true)}
                onChange={(event: any) => {
                  handleSearch(event?.target?.value);
                }}
              />
            </CorInput>
            {isVisible && pharmacies && pharmacies?.length ? (
              <div className="absolute w-full max-h-[296px] overflow-y-scroll z-50 mt-1 py-2 rounded-[8px] border border-solid border-border-default bg-decorative-neutral-1">
                {pharmacies.map((item: PharmacyDto) => (
                  <div
                    className="relative flex gap-2 justify-between w-full px-4 py-2 cursor-pointer group hover:bg-surface-hover"
                    key={hash(item)}
                    onClick={() => {
                      handleSelect(item?.address || '', item?.code);
                      setVisible(false);
                    }}
                  >
                    <CorText variant={TextVariant.LABEL_2_REGULAR}>
                      <p className="text-content-muted group-hover:text-content-default">
                        {item.address}
                      </p>
                    </CorText>
                    {value === item.address ? <Check /> : null}
                  </div>
                ))}
              </div>
            ) : null}
          </div>
        </div>
        <div className="w-full border-0 border-t border-solid border-border-disabled mx-4 my-6" />
      </>
    );
  };

  const renderReportType = () => {
    return (
      <>
        <CorText variant={TextVariant.LABEL_1_MEDIUM} className="px-4">
          <div className="text-content-default">
            <T key={'annex.reportType'}>Tip Raport</T>
          </div>
        </CorText>
        <div className="flex flex-col px-4 pt-4 mb-4">
          <div className="flex gap-8">
            <CorRadioButton
              isChecked={
                ReportSearchType.TOTALIZER_REPORT === formik?.values?.annex8PharmacyHeadReportType
              }
              disabled={false}
            >
              <label className="whitespace-nowrap">
                <T keyName={'annex8PharmacyHead.totalizer'}>{ReportSearchType.TOTALIZER_REPORT}</T>
              </label>
              <input
                type="radio"
                name="annex8PharmacyHeadReportType"
                value={ReportSearchType.TOTALIZER_REPORT}
                checked={
                  ReportSearchType.TOTALIZER_REPORT === formik?.values?.annex8PharmacyHeadReportType
                }
                onChange={(event) => handleChange(event, 'annex8PharmacyHeadReportType')}
                className="bg-background-secondary"
              />
            </CorRadioButton>
            <CorRadioButton
              isChecked={
                ReportSearchType.PER_PHARMACIST === formik?.values?.annex8PharmacyHeadReportType
              }
              disabled={false}
            >
              <label className="whitespace-nowrap">
                <T keyName={'annex8PharmacyHead.perPharmacist'}>
                  {ReportSearchType.PER_PHARMACIST}
                </T>
              </label>
              <input
                type="radio"
                name="annex8PharmacyHeadReportType"
                value={ReportSearchType.PER_PHARMACIST}
                checked={
                  ReportSearchType.PER_PHARMACIST === formik?.values?.annex8PharmacyHeadReportType
                }
                onChange={formik.handleChange}
                className="bg-background-secondary"
              />
            </CorRadioButton>
            <CorRadioButton
              isChecked={
                ReportSearchType.PER_PRESCRIPTION === formik?.values?.annex8PharmacyHeadReportType
              }
              disabled={false}
            >
              <label className="whitespace-nowrap">
                <T keyName={'annex8PharmacyHead.perPrescription'}>
                  {ReportSearchType.PER_PRESCRIPTION}
                </T>
              </label>
              <input
                type="radio"
                name="annex8PharmacyHeadReportType"
                value={ReportSearchType.PER_PRESCRIPTION}
                checked={
                  ReportSearchType.PER_PRESCRIPTION === formik?.values?.annex8PharmacyHeadReportType
                }
                onChange={formik.handleChange}
                className="bg-background-secondary"
              />
            </CorRadioButton>
          </div>
        </div>
      </>
    );
  };

  const renderInputPlaceholder = () => {
    if (ReportSearchType.PER_PHARMACIST === formik?.values?.annex8PharmacyReportType) {
      return 'Introduceti idnp farmacist';
    }
    if (ReportSearchType.PER_PRESCRIPTION === formik?.values?.annex8PharmacyReportType) {
      return 'Introduceti id prescriere';
    }
  };

  const renderInput = () => {
    if (
      formik?.values?.annex8PharmacyHeadReportType === undefined ||
      formik?.values?.annex8PharmacyHeadReportType === ReportSearchType.TOTALIZER_REPORT
    ) {
      return null;
    }
    return (
      <>
        <div className="grid grid-cols-2 xs:px-4 mb-4">
          <CorInput>
            <input
              type="text"
              placeholder={renderInputPlaceholder()}
              value={formik.values.annex8PharmacyHeadSearchInput}
              onChange={(event) => {
                formik.setFieldValue('annex8PharmacyHeadSearchInput', event.target.value);
              }}
            />
          </CorInput>
        </div>
      </>
    );
  };

  return (
    <div className="flex flex-col">
      <CorText variant={TextVariant.LABEL_1_MEDIUM} className="px-4">
        <div className="text-content-default">
          <T keyName={'annex8PharmacyPsfHead.choosePsfType'}>Alege Filiala</T>
        </div>
      </CorText>
      <div className="flex flex-col px-4 pt-4 mb-4">
        <div className="flex gap-8">
          <CorRadioButton
            isChecked={
              ReportSearchType.ALL_BRANCHES === formik?.values?.annex8PharmacyHeadSelectInstitution
            }
            disabled={false}
          >
            <label className="whitespace-nowrap">
              <T keyName={'annex.searchByPharmacy'}>{ReportSearchType.ALL_BRANCHES}</T>
            </label>
            <input
              type="radio"
              name="annex8PharmacyHeadSelectInstitution"
              value={ReportSearchType.ALL_BRANCHES}
              checked={
                ReportSearchType.ALL_BRANCHES ===
                formik?.values?.annex8PharmacyHeadSelectInstitution
              }
              onChange={(event) => handleChange(event, 'annex8PharmacyHeadSelectInstitution')}
              className="bg-background-secondary"
            />
          </CorRadioButton>
          <CorRadioButton
            isChecked={
              ReportSearchType.PER_INSTITUTION_NAME ===
              formik?.values?.annex8PharmacyHeadSelectInstitution
            }
            disabled={false}
          >
            <label className="whitespace-nowrap">{ReportSearchType.PER_INSTITUTION_NAME}</label>
            <input
              type="radio"
              name="annex8PharmacyHeadSelectInstitution"
              value={ReportSearchType.PER_INSTITUTION_NAME}
              checked={
                ReportSearchType.PER_INSTITUTION_NAME ===
                formik?.values?.annex8PharmacyHeadSelectInstitution
              }
              onChange={(event) => handleChange(event, 'annex8PharmacyHeadSelectInstitution')}
              className="bg-background-secondary"
            />
          </CorRadioButton>
        </div>
      </div>
      {renderDropDown()}
      {renderReportType()}
      {renderInput()}
      <div className="w-full border-0 border-t border-solid border-border-disabled mx-4 my-6" />
    </div>
  );
};

export default Annex8ForPharmacyHead;
