import React, { memo, useRef } from 'react';
import classNames from 'classnames';
import { CorBox, CorText } from '@e-reteta/react-design-system';

import { TextVariant } from '../../enums/design-system.enum';
import { T, useTranslate } from '@tolgee/react';
import { Form, Formik, FormikProps } from 'formik';
import Accordion from '../../components/Reports/Accordion';
import { ReportType } from '../../components/Reports/report-type.enum';
import Annex1 from '../../components/Reports/Annex1';
import Annex7 from '../../components/Reports/Annex7';
import Annex8ForInstitutions from '../../components/Reports/Annex8ForInstitutions';

const DoctorInstitutionalReports = () => {
  const formikRef = useRef<FormikProps<{}> | null>(null);
  const { t } = useTranslate();

  const handleSave = async () => {
    formikRef.current?.resetForm();
  };

  return (
    <Formik
      onSubmit={handleSave}
      innerRef={formikRef}
      enableReinitialize={true}
      validateOnBlur={false}
      validateOnChange={false}
      validateOnMount={false}
      initialValues={{}}
    >
      <Form className="flex flex-col flex-1 overflow-auto" id="management-tool-form">
        <div className={classNames('flex-1 flex flex-col overflow-auto ml-6')}>
          <div className="flex flex-col flex-1 overflow-auto" id="management-tool-form">
            <div className="flex justify-between items-center gap-4">
              <CorText variant={TextVariant.HEADING_2_BOLD}>
                <h2>
                  <T keyName={'reports.reportsText'}>Reports</T>
                </h2>
              </CorText>
            </div>

            <div className="mt-4 overflow-auto">
              <CorBox className="bg-white p-2 xs:p-6 h-[calc(100vh-88px)] overflow-auto">
                <Accordion
                  report="annex1ForDrugs"
                  name={t(
                    'managementToolReports.annex1ForDrugs',
                    'Raport 1 - Varianta pentru medicamente',
                  )}
                  type={ReportType.ANNEX1_FOR_DRUGS_PER_IMS}
                >
                  <Annex1 report="annex1ForDrugs" />
                </Accordion>
                <Accordion
                  report="annex1ForDevices"
                  name={t(
                    'managementToolReports.annex1ForDevices',
                    'Raport 1 - Varianta pentru dispozitive',
                  )}
                  type={ReportType.ANNEX1_FOR_DEVICES_PER_IMS}
                >
                  <Annex1 report="annex1ForDevices" />
                </Accordion>
                <Accordion
                  report="annex4"
                  name={t('managementToolReports.annex4', 'Executarea fondurilor AOAM per IMS')}
                  type={ReportType.ANNEX4_PER_IMS}
                >
                  <Annex1 report="annex4" />
                </Accordion>
                <Accordion
                  report="annex7"
                  name={t(
                    'managementToolReports.annex7',
                    'Raport generalizat per Medici prescriptori',
                  )}
                  type={ReportType.ANNEX7_PER_IMS}
                >
                  <Annex7 />
                </Accordion>
                <Accordion
                  report="annex8ForInstitution"
                  name={t('managementToolReports.annex8ForInstitution', 'Raport descifrat')}
                  type={ReportType.ANNEX8_FOR_INSTITUTION}
                >
                  <Annex8ForInstitutions />
                </Accordion>
              </CorBox>
            </div>
          </div>
        </div>
      </Form>
    </Formik>
  );
};

export default memo(DoctorInstitutionalReports);
