import Api from '../axios/api';
import { Prescription } from '../types/Prescription';
import { HolographPrescription } from '../types/HolographPrescription';
import { Product } from '../types/product.interface';
import { DoctorData } from '../types/CommonTypes';

export interface GetPrescriptionResponse {
  data: Prescription;
}

interface GetProductsResponse {
  data: {
    items: Product[];
    cashRegister?: string;
    error?: string;
  };
}
interface GetHPrescriptionResponse {
  data: HolographPrescription;
}

export interface GetPrescriptionsResponse {
  data: {
    items: Prescription[];
    count: number;
  };
}

export interface ThirdPerson {
  firstName?: string;
  lastName?: string;
  idnp?: string;
}

export interface GetPrescriptionsRequest {
  isHolograph?: boolean;
  isDoctorUnsigned?: boolean;
  limit?: number;
  skip?: number;
  createdAtRangeInDays?: number;
  orderBy?: { createdAt: string };
}

export interface CreateDPrescriptions {
  deviceCommercialCod?: number;
  commercialName?: string;
  drugInternationalName?: string;
  drugRouteOfAdministration?: string;
  drugDose?: string;
  deviceName?: string;
  quantity: string;
  validity?: string;
  signature?: string;
  expiration?: number;
}

export interface DPrescriptions extends CreateDPrescriptions {
  id: string;
  createdAt: string;
}

export interface CreatePrescription {
  doctor?: {
    firstName?: string;
    lastName?: string;
    medicalUnit?: string;
    doctorIDNP?: string;
    speciality?: string;
  };
  pharmacist?: {
    firstName?: string;
    lastName?: string;
    medicalUnit?: string;
    idnp?: string;
  };
  patient?: {
    firstName?: string;
    lastName?: string;
    medicalUnit?: string;
    birthday?: string;
    isInsured?: boolean;
    patientIdentifier?: string;
  };
  thirdPerson?: ThirdPerson;
  diagnosticCode: string;
  dPrescriptions: CreateDPrescriptions[];
  hPrescriptionId?: string;
  prescribedAt?: string;
}

export interface UpdatePrescription {
  diagnosticCode?: string;
  dPrescriptions?: Partial<DPrescriptions>[];
  thirdPerson?: ThirdPerson;
  status?: string;
  doctor?: DoctorData;
}

interface GetPrescriptionQuery {
  statuses?: string[];
}

export interface DrugProductDto {
  internationalName: string;
  dose: string;
  routeOfAdministration: string;
  quantityLeftToDispense: number;
}

export interface DeviceProductDto {
  internationalName: string;
  quantityLeftToDispense: number;
}
export interface GetPrescriptionAvailableProductsDto {
  drugs: DrugProductDto[];
  devices: DeviceProductDto[];
}

export class PrescriptionServices {
  static getPrescriptionById(
    id: string,
    target: string = 'doctor',
    query?: GetPrescriptionQuery,
  ): Promise<GetPrescriptionResponse> {
    return Api.get(`/v1/${target}/prescriptions/${id}`, { params: query });
  }

  static getHolographPrescriptionById(
    target: string,
    id?: string,
  ): Promise<GetHPrescriptionResponse> {
    return Api.get(`/v1/${target}/holograph-prescriptions/${id}`);
  }

  static createPrescription(
    data: CreatePrescription,
    target: string = 'doctor',
  ): Promise<GetPrescriptionResponse> {
    return Api.post(`/v1/${target}/prescriptions`, data);
  }

  static updatePrescription(
    id: string,
    data: UpdatePrescription,
  ): Promise<GetPrescriptionResponse> {
    return Api.patch(`/v1/doctor/prescriptions/${id}`, data);
  }

  static deletePrescription(id: string): Promise<GetPrescriptionResponse> {
    return Api.delete(`/v1/doctor/prescriptions/${id}`);
  }

  static getAvailableProducts(
    id: string,
    query: GetPrescriptionAvailableProductsDto,
  ): Promise<GetProductsResponse> {
    return Api.get(`/v1/pharmacy/prescriptions/${id}/available-products`, {
      params: query,
    });
  }
}
