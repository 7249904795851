import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { CorButton } from '@e-reteta/react-design-system';
import { T, useTranslate } from '@tolgee/react';

import ComponentToPrint from './print';
import { ButtonVariant } from '../../../enums/design-system.enum';
import Printer from '../../../icons/Printer';
import { PrescriptionPrintProps } from './prescription-print.interface';
import { IconName } from '../../../enums/design-system-icons.enum';
import { LinkButton } from '../../index';
import { InstitutionService } from '../../../services/institution.service';

const PrescriptionPrint = (props: PrescriptionPrintProps) => {
  const { t } = useTranslate();
  const { prescription, buttonForPrint } = props;
  const componentRef = useRef(null);

  const onBeforeGetContentResolve = useRef<unknown>(null);

  const [medicalUnit, setMedicalUnit] = useState<string>('');

  const getMedicalUnitName = useCallback(async (codIms?: string) => {
    const inst = await InstitutionService.getAll('auth', {
      codIms: codIms,
    });
    setMedicalUnit(inst.data.items?.at(0)?.imsp || '');
  }, []);

  const handleOnBeforeGetContent = useCallback(async () => {
    return new Promise<void>((resolve) => {
      onBeforeGetContentResolve.current = resolve;
      resolve();
    });
  }, []);

  const reactToPrintContent = useCallback(() => {
    return componentRef.current;
  }, []);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: prescription?.id,
    onBeforeGetContent: handleOnBeforeGetContent,
    removeAfterPrint: true,
  });

  const handlePrintClick = async () => {
    if (!medicalUnit) {
      await getMedicalUnitName(prescription?.doctor?.medicalUnit);
    }

    await new Promise((resolve) => setTimeout(resolve, 0));
    handlePrint();
  };

  useEffect(() => {
    if (typeof onBeforeGetContentResolve.current === 'function') {
      onBeforeGetContentResolve.current();
    }
  }, []);

  return (
    <>
      {buttonForPrint === 'table' ? (
        <LinkButton
          onClick={handlePrintClick}
          text={t('prescriptionPrint.print', 'Printeaza')}
          iconName={IconName.PRINTER}
        />
      ) : null}
      {buttonForPrint === 'view' ? (
        <CorButton className="w-full" variant={ButtonVariant.SECONDARY}>
          <button className="gap-1.5 pr-3 w-full" type="button" onClick={handlePrintClick}>
            <T keyName="prescriptionPrint.print">Printeaza</T>
            <Printer width={16} height={16} className="fill-[#008000]" />
          </button>
        </CorButton>
      ) : null}
      <div style={{ display: 'none' }}>
        <ComponentToPrint
          ref={componentRef}
          prescription={{ ...prescription, doctor: { ...prescription?.doctor, medicalUnit } }}
        />
      </div>
    </>
  );
};

export default PrescriptionPrint;
