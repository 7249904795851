import classNames from 'classnames';
import { CorBox, CorButton, CorDatepicker, CorInput, CorText } from '@e-reteta/react-design-system';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useFormikContext } from 'formik';
import { T, useTolgee, useTranslate } from '@tolgee/react';
import dayjs from 'dayjs';

import 'dayjs/locale/ro';
import 'dayjs/locale/ru';
import 'dayjs/locale/en';

import { ChevronDown } from '../../../icons';
import { AccordionProps } from './accordion.interface';
import { getLocale } from '../../../utils/getLocale';
import { ButtonVariant, TextVariant } from '../../../enums/design-system.enum';
import ExportMenu from '../../ExportMenu';
import { AuthContext } from '../../../context/context';

var localizedFormat = require('dayjs/plugin/localizedFormat');
dayjs.extend(localizedFormat);

const Accordion = (props: AccordionProps) => {
  const { t } = useTranslate();
  const formik = useFormikContext<any>();
  const tolgee = useTolgee(['pendingLanguage']);
  const buttonRef = useRef<HTMLDivElement>(null);
  const {} = useContext(AuthContext);

  const { name, children, type, datePickerPositionTop = false } = props;
  const [expandedMenu, setExpandedMenu] = useState(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const callback = useCallback(
    (event: any, field: string, fromDate: boolean) => {
      event.preventDefault();
      let date = dayjs(event.detail).toISOString();
      if (fromDate) {
        date = dayjs(event.detail).startOf('day').toISOString();
      } else {
        date = dayjs(event.detail).endOf('day').toISOString();
      }
      formik.setFieldValue(field, date);
    },
    [formik],
  );

  const handleClickOutside = (event: any) => {
    if (buttonRef.current && !buttonRef.current.contains(event.target)) {
      setExpandedMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return (
    <div className="bg-background-secondary p-4 border-0 border-t border-solid border-border-default">
      <CorBox boxStyle="none" className="relative">
        <CorBox
          boxStyle="none"
          flexDirection="row"
          className={classNames('justify-between items-center cursor-pointer', { 'pb-8': isOpen })}
          onClick={() => setIsOpen((value) => !value)}
        >
          <CorText variant={TextVariant.HEADING_4_MEDIUM}>
            <div className="text-content-default">{name}</div>
          </CorText>
          <ChevronDown
            width={24}
            height={24}
            className={classNames('duration-100 ease-in-out', { 'transform rotate-180': isOpen })}
          />
        </CorBox>
        {isOpen ? (
          <div className="flex flex-col">
            <CorText variant={TextVariant.LABEL_1_MEDIUM} className="xs:px-4 mb-4">
              <div className="text-content-default">
                <T keyName={'acordion.chooseDate'}>Alege Data</T>
              </div>
            </CorText>
            <div className="flex gap-4 items-center xs:px-4 pb-6">
              <div className="flex flex-col md:flex-row gap-2 items-center">
                <CorInput>
                  <CorDatepicker
                    value={formik.values[`${props.report}FromDate`]}
                    label="Date"
                    name={`${props.report}FromDate`}
                    placeholder={t('acordion.selectDatePlaceholder', 'Select date')}
                    onCallback={(e: any) => callback(e, `${props.report}FromDate`, true)}
                    positionTop={datePickerPositionTop}
                    language={getLocale(tolgee)}
                    max={formik.values[`${props.report}ToDate`]}
                  />
                </CorInput>
                <CorText>
                  <div className="text-sm leading-4 text-content-muted">-</div>
                </CorText>
                <CorInput>
                  <CorDatepicker
                    value={formik.values[`${props.report}ToDate`]}
                    label="Date"
                    name={`${props.report}ToDate`}
                    placeholder={t('acordion.selectDatePlaceholder', 'Select date')}
                    onCallback={(e: any) => callback(e, `${props.report}ToDate`, false)}
                    positionTop={datePickerPositionTop}
                    language={getLocale(tolgee)}
                    min={formik.values[`${props.report}FromDate`]}
                  />
                </CorInput>
              </div>
            </div>
            <div className="w-full border-0 border-t border-solid border-border-disabled mx-4 mb-6" />
            {children}
            <div className="relative z-40 w-fit px-4" ref={buttonRef}>
              <CorButton
                className="group"
                variant={ButtonVariant.PRIMARY}
                onClick={() => setExpandedMenu((prev) => !prev)}
              >
                <button type="button">
                  <T keyName="acordion.download">Descarca</T>
                  <ChevronDown
                    className={classNames('w-4 h-4 fill-white', {
                      'rotate-180': expandedMenu,
                    })}
                  />
                </button>
              </CorButton>
              <ExportMenu expanded={expandedMenu} setExpanded={setExpandedMenu} reportType={type} />
            </div>
          </div>
        ) : null}
      </CorBox>
    </div>
  );
};

export default Accordion;
