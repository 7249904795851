import React, { ReactNode, useMemo, useState } from 'react';

import { LoaderContext } from './context';
import { LoaderProviderProps } from './context.types';

export const LoaderContextProvider = ({ children }: LoaderProviderProps): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);

  const value = useMemo(
    () => ({
      loading,
      setLoading,
    }),
    [loading],
  );

  return <LoaderContext.Provider value={value}>{children}</LoaderContext.Provider>;
};

const LoaderProvider = ({ children }: { children: ReactNode }) => {
  return <LoaderContextProvider>{children}</LoaderContextProvider>;
};

export default LoaderProvider;
