import { createContext } from 'react';

import { AuthContextProps } from './context.types';

export const AuthContext = createContext<AuthContextProps>({
  user: undefined,
  setUser: () => null,
  role: undefined,
  setRole: () => null,
  logout: (ssoLogout: boolean) => null,
  hasRole: (role: string) => undefined,
  mainRoute: undefined,
});
