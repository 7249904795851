import Api from '../axios/api';
import type { PaginationQuery } from '../types/PaginationQuery';

export interface SpecialityDto {
  name: string;
  prescribedDisease: DiseaseDto[];
  rePrescribedDisease: DiseaseDto[];
  createdAt: string;
  updatedAt: string;
  prescribedDiseasesCodes: string[];
  rePrescribedDiseasesCodes: string[];
}

export interface CreateSpecialityDto {
  name: string;
  prescribedDiseasesCodes: string[];
  rePrescribedDiseasesCodes: string[];
}

export interface UpdateSpecialityDto {
  prescribedDiseasesCodes: string[];
  rePrescribedDiseasesCodes: string[];
}

export interface DiseaseDto {
  code: string;
  name: string;
  createdAt: string;
  updatedAt: string;
}

export interface GetSpecialitiesResponse {
  data: {
    items?: SpecialityDto[];
    count: number;
  };
}

export interface MessageResponse {
  message: string;
}

export class SpecialityService {
  static getAll(query: PaginationQuery, search?: string): Promise<GetSpecialitiesResponse> {
    return Api.get(`/v1/admin/specialities`, {
      params: { ...query, search },
    });
  }

  static delete(name: string): Promise<MessageResponse> {
    return Api.delete(`/v1/admin/specialities/${name}`);
  }

  static bulkUpload(data: FormData): Promise<MessageResponse> {
    return Api.post(`/v1/admin/specialities/upload`, data);
  }

  static create(body: CreateSpecialityDto): Promise<MessageResponse> {
    return Api.post(`/v1/admin/specialities`, body);
  }

  static update(name: string, body: UpdateSpecialityDto): Promise<SpecialityDto> {
    return Api.patch(`/v1/admin/specialities/${name}`, body);
  }

  static download(type: string) {
    return Api.get(`/v1/admin/specialities/download.${type}`, {
      responseType: 'blob',
    });
  }

  static getByName(name: string) {
    return Api.get(`/v1/doctor/specialities/${name}`);
  }
}
