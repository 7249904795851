import Api from '../axios/api';
import { PharmacyDto } from './pharmacies.services';
import { SettingsStatusType } from '../enums/settings-status.enum';

export interface PharmacySettingsDto {
  idno: string;
  isActive: boolean;
  pullProductsURL: string;
  pushDispensesURL: string;
  secretToken: string;
  integrationStatus: SettingsStatusType;
  pharmacy?: PharmacyDto;
  createdAt: Date;
  updatedAt: Date;
  integration?: boolean;
}

interface OrderBy {
  createdAt?: string;
  idno?: string;
}

interface PharmacySettingsQueryParams {
  idnos?: string[];
  statuses?: SettingsStatusType[];
  isActive?: boolean;
  orderBy?: OrderBy;
  skip?: number;
  limit?: number;
  search?: string;
  includePharmacy?: boolean;
}

export interface GetPharmacySettingsResponseDto {
  data: {
    items: PharmacySettingsDto[];
    count: number;
  };
}

export interface GetPharmacySettingResponseDto {
  data: PharmacySettingsDto;
}

export interface CreatePharmacySettings {
  idno: string;
  isActive: boolean;
  pullProductsURL: string;
  pushDispensesURL: string;
  integrationStatus: SettingsStatusType;
}

export interface UpdatePharmacySettings {
  idno?: string;
  isActive?: boolean;
  pullProductsURL?: string;
  pushDispensesURL?: string;
  integrationStatus?: SettingsStatusType;
}

export interface GeneratedCodeResponse {
  data: { secretToken: string };
}

export class PharmaciesSettingsServices {
  static getAll(params: PharmacySettingsQueryParams): Promise<GetPharmacySettingsResponseDto> {
    return Api.get(`/v1/admin/pharmacy-settings`, { params });
  }

  static getByIDNOForPharmacy(): Promise<GetPharmacySettingResponseDto> {
    return Api.get(`/v1/pharmacy/pharmacy-settings`);
  }

  static create(body: CreatePharmacySettings): Promise<GetPharmacySettingsResponseDto> {
    return Api.post(`/v1/admin/pharmacy-settings`, body);
  }

  static update(body: UpdatePharmacySettings): Promise<GetPharmacySettingsResponseDto> {
    return Api.patch(`/v1/admin/pharmacy-settings/${body.idno}`, body);
  }

  static pharmacyUpdate(body: UpdatePharmacySettings): Promise<GetPharmacySettingsResponseDto> {
    return Api.patch(`/v1/pharmacy/pharmacy-settings`, body);
  }

  static generateCode(): Promise<GeneratedCodeResponse> {
    return Api.post(`/v1/pharmacy/pharmacy-settings/regenerate-token`);
  }
}
