import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { isAxiosError } from 'axios';
import { T, useTolgee, useTranslate } from '@tolgee/react';
import {
  CorBox,
  CorButton,
  CorDatepicker,
  CorIcon,
  CorInput,
  CorText,
} from '@e-reteta/react-design-system';

import { IconColor, IconName, IconSize } from '../../enums/design-system-icons.enum';
import { Card } from '../index';
import { TextVariant } from '../../enums/design-system.enum';
import { CardProps } from '../Card/card-props.interface';
import { PharmacyService } from '../../services/pharmacy.services';
import { PatientAssignmentProps } from './interface';
import validateSearch from '../../utils/validate-search';
import { handleError } from '../../utils/handleRequestErrors';
import { PatientAsignmentData } from '../../types/CommonTypes';
import { getLocale } from '../../utils/getLocale';
import { formatDate } from '../../utils/formatDate';

const PatientAssignment: FC<PatientAssignmentProps> = ({ id }) => {
  const { t } = useTranslate();
  const tolgee = useTolgee(['pendingLanguage']);
  const inputRef = useRef<any>(null);

  const navigate = useNavigate();
  const [dateValue, setDateValue] = useState<string>(dayjs().toISOString());
  const [inputValue, setInputValue] = useState('');
  const [patient, setPatient] = useState<PatientAsignmentData>();
  const [selectedCard, setSelectedCard] = useState<Map<string, boolean>>(new Map());

  const onSendBtn = () => {
    navigate(
      `/patient/${patient?.id}/prescription/new?holographId=${id}&prescribedAt=${dateValue}`,
    );
  };

  const handleSelectedCard = (item: CardProps) => {
    selectedCard.clear();
    setSelectedCard(new Map(selectedCard.set(item?.id!, !item.selected)));
  };

  const handleDisabled = useCallback(() => {
    return !(dateValue && selectedCard.size !== 0);
  }, [dateValue, selectedCard.size]);

  const clearIfIinputEmpty = () => {
    if (inputValue !== '') {
      selectedCard.clear();
    }
  };

  useEffect(() => {
    const validateIDNP = validateSearch(inputValue, 13);

    if (inputValue !== '' && validateIDNP) {
      const delayInputTimeoutId = setTimeout(async () => {
        try {
          const { data } = await PharmacyService.getPatient({ basicOnly: true, idnp: inputValue });
          setPatient(data);
        } catch (error) {
          isAxiosError(error) && handleError(error);
        }
      }, 500);
      return () => clearTimeout(delayInputTimeoutId);
    } else {
      setPatient(undefined);
    }
  }, [inputValue]);

  const renderBirthday = () => {
    if (patient?.birthday) {
      return formatDate(patient.birthday);
    }
    return formatDate(dayjs().toISOString());
  };

  const handleInputChange = () => {
    const inputDate: HTMLInputElement | null = document?.querySelector('datepicker-input input');
    if (inputDate) {
      if (!inputDate.value) {
        setDateValue('');
      }
    }
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current?.addEventListener('corInputOnChangeValue', handleInputChange);
    }
    return () => {
      if (inputRef.current) {
        inputRef.current?.removeEventListener('corInputOnChangeValue', handleInputChange);
      }
    };
  }, []);

  return (
    <>
      <CorBox
        boxStyle="none"
        className=" flex flex-col items-start pt-5 px-6 pb-6 gap-4 bg-white rounded-3xl h-full"
      >
        <CorText variant={TextVariant.HEADING_4_BOLD}>
          <span>
            <T keyName="patientAssignment.asignPatient">Asignare pacient</T>
          </span>
        </CorText>
        <div className="flex flex-col sm:flex-row sm:items-end gap-4 w-full">
          <CorInput>
            <label className="text-sm text-content-muted">
              <T keyName="patientAssignment.patientSearch">Cautare pacient</T>
            </label>
            <input
              type="text"
              placeholder={t(
                'patientAssignment.identifierOrTemporaryDoc',
                'IDNP sau Nr. actului provizoriu',
              )}
              onChange={(e) => {
                setInputValue(e.target.value);
                clearIfIinputEmpty();
              }}
            />
          </CorInput>
          <div className="flex flex-col gap-1 h-full">
            <label className="text-sm text-content-muted">
              <T keyName="patientAssignment.prescriptionDate">Data prescrierii</T>
            </label>
            <CorDatepicker
              ref={inputRef}
              value={dateValue}
              max={dayjs().format('YYYY-MM-DD')}
              label="Date"
              name="Datepicker"
              placeholder={t('patientAssignment.selectDate', 'Select date')}
              onCallback={({ detail }) => {
                setDateValue(detail);
              }}
              language={getLocale(tolgee)}
            />
          </div>
        </div>
        {inputValue !== '' ? <div className="h-px w-full bg-border-default" /> : null}
        <div className="grid grid-cols-1 sm:grid-cols-5 2md:grid-cols-8 xl:grid-cols-12 items-start gap-2 h-fit w-full">
          {patient ? (
            <Card
              className="col-span-4 hover:cursor-pointer"
              isInsured={patient.isInsured!}
              firstName={patient.firstName!}
              lastName={patient.lastName!}
              id={patient.id}
              birthday={renderBirthday()}
              gender={patient.gender!}
              selected={selectedCard.has(patient?.id!)}
              onClick={() => {
                handleSelectedCard(patient as CardProps);
              }}
            ></Card>
          ) : null}
        </div>
      </CorBox>

      <div className="flex justify-center sm:justify-end h-fit sm:pb-4">
        <CorButton size="medium" className="w-full sm:w-fit" onClick={onSendBtn}>
          <button disabled={handleDisabled()} className="w-full">
            <T keyName="patientAssignment.continue">Continua</T>
            <CorIcon
              size={IconSize.SM}
              name={IconName.ARROW_RIGHT}
              color={IconColor.WHITE}
            ></CorIcon>
          </button>
        </CorButton>
      </div>
    </>
  );
};

export default PatientAssignment;
