import React, { FC, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CorText } from '@e-reteta/react-design-system';
import { isAxiosError } from 'axios';
import { T, useTranslate } from '@tolgee/react';

import { SearchInput } from '../../components';
import { TextVariant } from '../../enums/design-system.enum';
import { PatientServices } from '../../services/patient.services';
import useWindowWidth from '../../hooks/use-window-width';
import { PrescriptionServices } from '../../services/prescription.services';
import { QueryParams } from '../../services/doctorsPrescription.services';
import { AuthContext } from '../../context/context';
import { handleError } from '../../utils/handleRequestErrors';
import { SwitchEnum } from '../../enums/switch.enum';
import { renderResponsiveClasses } from '../../utils/render-responsive-classes.utils';
import { LoaderContext } from '../../context/loader/context';

const Pharmacy: FC = () => {
  const { t } = useTranslate();
  const navigate = useNavigate();
  const { isTablet, isMobile } = useWindowWidth();
  const [notFound, setNotFound] = useState(false);
  const [searchCategory, setSearchCategory] = useState<SwitchEnum>(SwitchEnum.PATIENT);
  const { role } = useContext(AuthContext);
  const { setLoading } = useContext(LoaderContext);

  const holographErrorMessage = t(
    'pharmacy.holographErrorMessage',
    'Reteta pe care o cautati nu exista',
  );

  const searchPatientOrPrescription = async (value: string) => {
    try {
      if (role) {
        setLoading(true);
        if (searchCategory === SwitchEnum.PATIENT) {
          const query: QueryParams = {
            patientId: value,
          };
          let patient = await PatientServices.getPatient(role, query, true);
          if (!patient) {
            setNotFound(true);
            return;
          }
          setLoading(false);
          navigate(`/patient/${patient.data.id}`);
        }

        if (searchCategory === SwitchEnum.PRESCRIPTION) {
          try {
            let { data } = await PrescriptionServices.getPrescriptionById(value, role);
            setLoading(false);
            if (data && !data?.hPrescriptionId) {
              navigate(`/patient/${data?.patientIdentifier}/prescription/${data?.id}`);
              return;
            }
            if (data?.id) {
              navigate(`/holographic-recipe/${data?.id}`);
            }
          } catch (e: any) {
            if (e.response?.status === 404 && value) {
              let hPrescription = await PrescriptionServices.getHolographPrescriptionById(
                role,
                value,
              );
              if (!hPrescription) {
                setNotFound(true);
                return;
              }

              setLoading(false);
              if (hPrescription && hPrescription?.data.id) {
                navigate(`/holographic-recipe/${hPrescription?.data.id}`);
              }
            }
          }
        }
      }
    } catch (error) {
      setNotFound(true);
      isAxiosError(error) && handleError(error, holographErrorMessage);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setTimeout(() => setLoading(false), 0);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex flex-col overflow-auto sm:pl-6 sm:p-4 lg:pl-6 lg:p-0 relative h-full">
      <div className="flex flex-col gap-3">
        <SearchInput
          placeholder1={'IDNP sau Nr. actului provizoriu'}
          placeholder2={'ID Reteta'}
          translationKey1="pharmacy.identifierOrTemporaryDoc"
          translationKey2="pharmacy.prescriptionId"
          switchValue1={SwitchEnum.PATIENT}
          switchValue2={SwitchEnum.PRESCRIPTION}
          onSubmit={(value) => searchPatientOrPrescription(value)}
          onChange={(value) => setSearchCategory(value)}
          withButton
          withSwitch
        />
      </div>

      {!notFound ? (
        <div
          className={`flex w-full h-full justify-center items-center p-4 rounded-2xl mt-6 ${
            !notFound ? 'block' : 'hidden'
          }`}
        >
          <div className="flex flex-col gap-8 w-[301px] sm:w-[401px] items-center text-center">
            <CorText
              variant={renderResponsiveClasses(
                isMobile,
                isTablet,
                TextVariant.HEADING_4_BOLD,
                TextVariant.HEADING_3_BOLD,
                TextVariant.HEADING_2_BOLD,
              )}
            >
              <span className="text-content-default text-center">
                <T keyName="pharmacy.startPatientSearch">
                  Incepeti cautarea unui pacient sau a unei retete in bara de cautare
                </T>
              </span>
            </CorText>
            <img className="w-[200px] sm:w-[400px]" src="/search.png" alt="search" />
          </div>
        </div>
      ) : null}
      {notFound ? (
        <div className="flex-1 flex items-center justify-center overflow-auto gap-4 p-4 my-6 flex-col">
          <div className="w-[350px] text-center">
            <CorText
              variant={renderResponsiveClasses(
                isMobile,
                isTablet,
                TextVariant.HEADING_4_BOLD,
                TextVariant.HEADING_3_BOLD,
                TextVariant.HEADING_2_BOLD,
              )}
            >
              <span className="text-content-default">
                <T keyName="pharmacy.notFoundPatientOrPrescription">
                  Nu a fost gasit nici un pacient sau o reteta in baza informatiei introduse
                </T>
              </span>
            </CorText>
          </div>
          <img src="/not-found.png" alt="not found loop" className="w-[200px] sm:w-[400px]" />
        </div>
      ) : null}
    </div>
  );
};

export default Pharmacy;
