import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { isAxiosError } from 'axios';
import { CorInput } from '@e-reteta/react-design-system';

import { handleError } from '../../../../utils/handleRequestErrors';

const PriceInput: FC<PriceInputProps> = ({
  formik,
  autoComplete,
  type,
  min,
  placeholder,
  name,
  value,
  error,
  errorMsg,
  onWheel,
}) => {
  const [formatedPrice, setFormatedPrice] = useState<number | string>(value!);

  const handlePriceChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = parseFloat(e.target.value);
    setFormatedPrice(e.target.value);
    formik.setFieldValue(name, newValue);
  };

  useEffect(() => {
    if (formatedPrice !== '') {
      const delayInputTimeoutId = setTimeout(async () => {
        try {
          if (value && value === Math.floor(value)) {
            setFormatedPrice(value);
          } else {
            setFormatedPrice(value?.toFixed(2)!);
          }
        } catch (error) {
          isAxiosError(error) && handleError(error);
        }
      }, 500);
      return () => clearTimeout(delayInputTimeoutId);
    }
  }, [formatedPrice, value]);

  return (
    <CorInput error={error}>
      <input
        autoComplete={autoComplete}
        type={type}
        min={min}
        placeholder={placeholder}
        name={name}
        value={formatedPrice}
        onChange={handlePriceChange}
        step=".01"
        onWheel={(event) => (onWheel ? onWheel(event) : null)}
      />
      {error ? <span className="bottom-helper">{errorMsg}</span> : null}
    </CorInput>
  );
};

export default PriceInput;
