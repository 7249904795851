import { CorText } from '@e-reteta/react-design-system';

import Copy from '../../../../../components/Copy';

import { TextVariant } from '../../../../../enums/design-system.enum';
import { TextCellProps } from './interface';
import { useState } from 'react';

const TextCell = (props: TextCellProps) => {
  const { value, width, widthType } = props;
  const [copy, setCopy] = useState<boolean>(false);

  const showCopy = () => {
    setCopy(true);
  };
  const hideCopy = () => {
    setCopy(false);
  };

  return (
    <CorText
      className={`shrink-0 overflow-hidden text-ellipsis relative`}
      variant={TextVariant.LABEL_2_MEDIUM}
      style={{ width: `${width}${widthType}` }}
      onMouseOver={showCopy}
      onMouseOut={hideCopy}
    >
      <span className="text-content-default">{value ?? '-'}</span>

      {value && copy ? <Copy textToCopy={value} /> : null}
    </CorText>
  );
};

export default TextCell;
