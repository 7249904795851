import { FC } from 'react';
import { CorBox, CorText } from '@e-reteta/react-design-system';
import dayjs from 'dayjs';
import hash from 'object-hash';
import { useTranslate } from '@tolgee/react';

import type { PatientProfileCardProps } from './interface';
import * as icons from '../../../icons';
import { TextVariant } from '../../../enums/design-system.enum';
import useWindowWidth from '../../../hooks/use-window-width';
import { renderResponsiveClasses } from '../../../utils/render-responsive-classes.utils';
import { InsuredStatusEnums } from '../../PatientCard/enums';
import { formatDate } from '../../../utils/formatDate';

const PatientProfileCard: FC<PatientProfileCardProps> = ({
  gender,
  idno,
  birthday,
  firstName,
  lastName,
  isInsured,
}) => {
  const { t } = useTranslate();

  const { isTablet, isMobile } = useWindowWidth();
  const fields = [
    {
      key: t('patientProfileCard.identifier', 'IDNP'),
      value: idno,
      Icon: icons.User,
    },
    {
      key: t('patientProfileCard.birthday', 'Data Nasterii'),
      value: birthday
        ? (() => {
            const date = formatDate(birthday);
            const now = dayjs();
            const parsedBd = dayjs(birthday);
            const diff = dayjs(now.diff(parsedBd));
            const years = Math.abs(1970 - diff.year());

            return `${date} | ${years} ani`;
          })()
        : 'N/A',
      Icon: icons.Calendar,
    },
    {
      key: t('patientProfileCard.gender', 'Gen'),
      value: gender,
      Icon: icons.Gender,
    },
    {
      key: t('patientProfileCard.status', 'Statut'),
      value: isInsured ? InsuredStatusEnums.INSURED : InsuredStatusEnums.UNINSURED,
      Icon: icons.Shield,
    },
  ];

  return (
    <CorBox
      boxStyle="none"
      px="lg"
      className="gap-5 bg-background-primary h-fit py-3 px-4 lg:py-5 lg:px-6 mt-4 rounded-[20px]"
    >
      <header>
        <CorText
          variant={renderResponsiveClasses(
            isMobile,
            isTablet,
            TextVariant.LABEL_1_BOLD,
            TextVariant.HEADING_4_BOLD,
            TextVariant.HEADING_3_BOLD,
          )}
        >
          <span className="font-semibold">
            {firstName} {lastName}
          </span>
        </CorText>
      </header>
      <CorBox
        flexDirection="row"
        boxStyle="none"
        className="grid grid-cols-2 2md:flex items-center justify-between lg:justify-start gap-x-4 gap-y-2 xl:gap-[72px]"
      >
        {fields.map(({ key, value, Icon }) => (
          <div
            key={hash(key)}
            className="lg:flex-1 xl:flex-none flex items-center justify-start gap-4"
          >
            <div className="hidden sm:flex rounded-lg p-3 bg-surface-default max-w-[56px] max-h-[56px]">
              <Icon width={32} height={32} />
            </div>
            <div className="flex flex-col justify-center">
              <CorText variant={TextVariant.LABEL_2_REGULAR}>
                <span className="text-content-muted">{key}</span>
              </CorText>
              <CorText variant={TextVariant.LABEL_2_MEDIUM}>
                <span className="font-semibold">{value}</span>
              </CorText>
            </div>
          </div>
        ))}
      </CorBox>
    </CorBox>
  );
};

export default PatientProfileCard;
