import { SVGProps } from 'react';

const ClosePanel = (props: SVGProps<any>) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect opacity="0.3" x="1.5" y="2" width="15" height="14" rx="2.5" stroke="#343841" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.62814 6.73162C9.45729 6.56425 9.45729 6.29289 9.62814 6.12553C9.799 5.95816 10.076 5.95816 10.2469 6.12553L12.8719 8.69695C13.0375 8.8592 13.0433 9.12045 12.885 9.28959L10.4788 11.861C10.3155 12.0355 10.0387 12.0473 9.86062 11.8873C9.68251 11.7274 9.67047 11.4563 9.83375 11.2818L11.9569 9.01289L9.62814 6.73162Z"
        fill="#343841"
      />
      <path opacity="0.3" d="M6 2.5V15.5" stroke="#343841" />
    </svg>
  );
};

export default ClosePanel;
