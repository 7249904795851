import { T, useTranslate } from '@tolgee/react';
import { CorBadge, CorBox, CorText } from '@e-reteta/react-design-system';
import React, { useEffect, useState } from 'react';
import { isAxiosError } from 'axios';
import { SpinnerCircularFixed } from 'spinners-react';
import dayjs from 'dayjs';

import Alert from '../../Alert';
import { handleError } from '../../../utils/handleRequestErrors';
import { AiResponseDto, AiService, AskQuery, Document } from '../../../services/ai.services';
import { AlertStatus } from '../../Alert/enum';
import { BadgeColor, BadgeSize, TextVariant } from '../../../enums/design-system.enum';
import SearchInput from '../SearchInput';
import { QuestionTabProps } from './question-tab.interface';
import Like from '../../../icons/Like';
import Dislike from '../../../icons/Dislike';
import { ChevronDown, Link } from '../../../icons';
import { formatDate } from '../../../utils/formatDate';
import { DropdownEnum } from '../SearchInput/dropdown.enum';

const QuestionTab = (props: QuestionTabProps) => {
  const { description, setNotification } = props;
  const { t } = useTranslate();
  const [insight, setInsight] = useState<AiResponseDto>();
  const [errorMessage, setError] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [notFound, setNotFound] = useState<boolean>(false);
  const [selectedCard, setSelectedCard] = useState<Document>();
  const [selectedDropDown, setSelectedDropdown] = useState<DropdownEnum>(DropdownEnum.NONE);

  const searchForResponses = async (query: string) => {
    if (query?.length > 3) {
      setLoading(true);
      const language = localStorage.getItem('saved_language');
      try {
        const params: AskQuery = { query };
        if (language) {
          params.language = language;
        } else {
          params.language = 'ro';
        }
        if (selectedDropDown === DropdownEnum.QUOTED) {
          params.orderByCitations = true;
        }
        if (selectedDropDown === DropdownEnum.RECENT) {
          const currentDate = dayjs();
          params.dateFrom = currentDate.subtract(5, 'years').toISOString();
        }
        const response = await AiService.ask(params);
        setInsight(response.data);
        if (!response.data) {
          setNotFound(true);
        } else {
          setNotFound(false);
        }
        setNotification(true);
        setLoading(false);
        setError('');
      } catch (error) {
        if (error instanceof Error) {
          setError(error.toString());
          setLoading(false);
          setNotFound(true);
        }
        isAxiosError(error) && handleError(error);
      }

      return;
    }

    setError(t('einsteinDrawer.errorMessage', 'Fraza trebuie a contie mai mult de 3 caractere'));
  };

  useEffect(() => {
    setError('');
  }, [localStorage.getItem('saved_language')]);

  const openInNewTab = (url: string) => {
    window.open(url, '_blank');
  };

  const handleSelect = (doc: Document) => {
    setSelectedCard(doc);
  };

  return (
    <div>
      {!!errorMessage?.length && (
        <div className="mx-6">
          <Alert text={errorMessage} className="h-fit w-full mt-5" status={AlertStatus.DANGER} />
        </div>
      )}
      <>
        <CorText className="py-5 px-6">
          <div className="text-sm text-surface-neutral-inverted-hover">{description}</div>
        </CorText>
        <div className="px-6">
          <SearchInput
            onClick={searchForResponses}
            selected={selectedDropDown}
            setSelected={setSelectedDropdown}
          />
        </div>
        {loading ? (
          <div className="flex-none w-full h-[calc(100vh-390px)] flex flex-col gap-8 justify-center items-center px-6">
            <SpinnerCircularFixed
              size={56}
              thickness={100}
              speed={100}
              color="#80C080"
              secondaryColor="#E6E6EA"
            />
            <CorText variant={TextVariant.HEADING_4_MEDIUM}>
              <h5 className="text-center text-surface-neutral-inverted-hover">
                <T keyName={'einsteinDrawer.loadingText'}>
                  Vă rugăm să așteptați, raspunsul se încarcă...
                </T>
              </h5>
            </CorText>
          </div>
        ) : null}
        {!loading && notFound ? (
          <div className="flex-none w-full h-[calc(100vh-390px)] flex flex-col gap-8 justify-center items-center px-6">
            <img
              src="/not-found-qa.png"
              alt="not-found"
              className="rounded-2xl cursor-pointer"
              height={127}
            />
            <CorText variant={TextVariant.HEADING_4_MEDIUM}>
              <h5 className="text-center">
                <T keyName={'einsteinDrawer.notFound'}>Nu a fost gasit nici un rezultat</T>
              </h5>
            </CorText>
          </div>
        ) : null}
        {!loading && !notFound && insight && !selectedCard && (
          <div className="bg-background-secondary px-6 h-[calc(100vh-305px)] pt-5 mt-5 border-t border-b-0 border-x-0 border-solid border-border-default overflow-x-auto">
            <CorText variant={TextVariant.BODY_1_REGULAR}>
              <p>{insight?.answer}</p>
            </CorText>
            <div className="flex gap-5 pt-5 pb-[30px]">
              <Like className="fill-transparent stroke-action-neutral-default hover:stroke-action-primary-default" />
              <Dislike className="fill-transparent stroke-action-neutral-default hover:stroke-action-primary-default" />
            </div>
            <div className="flex flex-col gap-5 mb-5">
              {insight?.documents?.map((doc) => {
                return (
                  <CorBox
                    key={`art_${doc.id}`}
                    boxStyle="none"
                    flexDirection="column"
                    className="bg-white cursor-pointer p-4 border border-solid border-border-hover rounded-[18px] overflow-hidden h-fit"
                    onClick={() => handleSelect(doc)}
                  >
                    <div className="flex justify-between mb-5">
                      <CorBadge color={BadgeColor.GREY} size={BadgeSize.SM}>
                        <span>{doc.index_name}</span>
                      </CorBadge>
                      <div className="flex gap-8 text-content-muted">
                        <CorText>
                          <div>{formatDate(doc.pubdate)}</div>
                        </CorText>
                        <CorText>
                          <div>{doc.no_citations}</div>
                        </CorText>
                      </div>
                    </div>
                    <CorText variant={TextVariant.BODY_1_BOLD}>
                      <p> {doc.title}</p>
                    </CorText>
                    <CorText variant={TextVariant.BODY_1_REGULAR}>
                      <p className="line-clamp-3">{doc.abs}</p>
                    </CorText>
                  </CorBox>
                );
              })}
            </div>
          </div>
        )}
        {!loading && selectedCard ? (
          <div className="bg-background-secondary px-6 h-[calc(100vh-305px)] pt-5 mt-5 border-t border-b-0 border-x-0 border-solid border-border-default flex flex-col gap-5">
            <div className="flex justify-between">
              <div className="flex gap-5">
                <ChevronDown
                  className="w-5 h-5 fill-action-primary-default rotate-90"
                  onClick={() => setSelectedCard(undefined)}
                />
                <CorBadge color={BadgeColor.GREY} size={BadgeSize.SM}>
                  <span>{selectedCard.index_name}</span>
                </CorBadge>
              </div>
              <div className="flex gap-8 text-content-muted">
                <CorText>
                  <div>
                    <T keyName="einsteinDrawer.date" params={{ pubdate: selectedCard.pubdate }}>
                      Date
                    </T>
                  </div>
                </CorText>
                <CorText>
                  <div>
                    <T
                      keyName="einsteinDrawer.citations"
                      params={{ noCitations: selectedCard.no_citations }}
                    >
                      Citations
                    </T>
                  </div>
                </CorText>
              </div>
            </div>
            <CorText variant={TextVariant.HEADING_4_BOLD}>
              <p className="text-content-default"> {selectedCard.title}</p>
            </CorText>
            <div className="flex flex-col gap-1.5">
              <div
                className="flex gap-2 border-b border-t-0 border-x-0 border-border-success-default border-solid items-center w-fit cursor-pointer"
                onClick={() => openInNewTab(selectedCard?.source)}
              >
                <Link className="fill-white stroke-action-primary-default" />
                <CorText>
                  <p className="text-content-success line-clamp-1">{selectedCard.title}</p>
                </CorText>
              </div>
            </div>
            <CorText variant={TextVariant.BODY_1_REGULAR}>
              <p className="text-content-default">{selectedCard.abs}</p>
            </CorText>
            <CorText>
              <div className="text-content-muted">
                Authors:{' '}
                {selectedCard.authors.map(
                  (author, index) =>
                    `${author.lastname} ${author.forename}${
                      index !== selectedCard.authors.length - 1 ? ', ' : ''
                    }`,
                )}
              </div>
            </CorText>
          </div>
        ) : null}
      </>
    </div>
  );
};

export default QuestionTab;
