import { CorInput, CorText, CorToggle } from '@e-reteta/react-design-system';
import { T, useTranslate } from '@tolgee/react';
import { FC, memo } from 'react';
import { useFormikContext } from 'formik';

import { TextVariant } from '../../../../enums/design-system.enum';
import { ModalContentProps } from '../interface';
import disableScrollOnInput from '../../../../utils/disableScrollOnInput';
import { PharmacySettingsDto } from '../../../../services/pharmacy-settings.services';

const IntegrationModal: FC<ModalContentProps> = ({ isEdit }) => {
  const { t } = useTranslate();
  const formik = useFormikContext<PharmacySettingsDto>();

  const handleToggle = async (id: string, value: boolean) => {
    await formik.setFieldValue(id, value);
  };

  return (
    <div className="px-8 mb-6 overflow-auto pb-10">
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-1">
          <CorText variant={TextVariant.LABEL_2_REGULAR}>
            <div className="text-content-muted">
              <T keyName={'integrationModal.id'}>IDNO</T>
            </div>
          </CorText>
          <CorInput disabled={isEdit} error={!!formik.errors.idno}>
            <input
              autoComplete="off"
              type="text"
              name="idno"
              placeholder={t('integrationModal.idPlaceholder', 'Introduceti IDNO farmacie')}
              value={formik.values.idno}
              onChange={formik.handleChange}
            />
            {formik.errors.idno ? (
              <span className="bottom-helper">{formik.errors.idno}</span>
            ) : null}
          </CorInput>
        </div>
        <div className="flex flex-col gap-1">
          <CorToggle
            label={t('integrationModal.isActive', 'Statutul Integrarii (Conectat/Dezactivat)')}
            value={formik.values?.isActive}
            onClick={() => handleToggle('isActive', !formik.values?.isActive)}
          >
            <input autoComplete="off" type="checkbox" value={String(formik.values?.isActive)} />
          </CorToggle>
        </div>
        <div className="flex flex-col gap-1">
          <CorText variant={TextVariant.LABEL_2_REGULAR}>
            <div className="text-content-muted">
              <T keyName={'integrationModal.pullProductsURL'}>
                Link de extragere medicamente/dispozitive
              </T>
            </div>
          </CorText>
          <CorInput error={!!formik.errors?.pullProductsURL}>
            <input
              autoComplete="off"
              type="text"
              name="pullProductsURL"
              placeholder={t(
                'integrationModal.pullProductsURLPlaceholder',
                'Introduceti link de extragere medicamente/dispozitive',
              )}
              value={formik.values?.pullProductsURL}
              onChange={formik.handleChange}
              onWheel={(e) => disableScrollOnInput(e)}
            />
            {formik.errors?.pullProductsURL ? (
              <span className="bottom-helper">{formik.errors?.pullProductsURL}</span>
            ) : null}
          </CorInput>
        </div>
        <div className="flex flex-col gap-1">
          <CorText variant={TextVariant.LABEL_2_REGULAR}>
            <div className="text-content-muted">
              <T keyName={'integrationModal.pushDispensesURL'}>Link de trimitere eliberări</T>
            </div>
          </CorText>
          <CorInput error={!!formik.errors?.pushDispensesURL}>
            <input
              autoComplete="off"
              type="text"
              name="pushDispensesURL"
              placeholder={t(
                'integrationModal.pushDispensesURLPlaceholder',
                'Introduceti link de trimitere eliberări',
              )}
              value={formik.values?.pushDispensesURL}
              onChange={formik.handleChange}
              onWheel={(e) => disableScrollOnInput(e)}
            />
            {formik.errors?.pushDispensesURL ? (
              <span className="bottom-helper">{formik.errors?.pushDispensesURL}</span>
            ) : null}
          </CorInput>
        </div>
        <div className="flex flex-col gap-1">
          <CorToggle
            label={t(
              'integrationModal.integrationStatus',
              'Statutul Conexiunii (Conectat/Dezactivat)',
            )}
            value={formik.values?.integration}
            onClick={() => handleToggle('integration', !formik.values?.integration)}
          >
            <input autoComplete="off" type="checkbox" value={String(formik.values?.integration)} />
          </CorToggle>
        </div>
      </div>
    </div>
  );
};

export default memo(IntegrationModal);
