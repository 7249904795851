import { FC, SVGProps } from 'react';

const Receipt: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        opacity="0.3"
        d="M1.33301 4.66659V13.9999C1.33301 14.5533 1.95967 14.8666 2.39967 14.5333L3.53967 13.6799C3.80634 13.4799 4.17967 13.5066 4.41967 13.7466L5.52634 14.8599C5.78634 15.1199 6.21301 15.1199 6.47301 14.8599L7.59301 13.7399C7.82634 13.5066 8.19967 13.4799 8.45967 13.6799L9.59967 14.5333C10.0397 14.8599 10.6663 14.5466 10.6663 13.9999V2.66659C10.6663 1.93325 11.2663 1.33325 11.9997 1.33325H4.66634H3.99967C1.99967 1.33325 1.33301 2.52659 1.33301 3.99992V4.66659Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.25 8.67333C5.25 8.25912 5.58579 7.92333 6 7.92333H8C8.41421 7.92333 8.75 8.25912 8.75 8.67333C8.75 9.08755 8.41421 9.42333 8 9.42333H6C5.58579 9.42333 5.25 9.08755 5.25 8.67333Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.25 6.00667C5.25 5.59245 5.58579 5.25667 6 5.25667H8C8.41421 5.25667 8.75 5.59245 8.75 6.00667C8.75 6.42088 8.41421 6.75667 8 6.75667H6C5.58579 6.75667 5.25 6.42088 5.25 6.00667Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.24707 8.66667C3.24707 8.25245 3.58286 7.91667 3.99707 7.91667H4.00306C4.41727 7.91667 4.75306 8.25245 4.75306 8.66667C4.75306 9.08088 4.41727 9.41667 4.00306 9.41667H3.99707C3.58286 9.41667 3.24707 9.08088 3.24707 8.66667Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.24707 6C3.24707 5.58579 3.58286 5.25 3.99707 5.25H4.00306C4.41727 5.25 4.75306 5.58579 4.75306 6C4.75306 6.41421 4.41727 6.75 4.00306 6.75H3.99707C3.58286 6.75 3.24707 6.41421 3.24707 6Z"
      />
      <path d="M14.6667 3.99992V5.61325C14.6667 6.66658 14 7.33325 12.9467 7.33325H10.6667V2.67325C10.6667 1.93325 11.2733 1.33325 12.0133 1.33325C12.74 1.33992 13.4067 1.63325 13.8867 2.11325C14.3667 2.59992 14.6667 3.26659 14.6667 3.99992Z" />
    </svg>
  );
};

export default Receipt;
