export const calculateDaysUntilDate = (specificDate?: string): number => {
  if (!specificDate) {
    return 0;
  }
  // Parse the specified date
  const targetDate = new Date(specificDate);

  // Get the current date
  const currentDate = new Date();

  // Calculate the difference in milliseconds
  const differenceInMilliseconds = targetDate.getTime() - currentDate.getTime();

  // Convert the difference to days
  const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);

  // Return the number of days (rounded up if necessary)
  return Math.ceil(differenceInDays);
};
