import { FC, SVGProps } from 'react';

const NotFound: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg viewBox="0 0 130 128" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_909_5380)">
        <path
          d="M130 67.5C130 83.5409 123.704 98.1148 113.447 108.878C110.095 112.402 106.313 115.517 102.192 118.14C101.969 118.28 101.746 118.419 101.523 118.559C99.5223 119.798 97.4432 120.922 95.293 121.924C87.6058 125.502 79.0368 127.5 70 127.5C62.6922 127.5 55.6881 126.192 49.2107 123.8C46.9797 122.977 44.8124 122.024 42.7159 120.952C26.6017 112.708 14.7044 97.3922 11.1217 79.0983C10.671 76.8035 10.3504 74.4623 10.1716 72.082C10.0588 70.5687 10 69.0403 10 67.5C10 65.171 10.1322 62.8713 10.3919 60.611C10.5487 59.2371 10.7519 57.878 11.0017 56.5334C16.1519 28.6348 40.6073 7.5 70 7.5C80.8661 7.5 91.0562 10.3873 99.8458 15.4371C101.041 16.1229 102.207 16.8502 103.348 17.6142C103.593 17.7783 103.838 17.9449 104.08 18.1139C104.097 18.1237 104.117 18.1359 104.134 18.1507C106.015 19.4536 107.817 20.8617 109.534 22.3655C113.193 25.5713 116.457 29.2177 119.249 33.2194C126.025 42.9368 130 54.7556 130 67.5Z"
          fill="url(#paint0_linear_909_5380)"
        />
        <g clipPath="url(#clip1_909_5380)">
          <path
            d="M120 110.955C120 116.136 115.636 120.5 110.455 120.5C107.727 120.5 105.273 119.409 103.364 117.5L72.8183 84.2272C71.7272 83.1362 71.7272 81.5 72.8183 80.409L79.9093 73.318C81.0003 72.227 82.6365 72.227 83.7275 73.318L117 104.136C118.909 105.773 120 108.5 120 110.955Z"
            fill="#B06328"
          />
          <path
            d="M116.727 103.864L115.09 102.5C116.454 104.137 117.272 106.318 117.272 108.5C117.272 112.046 112.908 113.409 110.454 110.955L81.8177 82.3184C80.1813 80.682 77.7266 80.682 76.0905 82.3184L73.3633 85.0456L103.09 116.955C104.727 118.591 106.909 119.955 109.09 120.227C112.09 120.5 115.09 119.409 116.999 117.5C118.908 115.591 119.999 112.591 119.727 109.591C119.727 107.409 118.363 105.5 116.727 103.864Z"
            fill="#A35425"
          />
          <path
            d="M88.6366 85.0455C88.3638 85.0455 87.8184 85.0455 87.5456 84.7727L80.7274 77.9545C80.182 77.4091 80.182 76.5909 80.7274 76.0455C81.2728 75.5001 82.091 75.5001 82.6364 76.0455L89.4546 82.8637C90 83.4091 90 84.2273 89.4546 84.7727C89.4548 85.0455 88.9092 85.0455 88.6366 85.0455Z"
            fill="#C97629"
          />
        </g>
        <mask
          id="mask0_909_5380"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="10"
          y="7"
          width="120"
          height="121"
        >
          <g opacity="0.157436">
            <path
              d="M130 67.5C130 83.5409 123.704 98.1148 113.447 108.878C110.095 112.402 106.313 115.517 102.192 118.14C101.969 118.28 101.746 118.419 101.523 118.559C99.5223 119.798 97.4432 120.922 95.293 121.924C87.6058 125.502 79.0368 127.5 70 127.5C62.6922 127.5 55.6881 126.192 49.2107 123.8C46.9797 122.977 44.8124 122.024 42.7159 120.952C26.6017 112.708 14.7044 97.3922 11.1217 79.0983C10.671 76.8035 10.3504 74.4623 10.1716 72.082C10.0588 70.5687 10 69.0403 10 67.5C10 65.171 10.1322 62.8713 10.3919 60.611C10.5487 59.2371 10.7519 57.878 11.0017 56.5334C16.1519 28.6348 40.6073 7.5 70 7.5C80.8661 7.5 91.0562 10.3873 99.8458 15.4371C101.041 16.1229 102.207 16.8502 103.348 17.6142C103.593 17.7783 103.838 17.9449 104.08 18.1139C104.097 18.1237 104.117 18.1359 104.134 18.1507C106.015 19.4536 107.817 20.8617 109.534 22.3655C113.193 25.5713 116.457 29.2177 119.249 33.2194C126.025 42.9368 130 54.7556 130 67.5Z"
              fill="#DDA319"
            />
          </g>
        </mask>
        <g mask="url(#mask0_909_5380)">
          <path
            opacity="0.1574"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M41 2.5C65.3005 2.5 85 22.1995 85 46.5C85 56.3012 81.7953 65.3539 76.3767 72.6675L83.0688 80.0995L75.6373 86.7908L69.5252 80.0021C61.844 86.5486 51.8834 90.5 41 90.5C16.6995 90.5 -3 70.8005 -3 46.5C-3 22.1995 16.6995 2.5 41 2.5Z"
            fill="#DDA319"
          />
        </g>
        <g clipPath="url(#clip2_909_5380)">
          <path
            d="M73.6219 79.5221L79.0215 74.1226L70.9221 66.0232L65.5226 71.4227L73.6219 79.5221Z"
            fill="#F0F0F6"
          />
          <path
            d="M75.0007 75.5L76.3643 76.8636L79.0915 74.1364L70.9097 65.9546L65.4551 71.4092L69.5461 75.5C71.1825 73.8636 73.3643 73.8636 75.0007 75.5Z"
            fill="#D8D8EB"
          />
          <path
            d="M40.909 0.5C18.2728 0.5 0 18.7728 0 41.409C0 64.0452 18.2728 82.318 40.909 82.318C63.5452 82.318 81.818 64.0452 81.818 41.409C81.818 18.7728 63.5454 0.5 40.909 0.5ZM40.909 74.1364C22.909 74.1364 8.1818 59.4092 8.1818 41.4092C8.1818 23.4092 22.909 8.6818 40.909 8.6818C58.909 8.6818 73.6362 23.409 73.6362 41.409C73.6362 59.409 58.909 74.1364 40.909 74.1364Z"
            fill="url(#paint1_linear_909_5380)"
          />
          <path
            d="M40.9088 74.1363C58.9836 74.1363 73.636 59.4838 73.636 41.4091C73.636 23.3343 58.9836 8.68188 40.9088 8.68188C22.8341 8.68188 8.18164 23.3343 8.18164 41.4091C8.18164 59.4838 22.8341 74.1363 40.9088 74.1363Z"
            fill="url(#paint2_linear_909_5380)"
          />
          <path
            d="M24.8179 45.2271C26.1815 34.8635 34.3632 26.6817 44.7268 25.3181H44.9997C49.6361 24.7727 49.9086 18.2271 45.545 17.4091C42.8179 16.8637 40.0904 16.8637 37.3632 17.1363C26.7268 18.7727 18.2722 27.2273 16.9086 37.5909C16.3632 40.3181 16.6358 43.3181 17.1814 45.7727C17.9997 50.4091 24.545 49.8637 25.0904 45.2273C24.8179 45.4999 24.8179 45.2271 24.8179 45.2271Z"
            fill="white"
            fillOpacity="0.495274"
          />
          <path
            d="M10.9088 44.1365C10.9088 26.1365 25.636 11.4093 43.636 11.4093C52.0906 11.4093 59.4542 14.6821 65.4542 19.5911C59.4542 12.7729 50.727 8.68213 40.9088 8.68213C22.9088 8.68213 8.18164 23.4093 8.18164 41.4093C8.18164 51.2275 12.5452 59.9547 19.0906 65.9547C14.1816 59.9547 10.9088 52.5911 10.9088 44.1365Z"
            fill="#DBDBEB"
          />
          <path
            opacity="0.813291"
            d="M72.5007 35.5001C75.3165 53.2785 62.7791 69.6843 45.0007 72.5001C36.6501 73.8227 27.8205 71.0351 21.1264 67.1252C28.1191 72.9208 37.3788 75.596 47.0761 74.0601C64.8545 71.2443 77.0966 54.3946 74.2807 36.6162C72.7448 26.9188 67.0697 18.9817 59.6663 14.0795C65.4535 19.2377 71.1781 27.1496 72.5007 35.5001Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_909_5380"
          x1="9.99995"
          y1="2425.58"
          x2="10562.7"
          y2="9893.66"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFCB86" />
          <stop offset="1" stopColor="#FFCC4E" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_909_5380"
          x1="8181.8"
          y1="8182.3"
          x2="768.779"
          y2="2035.39"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E8E8F0" />
          <stop offset="1" stopColor="#F7F7FC" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_909_5380"
          x1="623.205"
          y1="1636.6"
          x2="6553.62"
          y2="6554.12"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F0E6DD" />
          <stop offset="1" stopColor="#F7EDDF" />
        </linearGradient>
        <clipPath id="clip0_909_5380">
          <rect width="130" height="127" fill="white" transform="translate(0 0.5)" />
        </clipPath>
        <clipPath id="clip1_909_5380">
          <rect width="48.0001" height="48.0003" fill="white" transform="translate(72 72.4998)" />
        </clipPath>
        <clipPath id="clip2_909_5380">
          <rect width="81.818" height="81.818" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default NotFound;
