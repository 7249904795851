import { SVGProps } from 'react';

const Menu = (props: SVGProps<any>) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      {...props}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="2.66675" y="3.33325" width="10.6667" height="2" rx="1" fill="black" />
      <g opacity="0.3">
        <path
          d="M3.66675 6.66675C3.11446 6.66675 2.66675 7.11446 2.66675 7.66675C2.66675 8.21903 3.11446 8.66675 3.66675 8.66675H12.3334C12.8857 8.66675 13.3334 8.21903 13.3334 7.66675C13.3334 7.11446 12.8857 6.66675 12.3334 6.66675H3.66675Z"
          fill="black"
        />
        <path
          d="M3.66675 10.0001C3.11446 10.0001 2.66675 10.4478 2.66675 11.0001C2.66675 11.5524 3.11446 12.0001 3.66675 12.0001H12.3334C12.8857 12.0001 13.3334 11.5524 13.3334 11.0001C13.3334 10.4478 12.8857 10.0001 12.3334 10.0001H3.66675Z"
          fill="black"
        />
      </g>
    </svg>
  );
};

export default Menu;
