import { CorInput, CorRadioButton, CorText } from '@e-reteta/react-design-system';
import { T } from '@tolgee/react';
import { useFormikContext } from 'formik';
import { ChangeEvent } from 'react';

import { TextVariant } from '../../../enums/design-system.enum';
import { ReportSearchType } from '../report-search-type.enum';
import { Annex8PharmacyFormikProps } from './annex8-for-pharmacy.interface';

const Annex8ForPharmacyPsf = () => {
  const formik = useFormikContext<Annex8PharmacyFormikProps>();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    formik.handleChange(event);
    formik.setFieldValue('annex8PharmacyPsfSearchInput', undefined);
  };

  const renderReportType = () => {
    return (
      <>
        <CorText variant={TextVariant.LABEL_1_MEDIUM} className="px-4">
          <div className="text-content-default">
            <T keyName={'annex.reportType'}>Tip Raport</T>
          </div>
        </CorText>
        <div className="flex flex-col px-4 pt-4 mb-4">
          <div className="flex flex-wrap xs:flex-nowrap gap-2 xs:gap-8">
            <CorRadioButton
              isChecked={
                ReportSearchType.TOTALIZER_REPORT === formik?.values?.annex8PharmacyPPsfReportType
              }
              disabled={false}
            >
              <label className="whitespace-nowrap">
                <T keyName={'annex8Psf.totalizer'}>{ReportSearchType.TOTALIZER_REPORT}</T>
              </label>
              <input
                className="bg-background-secondary"
                type="radio"
                name="annex8PharmacyPPsfReportType"
                value={ReportSearchType.TOTALIZER_REPORT}
                checked={
                  ReportSearchType.TOTALIZER_REPORT === formik?.values?.annex8PharmacyPPsfReportType
                }
                onChange={(event) => handleChange(event)}
              />
            </CorRadioButton>
            <CorRadioButton
              isChecked={
                ReportSearchType.PER_PRESCRIPTION === formik?.values?.annex8PharmacyPPsfReportType
              }
              disabled={false}
            >
              <label className="whitespace-nowrap">
                <T keyName={'annex8.perPrescription'}>{ReportSearchType.PER_PRESCRIPTION}</T>
              </label>
              <input
                className="bg-background-secondary"
                type="radio"
                name="annex8PharmacyPPsfReportType"
                value={ReportSearchType.PER_PRESCRIPTION}
                checked={
                  ReportSearchType.PER_PRESCRIPTION === formik?.values?.annex8PharmacyPPsfReportType
                }
                onChange={formik.handleChange}
              />
            </CorRadioButton>
          </div>
        </div>
      </>
    );
  };

  const renderInputPlaceholder = () => {
    if (ReportSearchType.PER_PRESCRIPTION === formik?.values?.annex8PharmacyReportType) {
      return 'Introduceti id prescriere';
    }
  };

  const renderInput = () => {
    if (
      formik?.values?.annex8PharmacyPPsfReportType === undefined ||
      formik?.values?.annex8PharmacyPPsfReportType === ReportSearchType.TOTALIZER_REPORT
    ) {
      return null;
    }
    return (
      <>
        <div className="grid grid-cols-2 xs:px-4 mb-4">
          <CorInput>
            <input
              type="text"
              placeholder={renderInputPlaceholder()}
              value={formik.values.annex8PharmacyPsfSearchInput || ''}
              onChange={(event) => {
                formik.setFieldValue('annex8PharmacyPsfSearchInput', event.target.value);
              }}
            />
          </CorInput>
        </div>
        <div className="w-full border-0 border-t border-solid border-border-disabled mx-4 my-6" />
      </>
    );
  };

  return (
    <div className="flex flex-col">
      {renderReportType()}
      {renderInput()}
      <div className="w-full border-0 border-t border-solid border-border-disabled mx-4 my-6" />
    </div>
  );
};

export default Annex8ForPharmacyPsf;
