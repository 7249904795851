import { toast } from 'react-toastify';

export const handleError = (error: any, message?: string) => {
  switch (true) {
    case !!error.response:
      customNotFoundMessage(error, message);
      break;
    case !!error.request:
      toast.error('Network error. Please check your internet connection.');
      break;
    default:
      toast.error(`An error occurred. Please try again later. ${error}`);
  }
};

const customNotFoundMessage = (error: any, message?: string) => {
  if (error?.response?.data?.statusCode === 404) {
    toast.error(message);
    return;
  }

  return toast.error(
    error.response?.data?.message || `Request failed with status code ${error.response?.status}`,
  );
};
