import { CorChips, CorIcon, CorText } from '@e-reteta/react-design-system';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import hash from 'object-hash';
import { T, useTranslate } from '@tolgee/react';

import Modal from '../../Modal';
import * as icons from '../../../icons';
import { ChipSize, ChipVariant, TextVariant } from '../../../enums/design-system.enum';
import { IconColor, IconName, IconSize } from '../../../enums/design-system-icons.enum';
import { TableRowProps } from './interface';
import ChipCell from './RowChildren/ChipCell';
import { ModalWindowOpen } from './enums';
import { TableColumnType, WidthType } from '../TableHeader/interface';
import TextCell from './RowChildren/TextCell';
import ToggleCell from './RowChildren/ToggleCell';
import { CompensatedStatusTypesEnum } from '../../../enums/CompensatedStatusTypesEnum';
import { Drug } from '../../../types/Drug';
import { SpecialityDto } from '../../../services/speciality.service';
import { Disease } from '../../../types/Disease';
import { PharmacyDto } from '../../../services/pharmacies.services';
import { DeviceDto } from '../../../services/device.service';
import { InstitutionDto } from '../../../services/institution.service';
import { DrugData } from '../../../types/CommonTypes';
import { calculateDaysUntilDate } from '../../../utils/calculateDaysUntilDate.util';
import classNames from 'classnames';
import { formatDate } from '../../../utils/formatDate';

const TableRow: FC<TableRowProps> = ({ item, isLast, editItem, onDelete, headers, index }) => {
  const { type } = useParams();
  const { t } = useTranslate();
  const menuRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLDivElement>(null);

  const [keys, setKeys] = useState<string[]>([]);

  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<ModalWindowOpen>(ModalWindowOpen.NULL);

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      const target = event.target as HTMLElement;

      if (!menuRef.current?.contains(target) && !buttonRef.current?.contains(target)) {
        setMenuOpen(false);
      }
    };

    document.body.addEventListener('click', handleClick);

    return () => document.body.removeEventListener('click', handleClick);
  }, []);

  const modalWindowContent: string[] = useMemo<string[]>(() => {
    switch (modalOpen) {
      case ModalWindowOpen.CIM_CODES:
        return (item as Drug)?.cimCodes;
      case ModalWindowOpen.PRESCRIPTION_RIGHTS:
        return (item as Drug)?.prescriptionsRights;
      case ModalWindowOpen.DIAGNOSTICS_FOR_PRESCRIPTION:
        return (item as SpecialityDto)?.prescribedDisease?.map((item) => item.code);
      case ModalWindowOpen.DIAGNOSTICS_FOR_REPRESCRIPTION:
        return (item as SpecialityDto)?.rePrescribedDisease?.map((item) => item.code);
      default:
        return [];
    }
  }, [modalOpen, item]);

  const checkIfObject = (
    items: Disease[] | PharmacyDto[] | Drug[] | DeviceDto[] | SpecialityDto[] | InstitutionDto[],
  ) => {
    if (items?.length) {
      for (const itemCheck of items) {
        if (typeof itemCheck === 'object') {
          return true;
        }
      }
    }
    return false;
  };

  const getCompensatedDisplayData = (value: string) => {
    switch (value) {
      case CompensatedStatusTypesEnum.FULLY_COMPENSATED: {
        return t('deviceTable.FULLY_COMPENSATED', 'Fully compensated');
      }
      case CompensatedStatusTypesEnum.PARTIALLY_COMPENSATED: {
        return t('deviceTable.PARTIALLY_COMPENSATED', 'Partialy compensated');
      }
      case CompensatedStatusTypesEnum.NOT_COMPENSATED: {
        return t('deviceTable.NOT_COMPENSATED', 'Not compensated');
      }
      default: {
        return t('deviceTable.FULLY_COMPENSATED', 'Fully compensated');
      }
    }
  };

  const getCompensatedDisplaDrug = (value: string) => {
    switch (value) {
      case CompensatedStatusTypesEnum.FULLY_COMPENSATED: {
        return t('drugTable.FULLY_COMPENSATED', 'Fully compensated');
      }
      case CompensatedStatusTypesEnum.PARTIALLY_COMPENSATED: {
        return t('drugTable.PARTIALLY_COMPENSATED', 'Partialy compensated');
      }
      case CompensatedStatusTypesEnum.NOT_COMPENSATED: {
        return t('drugTable.NOT_COMPENSATED', 'Not compensated');
      }
      default: {
        return t('drugTable.FULLY_COMPENSATED', 'Fully compensated');
      }
    }
  };

  const getCellType = useCallback(
    (
      headerCell: {
        type: TableColumnType;
        width: number;
        modalType: ModalWindowOpen;
        compareValues: string[];
      },
      key: string,
      widthType: WidthType,
    ) => {
      if (headerCell?.type) {
        switch (headerCell?.type) {
          case 'text':
            let data = '';
            switch (key) {
              case 'groups':
                data = (item as Drug)['groups']?.at(0)?.name!;
                break;
              case 'country':
              case 'group':
              case 'producer':
              case 'form':
                data = (item as DrugData)[key]?.name!;
                break;
              case 'subGroup':
                data = (item as Disease)?.group?.group?.name!;
                break;
              case 'compensatedStatus':
                data = getCompensatedDisplayData((item as DeviceDto)[key]);
                break;
              case 'compensatedStatusAdult':
                data = getCompensatedDisplaDrug((item as DrugData)[key]!);
                break;
              case 'compensatedStatusChildren':
                data = getCompensatedDisplaDrug((item as DrugData)[key]!);
                break;
              case 'vatPayer':
                data = (item as PharmacyDto)[key]
                  ? t('pharmacyTable.1', 'Da')
                  : t('pharmacyTable.0', 'Nu');
                break;
              default:
                data = (item as any)[key];
            }
            return (
              <TextCell key={key} value={data} width={headerCell?.width} widthType={widthType} />
            );
          case 'chip':
            return (
              <ChipCell
                key={key}
                items={(item as any)[key]}
                modalType={headerCell?.modalType}
                setModalOpen={setModalOpen}
                width={headerCell?.width}
                widthType={widthType}
                isObject={checkIfObject((item as any)[key])}
                keyName={key}
                rowIndex={index}
              />
            );
          case 'toggle':
            return (
              <ToggleCell
                key={key}
                value={(item as any)[key]}
                width={headerCell?.width}
                widthType={widthType}
              />
            );
          case 'range':
            return (
              <TextCell
                key={key}
                value={
                  (item as any)[`from${key}`] && (item as any)[`to${key}`]
                    ? (item as any)[`from${key}`] + ' - ' + (item as any)[`to${key}`]
                    : '-'
                }
                width={headerCell?.width}
                widthType={widthType}
              />
            );
          case 'number':
            return (
              <TextCell
                key={key}
                value={(item as any)[key] ? (item as any)[key].toFixed(2) : null}
                width={headerCell?.width}
                widthType={widthType}
              />
            );
          case 'date':
            const date = (item as any)[key] ? formatDate(new Date((item as any)[key])) : '-';
            return (
              <TextCell key={key} value={date} width={headerCell?.width} widthType={widthType} />
            );

          case 'boolean':
            const value = (item as any)[key]
              ? headerCell?.compareValues[0]
              : headerCell?.compareValues[1];
            return (
              <TextCell key={key} value={value} width={headerCell?.width} widthType={widthType} />
            );
          default:
            return (
              <TextCell
                key={key}
                value={(item as any)[key]}
                width={headerCell?.width}
                widthType={widthType}
              />
            );
        }
      }
    },
    [item],
  );

  useEffect(() => {
    setKeys(Object.keys(headers.columns));
  }, [type, headers.columns]);

  const handleEdit = (
    item: Disease | PharmacyDto | Drug | DeviceDto | SpecialityDto | InstitutionDto,
    url?: string,
  ) => {
    if (url) {
      editItem(item, url);
      setMenuOpen(false);
    }
  };

  const modalPositionIfLastItem = isLast ? 'translate-y-[-40px]' : 'translate-y-[40px]';
  const modalPositionIfOnlyOneOption =
    type !== 'integrations' ? 'translate-y-[-40px]' : 'translate-y-[-10px]';

  return (
    <>
      {modalOpen && (
        <Modal>
          <div
            className="absolute inset-0 w-screen h-screen max-w-screen max-h-screen flex items-center justify-center px-[280px]
            py-12 z-50 bg-modal-background"
          >
            <div className="w-full h-full bg-background-primary px-8 py-7 rounded-[28px] overflow-x-scroll">
              <header className="flex items-center justify-between pb-4">
                <CorText variant={TextVariant.HEADING_2_BOLD}>
                  <span>{modalOpen}</span>
                </CorText>
                <button
                  className="bg-transparent cursor-pointer outline-none border-none"
                  onClick={() => setModalOpen(ModalWindowOpen.NULL)}
                >
                  <CorIcon name={IconName.CLOSE} width="32px" height="32px" />
                </button>
              </header>
              <div className="flex flex-row flex-wrap gap-1 pt-4">
                {modalWindowContent?.map((text) => (
                  <CorChips key={hash(text)} size={ChipSize.LG} variant={ChipVariant.OUTLINE}>
                    <CorText>
                      <span>{text}</span>
                    </CorText>
                  </CorChips>
                ))}
              </div>
            </div>
          </div>
        </Modal>
      )}

      <div
        className={classNames(
          'flex flex-row items-start gap-4 py-2 px-4 hover:bg-background-secondary overflow-hidden h-[116px] max-h-[116px]',
          {
            'bg-surface-critical-default hover:bg-surface-critical-accent':
              (item as InstitutionDto).licenseValidity &&
              calculateDaysUntilDate((item as InstitutionDto).licenseValidity) <= 60,
          },
        )}
        style={{
          width: `${headers.tableWidth ? headers.tableWidth : '100%'}`,
          maxWidth: `${headers.tableWidth ? headers.tableWidth : '100%'}`,
        }}
      >
        {keys.map((key) => getCellType(headers.columns[key], key, headers.widthType))}
        <div
          className="absolute right-0 flex flex-row transform-gpu translate-y-[-8px] duration-0 h-[116px] max-h-[116px]"
          ref={buttonRef}
        >
          <div className="h-[116px] max-h-[116px] opacity-30 mgmt-drugs-button-bg w-5" />
          <div className="box-content bg-surface-default w-[15px] px-2 h-[116px] max-h-[116px] flex items-center z-10">
            <button
              type="button"
              onClick={() => setMenuOpen((value) => !value)}
              className="bg-transparent border-none outline-none z-10 right-0 cursor-pointer transform-gpu translate-x-[-8px]"
            >
              <icons.Kebab width={16} height={16} className="fill-content-muted" />
            </button>
          </div>
        </div>
        {menuOpen && (
          <div
            ref={menuRef}
            className={`absolute py-2 w-[130px] overflow-hidden transform-gpu translate-x-[-30px] rounded-lg z-50 right-0
              bg-decorative-neutral-1 flex flex-col border border-solid border-border-default mgmt-drugs-button-box-shadow shadow-elevation-1
              ${modalPositionIfLastItem}
              ${modalPositionIfOnlyOneOption}`}
          >
            <button
              type="button"
              onClick={() => handleEdit(item, type)}
              className="z-50 px-4 py-2 hover:bg-surface-hover cursor-pointer bg-decorative-neutral-1 border-none outline-none flex items-center justify-between"
            >
              <CorText variant={TextVariant.LABEL_2_REGULAR}>
                <span className="text-content-muted">
                  <T keyName={'tableRow.modify'}>Modifica</T>
                </span>
              </CorText>
              <CorIcon name={IconName.EDIT} size={IconSize.MD} />
            </button>
            {type !== 'integrations' ? (
              <button
                type="button"
                onClick={() => {
                  setMenuOpen(false);
                  onDelete();
                }}
                className="z-50 hover:bg-surface-hover px-4 py-2 cursor-pointer bg-decorative-neutral-1 border-none outline-none flex items-center justify-between"
              >
                <CorText variant={TextVariant.LABEL_2_REGULAR}>
                  <span className="text-content-muted">
                    <T keyName={'tableRow.delete'}>Sterge</T>
                  </span>
                </CorText>
                <CorIcon name={IconName.TRASH} size={IconSize.MD} color={IconColor.CONTENT_MUTED} />
              </button>
            ) : null}
          </div>
        )}
      </div>
    </>
  );
};

export default TableRow;
