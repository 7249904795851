export enum ReportSearchType {
  TOTALIZER_REPORT = 'Raport totalizator',
  PER_INSTITUTION_NAME = 'Raport per filiala',
  DEVICES = 'Dispozitive',
  DRUGS = 'Medicamente',
  CODE = 'Cod Filiala',
  ADDRESS = 'Adresa Filialei',
  DRUGS_REPORT = 'Raport Medicamente',
  DEVICES_REPORT = 'Raport Dispozitive',
  PER_INSTITUTION = 'Raport per IMS',
  DRUG = 'Medicament',
  DEVICE = 'Dispozitiv Medical',
  COMMERCIAL_NAME = 'Denumirea Comerciala',
  COMMERCIAL_COD = 'Cod DC',
  IS_DETAILED = 'Raport Detaliat',
  IS_GENERALIZED = 'Raport Generalizat',
  ALL_PRODUCTS = 'Toate Medicamentele si Dispozitivele',
  PER_COMMERCIAL_NAME = 'Per denumire DC',
  PER_COMMERCIAL_COD = 'Per Cod DC',
  INSTITUTION = 'IMS',
  PHARMACY = 'PSF',
  PHARMACY_BRANCH = 'Filiala PSF',
  PER_DOCTOR = 'Per Medic',
  PER_PHARMACIST = 'Per Farmacist',
  PER_PATIENT = 'Per Pacient',
  PER_PRESCRIPTION = 'Per Reteta',
  ALL_PRESCRIPTIONS = 'Toate Retetele',
  HOLOGRAPH_PRESCRIPTIONS = 'Retete Olografe',
  ALL = 'Toate',
  ALL_DRUGS = 'Toate Medicamentele',
  ALL_DEVICES = 'Toate Dispozitivele',
  DCI = 'DCI',
  DEVICE_NAME = 'Denumirea dispozitivului medical',
  MEDICAL_DEVICES = 'Denumirea dispozitivului medical',
  ALL_BRANCHES = 'Toate filialele',
}
