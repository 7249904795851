import { FC, useEffect, useState } from 'react';
import { CorBox, CorText } from '@e-reteta/react-design-system';
import { T, useTranslate } from '@tolgee/react';

import { PrescribedDoctorProps } from './interface';
import { TextVariant } from '../../enums/design-system.enum';
import useWindowWidth from '../../hooks/use-window-width';
import { Keys } from '../../enums/Keys';
import { InstitutionService } from '../../services/institution.service';

const PrescribingDoctor: FC<PrescribedDoctorProps> = ({ doctor, institution, user }) => {
  const { t } = useTranslate();
  const { isMobile } = useWindowWidth();

  const [doctorFields, setDoctorFields] = useState({
    [t(
      'prescribedDoctor.nameSurname',
      Keys.nameSurname,
    )]: `${doctor?.lastName} ${doctor?.firstName}`,
    [t('prescribedDoctor.medicalUnit', Keys.medicalUnit)]: institution?.imsp || doctor?.medicalUnit,
  });

  const getMedicalUnitName = async (codIms?: string) => {
    if (!codIms) return;
    const inst = await InstitutionService.getAll('doctor', {
      codIms,
    });
    let fields = {
      [t(
        'prescribedDoctor.nameSurname',
        Keys.nameSurname,
      )]: `${doctor?.lastName} ${doctor?.firstName}`,
      [t('prescribedDoctor.medicalUnit', Keys.medicalUnit)]:
        institution?.imsp || inst.data.items?.at(0)?.imsp,
    };
    if (doctor?.phone) {
      fields = { ...fields, [t('prescribedDoctor.phone', Keys.phone)]: doctor?.phone };
    }
    setDoctorFields(fields);
  };

  useEffect(() => {
    getMedicalUnitName(doctor?.medicalUnit || user?.activeCompany?.code);
  }, [doctor?.medicalUnit, doctor?.phone]);

  return (
    <CorBox boxStyle="none">
      <section className="flex flex-col items-start gap-2">
        <header>
          <CorText variant={isMobile ? TextVariant.LABEL_1_BOLD : TextVariant.HEADING_4_BOLD}>
            <span>
              <T keyName="prescribedDoctor.prescribedDoctor">Medicul prescriptor</T>
            </span>
          </CorText>
        </header>
        <div className="flex flex-col w-full xs:grid xs:grid-cols-2 sm:grid-cols-3 gap-3">
          {Object.entries(doctorFields).map(([key, value]) => (
            <div className="grid grid-cols-2 xs:flex xs:flex-col" key={key}>
              <CorText variant={TextVariant.LABEL_2_REGULAR}>
                <span className="text-content-muted">{key}</span>
              </CorText>
              <CorText variant={TextVariant.LABEL_2_MEDIUM}>
                <span className="flex justify-end xs:justify-start text-content-default text-end xs:text-start">
                  {value}
                </span>
              </CorText>
            </div>
          ))}
        </div>
      </section>
    </CorBox>
  );
};

export default PrescribingDoctor;
