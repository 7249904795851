import { useState, useEffect } from 'react';

const useWindowWidth = () => {
  const [width, setWidth] = useState<number>(0);
  const [isTablet, setTablet] = useState(false);
  const [isSmallTablet, setSmallTablet] = useState(false);
  const [isMobile, setMobile] = useState(false);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
      if (window.innerWidth > 895 && window.innerWidth <= 1024) {
        setTablet(true);
        setSmallTablet(false);
        setMobile(false);
      }
      if (window.innerWidth > 640 && window.innerWidth <= 895) {
        setTablet(false);
        setSmallTablet(true);
        setMobile(false);
      }
      if (window.innerWidth <= 640) {
        setMobile(true);
        setSmallTablet(false);
        setTablet(false);
      }
      if (window.innerWidth > 1024) {
        setTablet(false);
        setSmallTablet(false);
        setMobile(false);
      }
    }
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return { width, isTablet, isMobile, isSmallTablet };
};

export default useWindowWidth;
