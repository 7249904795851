import { FC, SVGProps } from 'react';

const Duplicate: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        opacity="0.3"
        d="M9.21196 2.66666H4.12105C3.17818 2.66666 2.6665 3.17833 2.6665 4.1212V9.21211C2.6665 10.12 3.14092 10.6281 4.01791 10.6645C4.00597 10.5558 3.99984 10.4453 3.99984 10.3333V6.99999C3.99984 5.34313 5.34298 3.99999 6.99984 3.99999H10.3332C10.4451 3.99999 10.5556 4.00612 10.6644 4.01807C10.628 3.14108 10.1199 2.66666 9.21196 2.66666Z"
        fill="#008000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.45455 4H10.5455C11.4883 4 12 4.51167 12 5.45455V10.5455C12 11.4883 11.4883 12 10.5455 12H5.45455C4.51167 12 4 11.4883 4 10.5455V5.45455C4 4.51167 4.51167 4 5.45455 4Z"
        fill="#008000"
      />
    </svg>
  );
};

export default Duplicate;
