import ApiLogin from '../axios/api-login';
import Api from '../axios/api';
import { GetInstitutionsResponse } from './institution.service';
import { CompanyTypeEnum } from '../enums/company-type.enum';

export interface SelectCompany {
  code: string;
  speciality?: string;
}
interface AuthTokens {
  accessToken: string;
  refreshToken: string;
}

export interface User {
  idnp: string;
  birthDate?: string;
  firstName: string;
  lastName: string;
  type?: string;
  customAttributes: Pair[];
  companies: Company[];
  activeCompany: Company | undefined;
}

interface Pair {
  key: string;
  value: string;
}

export interface Company {
  idno: string;
  name: string;
  speciality: string;
  code: string;
  type?: string;
  isEnabled?: boolean; // is a decoration added after the fact
  roles: string[];
  isActive?: boolean;
  licenceExpiresInDays?: number;
  address?: string;
}
interface PostAuthResponse {
  data: AuthTokens;
}

interface Pharmacy {
  idno: string;
  name: string;
  code?: string;
  address?: string;
  type?: CompanyTypeEnum;
}

export interface PharmacyResponse {
  data: {
    items: Pharmacy[];
    count: number;
  };
}

export class AuthServices {
  static login(role: string): Promise<PostAuthResponse> {
    return ApiLogin.post(`/v1/auth/login/${role}`);
  }

  static setActiveCompany({ code, speciality }: SelectCompany): Promise<PostAuthResponse> {
    return Api.post(`/v1/auth/companies/${code}`, {
      speciality,
    });
  }

  static getInstitutions(codes: string[]): Promise<GetInstitutionsResponse> {
    return Api.get(`/v1/auth/institutions`, { params: { codImses: codes } });
  }

  static getPharmacies(codes: string[]): Promise<PharmacyResponse> {
    return Api.get(`/v1/auth/pharmacies`, { params: { codes } });
  }
}
