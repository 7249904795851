import React, { FC, useContext, useEffect, useState } from 'react';
import { CorButton, CorIcon, CorInput, CorText } from '@e-reteta/react-design-system';
import { T, useTranslate } from '@tolgee/react';

import { IconName, IconSize } from '../../enums/design-system-icons.enum';
import { PaymentProps } from './interface';
import { TextVariant } from '../../enums/design-system.enum';
import { Alert } from '../index';
import { AlertStatus } from '../Alert/enum';
import { LoaderContext } from '../../context/loader/context';

const Payment: FC<PaymentProps> = ({
  onGoBack,
  signLater,
  onSuccessSign,
  showErrorAlert,
  cashRegisterVal = '',
  houseVoucherVal = '',
}) => {
  const { t } = useTranslate();
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [cashRegister, setCashRegister] = useState<string>(
    cashRegisterVal || localStorage.getItem('cash_register')!,
  );
  const { setLoading } = useContext(LoaderContext);
  const [houseVoucher, setHouseVoucher] = useState<string>(houseVoucherVal);

  useEffect(() => {
    localStorage.setItem('cash_register', cashRegister);
  }, [cashRegister]);

  useEffect(() => {
    if (cashRegister !== '' && houseVoucher !== '') {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [cashRegister, houseVoucher]);

  useEffect(() => {
    if (cashRegisterVal) {
      setCashRegister(cashRegisterVal);
    }
    if (houseVoucherVal) {
      setHouseVoucher(houseVoucherVal);
    }
  }, [cashRegisterVal, houseVoucherVal]);

  return (
    <div className="flex flex-col w-full h-full gap-4 rounded-2xl overflow-auto">
      <div className="flex flex-row items-center gap-2 h-8 sm:pt-4 lg:pt-0">
        <CorIcon
          size={IconSize.MD}
          name={IconName.ARROW_LEFT}
          className="cursor-pointer"
          onClick={onGoBack}
        />
        <CorText variant={TextVariant.HEADING_2_BOLD}>
          <h3>
            <T keyName="payment.payment">Achitare</T>
          </h3>
        </CorText>
      </div>
      {showErrorAlert ? (
        <Alert label={t('payment.signFailed', 'Semnarea a esuat')} status={AlertStatus.DANGER} />
      ) : null}
      <div className="bg-white rounded-2xl h-full shadow-elevation-1 flex flex-col items-start py-4 px-5 gap-8">
        <div className="flex flex-col items-start gap-4 w-full sm:w-1/2">
          <CorText variant={TextVariant.LABEL_1_BOLD}>
            <span>
              <T keyName="payment.paymentDetails">Detalii achitare</T>
            </span>
          </CorText>
          <div className="flex flex-col items-start gap-3 w-full">
            <div className="flex flex-col items-start justify-center gap-1 w-full">
              <CorInput className="w-full">
                <label>
                  <T keyName="payment.numberPaymentBill">Numarul bonului de plata</T>
                </label>
                <input
                  type="text"
                  value={houseVoucher}
                  placeholder="1234567890"
                  onChange={(e) => setHouseVoucher(e.target.value)}
                />
              </CorInput>
            </div>
            <div className="flex flex-col items-start justify-center gap-1 w-full">
              <CorInput className="w-full">
                <label>
                  <T keyName="payment.numberCashMachine">Numarul masinii de casa si control</T>
                </label>
                <input
                  type="text"
                  value={cashRegister}
                  placeholder="1234567890"
                  onChange={(e) => setCashRegister(e.target.value)}
                />
              </CorInput>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-row items-start self-end gap-4 h-10 mb-4 w-full flex-wrap sm:w-auto xs:flex-nowrap">
        <CorButton
          className="xs:flex-auto"
          variant="secondary"
          onClick={async () => {
            setLoading(true);
            await signLater(cashRegister, houseVoucher);
            setLoading(false);
          }}
        >
          <button className="w-full" disabled={isDisabled}>
            <T keyName="payment.signLater">Semneaza mai tarziu</T>
          </button>
        </CorButton>
        <CorButton
          className="flex-1 xs:flex-auto"
          onClick={() => {
            onSuccessSign(cashRegister, houseVoucher);
          }}
        >
          <button className="w-full" disabled={isDisabled}>
            <T keyName="payment.sign">Semneaza</T>
          </button>
        </CorButton>
      </div>
    </div>
  );
};

export default Payment;
