import { FC, SVGProps } from 'react';

const Printer: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 4C2.89543 4 2 4.89543 2 6V9.33333C2 10.4379 2.89543 11.3333 4 11.3333H5.33333V13.6667C5.33333 14.219 5.78105 14.6667 6.33333 14.6667H9.66667C10.219 14.6667 10.6667 14.219 10.6667 13.6667V11.3333H12C13.1046 11.3333 14 10.4379 14 9.33333V6C14 4.89543 13.1046 4 12 4H4ZM11.6667 7.33333C12.219 7.33333 12.6667 6.88562 12.6667 6.33333C12.6667 5.78105 12.219 5.33333 11.6667 5.33333C11.1144 5.33333 10.6667 5.78105 10.6667 6.33333C10.6667 6.88562 11.1144 7.33333 11.6667 7.33333ZM9.33333 9.33333H6.66667V13.3333H9.33333V9.33333Z"
        fill="#008000"
      />
      <rect
        opacity="0.3"
        x="5.3335"
        y="1.33334"
        width="5.33333"
        height="1.33333"
        rx="0.666667"
        fill="#008000"
      />
    </svg>
  );
};

export default Printer;
