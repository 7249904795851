import { CorIcon, CorInput, CorText } from '@e-reteta/react-design-system';
import { FC, useEffect, useState } from 'react';
import hash from 'object-hash';

import type { PlusInputProps } from './interface';
import { IconColor, IconName } from '../../../enums/design-system-icons.enum';
import { TextVariant } from '../../../enums/design-system.enum';

const PlusInput: FC<PlusInputProps> = ({
  placeholder,
  addNewChip,
  name,
  className,
  label,
  autoComplete = 'off',
  error,
  diagnostics,
  searchDiagnostics,
  selectedDiagnostics,
  isDiagnosticInput,
  setSelectedDiagnostics,
}) => {
  const [value, setValue] = useState<string>('');

  const handleChange = (value: string) => {
    setValue(value);
    if (searchDiagnostics) {
      searchDiagnostics(value);
    }
  };

  const handleAddChip = () => {
    if (!isDiagnosticInput) {
      addNewChip(value);
      setValue('');
    }
  };

  useEffect(() => {
    if (!isDiagnosticInput) {
      const inputElement = document.getElementById(name);
      const handleFocus = (event: KeyboardEvent) => {
        if (document.activeElement === inputElement) {
          if (event.key === 'Enter') {
            handleAddChip();
          }
        }
      };
      inputElement?.addEventListener('keypress', handleFocus);
      return () => {
        inputElement?.removeEventListener('keypress', handleFocus);
      };
    }
  }, [value]);

  const handleClickDiagnostic = (value: string) => {
    if (!selectedDiagnostics?.includes(value)) {
      addNewChip(value);
    }
    if (setSelectedDiagnostics) {
      setSelectedDiagnostics([]);
    }
    setValue('');
  };

  return (
    <div className={className}>
      <CorText variant={TextVariant.LABEL_2_REGULAR} className="mb-1">
        <div className="text-content-muted">{label}</div>
      </CorText>
      <div className="relative">
        <CorInput error={Boolean(error)}>
          <input
            id={name}
            autoComplete={autoComplete}
            name={name}
            placeholder={placeholder}
            value={value}
            onChange={(event) => handleChange(event.target.value)}
          />
          {error ? <span className="bottom-helper">{error}</span> : null}
        </CorInput>
        <CorIcon
          className="absolute top-3 right-4"
          name={IconName.PLUS}
          color={IconColor.CONTENT_MUTED}
          onClick={handleAddChip}
        />
        {diagnostics?.length && isDiagnosticInput ? (
          <div className="absolute top-12 border-border-default border border-solid shadow-elevation-1 w-full rounded-lg py-2 overflow-hidden bg-white z-50">
            {diagnostics.map((diagnostic) => (
              <div
                key={hash(diagnostic)}
                className="flex justify-between items-center py-2 px-4 hover:bg-surface-hover active:bg-surface-pressed"
                onClick={() => handleClickDiagnostic(diagnostic.code)}
              >
                <CorText
                  variant={TextVariant.LABEL_2_REGULAR}
                  className="text-content-muted whitespace-nowrap"
                >
                  <div>{`${diagnostic.code} - ${diagnostic.name}`}</div>
                </CorText>
                {selectedDiagnostics?.includes(diagnostic.code) ? (
                  <CorIcon name={IconName.CHECK} />
                ) : null}
              </div>
            ))}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default PlusInput;
