/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { isAxiosError } from 'axios';
import { T, useTranslate } from '@tolgee/react';
import { CorButton, CorText } from '@e-reteta/react-design-system';

import useWindowWidth from '../../hooks/use-window-width';
import { Alert, HandwrittenTable, Pagination, Tabs } from '../../components';
import { ButtonSize, TextVariant } from '../../enums/design-system.enum';
import { AlertStatus } from '../../components/Alert/enum';
import { PaginationData } from '../../components/Pagination/interface';
import { DEFAULT_PAGINATION_DATA } from '../../components/Pagination/constants';
import HandwrittenTableMobile from '../../components/HandwrittenTableMobile';
import { PharmacyService } from '../../services/pharmacy.services';
import ConfirmModal from '../../components/ConfirmModal';
import useQuery from '../../hooks/use-query';
import { AuthContext } from '../../context/context';
import { handleError } from '../../utils/handleRequestErrors';
import { renderResponsiveClasses } from '../../utils/render-responsive-classes.utils';
import { DispensesStatusEnum } from '../../enums/dispenses-status.enum';
import { OpenedTab } from '../../components/PatientProfile/enums';
import { LoaderContext } from '../../context/loader/context';
import { TargetUrlEnum } from '../../enums/target-url.enum';
import { Dispense } from '../../types/CommonTypes';

const UnsignedDispenses: FC = () => {
  const { t } = useTranslate();
  const query = useQuery();
  const { isMobile, isTablet } = useWindowWidth();
  const [showModalConfirm, setShowModalConfirm] = useState<boolean>(false);
  const [signed, setSigned] = useState<boolean>(false);
  const [errorSigned, setErrorSigned] = useState<boolean>(false);
  const [signedAll, setSignedAll] = useState<boolean>(true);
  const [countSelected, setCountSelected] = useState<number>(0);
  const [myDispensesCount, setMyDispensesCount] = useState<number>(0);
  const [myDispensesPaginationData, setMyDispensesPaginationData] =
    useState<PaginationData>(DEFAULT_PAGINATION_DATA);
  const [allDispensesPaginationData, setAllDispensesPaginationData] =
    useState<PaginationData>(DEFAULT_PAGINATION_DATA);
  const [myDispenses, setMyDispenses] = useState<Dispense[]>([]);
  const [allDispenses, setAllDispenses] = useState<Dispense[]>([]);
  const [isChecked, setIsChecked] = useState<string[]>([]);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const { role, hasRole } = useContext(AuthContext);
  const { setLoading } = useContext(LoaderContext);
  const [currentTab, setCurrentTab] = useState<string>(
    role === 'pharmacy' ? OpenedTab.MY_RECEIPTS : OpenedTab.ALL_RECEITS,
  );
  const [availableTabs, setAvailableTabs] = useState<string[]>([]);

  useEffect(() => {
    if (currentTab === OpenedTab.ALL_RECEITS) {
      setIsChecked([]);
      setIsAllChecked(false);
      setCountSelected(0);
    }
  }, [currentTab]);
  useEffect(() => {
    if (!role) {
      return;
    }
    if (hasRole('LOCAL_ADMIN')) {
      setAvailableTabs([OpenedTab.MY_RECEIPTS, OpenedTab.ALL_RECEITS]);
      return;
    }

    setAvailableTabs([OpenedTab.MY_RECEIPTS]);
  }, [role]);
  const signDespenses = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await PharmacyService.signDispenses({
        items: isChecked,
        targetUrl: TargetUrlEnum.UNSIGNED_DISPENSES,
      });
      if (data?.redirectUrl) {
        window?.location?.replace(data?.redirectUrl);
      }
    } catch (error) {
      isAxiosError(error) && handleError(error);
    } finally {
      setLoading(true);
    }
  }, [isChecked]);

  const getAllDispenses = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await PharmacyService.getDispenses({
        statuses: [DispensesStatusEnum.NOT_SIGNED],
        limit: allDispensesPaginationData.itemsPerPage,
        skip: allDispensesPaginationData.page * allDispensesPaginationData.itemsPerPage,
      });
      setAllDispenses(data.items);
      allDispensesPaginationData.totalPages = Math.ceil(
        data.count / allDispensesPaginationData.itemsPerPage,
      );
      allDispensesPaginationData.totalItems = data.count;
      setAllDispensesPaginationData(allDispensesPaginationData);
    } catch (error) {
      isAxiosError(error) && handleError(error);
    } finally {
      setLoading(false);
    }
  }, [allDispensesPaginationData.page, allDispensesPaginationData.itemsPerPage]);

  const getMyDispenses = useCallback(async () => {
    try {
      setLoading(true);
      const query = {
        statuses: [DispensesStatusEnum.NOT_SIGNED],
        limit: myDispensesPaginationData.itemsPerPage,
        skip: myDispensesPaginationData.page * myDispensesPaginationData.itemsPerPage,
        orderBy: {
          createdAt: 'DESC',
        },
      };
      const { data } = await PharmacyService.getMyDispenses(query);
      setMyDispenses(data.items);
      setMyDispensesCount(data.count);
      myDispensesPaginationData.totalPages = Math.ceil(
        data.count / myDispensesPaginationData.itemsPerPage,
      );
      myDispensesPaginationData.totalItems = data.count;
      setMyDispensesPaginationData(myDispensesPaginationData);
    } catch (error) {
      isAxiosError(error) && handleError(error);
    } finally {
      setLoading(false);
    }
  }, [myDispensesPaginationData.page, myDispensesPaginationData.itemsPerPage]);

  useEffect(() => {
    if (currentTab === OpenedTab.MY_RECEIPTS) {
      getMyDispenses();
    }
  }, [myDispensesPaginationData.itemsPerPage, myDispensesPaginationData.page]);

  useEffect(() => {
    if (currentTab === OpenedTab.ALL_RECEITS) {
      getAllDispenses();
    }
  }, [allDispensesPaginationData.itemsPerPage, allDispensesPaginationData.page]);

  const chekSign = () => {
    setShowModalConfirm(true);
  };

  const closeCheckSign = () => {
    setShowModalConfirm(false);
  };

  const onSignAllRecipes = () => {
    if (countSelected) {
      if (countSelected >= myDispenses.length) {
        setSignedAll(true);
      }
      signDespenses();
    }
  };
  const handleCheckboxClick = (id: string) => {
    const exist = isChecked.some((r) => r === id);
    let newChecked = isChecked;
    if (exist) {
      newChecked = newChecked.filter((r) => r !== id);
    } else {
      newChecked.push(id);
    }
    setIsChecked(newChecked);
    setCountSelected(newChecked.length);
  };

  const handleToggleAll = () => {
    if (isAllChecked) {
      setIsChecked([]);
      setIsAllChecked(false);
      setCountSelected(0);
    } else {
      setIsChecked(myDispenses.map((r) => r.id));
      setIsAllChecked(true);
      setCountSelected(myDispensesCount);
    }
  };

  useEffect(() => {
    if (query && query.get('sign-status') === 'Success') {
      setSigned(true);
    }
    if (query && query.get('sign-status') === 'Error') {
      setErrorSigned(true);
    }
  }, [query]);

  return (
    <div className="flex flex-col overflow-auto hide-scrollbar px-0 sm:px-4 relative h-full">
      {signedAll ? (
        <>
          <div className="flex flex-row justify-between items-start gap-6 w-full sm:pt-4 lg:pt-0">
            <CorText variant={TextVariant.HEADING_2_BOLD}>
              <h3>
                <T keyName="unsignedDispenses.unsignedDispenses">Eliberări Nesemnate</T>
              </h3>
            </CorText>
          </div>
          {signed ? (
            <Alert
              label={t('unsignedDispenses.successSign', 'Semnarea a fost efectuata cu succes')}
              status={AlertStatus.SUCCESS}
            />
          ) : null}
          {errorSigned ? (
            <Alert
              label={t('unsignedDispenses.errorSign', 'Semnarea a esuat')}
              status={AlertStatus.DANGER}
            />
          ) : null}
          <div className="flex flex-col gap-4">
            <div className="flex flex-row justify-between w-full">
              <div className="flex flex-row items-end gap-6 whitespace-nowrap flex-1 h-[40px]">
                <Tabs values={availableTabs} onChange={(value) => setCurrentTab(value)} />
              </div>
              {!isMobile && currentTab === OpenedTab.MY_RECEIPTS && myDispenses?.length ? (
                <CorButton onClick={chekSign}>
                  <button disabled={countSelected === 0}>
                    <T keyName="unsignedDispenses.sign">Semnează</T>{' '}
                    {countSelected !== 0 ? `(${countSelected})` : null}
                  </button>
                </CorButton>
              ) : null}
            </div>

            {myDispenses?.length ? (
              <div>
                <div className="hidden md:flex pb-6">
                  <HandwrittenTable
                    hasCheckbox={currentTab === OpenedTab.MY_RECEIPTS}
                    isChecked={isChecked}
                    toggleAll={handleToggleAll}
                    checkboxClick={handleCheckboxClick}
                    recipes={currentTab === OpenedTab.MY_RECEIPTS ? myDispenses : allDispenses}
                  />
                </div>
                <div className="grid md:hidden w-full pb-4">
                  <HandwrittenTableMobile
                    hasCheckbox={currentTab === OpenedTab.MY_RECEIPTS}
                    isChecked={isChecked}
                    toggleAll={handleToggleAll}
                    checkboxClick={handleCheckboxClick}
                    recipes={currentTab === OpenedTab.MY_RECEIPTS ? myDispenses : allDispenses}
                  />
                </div>
                <div className="flex flex-row justify-between items-center pb-14 sm:pb-0">
                  {currentTab === OpenedTab.MY_RECEIPTS ? (
                    <Pagination
                      key={OpenedTab.MY_RECEIPTS}
                      paginationData={myDispensesPaginationData}
                      setPaginationData={(props) => {
                        setMyDispensesPaginationData(props);
                      }}
                    />
                  ) : (
                    <Pagination
                      key={OpenedTab.ALL_RECEITS}
                      paginationData={allDispensesPaginationData}
                      setPaginationData={(props) => {
                        setAllDispensesPaginationData(props);
                      }}
                    />
                  )}
                </div>
              </div>
            ) : (
              <div
                className={`w-full h-full justify-center items-center p-4 rounded-2xl mt-6 flex`}
              >
                <div className="flex flex-col gap-8 w-[301px] sm:w-[401px] items-center text-center">
                  <CorText
                    variant={renderResponsiveClasses(
                      isMobile,
                      isTablet,
                      TextVariant.HEADING_4_BOLD,
                      TextVariant.HEADING_3_BOLD,
                      TextVariant.HEADING_2_BOLD,
                    )}
                  >
                    <span className="text-content-default text-center">
                      <T keyName="unsignedDispenses.noUnsignedDispensesText">
                        Nu aveti nici o eliberare nesemnata
                      </T>
                    </span>
                  </CorText>
                  <img className="w-[200px] sm:w-[400px]" src="/search.png" alt="search" />
                </div>
              </div>
            )}
            {isMobile && currentTab === OpenedTab.MY_RECEIPTS && myDispenses?.length ? (
              <CorButton
                size={ButtonSize.MEDIUM}
                onClick={() => handleCheckboxClick}
                className="w-full fixed bottom-0 left-0 z-100 pb-4 px-4 mt-4"
              >
                <button className="w-full">
                  <T keyName="unsignedDispenses.sign">Semnează</T>{' '}
                  {countSelected !== 0 ? `(${countSelected})` : null}
                </button>
              </CorButton>
            ) : null}
          </div>
        </>
      ) : (
        <div className="flex flex-col h-full sm:py-4">
          <Alert
            text={t(
              'unsignedDispenses.allPrescriptionSigned',
              'Toate retetele au fost semnate cu succes!',
            )}
            className="h-fit flex-row"
            status={AlertStatus.SUCCESS}
          />
          <div className={`flex w-full h-full justify-center items-center p-4 rounded-3xl mt-6`}>
            <div className="flex flex-col justify-center gap-6 max-w-[400px] w-full">
              <CorText variant={TextVariant.HEADING_2_BOLD} className="flex justify-center">
                <span className="text-black">
                  <T keyName="unsignedDispenses.notFoundPrescription">Nu este nici o reteta</T>
                </span>
              </CorText>
              <img src="/not-found.png" alt="not found" />
            </div>
          </div>
        </div>
      )}
      {showModalConfirm && (
        <ConfirmModal
          title={t('unsignedDispenses.attention', 'Atentie')}
          description={t('unsignedDispenses.signConfirm', 'Ești sigur că semnezi rețeta?')}
          onClose={closeCheckSign}
          onAccept={onSignAllRecipes}
        />
      )}
    </div>
  );
};

export default UnsignedDispenses;
