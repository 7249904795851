import { SVGProps } from 'react';

const Dots = (props: SVGProps<any>) => {
  return (
    <svg width="13" height="11" viewBox="0 0 13 11" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M1.04756 2.11492C1.63691 2.12165 2.10839 1.67374 2.11849 1.0945C2.12859 0.481574 1.66385 -8.58307e-06 1.0644 -8.58307e-06C0.488518 -8.58307e-06 0.00356793 0.481574 0.000200033 1.06082C-0.00316763 1.6367 0.468312 2.10818 1.04756 2.11492Z" />
      <path d="M11.1102 10.1435C11.6861 10.157 12.1778 9.67204 12.1811 9.09617C12.1845 8.50682 11.7198 8.0387 11.1304 8.0286C10.5478 8.02187 10.0662 8.47987 10.0595 9.05575C10.0561 9.65521 10.5107 10.1301 11.1102 10.1435Z" />
      <path d="M6.70513 5.37151C7.28101 5.36814 7.75586 4.8933 7.75586 4.32078C7.75586 3.72807 7.28101 3.24985 6.68829 3.25322C6.10231 3.25659 5.63757 3.73143 5.64094 4.32078C5.6443 4.9135 6.11578 5.37825 6.70513 5.37151Z" />
    </svg>
  );
};

export default Dots;
