import {
  ChangeEvent,
  FC,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { CorDropdown, CorInput, CorRadioButton, CorText } from '@e-reteta/react-design-system';
import { useParams } from 'react-router-dom';
import { T, useTranslate } from '@tolgee/react';

import type { DrugFieldsProps } from './interface';
import { TextVariant } from '../../enums/design-system.enum';
import { CorDropdownOption } from '../index';
import Alert from '../Alert';
import { AlertStatus } from '../Alert/enum';
import { DeviceOrDrug } from './enum';
import { DrugService } from '../../services/drug.service';
import { checkIfHave18 } from '../../utils/check-18.utils';
import { Drug } from '../../types/Drug';
import { RestrictionsEnum } from '../../enums/restrictions.enum';
import InputWithAmount from '../InputWithAmount';
import disableScrollOnInput from '../../utils/disableScrollOnInput';
import { DropdownEvent } from '../../types/CommonTypes';
import { DPrescriptions } from '../../services/prescription.services';
import { LoaderContext } from '../../context/loader/context';
import { toast } from 'react-toastify';
import { notIntegerSymbols } from '../../constants/not-integer';
import dayjs from 'dayjs';

const DrugFields: FC<DrugFieldsProps> = ({
  count,
  drugs,
  setDisabled,
  disabled,
  formik,
  selectedDrugs = [],
  patient,
  restriction,
  setRestriction,
  setDisabledActions,
  resetWarning,
  setResetWarning,
}) => {
  const { idnp = '', action, prescriptionId } = useParams();
  const { t } = useTranslate();
  const inputRefs = useRef<(HTMLInputElement | null)[]>(Array(4).fill(null));
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [touched, setTouched] = useState(['']);
  const { setLoading } = useContext(LoaderContext);

  const [warningMessage, setWarningMessage] = useState<string>('');

  const onSelectDropdownDrugAdministrationRoute = async (e: DropdownEvent) => {
    await formik?.setFieldValue(
      `dPrescriptions[${count}].drugRouteOfAdministration`,
      e?.detail?.value,
    );
    await formik?.setFieldValue(`dPrescriptions[${count}].drugDose`, '');
    await formik?.setFieldValue(`dPrescriptions[${count}].quantity`, '');
    await formik?.setFieldValue(`dPrescriptions[${count}].validity`, '');
    await formik?.setFieldValue(`dPrescriptions[${count}].signature`, '');
    await formik?.setFieldValue(`dPrescriptions[${count}].expiration`, 0);
    if (restriction.type === RestrictionsEnum.RESTRICTION) {
      setRestriction({ type: RestrictionsEnum.NONE });
    }
    setTouched(['']);
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>, field: string) => {
    setTouched((prevState) => [...prevState, field]);
    formik?.handleChange(event);
  };

  const checkAdultWarning = () => {
    const is18 = checkIfHave18(patient.birthday ? new Date(patient.birthday) : new Date());
    const drug = drugs?.filter(
      (item: Drug) =>
        item.internationalName === formik.values.dPrescriptions[count].drugInternationalName &&
        item.routeOfAdministration ===
          formik.values.dPrescriptions[count].drugRouteOfAdministration &&
        item.dose === formik.values.dPrescriptions[count].drugDose,
    );
    if (
      is18 &&
      drug?.some((d: Drug) => d.isAllowedForChildren) &&
      !drug.some((d: Drug) => d.isAllowedForAdult)
    ) {
      return RestrictionsEnum.ADULT_RESTRICTION;
    }
    if (
      !is18 &&
      !drug?.some((d: Drug) => d.isAllowedForChildren) &&
      drug?.some((d: Drug) => d.isAllowedForAdult)
    ) {
      return RestrictionsEnum.CHILDREN_RESTRICTION;
    }
    return RestrictionsEnum.NONE;
  };

  const drugsMap: Record<string, Drug> = useMemo(() => {
    const tmpMap: Record<string, Drug> = {};
    drugs?.forEach((drug) => {
      tmpMap[drug.internationalName] = drug;
    });
    return tmpMap;
  }, [drugs]);

  const haveIntersection = (arr1: string[] = [], arr2: string[] = []): false | string => {
    const set1 = new Set(arr1);
    for (let item of arr2) {
      if (set1.has(item)) {
        return item;
      }
    }
    return false;
  };

  const hasGroupRestrictions = (drug: string, oldVal: string = ''): string | false => {
    if (selectedDrugs.includes(drug)) {
      return false;
    }
    const selectedGroups: string[] =
      selectedDrugs?.reduce((accum: string[], value: string) => {
        if (oldVal === value) {
          return accum;
        }
        return [...accum, ...drugsMap[value]?.groups?.map((group) => group.name)];
      }, []) || [];
    return haveIntersection(
      selectedGroups,
      drugsMap[drug]?.groups?.map((group) => group.name),
    );
  };

  const checkRestrictions = async (
    internationalName: string,
    oldVal?: string,
  ): Promise<boolean> => {
    const groupRestriction = hasGroupRestrictions(internationalName, oldVal);
    if (groupRestriction) {
      setRestriction({ type: RestrictionsEnum.GROUP_RESTRICTION, details: { groupRestriction } });
      return true;
    }

    return false;
  };
  const checkWarnings = (internationalName: string) => {
    const currentProduct = drugs?.find(
      (drug: Drug) => internationalName === drug.internationalName,
    );

    if (currentProduct?.warningsDCI) {
      setWarningMessage(currentProduct.warningsDCI);
    } else {
      setWarningMessage('');
    }
  };

  useEffect(() => {
    if (resetWarning) {
      setWarningMessage('');
    }
    setResetWarning(false);
  }, [resetWarning]);

  useEffect(() => {
    if (!formik.values.dPrescriptions[count].drugInternationalName) {
      return;
    }

    if (
      !formik.values.dPrescriptions[count].drugRouteOfAdministration !==
      !formik.values.dPrescriptions[count].drugDose
    ) {
      return;
    }

    const adultWarning = checkAdultWarning();
    setRestriction({ type: adultWarning });

    if (
      restriction.type === RestrictionsEnum.GROUP_RESTRICTION ||
      adultWarning === RestrictionsEnum.CHILDREN_RESTRICTION ||
      adultWarning === RestrictionsEnum.ADULT_RESTRICTION
    ) {
      return;
    }

    (async () => {
      try {
        if (formik.values?.dPrescriptions?.length > 1) {
          const inPrescription: DPrescriptions[] = formik.values?.dPrescriptions.slice();
          inPrescription.splice(count, 1);
          if (
            inPrescription.some(
              (item) =>
                item.drugInternationalName ===
                  formik.values.dPrescriptions[count].drugInternationalName &&
                item.drugRouteOfAdministration ===
                  formik.values.dPrescriptions[count].drugRouteOfAdministration &&
                item.drugDose === formik.values.dPrescriptions[count].drugDose,
            )
          ) {
            setRestriction({ type: RestrictionsEnum.RESTRICTION, details: {} });
            await formik?.setFieldValue(`dPrescriptions[${count}].quantity`, '');
            await formik?.setFieldValue(`dPrescriptions[${count}].validity`, '');
            await formik?.setFieldValue(`dPrescriptions[${count}].signature`, '');
            await formik?.setFieldValue(`dPrescriptions[${count}].expiration`, 0);
            setTouched([]);
            return true;
          }
        }
        setLoading(true);
        const resp = await DrugService.checkAvailable({
          idnp,
          internationalName: formik.values.dPrescriptions[count].drugInternationalName,
          routeOfAdministration: formik.values.dPrescriptions[count].drugRouteOfAdministration,
          dose: formik.values.dPrescriptions[count].drugDose,
        });
        if (
          !resp?.data?.isAvailable &&
          (action === 'clone' || resp?.data?.prescriptionId !== prescriptionId)
        ) {
          setRestriction({ type: RestrictionsEnum.RESTRICTION, details: resp?.data });
          await formik?.setFieldValue(`dPrescriptions[${count}].quantity`, '');
          await formik?.setFieldValue(`dPrescriptions[${count}].validity`, '');
          await formik?.setFieldValue(`dPrescriptions[${count}].signature`, '');
          await formik?.setFieldValue(`dPrescriptions[${count}].expiration`, 0);
          setTouched([]);
          return true;
        }
        if (!resp?.data?.notHaveGroupConflict) {
          setRestriction({ type: RestrictionsEnum.GROUP_WARNING, details: resp?.data });
          return;
        }

        setRestriction({ type: RestrictionsEnum.NONE });
      } catch (e: any) {
        toast.error(
          t('drugFields.error', 'Eroare la verificarea disponibilitatii', {
            error: e?.response?.data?.message || e?.toString(),
          }),
        );
      } finally {
        setLoading(false);
      }
    })();
  }, [
    formik.values.dPrescriptions[count].drugInternationalName,
    formik.values.dPrescriptions[count].drugDose,
  ]);
  const handleInternationalNameChange = async (event: { detail: { value: string } }) => {
    if (setDisabled) {
      setDisabled(true);
    }
    const oldName = formik.values.dPrescriptions[count]?.drugInternationalName;
    formik.setFieldValue(`dPrescriptions[${count}]`, {
      deviceCommercialCod: '',
      drugDose: '',
      drugRouteOfAdministration: '',
      validity: '',
      quantity: '',
      type: DeviceOrDrug.DRUG,
      drugInternationalName: event?.detail?.value,
      expiration: 0,
      remove: false,
      signature: '',
    });
    setTouched([]);

    const hasRestrictions = await checkRestrictions(event?.detail?.value, oldName);
    if (!hasRestrictions) {
      checkWarnings(event?.detail?.value);
    }
  };

  useEffect(() => {
    if (
      action !== 'clone' ||
      (action === 'clone' && formik.values.dPrescriptions[count].drugInternationalName === '')
    ) {
      return;
    }
    checkRestrictions(formik.values.dPrescriptions[count].drugInternationalName).then(
      (hasRestrictions) => {
        const internationalName = formik.values.dPrescriptions[count].drugInternationalName;
        if (hasRestrictions) {
          formik.setFieldValue(`dPrescriptions[${count}]`, {
            deviceCommercialCod: '',
            drugDose: '',
            drugRouteOfAdministration: '',
            validity: '',
            quantity: '',
            type: DeviceOrDrug.DRUG,
            drugInternationalName: internationalName,
            remove: false,
            signature: '',
            expiration: 0,
          });
          if (setDisabledActions) {
            setDisabledActions(false);
          }

          return;
        }
        if (setDisabledActions) {
          setDisabledActions(true);
        }
        checkWarnings(internationalName);
      },
    );
  }, []);

  const getWarningMessage = () => {
    switch (restriction.type) {
      case RestrictionsEnum.RESTRICTION: {
        return (
          <>
            {restriction.details?.prescriptionId ? (
              <T
                keyName={'drugFields.warningMessageRestriction'}
                params={{
                  blockedUntil: dayjs(restriction.details?.blockedUntil).format('DD/MM/YYYY hh:mm'),
                  prescriptionId: restriction.details?.prescriptionId || '',
                }}
              >
                Medicumantul selectat a fost deja prescris
              </T>
            ) : (
              <T keyName={'drugFields.warningMessageRestrictionDeduplicate'}>
                Medicumantul selectat a fost deja prescris in acesta reteta
              </T>
            )}
          </>
        );
      }
      case RestrictionsEnum.ADULT_RESTRICTION: {
        return (
          <T keyName={'drugFields.warningMessageAdultRestriction'}>
            Substanța activă selectată nu poate fi prescrisă pentru adulți
          </T>
        );
      }
      case RestrictionsEnum.CHILDREN_RESTRICTION: {
        return (
          <T keyName={'drugFields.warningMessageChildrenRestriction'}>
            Substanța activă selectată nu poate fi prescrisă pentru copii cu vârsta între 0 și 18
            ani
          </T>
        );
      }
      case RestrictionsEnum.GROUP_WARNING: {
        return (
          <T keyName={'drugFields.warningMessageGroupWarning'}>
            O substanță activă din aceeași grupă farmacoterapeutică a fost deja prescrisă în
            ultimele 3 luni
          </T>
        );
      }
      case RestrictionsEnum.GROUP_RESTRICTION: {
        return (
          <T
            keyName={'drugFields.warningMessageGroupRestriction'}
            params={{ groupRestriction: restriction.details?.groupRestriction }}
          >
            O substanță activă din aceeași grupă farmacoterapeutică a fost deja prescrisă în aceasta
            reteta
          </T>
        );
      }
    }
  };

  const getWarningType = () => {
    switch (restriction.type) {
      case RestrictionsEnum.RESTRICTION:
      case RestrictionsEnum.ADULT_RESTRICTION:
      case RestrictionsEnum.GROUP_RESTRICTION:
      case RestrictionsEnum.CHILDREN_RESTRICTION: {
        return AlertStatus.DANGER;
      }
      case RestrictionsEnum.GROUP_WARNING: {
        return AlertStatus.WARNING;
      }
    }
  };

  const getTitleAlert = () => {
    switch (restriction.type) {
      case RestrictionsEnum.RESTRICTION:
      case RestrictionsEnum.ADULT_RESTRICTION:
      case RestrictionsEnum.CHILDREN_RESTRICTION: {
        return <T keyName={'drugFields.restrictionAlertLabel'}>Restrictionare</T>;
      }
      case RestrictionsEnum.GROUP_WARNING: {
        return <T keyName={'drugFields.warningAlertLabel'}>Avertizare</T>;
      }
    }
  };

  const getInternationalNames = () => {
    const drugsNames = drugs?.map((drug) => drug.internationalName);
    const seen = new Set<string>();

    if (drugsNames?.length) {
      for (const item of drugsNames) {
        if (!seen.has(item)) {
          seen.add(item);
        }
      }
      return Array.from(seen);
    }
  };

  const getRoutes = (internationalName: string) => {
    const drugForms = drugs
      ?.filter((drug) => drug.internationalName === internationalName)
      ?.map((drug) => drug.routeOfAdministration);
    const seen = new Set();

    if (drugForms?.length) {
      for (const item of drugForms) {
        if (!seen.has(item)) {
          seen.add(item);
        }
      }
      return Array.from(seen);
    }
  };

  const getDozes = (administrationRoute: string, internationalName: string) => {
    const drugDozes = drugs
      ?.filter(
        (drug) =>
          drug.routeOfAdministration === administrationRoute &&
          drug.internationalName === internationalName,
      )
      ?.map((drug) => drug.dose);
    const seen = new Set<string>();

    if (drugDozes?.length) {
      for (const item of drugDozes) {
        if (!seen.has(item)) {
          seen.add(item);
        }
      }
      return Array.from(seen);
    }
  };

  const getTreatmentDuration = useCallback(
    (dose: string, routeOfAdministration: string, internationalName: string) => {
      return drugs?.find(
        (drug) =>
          drug.dose === dose &&
          drug.routeOfAdministration === routeOfAdministration &&
          drug.internationalName === internationalName,
      )?.treatmentDuration;
    },
    [drugs],
  );

  const formatDaysText =
    getTreatmentDuration(
      formik?.values?.dPrescriptions[count]?.drugDose,
      formik?.values?.dPrescriptions[count]?.drugRouteOfAdministration,
      formik?.values?.dPrescriptions[count]?.drugInternationalName,
    ) >= 20
      ? t('drugFields.daysAbove20', 'de zile')
      : t('drugFields.days', 'zile');

  const treatmentDurationErrorMessage =
    formik?.values?.dPrescriptions[count].validity <= 0
      ? t(
          'drugFields.defaultTreatmentDurationErrorMessage',
          'Durata tratamentului nu poate fi o valoare negativa sau 0',
        )
      : `${t(
          'drugFields.customTreatmentDurationErrorMessage',
          'Durata tratamentului nu poate depasi',
        )} ${getTreatmentDuration(
          formik?.values?.dPrescriptions[count]?.drugDose,
          formik?.values?.dPrescriptions[count]?.drugRouteOfAdministration,
          formik?.values?.dPrescriptions[count]?.drugInternationalName,
        )} ${formatDaysText}`;

  const checkQuantity = () => {
    if (typeof formik?.values?.dPrescriptions[count]?.quantity == 'string') {
      return false;
    }
    const quantity = Number(formik?.values?.dPrescriptions[count]?.quantity);
    return quantity < 1;
  };

  const checkTreatmentDuration = () => {
    if (typeof formik?.values?.dPrescriptions[count]?.validity === 'string') {
      return false;
    }
    const validity = Number(formik?.values?.dPrescriptions[count]?.validity);
    const maxDuration = Number(
      getTreatmentDuration(
        formik?.values?.dPrescriptions[count]?.drugDose,
        formik?.values?.dPrescriptions[count]?.drugRouteOfAdministration,
        formik?.values?.dPrescriptions[count]?.drugInternationalName,
      ),
    );

    return validity < 1 || validity > maxDuration;
  };

  useEffect(() => {
    if (formik?.values?.dPrescriptions[count]?.validity) {
      const treatmentDuration =
        drugs?.find(
          (drug) =>
            drug.dose === formik?.values?.dPrescriptions[count]?.drugDose &&
            drug.routeOfAdministration ===
              formik?.values?.dPrescriptions[count]?.drugRouteOfAdministration &&
            drug.internationalName === formik?.values?.dPrescriptions[count]?.drugInternationalName,
        )?.treatmentDuration || 0;

      formik.setFieldValue(`dPrescriptions[${count}].treatmentDuration`, treatmentDuration);
    }
  }, [formik?.values?.dPrescriptions[count]?.validity, drugs]);

  useEffect(() => {
    if (setDisabled) {
      const isValid =
        formik?.values?.dPrescriptions[count]?.drugInternationalName?.length &&
        formik?.values?.dPrescriptions[count]?.drugRouteOfAdministration?.length &&
        formik?.values?.dPrescriptions[count]?.drugDose?.length &&
        typeof formik?.values?.dPrescriptions[count]?.quantity === 'number' &&
        formik?.values?.dPrescriptions[count]?.quantity > 0 &&
        typeof formik?.values?.dPrescriptions[count]?.validity === 'number' &&
        formik?.values?.dPrescriptions[count]?.validity > 0 &&
        formik?.values?.dPrescriptions[count]?.signature?.length <= 50 &&
        !checkQuantity() &&
        !checkTreatmentDuration();

      setDisabled(!isValid);
    }
  }, [formik, count, setDisabled]);

  const handelDoseChange = (dose: string) => {
    const expiration = drugs?.find(
      (drug) =>
        drug.routeOfAdministration ===
          formik?.values?.dPrescriptions[count]?.drugRouteOfAdministration &&
        drug.internationalName === formik?.values?.dPrescriptions[count]?.drugInternationalName &&
        drug.dose === dose,
    )?.expiration;

    formik?.setFieldValue(`dPrescriptions[${count}].drugDose`, dose);
    formik?.setFieldValue(`dPrescriptions[${count}].expiration`, expiration);
  };

  const renderAlertNotCompensated = () => {
    if (drugs && drugs?.length > 0) {
      return;
    }
    return (
      <Alert
        status={AlertStatus.WARNING}
        label={t(
          'drugFields.noCompensatedDrugsForSelecting',
          'Nu sunt medicamente compensate pentru diagnostic selectat',
        )}
        className="col-span-7 col-start-6 mt-5"
      />
    );
  };

  return (
    <div className="flex flex-col gap-4">
      {formik?.values?.dPrescriptions[count]?.type ? (
        <div className="flex flex-col sm:grid sm:grid-cols-12 gap-1">
          <CorText variant={TextVariant.LABEL_2_REGULAR} className="col-span-5">
            <span className="text-content-muted">
              <T keyName={'drugFields.chooseSubstance'}>Alege Substanta activa</T>
            </span>
          </CorText>
          <div className="col-span-7">
            <CorDropdown
              placeholder={t('drugFields.selectDrug', 'Selectati substanta activa')}
              searchEnabled={true}
              searchPlaceholder={t('drugFields.searchDrug', 'Cauta substanta activa')}
              hasSelectedValue={!!formik?.values?.dPrescriptions[count]?.drugInternationalName}
              callback={(e) => handleInternationalNameChange(e)}
              disabled={!drugs?.length || disabled}
            >
              {getInternationalNames()?.length
                ? getInternationalNames()?.map((drug) => {
                    return (
                      <CorDropdownOption
                        key={drug}
                        value={drug}
                        label={drug}
                        selected={
                          drug === formik?.values?.dPrescriptions[count]?.drugInternationalName
                        }
                      />
                    );
                  })
                : null}
            </CorDropdown>
            {renderAlertNotCompensated()}
            {warningMessage !== '' &&
            (restriction.type === RestrictionsEnum.NONE ||
              restriction.type === RestrictionsEnum.GROUP_WARNING) ? (
              <Alert
                className="mt-1"
                label={t('drugFields.warningAlertLabel', 'Avertizare')}
                text={warningMessage}
                status={AlertStatus.WARNING}
              />
            ) : null}
            {restriction.type !== RestrictionsEnum.NONE &&
            restriction.type !== RestrictionsEnum.RESTRICTION &&
            restriction.type !== RestrictionsEnum.ADULT_RESTRICTION &&
            restriction.type !== RestrictionsEnum.CHILDREN_RESTRICTION ? (
              <Alert
                className="mt-1"
                label={getTitleAlert()}
                text={getWarningMessage()}
                status={getWarningType()}
              />
            ) : null}
          </div>
        </div>
      ) : null}
      {formik?.values?.dPrescriptions[count]?.drugInternationalName?.length ? (
        <div className="flex flex-col sm:grid sm:grid-cols-12 gap-1">
          <CorText variant={TextVariant.LABEL_2_REGULAR} className="col-span-5">
            <span className="text-content-muted">
              <T keyName={'drugFields.routeOfAdministration'}>Alege modul de administrare</T>
            </span>
          </CorText>
          <CorDropdown
            className="col-span-7"
            placeholder={t(
              'drugFields.selectAdministrationRoute',
              'Selectati calea de administrare',
            )}
            callback={(e) => onSelectDropdownDrugAdministrationRoute(e)}
            searchEnabled={true}
            hasSelectedValue={!!formik?.values?.dPrescriptions[count]?.drugRouteOfAdministration}
            searchPlaceholder={t('drugFields.searchReleaseForm', 'Cauta forma de eliberare')}
          >
            {getRoutes(formik?.values?.dPrescriptions[count]?.drugInternationalName)?.length
              ? getRoutes(formik?.values?.dPrescriptions[count]?.drugInternationalName)?.map(
                  (form) => {
                    return (
                      // @ts-ignore
                      <cor-dropdown-option
                        key={form}
                        value={form}
                        label={form}
                        selected={
                          form === formik?.values?.dPrescriptions[count]?.drugRouteOfAdministration
                        }
                      />
                    );
                  },
                )
              : null}
          </CorDropdown>
        </div>
      ) : null}
      {formik?.values?.dPrescriptions[count]?.drugInternationalName?.length &&
      formik?.values?.dPrescriptions[count]?.drugRouteOfAdministration?.length ? (
        <div className="flex flex-col sm:grid sm:grid-cols-12 gap-1">
          <CorText variant={TextVariant.LABEL_2_REGULAR} className="col-span-5">
            <span className="text-content-muted">
              <T keyName={'drugFields.chooseDosage'}>Alege doza</T>
            </span>
          </CorText>
          <div className="flex flex-wrap md:flex-nowrap gap-x-6">
            {getDozes(
              formik?.values?.dPrescriptions[count]?.drugRouteOfAdministration,
              formik?.values?.dPrescriptions[count]?.drugInternationalName,
            )?.length
              ? getDozes(
                  formik?.values?.dPrescriptions[count]?.drugRouteOfAdministration,
                  formik?.values?.dPrescriptions[count]?.drugInternationalName,
                )?.map((dose) => (
                  <CorRadioButton
                    key={dose}
                    isChecked={dose === formik?.values?.dPrescriptions[count]?.drugDose}
                    disabled={false}
                  >
                    <label className="whitespace-nowrap">{dose}</label>
                    <input
                      type="radio"
                      name={`dPrescriptions[${count}].drugDose`}
                      value={dose}
                      checked={dose === formik?.values?.dPrescriptions[count]?.drugDose}
                      onChange={() => handelDoseChange(dose)}
                      className="bg-transparent"
                    />
                  </CorRadioButton>
                ))
              : null}
          </div>
        </div>
      ) : null}
      {restriction.type === RestrictionsEnum.RESTRICTION ||
      restriction?.type === RestrictionsEnum.CHILDREN_RESTRICTION ||
      restriction?.type === RestrictionsEnum.ADULT_RESTRICTION ? (
        <Alert
          className="mt-1"
          label={getTitleAlert()}
          text={getWarningMessage()}
          status={getWarningType()}
        />
      ) : null}
      {restriction?.type !== RestrictionsEnum.RESTRICTION &&
      restriction?.type !== RestrictionsEnum.CHILDREN_RESTRICTION &&
      restriction?.type !== RestrictionsEnum.ADULT_RESTRICTION &&
      formik?.values?.dPrescriptions[count]?.drugInternationalName?.length &&
      formik?.values?.dPrescriptions[count]?.drugRouteOfAdministration?.length &&
      formik?.values?.dPrescriptions[count]?.drugDose?.length ? (
        <div className="flex flex-col sm:grid sm:grid-cols-12 gap-1">
          <CorText variant={TextVariant.LABEL_2_REGULAR} className="col-span-5">
            <span className="text-content-muted">
              <T keyName={'drugFields.insertQuantity'}>Insereaza cantitatea</T>
            </span>
          </CorText>
          <CorInput className="col-span-7" error={checkQuantity()}>
            <input
              type="number"
              name={`dPrescriptions.[${count}].quantity`}
              placeholder={t('drugFields.insertQuantityPlaceholder', 'Introduceti cantitatea')}
              value={formik.values?.dPrescriptions[count].quantity}
              onChange={(event) => handleInputChange(event, 'quantity')}
              ref={(el) => (inputRefs.current[2] = el)}
              onWheel={(e) => disableScrollOnInput(e)}
              onKeyDown={(e) => notIntegerSymbols.includes(e.key) && e.preventDefault()}
            />
            {checkQuantity() ? (
              <span className="bottom-helper">
                <T keyName={'drugFields.quantityErrorMessage'}>
                  Cantitatea nu poate fi mai mică de 1
                </T>
              </span>
            ) : null}
          </CorInput>
        </div>
      ) : null}
      {formik?.values?.dPrescriptions[count]?.drugInternationalName?.length &&
      formik?.values?.dPrescriptions[count]?.drugRouteOfAdministration?.length &&
      formik?.values?.dPrescriptions[count]?.drugDose?.length &&
      (formik?.values?.dPrescriptions[count]?.quantity || touched.includes('quantity')) ? (
        <div className="flex flex-col sm:grid sm:grid-cols-12 gap-1">
          <CorText variant={TextVariant.LABEL_2_REGULAR} className="col-span-5">
            <span className="text-content-muted">
              <T keyName={'drugFields.treatmentDuration'}>Durata tratamentului</T>
            </span>
          </CorText>
          <div className="col-span-7">
            <InputWithAmount
              type="number"
              placeholder={t(
                'drugFields.treatmentDurationPlaceholder',
                'Introduce durata tratamentului',
              )}
              name={`dPrescriptions.[${count}].validity`}
              value={formik?.values?.dPrescriptions[count].validity}
              isError={checkTreatmentDuration()}
              onChange={(event) => handleInputChange(event, 'validity')}
              error={treatmentDurationErrorMessage}
              currency={t('drugFields.inputCurrency', 'Zile')}
              onWheel={(e) => disableScrollOnInput(e)}
              onKeyDown={(e) => notIntegerSymbols.includes(e.key) && e.preventDefault()}
            />
          </div>
        </div>
      ) : null}
      {formik?.values?.dPrescriptions[count]?.drugInternationalName?.length &&
      formik?.values?.dPrescriptions[count]?.drugRouteOfAdministration?.length &&
      formik?.values?.dPrescriptions[count]?.drugDose?.length &&
      (formik?.values?.dPrescriptions[count]?.quantity || touched.includes('quantity')) &&
      (formik?.values?.dPrescriptions[count]?.validity || touched.includes('validity')) ? (
        <div className="flex flex-col sm:grid sm:grid-cols-12 gap-1 relative">
          <CorText variant={TextVariant.LABEL_2_REGULAR} className="col-span-5">
            <span className="text-content-muted">
              <T keyName={'drugFields.insertSignature'}>Insereaza signatura</T>
            </span>
          </CorText>
          <CorInput
            className="col-span-7"
            error={formik?.values?.dPrescriptions[count]?.signature?.length > 50}
          >
            <textarea
              name={`dPrescriptions.[${count}].signature`}
              value={formik.values?.dPrescriptions[count].signature}
              placeholder={t(
                'drugFields.insertSignaturePlaceholder',
                'Precizati modul de utilizare a medicamentului prescris',
              )}
              onChange={(event) => formik.handleChange(event)}
              ref={textareaRef}
              rows={4}
            ></textarea>
          </CorInput>
          <CorText variant={TextVariant.LABEL_3_MEDIUM} className="col-span-5">
            <div className="absolute bottom-2 right-2 text-content-muted">
              {formik?.values?.dPrescriptions[count].signature?.length}/50
            </div>
          </CorText>
        </div>
      ) : null}
    </div>
  );
};

export default memo(DrugFields);
