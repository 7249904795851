import { FC, SVGProps } from 'react';

const Trash: FC<SVGProps<SVGSVGElement>> = (props) => {
  const { color = 'currentColor' } = props;
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 5.33301V13.1663C4 13.9948 4.67157 14.6663 5.5 14.6663H10.5C11.3284 14.6663 12 13.9948 12 13.1663V5.33301H4Z"
        fill={color}
      />
      <g opacity="0.3">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.3335 3C9.3335 2.44772 8.88578 2 8.3335 2H7.66683C7.11455 2 6.66683 2.44772 6.66683 3H3.8335C3.55735 3 3.3335 3.22386 3.3335 3.5C3.3335 3.77614 3.55735 4 3.8335 4H12.1668C12.443 4 12.6668 3.77614 12.6668 3.5C12.6668 3.22386 12.443 3 12.1668 3H9.3335Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

export default Trash;
