import { CorChips, CorDropdown, CorInput, CorText, CorToggle } from '@e-reteta/react-design-system';
import { FC, memo, useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { T, useTranslate } from '@tolgee/react';
import hash from 'object-hash';

import { ChipSize, ChipVariant, TextVariant } from '../../../../enums/design-system.enum';
import PlusInput from '../../PlusInput';
import type { Intersection } from '../../../../types/Drug';
import { CorDropdownOption } from '../../../index';
import { CompensatedStatusTypesEnum } from '../../../../enums/CompensatedStatusTypesEnum';
import { ModalContentProps } from '../interface';
import { DiagnosticServices } from '../../../../services/diagnostics.services';
import { Disease } from '../../../../types/Disease';
import PriceInput from '../PriceInput';
import disableScrollOnInput from '../../../../utils/disableScrollOnInput';
import { DeviceDto } from '../../../../services/device.service';

const DeviceModal: FC<ModalContentProps> = ({ isEdit }) => {
  const { t } = useTranslate();
  const formik = useFormikContext<DeviceDto>();

  const [diagnosticChips, setDiagnosticChips] = useState<string[]>([]);
  const [intersections, setIntersections] = useState<string[]>([]);
  const [diagnostics, setDiagnostics] = useState<Disease[]>([]);

  const addNewDiagnostic = (value: string) => {
    if (!diagnosticChips.includes(value)) {
      if (value && value.trim().length !== 0) {
        const values = [...diagnosticChips, value];
        setDiagnosticChips(values);
        formik.setFieldValue('cimCodes', values);
      }
    }
  };

  const removeDiagnostic = (value: string) => {
    const values = diagnosticChips.filter((diagnostic) => diagnostic !== value);
    setDiagnosticChips(values);
    formik.setFieldValue('cimCodes', values);
  };

  const handleToggle = async (id: string, value: boolean) => {
    await formik.setFieldValue(id, value);
  };

  useEffect(() => {
    if (formik.values?.cimCodes?.length) {
      setDiagnosticChips(formik.values?.cimCodes);
    }
    if (formik.values.intersections?.length && !intersections.length) {
      const values = formik.values?.intersections.map(
        (intersection: Intersection) => intersection.destination?.atcCod as string,
      );
      setIntersections(values);
    }
  }, [formik.values, intersections.length]);

  const handleCompensatedStatus = (event: { detail: { value: string } }) => {
    formik.setFieldValue(`compensatedStatus`, event?.detail?.value);
  };

  const renderTranslationKeys = (value: CompensatedStatusTypesEnum) => {
    switch (value) {
      case CompensatedStatusTypesEnum.FULLY_COMPENSATED: {
        return t('deviceModal.FULLY_COMPENSATED', 'Fully compensated');
      }
      case CompensatedStatusTypesEnum.PARTIALLY_COMPENSATED: {
        return t('deviceModal.PARTIALLY_COMPENSATED', 'Partialy compensated');
      }
      case CompensatedStatusTypesEnum.NOT_COMPENSATED: {
        return t('deviceModal.NOT_COMPENSATED', 'Not compensated');
      }
      default: {
        return t('deviceModal.FULLY_COMPENSATED', 'Fully compensated');
      }
    }
  };

  const handleSearchDiagnostics = async (value: string) => {
    const diseases = await DiagnosticServices.getDiagnostics('admin', { code: value });
    if (value) {
      setDiagnostics(diseases.data.items);
    } else {
      setDiagnostics([]);
    }
  };

  return (
    <div className="px-8 mb-6 overflow-auto">
      <div className="flex flex-col gap-4">
        <div className="flex flex-col">
          <PlusInput
            className="mb-1.5"
            placeholder={t('deviceModal.diagnosticPlaceholder', 'Cod CIM-10-OMS')}
            name="diagnostic"
            label={t('deviceModal.diagnostic', 'Diagnostice')}
            addNewChip={addNewDiagnostic}
            searchDiagnostics={handleSearchDiagnostics}
            diagnostics={diagnostics}
            setSelectedDiagnostics={setDiagnostics}
            selectedDiagnostics={diagnosticChips}
            error={formik.errors?.cimCodes}
            isDiagnosticInput={true}
          />
          <div className="flex flex-wrap gap-1">
            {diagnosticChips?.map((item) => (
              <CorChips
                key={hash(item)}
                closeButton={true}
                size={ChipSize.MD}
                variant={ChipVariant.OUTLINE}
                onOnClose={() => removeDiagnostic(item)}
              >
                <div>{item}</div>
              </CorChips>
            ))}
          </div>
        </div>
        <div className="flex flex-col gap-1">
          <CorText variant={TextVariant.LABEL_2_REGULAR}>
            <div className="text-content-muted">
              <T keyName={'deviceModal.deviceName'}>Denumirea dispozitivului medical</T>
            </div>
          </CorText>
          <CorInput>
            <input
              autoComplete="off"
              type="text"
              placeholder={t(
                'deviceModal.deviceNamePlaceholder',
                'Introduceti denumirea dispozitivului medical',
              )}
              name="name"
              value={formik?.values?.name}
              onChange={formik.handleChange}
            />
          </CorInput>
        </div>
        <CorToggle
          label={t('deviceModal.isAllowedUninsured', 'Statut prescriere pentru neasigurați')}
          value={formik.values?.isAllowedUninsured}
          onClick={() => handleToggle('isAllowedUninsured', !formik.values?.isAllowedUninsured)}
        >
          <input
            autoComplete="off"
            type="checkbox"
            value={String(formik.values?.isAllowedUninsured)}
          />
        </CorToggle>
        <div className="grid grid-cols-2 gap-4">
          <div className="flex flex-col gap-1">
            <CorText variant={TextVariant.LABEL_2_REGULAR}>
              <div className="text-content-muted">
                <T keyName={'deviceModal.internationalCod'}>Cod DCI</T>
              </div>
            </CorText>
            <CorInput>
              <input
                autoComplete="off"
                type="number"
                min={0}
                placeholder={t('deviceModal.internationalCodPlaceholder', 'Introduceti cod DCI')}
                name="internationalCod"
                value={formik.values?.internationalCod}
                onChange={formik.handleChange}
                onWheel={(e) => disableScrollOnInput(e)}
              />
            </CorInput>
          </div>
          <div className="flex flex-col gap-1">
            <CorText variant={TextVariant.LABEL_2_REGULAR}>
              <div className="text-content-muted">
                <T keyName={'deviceModal.treatmentDuration'}>Durata tratamentului</T>
              </div>
            </CorText>
            <CorInput>
              <input
                autoComplete="off"
                type="number"
                min={1}
                name="treatmentDuration"
                placeholder={t(
                  'deviceModal.treatmentDurationPlaceholder',
                  'Introduceti durata tratamentului',
                )}
                value={formik.values?.treatmentDuration}
                onChange={formik.handleChange}
                onWheel={(e) => disableScrollOnInput(e)}
              />
            </CorInput>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div className="flex flex-col gap-1">
            <CorText variant={TextVariant.LABEL_2_REGULAR}>
              <div className="text-content-muted">
                <T keyName={'deviceModal.expiration'}>Valabilitatea rețetei(zile)</T>
              </div>
            </CorText>
            <CorInput>
              <input
                autoComplete="off"
                type="number"
                min={1}
                placeholder={t(
                  'deviceModal.expirationPlaceholder',
                  'Introduceti valabilitarea retetei',
                )}
                name="expiration"
                value={formik.values?.expiration}
                onChange={formik.handleChange}
                onWheel={(e) => disableScrollOnInput(e)}
              />
            </CorInput>
          </div>
          <div className="flex flex-col gap-1">
            <CorText variant={TextVariant.LABEL_2_REGULAR}>
              <div className="text-content-muted">
                <T keyName={'deviceModal.threeMonthPrescriptionNorm'}>
                  Norma de prescriere pentru 3 luni
                </T>
              </div>
            </CorText>
            <CorInput>
              <input
                autoComplete="off"
                type="number"
                placeholder={t(
                  'deviceModal.threeMonthPrescriptionNormPlaceholder',
                  'Introduceti norma de prescriere pentru 3 luni',
                )}
                name="threeMonthPrescriptionNorm"
                value={formik.values?.threeMonthPrescriptionNorm}
                onChange={formik.handleChange}
                onWheel={(e) => disableScrollOnInput(e)}
              />
            </CorInput>
          </div>
        </div>
        <div className="grid grid-cols-4 gap-4">
          <div className="flex flex-col gap-1">
            <CorText variant={TextVariant.LABEL_2_REGULAR}>
              <div className="text-content-muted">
                <T keyName={'deviceModal.commercialCod'}>Cod DC</T>
              </div>
            </CorText>
            <CorInput disabled={isEdit} error={!!formik.errors.commercialCod}>
              <input
                autoComplete="off"
                type="text"
                placeholder={t('deviceModal.commercialCodPlaceholder', 'Introduceti cod DC')}
                name="commercialCod"
                value={formik.values?.commercialCod}
                onChange={formik.handleChange}
              />
              {formik.errors.commercialCod ? (
                <span className="bottom-helper">{formik.errors.commercialCod}</span>
              ) : null}
            </CorInput>
          </div>
          <div className="flex flex-col gap-1 col-span-3">
            <CorText variant={TextVariant.LABEL_2_REGULAR}>
              <div className="text-content-muted">
                <T keyName={'deviceModal.commercialName'}>Denumirea comercială (Model)</T>
              </div>
            </CorText>
            <CorInput>
              <input
                autoComplete="off"
                type="text"
                placeholder={t(
                  'deviceModal.commercialNamePlaceholder',
                  'Introduceti denumirea comerciala',
                )}
                name="commercialName"
                value={formik.values?.commercialName}
                onChange={formik.handleChange}
              />
            </CorInput>
          </div>
        </div>

        <div className="flex flex-col gap-1">
          <CorText variant={TextVariant.LABEL_2_REGULAR}>
            <div className="text-content-muted">
              <T keyName={'deviceModal.measurementUnit'}>Unități de măsură</T>
            </div>
          </CorText>
          <CorInput error={!!formik.errors?.measurementUnit}>
            <input
              autoComplete="off"
              type="text"
              name="measurementUnit"
              placeholder={t(
                'deviceModal.measurementUnitPlaceholder',
                'Introduceti unitatea de masura',
              )}
              value={formik.values?.measurementUnit}
              onChange={formik.handleChange}
            />
            {formik.errors?.measurementUnit ? (
              <span className="bottom-helper">{formik.errors?.measurementUnit}</span>
            ) : null}
          </CorInput>
        </div>

        <div className="grid grid-cols-4 gap-4">
          <div className="flex flex-col gap-1">
            <CorText variant={TextVariant.LABEL_2_REGULAR}>
              <div className="text-content-muted">
                <T keyName={'deviceModal.dividing'}>Divizarea Producător</T>
              </div>
            </CorText>
            <CorInput error={!!formik.errors?.dividing}>
              <input
                autoComplete="off"
                type="text"
                placeholder={t(
                  'deviceModal.dividingPlaceholder',
                  'Introduceti divizare producator',
                )}
                name="dividing"
                value={formik.values.dividing}
                onChange={formik.handleChange}
              />
              {formik.errors?.dividing ? (
                <span className="bottom-helper">{formik.errors?.dividing}</span>
              ) : null}
            </CorInput>
          </div>
          <div className="col-span-3 flex flex-col gap-1">
            <CorText variant={TextVariant.LABEL_2_REGULAR}>
              <div className="text-content-muted">
                <T keyName={'deviceModal.producerName'}>Producător</T>
              </div>
            </CorText>
            <CorInput error={!!formik.errors?.producer?.name}>
              <input
                autoComplete="off"
                type="text"
                placeholder={t('deviceModal.producerNamePlaceholder', 'Introduceti producatorul')}
                name="producer.name"
                value={formik.values?.producer?.name}
                onChange={formik.handleChange}
              />
              {formik.errors?.producer?.name ? (
                <span className="bottom-helper">{formik.errors?.producer?.name}</span>
              ) : null}
            </CorInput>
          </div>
        </div>

        <div className="flex flex-col gap-1">
          <CorText variant={TextVariant.LABEL_2_REGULAR}>
            <div className="text-content-muted">
              <T keyName={'deviceModal.registryName'}>
                Nr. de înregistrare în Registrul de stat al dispozitivelor medicale
              </T>
            </div>
          </CorText>
          <CorInput>
            <input
              autoComplete="off"
              type="text"
              name="registryName"
              placeholder={t(
                'deviceModal.registryNamePlaceholder',
                'Introduceti numarul de inregistrare in registrul de stat al dispozitivului',
              )}
              value={formik.values.registryName}
              onChange={formik.handleChange}
            />
          </CorInput>
        </div>

        <div className="flex flex-col gap-1">
          <CorText variant={TextVariant.LABEL_2_REGULAR}>
            <div className="text-content-muted">
              <T keyName={'deviceModal.compensatedStatus'}>Statut de compensare</T>
            </div>
          </CorText>
          <CorDropdown
            placeholder={t(
              'deviceModal.compensatedStatusPlaceholder',
              'Selectati statut de prescriere',
            )}
            hasSelectedValue={!!formik?.values?.compensatedStatus}
            callback={(e) => handleCompensatedStatus(e)}
          >
            {Object.values(CompensatedStatusTypesEnum)?.length
              ? Object.values(CompensatedStatusTypesEnum).map((status) => {
                  return (
                    <CorDropdownOption
                      key={hash(status)}
                      value={status}
                      label={renderTranslationKeys(status)}
                      selected={status === formik?.values?.compensatedStatus}
                    />
                  );
                })
              : null}
          </CorDropdown>
        </div>

        <div className="flex flex-col gap-1">
          <CorText variant={TextVariant.LABEL_2_REGULAR}>
            <div className="text-content-muted">
              <T keyName={'deviceModal.compensatedSumWithVAT'}>
                Suma fixă compensată per unitate de măsură(MDL) inclusiv TVA
              </T>
            </div>
          </CorText>
          <PriceInput
            autoComplete="off"
            type="number"
            min={0}
            name="compensatedSumWithVAT"
            placeholder={t(
              'deviceModal.compensatedSumWithVATPlaceholder',
              'Introduceti suma fixa compensata pe unitate inclusiv TVA',
            )}
            errorMsg="Suma trebuie sa fie un numar"
            value={formik.values?.compensatedSumWithVAT}
            error={formik.errors?.compensatedSumWithVAT}
            step=".01"
            formik={formik}
            onWheel={(e) => disableScrollOnInput(e)}
          ></PriceInput>
        </div>

        <div className="grid grid-cols-2 gap-4">
          <div className="flex flex-col gap-1">
            <CorText variant={TextVariant.LABEL_2_REGULAR}>
              <div className="text-content-muted whitespace-nowrap">
                <T keyName={'deviceModal.compensatedSumWithoutVAT'}>
                  Suma fixă compensată per unitate de măsură(MDL) fără TVA
                </T>
              </div>
            </CorText>
            <PriceInput
              autoComplete="off"
              type="number"
              min={0}
              name="compensatedSumWithoutVAT"
              placeholder={t(
                'deviceModal.compensatedSumWithoutVATPlaceholder',
                'Introduceti suma fixa compensata per unitate fara TVA',
              )}
              errorMsg="Suma trebuie sa fie un numar"
              value={formik.values?.compensatedSumWithoutVAT}
              error={formik.errors?.compensatedSumWithoutVAT}
              step=".01"
              formik={formik}
              onWheel={(e) => disableScrollOnInput(e)}
            ></PriceInput>
          </div>
          <div className="flex flex-col gap-1">
            <CorText variant={TextVariant.LABEL_2_REGULAR}>
              <div className="text-content-muted">
                <T keyName={'deviceModal.maximRetailPriceWithVAT'}>
                  Preț maxim cu amănuntul în farmacii per unitate, MDL
                </T>
              </div>
            </CorText>
            <PriceInput
              autoComplete="off"
              type="number"
              min={0}
              name="maximRetailPriceWithVAT"
              placeholder={t(
                'deviceModal.maximRetailPriceWithVATPlaceholder',
                'Introduceti pretul maxim cu amanuntul',
              )}
              errorMsg="Prețul trebuie sa fie un numar"
              value={formik.values?.maximRetailPriceWithVAT}
              error={formik.errors?.maximRetailPriceWithVAT}
              step=".01"
              formik={formik}
              onWheel={(e) => disableScrollOnInput(e)}
            ></PriceInput>
          </div>
        </div>

        <div className="flex flex-col gap-1">
          <CorText variant={TextVariant.LABEL_2_REGULAR}>
            <div className="text-content-muted">
              <T keyName={'deviceModal.tvaAmount'}>TVA %</T>
            </div>
          </CorText>
          <CorInput>
            <input
              autoComplete="off"
              type="number"
              min={0}
              max={100}
              placeholder={t('deviceModal.tvaAmountPlaceholder', 'Introduceti TVA in %')}
              name="vat"
              value={formik.values?.vat}
              onChange={formik.handleChange}
              onWheel={(e) => disableScrollOnInput(e)}
            />
          </CorInput>
        </div>

        <div className="flex flex-col gap-1">
          <CorText variant={TextVariant.LABEL_2_REGULAR}>
            <div className="text-content-muted">
              <T keyName={'deviceModal.coPayment'}>
                Valoarea maximă a co-plății pacientului (inclusiv TVA), MDL
              </T>
            </div>
          </CorText>
          <PriceInput
            autoComplete="off"
            type="number"
            min={0}
            name="coPayment"
            placeholder={t(
              'deviceModal.coPaymentPlaceholder',
              'Introduceti valoarea maxima a coplatii',
            )}
            errorMsg="Co-plata trebuie sa fie un numar"
            value={formik.values?.coPayment}
            error={formik.errors?.coPayment}
            step=".01"
            formik={formik}
            onWheel={(e) => disableScrollOnInput(e)}
          ></PriceInput>
        </div>

        <div className="flex flex-col gap-1">
          <CorText variant={TextVariant.LABEL_2_REGULAR}>
            <div className="text-content-muted">
              <T keyName={'deviceModal.warningMessage'}>
                Mesaj de avertizare (la prescriere si eliberare)
              </T>
            </div>
          </CorText>
          <CorInput>
            <textarea
              autoComplete="off"
              placeholder={t('deviceModal.warningMessagePlaceholder', 'Tapeaza')}
              rows={4}
              name="warningMessage"
              value={formik.values.warningMessage}
              onChange={formik.handleChange}
            />
          </CorInput>
        </div>
        <CorToggle
          label={t('deviceModal.active', 'Active')}
          value={formik.values?.active}
          onClick={() => handleToggle('active', !formik.values?.active)}
        >
          <input autoComplete="off" type="checkbox" value={String(formik.values?.active)} />
        </CorToggle>
      </div>
    </div>
  );
};

export default memo(DeviceModal);
