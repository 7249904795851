import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';

import { PatientProfile } from '../../components';
import { AuthContext } from '../../context/context';

const Patient = () => {
  const navigate = useNavigate();
  const { role } = useContext(AuthContext);

  const onClosePatientProfile = () => {
    switch (role) {
      case 'pharmacy': {
        navigate('/pharmacy/search');
        break;
      }
      case 'doctor': {
        navigate('/doctor/search');
        break;
      }
    }
  };

  return (
    <div className="h-full sm:pl-6 overflow-y-scroll hide-scrollbar">
      <div className="overflow-auto h-full">
        <PatientProfile onClose={onClosePatientProfile} />
      </div>
    </div>
  );
};

export default Patient;
