import { IData } from '../pages/icds';

export const icdsMockData: IData[] = [
  {
    code: 'A00',
    name: 'Cholera',
    id: 'a5e9efc2-474c-4f85-bae3-9e608116f150',
  },
  {
    code: 'A01',
    name: 'Typhoid fever',
    id: '2b724854-5fbb-48b0-a802-82fd51919107',
  },
  {
    code: 'A01',
    name: 'Typhoid fever',
    id: 'f9d6d01e-9c90-49d2-9eb6-d094a340b083',
  },
];
