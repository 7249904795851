import axios from 'axios';
import { injectToken } from './inject-token';
import { onReject } from './on-reject';

const iaAPI = axios.create({
  baseURL: process.env.REACT_APP_AI_URL || process.env.REACT_APP_API_URL,
  headers: {
    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
  },
});

iaAPI.interceptors.request.use(injectToken, (error) => Promise.reject(error));

iaAPI.interceptors.response.use((response) => response, onReject(iaAPI));

export default iaAPI;
