import { CorBadge, CorBox, CorText } from '@e-reteta/react-design-system';
import { T } from '@tolgee/react';

import { BadgeColor, BadgeSize, TextVariant } from '../../enums/design-system.enum';
import { CardProps } from './card-props.interface';

const Card = (props: CardProps) => {
  const { isInsured, gender, firstName, lastName, birthday, id, selected, onClick, className } =
    props;

  const getGender = () => {
    if (gender.toLowerCase() === 'm') {
      return <T keyName="patientAssignmentCard.male">Masculin</T>;
    } else {
      return <T keyName="patientAssignmentCard.female">Feminin</T>;
    }
  };

  return (
    <CorBox
      className={`rounded-xl border ${
        selected ? 'shadow-elevation-1 border-border-success-default' : ''
      } ${className ? className : ''}`}
      onClick={onClick}
    >
      <div className="bg-background-secondary rounded-t-xl">
        <CorBadge
          className="py-3 pl-4"
          color={isInsured ? BadgeColor.GREEN : BadgeColor.GREY}
          size={BadgeSize.SM}
        >
          <span className="capitalize">
            {isInsured ? (
              <T keyName="patientAssignmentCard.insured">Asigurat</T>
            ) : (
              <T keyName="patientAssignmentCard.uninsured">Neasigurat</T>
            )}
          </span>
        </CorBadge>
      </div>
      <div className="flex flex-col mx-4 mt-3 mb-4 gap-1">
        <div className="flex justify-between">
          <CorText className="text-content-muted" variant={TextVariant.LABEL_2_REGULAR}>
            <div>
              <T keyName="patientAssignmentCard.lastName">Nume</T>
            </div>
          </CorText>
          <CorText className="text-content-default" variant={TextVariant.LABEL_1_MEDIUM}>
            <div>{firstName}</div>
          </CorText>
        </div>
        <div className="flex justify-between">
          <CorText className="text-content-muted" variant={TextVariant.LABEL_2_REGULAR}>
            <div>
              <T keyName="patientAssignmentCard.firstName">Prenume</T>
            </div>
          </CorText>
          <CorText className="text-content-default" variant={TextVariant.LABEL_1_MEDIUM}>
            <div>{lastName}</div>
          </CorText>
        </div>
        <div className="flex justify-between">
          <CorText className="text-content-muted" variant={TextVariant.LABEL_2_REGULAR}>
            <div>
              <T keyName="patientAssignmentCard.identifier">IDNP</T>
            </div>
          </CorText>
          <CorText className="text-content-default" variant={TextVariant.LABEL_1_MEDIUM}>
            <div>{id}</div>
          </CorText>
        </div>
        <div className="flex justify-between">
          <CorText className="text-content-muted" variant={TextVariant.LABEL_2_REGULAR}>
            <div>
              <T keyName="patientAssignmentCard.birthday">Data nasterii</T>
            </div>
          </CorText>
          <CorText className="text-content-default" variant={TextVariant.LABEL_1_MEDIUM}>
            <div>{birthday.toLocaleString()}</div>
          </CorText>
        </div>
        <div className="flex justify-between">
          <CorText className="text-content-muted" variant={TextVariant.LABEL_2_REGULAR}>
            <div>
              <T keyName="patientAssignmentCard.gender">Gen</T>
            </div>
          </CorText>
          <CorText className="text-content-default" variant={TextVariant.LABEL_1_MEDIUM}>
            <div>{getGender()}</div>
          </CorText>
        </div>
      </div>
    </CorBox>
  );
};

export default Card;
