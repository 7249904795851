import React, { memo, useRef } from 'react';
import classNames from 'classnames';
import { CorBox, CorText } from '@e-reteta/react-design-system';

import { TextVariant } from '../../enums/design-system.enum';
import { T, useTranslate } from '@tolgee/react';
import { Form, Formik, FormikProps } from 'formik';
import Accordion from '../../components/Reports/Accordion';
import { ReportType } from '../../components/Reports/report-type.enum';
import Annex8ForInstitutions from '../../components/Reports/Annex8ForInstitutions';

const DoctorReports = () => {
  const formikRef = useRef<FormikProps<{}> | null>(null);
  const { t } = useTranslate();

  const handleSave = async () => {
    formikRef.current?.resetForm();
  };

  return (
    <Formik
      onSubmit={handleSave}
      innerRef={formikRef}
      enableReinitialize={true}
      validateOnBlur={false}
      validateOnChange={false}
      validateOnMount={false}
      initialValues={{}}
    >
      <Form className="flex flex-col flex-1 overflow-auto" id="management-tool-form">
        <div className={classNames('flex-1 flex flex-col overflow-auto sm:ml-6')}>
          <div className="flex flex-col flex-1 overflow-auto" id="management-tool-form">
            <div className="flex justify-between items-center gap-4">
              <CorText variant={TextVariant.HEADING_2_BOLD}>
                <h2>
                  <T keyName={'reports.reportsText'}>Reports</T>
                </h2>
              </CorText>
            </div>

            <div className="mt-4 overflow-auto">
              <CorBox className="bg-white p-2 xs:p-6 h-[calc(100vh-88px)] overflow-auto">
                <Accordion
                  report="annex8ForInstitution"
                  name={t('managementToolReports.annex8ForInstitution', 'Raport descifrat')}
                  type={ReportType.ANNEX8_FOR_INSTITUTION}
                >
                  <Annex8ForInstitutions />
                </Accordion>
              </CorBox>
            </div>
          </div>
        </div>
      </Form>
    </Formik>
  );
};

export default memo(DoctorReports);
