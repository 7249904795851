/* eslint-disable react-hooks/exhaustive-deps */
import { CorBadge, CorButton, CorIcon, CorText } from '@e-reteta/react-design-system';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { T, useTranslate } from '@tolgee/react';
import { AxiosError } from 'axios';

import { IconName, IconSize } from '../../enums/design-system-icons.enum';
import { BadgeSize, ButtonVariant, TextVariant } from '../../enums/design-system.enum';
import { Alert, PatientMedicCard } from '../../components';
import { AlertStatus } from '../../components/Alert/enum';
import { useGetPatient } from '../../hooks/use-get-patient';
import { PrescriptionServices } from '../../services/prescription.services';
import { DiagnosticServices } from '../../services/diagnostics.services';
import { getPrescriptionStatusColor } from '../../lib/getPrescriptionStatusColor';
import { ReceiptStatus } from '../../enums/ReceiptStatus';
import {
  getPrescriptionStatusTranslation,
  getPrescriptionStatusTranslationKey,
} from '../../lib/getPrescriptionStatusTranslation';
import useWindowWidth from '../../hooks/use-window-width';
import ConfirmModal from '../../components/ConfirmModal';
import useQuery from '../../hooks/use-query';
import { PharmacyService } from '../../services/pharmacy.services';
import { Doctor } from '../../types/Doctor';
import { AuthContext } from '../../context/context';
import { renderResponsiveClasses } from '../../utils/render-responsive-classes.utils';
import PharmacistViewPrescription from '../../components/ContainerViewPrescription/Pharmacist';
import { PatientData } from '../../components/PatientMedicCard/interface';
import { LoaderContext } from '../../context/loader/context';
import { TargetUrlEnum } from '../../enums/target-url.enum';

const PharmacistSignDispense = () => {
  const { idnp, prescriptionId, dispenseId } = useParams();
  let query = useQuery();
  const navigate = useNavigate();
  const { isTablet, isMobile } = useWindowWidth();
  const { user, role } = useContext(AuthContext);
  const { setLoading } = useContext(LoaderContext);

  const [isSigned, setIsSigned] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [disease, setDisease] = useState<any>();
  const [prescription, setPrescription] = useState<any>();
  const [confirmSign, setConfirmSign] = useState<boolean>(false);
  const [errorMessage, setError] = useState<string>();
  const [dispenses, setDispenses] = useState<any[]>([]);

  const [patient] = useGetPatient({ idnp });
  const { t } = useTranslate();

  const onGoBack = () => {
    navigate(`/unsigned-dispenses`);
  };

  const getPrescription = useCallback(async () => {
    if (!prescriptionId) {
      return;
    }
    try {
      const queryStatuses = [
        ReceiptStatus.SIGNED,
        ReceiptStatus.RELEASED,
        ReceiptStatus.PARTIAL_RELEASED,
        ReceiptStatus.SIGNED_BY_PHARMACIST,
      ];
      if (query && query.get('sign-status') === 'Error') {
        queryStatuses.push(ReceiptStatus.PRESCRIBED);
      }
      const filter = {
        statuses: queryStatuses,
      };
      const { data } = await PrescriptionServices.getPrescriptionById(prescriptionId, role, filter);
      setPrescription({ data });
      const disease = await DiagnosticServices.getDiagnostics('pharmacy', {
        code: data.diagnosticCode,
      });
      setDisease(disease?.data?.items?.at(0));
    } catch (e) {
      onGoBack();
    }
  }, [prescriptionId, setDisease, query, navigate]);

  const getDispenses = useCallback(async () => {
    if (prescription && role && prescriptionId) {
      const { data }: any = await PharmacyService.getDispensesByPrescriptionId(
        role,
        prescriptionId,
      );
      if (data?.items?.length) {
        setDispenses(data?.items);
      }
    }
  }, [prescriptionId, role, prescription]);

  useEffect(() => {
    setLoading(true);
    if (!user) {
      return;
    }
    if (!patient) {
      return;
    }
    if (prescription?.data?.status && prescription.data.status !== 'signed') {
      getDispenses();
    }
    if (prescription && disease && patient) {
      setLoading(false);
    }
  }, [patient, getPrescription, disease, prescription, user]);

  const checkSignPrescription = () => {
    setConfirmSign(true);
  };

  const closeCheckSign = () => {
    setError(undefined);
    setConfirmSign(false);
  };

  const signDispense = async () => {
    setConfirmSign(false);
    setLoading(true);
    try {
      const dispensesToSign = dispenses
        .filter(
          (dispense) =>
            dispense.addedBy === user?.idnp &&
            dispense.status === 'not_signed' &&
            dispense.institutionCode === user?.activeCompany?.code &&
            dispense.id === dispenseId,
        )
        .map((dispense) => dispense.id);
      const { data } = await PharmacyService.signDispenses({
        items: dispensesToSign,
        targetUrl: TargetUrlEnum.PRESCRIPTION_URL,
      });
      if (data?.redirectUrl) {
        window.location.assign(data?.redirectUrl);
      }
    } catch (error) {
      if (error instanceof Error || error instanceof AxiosError) {
        setError(error && error?.message);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (query && query.get('sign-status') === 'Success') {
      setIsSigned(true);
    }
    if (query && query.get('sign-status') === 'Error') {
      setIsError(true);
    }
  }, [query]);

  useEffect(() => {
    localStorage.setItem('prescriptionReleases', JSON.stringify([]));
    if (user) {
      getPrescription();
    }
  }, []);

  return (
    <div className="h-full sm:pl-6 sm:p-4 lg:pl-6 lg:p-0">
      <div className="flex flex-row justify-between items-center mb-4">
        <div className="flex flex-row items-center gap-2">
          <CorIcon
            size={IconSize.MD}
            name={IconName.ARROW_LEFT}
            className="cursor-pointer"
            onClick={onGoBack}
          />
          <div className="flex flex-col sm:flex-row sm:items-center justify-start gap-x-2">
            <CorText
              variant={renderResponsiveClasses(
                isMobile,
                isTablet,
                TextVariant.LABEL_1_REGULAR,
                TextVariant.HEADING_3_BOLD,
                TextVariant.HEADING_2_BOLD,
              )}
            >
              <h2>
                <T keyName="pharmacistSignDispense.id">ID reteta</T>:
              </h2>
            </CorText>
            <CorText
              variant={renderResponsiveClasses(
                isMobile,
                isTablet,
                TextVariant.LABEL_1_BOLD,
                TextVariant.HEADING_3_BOLD,
                TextVariant.HEADING_2_BOLD,
              )}
            >
              <h2>{prescription?.data?.id}</h2>
            </CorText>
          </div>
          <CorBadge
            size={BadgeSize.MD}
            color={getPrescriptionStatusColor(prescription?.data.status as ReceiptStatus)}
          >
            <span className="capitalize">
              <T keyName={getPrescriptionStatusTranslationKey(prescription?.data.status)}>
                {getPrescriptionStatusTranslation(prescription?.data.status as ReceiptStatus)}
              </T>
            </span>
          </CorBadge>
        </div>
        <CorButton variant={ButtonVariant.PRIMARY}>
          <button onClick={checkSignPrescription}>
            <T keyName={'pharmacistSignDispense.signButton'}>Semneaza</T>
          </button>
        </CorButton>
      </div>
      {isSigned ? (
        <Alert
          label={t(
            'pharmacistSignDispensePharmacist.signSuccess',
            'Semnarea a fost efectuata cu succes',
          )}
          className="h-fit w-full mb-4"
          status={AlertStatus.SUCCESS}
        />
      ) : null}
      {isError ? (
        <Alert
          label={t('pharmacistSignDispensePharmacist.signFailed', 'Semnarea a esuat')}
          className="h-fit w-full mb-4"
          status={AlertStatus.DANGER}
        />
      ) : null}
      {errorMessage?.length && (
        <Alert text={errorMessage} className="h-fit w-full mb-4" status={AlertStatus.DANGER} />
      )}
      <PatientMedicCard
        patient={patient as PatientData}
        patientIdentifier={idnp}
        user={user}
        doctor={prescription?.data?.doctor as Doctor}
        status="prescribed"
        role={role}
      />
      <div>
        <PharmacistViewPrescription
          onRefresh={() => {}}
          items={prescription?.data?.dPrescriptions}
          thirdPersonIDNP={prescription?.data?.thirdPerson?.idnp}
          thirdPersonName={`${prescription?.data?.thirdPerson?.lastName} ${prescription?.data?.thirdPerson?.firstName}`}
          code={prescription?.data?.diagnosticCode}
          name={disease?.name}
          role={role}
          dispenses={dispenses}
          setIsDisabled={() => {}}
          prescribedAt={prescription?.data?.prescribedAt}
        />
      </div>
      {confirmSign && (
        <ConfirmModal
          title={t('pharmacistSignDispensePharmacist.warningCancelModalText', 'Atentie')}
          description={t(
            'pharmacistSignDispensePharmacist.confirmSignDescription',
            'Ești sigur că semnezi rețeta?',
          )}
          onClose={closeCheckSign}
          onAccept={signDispense}
        />
      )}
    </div>
  );
};

export default PharmacistSignDispense;
