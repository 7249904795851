import { CorInput, CorRadioButton, CorText } from '@e-reteta/react-design-system';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useFormikContext } from 'formik';
import { T, useTranslate } from '@tolgee/react';
import { isAxiosError } from 'axios';
import hash from 'object-hash';

import { InputIconDisplay, TextVariant } from '../../../enums/design-system.enum';
import { Annex10DCIFormikProps } from './annex10-dci.interface';
import { IconName } from '../../../enums/design-system-icons.enum';
import Check from '../../../icons/Check';
import { Drug } from '../../../types/Drug';
import { DeviceDto, DeviceService } from '../../../services/device.service';
import { handleError } from '../../../utils/handleRequestErrors';
import { DrugService } from '../../../services/drug.service';
import { ReportSearchType } from '../report-search-type.enum';

const Annex10DCI = () => {
  const formik = useFormikContext<Annex10DCIFormikProps>();
  const ref = useRef<HTMLDivElement>(null);
  const { t } = useTranslate();

  const [isVisible, setVisible] = useState<boolean>(false);
  const [drugs, setDrugs] = useState<Drug[]>([]);
  const [value, setValue] = useState<string>();
  const [devices, setDevices] = useState<DeviceDto[]>([]);

  const handleClickOutside = (event: any) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const getDrugs = async () => {
    const d = await DrugService.getAll('admin', {
      internationalNames: formik?.values?.annex10DCIInputValue
        ? [formik?.values?.annex10DCIInputValue]
        : [],
    });
    let uniqueDrugs: Drug[] = [];

    const internationalNames: string[] = [];
    if (d?.data?.items?.length) {
      uniqueDrugs = d?.data?.items?.filter((item) => {
        if (item.internationalName) {
          if (!internationalNames.includes(item.internationalName)) {
            internationalNames.push(item.internationalName);
            return item;
          }
        }
      });
    }
    setDrugs(uniqueDrugs || []);
  };

  const getDevices = async () => {
    const d = await DeviceService.getAll('admin', {
      names: formik?.values?.annex10DCIInputValue ? [formik?.values?.annex10DCIInputValue] : [],
    });
    let uniqueDevices: DeviceDto[] = [];

    const devicesNames: string[] = [];
    if (d?.data?.items?.length) {
      uniqueDevices = d?.data?.items?.filter((item) => {
        if (item.name) {
          if (!devicesNames.includes(item.name)) {
            devicesNames.push(item.name);
            return item;
          }
        }
      });
    }
    setDevices(uniqueDevices || []);
  };

  useEffect(() => {
    if (!(devices?.length || drugs?.length) && formik?.values?.annex10DCIInputValue !== '') {
      if (formik.values.annex10DCISearchBy === ReportSearchType.DCI) {
        setDevices([]);
        getDrugs();
      } else {
        setDrugs([]);
        getDevices();
      }
    }
    const delayInputTimeoutId = setTimeout(async () => {
      try {
        if (formik.values.annex10DCISearchBy === ReportSearchType.DCI) {
          setDevices([]);
          getDrugs();
        } else {
          setDrugs([]);
          getDevices();
        }
      } catch (error) {
        isAxiosError(error) && handleError(error);
      }
    }, 500);
    return () => clearTimeout(delayInputTimeoutId);
  }, [formik?.values?.annex10DCIInputValue, formik?.values?.annex10DCISearchBy]);

  const handleSearch = (value: string) => {
    formik.setFieldValue('annex10DCIInternationalCode', undefined);
    formik.setFieldValue('annex10DCIInputValue', value);
  };

  const handleSelect = (value: string, deviceInternationalCode?: string) => {
    formik.setFieldValue('annex10DCIInputValue', value);
    if (deviceInternationalCode) {
      formik.setFieldValue('annex10DCIInternationalCode', deviceInternationalCode);
    }
    setValue(value);
  };

  const renderPlaceHolder = () => {
    if (formik?.values?.annex10DCISearchBy === ReportSearchType.DCI) {
      return 'Introduceti denumirea interantionala a medicamentului';
    }
    if (formik?.values?.annex10DCISearchBy === ReportSearchType.DEVICE_NAME) {
      return 'Introduceti denumire dispozitiv';
    }
  };

  const renderTranslationKeys = () => {
    if (formik?.values?.annex10DCISearchBy === ReportSearchType.DCI) {
      return 'annex10DCI.internationalName';
    }
    if (formik?.values?.annex10DCISearchBy === ReportSearchType.DEVICE_NAME) {
      return 'annex10DCI.devicesName';
    }
  };

  const handleChangeRadioButtons = (event: ChangeEvent<HTMLInputElement>) => {
    formik.handleChange(event);
    formik.setFieldValue('annex10DCIInputValue', undefined);
  };

  const renderDrugOrDeviceDropdown = () => {
    if (formik?.values?.annex10DCISearchBy === undefined) {
      return null;
    }
    return (
      <>
        <div className="px-4 mt-4 mb-4">
          <div className="relative md:w-1/2" ref={ref}>
            <CorInput iconName={IconName.SEARCH} iconDisplay={InputIconDisplay.LEFT}>
              <input
                autoComplete="off"
                type="text"
                name="annex10DCIInputValue"
                placeholder={t(renderTranslationKeys()!, renderPlaceHolder())}
                value={formik?.values?.annex10DCIInputValue || ''}
                onClick={() => setVisible(true)}
                onChange={(event: any) => {
                  handleSearch(event?.target?.value);
                }}
              />
            </CorInput>
            {isVisible && drugs && drugs?.length ? (
              <div className="absolute w-full max-h-[296px] overflow-y-scroll z-50 mt-1 py-2 rounded-[8px] border border-solid border-border-default bg-decorative-neutral-1">
                {drugs.map((item: Drug) => {
                  return (
                    <div
                      className="relative flex gap-2 justify-between w-full px-4 py-2 cursor-pointer group hover:bg-surface-hover"
                      key={hash(item)}
                      onClick={() => {
                        handleSelect(item?.internationalName || '');
                        setVisible(false);
                      }}
                    >
                      <CorText variant={TextVariant.LABEL_2_REGULAR}>
                        <p className="text-content-muted group-hover:text-content-default">
                          {item.internationalName}
                        </p>
                      </CorText>
                      {value === item.internationalName ? <Check /> : null}
                    </div>
                  );
                })}
              </div>
            ) : null}
            {isVisible && devices && devices?.length ? (
              <div className="absolute w-full max-h-[296px] overflow-y-scroll z-50 mt-1 py-2 rounded-[8px] border border-solid border-border-default bg-decorative-neutral-1">
                {devices.map((item: DeviceDto) => (
                  <div
                    className="relative flex gap-2 justify-between w-full px-4 py-2 cursor-pointer group hover:bg-surface-hover"
                    key={hash(item)}
                    onClick={() => {
                      handleSelect(item?.name || '', item?.internationalCod);
                      setVisible(false);
                    }}
                  >
                    <CorText variant={TextVariant.LABEL_2_REGULAR}>
                      <p className="text-content-muted group-hover:text-content-default">
                        {item.name}
                      </p>
                    </CorText>
                    {value === item.name ? <Check /> : null}
                  </div>
                ))}
              </div>
            ) : null}
          </div>
        </div>
        <div className="w-full border-0 border-t border-solid border-border-disabled mx-4 my-6" />
      </>
    );
  };

  const renderFields = () => {
    return (
      <>
        <CorText variant={TextVariant.LABEL_1_MEDIUM} className="px-4">
          <div className="text-content-default">
            <T keyName={'annex.reportType'}>Tip Raport</T>
          </div>
        </CorText>
        <div className="flex flex-col px-4 pt-4">
          <div className="flex gap-8">
            <CorRadioButton
              isChecked={ReportSearchType.IS_DETAILED === formik?.values?.annex10DCIReportType}
              disabled={false}
            >
              <label className="whitespace-nowrap">
                <T keyName={'annex.detailedReport'}>{ReportSearchType.IS_DETAILED}</T>
              </label>
              <input
                type="radio"
                name="annex10DCIReportType"
                value={ReportSearchType.IS_DETAILED}
                checked={ReportSearchType.IS_DETAILED === formik?.values?.annex10DCIReportType}
                onChange={formik.handleChange}
                className="bg-background-secondary"
              />
            </CorRadioButton>
            <CorRadioButton
              isChecked={ReportSearchType.IS_GENERALIZED === formik?.values?.annex10DCIReportType}
              disabled={false}
            >
              <label className="whitespace-nowrap">
                <T keyName={'annex.generalizedReport'}>{ReportSearchType.IS_GENERALIZED}</T>
              </label>
              <input
                type="radio"
                name="annex10DCIReportType"
                value={ReportSearchType.IS_GENERALIZED}
                checked={ReportSearchType.IS_GENERALIZED === formik?.values?.annex10DCIReportType}
                onChange={formik.handleChange}
                className="bg-background-secondary"
              />
            </CorRadioButton>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="flex flex-col">
      <CorText variant={TextVariant.LABEL_1_MEDIUM} className="px-4">
        <div className="text-content-default">
          <T keyName={'annex10dci.searchBy'}>Cauta dupa</T>
        </div>
      </CorText>
      <div className="flex flex-col px-4 pt-4 mb-4">
        <div className="flex gap-8">
          <CorRadioButton
            isChecked={ReportSearchType.DCI === formik?.values?.annex10DCISearchBy}
            disabled={false}
          >
            <label className="whitespace-nowrap">
              <T keyName={'annex.codeDCIDrug'}>{ReportSearchType.DCI}</T>
            </label>
            <input
              type="radio"
              name="annex10DCISearchBy"
              value={ReportSearchType.DCI}
              checked={ReportSearchType.DCI === formik?.values?.annex10DCISearchBy}
              onChange={(event) => {
                handleChangeRadioButtons(event);
              }}
              className="bg-background-secondary"
            />
          </CorRadioButton>
          <CorRadioButton
            isChecked={ReportSearchType.DEVICE_NAME === formik?.values?.annex10DCISearchBy}
            disabled={false}
          >
            <label className="whitespace-nowrap">
              <T keyName={'annex.codeDCIDevice'}>{ReportSearchType.DEVICE_NAME}</T>
            </label>
            <input
              type="radio"
              name="annex10DCISearchBy"
              value={ReportSearchType.DEVICE_NAME}
              checked={ReportSearchType.DEVICE_NAME === formik?.values?.annex10DCISearchBy}
              onChange={(event) => {
                handleChangeRadioButtons(event);
              }}
              className="bg-background-secondary"
            />
          </CorRadioButton>
        </div>
      </div>
      {renderDrugOrDeviceDropdown()}
      {renderFields()}
      <div className="w-full border-0 border-t border-solid border-border-disabled mx-4 my-6" />
    </div>
  );
};

export default Annex10DCI;
