import React from 'react';
import Accordion from './Accordion';

const accordions = [
  {
    title: 'What is the difference between a virus and a bacterium?',
    description:
      'While Large Language Models (LLMs) like GPT-3 are impressive in their linguistic capabilities, they can fall short in areas requiring high accuracy and evidence-based responses, such as biomedicine. LLMs may generate responses lacking in concrete evidence or based on outdated information. Orpheus bridges this gap by integrating the advanced capabilities of LLMs with the factual accuracy of Knowledge Graphs. This synergy ensures that the information provided by Orpheus is not only current but also verifiable and reliable, making it a superior choice for biomedical applications.',
  },
  {
    title: 'How does the immune system work?',
    description:
      'While Large Language Models (LLMs) like GPT-3 are impressive in their linguistic capabilities, they can fall short in areas requiring high accuracy and evidence-based responses, such as biomedicine. LLMs may generate responses lacking in concrete evidence or based on outdated information. Orpheus bridges this gap by integrating the advanced capabilities of LLMs with the factual accuracy of Knowledge Graphs. This synergy ensures that the information provided by Orpheus is not only current but also verifiable and reliable, making it a superior choice for biomedical applications.',
  },
  {
    title: 'Why Opt for Orpheus Instead of Solely Relying on Large Language Models like GPT-3?',
    description:
      'While Large Language Models (LLMs) like GPT-3 are impressive in their linguistic capabilities, they can fall short in areas requiring high accuracy and evidence-based responses, such as biomedicine. LLMs may generate responses lacking in concrete evidence or based on outdated information. Orpheus bridges this gap by integrating the advanced capabilities of LLMs with the factual accuracy of Knowledge Graphs. This synergy ensures that the information provided by Orpheus is not only current but also verifiable and reliable, making it a superior choice for biomedical applications.',
  },
  {
    title: 'How do vaccines work and why are they important?',
    description:
      'While Large Language Models (LLMs) like GPT-3 are impressive in their linguistic capabilities, they can fall short in areas requiring high accuracy and evidence-based responses, such as biomedicine. LLMs may generate responses lacking in concrete evidence or based on outdated information. Orpheus bridges this gap by integrating the advanced capabilities of LLMs with the factual accuracy of Knowledge Graphs. This synergy ensures that the information provided by Orpheus is not only current but also verifiable and reliable, making it a superior choice for biomedical applications.',
  },
  {
    title: 'How does sleep impact overall well-being?',
    description:
      'While Large Language Models (LLMs) like GPT-3 are impressive in their linguistic capabilities, they can fall short in areas requiring high accuracy and evidence-based responses, such as biomedicine. LLMs may generate responses lacking in concrete evidence or based on outdated information. Orpheus bridges this gap by integrating the advanced capabilities of LLMs with the factual accuracy of Knowledge Graphs. This synergy ensures that the information provided by Orpheus is not only current but also verifiable and reliable, making it a superior choice for biomedical applications.',
  },
  {
    title: 'What are the different types of allergies, and how are they treated?',
    description:
      'While Large Language Models (LLMs) like GPT-3 are impressive in their linguistic capabilities, they can fall short in areas requiring high accuracy and evidence-based responses, such as biomedicine. LLMs may generate responses lacking in concrete evidence or based on outdated information. Orpheus bridges this gap by integrating the advanced capabilities of LLMs with the factual accuracy of Knowledge Graphs. This synergy ensures that the information provided by Orpheus is not only current but also verifiable and reliable, making it a superior choice for biomedical applications.',
  },
  {
    title: 'What is the impact of stress on overall health?',
    description:
      'While Large Language Models (LLMs) like GPT-3 are impressive in their linguistic capabilities, they can fall short in areas requiring high accuracy and evidence-based responses, such as biomedicine. LLMs may generate responses lacking in concrete evidence or based on outdated information. Orpheus bridges this gap by integrating the advanced capabilities of LLMs with the factual accuracy of Knowledge Graphs. This synergy ensures that the information provided by Orpheus is not only current but also verifiable and reliable, making it a superior choice for biomedical applications.',
  },
  {
    title: 'What is the difference between a virus and a bacterium?',
    description:
      'While Large Language Models (LLMs) like GPT-3 are impressive in their linguistic capabilities, they can fall short in areas requiring high accuracy and evidence-based responses, such as biomedicine. LLMs may generate responses lacking in concrete evidence or based on outdated information. Orpheus bridges this gap by integrating the advanced capabilities of LLMs with the factual accuracy of Knowledge Graphs. This synergy ensures that the information provided by Orpheus is not only current but also verifiable and reliable, making it a superior choice for biomedical applications.',
  },
  {
    title: 'How does the immune system work?',
    description:
      'While Large Language Models (LLMs) like GPT-3 are impressive in their linguistic capabilities, they can fall short in areas requiring high accuracy and evidence-based responses, such as biomedicine. LLMs may generate responses lacking in concrete evidence or based on outdated information. Orpheus bridges this gap by integrating the advanced capabilities of LLMs with the factual accuracy of Knowledge Graphs. This synergy ensures that the information provided by Orpheus is not only current but also verifiable and reliable, making it a superior choice for biomedical applications.',
  },
];

const HelpTab = () => {
  return (
    <div className="flex flex-col h-[calc(100vh-155px)] overflow-x-auto">
      {accordions.map((accordion) => (
        <Accordion title={accordion.title} description={accordion.description} />
      ))}
    </div>
  );
};

export default HelpTab;
