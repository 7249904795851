export enum ReportType {
  ANNEX1_FOR_DRUGS = 'annex1-for-drugs',
  ANNEX1_FOR_DRUGS_PER_IMS = 'annex1-for-drugs-per-ims',
  ANNEX1_FOR_DEVICES = 'annex1-for-devices',
  ANNEX1_FOR_DEVICES_PER_IMS = 'annex1-for-devices-per-ims',
  ANNEX2 = 'annex2',
  ANNEX3_FOR_DRUGS = 'annex3-for-drugs',
  ANNEX3_FOR_DEVICES = 'annex3-for-devices',
  ANNEX4 = 'annex4',
  ANNEX4_PER_IMS = 'annex4-per-ims',
  ANNEX5 = 'annex5',
  ANNEX6 = 'annex6',
  ANNEX6_PER_PSF = 'annex6-per-psf',
  ANNEX7 = 'annex7',
  ANNEX7_PER_IMS = 'annex7-per-ims',
  ANNEX9 = 'annex9',
  ANNEX10_INTERNATIONAL_CODE = 'annex10-international-code',
  ANNEX10_COMMERCIAL_CODE = 'annex10-commercial-code',
  ANNEX11 = 'annex11',
  ANNEX8_FOR_CNAM = 'annex8-for-cnam',
  ANNEX8_FOR_INSTITUTION = 'annex8-for-institution',
  ANNEX8_FOR_PHARMACY = 'annex8-for-pharmacy',
  ANNEX11_PER_PHARMACY = 'annex11-per-pharmacy',
  REPORT126 = 'report-126',
  ANNEX12 = 'annex12',
  ANNEX13 = 'annex13',
  REPORT126_PER_PSF = 'report126-per-psf',
  ANNEX8_FOR_PHARMACY_HEAD = 'annex8-per-pharmacy-head',
  ANNEX8_FOR_PHARMACY_PSF = 'annex8-for-pharmacy-psf',
  REPORT126_PER_PSF_BRANCH = 'report126-psf-branch',
  ANNEX6_PER_PSF_BRANCH = 'annex6-psf-branch',
  ANNEX8_PHARMACY_BRANCH = 'annex8-pharmacy-branch',
}
