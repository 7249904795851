import { FC } from 'react';
import { CorBox, CorButton, CorIcon, CorText } from '@e-reteta/react-design-system';
import { twMerge } from 'tailwind-merge';
import classNames from 'classnames';

import { AlertProps } from './interface';
import { IconColor, IconSize } from '../../enums/design-system-icons.enum';
import { ButtonVariant, TextVariant } from '../../enums/design-system.enum';
import { AlertStatus } from './enum';

const Alert: FC<AlertProps> = ({
  text,
  className,
  label,
  status = AlertStatus.WARNING,
  withButton = false,
  firstButton,
  secondButton,
  firstButtonClick,
  secondButtonClick,
}) => {
  let bgColor;
  let iconColor;

  switch (status) {
    case AlertStatus.DANGER:
      bgColor = 'bg-surface-critical-default';
      iconColor = IconColor.CONTENT_CRITICAL;
      break;
    case AlertStatus.WARNING:
      bgColor = 'bg-surface-warning-default';
      iconColor = IconColor.CONTENT_WARNING;
      break;
    case AlertStatus.SUCCESS:
      bgColor = 'bg-surface-success-hover';
      iconColor = IconColor.CONTENT_SUCCESS;
      break;
  }

  return (
    <CorBox
      boxStyle="none"
      flexDirection="col"
      className={twMerge(`${bgColor} flex flex-col p-4 h-fit`, className)}
    >
      <div className={`flex gap-4 ${withButton ? 'flex-col xs:flex-row ' : ''}`}>
        <CorIcon name="warning-1-circle" size={IconSize.LG} color={iconColor} />
        <CorBox
          boxStyle="none"
          className={classNames('items-start justify-start', { 'gap-1': text && label })}
        >
          {label ? (
            <CorText variant={TextVariant.HEADING_4_BOLD}>
              <span>{label}</span>
            </CorText>
          ) : null}
          {text ? (
            <CorText variant={TextVariant.LABEL_1_REGULAR}>
              <span>{text}</span>
            </CorText>
          ) : null}
        </CorBox>
      </div>
      {withButton ? (
        <CorBox
          className={classNames('flex flex-col xs:flex-row justify-end gap-2 border-0', {
            'pt-0 basis-0': withButton === 'inline',
            'pt-4': withButton !== 'inline',
          })}
        >
          <>
            {firstButton && firstButtonClick ? (
              <CorButton variant={ButtonVariant.SECONDARY} onClick={firstButtonClick}>
                <button
                  type="button"
                  className="bg-transparent h-10 px-6 py-2.5 w-full whitespace-nowrap"
                >
                  {firstButton}
                </button>
              </CorButton>
            ) : null}
          </>
          <>
            {secondButton && secondButton ? (
              <CorButton variant={ButtonVariant.PRIMARY} onClick={secondButtonClick}>
                <button type="button" className="px-6 w-full whitespace-nowrap">
                  {secondButton}
                </button>
              </CorButton>
            ) : null}
          </>
        </CorBox>
      ) : null}
    </CorBox>
  );
};

export default Alert;
