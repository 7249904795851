import { StylesConfig } from 'react-select';

export const colourStyles: StylesConfig<any> = {
  control: (styles) => ({
    ...styles,
    backgroundColor: '#D5D6DC4D',
    ':focus': { borderColor: '#E6E6EA' },
    ':hover': { borderColor: '#E6E6EA' },
    ':focus-visible': { borderColor: '#E6E6EA' },
    ':focus-within': { borderColor: '#E6E6EA' },
    boxShadow: 'none',
    borderColor: '#E6E6EA',
    padding: '10px',
    maxHeight: '40px',
    borderRadius: '20px',
    width: '100%',
    '&.dropdown__control': {
      '&--menu-is-open': {
        '.dropdown__indicators': {
          '.dropdown__indicator': {
            transform: 'rotate(180deg)',
          },
        },
      },
    },
  }),
  placeholder: (styles) => ({
    ...styles,
    fontFamily: 'Satoshi',
    color: '#808080',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  valueContainer: (styles) => ({
    ...styles,
    maxHeight: '20px',
    padding: 0,
  }),
  container: (styles) => ({
    ...styles,
    width: '100%',
  }),
  menu: (styles) => ({
    ...styles,
    border: 'none',
    backgroundColor: '#ffffff',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    padding: '8px',
    marginTop: '15px',
    marginBottom: '15px',
  }),
  menuList: (styles) => ({
    ...styles,
    position: 'relative',
    zIndex: 2000,
    width: '100%',
    padding: 0,
  }),
  indicatorsContainer: (styles) => ({
    ...styles,
    padding: 0,
    '.dropdown': {
      '&__dropdown-indicator': {
        padding: 0,
        color: '#000000',
        height: '20px',
        width: '16px',
      },
    },
  }),
  input: (styles) => ({
    ...styles,
    padding: 0,
    margin: 0,
  }),
};
