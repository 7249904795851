import { SVGProps } from 'react';

const Cross = (props: SVGProps<any>) => {
  return (
    <svg viewBox="0 0 50 48" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M16.8538 0.341064V7.45049V15.757H7.10127H0V22.8768H7.10127H16.8538H23.9655V15.757V7.45049V0.341064H16.8538Z" />
      <path d="M42.8999 15.757H33.1473V7.45049V0.341064H26.0356V7.45049V15.757V22.8768H33.1473H42.8999H50.0011V15.757H42.8999Z" />
      <path d="M7.10127 24.9453H0V32.0651H7.10127H16.8538V40.3716V47.481H23.9655V40.3716V32.0651V24.9453H16.8538H7.10127Z" />
      <path d="M33.1473 24.9453H26.0356V32.0651V40.3716V47.481H33.1473V40.3716V32.0651H42.8999H50.0011V24.9453H42.8999H33.1473Z" />
    </svg>
  );
};

export default Cross;
