const Hospital = () => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_8021_16957)">
        <path
          d="M46.5009 47.0409H1.50094C1.20244 47.0409 0.960938 46.7994 0.960938 46.5009V12.0009C0.960938 11.7024 1.20244 11.4609 1.50094 11.4609H33.9609V1.50094C33.9609 1.20244 34.2024 0.960938 34.5009 0.960938H46.5009C46.7994 0.960938 47.0409 1.20244 47.0409 1.50094V46.5009C47.0409 46.7994 46.7994 47.0409 46.5009 47.0409ZM24.5409 45.9609H45.9609V2.04094H35.0409V12.0009C35.0409 12.2994 34.7994 12.5409 34.5009 12.5409H2.04094V45.9609H15.2109V36.0009C15.2109 35.7024 15.4524 35.4609 15.7509 35.4609H24.0009C24.2994 35.4609 24.5409 35.7024 24.5409 36.0009V45.9609ZM16.2909 45.9609H23.4609V36.5409H16.2909V45.9609ZM41.0409 37.5009H39.9609V34.5009H41.0424L41.0409 37.5009ZM32.7909 37.5009H31.7109V34.5009H32.7924L32.7909 37.5009ZM8.04094 37.5009H6.96094V34.5009H8.04094V37.5009ZM41.0409 31.5009H39.9609V28.5009H41.0424L41.0409 31.5009ZM32.7909 31.5009H31.7109V28.5009H32.7924L32.7909 31.5009ZM24.5409 31.5009H23.4609V28.5009H24.5409V31.5009ZM16.2909 31.5009H15.2109V28.5009H16.2909V31.5009ZM8.04094 31.5009H6.96094V28.5009H8.04094V31.5009ZM41.0409 25.5009H39.9609V22.5009H41.0424L41.0409 25.5009ZM32.7909 25.5009H31.7109V22.5009H32.7924L32.7909 25.5009ZM24.5409 25.5009H23.4609V22.5009H24.5409V25.5009ZM16.2909 25.5009H15.2109V22.5009H16.2909V25.5009ZM8.04094 25.5009H6.96094V22.5009H8.04094V25.5009ZM41.0409 19.5009H39.9609V16.5009H41.0424L41.0409 19.5009ZM32.7909 19.5009H31.7109V16.5009H32.7924L32.7909 19.5009ZM24.5409 19.5009H23.4609V16.5009H24.5409V19.5009ZM16.2909 19.5009H15.2109V16.5009H16.2909V19.5009ZM8.04094 19.5009H6.96094V16.5009H8.04094V19.5009ZM41.0409 9.75094H39.9609V7.29094H37.5009V6.21094H39.9609V3.75094H41.0424V6.21094H43.5009V7.29094H41.0409V9.75094Z"
          fill="#E89D00"
        />
      </g>
      <defs>
        <clipPath id="clip0_8021_16957">
          <rect width="48" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Hospital;
