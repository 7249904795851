import { CorChips, CorText } from '@e-reteta/react-design-system';
import { useRef } from 'react';
import hash from 'object-hash';

import { ChipSize, ChipVariant, TextVariant } from '../../../../../enums/design-system.enum';
import RenderChip from '../../RenderChip';
import { ChipCellProps } from './interface';
import { CHIP_HEIGHT, CHIP_WIDTH } from '../../../../../constants/chip';

const ChipCell = (props: ChipCellProps) => {
  const { items, setModalOpen, modalType, widthType, width, isObject, keyName, rowIndex } = props;

  const ref = useRef<HTMLDivElement>(null);
  const renderName = (item: { code: string; name: string }) => {
    if (isObject) {
      if (keyName === 'prescribedDisease' || keyName === 'rePrescribedDisease') {
        return item.code;
      }
      return item.name;
    }
    return item;
  };

  const calculateDisplayNumber = () => {
    const rowWidth = ref?.current?.clientWidth || 0;
    const maxChipsToShow = Math.round(rowWidth / CHIP_HEIGHT) + Math.floor(rowWidth / CHIP_WIDTH);

    return Math.min(items?.length || 0, maxChipsToShow);
  };

  const displayNumber = calculateDisplayNumber();

  return (
    <div
      ref={ref}
      className="flex-wrap shrink-0 flex flex-row items-start justify-start gap-1"
      style={{ width: `${width}${widthType}` }}
    >
      {!items?.length ? (
        <CorText variant={TextVariant.LABEL_2_MEDIUM}>
          <div className="text-content-default">-</div>
        </CorText>
      ) : (
        <>
          {items?.slice(0, displayNumber).map((item) => (
            <RenderChip
              key={hash(item)}
              name={renderName(item)}
              index={rowIndex}
              keyName={keyName}
            />
          ))}
          {items?.length > displayNumber && (
            <CorChips
              size={ChipSize.SM}
              variant={ChipVariant.DEFAULT}
              className="cursor-pointer"
              onClick={() => setModalOpen(modalType)}
            >
              <CorText variant={TextVariant.BODY_1_MEDIUM}>
                <span>+{items.length - displayNumber}</span>
              </CorText>
            </CorChips>
          )}
        </>
      )}
    </div>
  );
};

export default ChipCell;
