import { CorBadge, CorText } from '@e-reteta/react-design-system';
import { FC, memo, MouseEventHandler, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import hash from 'object-hash';
import { useTranslate } from '@tolgee/react';

import { IconName } from '../../../../enums/design-system-icons.enum';
import { BadgeSize, TextVariant } from '../../../../enums/design-system.enum';
import { ReceiptStatus } from '../../../../enums/ReceiptStatus';
import { getPrescriptionStatusColor } from '../../../../lib/getPrescriptionStatusColor';
import {
  getPrescriptionStatusTranslation,
  getPrescriptionStatusTranslationKey,
} from '../../../../lib/getPrescriptionStatusTranslation';
import { LinkButton } from '../../../index';
import { TableRowProps } from './interface';
import PrescriptionPrint from '../../../Prints/PrescriptionPrint';
import { formatDate } from '../../../../utils/formatDate';

const TableRow: FC<TableRowProps> = ({
  prescription,
  patientId,
  user,
  role,
  allowedCodesForCloning,
}) => {
  const { t } = useTranslate();
  const navigate = useNavigate();

  const handleButtonClick = useCallback(
    (to: string): MouseEventHandler => {
      return (event) => {
        event.stopPropagation();
        navigate(to);
      };
    },
    [navigate],
  );

  const getDrugDevice = () => {
    const drugAndDevices: string[] = [];
    prescription.dPrescriptions?.map(({ drugInternationalName, deviceName }) => {
      if (drugInternationalName) {
        drugAndDevices.push(drugInternationalName);
      }
      if (deviceName) {
        drugAndDevices.push(deviceName);
      }
    });

    return (
      <>
        {drugAndDevices.length > 1 ? (
          <div className="flex items-center gap-2">
            <CorText
              className="whitespace-nowrap text-ellipsis overflow-hidden"
              variant={TextVariant.LABEL_2_REGULAR}
            >
              <p>{drugAndDevices[0]}</p>
            </CorText>
            <div
              className="flex justify-center items-center h-5 rounded-md text-white bg-action-neutral-default hover:bg-action-neutral-hover px-1.5 py-0.5"
              data-tooltip-id={`tooltip-${prescription.id}`}
            >
              <CorText variant={TextVariant.BODY_1_REGULAR}>
                <p>+{drugAndDevices.length - 1}</p>
              </CorText>
            </div>
            <Tooltip
              id={`tooltip-${prescription.id}`}
              style={{
                backgroundColor: 'white',
                borderRadius: '6px',
                outline: '1px solid #e6e6ea',
                color: 'rgb(0 0 0)',
              }}
              opacity="1"
            >
              <div className="bg-white flex flex-col gap-1">
                {drugAndDevices.map((item, index) => {
                  if (index != 0) {
                    return (
                      <CorText key={hash(item)} variant={TextVariant.BODY_1_REGULAR}>
                        <p>{item}</p>
                      </CorText>
                    );
                  }
                })}
              </div>
            </Tooltip>
          </div>
        ) : (
          drugAndDevices
        )}
      </>
    );
  };

  return (
    <div
      key={prescription.id}
      className="flex w-full items-center hover:bg-surface-hover bg-background-primary"
    >
      <div
        onClick={() => navigate(`/patient/${patientId}/prescription/${prescription.id}`)}
        className="flex w-full gap-4 pl-6 pr-4 py-3 cursor-pointer"
      >
        {role === 'doctor' ? (
          <>
            <section className="w-[80px] min-w-[80px] flex-1 overflow-hidden text-ellipsis">
              <CorText
                variant={TextVariant.LABEL_2_REGULAR}
                className="whitespace-nowrap text-ellipsis overflow-hidden"
              >
                <span className="text-content-default">
                  {prescription.diagnosticCode}
                  {prescription.diagnosticName ? ` - ${prescription.diagnosticName}` : null}
                </span>
              </CorText>
            </section>
            <section className="w-[80px] min-w-[80px]">
              <CorText
                variant={TextVariant.LABEL_2_REGULAR}
                className="whitespace-nowrap text-ellipsis overflow-hidden"
              >
                <span className="text-content-default">{formatDate(prescription.createdAt)}</span>
              </CorText>
            </section>
            <section className="w-[152px] min-w-[152px]">
              <CorBadge color={getPrescriptionStatusColor(prescription.status)} size={BadgeSize.SM}>
                <span className="capitalize">
                  {t(
                    getPrescriptionStatusTranslationKey(prescription.status),
                    getPrescriptionStatusTranslation(prescription.status),
                  )}
                </span>
              </CorBadge>
            </section>
            <section className="w-[168px] min-w-[168px]">
              <CorText
                variant={TextVariant.LABEL_2_REGULAR}
                className="whitespace-nowrap text-ellipsis overflow-hidden"
              >
                <span className="text-content-default">{`${prescription?.doctor?.firstName} ${prescription?.doctor?.lastName}`}</span>
              </CorText>
            </section>
          </>
        ) : null}
        {role === 'pharmacy' ? (
          <>
            <section className="w-[96px] min-w-[96px] flex-1 overflow-hidden text-ellipsis">
              <CorText
                variant={TextVariant.LABEL_2_REGULAR}
                className="whitespace-nowrap text-ellipsis overflow-hidden"
              >
                <span className="text-content-default">
                  {prescription.diagnosticCode}
                  {prescription.diagnosticName ? ` - ${prescription.diagnosticName}` : null}
                </span>
              </CorText>
            </section>
            <section className="w-[96px] min-w-[96px]">
              <CorText
                variant={TextVariant.LABEL_2_REGULAR}
                className="whitespace-nowrap text-ellipsis overflow-hidden"
              >
                <span className="text-content-default">{formatDate(prescription.createdAt)}</span>
              </CorText>
            </section>
            <section className="w-[168px] min-w-[168px]">
              <CorBadge color={getPrescriptionStatusColor(prescription.status)} size={BadgeSize.SM}>
                <span className="capitalize">
                  {t(
                    getPrescriptionStatusTranslationKey(prescription.status),
                    getPrescriptionStatusTranslation(prescription.status),
                  )}
                </span>
              </CorBadge>
            </section>
            <section className="w-[247px] min-w-[247px]">
              <CorText variant={TextVariant.LABEL_2_REGULAR}>
                <span className="text-content-default">{getDrugDevice()}</span>
              </CorText>
            </section>
          </>
        ) : null}
      </div>

      {role === 'doctor' ? (
        <section className="flex flex-row items-center justify-end w-[136px] min-w-[136px] 2lg:w-[206px] 2lg:min-w-[206px] gap-2 mr-6">
          {[ReceiptStatus.PRESCRIBED, ReceiptStatus.SIGNED].includes(prescription.status) &&
          prescription.doctorIDNP === user?.idnp &&
          user.activeCompany?.speciality === prescription?.doctor?.speciality ? (
            <LinkButton
              onClick={handleButtonClick(
                `/patient/${patientId}/prescription/${prescription.id}/edit?doctor=true`,
              )}
              text={t('prescriptionsTable.modify', 'Modifica')}
              iconName={IconName.EDIT}
            />
          ) : null}
          {allowedCodesForCloning?.includes(prescription.diagnosticCode) ? (
            <LinkButton
              onClick={handleButtonClick(
                `/patient/${patientId}/prescription/${prescription.id}/clone?doctor=true`,
              )}
              text={t('prescriptionsTable.clone', 'Cloneaza')}
              iconName={IconName.DUPLICATE}
            />
          ) : null}
          {prescription?.status !== ReceiptStatus.PRESCRIBED ? (
            <PrescriptionPrint prescription={prescription} buttonForPrint={'table'} />
          ) : null}
        </section>
      ) : null}
    </div>
  );
};

export default memo(TableRow);
