import { SVGProps } from 'react';

const Link = (props: SVGProps<any>) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M10.0001 4.66663H12.0001C12.4378 4.66663 12.8713 4.75285 13.2757 4.92036C13.6801 5.08788 14.0476 5.33341 14.3571 5.64294C14.6666 5.95247 14.9122 6.31993 15.0797 6.72435C15.2472 7.12877 15.3334 7.56222 15.3334 7.99996C15.3334 8.4377 15.2472 8.87115 15.0797 9.27557C14.9122 9.67999 14.6666 10.0475 14.3571 10.357C14.0476 10.6665 13.6801 10.912 13.2757 11.0796C12.8713 11.2471 12.4378 11.3333 12.0001 11.3333H10.0001M6.00008 11.3333H4.00008C3.56234 11.3333 3.12889 11.2471 2.72447 11.0796C2.32005 10.912 1.95259 10.6665 1.64306 10.357C1.01794 9.73186 0.666748 8.88401 0.666748 7.99996C0.666748 7.1159 1.01794 6.26806 1.64306 5.64294C2.26818 5.01782 3.11603 4.66663 4.00008 4.66663H6.00008"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M5.33325 8H10.6666" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default Link;
