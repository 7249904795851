import { FC, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { AuthContext } from '../../context/context';

const NotFound: FC = () => {
  const { mainRoute } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    navigate(mainRoute as string, { replace: true });
  }, [mainRoute, navigate]);

  return null;
};

export default NotFound;
