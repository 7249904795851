import Api from '../axios/api';
import { Intersection } from '../types/Drug';
import { ProductAvailabilityQuery } from './drug.service';

export interface DeviceDto {
  id: string;
  cimCodes: string[];
  intersections?: Intersection[];
  isAllowedUninsured?: boolean;
  internationalCod?: string;
  name?: string;
  deviceGroup: string;
  treatmentDuration?: number;
  prescriptionsRights: string[];
  expiration?: number;
  commercialCod: string;
  commercialName?: string;
  measurementUnit?: string;
  dividing?: string;
  registryName?: string;
  producer: { name: string };
  compensatedSumWithVAT?: number;
  compensatedSumWithoutVAT?: number;
  maximRetailPriceWithVAT?: number;
  vat?: number;
  coPayment?: number;
  comments?: string;
  country: { name: string };
  threeMonthPrescriptionNorm: number;
  compensatedStatus: string;
  warningMessage: string;
  createdAt: string;
  updatedAt: string;
  active?: boolean;
}

export interface GetDevicesResponse {
  data: {
    items?: DeviceDto[];
    count: number;
  };
}

export interface MessageResponse {
  message: string;
}

export interface DeviceResponse {
  data: DeviceDto;
}

export interface DeviceSearchQuery {
  limit?: number;
  skip?: number;
  orderBy?: any;
  names?: string[];
  internationalCodes?: number[];
  commercialCodes?: string[];
  commercialName?: string;
  search?: string;
}

export interface DeviceAvailabilityResponse {
  data: {
    referencePeriod: number;
    isAvailable?: boolean;
  };
}

export class DeviceService {
  static getAll(target: string, params: DeviceSearchQuery): Promise<GetDevicesResponse> {
    return Api.get(`/v1/${target}/devices`, {
      params,
    });
  }

  static delete(id: string): Promise<MessageResponse> {
    return Api.delete(`/v1/admin/devices/${id}`);
  }

  static bulkUpload(data: FormData): Promise<MessageResponse> {
    return Api.post(`/v1/admin/devices/upload`, data);
  }

  static create(body: DeviceDto): Promise<DeviceResponse> {
    return Api.post(`/v1/admin/devices`, body);
  }

  static update(id: string, body: DeviceDto): Promise<DeviceResponse> {
    return Api.patch(`/v1/admin/devices/${id}`, body);
  }

  static download(type: string) {
    return Api.get(`/v1/admin/devices/download.${type}`, {
      responseType: 'blob',
    });
  }

  static getDevices(target: string, idno: string, search: string): Promise<GetDevicesResponse> {
    return Api.get(`/v1/${target}/${idno}/devices`, { params: { search } });
  }

  static checkAvailable(params: ProductAvailabilityQuery): Promise<DeviceAvailabilityResponse> {
    return Api.get(`/v1/doctor/devices/check-availability`, {
      params,
    });
  }
}
