import { CorButton, CorIcon, CorText } from '@e-reteta/react-design-system';
import { FC, memo, PropsWithChildren } from 'react';
import { useFormikContext } from 'formik';
import { T } from '@tolgee/react';

import { ButtonVariant, TextVariant } from '../../../enums/design-system.enum';
import { IconColor, IconName } from '../../../enums/design-system-icons.enum';
import { ModalProps } from './interface';

const ManagementToolModal: FC<PropsWithChildren<ModalProps>> = ({
  children,
  title,
  onCancel,
  item,
}) => {
  const formik = useFormikContext();
  const removeModal = () => {
    onCancel?.();
  };

  return (
    <div className="fixed inset-0 h-screen w-full bg-modal-background z-50">
      <div className="bg-primary mx-auto max-w-[800px] my-12 rounded-3xl flex flex-col max-h-[calc(100vh-96px)]">
        <div className="flex justify-between px-8 mt-7 mb-8">
          <CorText variant={TextVariant.HEADING_2_BOLD}>
            <h2>{title}</h2>
          </CorText>
          <div className={`flex gap-2 ${formik?.isSubmitting ? 'pointer-events-none' : ''}`}>
            <CorButton variant={ButtonVariant.SECONDARY}>
              <button type="button" onClick={removeModal}>
                <T keyName={'managementToolModal.cancelButtton'}>Anuleaza</T>
                <CorIcon name={IconName.CLOSE} color={IconColor.CONTENT_SUCCESS}></CorIcon>
              </button>
            </CorButton>
            <CorButton variant={ButtonVariant.PRIMARY}>
              {item ? (
                <button type="submit">
                  <T keyName={'managementToolModal.save'}>Salveaza</T>{' '}
                  <CorIcon name={IconName.EDIT} color={IconColor.WHITE}></CorIcon>
                </button>
              ) : (
                <button type="submit">
                  <T keyName={'managementToolModal.add'}>Adauga</T>{' '}
                  <CorIcon name={IconName.CHECK} color={IconColor.WHITE}></CorIcon>
                </button>
              )}
            </CorButton>
          </div>
        </div>
        {children}
      </div>
    </div>
  );
};

export default memo(ManagementToolModal);
