import { forwardRef, useImperativeHandle, useRef } from 'react';
import dayjs from 'dayjs';
import Barcode from 'react-jsbarcode';
import hash from 'object-hash';

import { renderSpeciality } from '../../../utils/render-speciality.utils';
import { ComponentToPrintProps } from './prescription-print.interface';

const ComponentToPrint = forwardRef((props: ComponentToPrintProps, ref) => {
  const { prescription } = props;
  const componentRef = useRef(null);

  useImperativeHandle(ref, () => {
    return componentRef.current;
  });

  const renderAge = (birthday: Date) => {
    const now = dayjs();
    const parsedBd = dayjs(birthday);
    const diff = dayjs(now.diff(parsedBd));
    const years = Math.abs(1970 - diff.year());

    return `${years}`;
  };

  const generatePages = () => {
    const resp: JSX.Element[] = [];
    for (let i = 0; i <= Math.round((prescription?.dPrescriptions?.length! - 6) / 11); i++) {
      resp.push(
        <>
          <div className="pagebreak" />
          <div className="flex justify-between mb-6 mt-5 w-[calc(100%-40px)]">
            <div className="flex gap-1">
              <div className="text-content-muted prescription-label-2-regular">Nr. rețetei:</div>
              <div className="text-content-default prescription-label-2-medium">
                {prescription?.hPrescriptionId || prescription?.id}
              </div>
            </div>
            <div className="flex gap-1">
              <div className="text-content-muted prescription-label-2-regular">
                Data prescrierii:
              </div>
              <div className="text-content-default prescription-label-2-medium">
                {new Date(prescription?.createdAt!).toLocaleDateString()}
              </div>
            </div>
          </div>
          <div className="border border-solid border-border-selected text-content-default divide-x-0 divide-y divide-solid divide-border-selected mb-6">
            <div className="prescription-body-1-bold flex justify-center items-center divide-x divide-y-0 divide-solid divide-border-selected h-10">
              <div className="p-1 text-center h-full flex items-center w-[3%]">Nr</div>
              <div className="p-1 text-center h-full flex items-center w-[17%]">
                DCI medicament/ dispozitiv medical
              </div>
              <div className="p-1 text-center h-full flex items-center w-[12%]">
                Calea de administrare
              </div>
              <div className="p-1 text-center h-full flex items-center w-[7%]">Doza</div>
              <div className="p-1 text-center h-full flex items-center w-[9%]">Cantitatea UM</div>
              <div className="p-1 text-center h-full flex items-center w-[12%]">
                Mod de administrare
              </div>
              <div className="p-1 text-center h-full flex items-center w-[9%]">
                Durata tratament
              </div>
              <div className="p-1 text-center h-full flex items-center w-[11%]">
                Valabilitatea prescripției
              </div>
            </div>
            {prescription?.dPrescriptions
              ?.slice(6 + i * 11, 17 + i * 11)
              ?.map((item, index: number) => {
                return (
                  <div
                    key={hash(item)}
                    className="prescription-body-1-regular flex justify-center items-center divide-x divide-y-0 divide-solid divide-border-selected h-10"
                  >
                    <div className="p-1 text-center h-full flex justify-center items-center w-[3%]">
                      {index + 1 + 6 + i * 11}
                    </div>
                    <div className="p-1 text-center h-full flex justify-center items-center w-[30%]">
                      {item.deviceName || item.drugInternationalName}
                    </div>
                    <div className="p-1 text-center h-full flex justify-center items-center w-[12%]">
                      {item.drugRouteOfAdministration}
                    </div>
                    <div className="p-1 text-center h-full flex justify-center items-center w-[7%]">
                      {item.drugDose}
                    </div>
                    <div className="p-1 text-center h-full flex justify-center items-center w-[9%]">
                      {item.quantity}
                    </div>
                    <div className="p-1 text-center h-full flex justify-center items-center w-[19%]">
                      {item.signature}
                    </div>
                    <div className="p-1 text-center h-full flex justify-center items-center w-[9%]">
                      {item.validity}
                    </div>
                    <div className="p-1 text-center h-full flex justify-center items-center w-[11%]">
                      {item.expiration}
                    </div>
                  </div>
                );
              })}
          </div>
        </>,
      );
    }
    return resp;
  };

  return (
    <div ref={componentRef} className="px-5">
      <style type="text/css" media="print">
        {'@page { size: A4 landscape; }'}
      </style>
      <div className="flex justify-between mb-6 mt-5 w-[calc(100%-40px)]">
        <div className="flex gap-1">
          <div className="text-content-muted prescription-label-2-regular">Nr. rețetei:</div>
          <div className="text-content-default prescription-label-2-medium">
            {prescription?.hPrescriptionId || prescription?.id}
          </div>
        </div>
        <div className="flex gap-1">
          <div className="text-content-muted prescription-label-2-regular">Data prescrierii:</div>
          <div className="text-content-default prescription-label-2-medium">
            {new Date(prescription?.createdAt!).toLocaleDateString()}
          </div>
        </div>
      </div>
      <div className="bg-border-hover h-[1px] w-full mb-2 mt-11" />
      <div className="flex justify-between mb-6">
        <div>
          <div className="prescription-label-1-bold text-content-default mb-1">Prescris:</div>
          <div className="prescription-label-2-regular text-content-muted">
            Denumire Instituție Medicală
          </div>
          <div className="prescription-label-2-medium text-content-default mb-1">
            {prescription?.doctor?.medicalUnit}
          </div>
        </div>
        <Barcode
          className="h-[60px] w-[106px]"
          value={prescription?.id}
          options={{ format: 'code128' }}
        />
      </div>
      <div className="bg-border-hover h-[1px] w-full mb-2" />
      <div className="mb-6">
        <div className="prescription-label-1-bold text-content-default mb-1">
          Date despre pacient:
        </div>
        <div className="prescription-label-2-regular text-content-muted flex gap-8 mb-0.5">
          <div className="w-[124px]">Nume, Prenume</div>
          <div>Vârstă</div>
        </div>
        <div className="prescription-label-2-medium text-content-default flex gap-8">
          <div className="w-[124px]">
            {`${prescription?.patient?.firstName} ${prescription?.patient?.lastName}`}
          </div>
          <div>{renderAge(prescription?.patient?.birthday)}</div>
        </div>
      </div>
      <div className="border border-solid border-border-selected text-content-default divide-x-0 divide-y divide-solid divide-border-selected mb-6">
        <div className="prescription-body-1-bold flex justify-center items-center divide-x divide-y-0 divide-solid divide-border-selected h-10">
          <div className="p-1 text-center h-full flex items-center w-[3%]">Nr</div>
          <div className="p-1 text-center h-full flex justify-center items-center w-[30%]">
            DCI medicament/ dispozitiv medical
          </div>
          <div className="p-1 text-center h-full flex items-center w-[12%]">
            Calea de administrare
          </div>
          <div className="p-1 text-center h-full flex items-center w-[7%]">Doza</div>
          <div className="p-1 text-center h-full flex items-center w-[9%]">Cantitatea UM</div>
          <div className="p-1 text-center h-full flex justify-center items-center w-[19%]">
            Mod de administrare
          </div>
          <div className="p-1 text-center h-full flex items-center w-[9%]">Durata tratament</div>
          <div className="p-1 text-center h-full flex items-center w-[11%]">
            Valabilitatea prescripției
          </div>
        </div>
        {prescription?.dPrescriptions?.slice(0, 6)?.map((item, index: number) => {
          return (
            <div
              key={hash(item)}
              className="prescription-body-1-regular flex justify-center items-center divide-x divide-y-0 divide-solid divide-border-selected h-10"
            >
              <div className="p-1 text-center h-full flex justify-center items-center w-[3%]">
                {index + 1}
              </div>
              <div className="p-1 text-center h-full flex justify-center items-center w-[30%]">
                {item.deviceName || item.drugInternationalName}
              </div>
              <div className="p-1 text-center h-full flex justify-center items-center w-[12%]">
                {item.drugRouteOfAdministration}
              </div>
              <div className="p-1 text-center h-full flex justify-center items-center w-[7%]">
                {item.drugDose}
              </div>
              <div className="p-1 text-center h-full flex justify-center items-center w-[9%]">
                {item.quantity}
              </div>
              <div className="p-1 text-center h-full flex justify-center items-center w-[19%]">
                {item.signature}
              </div>
              <div className="p-1 text-center h-full flex justify-center items-center w-[9%]">
                {item.validity}
              </div>
              <div className="p-1 text-center h-full flex justify-center items-center w-[11%]">
                {item.expiration}
              </div>
            </div>
          );
        })}
      </div>
      {prescription?.dPrescriptions?.length! > 6 ? generatePages() : null}

      <div className="flex flex-col gap-0.5 mb-2">
        <div className="text-content-muted prescription-label-2-regular">
          Nume, Prenume {renderSpeciality(prescription?.doctor?.speciality!)}
        </div>
        <div className="text-content-default prescription-label-2-medium">
          {`${prescription?.doctor?.lastName} ${prescription?.doctor?.firstName}`}
        </div>
      </div>
      <div className="bg-border-hover h-[1px] w-full mb-6" />
      <div className="max-w-[700px] w-full prescription-label-2-medium text-content-default">
        Forma de eliberare Prezentul document medical contine date cu caracter personal prelucrate
        in conformitate cu Legea 133/2011 privind protectia datelor cu caracter personal. În caz de
        pierdere documentul poate fi restituit la adresa institutiei medicale indicate pe retetă.
      </div>
    </div>
  );
});

export default ComponentToPrint;
