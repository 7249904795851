export enum NavigationEnum {
  DRUGS = 'drugs',
  DEVICES = 'devices',
  DIAGNOSTIC = 'diagnostics',
  SPECIALITIES = 'specialities',
  CLINICS = 'clinics',
  PHARMACIES = 'pharmacies',
  SYSTEM_CONFIGURATION = 'systemConfiguration',
  REPORTS = 'reports',
  INTEGRATIONS = 'integrations',
}
