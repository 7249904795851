import { FC, SVGProps } from 'react';

const Gender: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M4.02537 8.00397C5.32221 6.69183 7.08771 5.92716 8.94814 5.87183C8.51537 6.99647 8.31841 8.19549 8.36907 9.39684C8.41973 10.5982 8.71698 11.7771 9.24295 12.8627C9.76893 13.9483 10.5128 14.9182 11.4298 15.7141C12.3468 16.51 13.4181 17.1156 14.5792 17.4943C13.908 18.261 13.0762 18.8762 12.1407 19.2977C11.2052 19.7191 10.188 19.937 9.15887 19.9363C5.21161 19.9363 2 16.7817 2 12.902C2.00215 11.0727 2.72853 9.31611 4.02537 8.00397Z"
        fill="#343841"
      />
      <path
        d="M4.98942 24.0369H8.20905V21.7574C8.83836 21.8533 9.47894 21.8533 10.1082 21.7574V24.0369H13.3285C13.5804 24.0369 13.822 24.1352 14.0001 24.3103C14.1782 24.4854 14.2783 24.7228 14.2783 24.9704C14.2783 25.2179 14.1782 25.4554 14.0001 25.6304C13.822 25.8055 13.5804 25.9038 13.3285 25.9038H10.1082V29.0668C10.1082 29.3143 10.0082 29.5516 9.83011 29.7267C9.65203 29.9017 9.4105 30 9.15865 30C8.9068 30 8.66526 29.9017 8.48718 29.7267C8.30909 29.5516 8.20905 29.3143 8.20905 29.0668V25.9038H4.98942C4.73751 25.9038 4.49592 25.8055 4.31779 25.6304C4.13967 25.4554 4.0396 25.2179 4.0396 24.9704C4.0396 24.7228 4.13967 24.4854 4.31779 24.3103C4.49592 24.1352 4.73751 24.0369 4.98942 24.0369Z"
        fill="#343841"
      />
      <g opacity="0.3">
        <path
          d="M18.2181 12.9016C18.2181 8.96345 15.6048 5.61726 11.9934 4.44623C12.0487 4.38303 12.2102 4.20462 12.3585 4.05866C13.5051 2.92996 14.6356 2.40589 16.0241 2.13484C17.4126 1.86379 18.8518 2.00333 20.1596 2.53582C21.4675 3.06831 22.5854 3.96986 23.372 5.12653C24.1587 6.28321 24.5789 7.64311 24.5794 9.03438C24.5796 10.8657 23.8534 12.6249 22.5549 13.9384C21.2565 15.2518 19.4881 16.0161 17.6255 16.0687C18.0185 15.0575 18.2193 13.984 18.2181 12.9016Z"
          fill="#343841"
        />
        <path
          d="M22.6148 20.4653C22.6147 20.3428 22.6392 20.2214 22.6869 20.1082C22.7345 19.9949 22.8044 19.892 22.8926 19.8053C22.9808 19.7186 23.0855 19.6498 23.2007 19.6029C23.316 19.556 23.4395 19.5319 23.5642 19.5319H26.7576L23.1194 15.9562C23.6155 15.5662 24.0659 15.1231 24.4623 14.6352L28.1009 18.2107V15.0727C28.0999 14.9496 28.1238 14.8275 28.171 14.7134C28.2183 14.5993 28.2881 14.4956 28.3764 14.4082C28.4647 14.3208 28.5697 14.2514 28.6854 14.204C28.8011 14.1567 28.9252 14.1323 29.0505 14.1323C29.1758 14.1323 29.2999 14.1567 29.4156 14.204C29.5313 14.2514 29.6363 14.3208 29.7246 14.4082C29.8128 14.4956 29.8826 14.5993 29.9299 14.7134C29.9772 14.8275 30.0011 14.9496 30.0001 15.0727V20.465C30.0003 20.5876 29.9758 20.709 29.9282 20.8223C29.8805 20.9355 29.8106 21.0385 29.7224 21.1252C29.6342 21.2119 29.5295 21.2807 29.4143 21.3275C29.299 21.3744 29.1755 21.3985 29.0507 21.3984H23.564C23.4393 21.3985 23.3159 21.3743 23.2007 21.3274C23.0855 21.2806 22.9808 21.2118 22.8926 21.1251C22.8045 21.0385 22.7346 20.9356 22.6869 20.8223C22.6393 20.7091 22.6148 20.5878 22.6148 20.4653Z"
          fill="#343841"
        />
        <path
          d="M10.9194 6.07959C14.0186 6.85468 16.319 9.61757 16.319 12.9014C16.32 13.9219 16.094 14.9303 15.6568 15.856C14.6382 15.6001 13.6894 15.1273 12.8775 14.471C12.0656 13.8146 11.4104 12.9909 10.9584 12.0581C10.5064 11.1253 10.2686 10.1063 10.2619 9.07313C10.2551 8.03998 10.4796 7.01801 10.9194 6.07959Z"
          fill="#343841"
        />
      </g>
    </svg>
  );
};

export default Gender;
