import { useFormikContext } from 'formik';
import { CorChips, CorRadioButton, CorText } from '@e-reteta/react-design-system';
import React, { ChangeEvent, useState } from 'react';
import { T, useTranslate } from '@tolgee/react';
import hash from 'object-hash';

import { Annex2FormikProps } from './annex2.interface';
import { ChipSize, ChipVariant, TextVariant } from '../../../enums/design-system.enum';
import { Drug } from '../../../types/Drug';
import { DrugService } from '../../../services/drug.service';
import PlusInputReport from '../PlusInputReport';
import { DeviceDto, DeviceService } from '../../../services/device.service';
import { ReportSearchType } from '../report-search-type.enum';

const Annex2 = () => {
  const formik = useFormikContext<Annex2FormikProps>();
  const { t } = useTranslate();

  const [dci, setDci] = useState<string[]>([]);
  const [deviceNames, setDeviceNames] = useState<string[]>([]);
  const [drugs, setDrugs] = useState<Drug[]>([]);
  const [devices, setDevices] = useState<DeviceDto[]>([]);

  const addNewDCI = (value: string) => {
    if (value && value.trim().length !== 0) {
      const values = [...dci, value];
      setDci(values);
      formik.values.drugInternationalNames = values;
    }
  };

  const addNewDeviceName = (value: string) => {
    if (value && value.trim().length !== 0) {
      const values = [...deviceNames, value];
      setDeviceNames(values);
      formik.values.deviceInternationalNames = values;
    }
  };

  const removeDCI = (value: string) => {
    const values = dci.filter((drug) => drug !== value);
    setDci(values);
    formik.values.drugInternationalNames = values;
  };

  const removeDeviceName = (value: string) => {
    const values = deviceNames.filter((device) => device !== value);
    setDeviceNames(values);
    formik.values.deviceInternationalNames = values;
  };

  const handleSearchDCI = async (value: string) => {
    const drugsCount = await DrugService.getAll('admin', { limit: 0, internationalNames: [value] });
    const drugs = await DrugService.getAll('admin', {
      internationalNames: [value],
      limit: drugsCount.data.count,
    });
    const outputDrugs = drugs?.data?.items?.filter(
      (obj, index, array) =>
        array.findIndex((o) => o.internationalName === obj.internationalName) === index,
    );
    if (value && outputDrugs?.length) {
      setDrugs(outputDrugs);
    } else {
      setDrugs([]);
    }
  };

  const handleSearchDeviceName = async (value: string) => {
    const devicesCount = await DeviceService.getAll('admin', { limit: 0, names: [value] });
    const drugs = await DeviceService.getAll('admin', {
      names: [value],
      limit: devicesCount.data.count,
    });
    const outputDevices = drugs?.data?.items?.filter(
      (obj, index, array) => array.findIndex((o) => o.name === obj.name) === index,
    );
    if (value && outputDevices?.length) {
      setDevices(outputDevices);
    } else {
      setDevices([]);
    }
  };

  const renderPlusInput = () => {
    if (
      formik?.values?.annex2MedicalProduct === undefined ||
      formik?.values?.annex2MedicalProduct === ReportSearchType.ALL_DEVICES ||
      formik?.values?.annex2MedicalProduct === ReportSearchType.ALL_DRUGS
    ) {
      return null;
    }
    if (formik?.values?.annex2MedicalProduct === ReportSearchType.DRUGS) {
      return (
        <div className="flex flex-col px-4">
          <PlusInputReport
            className="mb-1.5"
            placeholder={t('annex.searchDCIPlaceholder', 'Introduceti dci cautat')}
            name="drug-dci"
            label={t('annex.searchDCI', 'Cauta DCI')}
            addNewChip={addNewDCI}
            searchItem={handleSearchDCI}
            items={drugs}
            setItems={setDrugs}
            selectedItem={formik?.values?.drugInternationalNames}
            displayKey="internationalName"
          />
          <div className="flex flex-wrap gap-1">
            {formik?.values?.drugInternationalNames?.map((item) => (
              <CorChips
                key={hash(item)}
                closeButton={true}
                size={ChipSize.MD}
                variant={ChipVariant.OUTLINE}
                onOnClose={() => removeDCI(item)}
              >
                <div>{item}</div>
              </CorChips>
            ))}
          </div>
        </div>
      );
    } else
      return (
        <div className="flex flex-col px-4">
          <PlusInputReport
            className="mb-1.5"
            placeholder={t(
              'annex.deviceNamesPlaceholder',
              'Introduceti denumrea dispozitiv cautat',
            )}
            name="device-name"
            label={t('annex.deviceNames', 'Cauta Denumirea dispozitivului medical')}
            addNewChip={addNewDeviceName}
            searchItem={handleSearchDeviceName}
            items={devices}
            setItems={setDevices}
            selectedItem={formik?.values?.deviceInternationalNames}
            displayKey="name"
          />
          <div className="flex flex-wrap gap-1">
            {formik?.values?.deviceInternationalNames?.map((item) => (
              <CorChips
                key={hash(item)}
                closeButton={true}
                size={ChipSize.MD}
                variant={ChipVariant.OUTLINE}
                onOnClose={() => removeDeviceName(item)}
              >
                <div>{item}</div>
              </CorChips>
            ))}
          </div>
        </div>
      );
  };

  const handleChangeRadioButtons = (event: ChangeEvent<HTMLInputElement>) => {
    formik.handleChange(event);
    if (event.target.value === ReportSearchType.ALL_DRUGS) {
      formik.values.allDrugs = true;
      formik.values.allDevices = false;
      formik.values.drugInternationalNames = [];
      formik.values.deviceInternationalNames = [];
      setDci([]);
      setDeviceNames([]);
    }
    if (event.target.value === ReportSearchType.ALL_DEVICES) {
      formik.values.allDrugs = false;
      formik.values.allDevices = true;
      formik.values.drugInternationalNames = [];
      formik.values.deviceInternationalNames = [];
      setDci([]);
      setDeviceNames([]);
    }
    if (event.target.value === ReportSearchType.DEVICES) {
      formik.values.drugInternationalNames = [];
      formik.values.allDrugs = false;
      formik.values.allDevices = false;
      setDeviceNames([]);
    }
    if (event.target.value === ReportSearchType.DRUGS) {
      formik.values.deviceInternationalNames = [];
      formik.values.allDrugs = false;
      formik.values.allDevices = false;
      setDci([]);
    }
  };

  return (
    <div className="flex flex-col">
      <CorText variant={TextVariant.LABEL_1_MEDIUM} className="px-4">
        <div className="text-content-default">
          <T keyName={'anex2.medicalProduct'}>Produsul medical</T>
        </div>
      </CorText>
      <div className="flex flex-col px-4 py-4">
        <div className="flex gap-8 flex-wrap">
          <CorRadioButton
            isChecked={ReportSearchType.ALL === formik?.values?.annex2MedicalProduct}
            disabled={false}
          >
            <label className="whitespace-nowrap">
              <T keyName={'annex2.allDrigs'}>{ReportSearchType.ALL_DRUGS}</T>
            </label>
            <input
              type="radio"
              name="annex2MedicalProduct"
              value={ReportSearchType.ALL_DRUGS}
              checked={ReportSearchType.ALL_DRUGS === formik?.values?.annex2MedicalProduct}
              onChange={(event) => handleChangeRadioButtons(event)}
              className="bg-background-secondary"
            />
          </CorRadioButton>
          <CorRadioButton
            isChecked={ReportSearchType.ALL === formik?.values?.annex2MedicalProduct}
            disabled={false}
          >
            <label className="whitespace-nowrap">
              <T keyName={'annex2.allDrigs'}>{ReportSearchType.ALL_DEVICES}</T>
            </label>
            <input
              type="radio"
              name="annex2MedicalProduct"
              value={ReportSearchType.ALL_DEVICES}
              checked={ReportSearchType.ALL_DEVICES === formik?.values?.annex2MedicalProduct}
              onChange={(event) => handleChangeRadioButtons(event)}
              className="bg-background-secondary"
            />
          </CorRadioButton>
          <CorRadioButton
            isChecked={ReportSearchType.DRUGS === formik?.values?.annex2MedicalProduct}
            disabled={false}
          >
            <label className="whitespace-nowrap">
              <T keyName={'annex2.drugs'}>{ReportSearchType.DRUGS}</T>
            </label>
            <input
              type="radio"
              name="annex2MedicalProduct"
              value={ReportSearchType.DRUGS}
              checked={ReportSearchType.DRUGS === formik?.values?.annex2MedicalProduct}
              onChange={(event) => handleChangeRadioButtons(event)}
              className="bg-background-secondary"
            />
          </CorRadioButton>
          <CorRadioButton
            isChecked={ReportSearchType.DEVICES === formik?.values?.annex2MedicalProduct}
            disabled={false}
          >
            <label className="whitespace-nowrap">
              <T keyName={'annex2.devices'}>{ReportSearchType.DEVICES}</T>
            </label>
            <input
              type="radio"
              name="annex2MedicalProduct"
              value={ReportSearchType.DEVICES}
              checked={ReportSearchType.DEVICES === formik?.values?.annex2MedicalProduct}
              onChange={(event) => handleChangeRadioButtons(event)}
              className="bg-background-secondary"
            />
          </CorRadioButton>
        </div>
      </div>
      {renderPlusInput()}
      <div className="w-full border-0 border-t border-solid border-border-disabled mx-4 my-6" />
    </div>
  );
};

export default Annex2;
