import { FC, memo } from 'react';
import { CorBox } from '@e-reteta/react-design-system';
import { useParams } from 'react-router-dom';

import { PrescriptionsTableProps } from '../PrescriptionsTable/interface';
import PrescriptionCard from './PrescriptionCard';

const PrescriptionsTableMobile: FC<PrescriptionsTableProps> = ({
  prescriptions,
  medicData,
  role,
  allowedCodesForCloning,
}) => {
  const { idnp } = useParams();

  return (
    <CorBox className="border-0 flex gap-4">
      {!!prescriptions?.length &&
        prescriptions?.map((prescription) => (
          <CorBox
            key={prescription.id}
            className="border border-solid border-border-hover rounded-[18px] overflow-hidden h-fit"
          >
            <PrescriptionCard
              role={role}
              prescription={prescription}
              patientId={idnp}
              key={prescription.id}
              medicData={medicData}
              allowedCodesForCloning={allowedCodesForCloning}
            />
          </CorBox>
        ))}
    </CorBox>
  );
};

export default memo(PrescriptionsTableMobile);
