import Api from '../axios/api';
import type { Drug } from '../types/Drug';

export interface GetDrugsResponse {
  data: {
    items?: Drug[];
    count: number;
  };
}

export interface CreateDrugResponse {
  data: Drug;
}

export interface UpdateDrugResponse {
  data: Drug;
}

export interface MessageResponse {
  message: string;
}

interface DrugSearchQuery {
  limit?: number;
  skip?: number;
  orderBy?: any;
  search?: string;
  internationalNames?: string[];
  commercialName?: string;
  commercialCod?: string;
  commercialCodes?: string[];
}

export interface DrugAvailabilityResponse {
  data: {
    prescriptionId: string;
    referencePeriod: number;
    isAvailable?: boolean;
    notHaveGroupConflict?: boolean;
  };
}

export interface ProductAvailabilityQuery {
  idnp: string;
  internationalName: string;
  routeOfAdministration?: string;
  dose?: string;
}
export class DrugService {
  static create(body: Drug): Promise<CreateDrugResponse> {
    return Api.post(`/v1/admin/drugs`, body);
  }

  static getAll(target: string, params: DrugSearchQuery): Promise<GetDrugsResponse> {
    return Api.get(`/v1/${target}/drugs`, {
      params,
    });
  }

  static bulkUpload(data: FormData): Promise<MessageResponse> {
    return Api.post(`/v1/admin/drugs/upload`, data);
  }

  static delete(id: string): Promise<MessageResponse> {
    return Api.delete(`/v1/admin/drugs/${id}`);
  }

  static update(id: string, body: Drug): Promise<UpdateDrugResponse> {
    return Api.patch(`/v1/admin/drugs/${id}`, body);
  }

  static download(type: string) {
    return Api.get(`/v1/admin/drugs/download.${type}`, {
      responseType: 'blob',
    });
  }

  static checkAvailable(params: ProductAvailabilityQuery): Promise<DrugAvailabilityResponse> {
    return Api.get(`/v1/doctor/drugs/check-availability`, {
      params,
    });
  }
}
