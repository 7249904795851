import { CorInput, CorRadioButton, CorText } from '@e-reteta/react-design-system';
import { T, useTranslate } from '@tolgee/react';
import React, { useContext, useEffect, useState } from 'react';
import { useFormikContext } from 'formik';

import { TextVariant } from '../../../enums/design-system.enum';
import { ReportSearchType } from '../report-search-type.enum';
import { Annex8ForInstitutionsProps } from './annex8-for-institutions.interface';
import { AuthContext } from '../../../context/context';
import { ClinicRolesEnum } from '../../../constants/roles.enum';

const Annex8ForInstitutions = () => {
  const { user, hasRole } = useContext(AuthContext);
  const formik = useFormikContext<Annex8ForInstitutionsProps>();
  const { t } = useTranslate();
  const [isForDoctor, setIsForDoctor] = useState(true);

  useEffect(() => {
    if (!user?.activeCompany) {
      return;
    }
    formik.setFieldValue('annex8InstitutionCode', user?.activeCompany?.idno);
    if (hasRole(ClinicRolesEnum.IMS_BRANCH) || hasRole(ClinicRolesEnum.IMS_HEAD)) {
      setIsForDoctor(false);
    }
  }, [user]);

  const renderInputPlaceholder = () => {
    if (ReportSearchType.PER_DOCTOR === formik?.values?.annex8InstitutionReportType) {
      return 'Introduceti doctor idnp';
    }
    if (ReportSearchType.PER_PATIENT === formik?.values?.annex8InstitutionReportType) {
      return 'Introduceti idnp patient sau act provizoriu';
    }
    if (ReportSearchType.PER_PRESCRIPTION === formik?.values?.annex8InstitutionReportType) {
      return 'Introduceti id prescriere';
    }
  };

  const renderTranslationKeys = () => {
    if (ReportSearchType.PER_DOCTOR === formik?.values?.annex8InstitutionReportType) {
      return 'annex8.doctorIdnp';
    }
    if (ReportSearchType.PER_PATIENT === formik?.values?.annex8InstitutionReportType) {
      return 'annex8.patientIdnp';
    }
    if (ReportSearchType.PER_PRESCRIPTION === formik?.values?.annex8InstitutionReportType) {
      return 'annex8.prescriptionId';
    }
  };

  const renderInput = () => {
    if (formik?.values?.annex8InstitutionReportType === undefined) {
      return null;
    }
    return (
      <>
        <div className="grid grid-cols-2 xs:px-4 mb-4">
          <CorInput>
            <input
              type="text"
              placeholder={t(`${renderTranslationKeys()}`, renderInputPlaceholder())}
              value={formik.values.annex8InstitutionsSearchInput}
              onChange={(event) => {
                formik.setFieldValue('annex8InstitutionsSearchInput', event.target.value);
              }}
            />
          </CorInput>
        </div>
      </>
    );
  };

  return (
    <div className="flex flex-col">
      <CorText variant={TextVariant.LABEL_1_MEDIUM} className="px-4">
        <div className="text-content-default">
          <T keyName={'annex.reportType'}>Tip Raport</T>
        </div>
      </CorText>
      <div className="flex flex-col px-4 pt-4 mb-4">
        <div className="flex gap-8">
          {!isForDoctor && (
            <CorRadioButton
              isChecked={
                ReportSearchType.PER_DOCTOR === formik?.values?.annex8InstitutionReportType
              }
              disabled={false}
            >
              <label className="whitespace-nowrap">
                <T keyName={'annex8.perDoctor'}>{ReportSearchType.PER_DOCTOR}</T>
              </label>
              <input
                type="radio"
                name="annex8InstitutionReportType"
                value={ReportSearchType.PER_DOCTOR}
                checked={
                  ReportSearchType.PER_DOCTOR === formik?.values?.annex8InstitutionReportType
                }
                onChange={formik.handleChange}
                className="bg-background-secondary"
              />
            </CorRadioButton>
          )}
          <CorRadioButton
            isChecked={ReportSearchType.PER_PATIENT === formik?.values?.annex8InstitutionReportType}
            disabled={false}
          >
            <label className="whitespace-nowrap">
              <T keyName={'annex8.perPatient'}>{ReportSearchType.PER_PATIENT}</T>
            </label>
            <input
              type="radio"
              name="annex8InstitutionReportType"
              value={ReportSearchType.PER_PATIENT}
              checked={ReportSearchType.PER_PATIENT === formik?.values?.annex8InstitutionReportType}
              onChange={formik.handleChange}
              className="bg-background-secondary"
            />
          </CorRadioButton>
          <CorRadioButton
            isChecked={
              ReportSearchType.PER_PRESCRIPTION === formik?.values?.annex8InstitutionReportType
            }
            disabled={false}
          >
            <label className="whitespace-nowrap">
              <T keyName={'annex8.perPrescription'}>{ReportSearchType.PER_PRESCRIPTION}</T>
            </label>
            <input
              type="radio"
              name="annex8InstitutionReportType"
              value={ReportSearchType.PER_PRESCRIPTION}
              checked={
                ReportSearchType.PER_PRESCRIPTION === formik?.values?.annex8InstitutionReportType
              }
              onChange={formik.handleChange}
              className="bg-background-secondary"
            />
          </CorRadioButton>
        </div>
      </div>
      {renderInput()}
      <div className="w-full border-0 border-t border-solid border-border-disabled mx-4 my-6" />
    </div>
  );
};

export default Annex8ForInstitutions;
