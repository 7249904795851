import { CorBadge, CorButton, CorIcon, CorText } from '@e-reteta/react-design-system';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { T, useTranslate } from '@tolgee/react';

import { IconName, IconSize } from '../../enums/design-system-icons.enum';
import { BadgeSize, TextVariant } from '../../enums/design-system.enum';
import { Alert, ModalWindow, PatientMedicCard } from '../../components';
import { useGetPatient } from '../../hooks/use-get-patient';
import {
  DPrescriptions,
  GetPrescriptionAvailableProductsDto,
  PrescriptionServices,
} from '../../services/prescription.services';
import { DiagnosticServices } from '../../services/diagnostics.services';
import { Edit } from '../../icons';
import { getPrescriptionStatusColor } from '../../lib/getPrescriptionStatusColor';
import { ReceiptStatus } from '../../enums/ReceiptStatus';
import {
  getPrescriptionStatusTranslation,
  getPrescriptionStatusTranslationKey,
} from '../../lib/getPrescriptionStatusTranslation';
import useWindowWidth from '../../hooks/use-window-width';
import { PharmacyService, PrescriptionRelease } from '../../services/pharmacy.services';
import { Doctor } from '../../types/Doctor';
import { AuthContext } from '../../context/context';
import { renderResponsiveClasses } from '../../utils/render-responsive-classes.utils';
import PharmacistViewPrescription from '../../components/ContainerViewPrescription/Pharmacist';
import { Product } from '../../types/product.interface';
import { isAxiosError } from 'axios';
import { handleError } from '../../utils/handleRequestErrors';
import { DispensesStatusEnum } from '../../enums/dispenses-status.enum';
import { PatientData } from '../../components/PatientMedicCard/interface';
import { isPatient } from '../../utils/checkTableData';
import { LoaderContext } from '../../context/loader/context';
import { toast } from 'react-toastify';
import { AlertStatus } from '../../components/Alert/enum';

const DispensePrescription = () => {
  const { idnp, prescriptionId } = useParams();
  const navigate = useNavigate();
  const { isTablet, isMobile } = useWindowWidth();
  const { user, role } = useContext(AuthContext);
  const { setLoading } = useContext(LoaderContext);
  const location = useLocation();

  const [disease, setDisease] = useState<any>();
  const [prescription, setPrescription] = useState<any>();
  const [availableProducts, setAvailableProducts] = useState<Product[]>([]);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [releases, setReleases] = useState<any[]>([]);
  const [dispenses, setDispenses] = useState<any[]>([]);
  const [cashRegister, setCashRegister] = useState<string>();
  const [integrationErrors, setIntegrationErrors] = useState<string>('');

  const [patient] = useGetPatient({ idnp });
  const { t } = useTranslate();

  const onSubmit = async () => {
    try {
      setLoading(true);
      let prescriptionReleases: any = localStorage.getItem('prescriptionReleases');
      prescriptionReleases = JSON.parse(prescriptionReleases);

      if (!isPatient(patient)) {
        throw new Error('Invalid patient data');
      }

      const dispense = await PharmacyService.saveDispenses({
        patient,
        prescriptionId,
        diagnosticCode: disease?.code,
        prescriptionReleases,
        addedBy: user?.idnp,
        institutionCode: user?.companies?.find((company) => company.isActive)?.code,
        status: DispensesStatusEnum.DRAFT,
        cashRegister,
      });
      localStorage.setItem('prescriptionReleases', JSON.stringify([]));

      if (dispense?.data?.error) {
        toast.error(dispense?.data?.error);
        setIntegrationErrors(dispense?.data?.error);
        return;
      }

      navigate(`/patient/${idnp}/dispenses/${dispense.data.id}/close`);
    } catch (error) {
      isAxiosError(error) && handleError(error);
    } finally {
      setLoading(false);
    }
  };

  const onGoBack = () => {
    if (location?.pathname?.includes('prescription') && location?.pathname?.includes('sign')) {
      navigate(`/unsigned-dispenses`);
    } else if (location?.pathname?.includes('dispenses')) {
      localStorage.setItem('prescriptionReleases', JSON.stringify([]));
      navigate(`/patient/${idnp}/prescription/${prescriptionId}`);
    } else {
      navigate(`/patient/${idnp}`);
    }
  };

  const getDisease = useCallback(
    async (code: string) => {
      if (role) {
        const disease = await DiagnosticServices.getDiagnostics(role, {
          code,
        });
        setDisease(disease?.data?.items?.at(0));
      }
    },
    [role],
  );

  const getPrescription = useCallback(async () => {
    if (!prescriptionId || !role) {
      return;
    }
    try {
      const query = {
        statuses: [
          ReceiptStatus.SIGNED,
          ReceiptStatus.RELEASED,
          ReceiptStatus.PARTIAL_RELEASED,
          ReceiptStatus.SIGNED_BY_PHARMACIST,
        ],
      };
      const { data } = await PrescriptionServices.getPrescriptionById(
        prescriptionId,
        'pharmacy',
        query,
      );
      await getDisease(data.diagnosticCode);
      setPrescription({ data });
      localStorage.setItem('prescriptionReleases', JSON.stringify([]));
      let prescriptionReleases: any;
      if (data?.status && data?.status !== 'signed') {
        prescriptionReleases = await getDispenses();
      }
      await getProducts(data, prescriptionReleases);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prescriptionId, getDisease]);

  const getDispenses = useCallback(async () => {
    if (prescriptionId && role) {
      const { data }: any = await PharmacyService.getDispensesByPrescriptionId(
        role,
        prescriptionId,
      );
      let prescriptionReleases: any[] = [];
      data?.items?.forEach((d: any) => {
        d.prescriptionReleases?.forEach((pr: any) => prescriptionReleases.push(pr));
      });
      setDispenses(data?.items);
      setReleases(prescriptionReleases);
      return prescriptionReleases;
    }
  }, [prescriptionId, role]);

  const getProducts = async (prescriptionData: any, prescriptionReleases: any) => {
    const releasedPositions: Record<string, number> = {};
    if (prescriptionReleases && prescriptionReleases.length) {
      prescriptionReleases.forEach((item: PrescriptionRelease) => {
        if (!releasedPositions[item.dPrescriptionId]) {
          releasedPositions[item.dPrescriptionId] = Number(item.quantity);
          return;
        }
        releasedPositions[item.dPrescriptionId] += Number(item.quantity);
      });
    }
    const query: GetPrescriptionAvailableProductsDto = {
      drugs: [],
      devices: [],
    };

    prescriptionData?.dPrescriptions?.forEach((p: DPrescriptions) => {
      if (p.deviceName) {
        query.devices.push({
          internationalName: p.deviceName,
          quantityLeftToDispense: Number(p.quantity) - (releasedPositions[p.id] || 0),
        });
        return;
      }

      query.drugs.push({
        internationalName: p.drugInternationalName!,
        dose: p.drugDose!,
        routeOfAdministration: p.drugRouteOfAdministration!,
        quantityLeftToDispense: Number(p.quantity) - (releasedPositions[p.id] || 0),
      });
    });

    const { data } = await PrescriptionServices.getAvailableProducts(prescriptionData.id, query);
    if (data.error) {
      toast.error(
        t('dispensePrescription.integrationError', 'A aparut o eroare la cererea produselor', {
          error: data.error,
        }),
      );
    } else {
      toast.success(
        t('dispensePrescription.refreshed', 'Produsele disponbile au fost actualizate!'),
      );
    }
    setCashRegister(data.cashRegister);
    setAvailableProducts(data.items);
  };

  useEffect(() => {
    if (user) {
      getPrescription();
    }
    return () => {
      localStorage.setItem('prescriptionReleases', JSON.stringify([]));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="h-full sm:pl-6 sm:p-4 lg:pl-6 lg:p-0">
      <div className="flex flex-row justify-between items-center mb-4">
        <div className="flex flex-row items-center gap-2">
          <CorIcon
            size={IconSize.MD}
            name={IconName.ARROW_LEFT}
            className="cursor-pointer"
            onClick={onGoBack}
          />
          <div className="flex flex-col sm:flex-row sm:items-center justify-start gap-x-2">
            <CorText
              variant={renderResponsiveClasses(
                isMobile,
                isTablet,
                TextVariant.LABEL_1_REGULAR,
                TextVariant.HEADING_3_BOLD,
                TextVariant.HEADING_2_BOLD,
              )}
            >
              <h2>
                <T keyName={'pharmacistDispensing.releasePrescriptionTitle'}>Eliberare reteta</T>
              </h2>
            </CorText>
          </div>
          <CorBadge
            size={BadgeSize.MD}
            color={getPrescriptionStatusColor(prescription?.data.status as ReceiptStatus)}
          >
            <span className="capitalize">
              <T keyName={getPrescriptionStatusTranslationKey(prescription?.data.status)}>
                {getPrescriptionStatusTranslation(prescription?.data.status as ReceiptStatus)}
              </T>
            </span>
          </CorBadge>
        </div>
      </div>
      <PatientMedicCard
        patient={patient as PatientData}
        patientIdentifier={idnp}
        user={user}
        doctor={prescription?.data?.doctor as Doctor}
        status="prescribed"
        role={role}
      />
      <CorText
        className="mb-3 mt-6"
        variant={isMobile ? TextVariant.HEADING_4_BOLD : TextVariant.HEADING_3_BOLD}
      >
        <span>
          <T keyName={'pharmacistDispensing.drugsAndDevices'}>Medicamente/Dispozitive</T>
        </span>
      </CorText>
      <div>
        <PharmacistViewPrescription
          onRefresh={async () => {
            toast.warning(t('dispensePrescription.doRefresh', 'Produsele se acualizeaza!'));
            try {
              await getProducts(prescription?.data, releases);
            } catch (e) {
              toast.error(t('dispensePrescription.refreshError', 'A aparut o eroare!'));
            }
          }}
          products={availableProducts}
          items={prescription?.data?.dPrescriptions}
          thirdPersonIDNP={prescription?.data?.thirdPerson?.idnp}
          thirdPersonName={`${prescription?.data?.thirdPerson?.lastName} ${prescription?.data?.thirdPerson?.firstName}`}
          code={prescription?.data?.diagnosticCode}
          name={disease?.name}
          role={role}
          dispenses={dispenses || []}
          setIsDisabled={setDisabled}
          prescribedAt={prescription?.data?.prescribedAt}
        />
      </div>
      <div className={`flex flex-row gap-4 mt-4 sm:pb-4 lg:pb-0 justify-end`}>
        <div className="flex gap-2 sm:gap-4 w-full sm:w-fit">
          <CorButton className="flex-1 sm:flex-auto">
            <button disabled={disabled} className="w-full" onClick={onSubmit}>
              <T keyName={'pharmacistDispensing.releaseButtonText'}>Elibereaza </T>
              <Edit width={16} height={16} className="fill-[#FFFFFF]" />
            </button>
          </CorButton>
        </div>
      </div>
      {!!integrationErrors && (
        <ModalWindow>
          <div className="h-fit w-[520px] p-4">
            <Alert
              label={t('closeDispenses.errorMessageLabel', 'A avut loc o eraore')}
              text={integrationErrors}
              secondButton={t('closeDispenses.retry', 'Reincearca eliberarea')}
              firstButton={t('closeDispenses.cancelDispensing', 'Anuleaza eliberarea')}
              status={AlertStatus.DANGER}
              withButton
              secondButtonClick={async () => {
                window.location.reload();
              }}
              firstButtonClick={async () => {
                navigate(`/patient/${idnp}`);
              }}
            />
          </div>
        </ModalWindow>
      )}
    </div>
  );
};

export default DispensePrescription;
