import { FC, PropsWithChildren } from 'react';

const ModalWindow: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className="fixed inset-0 bg-modal-background z-50">
      <div className="flex items-center h-screen justify-center">{children}</div>
    </div>
  );
};

export default ModalWindow;
