import { FC } from 'react';
import { CorBox, CorText } from '@e-reteta/react-design-system';

import { NotFound } from '../../icons';
import { NotFoundBlockProps } from './interface';
import { NotFoundType } from './notFoundBlock.enum';
import { TextVariant } from '../../enums/design-system.enum';
import useWindowWidth from '../../hooks/use-window-width';
import { renderResponsiveClasses } from '../../utils/render-responsive-classes.utils';

const NotFoundBlock: FC<NotFoundBlockProps> = (props) => {
  const { text, type } = props;
  const { isMobile, isTablet } = useWindowWidth();

  let image;
  switch (type) {
    case NotFoundType.MAGNIFIER:
      image = <NotFound width={130} height={127} />;
      break;
    case NotFoundType.RECIPE:
      image = (
        <img src="/not-found-prescription.png" alt="no recipe" width={!isMobile ? 280 : 200} />
      );
      break;
    default:
      image = <img src="/not-found.png" alt="not found loop" className="w-[200px] sm:w-[400px]" />;
  }

  return (
    <CorBox className="flex flex-col items-center justify-center gap-8 sm:gap-16" boxStyle="none">
      <CorText
        className="sm:w-[500px] text-center"
        variant={renderResponsiveClasses(
          isMobile,
          isTablet,
          TextVariant.HEADING_4_BOLD,
          TextVariant.HEADING_3_BOLD,
          TextVariant.HEADING_2_BOLD,
        )}
      >
        <span>{text}</span>
      </CorText>
      {image}
    </CorBox>
  );
};

export default NotFoundBlock;
