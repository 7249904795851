import { FC, useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { isAxiosError } from 'axios';
import { CorButton, CorIcon, CorText } from '@e-reteta/react-design-system';
import { T } from '@tolgee/react';

import { ButtonVariant, TextVariant } from '../../enums/design-system.enum';
import { AuthServices, User } from '../../services/auth.services';
import { AuthContext } from '../../context/context';
import { IconColor, IconSize } from '../../enums/design-system-icons.enum';
import { handleError } from '../../utils/handleRequestErrors';
import { getActiveCompanyUtil } from '../../utils/getActiveCompany.util';
import { LanguagePicker } from '../../components';

const Login: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const useQuery = () => {
    return new URLSearchParams(location.search);
  };

  const query = useQuery();
  const error = query.get('error');

  const { setUser } = useContext(AuthContext);
  const [failedLogin, setFailedLogin] = useState<boolean>(!!error);

  // TODO: remove when auth is implemented
  const mockLogin = async (url: string, role: string) => {
    if (process.env.REACT_APP_ENV === 'dev') {
      try {
        const resp = await AuthServices.login(role);
        localStorage.setItem('access_token', resp.data.accessToken);
        localStorage.setItem('refresh_token', resp.data.refreshToken);
        localStorage.setItem('role', role);
        const user: User = jwtDecode(resp.data.accessToken);
        user.activeCompany = getActiveCompanyUtil(user);
        localStorage.setItem('is_active', user.activeCompany ? user.activeCompany.code : '');
        setUser(user);
        navigate(url);
      } catch (error) {
        setFailedLogin(true);
        isAxiosError(error) && handleError(error);
      }
    }
  };

  return (
    <div className="flex flex-col-reverse sm:flex-row absolute inset-0 sm:relative sm:h-screen sm:w-screen overflow-hidden bg-white p-4">
      <div className="flex flex-col flex-1 sm:flex-none sm:w-1/2 sm:h-full justify-between sm:justify-center items-center px-0 sm:px-10 xl:px-24 py-8 sm:py-16">
        {!failedLogin ? (
          <CorText
            variant={TextVariant.HEADING_1_BOLD}
            className="text-center sm:text-left w-full max-w-[432px]"
          >
            <p>
              <T keyName="login.login">Logare</T>
            </p>
          </CorText>
        ) : (
          <div className="border border-solid border-content-critical rounded-lg flex flex-col items-center justify-center text-center gap-6 mb-6 py-6 px-12 w-full max-w-[432px]">
            <CorIcon
              name="warning-1-circle"
              size={IconSize.LG}
              color={IconColor.CONTENT_CRITICAL}
            />
            <CorText variant={TextVariant.HEADING_1_BOLD}>
              <p className="text-4xl">
                <T keyName="login.failedLogin">Autentificare esuata!</T>
              </p>
            </CorText>
            <CorText variant={TextVariant.LABEL_2_REGULAR}>
              <p className="text-xl">
                <T keyName="login.tryAgain">Va rugam sa incercati din nou.</T>
              </p>
              {error && <small>{error}</small>}
            </CorText>
          </div>
        )}

        <div className="flex flex-col items-center justify-end sm:justify-center flex-1 w-full max-w-[432px] gap-3">
          <CorButton variant={ButtonVariant.PRIMARY} size="large" className="w-full">
            <a href={`${process.env.REACT_APP_API_URL}v1/auth/login`} className="w-full">
              <T keyName="login.auth">Autentificare</T>
            </a>
          </CorButton>
          {process.env.REACT_APP_ENV === 'dev' ? (
            <>
              <CorButton variant={ButtonVariant.PRIMARY} size="large" className="w-full">
                <button className="w-full" onClick={() => mockLogin('/doctor/search', 'doctor')}>
                  <T keyName="login.authDoctor">Autentificare doctor</T>
                </button>
              </CorButton>
              <CorButton variant={ButtonVariant.PRIMARY} size="large" className="w-full">
                <button
                  className="w-full"
                  onClick={() => mockLogin('/pharmacy/search', 'pharmacy')}
                >
                  <T keyName="login.authPharmacist">Autentificare farmacist</T>
                </button>
              </CorButton>
              <CorButton variant={ButtonVariant.PRIMARY} size="large" className="w-full">
                <button
                  className="w-full"
                  onClick={() => mockLogin('/management-tool/reports', 'admin')}
                >
                  <T keyName="login.managementTool">Management tool</T>
                </button>
              </CorButton>
            </>
          ) : null}
        </div>
        <LanguagePicker />
      </div>
      <div
        className={`relative p-10 flex sm:flex-1 justify-center items-center sm:w-1/2 overflow-hidden ${
          failedLogin ? 'bg-background-critical ' : 'bg-background-secondary '
        } rounded-3.5xl`}
      >
        <img
          src={`${failedLogin ? '/login-failed.png' : '/login.png'}`}
          alt="login"
          className="h-auto w-full md:w-auto xl:w-full max-h-[200px] sm:max-h-[376px] xl:max-h-[500px] max-w-[200px] sm:max-w-[376px] xl:max-w-[500px]"
        />
      </div>
    </div>
  );
};

export default Login;
