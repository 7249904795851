import { CorBox, CorChips, CorText } from '@e-reteta/react-design-system';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';

import 'dayjs/locale/ro';
import 'dayjs/locale/ru';
import 'dayjs/locale/en';
import { GroupBy } from './group-by.interface';
import { ChipSize, ChipVariant, TextVariant } from '../../../enums/design-system.enum';
import hash from 'object-hash';

var localizedFormat = require('dayjs/plugin/localizedFormat');
dayjs.extend(localizedFormat);

const GroupByContainer = ({ name, fields, preSelected = [], onChange }: GroupBy) => {
  const [activeFields, setActiveFields] = useState<string[]>(preSelected);
  useEffect(() => {
    if (onChange) {
      onChange(activeFields);
    }
  }, [activeFields]);
  const handleFieldClick = (field: string) => {
    return () => {
      const fieldIdx = activeFields.indexOf(field);
      if (fieldIdx !== -1) {
        activeFields.splice(fieldIdx, 1);
        setActiveFields(activeFields.slice());
        return;
      }
      activeFields.push(field);
      setActiveFields(activeFields.slice());
      return;
    };
  };

  return (
    <div className="bg-background-secondary px-4">
      <CorBox boxStyle="none" className="relative">
        <CorText variant={TextVariant.HEADING_4_MEDIUM}>
          <div className="text-content-default">{name}</div>
        </CorText>
        <div>
          {fields?.map((field) => (
            <CorChips
              onClick={handleFieldClick(field.value)}
              key={hash(field.value)}
              variant={ChipVariant.OUTLINE}
              isSelected={activeFields.indexOf(field.value) !== -1}
              size={ChipSize.MD}
              className="mr-4 my-2 inline-block"
            >
              {field.label}
            </CorChips>
          ))}
        </div>
      </CorBox>
    </div>
  );
};

export default GroupByContainer;
