import { FC, memo, useContext } from 'react';
import { CorBox } from '@e-reteta/react-design-system';
import { useNavigate } from 'react-router-dom';

import PrescriptionCard from './HandwrittenCard';
import { HandwrittenTableProps } from '../HandwrittenTable/interface';
import { AuthContext } from '../../context/context';

const HandwrittenTableMobile: FC<HandwrittenTableProps> = (props) => {
  const { recipes, hasCheckbox, checkboxClick, isChecked } = props;
  const navigate = useNavigate();
  const { role } = useContext(AuthContext);

  const isCheckbox = (e: any) => !e.target.matches('input[type="checkbox"]');

  const navigateTo = (patientId: string, prescriptionId: string) => {
    if (role === 'pharmacy') {
      navigate(`/patient/${patientId}/prescription/${prescriptionId}/sign-dispense`);
    }
    if (role === 'doctor') {
      navigate(`/patient/${patientId}/prescription/${prescriptionId}/sign`);
    }
  };

  return (
    <CorBox className="border-0 grid grid-cols-1 sm:grid-cols-2 gap-4">
      {!!recipes?.length &&
        recipes?.map((recipe, index: number) => {
          const key = `${Math.random()}-${recipe.id}`;

          const prescription = recipe.prescription ? recipe.prescription : recipe;
          return (
            <CorBox
              key={key}
              className="border border-solid border-border-hover rounded-[18px] overflow-hidden h-fit"
              onClick={(e: any) =>
                isCheckbox(e) ? navigateTo(prescription.patientIdentifier, prescription.id) : null
              }
            >
              <PrescriptionCard
                recipe={recipe}
                hasCheckbox={hasCheckbox}
                isChecked={isChecked}
                checkboxClick={checkboxClick}
                index={index}
              />
            </CorBox>
          );
        })}
    </CorBox>
  );
};

export default memo(HandwrittenTableMobile);
