import { FC, SVGProps } from 'react';

const Check: FC<SVGProps<SVGSVGElement>> = (props) => {
  const { color = 'black' } = props;

  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7817 5.29361C15.0798 4.94294 15.6057 4.9003 15.9564 5.19837C16.307 5.49644 16.3497 6.02235 16.0516 6.37303L8.96827 14.7064C8.66551 15.0625 8.12907 15.1001 7.77968 14.7895L4.02968 11.4562C3.68569 11.1504 3.65471 10.6237 3.96047 10.2797C4.26624 9.9357 4.79297 9.90471 5.13695 10.2105L8.25016 12.9778L14.7817 5.29361Z"
        fill={color}
      />
    </svg>
  );
};

export default Check;
