import React, { FC, useState } from 'react';
import { T, useTranslate } from '@tolgee/react';
import { CorText } from '@e-reteta/react-design-system';
import classNames from 'classnames';

import { EinsteinDrawerProps } from './interface';
import useWindowWidth from '../../hooks/use-window-width';
import { ChevronDown, Cross } from '../../icons';
import Tab from './Tab';
import { TabEnum } from './Tab/tab.enum';
import QuestionTab from './QuestionTab';
import { TextVariant } from '../../enums/design-system.enum';
import InteractionTab from './InteractionTab';
import HelpTab from './HelpTab';

const EinsteinDrawer: FC<EinsteinDrawerProps> = ({ toBottom = false }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const { isMobile } = useWindowWidth();
  const { t } = useTranslate();
  const [notification, setNotification] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<TabEnum>(TabEnum.QUESTION);

  const renderTab = () => {
    switch (selectedTab) {
      case TabEnum.QUESTION:
        return (
          <QuestionTab
            description={t(
              'einsteinDrawer.questionTabDescription',
              'Sectiunea data ofera posibilitatea de a face o cautare dupa cuvintul cheie introdus',
            )}
            setNotification={setNotification}
          />
        );
      case TabEnum.DRUGS:
        return (
          <InteractionTab
            description={t(
              'einsteinDrawer.interactionsTabDescription',
              'Sectiunea data ofera posibilitatea de a verifica interactiuniea dintre doua substante active',
            )}
            setNotification={setNotification}
          />
        );
      case TabEnum.HELP:
        return <HelpTab></HelpTab>;
    }
  };

  const renderTitle = () => {
    switch (selectedTab) {
      case TabEnum.QUESTION:
        return (
          <CorText variant={TextVariant.HEADING_2_BOLD}>
            <div className="text-white">
              <T keyName="einsteinDrawer.QuestionTitle">Intrebare-raspuns</T>
            </div>
          </CorText>
        );
      case TabEnum.DRUGS:
        return (
          <CorText variant={TextVariant.HEADING_2_BOLD}>
            <div className="text-white">
              <T keyName="einsteinDrawer.Drug2DrugTitle">Interactione medicamente</T>
            </div>
          </CorText>
        );
      case TabEnum.HELP:
        return (
          <CorText variant={TextVariant.HEADING_2_BOLD}>
            <div className="text-white">
              <T keyName="einsteinDrawer.helpTitle">Ajutor</T>
            </div>
          </CorText>
        );
    }
  };

  const openInNewTab = (url: string) => {
    window.open(url, '_blank');
  };

  return (
    <>
      <div
        className={classNames(
          'fixed right-4 rounded-full w-[70px] h-[70px] p-[12px] flex justify-center border-solid border-5 ' +
            'cursor-pointer border-action-primary-default opacity-1 hover:bg-border-success-default group',
          { hidden: isMobile },
          {
            'top-4': !toBottom,
            'bottom-4': toBottom,
          },
        )}
        onClick={() => {
          setIsDrawerOpen(true);
          setNotification(false);
        }}
      >
        {notification ? (
          <div className="absolute w-5 h-5 bg-[red] rounded-full top-0 right-0 outline-4 outline outline-white" />
        ) : null}
        <Cross className="fill-border-success-default group-hover:fill-white"></Cross>
      </div>
      <div
        className={classNames({
          'fixed inset-0 h-screen w-full bg-modal-qa-background': isDrawerOpen,
        })}
      >
        <div
          className={classNames(
            'fixed top-0 right-0 z-20 w-[470px] h-full duration-500 transform bg-white shadow-lg overflow-auto',
            {
              '-translate-x-0': isDrawerOpen,
              'translate-x-[470px]': !isDrawerOpen,
            },
          )}
        >
          <div className="flex h-full flex-col justify-between">
            <div>
              <div className="flex justify-between bg-action-primary-default h-[50px] w-full px-6 py-4">
                <div className="flex gap-5 items-center">
                  <div className="w-[30px] h-[30px] border-solid border border-white rounded-full p-1.5">
                    <Cross className="fill-white" />
                  </div>
                  {renderTitle()}
                </div>
                <ChevronDown
                  className="w-[20px] h-[20px] fill-white cursor-pointer"
                  onClick={() => setIsDrawerOpen(false)}
                />
              </div>
              {renderTab()}
            </div>
            <div>
              <div className="bg-border-inverted-selected w-full h-px" />
              <Tab selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
              <div className="bg-border-inverted-selected w-full h-px" />
              <div className="flex justify-between py-5 px-4">
                <div className="flex gap-2.5">
                  <CorText>
                    <div className="text-xs text-content-muted">
                      <T keyName="einsteinDrawer.poweredBy">Powered by:</T>
                    </div>
                  </CorText>
                  <img
                    src="/corlab.png"
                    alt="interactions"
                    className="rounded-2xl cursor-pointer"
                    height={17}
                    onClick={() => openInNewTab('//corlab.tech/industry-healthcare')}
                  />
                  <small className="text-xs text-content-muted">&</small>
                  <img
                    src="/wisecube.png"
                    alt="interactions"
                    className="rounded-2xl cursor-pointer"
                    height={17}
                    onClick={() => openInNewTab('//www.wisecube.ai')}
                  />
                </div>
                <div className="flex gap-2">
                  <CorText>
                    <div className="text-xs text-content-muted">
                      <T keyName="einsteinDrawer.temrs">Termeni</T>
                    </div>
                  </CorText>
                  <CorText>
                    <div className="text-xs text-content-muted">
                      <T keyName="einsteinDrawer.policy">Politică</T>
                    </div>
                  </CorText>
                  <CorText>
                    <div className="text-xs text-content-muted">© 2024</div>
                  </CorText>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EinsteinDrawer;
