import { DropDownReceiptStatus, ReceiptStatus } from '../enums/ReceiptStatus';

export function getPrescriptionStatusTranslation(
  status: ReceiptStatus | DropDownReceiptStatus,
): string {
  switch (status) {
    case ReceiptStatus.RELEASED:
      return 'Valorificat';
    case ReceiptStatus.SIGNED:
      return 'Semnat';
    case ReceiptStatus.UNSIGNED:
      return 'Nesemnat';
    case ReceiptStatus.EXPIRED:
      return 'Expirat';
    case ReceiptStatus.PARTIAL_RELEASED:
      return 'Partial valorificata';
    case ReceiptStatus.PRESCRIBED:
      return 'Prescris';
    case ReceiptStatus.CANCELED:
      return 'Anulat';
    default:
      return '';
  }
}

export function getPrescriptionStatusTranslationKey(
  status: ReceiptStatus | DropDownReceiptStatus,
): string {
  switch (status) {
    case ReceiptStatus.RELEASED:
      return 'tableRow.RELEASED';
    case ReceiptStatus.SIGNED:
      return 'tableRow.SIGNED';
    case ReceiptStatus.UNSIGNED:
      return 'tableRow.UNSIGNED';
    case ReceiptStatus.EXPIRED:
      return 'tableRow.EXPIRED';
    case ReceiptStatus.PARTIAL_RELEASED:
      return 'tableRow.PARTIAL_RELEASED';
    case ReceiptStatus.PRESCRIBED:
      return 'tableRow.PRESCRIBED';
    case ReceiptStatus.CANCELED:
      return 'tableRow.CANCELED';
    default:
      return '';
  }
}
