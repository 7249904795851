import Api from '../axios/api';

export interface RequestSign {
  prescriptionId: string;
}

interface ResponseSign {
  data?: {
    redirectUrl: string;
  };
}

export class SignServices {
  static sign(body: RequestSign, target: string): Promise<ResponseSign> {
    return Api.post(`/v1/${target}/sign`, body);
  }

  static signPharmacy(id: string): Promise<ResponseSign> {
    return Api.post(`/v1/pharmacy/prescriptions/${id}/sign`);
  }
}
