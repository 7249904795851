import React, { FC, memo, useCallback } from 'react';
import { CorDropdown, CorPagination, CorText } from '@e-reteta/react-design-system';
import { T } from '@tolgee/react';

import { TextVariant } from '../../enums/design-system.enum';
import type {
  CorItemsPerPageDropdownChangeEvent,
  CorTabChangeEvent,
  PaginationProps,
} from './interface';
import { DEFAULT_DROPDOWN_OPTION, dropdownOptions, VIEW_PAGE_RANGE } from './constants';
import { CorDropdownOption } from '../index';

const Pagination: FC<PaginationProps> = (props) => {
  const { setPaginationData, paginationData } = props;

  const handlePageChange = useCallback((event: CorTabChangeEvent) => {
    setPaginationData((data) => ({
      ...data,
      page: event.detail.page - 1,
    }));
  }, []);

  const handleItemsPerPageChange = useCallback(
    (event: CorItemsPerPageDropdownChangeEvent) => {
      const newItemsPerPage = parseInt(event.detail.value) ?? DEFAULT_DROPDOWN_OPTION;

      setPaginationData((data) => ({
        ...data,
        itemsPerPage: newItemsPerPage,
        page: 0,
      }));

      const queryParams = new URLSearchParams(window.location.search);

      queryParams.set('limit', newItemsPerPage.toString());

      const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
      window.history.replaceState({}, '', newUrl);
    },
    [setPaginationData],
  );

  return (
    <div className="flex flex-col xs:flex-row items-center w-full justify-between sm:pb-4 lg:pb-0 gap-4">
      <section className="flex flex-row items-center gap-3">
        <CorText variant={TextVariant.LABEL_2_REGULAR}>
          <span>
            <T keyName="pagination.Showing">Afișează</T>
          </span>
        </CorText>
        <CorDropdown
          searchEnabled={false}
          isButton={false}
          placeholder={paginationData.itemsPerPage ? paginationData.itemsPerPage.toString() : '#'}
          callback={handleItemsPerPageChange}
          isRight={false}
          hasSelectedValue={true}
        >
          {dropdownOptions.map((value) => (
            <CorDropdownOption
              key={value}
              value={value}
              label={value}
              selected={paginationData.itemsPerPage === value}
            />
          ))}
        </CorDropdown>
        <CorText variant={TextVariant.LABEL_2_REGULAR}>
          <span>
            <T keyName="pagination.perPage">per pagină din</T>
          </span>
        </CorText>
        <CorText variant={TextVariant.LABEL_2_REGULAR}>
          <span>{paginationData?.totalItems}</span>
        </CorText>
      </section>
      <section>
        <CorPagination
          count={paginationData.totalPages}
          page={paginationData.page + 1}
          viewPageRange={VIEW_PAGE_RANGE}
          onPageChanged={handlePageChange}
        />
      </section>
    </div>
  );
};

export default memo(Pagination);
