import { SVGProps } from 'react';

const UploadFolder = (props: SVGProps<any>) => {
  return (
    <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g opacity="0.3">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.33337 12.5C1.33337 13.3284 2.00495 14 2.83337 14H13.1667C13.9951 14 14.6667 13.3284 14.6667 12.5V6.16667C14.6667 5.33824 13.9951 4.66667 13.1667 4.66667H6.66671L5.10605 3.10601C4.82474 2.8247 4.44321 2.66667 4.04539 2.66667H2.83337C2.00495 2.66667 1.33337 3.33824 1.33337 4.16667V12.5Z"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.70127 8.82561C5.4098 9.14692 5.63778 9.66155 6.0716 9.66155H7.11077V10.8333C7.11077 11.1095 7.33463 11.3333 7.61077 11.3333H8.05599C8.33213 11.3333 8.55599 11.1095 8.55599 10.8333V9.66155H9.59516C10.029 9.66155 10.257 9.14692 9.96549 8.82561L8.20371 6.88349C8.00528 6.66474 7.66148 6.66474 7.46305 6.88349L5.70127 8.82561Z"
      />
    </svg>
  );
};

export default UploadFolder;
