import { useMemo } from 'react';

import ApiFacade from '../axios/api-facade';

export const useApiFacade = () => {
  return useMemo(
    () => new ApiFacade(process.env.REACT_APP_API_URL!, localStorage.getItem('access_token')!),
    [],
  );
};
