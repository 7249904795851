import React, { ReactElement, useContext, useEffect } from 'react';

import { AuthContext } from '../../context/context';

interface GuardProps {
  children: ReactElement<any, any>;
}

const DoctorGuardedRoute: React.FC<GuardProps> = ({ children }) => {
  const { role, logout } = useContext(AuthContext);

  useEffect(() => {
    if (role && role !== 'doctor') {
      logout(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role]);

  return <>{children}</>;
};

export default DoctorGuardedRoute;
