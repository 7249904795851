import { CorInput, CorRadioButton, CorText } from '@e-reteta/react-design-system';
import React, { useEffect, useRef, useState } from 'react';
import { T, useTranslate } from '@tolgee/react';
import { useFormikContext } from 'formik';
import { isAxiosError } from 'axios';
import hash from 'object-hash';

import {
  PharmaciesServices,
  PharmacyDto,
  PharmacySearchQuery,
} from '../../../services/pharmacies.services';
import { InputIconDisplay, TextVariant } from '../../../enums/design-system.enum';
import { IconName } from '../../../enums/design-system-icons.enum';
import { handleError } from '../../../utils/handleRequestErrors';
import { Report126FormikProps } from './report126.interface';
import Check from '../../../icons/Check';
import { ReportSearchType } from '../report-search-type.enum';

const Report126PsfHead = () => {
  const { t } = useTranslate();

  const formik = useFormikContext<Report126FormikProps>();
  const ref = useRef<HTMLDivElement>(null);
  const [isVisible, setVisible] = useState<boolean>(false);
  const [pharmacies, setPharmacies] = useState<PharmacyDto[]>([]);

  const handleSearchPharmacy = (address: string) => {
    formik.setFieldValue('report126Code', address);
    formik.setFieldValue('report126Address', undefined);
  };

  const handleSelectPharmacyByCode = (pharmacy: PharmacyDto) => {
    formik.setFieldValue('report126Code', pharmacy.code);
    formik.setFieldValue('report126Address', pharmacy.address);
  };

  const handleClickOutside = (event: any) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const getPharmacies = async () => {
    let query: PharmacySearchQuery = {
      address: formik?.values?.report126Code ? formik?.values?.report126Code : '',
    };
    const inst = await PharmaciesServices.getAll('pharmacy', query);
    setPharmacies(inst?.data?.items || []);
  };

  useEffect(() => {
    if (!pharmacies?.length && formik?.values?.report126Code !== '') {
      getPharmacies();
    }
    const delayInputTimeoutId = setTimeout(async () => {
      try {
        await getPharmacies();
      } catch (error) {
        isAxiosError(error) && handleError(error);
      }
    }, 500);
    return () => clearTimeout(delayInputTimeoutId);
  }, [formik?.values?.report126Code]);

  const renderDropDown = () => {
    if (
      formik?.values.report126HeadReportType === undefined ||
      formik?.values.report126HeadReportType === ReportSearchType.ALL_BRANCHES
    ) {
      return null;
    }

    return (
      <div className="relative md:w-1/2" ref={ref}>
        <CorInput iconName={IconName.SEARCH} iconDisplay={InputIconDisplay.LEFT}>
          <input
            autoComplete="off"
            type="text"
            name="report126Address"
            placeholder={t('report126Head.address', 'Introduceti adresa filiala')}
            value={formik?.values?.report126Address}
            onClick={() => setVisible(true)}
            onChange={(event: any) => {
              handleSearchPharmacy(event?.target?.value);
            }}
          />
        </CorInput>
        {isVisible && pharmacies && pharmacies?.length ? (
          <div className="absolute w-full max-h-[296px] overflow-y-scroll z-50 mt-1 py-2 rounded-[8px] border border-solid border-border-default bg-decorative-neutral-1">
            {pharmacies.map((item: PharmacyDto) => {
              return (
                <div
                  className="relative flex gap-2 justify-between w-full px-4 py-2 cursor-pointer group hover:bg-surface-hover"
                  key={hash(item)}
                  onClick={() => {
                    handleSelectPharmacyByCode(item);
                    setVisible(false);
                  }}
                >
                  <CorText variant={TextVariant.LABEL_2_REGULAR}>
                    <p className="text-content-muted group-hover:text-content-default">
                      {item.address}
                    </p>
                  </CorText>
                  {formik?.values?.report126Code === item.code ? <Check /> : null}
                </div>
              );
            })}
          </div>
        ) : null}
        <div className="w-full border-0 border-t border-solid border-border-disabled my-6" />
      </div>
    );
  };

  return (
    <div className="mb-4 px-4">
      <div className="flex flex-col px-4 py-4">
        <div className="flex gap-8">
          <CorRadioButton
            isChecked={ReportSearchType.ALL_BRANCHES === formik?.values.report126HeadReportType}
            disabled={false}
          >
            <label className="whitespace-nowrap">
              <T keyName={'report126.allBranches'}>{ReportSearchType.ALL_BRANCHES}</T>
            </label>
            <input
              type="radio"
              name="report126HeadReportType"
              value={ReportSearchType.ALL_BRANCHES}
              checked={ReportSearchType.ALL_BRANCHES === formik?.values.report126HeadReportType}
              onChange={formik.handleChange}
              className="bg-background-secondary"
            />
          </CorRadioButton>
          <CorRadioButton
            isChecked={
              ReportSearchType.PER_INSTITUTION_NAME === formik?.values.report126HeadReportType
            }
            disabled={false}
          >
            <label className="whitespace-nowrap">
              <T keyName={'report126.perInstitution'}>{ReportSearchType.PER_INSTITUTION_NAME}</T>
            </label>
            <input
              type="radio"
              name="report126HeadReportType"
              value={ReportSearchType.PER_INSTITUTION_NAME}
              checked={
                ReportSearchType.PER_INSTITUTION_NAME === formik?.values.report126HeadReportType
              }
              onChange={formik.handleChange}
              className="bg-background-secondary"
            />
          </CorRadioButton>
        </div>
      </div>
      {renderDropDown()}
    </div>
  );
};

export default Report126PsfHead;
