import { FC, useCallback } from 'react';
import classNames from 'classnames';
import { CorText } from '@e-reteta/react-design-system';
import { T } from '@tolgee/react';

import { TextVariant } from '../../enums/design-system.enum';
import { InputSwitchProps } from './interface';
import { SwitchEnum } from '../../enums/switch.enum';

const InputSwitch: FC<InputSwitchProps> = (props) => {
  const { value1, value2, selectedValue, onChange } = props;

  const handleToggle = useCallback(
    (value: SwitchEnum) => {
      if (onChange) {
        onChange(value);
      }
    },
    [onChange],
  );
  return (
    <div className="box-border flex flex-row p-1 bg-surface-primary border border-solid border-border-default rounded-5xl h-10 xs:h-8 w-full xs:w-fit">
      <div
        className={classNames(
          'flex flex-1 flex-row items-center py-1 px-2 gap-2 rounded-4xl cursor-pointer justify-center',
          { 'bg-surface-success-pressed': selectedValue === value1 },
        )}
        onClick={() => handleToggle(value1)}
      >
        <CorText variant={TextVariant.LABEL_2_MEDIUM}>
          <span className="text-content-default">
            <T keyName="inputSwitch.firstValue">{value1}</T>
          </span>
        </CorText>
      </div>
      <div
        className={classNames(
          'flex flex-1 flex-row items-center py-1 px-2 gap-2 rounded-4xl cursor-pointer justify-center',
          { 'bg-surface-success-pressed': selectedValue === value2 },
        )}
        onClick={() => handleToggle(value2)}
      >
        <CorText variant={TextVariant.LABEL_2_MEDIUM}>
          <span className="text-content-default">
            <T keyName="inputSwitch.secondValue">{value2}</T>
          </span>
        </CorText>
      </div>
    </div>
  );
};

export default InputSwitch;
