import Api from '../axios/api';
import { DisplayFieldsEnum } from '../components/ExportMenu/display-fields.enum';

interface RangeDto {
  startDate?: string;
  endDate?: string;
}

interface Annex7SearchQuery extends RangeDto {
  display?: DisplayFieldsEnum;
  institutionCode?: string;
}

interface Annex8ForCNAMSearchQuery extends RangeDto {
  institutionCode?: string;
  pharmacyIDNO?: string;
  pharmacyAddress?: string;
  doctorIDNP?: string;
  patientIdentifier?: string;
  pharmacistIDNP?: string;
  prescriptionId?: string;
  isHolographicPrescription?: boolean;
}

interface Annex8ForInstitutionSearchQuery extends RangeDto {
  institutionCode?: string;
  doctorIDNP?: string;
  patientIdentifier?: string;
  prescriptionId?: string;
  isHolographicPrescription?: boolean;
}

interface Annex8ForPharmacySearchQuery extends RangeDto {
  pharmacyCode?: string;
  pharmacyIDNO?: string;
  pharmacistIDNP?: string;
  prescriptionId?: string;
  isHolographicPrescription?: boolean;
}

interface Annex1ForDrugsSearchQuery extends RangeDto {
  institutionCode?: string;
}

interface Annex1ForDevicesSearchQuery extends RangeDto {
  institutionCode?: string;
}

interface Annex2SearchQuery extends RangeDto {
  internationalCodes?: string;
}

interface Annex4SearchQuery extends RangeDto {
  institutionCode?: string;
}

interface Annex6SearchQuery extends RangeDto {
  pharmacyCode?: string;
  display?: string;
}

interface Annex9SearchQuery extends RangeDto {
  institutionCode?: string;
  institutionName?: string;
  display?: string;
}

interface Annex10DCSearchQuery extends RangeDto {
  commercialCode?: string;
  isGeneralized?: boolean;
}

interface Annex10DCISearchQuery extends RangeDto {
  drugInternationalName?: string;
  deviceInternationalCode?: string;
  deviceName?: string;
  isGeneralized?: boolean;
}

interface Annex11SearchQuery extends RangeDto {
  commercialCode?: string;
  pharmacyIDNO: string;
}

interface Annex12SearchQuery extends RangeDto {
  includedColumns: string[];
}

interface Annex13SearchQuery extends RangeDto {
  startReferenceDate?: string;
  endReferenceDate?: string;
}

interface Report126SearchQuery extends RangeDto {
  pharmacyCode?: string;
}

export interface EInvoiceQuery {
  month?: number;
  year?: number;
}

export class ReportService {
  static downloadAnnex1ForDrugs(target: string, format: string, params: Annex1ForDrugsSearchQuery) {
    return Api.get(`/v1/${target}/reports/annex1-for-drugs.${format}`, {
      responseType: 'blob',
      params,
    });
  }

  static downloadAnnex1ForDevices(
    target: string,
    format: string,
    params: Annex1ForDevicesSearchQuery,
  ) {
    return Api.get(`/v1/${target}/reports/annex1-for-devices.${format}`, {
      responseType: 'blob',
      params,
    });
  }

  static downloadAnnex2(format: string, params: Annex2SearchQuery) {
    return Api.get(`/v1/admin/reports/annex2.${format}`, {
      responseType: 'blob',
      params,
    });
  }

  static downloadAnnex3ForDrugs(format: string, params: RangeDto) {
    return Api.get(`/v1/admin/reports/annex3-for-drugs.${format}`, {
      responseType: 'blob',
      params,
    });
  }

  static downloadAnnex3ForDevices(format: string, params: RangeDto) {
    return Api.get(`/v1/admin/reports/annex3-for-devices.${format}`, {
      responseType: 'blob',
      params,
    });
  }

  static downloadAnnex4(target: string, format: string, params: Annex4SearchQuery) {
    return Api.get(`/v1/${target}/reports/annex4.${format}`, {
      responseType: 'blob',
      params,
    });
  }

  static downloadAnnex5(format: string, params: RangeDto) {
    return Api.get(`/v1/admin/reports/annex5.${format}`, {
      responseType: 'blob',
      params,
    });
  }

  static downloadAnnex6(target: string, format: string, params: Annex6SearchQuery) {
    return Api.get(`/v1/${target}/reports/annex6.${format}`, {
      responseType: 'blob',
      params,
    });
  }

  static downloadAnnex7(target: string, format: string, params: Annex7SearchQuery) {
    return Api.get(`/v1/${target}/reports/annex7.${format}`, { responseType: 'blob', params });
  }

  static downloadAnnex8ForCNAM(format: string, params: Annex8ForCNAMSearchQuery) {
    return Api.get(`/v1/admin/reports/annex8-cnam.${format}`, { responseType: 'blob', params });
  }

  static downloadAnnex8ForInstitution(format: string, params: Annex8ForInstitutionSearchQuery) {
    return Api.get(`/v1/doctor/reports/annex8-clinics.${format}`, { responseType: 'blob', params });
  }

  static downloadAnnex8ForPharmacy(format: string, params: Annex8ForPharmacySearchQuery) {
    return Api.get(`/v1/pharmacy/reports/annex8-pharmacy.${format}`, {
      responseType: 'blob',
      params,
    });
  }

  static downloadAnnex9(format: string, params: Annex9SearchQuery) {
    return Api.get(`/v1/admin/reports/annex9.${format}`, {
      responseType: 'blob',
      params,
    });
  }

  static downloadAnnex10DC(format: string, params: Annex10DCSearchQuery) {
    return Api.get(`/v1/admin/reports/annex10-commercial-name.${format}`, {
      responseType: 'blob',
      params,
    });
  }

  static downloadAnnex10DCI(format: string, params: Annex10DCISearchQuery) {
    return Api.get(`/v1/admin/reports/annex10-international-name.${format}`, {
      responseType: 'blob',
      params,
    });
  }

  static downloadAnnex11(target: string, format: string, params: Annex11SearchQuery) {
    return Api.get(`/v1/${target}/reports/annex11.${format}`, {
      responseType: 'blob',
      params,
    });
  }

  static downloadReport126(target: string, format: string, params: Report126SearchQuery) {
    return Api.get(`/v1/${target}/reports/report126.${format}`, {
      responseType: 'blob',
      params,
    });
  }

  static downloadAnnex12(format: string, params: Annex12SearchQuery) {
    return Api.get(`/v1/admin/reports/annex12.${format}`, {
      responseType: 'blob',
      params,
    });
  }

  static downloadAnnex13(format: string, params: Annex13SearchQuery) {
    return Api.get(`/v1/admin/reports/annex13.${format}`, {
      responseType: 'blob',
      params,
    });
  }

  static downloadEInvoice(params: EInvoiceQuery) {
    return Api.get(`/v1/pharmacy/reports/e-invoice`, {
      responseType: 'blob',
      params,
    });
  }
}
