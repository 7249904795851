import React, { FC, memo, useContext } from 'react';
import { CorBox, CorText } from '@e-reteta/react-design-system';
import { useParams } from 'react-router-dom';
import { T } from '@tolgee/react';

import type { PrescriptionsTableProps } from './interface';
import { TextVariant } from '../../../enums/design-system.enum';
import TableRow from './TableRow';
import { AuthContext } from '../../../context/context';
import { LoaderContext } from '../../../context/loader/context';

const PrescriptionsTable: FC<PrescriptionsTableProps> = ({
  prescriptions,
  role,
  allowedCodesForCloning,
}) => {
  const { idnp } = useParams();
  const { user } = useContext(AuthContext);
  const { loading } = useContext(LoaderContext);

  return (
    <CorBox
      className={`flex border border-solid border-border-hover rounded-[18px] bg-background-primary overflow-hidden h-full mt-4 ${
        loading ? 'min-h-[300px] place-content-center' : ''
      }`}
    >
      {role === 'doctor' ? (
        <header className="flex h-11 gap-4 max-h-[44px] bg-surface-default items-center px-6">
          <div className="w-[80px] min-w-[80px] flex-1 flex items-center justify-start">
            <CorText variant={TextVariant.LABEL_2_REGULAR}>
              <span className="text-content-muted">
                <T keyName="prescriptionsTable.doctorDiagnostic">Diagnosticul</T>
              </span>
            </CorText>
          </div>
          <div className="w-[80px] min-w-[80px] flex items-center justify-start">
            <CorText variant={TextVariant.LABEL_2_REGULAR}>
              <span className="text-content-muted">
                <T keyName="prescriptionsTable.doctorDate">Data</T>
              </span>
            </CorText>
          </div>
          <div className="w-[152px] min-w-[152px] flex items-center justify-start">
            <CorText variant={TextVariant.LABEL_2_REGULAR}>
              <span className="text-content-muted">
                <T keyName="prescriptionsTable.doctorStatus">Statut</T>
              </span>
            </CorText>
          </div>
          <div className="w-[168px] min-w-[168px] flex items-center justify-start">
            <CorText variant={TextVariant.LABEL_2_REGULAR}>
              <span className="text-content-muted">
                <T keyName="prescriptionsTable.doctorPrecriberDoctor">Medicul prescriptor</T>
              </span>
            </CorText>
          </div>
          <div className="flex items-center justify-start w-[136px] min-w-[136px] 2lg:w-[206px] 2lg:min-w-[206px]">
            <CorText variant={TextVariant.LABEL_2_REGULAR}>
              <span className="text-content-muted">
                <T keyName="prescriptionsTable.doctorActions">Actiuni</T>
              </span>
            </CorText>
          </div>
        </header>
      ) : null}
      {role === 'pharmacy' ? (
        <header className="flex h-11 gap-4 max-h-[44px] bg-surface-default items-center px-6">
          <div className="w-[96px] min-w-[96px] flex-1 flex items-center justify-start">
            <CorText variant={TextVariant.LABEL_2_REGULAR}>
              <span className="text-content-muted">
                <T keyName="prescriptionsTable.pharmacyDiagnostic">Diagnosticul</T>
              </span>
            </CorText>
          </div>
          <div className="w-[96px] min-w-[96px] flex items-center justify-start">
            <CorText variant={TextVariant.LABEL_2_REGULAR}>
              <span className="text-content-muted">
                <T keyName="prescriptionsTable.pharmacyDate">Data</T>
              </span>
            </CorText>
          </div>
          <div className="w-[168px] min-w-[168px] flex items-center justify-start">
            <CorText variant={TextVariant.LABEL_2_REGULAR}>
              <span className="text-content-muted">
                <T keyName="prescriptionsTable.pharmacyStatus">Statut</T>
              </span>
            </CorText>
          </div>
          <div className="w-[240px] min-w-[240px] flex items-center justify-start">
            <CorText variant={TextVariant.LABEL_2_REGULAR}>
              <span className="text-content-muted">
                <T keyName="prescriptionsTable.pharmacyDrugsDevices">Medicamente/Dispozitive</T>
              </span>
            </CorText>
          </div>
        </header>
      ) : null}

      {!!prescriptions?.length && (
        <CorBox className="divide-y divide-x-0 divide-solid divide-border-hover overflow-y-auto overflow-x-hidden rounded-none flex-1 w-full">
          {prescriptions?.map((prescription) => (
            <TableRow
              role={role}
              prescription={prescription}
              patientId={idnp}
              key={prescription.id}
              user={user ? user : null}
              allowedCodesForCloning={allowedCodesForCloning}
            />
          ))}
        </CorBox>
      )}
    </CorBox>
  );
};

export default memo(PrescriptionsTable);
