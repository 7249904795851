import { CorText } from '@e-reteta/react-design-system';
import { T } from '@tolgee/react';
import { ReactNode, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import hash from 'object-hash';

import { TextVariant } from '../../../enums/design-system.enum';
import * as icons from '../../../icons';
import { ClosePanel, OpenPanel } from '../../../icons';
import LanguagePicker from '../../../components/LanguagePicker';
import NavigationItem from './NavigationItem';
import { NavigationEnum } from '../../../enums/navigation.enum';
import { AuthContext } from '../../../context/context';
import { NavigationItemProps } from './NavigationItem/navigation-item.interface';

const renderNavigationItemClasses = (disabled: boolean, selected: boolean) => {
  if (disabled) {
    return 'fill-content-disabled';
  } else {
    if (selected) {
      return 'fill-content-success';
    } else {
      return 'fill-surface-neutral-inverted-hover group-hover:fill-content-success';
    }
  }
};
const Navigation = ({ children, items }: { children: ReactNode; items: NavigationItemProps[] }) => {
  const { logout } = useContext(AuthContext);
  const { type } = useParams();

  const [selected, setSelected] = useState<NavigationEnum>(NavigationEnum.DRUGS);
  const [disabled] = useState<NavigationEnum[]>([NavigationEnum.SYSTEM_CONFIGURATION]);
  const [opened, setOpened] = useState(true);

  useEffect(() => {
    switch (type) {
      case 'drugs': {
        setSelected(NavigationEnum.DRUGS);
        break;
      }
      case 'devices': {
        setSelected(NavigationEnum.DEVICES);
        break;
      }
      case 'diagnostics': {
        setSelected(NavigationEnum.DIAGNOSTIC);
        break;
      }
      case 'specialities': {
        setSelected(NavigationEnum.SPECIALITIES);
        break;
      }
      case 'clinics': {
        setSelected(NavigationEnum.CLINICS);
        break;
      }
      case 'pharmacies': {
        setSelected(NavigationEnum.PHARMACIES);
        break;
      }
      case 'system-configurations': {
        setSelected(NavigationEnum.SYSTEM_CONFIGURATION);
        break;
      }
      case 'reports': {
        setSelected(NavigationEnum.REPORTS);
        break;
      }
      case 'integrations': {
        setSelected(NavigationEnum.INTEGRATIONS);
        break;
      }
      default: {
        setSelected(NavigationEnum.REPORTS);
        break;
      }
    }
  }, [type]);

  return (
    <div className="h-screen w-screen overflow-hidden flex">
      <div
        className={`${
          opened ? 'min-w-[240px] max-w-[240px]' : 'max-w-[80px] min-w-[80px]'
        } w-full p-2 flex flex-col justify-between`}
      >
        <div>
          <CorText variant={TextVariant.LABEL_1_BOLD} className="mb-2">
            {opened ? (
              <div className="px-4 py-5 flex items-center justify-between">
                eRețetă Compensată <OpenPanel onClick={() => setOpened((prev) => !prev)} />
              </div>
            ) : (
              <div className="py-5 flex justify-center">
                <ClosePanel onClick={() => setOpened((prev) => !prev)} />
              </div>
            )}
          </CorText>
          <div className="flex flex-col">
            {items.map((item) => (
              <NavigationItem
                key={hash(item.itemKey)}
                icon={
                  <item.icon
                    className={`w-5 h-5 ${renderNavigationItemClasses(
                      disabled.includes(item.itemKey),
                      selected === item.itemKey,
                    )}`}
                  />
                }
                itemKey={item.itemKey!}
                url={item.url!}
                selected={selected === item.itemKey}
                setSelected={setSelected}
                disabled={disabled.includes(item.itemKey!)}
                opened={opened}
              />
            ))}
          </div>
        </div>
        <section className=" flex flex-col items-center  py-4">
          <LanguagePicker />
          <button
            onClick={() => logout(true)}
            className={`w-full flex flex-row items-center ${
              opened ? 'justify-between' : 'justify-center'
            } bg-transparent border-solid border-action-neutral-default
                border rounded-[24px] w-[208px] max-w-[208px] h-10 max-h-[40px] px-4 py-2.5 cursor-pointer`}
          >
            {opened ? (
              <CorText variant={TextVariant.LABEL_2_MEDIUM}>
                <span className="text-action-neutral-default">
                  <T keyName={'navigation.loguot'}>Delogare</T>
                </span>
              </CorText>
            ) : null}
            <icons.SignOut width={16} height={16} />
          </button>
        </section>
      </div>
      <div className="flex-1 flex overflow-hidden p-5 bg-background-secondary">{children}</div>
    </div>
  );
};

export default Navigation;
