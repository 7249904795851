import { useRef, FC, useCallback, memo, ChangeEventHandler, useState } from 'react';
import { CorBox, CorButton, CorInput, CorText } from '@e-reteta/react-design-system';
import { T, useTranslate } from '@tolgee/react';
import PhoneInput from 'react-phone-number-input';
import { E164Number } from 'libphonenumber-js/types.cjs';

import type { NewReceiptModalProps } from './interface';
import { ButtonVariant, TextVariant } from '../../enums/design-system.enum';
import useWindowWidth from '../../hooks/use-window-width';
import disableScrollOnInput from '../../utils/disableScrollOnInput';
import useLocalStorage from '../../hooks/use-localstorage';

const NewReceiptModal: FC<NewReceiptModalProps> = ({
  onSave,
  onCancel,
  defaultReceiptsNr = 5,
  minReceiptsNr = 1,
  maxReceiptsNr = 20,
}) => {
  const { t } = useTranslate();
  const { isMobile } = useWindowWidth();
  const inputRef = useRef<HTMLInputElement>(null);

  const [phoneNumber, setPhoneNumber] = useLocalStorage('doctorPhoneNumber');
  const [phone, setPhone] = useState<E164Number | undefined>(phoneNumber);

  const handleSave = useCallback(() => {
    if (!inputRef.current) {
      return;
    }

    const value = inputRef.current.valueAsNumber;
    setPhoneNumber(phone?.toString() || '');
    onSave(value, phone?.toString() || '');
  }, [onSave, phone]);

  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      if (inputRef.current && event.target.valueAsNumber < minReceiptsNr) {
        inputRef.current.value = minReceiptsNr.toString();
      } else if (inputRef.current && event.target.valueAsNumber > maxReceiptsNr) {
        inputRef.current.value = maxReceiptsNr.toString();
      }
    },
    [minReceiptsNr, maxReceiptsNr],
  );

  const checkDisabled = () => {
    return !inputRef?.current?.value || !phone;
  };

  return (
    <CorBox
      px={!isMobile ? 'xl' : 'lg'}
      py={!isMobile ? 'xl' : 'lg'}
      boxStyle="none"
      className="justify-between w-[506px] h-[264px] sm:h-[318px] m-4 bg-white"
    >
      <header>
        <CorText variant={isMobile ? TextVariant.HEADING_3_BOLD : TextVariant.HEADING_2_BOLD}>
          <span>
            <T keyName="newReceiptModal.newHandwritenPrescription">Reteta noua olografa</T>
          </span>
        </CorText>
      </header>
      <main className="flex  gap-4">
        <div className="flex-none flex flex-col">
          <CorText variant={TextVariant.LABEL_2_REGULAR}>
            <span>
              <T keyName="newReceiptModal.phone">Numarul de retete</T>
            </span>
          </CorText>
          <CorInput type="number">
            <input
              type="number"
              ref={inputRef}
              defaultValue={defaultReceiptsNr}
              min={minReceiptsNr}
              max={maxReceiptsNr}
              name="new-handwritten-receipt"
              id="new-handwritten-receipt"
              placeholder={t(
                'newReceiptModal.introduceNumberOfPrescription',
                'Introduceti numarul de retete olografe',
              )}
              onChange={handleChange}
              onWheel={(e) => disableScrollOnInput(e)}
            />
          </CorInput>
        </div>
        <div className="w-full flex flex-col">
          <CorText variant={TextVariant.LABEL_2_REGULAR}>
            <span>
              <T keyName="newReceiptModal.phoneNumber">Numarul de contact</T>
            </span>
          </CorText>
          <PhoneInput
            placeholder={t(
              'newReceiptModal.phoneNumberPlaceHolder',
              'Introduceti numarul de contact',
            )}
            defaultCountry="MD"
            value={phone}
            onChange={setPhone}
            initialValueFormat="national"
          />
        </div>
      </main>
      <nav className="flex flex-col sm:flex-row items-center justify-between gap-2">
        <CorButton variant={ButtonVariant.SECONDARY} onClick={onCancel} className="w-full">
          <button className="flex items-center justify-center gap-2 w-full text-action-primary-default">
            <CorText variant={TextVariant.LABEL_2_MEDIUM}>
              <span className=" text-xs">
                <T keyName="newReceiptModal.cancel">Anulare</T>
              </span>
            </CorText>
          </button>
        </CorButton>
        <CorButton onClick={handleSave} className="w-full">
          <button
            className="flex items-center justify-center gap-2 w-full"
            disabled={checkDisabled()}
          >
            <CorText variant={TextVariant.LABEL_2_MEDIUM}>
              <span className="text-xs">
                <T keyName="newReceiptModal.saveAndPrint">Salveza si Printeaza</T>
              </span>
            </CorText>
          </button>
        </CorButton>
      </nav>
    </CorBox>
  );
};

export default memo(NewReceiptModal);
