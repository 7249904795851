import { SVGProps } from 'react';

const Building = (props: SVGProps<any>) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.66667 1.66663C5.28596 1.66663 4.16667 2.78592 4.16667 4.16663V17.5H8.75001V15.4166C8.75001 14.7263 9.30965 14.1666 10 14.1666C10.6904 14.1666 11.25 14.7263 11.25 15.4166V17.5H15.8333V4.16663C15.8333 2.78591 14.7141 1.66663 13.3333 1.66663H6.66667ZM7.91667 3.33329C7.22632 3.33329 6.66667 3.89294 6.66667 4.58329C6.66667 5.27365 7.22632 5.83329 7.91667 5.83329C8.60703 5.83329 9.16667 5.27365 9.16667 4.58329C9.16667 3.89294 8.60703 3.33329 7.91667 3.33329ZM10.8333 4.58329C10.8333 3.89294 11.393 3.33329 12.0833 3.33329C12.7737 3.33329 13.3333 3.89294 13.3333 4.58329C13.3333 5.27365 12.7737 5.83329 12.0833 5.83329C11.393 5.83329 10.8333 5.27365 10.8333 4.58329ZM7.91667 6.66663C7.22632 6.66663 6.66667 7.22627 6.66667 7.91663C6.66667 8.60698 7.22632 9.16663 7.91667 9.16663C8.60703 9.16663 9.16667 8.60698 9.16667 7.91663C9.16667 7.22627 8.60703 6.66663 7.91667 6.66663ZM6.66667 11.25C6.66667 10.5596 7.22632 9.99996 7.91667 9.99996C8.60703 9.99996 9.16667 10.5596 9.16667 11.25C9.16667 11.9403 8.60703 12.5 7.91667 12.5C7.22632 12.5 6.66667 11.9403 6.66667 11.25ZM12.0833 9.99996C11.393 9.99996 10.8333 10.5596 10.8333 11.25C10.8333 11.9403 11.393 12.5 12.0833 12.5C12.7737 12.5 13.3333 11.9403 13.3333 11.25C13.3333 10.5596 12.7737 9.99996 12.0833 9.99996ZM12.0835 6.66663C11.3932 6.66663 10.8335 7.22627 10.8335 7.91663C10.8335 8.60698 11.3932 9.16663 12.0835 9.16663C12.7739 9.16663 13.3335 8.60698 13.3335 7.91663C13.3335 7.22627 12.7739 6.66663 12.0835 6.66663Z"
      />
      <path
        opacity="0.3"
        d="M2.5 19.1567C2.5 18.2417 3.24174 17.5 4.15673 17.5H15.8433C16.7583 17.5 17.5 18.2417 17.5 19.1567C17.5 19.1622 17.4955 19.1667 17.4901 19.1667H2.50994C2.50445 19.1667 2.5 19.1622 2.5 19.1567Z"
      />
    </svg>
  );
};

export default Building;
