import { FC } from 'react';
import { CorText } from '@e-reteta/react-design-system';
import { useLocation } from 'react-router-dom';

import { TextVariant } from '../../enums/design-system.enum';
import { ContainerViewPrescriptionProps } from './interface';
import { ViewDevice, ViewDrug } from '../index';
import useWindowWidth from '../../hooks/use-window-width';
import { ViewDrugData } from '../../types/Drug';
import { T } from '@tolgee/react';
import { DPrescriptions } from '../../services/prescription.services';
import { checkExpiredProduct } from '../../../src/utils/checkExpiredProduct.util';

const ContainerViewPrescription: FC<ContainerViewPrescriptionProps> = (props) => {
  const location = useLocation();

  const {
    thirdPersonName,
    thirdPersonIDNP,
    role,
    code,
    name,
    drugs,
    devices,
    setIsDisabled,
    dispenses,
    prescribedAt,
  } = props;
  const { isMobile } = useWindowWidth();

  return (
    <div className="flex flex-col gap-4 sm:gap-6 rounded-2xl bg-white py-3 px-4 sm:py-5 sm:px-6">
      {location?.pathname?.includes('prescription') ? (
        <CorText variant={isMobile ? TextVariant.LABEL_1_BOLD : TextVariant.HEADING_4_BOLD}>
          <span>
            <T keyName="containerViewPrescription.prescriptionDetails">Detalii reteta</T>
          </span>
        </CorText>
      ) : null}
      <div className="grid 2md:grid-cols-2 2md:gap-2">
        <div className="rounded-bl-none rounded-br-none rounded-2xl 2md:rounded-2xl py-3 px-4 sm:py-5 sm:px-6 flex flex-col gap-2 w-full bg-white border border-solid border-border-default">
          <CorText variant={TextVariant.LABEL_1_BOLD}>
            <span>
              <T keyName="containerViewPrescription.diagnostic">Diagnosticul</T>
            </span>
          </CorText>
          <CorText variant={TextVariant.LABEL_2_MEDIUM}>
            <span className="text-content-default">
              {code} - {name}
            </span>
          </CorText>
        </div>
        {thirdPersonIDNP && thirdPersonName ? (
          <div className="rounded-tl-none rounded-tr-none rounded-2xl 2md:rounded-2xl py-3 px-4 sm:py-5 sm:px-6 flex flex-col gap-2 w-full max-2md:border-t-0 bg-white border border-solid border-border-default">
            <CorText variant={TextVariant.LABEL_1_BOLD}>
              <span>Persoana Terta</span>
            </CorText>
            <div className="flex flex-row gap-10">
              <div className="flex flex-col gap-2">
                <CorText variant={TextVariant.LABEL_2_REGULAR}>
                  <span className="text-content-muted">IDNP</span>
                </CorText>
                <CorText variant={TextVariant.LABEL_2_REGULAR}>
                  <span className="text-content-muted">Nume, Prenume</span>
                </CorText>
              </div>
              <div className="flex flex-col gap-2">
                <CorText variant={TextVariant.LABEL_2_MEDIUM}>
                  <span className="text-content-default">{thirdPersonIDNP}</span>
                </CorText>
                <div className="flex flex-col gap-1">
                  <CorText variant={TextVariant.LABEL_2_MEDIUM}>
                    <span className="text-content-default">{thirdPersonName}</span>
                  </CorText>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
      {drugs?.map((item: ViewDrugData, index: number) => {
        const uniqueKey = `${index}-${item.tradeName}`;
        let expired;
        if (item?.expiration && prescribedAt) {
          expired = checkExpiredProduct(item?.expiration, prescribedAt);
        }

        return (
          <ViewDrug
            onRefresh={() => {}}
            key={uniqueKey}
            releasedMedication={item.prescriptionReleases!}
            tradeName={item.drugInternationalName}
            treatmentDuration={item.validity!}
            prescribedQuantity={item.quantity!}
            dosage={item.drugDose!}
            administrationRoute={item.drugRouteOfAdministration!}
            activeSubstance={item.drugInternationalName!}
            additionalInfo={item.signature!}
            hasBorderBottom={index === drugs?.length - 1 && devices?.length === 0}
            role={role}
            dPrescriptionId={item.id!}
            setIsDisabled={setIsDisabled}
            dispenses={dispenses!}
            diagnosticsCode={code!}
            expired={expired}
          />
        );
      })}
      {devices?.map((item: DPrescriptions, index: number) => {
        const uniqueKey = `${index}-${item.id}`;
        let expired;
        if (item?.expiration && prescribedAt) {
          expired = checkExpiredProduct(item?.expiration, prescribedAt);
        }
        return (
          <ViewDevice
            onRefresh={() => {}}
            key={uniqueKey}
            name={item.deviceName!}
            commercialName={item.commercialName!}
            prescribedQuantity={item.quantity}
            treatmentDuration={item.validity!}
            quantity={item.quantity}
            signature={item.signature!}
            hasBorderBottom={index === devices?.length - 1}
            dPrescriptionId={item.id}
            role={role}
            setIsDisabled={setIsDisabled}
            dispenses={dispenses}
            diagnosticsCode={code!}
            expired={expired}
          />
        );
      })}
    </div>
  );
};

export default ContainerViewPrescription;
