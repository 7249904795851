import Api from '../axios/api';

export interface PharmacyDto {
  code?: string;
  idno?: string;
  atAscribed?: string;
  name?: string;
  district?: string;
  address?: string;
  series?: string;
  noLicense?: string;
  licenseValidity?: string;
  type?: string;
  accreditationCertificate?: string;
  validUntilTheDate?: string;
  branchCategory?: string;
  internalCodeOfTheBranch?: string;
  branchManager?: string;
  workingHours?: string;
  phone?: string;
  email?: string;
  contractNr?: string;
  vatPayer?: boolean;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface GetInstitutionsResponse {
  data: {
    items?: PharmacyDto[];
    count: number;
    expiringCount?: number;
  };
}

export interface MessageResponse {
  message: string;
}

export interface PharmacySearchQuery {
  names?: string[];
  codes?: string[];
  idnos?: string[];
  address?: string;
  limit?: number;
  skip?: number;
  orderBy?: any;
  search?: string;
}

export class PharmaciesServices {
  static getAll(target: string, params: PharmacySearchQuery): Promise<GetInstitutionsResponse> {
    return Api.get(`/v1/${target}/pharmacies`, {
      params,
    });
  }

  static delete(code: string): Promise<MessageResponse> {
    return Api.delete(`/v1/admin/pharmacies/${code}`);
  }

  static bulkUpload(data: FormData): Promise<MessageResponse> {
    return Api.post(`/v1/admin/pharmacies/upload`, data);
  }

  static create(body: PharmacyDto): Promise<MessageResponse> {
    return Api.post(`/v1/admin/pharmacies`, body);
  }

  static update(code: string, body: PharmacyDto): Promise<PharmacyDto> {
    return Api.patch(`/v1/admin/pharmacies/${code}`, body);
  }

  static download(type: string) {
    return Api.get(`/v1/admin/pharmacies/download.${type}`, {
      responseType: 'blob',
    });
  }
}
