import {
  GenerateHolographPrescriptionsDto,
  GetHolographPrescriptionsParams,
  ResponseHolographPrescriptionsDto,
} from '../types/HolographPrescription';
import Api from '../axios/api';
import { GetPrescriptionsResponse } from './prescription.services';

export class HolographPrescriptionService {
  static async generate(
    body: GenerateHolographPrescriptionsDto,
  ): Promise<ResponseHolographPrescriptionsDto> {
    return Api.post(`/v1/doctor/holograph-prescriptions/generate`, body);
  }

  static async getMyHolographPrescriptions(
    query: GetHolographPrescriptionsParams,
  ): Promise<GetPrescriptionsResponse> {
    return Api.get(`/v1/doctor/holograph-prescriptions/my`, { params: query });
  }
}
