import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { isAxiosError } from 'axios';
import { useTranslate } from '@tolgee/react';

import { AlertStatus } from '../../components/Alert/enum';
import { Alert, ModalWindow, Payment } from '../../components';
import { PharmacyService } from '../../services/pharmacy.services';
import ConfirmModal from '../../components/ConfirmModal';
import useQuery from '../../hooks/use-query';
import { useGetPatient } from '../../hooks/use-get-patient';
import { PrescriptionServices } from '../../services/prescription.services';
import { DiagnosticServices } from '../../services/diagnostics.services';
import { AuthContext } from '../../context/context';
import { handleError } from '../../utils/handleRequestErrors';
import eventBus from '../../events/event-bus';
import { SidebarItems } from '../../enums/sidebar-items.enum';
import { Disease } from '../../types/Disease';
import { isPatient } from '../../utils/checkTableData';
import { LoaderContext } from '../../context/loader/context';

const PaymentPage: FC = () => {
  const { t } = useTranslate();
  const query = useQuery();
  const [showModalAlert, setShowModalAlert] = useState<boolean>(false);
  const [showErrorAlert, setShowErrorAlert] = useState<boolean>(false);
  const [showModalConfirm, setShowModalConfirm] = useState<boolean>(false);
  const [voucher, setHouseVoucher] = useState<string | undefined>();
  const [cash, setCashRegister] = useState<string | undefined>();
  const [role] = useState<string>('pharmacy');
  const { setLoading } = useContext(LoaderContext);

  const { user } = useContext(AuthContext);
  const [disease, setDisease] = useState<Disease>();

  const navigate = useNavigate();
  const { idnp, prescriptionId } = useParams();

  const [patient] = useGetPatient({ idnp });

  const checkSign = (cashRegister: string, houseVoucher: string, sign = false) => {
    setHouseVoucher(houseVoucher);
    setCashRegister(cashRegister);
    setShowModalConfirm(sign);
  };

  const closeCheckSign = () => {
    setShowModalConfirm(false);
  };

  const onSuccessSign = async (sign = false, cashRegister?: string, houseVoucher?: string) => {
    setLoading(true);
    try {
      let prescriptionReleasesData = localStorage.getItem('prescriptionReleases');
      let prescriptionReleases = prescriptionReleasesData
        ? JSON.parse(prescriptionReleasesData)
        : [];

      if (!patient) {
        throw new Error('Patient is undefined');
      }

      if (typeof patient === 'boolean') {
        throw new Error('Patient is a boolean');
      }

      if (!isPatient(patient)) {
        throw new Error('Invalid patient data');
      }

      const dispense = await PharmacyService.saveDispenses({
        patient,
        prescriptionId,
        diagnosticCode: disease?.code!,
        prescriptionReleases,
        addedBy: user?.idnp,
        institutionCode: user?.companies?.find((company) => company.isActive)?.code,
        cashRegister: cashRegister || cash,
        houseVoucher: houseVoucher || voucher,
      });
      localStorage.setItem('prescriptionReleases', JSON.stringify([]));
      if (sign) {
        const resp = await PharmacyService.signDispenses({ items: [dispense.data.id] });
        if (resp?.data?.redirectUrl) {
          window?.location?.replace(resp?.data?.redirectUrl);
        }
      } else {
        eventBus.dispatch('updateCount', {
          detail: SidebarItems.UNSIGNED_DISPENSES,
        });
        navigate(`/patient/${idnp}`);
      }
    } catch (error) {
      isAxiosError(error) && handleError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (query && query.get('sign-status') === 'Success') {
      setShowModalAlert(true);
    }
    if (query && query.get('sign-status') === 'Error') {
      setShowErrorAlert(true);
    }
  }, [query]);

  const getDisease = useCallback(
    async (code: string) => {
      if (role) {
        const disease = await DiagnosticServices.getDiagnostics(role, {
          code,
        });
        setDisease(disease?.data?.items?.at(0));
      }
    },
    [role],
  );

  const getPrescription = useCallback(async () => {
    if (prescriptionId && role) {
      const { data } = await PrescriptionServices.getPrescriptionById(prescriptionId, role);
      await getDisease(data.diagnosticCode);
    }
  }, [prescriptionId, role, getDisease]);

  useEffect(() => {
    if (user) {
      getPrescription();
    }
  }, [user]);

  const goBack = () => {
    navigate(`/patient/${idnp}/dispenses/${prescriptionId}`);
  };

  return (
    <div className="flex flex-col overflow-auto hide-scrollbar sm:px-4 relative h-full">
      <Payment
        onGoBack={goBack}
        onSuccessSign={(cashRegister: string, houseVoucher: string) =>
          checkSign(cashRegister, houseVoucher, true)
        }
        signLater={(cashRegister: string, houseVoucher: string) =>
          onSuccessSign(false, cashRegister, houseVoucher)
        }
        showErrorAlert={showErrorAlert}
      />
      {showModalAlert ? (
        <ModalWindow>
          <div className="h-fit w-[520px] p-4">
            <Alert
              label={t('paymentPage.successSign', 'Semnarea a fost efectuata cu succes')}
              text={t('paymentPage.navigateTo', 'Navigheaza in continuare la')}
              firstButton={t('paymentPage.patientPage', 'Pagina pacientului')}
              secondButton={t('paymentPage.searchPage', 'Pagina de cautare')}
              status={AlertStatus.SUCCESS}
              withButton
              firstButtonClick={() => navigate(`/patient/${idnp}`)}
              secondButtonClick={() => navigate('/pharmacy/search')}
            />
          </div>
        </ModalWindow>
      ) : null}
      {showModalConfirm && (
        <ConfirmModal
          title={t('paymentPage.attention', 'Atentie')}
          description={t('paymentPage.questionSignPrescription', 'Ești sigur că semnezi rețeta?')}
          onClose={closeCheckSign}
          onAccept={() => onSuccessSign(true)}
        />
      )}
    </div>
  );
};

export default PaymentPage;
