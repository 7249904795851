import { CorInput, CorRadioButton, CorText } from '@e-reteta/react-design-system';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { T, useTranslate } from '@tolgee/react';
import { useFormikContext } from 'formik';
import hash from 'object-hash';
import { isAxiosError } from 'axios';

import { Annex6FormikProps } from './annex6.interface';
import { IconName } from '../../../enums/design-system-icons.enum';
import { InputIconDisplay, TextVariant } from '../../../enums/design-system.enum';
import Check from '../../../icons/Check';
import { handleError } from '../../../utils/handleRequestErrors';
import { AuthContext } from '../../../context/context';
import { PharmaciesServices, PharmacyDto } from '../../../services/pharmacies.services';
import { ReportSearchType } from '../report-search-type.enum';

const Annex6 = () => {
  const { role } = useContext(AuthContext);
  const { t } = useTranslate();

  const formik = useFormikContext<Annex6FormikProps>();
  const ref = useRef<HTMLDivElement>(null);
  const [isCodeVisible, setCodeVisible] = useState<boolean>(false);
  const [isAddressVisible, setAddressVisible] = useState<boolean>(false);
  const [pharmacies, setPharmacies] = useState<PharmacyDto[]>([]);

  const renderRadioTypes = () => {
    if (formik?.values?.annex6MedicalProductType === undefined) {
      return null;
    }
    return (
      <div className="mb-4">
        <CorText variant={TextVariant.LABEL_1_MEDIUM} className="mb-4">
          <div className="text-content-default">
            <T keyName={'annex.reportType'}>Tipul Raportului</T>
          </div>
        </CorText>
        <div className="flex gap-8">
          <CorRadioButton
            isChecked={ReportSearchType.TOTALIZER_REPORT === formik?.values?.annex6ReportType}
            disabled={false}
          >
            <label className="whitespace-nowrap">
              <T keyName={'annex.totalizerReport'}>{ReportSearchType.TOTALIZER_REPORT}</T>
            </label>
            <input
              type="radio"
              name="annex6ReportType"
              value={ReportSearchType.TOTALIZER_REPORT}
              checked={ReportSearchType.TOTALIZER_REPORT === formik?.values?.annex6ReportType}
              onChange={formik.handleChange}
              className="bg-background-secondary"
            />
          </CorRadioButton>
          <CorRadioButton
            isChecked={ReportSearchType.PER_INSTITUTION_NAME === formik?.values?.annex6ReportType}
            disabled={false}
          >
            <label className="whitespace-nowrap">
              <T keyName={'annex6.perBranchReport'}>{ReportSearchType.PER_INSTITUTION_NAME}</T>
            </label>
            <input
              type="radio"
              name="annex6ReportType"
              value={ReportSearchType.PER_INSTITUTION_NAME}
              checked={ReportSearchType.PER_INSTITUTION_NAME === formik?.values?.annex6ReportType}
              onChange={formik.handleChange}
              className="bg-background-secondary"
            />
          </CorRadioButton>
        </div>
      </div>
    );
  };

  const renderSearchBy = () => {
    if (
      formik?.values?.annex6ReportType === ReportSearchType.TOTALIZER_REPORT ||
      formik?.values?.annex6ReportType === undefined
    ) {
      return null;
    }
    return (
      <div className="mb-4">
        <CorText variant={TextVariant.LABEL_1_MEDIUM} className="mb-4">
          <div className="text-content-default">
            <T keyName={'annex.searchBy'}>Cauta Dupa</T>
          </div>
        </CorText>
        <div className="flex gap-8">
          <CorRadioButton
            isChecked={ReportSearchType.CODE === formik?.values?.annex6SearchBy}
            disabled={false}
          >
            <label className="whitespace-nowrap">
              <T keyName={'annex6.branchCodeLabel'}>{ReportSearchType.CODE}</T>
            </label>
            <input
              type="radio"
              name="annex6SearchBy"
              value={ReportSearchType.CODE}
              checked={ReportSearchType.CODE === formik?.values?.annex6SearchBy}
              onChange={formik.handleChange}
            />
          </CorRadioButton>
          <CorRadioButton
            isChecked={ReportSearchType.ADDRESS === formik?.values?.annex6SearchBy}
            disabled={false}
          >
            <label className="whitespace-nowrap">
              <T keyName={'annex6.branchAddressLabel'}>{ReportSearchType.ADDRESS}</T>
            </label>
            <input
              type="radio"
              name="annex6SearchBy"
              value={ReportSearchType.ADDRESS}
              checked={ReportSearchType.ADDRESS === formik?.values?.annex6SearchBy}
              onChange={formik.handleChange}
            />
          </CorRadioButton>
        </div>
      </div>
    );
  };

  const getPharmacies = async () => {
    if (role) {
      let query: { names?: string[]; codes?: string[]; address?: string } = {};
      if (formik?.values?.annex6Code) {
        query.codes = [formik?.values?.annex6Code];
      }
      if (formik?.values?.annex6Address) {
        query.address = formik?.values?.annex6Address;
      }
      const inst = await PharmaciesServices.getAll(role, query);
      setPharmacies(inst?.data?.items || []);
    }
  };

  useEffect(() => {
    if (!pharmacies?.length && formik?.values?.annex6Code !== '') {
      getPharmacies();
    }
    const delayInputTimeoutId = setTimeout(async () => {
      try {
        await getPharmacies();
      } catch (error) {
        isAxiosError(error) && handleError(error);
      }
    }, 500);
    return () => clearTimeout(delayInputTimeoutId);
  }, [formik?.values?.annex6Code, formik?.values?.annex6Address]);

  const handleSearchPharmacyByCode = (code: string) => {
    formik.setFieldValue('annex6Code', code);
  };

  const handleSelectPharmacyByCode = (pharmacy: PharmacyDto) => {
    formik.setFieldValue('annex6Code', pharmacy.code);
  };

  const handleSearchPharmacyByAddress = (address: string) => {
    formik.setFieldValue('annex6Address', address);
    formik.setFieldValue('annex6Code', undefined);
  };

  const handleSelectPharmacyByAddress = (pharmacy: PharmacyDto) => {
    formik.setFieldValue('annex6Address', pharmacy.address);
    formik.setFieldValue('annex6Code', pharmacy.code);
  };

  const handleClickOutside = (event: any) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setCodeVisible(false);
      setAddressVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const renderSearchDropDown = () => {
    if (
      formik?.values?.annex6SearchBy === undefined ||
      formik?.values?.annex6ReportType === ReportSearchType.TOTALIZER_REPORT
    ) {
      return null;
    }
    if (formik?.values?.annex6SearchBy === ReportSearchType.CODE) {
      return (
        <div className="mb-4">
          <div className="relative md:w-1/2" ref={ref}>
            <CorInput iconName={IconName.SEARCH} iconDisplay={InputIconDisplay.LEFT}>
              <input
                autoComplete="off"
                type="text"
                name="annex6Code"
                placeholder={t('annex6.branchCode', 'Introduceti cod filiala')}
                value={formik?.values?.annex6Code}
                onClick={() => setCodeVisible(true)}
                onChange={(event: any) => {
                  handleSearchPharmacyByCode(event?.target?.value);
                }}
              />
            </CorInput>
            {isCodeVisible && pharmacies && pharmacies?.length ? (
              <div className="absolute w-full max-h-[296px] overflow-y-scroll z-50 mt-1 py-2 rounded-[8px] border border-solid border-border-default bg-decorative-neutral-1">
                {pharmacies.map((item: PharmacyDto) => {
                  return (
                    <div
                      className="relative flex gap-2 justify-between w-full px-4 py-2 cursor-pointer group hover:bg-surface-hover"
                      key={hash(item)}
                      onClick={() => {
                        handleSelectPharmacyByCode(item);
                        setCodeVisible(false);
                      }}
                    >
                      <CorText variant={TextVariant.LABEL_2_REGULAR}>
                        <p className="text-content-muted group-hover:text-content-default">
                          {item.code}
                        </p>
                      </CorText>
                      {formik?.values?.annex6Code === item.code ? <Check /> : null}
                    </div>
                  );
                })}
              </div>
            ) : null}
          </div>
          <div className="w-full border-0 border-t border-solid border-border-disabled mx-4 my-6" />
        </div>
      );
    }
    if (formik?.values?.annex6SearchBy === ReportSearchType.ADDRESS) {
      return (
        <div className="mb-4">
          <div className="relative md:w-1/2" ref={ref}>
            <CorInput iconName={IconName.SEARCH} iconDisplay={InputIconDisplay.LEFT}>
              <input
                autoComplete="off"
                type="text"
                name="annex6Address"
                placeholder={t('annex6.branchAddress', 'Introduceti adresa filiala')}
                value={formik?.values?.annex6Address}
                onClick={() => setAddressVisible(true)}
                onChange={(event: any) => {
                  handleSearchPharmacyByAddress(event?.target?.value);
                }}
              />
            </CorInput>
            {isAddressVisible && pharmacies && pharmacies?.length ? (
              <div className="absolute w-full max-h-[296px] overflow-y-scroll z-50 mt-1 py-2 rounded-[8px] border border-solid border-border-default bg-decorative-neutral-1">
                {pharmacies.map((item: PharmacyDto) => {
                  return (
                    <div
                      className="relative flex gap-2 justify-between w-full px-4 py-2 cursor-pointer group hover:bg-surface-hover"
                      key={hash(item)}
                      onClick={() => {
                        handleSelectPharmacyByAddress(item);
                        setAddressVisible(false);
                      }}
                    >
                      <CorText variant={TextVariant.LABEL_2_REGULAR}>
                        <p className="text-content-muted group-hover:text-content-default">
                          {item.address}
                        </p>
                      </CorText>
                      {formik?.values?.annex6Code === item.code ? <Check /> : null}
                    </div>
                  );
                })}
              </div>
            ) : null}
          </div>
          <div className="w-full border-0 border-t border-solid border-border-disabled mx-4 my-6" />
        </div>
      );
    }
  };

  return (
    <div className="flex flex-col px-4 py-4">
      <CorText variant={TextVariant.LABEL_1_MEDIUM} className="mb-4">
        <div className="text-content-default">
          <T keyName={'anex2.medicalProduct'}>Produsul Medical</T>
        </div>
      </CorText>
      <div className="flex gap-8 mb-4">
        <CorRadioButton
          isChecked={ReportSearchType.DRUGS === formik?.values?.annex6MedicalProductType}
          disabled={false}
        >
          <label className="whitespace-nowrap">
            <T keyName={'annex2.drugs'}>{ReportSearchType.DRUGS}</T>
          </label>
          <input
            type="radio"
            name="annex6MedicalProductType"
            value={ReportSearchType.DRUGS}
            checked={ReportSearchType.DRUGS === formik?.values?.annex6MedicalProductType}
            onChange={formik.handleChange}
            className="bg-background-secondary"
          />
        </CorRadioButton>
        <CorRadioButton
          isChecked={ReportSearchType.DEVICES === formik?.values?.annex6MedicalProductType}
          disabled={false}
        >
          <label className="whitespace-nowrap">
            <T keyName={'annex2.devices'}>{ReportSearchType.DEVICES}</T>
          </label>
          <input
            type="radio"
            name="annex6MedicalProductType"
            value={ReportSearchType.DEVICES}
            checked={ReportSearchType.DEVICES === formik?.values?.annex6MedicalProductType}
            onChange={formik.handleChange}
            className="bg-background-secondary"
          />
        </CorRadioButton>
      </div>
      {renderRadioTypes()}
      {renderSearchBy()}
      {renderSearchDropDown()}
    </div>
  );
};

export default Annex6;
