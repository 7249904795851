import { createContext } from 'react';

import type { ManagementTableContextProps } from './interface';

export const ManagementTableContext = createContext<ManagementTableContextProps>({
  items: [],
  setItems: () => null,
  paginationData: {
    page: 0,
    itemsPerPage: 0,
    totalPages: 0,
    totalItems: 0,
  },
  setPaginationData: () => null,
  fetchItems: async () => {},
});
