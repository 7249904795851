import { FC, useEffect, useState } from 'react';
import { CorBox, CorText } from '@e-reteta/react-design-system';
import { T, useTranslate } from '@tolgee/react';

import { PrescribedPharmacistProps } from './interface';
import { TextVariant } from '../../enums/design-system.enum';
import useWindowWidth from '../../hooks/use-window-width';
import { Keys } from '../../enums/Keys';
import { Pharmacy, PharmacyService } from '../../services/pharmacy.services';

const PrescribingPharmacist: FC<PrescribedPharmacistProps> = ({ pharmacist }) => {
  const { isMobile } = useWindowWidth();
  const [pharmacyEntity, setPharmacyEntity] = useState<Pharmacy>();

  const { t } = useTranslate();

  useEffect(() => {
    getPharmacies();
  }, []);
  const getPharmacies = async () => {
    const company = pharmacist?.activeCompany;
    if (company?.code) {
      const { data } = await PharmacyService.getPharmacy(company.code);
      setPharmacyEntity(data);
    }
  };

  const pharmacyFields = {
    [t(
      'patientMedicCard.nameSurname',
      Keys.nameSurname,
    )]: `${pharmacist?.lastName} ${pharmacist?.firstName}`,
    [t('patientMedicCard.branch', 'Filiala')]: pharmacyEntity?.name,
    [t('pharmacistCard.branchLocation', Keys.branchLocation)]: pharmacyEntity?.address,
    [t('pharmacistCard.pharmacistIdno', Keys.pharmacistIdno)]: pharmacyEntity?.code,
  };

  return (
    <CorBox boxStyle="none">
      <section className="flex flex-col items-start gap-2">
        <header>
          <CorText variant={isMobile ? TextVariant.LABEL_1_BOLD : TextVariant.HEADING_4_BOLD}>
            <span>
              <T keyName="prescribingPharmacist.pharmacistData">Date Farmacist</T>
            </span>
          </CorText>
        </header>
        <div className="flex flex-col w-full xs:grid xs:grid-cols-2 sm:grid-cols-3 lg:flex lg:flex-row lg:justify-between gap-3">
          {Object.entries(pharmacyFields).map(([key, value]) => (
            <div className="grid grid-cols-2 xs:flex xs:flex-col" key={key}>
              <CorText variant={TextVariant.LABEL_2_REGULAR}>
                <span className="text-content-muted">{key}</span>
              </CorText>
              <CorText variant={TextVariant.LABEL_2_MEDIUM}>
                <span className="flex justify-end xs:justify-start text-content-default text-end xs:text-start">
                  {value}
                </span>
              </CorText>
            </div>
          ))}
        </div>
      </section>
    </CorBox>
  );
};

export default PrescribingPharmacist;
