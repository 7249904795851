import React, { FC, memo, useContext, useEffect, useState } from 'react';
import {
  CorBox,
  CorButton,
  CorIcon,
  CorTabButton,
  CorTabs,
  CorText,
} from '@e-reteta/react-design-system';
import classNames from 'classnames';
import { T, useTranslate } from '@tolgee/react';
import { useParams } from 'react-router-dom';

import { ChevronDown } from '../../icons';
import { ButtonVariant, TextVariant } from '../../enums/design-system.enum';
import type { DeviceAndDrugDropdownProps } from './interface';
import { DeviceOrDrug } from './enum';
import DrugFields from './DrugFields';
import DeviceFields from './DeviceFields';
import { IconColor, IconName } from '../../enums/design-system-icons.enum';
import { Restriction, RestrictionsEnum } from '../../enums/restrictions.enum';
import { DiagnosticServices, GetQueryParams } from '../../services/diagnostics.services';
import { Drug } from '../../types/Drug';
import { DeviceDto } from '../../services/device.service';
import { DeviceFieldData, DrugFieldData } from '../../types/CommonTypes';
import { CreateDPrescriptions } from '../../services/prescription.services';
import { LoaderContext } from '../../context/loader/context';
import { checkIfHave18 } from '../../utils/check-18.utils';

const DeviceAndDrugDropdown: FC<DeviceAndDrugDropdownProps> = ({
  selectedDevices,
  selectedDrugs,
  diagnosticCode,
  role,
  count,
  setAddNew,
  setAddedAtLeastOneDeviceOrDrug,
  formik,
  onRemove,
  patient,
}) => {
  const { action } = useParams();
  const { t } = useTranslate();

  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [disabled, setDisabled] = useState(true);
  const [disabledActions, setDisabledActions] = useState(false);
  const [restriction, setRestriction] = useState<Restriction>({ type: RestrictionsEnum.NONE });
  const [devices, setDevices] = useState<DeviceDto[]>();
  const [drugs, setDrugs] = useState<Drug[]>();
  const [resetWarning, setResetWarning] = useState(false);
  const { setLoading } = useContext(LoaderContext);

  const handleRemove = () => {
    onRemove && onRemove();
    formik.setFieldValue(
      `dPrescriptions`,
      formik.values.dPrescriptions.filter((_: CreateDPrescriptions, i: number) => i !== count),
    );
    if (!formik.values?.dPrescriptions?.length && setAddNew) {
      setAddNew(false);
    } else {
      setAddNew && setAddNew(true);
    }
  };

  const handleAdd = () => {
    formik.setFieldValue(`dPrescriptions[${count}].remove`, true);
    setDisabledActions(true);
    if (setAddNew) {
      setAddNew(true);
      setAddedAtLeastOneDeviceOrDrug && setAddedAtLeastOneDeviceOrDrug(true);
    }
  };

  const getDevices = async () => {
    try {
      setLoading(true);
      const query: GetQueryParams = {
        code: diagnosticCode,
      };
      if (!patient?.isInsured) {
        query.isAllowedUninsured = true;
      }
      const devicesCount = await DiagnosticServices.getDevicesByCode(role, {
        ...query,
        limit: 0,
      });
      const devices = await DiagnosticServices.getDevicesByCode(role, {
        ...query,
        limit: devicesCount?.data?.count,
      });
      setDevices(devices?.data?.items);
    } finally {
      setLoading(false);
    }
  };

  const getDrugs = async () => {
    try {
      setLoading(true);
      const query: GetQueryParams = {
        code: diagnosticCode,
      };
      if (!patient?.isInsured) {
        query.isAllowedUninsured = true;
      }
      const is18 = checkIfHave18(patient?.birthday ? new Date(patient?.birthday) : new Date());
      if (is18) {
        query.isAllowedForAdult = true;
      } else {
        query.isAllowedForChildren = true;
      }
      const drugsCount = await DiagnosticServices.getDrugsByCode(role, {
        ...query,
        limit: 0,
      });
      const drugs = await DiagnosticServices.getDrugsByCode(role, {
        ...query,
        limit: drugsCount?.data?.count,
      });
      if (drugs?.data?.items) {
        setDrugs(drugs?.data?.items);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!patient) {
      return;
    }
    if (!devices && selectedDevices?.length) {
      getDevices();
    }
    if (!drugs && selectedDrugs?.length) {
      getDrugs();
    }
  }, [selectedDevices, selectedDrugs, patient]);

  const handleToggleChange = async (type: string) => {
    if (!disabledActions) {
      if (type === DeviceOrDrug.DEVICE) {
        await getDevices();
      }
      if (type === DeviceOrDrug.DRUG) {
        setResetWarning(true);
        await getDrugs();
      }
      formik.setFieldValue(`dPrescriptions[${count}].remove`, false);
      setDisabled(true);
      setRestriction({ type: RestrictionsEnum.NONE });
      formik.setFieldValue(`dPrescriptions[${count}]`, {
        deviceCommercialCod: '',
        drugInternationalName: '',
        drugRouteOfAdministration: '',
        drugDose: '',
        validity: '',
        quantity: '',
        treatmentDuration: '',
        type,
      });
    }
  };

  useEffect(() => {
    if (action == 'clone') {
      return;
    }
    if (typeof formik?.values?.dPrescriptions[count]?.remove === 'boolean') {
      setDisabledActions(formik?.values?.dPrescriptions[count]?.remove);
    }
  }, [formik?.values?.dPrescriptions[count]]);

  return (
    <CorBox boxStyle="none" className="relative h-fit" px="none" py="none">
      {(formik?.values?.dPrescriptions[count]?.drugInternationalName &&
        formik?.values?.dPrescriptions[count]?.drugRouteOfAdministration &&
        formik?.values?.dPrescriptions[count]?.drugDose) ||
      formik?.values?.dPrescriptions[count]?.id ? (
        <CorBox
          boxStyle="none"
          flexDirection="row"
          className="flex gap-2 items-center cursor-pointer"
          onClick={() => {
            setIsOpen((value) => !value);
          }}
        >
          <ChevronDown
            width={24}
            height={24}
            className={classNames('duration-100 rotate-180 ease-in-out', {
              'transform rotate-270': isOpen,
            })}
          />
          <CorText variant={TextVariant.LABEL_1_BOLD}>
            <span className="font-bold">
              {formik?.values?.dPrescriptions[count]?.type === DeviceOrDrug.DRUG
                ? formik?.values?.dPrescriptions[count]?.drugInternationalName
                : formik?.values?.dPrescriptions[count]?.name}
            </span>
          </CorText>
        </CorBox>
      ) : null}

      {isOpen && (
        <CorBox boxStyle="none" className="box-border py-4">
          <div className="flex flex-col xs:grid xs:grid-cols-12 box-border pb-5 xs:items-center gap-y-1.5">
            <CorText variant={TextVariant.LABEL_2_REGULAR} className="col-span-5">
              <span className="text-content-muted">
                <T keyName={'deviceAndDrugDropdown.releaseType'}>Tipul Produsului Medical</T>
              </span>
            </CorText>
            <div className="col-span-7 box-border">
              <CorTabs isDisabled={disabledActions}>
                <CorTabButton
                  className={classNames({ 'pointer-events-none': disabledActions })}
                  isDisabled={disabledActions}
                  label={t('deviceAndDrugDropdown.drugLabel', DeviceOrDrug.DRUG)}
                  buttonId={DeviceOrDrug.DRUG}
                  isActive={DeviceOrDrug.DRUG === formik?.values?.dPrescriptions[count]?.type}
                  onClick={() => handleToggleChange(DeviceOrDrug.DRUG)}
                />
                <CorTabButton
                  className={classNames({ 'pointer-events-none': disabledActions })}
                  isDisabled={disabledActions}
                  label={t('deviceAndDrugDropdown.deviceLabel', DeviceOrDrug.DEVICE)}
                  buttonId={DeviceOrDrug.DEVICE}
                  isActive={DeviceOrDrug.DEVICE === formik?.values?.dPrescriptions[count]?.type}
                  onClick={() => handleToggleChange(DeviceOrDrug.DEVICE)}
                />
              </CorTabs>
            </div>
          </div>
          {formik?.values?.dPrescriptions[count]?.type ? (
            <CorBox boxStyle="none" className="gap-5 mb-4">
              {formik?.values?.dPrescriptions[count]?.type === DeviceOrDrug.DEVICE ? (
                <DeviceFields
                  key={`device_${count}`}
                  devices={devices}
                  selectedDevices={selectedDevices}
                  count={count}
                  setDisabled={setDisabled}
                  disabled={disabledActions}
                  formik={formik}
                  restriction={restriction}
                  setRestriction={setRestriction}
                  setDisabledActions={setDisabledActions}
                />
              ) : (
                <DrugFields
                  key={`drug_${count}`}
                  drugs={drugs}
                  count={count}
                  selectedDrugs={selectedDrugs}
                  setDisabled={setDisabled}
                  disabled={disabledActions}
                  formik={formik}
                  patient={patient}
                  restriction={restriction}
                  setRestriction={setRestriction}
                  setDisabledActions={setDisabledActions}
                  resetWarning={resetWarning}
                  setResetWarning={setResetWarning}
                />
              )}
            </CorBox>
          ) : null}

          <CorBox
            flexDirection="row"
            className="justify-end items-center gap-6 mb-4"
            boxStyle="none"
          >
            <CorButton variant={ButtonVariant.SECONDARY}>
              <button
                className="border-border-critical-default text-border-critical-default"
                type="button"
                onClick={handleRemove}
              >
                <T keyName={'deviceAndDrugDropdown.deleteButtonText'}>Sterge</T>
                <CorIcon name={IconName.CLOSE} color={IconColor.CONTENT_CRITICAL}></CorIcon>
              </button>
            </CorButton>
            {!formik.values.dPrescriptions[count]?.remove ? (
              <CorButton variant={ButtonVariant.SECONDARY}>
                <button type="button" disabled={disabled} onClick={handleAdd}>
                  <T keyName={'deviceAndDrugDropdown.addButtonText'}>Adauga</T>
                  <CorIcon name={IconName.PLUS} color="currentColor" />
                </button>
              </CorButton>
            ) : null}
          </CorBox>
          {(formik.values.dPrescriptions?.length &&
            formik.values.dPrescriptions?.filter((drug: DrugFieldData) => drug?.validity)
              ?.length) ||
          (formik.values.dPrescriptions?.length &&
            formik.values.dPrescriptions?.filter((device: DeviceFieldData) => device?.quantity)
              ?.length) ? (
            <div className="bg-border-inverted-selected w-full h-px" />
          ) : null}
        </CorBox>
      )}
    </CorBox>
  );
};

export default memo(DeviceAndDrugDropdown);
