import { CorChips, CorInput, CorText } from '@e-reteta/react-design-system';
import { FC, memo, useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { T, useTranslate } from '@tolgee/react';
import hash from 'object-hash';

import { ChipSize, ChipVariant, TextVariant } from '../../../../enums/design-system.enum';
import PlusInput from '../../PlusInput';
import { ModalContentProps } from '../interface';
import { DiagnosticServices } from '../../../../services/diagnostics.services';
import { Disease } from '../../../../types/Disease';
import { SpecialityDto } from '../../../../services/speciality.service';

const SpecialityModal: FC<ModalContentProps> = ({ isEdit }) => {
  const { t } = useTranslate();
  const formik = useFormikContext<SpecialityDto>();

  const [prescribedDisease, setPrescribeDiseases] = useState<string[]>([]);
  const [rePrescribedDiseases, setRePrescribedDiseases] = useState<string[]>([]);
  const [prescribedDiagnostics, setPrescribedDiagnostics] = useState<Disease[]>([]);
  const [rePrescribedDiagnostics, setRePrescribedDiagnostics] = useState<Disease[]>([]);

  const addPrescriptionDisease = (value: string) => {
    if (value && value.trim().length !== 0) {
      const values = [...prescribedDisease, value];
      setPrescribeDiseases(values);
      formik.values.prescribedDiseasesCodes = values;
    }
  };

  const removePrescriptionDisease = (value: string) => {
    const values = prescribedDisease.filter((disease) => disease !== value);
    setPrescribeDiseases(values);
    formik.values.prescribedDiseasesCodes = values;
  };

  const addNewRePrescriptionDisease = (value: string) => {
    if (value && value.trim().length !== 0) {
      const values = [...rePrescribedDiseases, value];
      setRePrescribedDiseases(values);
      formik.values.rePrescribedDiseasesCodes = values;
    }
  };

  const removeRePrescriptionDisease = (value: string) => {
    const values = rePrescribedDiseases.filter((disease) => disease !== value);
    setRePrescribedDiseases(values);
    formik.values.rePrescribedDiseasesCodes = values;
  };

  useEffect(() => {
    if (formik.values.prescribedDiseasesCodes?.length) {
      const values = formik.values.prescribedDiseasesCodes.map((item: string) => item);
      setPrescribeDiseases(values);
    }
    if (formik.values.rePrescribedDiseasesCodes?.length) {
      const values = formik.values.rePrescribedDiseasesCodes.map((item: string) => item);
      setRePrescribedDiseases(values);
    }
  }, [formik]);

  const handleSearchDiagnostics = async (value: string) => {
    const diseases = await DiagnosticServices.getDiagnostics('admin', { code: value });
    if (value) {
      setPrescribedDiagnostics(diseases.data.items);
    } else {
      setPrescribedDiagnostics([]);
    }
  };

  const handleSearchRePrescribedDiagnostics = async (value: string) => {
    const diseases = await DiagnosticServices.getDiagnostics('admin', { code: value });
    if (value) {
      setRePrescribedDiagnostics(diseases.data.items);
    } else {
      setRePrescribedDiagnostics([]);
    }
  };

  return (
    <div className="px-8 mb-6 overflow-auto">
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-1">
          <CorText variant={TextVariant.LABEL_2_REGULAR}>
            <div className="text-content-muted">
              <T keyName={'specialitiesModal.name'}>Specialitatea medicului</T>
            </div>
          </CorText>
          <CorInput disabled={isEdit} error={!!formik.errors.name}>
            <input
              autoComplete="off"
              type="text"
              placeholder={t('specialitiesModal.namePlaceholder', 'Introduceti specialitatea')}
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
            />
            {formik.errors.name ? (
              <span className="bottom-helper">{formik.errors.name}</span>
            ) : null}
          </CorInput>
        </div>
        <div className="flex flex-col">
          <PlusInput
            className="mb-1.5"
            placeholder={t('specialitiesModal.diagnosticsPlaceholder', 'Cod Maladie')}
            name="intersection"
            label={t('specialitiesModal.diagnostics', 'Diagnostice pentru prescriere')}
            addNewChip={addPrescriptionDisease}
            searchDiagnostics={handleSearchDiagnostics}
            diagnostics={prescribedDiagnostics}
            setSelectedDiagnostics={setPrescribedDiagnostics}
            selectedDiagnostics={prescribedDisease}
            error={formik.errors.prescribedDiseasesCodes}
            isDiagnosticInput={true}
          />
          <div className="flex flex-wrap gap-1">
            {prescribedDisease.map((item) => (
              <CorChips
                key={hash(item)}
                closeButton={true}
                size={ChipSize.MD}
                variant={ChipVariant.OUTLINE}
                onOnClose={() => removePrescriptionDisease(item)}
              >
                <div>{item}</div>
              </CorChips>
            ))}
          </div>
        </div>
        <div className="flex flex-col mb-1.5">
          <PlusInput
            className="mb-1.5"
            placeholder={t(
              'specialitiesModalrePrescribedDiagnosticsPlaceholder',
              'Introduceti dreptul de prescriere',
            )}
            name="prescriptions"
            label={t(
              'specialitiesModalrePrescribedDiagnostics',
              'Diagnostice pentru re-prescriere',
            )}
            addNewChip={addNewRePrescriptionDisease}
            searchDiagnostics={handleSearchRePrescribedDiagnostics}
            diagnostics={rePrescribedDiagnostics}
            setSelectedDiagnostics={setRePrescribedDiagnostics}
            selectedDiagnostics={rePrescribedDiseases}
            isDiagnosticInput={true}
          />
          <div className="flex flex-wrap gap-1">
            {rePrescribedDiseases.map((item) => (
              <CorChips
                key={hash(item)}
                closeButton={true}
                size={ChipSize.MD}
                variant={ChipVariant.OUTLINE}
                onOnClose={() => removeRePrescriptionDisease(item)}
              >
                <div>{item}</div>
              </CorChips>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(SpecialityModal);
