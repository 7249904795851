import dayjs from 'dayjs';

export const checkExpiredProduct = (expiration: number, compareDate: Date) => {
  let newDate;
  const expireDate = dayjs(compareDate);
  if (expiration) {
    newDate = expireDate.add(expiration, 'day');
  }
  if (newDate) {
    return newDate.isBefore(dayjs());
  }
  return false;
};
