export enum RestrictionsEnum {
  NONE,
  RESTRICTION,
  ADULT_RESTRICTION,
  CHILDREN_RESTRICTION,
  GROUP_WARNING,
  GROUP_RESTRICTION,
}

export interface Restriction {
  type: RestrictionsEnum;
  details?: Record<string, any>;
}
