import { SVGProps } from 'react';

const Patch = (props: SVGProps<any>) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.41074 4.69666L15.3033 10.5892L9.99999 15.8925L4.10744 9.99996L9.41074 4.69666ZM7.05372 9.4107C6.72828 9.73614 6.72828 10.2638 7.05372 10.5892C7.37915 10.9146 7.90679 10.9146 8.23223 10.5892C8.55766 10.2638 8.55766 9.73614 8.23223 9.4107C7.90679 9.08526 7.37915 9.08526 7.05372 9.4107ZM9.41074 7.05368C9.0853 7.37911 9.0853 7.90675 9.41074 8.23219C9.73618 8.55763 10.2638 8.55763 10.5892 8.23219C10.9147 7.90675 10.9147 7.37911 10.5892 7.05368C10.2638 6.72824 9.73618 6.72824 9.41074 7.05368ZM9.41074 11.7677C9.0853 12.0932 9.0853 12.6208 9.41074 12.9462C9.73618 13.2717 10.2638 13.2717 10.5892 12.9462C10.9147 12.6208 10.9147 12.0932 10.5892 11.7677C10.2638 11.4423 9.73618 11.4423 9.41074 11.7677ZM11.7678 9.4107C11.4423 9.73614 11.4423 10.2638 11.7678 10.5892C12.0932 10.9146 12.6208 10.9146 12.9463 10.5892C13.2717 10.2638 13.2717 9.73614 12.9463 9.4107C12.6208 9.08526 12.0932 9.08526 11.7678 9.4107Z"
      />
      <g opacity="0.3">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.76256 17.0122C7.14475 18.084 4.94358 17.9072 3.51819 16.4818C2.09281 15.0565 1.91604 12.8553 2.98787 11.2375L8.76256 17.0122Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.6482 3.57715C12.266 2.50532 14.4672 2.68209 15.8926 4.10747C17.3179 5.53286 17.4947 7.73403 16.4229 9.35184L10.6482 3.57715Z"
        />
      </g>
    </svg>
  );
};

export default Patch;
