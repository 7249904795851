import { FC } from 'react';

interface Props {
  prescriptionCode: string;
}

const Header: FC<Props> = ({ prescriptionCode }) => {
  return (
    <header className="flex justify-between align-center pb-6 border-solid border-x-0 border-t-0 border-b border-b-border-hover">
      <span className="text-content-muted text-sm">
        Nr. rețetei: <span className="text-content-default">{prescriptionCode}</span>
      </span>
      <div className="flex gap-1 text-sm font-medium">
        <span className="text-content-muted">Data prescrierii:</span>
        <div className="w-[60px] h-full border-solid border-x-0 border-t-0 border-b border-b-border-hover" />
      </div>
    </header>
  );
};

export default Header;
