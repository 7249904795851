import { SVGProps } from 'react';

const Question = (props: SVGProps<any>) => {
  return (
    <svg width="30" height="30" viewBox="0 0 43 42" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M37.574 2.71782C35.1312 0.682175 32.2419 -0.0926846 29.0899 0.0123811C28.0918 0.0780472 27.0674 0.0649139 26.0824 0.222512C19.5946 1.29944 15.2607 7.35385 16.2851 13.8548C16.8498 17.4664 18.7278 20.2113 21.7879 22.1287C25.1368 24.2432 28.8273 24.1906 32.5571 23.6522C32.9774 23.5865 33.24 23.6128 33.529 23.928C34.3432 24.7816 35.1706 25.6221 36.0505 26.4233C36.3001 26.6465 36.7466 26.791 37.0749 26.7516C37.5346 26.6991 37.5871 26.2394 37.5871 25.8191C37.574 24.4796 37.5609 23.1531 37.6003 21.8135C37.6134 21.5246 37.7579 21.17 37.9549 20.9861C40.2269 18.9242 41.6322 16.4158 41.9473 13.3426C42.3676 9.02176 40.8836 5.4758 37.574 2.71782ZM40.2269 13.6841C39.7672 16.2844 38.5327 18.3726 36.4971 20.0143C36.1162 20.3163 35.9717 20.6578 35.9849 21.1437C36.0111 22.0499 35.998 22.9692 35.998 23.8754C35.9455 23.9017 35.8798 23.928 35.8273 23.9411C35.3151 23.4026 34.7766 22.8904 34.2907 22.3257C33.8442 21.8135 33.3451 21.8004 32.7016 21.9186C29.9436 22.4308 27.1856 22.5621 24.4933 21.6034C20.028 20.0011 17.2438 15.4308 17.861 10.7422C18.4389 6.38199 21.2757 3.00675 25.6228 2.04803C29.0637 1.2863 32.5308 1.33884 35.6828 3.33508C39.0843 5.48893 40.923 9.73096 40.2269 13.6841Z" />
      <path d="M28.4202 26.2394C27.9211 26.2263 27.5928 26.6597 27.5928 27.3557C27.5928 28.9974 27.5928 30.639 27.5928 32.2807C27.5928 35.1437 25.8986 36.8379 23.0224 36.8379C20.3039 36.8379 17.5984 36.8379 14.8799 36.8379C14.0919 36.8379 13.8161 37.1268 13.8029 37.9017C13.7898 38.2957 13.8029 38.6897 13.8029 39.0837C13.7504 39.1231 13.6979 39.1494 13.6453 39.1888C13.0412 38.5715 12.4765 37.9148 11.8329 37.337C11.5571 37.0874 11.1369 36.8773 10.7692 36.8642C9.2457 36.8116 7.70912 36.7854 6.17253 36.851C3.69036 36.9561 1.58904 35.1437 1.62844 32.307C1.69411 28.367 1.69411 24.427 1.62844 20.4871C1.58904 17.7554 3.55902 15.9036 6.18567 15.943C8.72038 15.9824 11.2551 15.9561 13.8029 15.9561C14.5121 15.9561 14.8405 15.6934 14.8405 15.1681C14.8405 14.6428 14.499 14.3801 13.7898 14.3801C11.0975 14.3801 8.40518 14.3801 5.71287 14.3801C2.5609 14.3933 0.0261908 16.8754 -7.55768e-05 20.0143C-0.026342 24.2826 -0.026342 28.5509 -7.55768e-05 32.8192C0.0130576 35.8004 2.35077 38.2826 5.30574 38.4402C6.85546 38.5321 8.40518 38.4927 9.96803 38.4796C10.4408 38.4796 10.7823 38.5978 11.1238 38.9524C12.0299 39.898 12.9493 40.8435 13.9211 41.7235C14.1444 41.9336 14.6303 42.0912 14.893 41.9861C15.1425 41.8811 15.3132 41.4477 15.4183 41.1325C15.4971 40.8698 15.4446 40.5677 15.4446 40.2788C15.4446 39.701 15.4446 39.1231 15.4446 38.4927C15.8254 38.4927 16.0881 38.4927 16.3376 38.4927C18.8723 38.4796 21.4202 38.5452 23.9549 38.4402C26.726 38.322 29.0506 36.0105 29.1819 33.2394C29.2738 31.2694 29.2344 29.2995 29.2344 27.3295C29.2213 26.6334 28.9192 26.2525 28.4202 26.2394Z" />
      <path d="M22.3263 25.5302C19.5421 25.5302 16.7709 25.5302 13.9867 25.5302C11.1499 25.5302 8.30003 25.5302 5.46326 25.5302C4.91166 25.5302 4.50453 25.6746 4.47826 26.305C4.452 26.8566 4.78033 27.1193 5.48952 27.1193C11.1105 27.1193 16.7315 27.1193 22.3526 27.1193C23.0486 27.1193 23.3901 26.8566 23.3901 26.3181C23.377 25.7928 23.0355 25.5302 22.3263 25.5302Z" />
      <path d="M22.4446 30.1268C17.848 30.14 10.0206 30.1268 5.42399 30.14C5.21386 30.14 4.91179 30.1531 4.81986 30.2844C4.64913 30.5471 4.439 30.9674 4.54406 31.1775C4.66226 31.427 5.09566 31.6897 5.39772 31.6897C5.96245 31.7028 6.54031 31.7028 7.10504 31.716C8.81236 31.7291 10.5197 31.716 12.227 31.716C14.5516 31.716 20.0938 31.716 22.4184 31.716C23.0488 31.716 23.4033 31.427 23.3771 30.9148C23.3377 30.337 22.9962 30.1268 22.4446 30.1268Z" />
      <path d="M17.0075 20.9336C15.0769 20.9336 13.1594 20.9336 11.2288 20.9336C9.32452 20.9336 7.42021 20.9336 5.51589 20.9336C4.78043 20.9336 4.47837 21.17 4.46524 21.7085C4.4521 22.26 4.7673 22.5096 5.48963 22.5096C9.32452 22.5096 13.1463 22.5096 16.9812 22.5096C17.6904 22.5096 18.0318 22.2469 18.0318 21.7347C18.045 21.2094 17.7035 20.9336 17.0075 20.9336Z" />
      <path d="M29.4839 5.83044C27.5271 5.79104 25.9905 7.13062 25.8723 8.95614C25.8198 9.6916 26.0299 10.0331 26.5684 10.0462C27.2119 10.0725 27.4089 9.6916 27.4746 9.10061C27.5928 8.01055 28.6172 7.24882 29.6021 7.48522C30.5083 7.69535 31.0862 8.35201 31.1387 9.20567C31.1781 10.0199 30.6397 10.7554 29.7597 11.0575C28.7879 11.3989 28.5121 11.7666 28.499 12.7779C28.499 13.0012 28.499 13.2113 28.499 13.4346C28.499 13.8023 28.499 14.1832 28.499 14.5509C28.499 15.0631 28.8142 15.352 29.2738 15.352C29.7597 15.3651 30.0881 15.0631 30.1012 14.5246C30.1012 14.065 30.1275 13.6053 30.1012 13.1456C30.0749 12.7779 30.2194 12.6203 30.5609 12.489C32.0449 11.8848 32.7673 10.716 32.741 9.11374C32.7016 7.38016 31.2175 5.86984 29.4839 5.83044Z" />
      <path d="M29.3132 17.0067C28.8404 16.9804 28.4858 17.3482 28.5121 17.8341C28.5384 18.3331 28.8273 18.5695 29.3526 18.5827C29.8123 18.5433 30.1012 18.2937 30.1012 17.7947C30.0881 17.3088 29.7992 17.0461 29.3132 17.0067Z" />
    </svg>
  );
};

export default Question;
