import React, { FC, memo, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { CorBox, CorButton, CorText } from '@e-reteta/react-design-system';
import hash from 'object-hash';
import { T, useTranslate } from '@tolgee/react';

import { ButtonVariant, TextVariant } from '../../enums/design-system.enum';
import { ViewDeviceProps } from './interface';
import useWindowWidth from '../../hooks/use-window-width';
import Accordion from '../Accordion';
import { PrescriptionRelease } from '../../services/pharmacy.services';
import Trash from '../../icons/Trash';
import { Edit } from '../../icons';
import DispensingModal from '../DispensingModal';
import { DeviceOrDrug } from '../DeviceAndDrugDropdown/enum';
import Plus from '../../icons/Plus';
import { Dispense, PrescriptionReleaseData } from '../../types/CommonTypes';
import { AlertStatus } from '../Alert/enum';
import { Alert } from '../index';

const ViewDevice: FC<ViewDeviceProps> = ({
  onRefresh,
  name,
  quantity,
  signature,
  dispenses,
  prescribedQuantity,
  hasBorderBottom,
  dPrescriptionId,
  role,
  setIsDisabled,
  diagnosticsCode,
  products = [],
  expired,
  treatmentDuration,
}) => {
  const location = useLocation();
  const { isMobile } = useWindowWidth();
  const { t } = useTranslate();

  const [isOpenModal, setModalOpen] = useState<boolean>(false);
  const [localDispenses, setLocalDispenses] = useState<Dispense[]>([]);
  const [dispense, setDispense] = useState<Dispense>();
  const [countDispenses, setCountDispenses] = useState<number>(0);
  const [idDispense, setIdDispense] = useState<number>(1);
  const [filteredReleases, setFilteredReleases] = useState<PrescriptionRelease[]>([]);

  useEffect(() => {
    let count: number = 0;

    const filteredReleases =
      dispenses?.reduce((accum: PrescriptionRelease[], dispense: Dispense) => {
        return [
          ...accum,
          ...dispense.prescriptionReleases?.filter(
            (release) => release.dPrescriptionId === dPrescriptionId,
          ),
        ];
      }, []) || [];
    setFilteredReleases(filteredReleases);

    let prescriptionReleasesData = localStorage.getItem('prescriptionReleases');
    let prescriptionReleases = prescriptionReleasesData ? JSON.parse(prescriptionReleasesData) : [];

    if (filteredReleases && filteredReleases.length) {
      filteredReleases.forEach((item: PrescriptionRelease) => {
        if (item.dPrescriptionId === dPrescriptionId) {
          count += Number(item.quantity);
        }
      });
    }
    if (prescriptionReleases && prescriptionReleases.length) {
      prescriptionReleases.forEach((item: PrescriptionRelease) => {
        if (item.dPrescriptionId === dPrescriptionId) {
          count += Number(item.quantity);
        }
      });
    }
    setCountDispenses(count);
  }, [dispenses]);

  const saveDispenses = ({ values, product }: any) => {
    const currentCount = countDispenses + Number(values?.quantity);
    setModalOpen(false);
    setCountDispenses(currentCount);
    setIdDispense((prevState) => prevState + 1);

    let prescriptionReleasesData = localStorage.getItem('prescriptionReleases');
    let prescriptionReleases = prescriptionReleasesData ? JSON.parse(prescriptionReleasesData) : [];

    if (!prescriptionReleases) {
      prescriptionReleases = [];
    }
    prescriptionReleases = [
      ...prescriptionReleases,
      {
        ...values,
        id: `${idDispense}-${name}`,
        deviceCommercialCod: values.commercialCod,
        deviceCommercialName: values.commercialCod,
        externalId: product.externalId,
        dPrescriptionId,
      },
    ];
    setLocalDispenses(
      prescriptionReleases?.filter(
        (item: PrescriptionRelease) => item.dPrescriptionId === dPrescriptionId,
      ),
    );
    localStorage.setItem('prescriptionReleases', JSON.stringify(prescriptionReleases));
    if (prescriptionReleases.length > 0) {
      setIsDisabled(false);
    }
  };

  const editDispense = ({ values, product }: any) => {
    let prescriptionReleases: any = localStorage.getItem('prescriptionReleases');
    prescriptionReleases = JSON.parse(prescriptionReleases);
    const updatedDispensesLocalStorage = [...prescriptionReleases];

    let localSavedDispense = updatedDispensesLocalStorage.find(
      ({ id }: PrescriptionReleaseData) => id === values.id,
    );
    const currentCount =
      countDispenses - Number(localSavedDispense?.quantity) + Number(values?.quantity);

    if (localSavedDispense) {
      localSavedDispense.commercialCod = values.commercialCod;
      localSavedDispense.deviceCommercialCod = values.commercialCod;
      localSavedDispense.commercialName = values.commercialName;
      localSavedDispense.drugCommercialName = values.commercialName;
      localSavedDispense.quantity = values.quantity;
      localSavedDispense.internationalName = values.internationalName;
      localSavedDispense.additionalInfo = values.additionalInfo;
      localSavedDispense.retailPrice = values.retailPrice;
      localSavedDispense.compensatedAmount = values.compensatedAmount;
      localSavedDispense.amountToBePaid = values.amountToBePaid;
      localSavedDispense.externalId = product.externalId;
      localSavedDispense.status =
        Number(prescribedQuantity) == currentCount ? 'released' : 'partial_released';
      localStorage.setItem('prescriptionReleases', JSON.stringify(updatedDispensesLocalStorage));
      setLocalDispenses(
        updatedDispensesLocalStorage?.filter(
          (item: PrescriptionRelease) => item.dPrescriptionId === dPrescriptionId,
        ),
      );
    }
    setCountDispenses(currentCount);
    setModalOpen(false);
    setDispense(undefined);
  };

  const deleteDispense = (values: Dispense) => {
    let prescriptionReleasesData = localStorage.getItem('prescriptionReleases');
    let prescriptionReleases = prescriptionReleasesData ? JSON.parse(prescriptionReleasesData) : [];

    let filterDispense = prescriptionReleases.filter(
      ({ id }: PrescriptionRelease) => id != values.id,
    );
    const ids = filterDispense.map(({ id }: PrescriptionRelease) => id);
    const dispense = prescriptionReleases.filter(({ id }: PrescriptionRelease) => {
      if (id?.includes(name)) {
        return ids.includes(id);
      } else {
        return true;
      }
    });

    setCountDispenses((prevState) => Number(prevState) - Number(values?.quantity));
    localStorage.setItem('prescriptionReleases', JSON.stringify(dispense));
    setLocalDispenses(
      dispense?.filter((item: PrescriptionRelease) => item.dPrescriptionId === dPrescriptionId),
    );
    if (dispense.length === 0) {
      setIsDisabled(true);
    }
  };

  useEffect(() => {
    if (role === 'doctor') {
      return;
    }
    let prescriptionReleases: any = localStorage.getItem('prescriptionReleases');
    prescriptionReleases = JSON.parse(prescriptionReleases);
    prescriptionReleases = prescriptionReleases?.filter(
      (item: PrescriptionRelease) => item.dPrescriptionId === dPrescriptionId,
    );
    setLocalDispenses(prescriptionReleases);
    if (prescriptionReleases?.length > 0) {
      setIsDisabled(false);
    }
  }, []);

  const renderAlerts = (releasesCount: number) => {
    if (releasesCount === 1) {
      return (
        <Alert
          text={t(
            'viewDevice.secondVisitWarningText',
            'Va aflati la penultima vizita la farmacie pentru eliberarea retetei dumneavoastra.',
          )}
          status={AlertStatus.WARNING}
        />
      );
    }
    if (releasesCount === 2) {
      return (
        <Alert
          text={t(
            'viewDevice.lastVisitWarningText',
            'Va aflati la ultima vizita la farmacie pentru eliberarea retetei dumneavoastra.',
          )}
          status={AlertStatus.WARNING}
        />
      );
    }
    if (releasesCount === 3) {
      return (
        <Alert
          text={t('viewDevice.noVisitsLeft', 'Toate trei eliberari posibila au fost efectuate')}
          status={AlertStatus.WARNING}
        />
      );
    }
    return null;
  };

  const renderAddButton = () => {
    if (expired || !(role === 'pharmacy' && location?.pathname?.includes('dispenses'))) {
      return null;
    }

    const dispensesCount: Record<string, number> = {};
    dispenses?.forEach((dispense) => {
      const localMemo: Record<string, boolean> = {};
      dispense.prescriptionReleases?.forEach((release: PrescriptionRelease) => {
        if (localMemo[release.dPrescriptionId]) {
          return;
        }
        localMemo[release.dPrescriptionId] = true;
        if (!dispensesCount[release.dPrescriptionId]) {
          dispensesCount[release.dPrescriptionId] = 0;
        }
        dispensesCount[release.dPrescriptionId]++;
      });
    });

    if (dispensesCount[dPrescriptionId] > 2) {
      return renderAlerts(3);
    }

    if (Number(prescribedQuantity) - countDispenses > 0) {
      return (
        <>
          {renderAlerts(dispensesCount[dPrescriptionId])}
          <div
            className="flex w-full gap-2 rounded-2xl h-8 bg-decorative-surface-1 justify-center items-center cursor-pointer"
            onClick={() => setModalOpen(true)}
          >
            <CorText variant={TextVariant.LABEL_2_MEDIUM}>
              <span className="text-content-default">
                <T keyName="viewDevice.addDevice">Adaugă dispozitiv </T>
              </span>
            </CorText>
            <Plus width={16} height={16} color="currentColor" />
          </div>
        </>
      );
    }
  };

  const renderReleases = () => {
    if (filteredReleases?.length === 0) {
      return null;
    }
    return (
      <div className="pt-5 my-5 border-solid border-t border-0 border-border-inverted-selected">
        <div className="grid grid-cols-12">
          <CorText variant={TextVariant.LABEL_1_REGULAR} className="col-span-4">
            <span>
              <T keyName={'viewDevice.releasedDevice'}>Dispozitiv Eliberat</T>
            </span>
          </CorText>
          <div className="col-span-8 flex flex-col gap-2">
            {filteredReleases?.map((item: PrescriptionRelease) => (
              <div
                key={hash(item)}
                className="grid grid-cols-10 w-full p-4 bg-decorative-neutral-1 rounded-xl gap-2"
              >
                <CorText variant={TextVariant.LABEL_2_REGULAR} className="col-span-4">
                  <span className="text-content-muted">
                    <T keyName={'viewDevice.deviceName'}>Denumirea dispozitivului</T>
                  </span>
                </CorText>
                <CorText variant={TextVariant.LABEL_2_MEDIUM} className="col-span-6">
                  <span>{item.deviceCommercialName}</span>
                </CorText>
                <div className="col-span-10 border-image-circle" />
                <CorText variant={TextVariant.LABEL_2_REGULAR} className="col-span-4">
                  <span className="text-content-muted">
                    <T keyName={'viewDevice.releasedQuantity'}>Cantitatea eliberata</T>
                  </span>
                </CorText>
                <CorText variant={TextVariant.LABEL_2_MEDIUM} className="col-span-6">
                  <span>{item.quantity}</span>
                </CorText>
                <div className="col-span-10 border-image-circle" />
                <CorText variant={TextVariant.LABEL_2_REGULAR} className="col-span-4">
                  <span className="text-content-muted">
                    <T keyName={'viewDevice.additionalInfo'}>Informatii aditionale</T>
                  </span>
                </CorText>
                <CorText variant={TextVariant.LABEL_2_MEDIUM} className="col-span-6">
                  <span>{item.additionalInfo}</span>
                </CorText>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  return (
    <Accordion
      hasBorderBottom={hasBorderBottom}
      name={name}
      count={Number(prescribedQuantity) - countDispenses}
      quantity={prescribedQuantity}
      totallyDispensed={Number(prescribedQuantity) - countDispenses === 0}
      expired={expired}
    >
      <CorBox boxStyle="none" className="flex flex-col gap-5 md:px-12 md:pb-6 pt-2">
        <div className="flex flex-col 2md:grid 2md:grid-cols-12 gap-3">
          <div className="col-span-4">
            <CorText variant={isMobile ? TextVariant.LABEL_1_MEDIUM : TextVariant.LABEL_1_REGULAR}>
              <span className="text-content-default">
                <T keyName={'viewDevice.prescribedDevice'}>Dispozitivul prescris</T>
              </span>
            </CorText>
          </div>

          <div className="pl-2 2md:pl-0 border-0 border-l-[1px] md:border-0 border-solid border-l-border-default col-span-8 flex flex-col gap-3 2md:gap-2">
            <div className="flex flex-col sm:flex-row gap-x-2">
              <CorText
                variant={isMobile ? TextVariant.BODY_1_REGULAR : TextVariant.LABEL_2_REGULAR}
                className="col-span-4 w-[172px] flex-none"
              >
                <span className="text-content-muted">
                  <T keyName={'viewDevice.deviceName'}>Denumire dispozitiv</T>
                </span>
              </CorText>
              <CorText
                variant={isMobile ? TextVariant.BODY_1_MEDIUM : TextVariant.LABEL_2_MEDIUM}
                className="col-span-4"
              >
                <span className="text-content-default">{name}</span>
              </CorText>
            </div>
            <div className="border-image-circle" />
            <div className="flex flex-col sm:flex-row gap-x-2">
              <CorText
                variant={isMobile ? TextVariant.BODY_1_REGULAR : TextVariant.LABEL_2_REGULAR}
                className="col-span-4 w-[172px] flex-none"
              >
                <span className="text-content-muted">
                  <T keyName={'viewDevice.quantity'}>Cantitatea</T>
                </span>
              </CorText>
              <CorText
                variant={isMobile ? TextVariant.BODY_1_MEDIUM : TextVariant.LABEL_2_MEDIUM}
                className="col-span-4"
              >
                <span className="text-content-default">{quantity}</span>
              </CorText>
            </div>
            <div className="border-image-circle" />
            <div className="flex flex-col sm:flex-row gap-x-2">
              <CorText
                variant={isMobile ? TextVariant.BODY_1_REGULAR : TextVariant.LABEL_2_REGULAR}
                className="col-span-4 w-[172px] flex-none"
              >
                <span className="text-content-muted">
                  <T keyName="viewDevice.treatmentDuration">Durata Tratamentului</T>
                </span>
              </CorText>
              <CorText
                variant={isMobile ? TextVariant.BODY_1_MEDIUM : TextVariant.LABEL_2_MEDIUM}
                className="col-span-4"
              >
                <span className="text-content-default">{treatmentDuration}</span>
              </CorText>
            </div>
            <div className="border-image-circle" />
            <div className="flex sm:flex-col gap-3 2md:gap-2">
              <div className="flex-1 sm:flex-none flex flex-col sm:flex-row gap-x-2">
                <CorText
                  variant={isMobile ? TextVariant.BODY_1_REGULAR : TextVariant.LABEL_2_REGULAR}
                  className="col-span-4 w-[172px] flex-none"
                >
                  <span className="text-content-muted">
                    <T keyName={'viewDevice.signature'}>Signatura</T>
                  </span>
                </CorText>
                <CorText variant={TextVariant.LABEL_2_MEDIUM} className="col-span-4">
                  <span className="text-content-default">{signature}</span>
                </CorText>
              </div>
            </div>
          </div>
        </div>
        {renderReleases()}
        {localDispenses && localDispenses.length
          ? localDispenses.map((dispense) => {
              return (
                <div
                  key={hash(dispense)}
                  className="flex flex-col 2md:grid 2md:grid-cols-12 gap-3 sm:border sm:border-solid sm:border-border-default sm:rounded-2xl sm:px-4 sm:py-3.5"
                >
                  <CorText
                    variant={isMobile ? TextVariant.LABEL_1_MEDIUM : TextVariant.LABEL_1_REGULAR}
                    className="col-span-4"
                  >
                    <span className="text-content-default">
                      <T keyName={'viewDevice.deviceToBeReleased'}>Dispozitiv catre eliberare</T>
                    </span>
                  </CorText>
                  <div className="pl-2 2md:pl-0 border-0 border-l-[1px] md:border-0 border-solid border-l-border-default col-span-8 flex flex-col gap-3 2md:gap-2">
                    <div className="flex flex-col sm:flex-row gap-x-2">
                      <CorText
                        variant={
                          isMobile ? TextVariant.BODY_1_REGULAR : TextVariant.LABEL_2_REGULAR
                        }
                        className="col-span-4 w-[172px] flex-none"
                      >
                        <span className="text-content-muted">
                          <T keyName={'viewDevice.deviceToBeReleasedName'}>Denumire dispozitiv</T>
                        </span>
                      </CorText>
                      <CorText
                        variant={isMobile ? TextVariant.BODY_1_MEDIUM : TextVariant.LABEL_2_MEDIUM}
                        className="col-span-4"
                      >
                        <span className="text-content-default">{dispense.commercialName}</span>
                      </CorText>
                    </div>
                    <div className="border-image-circle" />
                    <div className="flex flex-col sm:flex-row gap-x-2">
                      <CorText
                        variant={
                          isMobile ? TextVariant.BODY_1_REGULAR : TextVariant.LABEL_2_REGULAR
                        }
                        className="col-span-4 w-[172px] flex-none"
                      >
                        <span className="text-content-muted">
                          <T keyName={'viewDevice.quantityToBeReleased'}>Cantitatea eliberata</T>
                        </span>
                      </CorText>
                      <CorText
                        variant={isMobile ? TextVariant.BODY_1_MEDIUM : TextVariant.LABEL_2_MEDIUM}
                        className="col-span-4"
                      >
                        <span className="text-content-default">{dispense.quantity}</span>
                      </CorText>
                    </div>
                    <div className="border-image-circle" />
                    <div className="flex flex-col sm:flex-row gap-x-2">
                      <CorText
                        variant={
                          isMobile ? TextVariant.BODY_1_REGULAR : TextVariant.LABEL_2_REGULAR
                        }
                        className="col-span-4 w-[172px] flex-none"
                      >
                        <span className="text-content-muted">
                          <T keyName={'viewDevice.additionalInfo'}>Informatii aditionale</T>
                        </span>
                      </CorText>
                      <CorText
                        variant={isMobile ? TextVariant.BODY_1_MEDIUM : TextVariant.LABEL_2_MEDIUM}
                        className="col-span-4"
                      >
                        <span className="text-content-default">{dispense.additionalInfo}</span>
                      </CorText>
                    </div>
                    <div className="flex flex-col sm:flex-row gap-x-2">
                      <CorText
                        variant={
                          isMobile ? TextVariant.BODY_1_REGULAR : TextVariant.LABEL_2_REGULAR
                        }
                        className="col-span-4 w-[172px] flex-none"
                      >
                        <span className="text-content-muted">
                          <T keyName={'viewDevice.retailPrice'}>Pretul cu amanuntul</T>
                        </span>
                      </CorText>
                      <CorText
                        variant={isMobile ? TextVariant.BODY_1_MEDIUM : TextVariant.LABEL_2_MEDIUM}
                        className="col-span-4"
                      >
                        <span className="text-content-default">{dispense.retailPrice}</span>
                      </CorText>
                    </div>
                    <div className="border-image-circle" />
                    <div className="flex flex-col sm:flex-row gap-x-2">
                      <CorText
                        variant={
                          isMobile ? TextVariant.BODY_1_REGULAR : TextVariant.LABEL_2_REGULAR
                        }
                        className="col-span-4 w-[172px] flex-none"
                      >
                        <span className="text-content-muted">
                          <T keyName={'viewDevice.compensatedAmount'}>Suma compensata</T>
                        </span>
                      </CorText>
                      <CorText
                        variant={isMobile ? TextVariant.BODY_1_MEDIUM : TextVariant.LABEL_2_MEDIUM}
                        className="col-span-4"
                      >
                        <span className="text-content-default">{dispense.compensatedAmount}</span>
                      </CorText>
                    </div>
                    <div className="border-image-circle" />
                    <div className="flex flex-col sm:flex-row gap-x-2">
                      <CorText
                        variant={
                          isMobile ? TextVariant.BODY_1_REGULAR : TextVariant.LABEL_2_REGULAR
                        }
                        className="col-span-4 w-[172px] flex-none"
                      >
                        <span className="text-content-muted">
                          <T keyName={'viewDevice.amountToBePaid'}>Suma Co-plata Pacient</T>
                        </span>
                      </CorText>
                      <CorText
                        variant={isMobile ? TextVariant.BODY_1_MEDIUM : TextVariant.LABEL_2_MEDIUM}
                        className="col-span-4"
                      >
                        <span className="text-content-default">{dispense.amountToBePaid}</span>
                      </CorText>
                    </div>
                  </div>
                  <div className="flex items-center justify-end gap-2 sm:col-span-12">
                    <CorButton variant={ButtonVariant.SECONDARY}>
                      <button
                        className="flex items-center justify-center gap-2 w-fit h-10 text-action-primary-default"
                        onClick={() => deleteDispense(dispense)}
                      >
                        <CorText variant={TextVariant.LABEL_2_MEDIUM}>
                          <span className="flex gap-2 items-center justify-center">
                            <T keyName={'viewDevice.deleteButton'}>Sterge</T>
                            <Trash width={16} height={16} />
                          </span>
                        </CorText>
                      </button>
                    </CorButton>
                    <CorButton>
                      <button
                        className="flex items-center justify-center gap-2 w-fit h-10"
                        onClick={() => {
                          setDispense(dispense);
                          setModalOpen(true);
                        }}
                      >
                        <CorText variant={TextVariant.LABEL_2_MEDIUM}>
                          <span className="flex gap-2 items-center justify-center">
                            <T keyName={'viewDevice.editButton'}>Editeaza</T>
                            <Edit width={16} height={16} color="currentColor" />
                          </span>
                        </CorText>
                      </button>
                    </CorButton>
                  </div>
                </div>
              );
            })
          : null}
        {renderAddButton()}
        {role == 'pharmacy' && isOpenModal ? (
          <DispensingModal
            onRefresh={onRefresh}
            products={products}
            isOpen={isOpenModal}
            onAdd={(values: PrescriptionReleaseData) => {
              saveDispenses(values);
            }}
            onEdit={(values: PrescriptionReleaseData) => {
              editDispense(values);
            }}
            onCancel={() => {
              setModalOpen(false);
              setDispense(undefined);
            }}
            type={DeviceOrDrug.DEVICE}
            quantity={
              dispense
                ? Number(prescribedQuantity) - countDispenses + Number(dispense.quantity)
                : Number(prescribedQuantity) - countDispenses
            }
            deviceName={name}
            dispense={dispense}
            diagnosticsCode={diagnosticsCode}
          />
        ) : null}
      </CorBox>
    </Accordion>
  );
};

export default memo(ViewDevice);
