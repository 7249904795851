import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CorBox, CorIcon, CorText } from '@e-reteta/react-design-system';
import { T } from '@tolgee/react';

import { PatientAssignmentPageProps } from './interface';
import PatientAssignment from '../PatientAssignment';
import { IconName, IconSize } from '../../enums/design-system-icons.enum';
import { TextVariant } from '../../enums/design-system.enum';
import { PrescriptionServices } from '../../services/prescription.services';
import { HolographPrescription } from '../../types/HolographPrescription';
import { InstitutionDto, InstitutionService } from '../../services/institution.service';
import PrescribingDoctor from '../PrescribedDoctor';

const PatientAssignmentPage: FC<PatientAssignmentPageProps> = (props) => {
  const { idPrescription } = props;
  const [hPrescription, setHPrescription] = useState<HolographPrescription>();
  const [institution, setInstitution] = useState<InstitutionDto>();

  const getMedicalUnitName = async () => {
    if (!hPrescription) {
      return;
    }
    const inst = await InstitutionService.getAll('doctor', {
      codIms: hPrescription.doctor.medicalUnit,
    });
    if (inst.data.items?.length) {
      setInstitution(inst.data.items[0]);
    }
  };

  const getHPrescription = async () => {
    const resp = await PrescriptionServices.getHolographPrescriptionById(
      'pharmacy',
      idPrescription,
    );
    setHPrescription(resp.data);
  };

  useEffect(() => {
    if (hPrescription) {
      return;
    }

    getHPrescription();
  }, [idPrescription, hPrescription]);

  useEffect(() => {
    getMedicalUnitName();
  }, [hPrescription]);

  const navigate = useNavigate();

  const goBack = () => {
    navigate(`/pharmacy/search/`);
  };

  return (
    <CorBox boxStyle="none" className="flex flex-col h-full gap-4 rounded-3xl">
      <div className="flex flex-row items-center gap-2 h-8 sm:pt-4 lg:pt-0">
        <CorIcon
          size={IconSize.MD}
          name={IconName.ARROW_LEFT}
          className="cursor-pointer"
          onClick={goBack}
        />
        <CorText variant={TextVariant.HEADING_2_BOLD}>
          <h3>
            <T keyName="patientAssignmentPage.prescriptionId">ID Reteta:</T> {idPrescription}
          </h3>
        </CorText>
      </div>
      <div className="rounded-3xl bg-white w-full px-6 py-5">
        <PrescribingDoctor doctor={hPrescription?.doctor} institution={institution} />
      </div>
      <PatientAssignment id={idPrescription} />
    </CorBox>
  );
};

export default PatientAssignmentPage;
