import React from 'react';
import { useFormikContext } from 'formik';
import { useTranslate } from '@tolgee/react';

import { Annex1Props } from './annex3.interface';
import GroupByContainer from '../GroupBy';

const Annex3 = (props: Annex1Props) => {
  const { report } = props;
  const { t } = useTranslate();
  const formik = useFormikContext<any>();

  const handleGroupByInstitution = (fields: string[]) => {
    const groupBy: Record<string, '0' | '1'> = {};
    groupByFields.forEach((field) => {
      groupBy[field] = fields.indexOf(field) === -1 ? '0' : '1';
    });
    formik.setFieldValue(`${report}GroupBy`, groupBy);
  };

  let groupByFields = [
    'internationalName',
    'commercialName',
    'commercialCode',
    'dose',
    'formName',
    'producerName',
  ];

  if (report === 'annex3ForDevices') {
    groupByFields = [
      'producerName',
      'internationalName',
      'internationalCode',
      'commercialName',
      'commercialCode',
    ];
  }

  return (
    <>
      <GroupByContainer
        name={t('annex3.groupBy', 'Grupeaza dupa')}
        fields={groupByFields.map((field) => {
          return {
            label: t(`annex3.${field}`, field),
            value: field,
          };
        })}
        preSelected={groupByFields}
        onChange={handleGroupByInstitution}
      />
      <div className="w-full border-0 border-t border-solid border-border-disabled mx-4 my-6" />
    </>
  );
};

export default Annex3;
