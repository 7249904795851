import { MutableRefObject, useEffect, useRef } from 'react';

interface Props {
  disabled?: boolean;
  withGrabCursor?: boolean;
  withGrabbingCursor?: boolean;
  onX?: boolean;
  onY?: boolean;
}

export const useDragScroll = <T extends HTMLElement>({
  disabled = false,
  withGrabCursor = true,
  withGrabbingCursor = true,
  onX = true,
  onY = true,
}: Props = {}): MutableRefObject<T> => {
  const ref: MutableRefObject<T> = useRef<T>({} as T);

  useEffect(() => {
    if (disabled) {
      return;
    }

    const element = ref.current;

    let lastX = 0;
    let lastY = 0;
    let isDragging = false;

    const handleMouseDown = (event: MouseEvent) => {
      lastX = event.clientX;
      lastY = event.clientY;
      isDragging = true;

      if (withGrabCursor) {
        element.classList.remove('cursor-grab');
      }

      if (withGrabbingCursor) {
        document.documentElement.classList.add('cursor-grabbing', 'select-none');
      }
    };

    const handleMouseMove = (event: MouseEvent) => {
      if (!isDragging) {
        return;
      }

      const opts: ScrollToOptions = {
        behavior: 'auto',
      };

      if (onX) {
        const x = event.clientX;
        opts.left = lastX - x;
        lastX = x;
      }

      if (onY) {
        const y = event.clientY;
        opts.top = lastY - y;
        lastY = y;
      }

      element.scrollBy(opts);
    };

    const handleMouseUp = () => {
      if (!isDragging) {
        return;
      }

      isDragging = false;

      if (withGrabbingCursor) {
        document.documentElement.classList.remove('cursor-grabbing', 'select-none');
      }

      if (withGrabCursor) {
        element.classList.add('cursor-grab');
      }
    };

    element.addEventListener('mousedown', handleMouseDown);
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);

    return () => {
      element.removeEventListener('mousedown', handleMouseDown);
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [disabled]);

  return ref;
};
