import { FC, memo } from 'react';
import { CorIcon, CorText } from '@e-reteta/react-design-system';

import type { FilterChipProps } from './interface';
import { IconColor, IconName, IconSize } from '../../enums/design-system-icons.enum';
import { TextVariant } from '../../enums/design-system.enum';

const FilterChip: FC<FilterChipProps> = ({ text, onClose }) => {
  return (
    <div
      className="inline-flex flex-row justify-center items-center gap-1 border-solid border-surface-neutral-inverted-hover border
      rounded-[20px] px-4 py-2.5 overflow-hidden max-w-[200px] w-fit bg-surface-default h-10"
    >
      <CorText
        variant={TextVariant.LABEL_2_MEDIUM}
        className="text-ellipsis whitespace-nowrap overflow-hidden"
      >
        <span>{text}</span>
      </CorText>
      <button
        onClick={() => onClose()}
        className="bg-transparent border-none outline-none cursor-pointer p-0 bg-red"
      >
        <CorIcon name={IconName.CLOSE} size={IconSize.MD} color={IconColor.CONTENT_DEFAULT} />
      </button>
    </div>
  );
};

export default memo(FilterChip);
