import React, { FC } from 'react';
import { toast } from 'react-toastify';

import * as icons from '../../icons';
import { useTranslate } from '@tolgee/react';

interface Props {
  textToCopy: string;
}

const Copy: FC<Props> = ({ textToCopy }) => {
  const { t } = useTranslate();

  const successMessage = t('copy.successMessage', 'Copiat cu success!');
  const failMessage = t('copy.failMessage', 'Copierea a esuat');

  const copyToClipboard = async () => {
    if (!(window.isSecureContext && navigator.clipboard)) {
      toast.error(`This feature requires a secured connection (HTTPS)`);
      return;
    }

    try {
      await navigator.clipboard?.writeText(textToCopy);
      toast.success(successMessage);
    } catch (error) {
      toast.error(`${failMessage} : ${error}`);
    }
  };

  return (
    <div className="absolute top-0 right-0 hover:cursor-pointer" onClick={copyToClipboard}>
      <icons.CopyIcon></icons.CopyIcon>
    </div>
  );
};

export default Copy;
