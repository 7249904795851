import { ChangeEvent, FC, memo, useCallback, useContext, useEffect, useState } from 'react';
import { CorButton, CorDropdown, CorIcon, CorInput, CorText } from '@e-reteta/react-design-system';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';
import { T, useTranslate } from '@tolgee/react';
import hash from 'object-hash';

import type { DispensingModalProps } from './interface';
import { ButtonVariant, TextVariant } from '../../enums/design-system.enum';
import { CorDropdownOption } from '../index';
import Check from '../../icons/Check';
import Close from '../../icons/Close';
import useWindowWidth from '../../hooks/use-window-width';
import Modal from '../Modal';
import { DeviceOrDrug } from '../DeviceAndDrugDropdown/enum';
import InputWithAmount from '../InputWithAmount';
import { AuthContext } from '../../context/context';
import { Pharmacy, PharmacyService } from '../../services/pharmacy.services';
import { checkIfHave18 } from '../../utils/check-18.utils';
import { CompensatedStatusTypesEnum } from '../../enums/CompensatedStatusTypesEnum';
import { handleError } from '../../utils/handleRequestErrors';
import { isAxiosError } from 'axios';
import disableScrollOnInput from '../../utils/disableScrollOnInput';
import { renderResponsiveClasses } from '../../utils/render-responsive-classes.utils';
import { Product } from '../../types/product.interface';
import { Patient } from '../../types/Patient';
import Alert from '../Alert';
import { AlertStatus } from '../Alert/enum';
import RetailInputPrice from '../InputWithAmount/RetailInputPrice';
import { formatDate } from '../../utils/formatDate';
import dayjs from 'dayjs';
import { IconName } from '../../enums/design-system-icons.enum';
import { notIntegerSymbols } from '../../constants/not-integer';

const DispensingModal: FC<DispensingModalProps> = ({
  isOpen,
  onRefresh,
  onAdd,
  onEdit,
  onCancel,
  type,
  deviceName,
  internationalName,
  quantity,
  routeOfAdministration,
  dose,
  dispense,
  diagnosticsCode,
  products = [],
}) => {
  const [maxQuantity, setMaxQuantity] = useState(0);
  // const [division, setDivision] = useState(0);

  const drugs = products.filter((product) => {
    return (
      product.productType === 'drug' &&
      product.dose === dose &&
      product.internationalName === internationalName &&
      product.routeOfAdministration === routeOfAdministration &&
      product.cimCodes?.includes(diagnosticsCode)
    );
  });

  const devices = products.filter((product) => {
    return (
      product.productType === 'device' &&
      product.name === deviceName &&
      product.cimCodes?.includes(diagnosticsCode)
    );
  });
  const { user } = useContext(AuthContext);
  const { isMobile, isTablet } = useWindowWidth();
  const { idnp } = useParams();
  const [patient, setPatient] = useState<Patient>();
  const [pharmacy, setPharmacy] = useState<Pharmacy>();
  const [currentProduct, setCurrentProduct] = useState<Product>();
  const { t } = useTranslate();

  const getLabel = (item: Product) => {
    const attr: string[] = [item.commercialName!];
    if (item.division) {
      attr.push(item.division);
    }
    if (item.series) {
      attr.push(item.series);
    }
    if (item.expirationDate) {
      attr.push(formatDate(dayjs.unix(item.expirationDate).toISOString()));
    }
    if (item.price) {
      attr.push(item.price.toFixed(2));
    }
    if (item.producer?.name) {
      attr.push(item.producer.name);
    }
    if (item.country?.name) {
      attr.push(item.country.name);
    }

    return attr.join(', ');
  };
  const setName = (itemString: string) => {
    const item: Product = JSON.parse(itemString);
    formik.setFieldValue('commercialName', item?.commercialName);
    formik.setFieldValue('externalId', item?.externalId);
    formik.setFieldValue('commercialCod', item?.commercialCod);
  };

  const checkRouteOfAdministration = () => {
    if (routeOfAdministration) {
      return !['perorale solide', 'perorale solide cu cedare prelungită'].includes(
        routeOfAdministration,
      );
    } else {
      return true;
    }
  };

  const getQuantityValidationSchema = () => {
    if (quantity) {
      if (checkRouteOfAdministration()) {
        return Number(quantity);
      } else {
        return Number(quantity + 10);
      }
    } else {
      return 1;
    }
  };
  let patternTwoDigitsAfterComma = /^\d+(\.\d{0,2})?$/;
  const formik = useFormik({
    initialValues: {
      id: dispense?.id ? dispense.id : '',
      externalId: dispense?.externalId ? dispense.externalId : '',
      commercialName: dispense?.commercialName ? dispense.commercialName : '',
      commercialCod: dispense?.commercialCod ? dispense.commercialCod : '',
      quantity: dispense?.quantity ? dispense.quantity : '',
      additionalInfo: dispense ? dispense.additionalInfo : '',
      retailPrice: dispense?.retailPrice ? dispense?.retailPrice : '',
      compensatedAmount: dispense?.compensatedAmount ? dispense?.compensatedAmount : 0,
      amountToBePaid: dispense?.amountToBePaid ? dispense?.amountToBePaid : 0,
    },
    onSubmit: (values) => {
      formik.resetForm();
      dispense
        ? onEdit({ values, product: currentProduct })
        : onAdd({ values, product: currentProduct });
    },
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnMount: true,
    validationSchema: Yup.object({
      commercialCod: Yup.string().required(
        t('dispensingModal.nameRequiredError', 'Denumirea comerciala este obligatorie'),
      ),
      quantity: Yup.number()
        .positive(t('dispensingModal.noZeroError', 'Cantitatea eliberata nu poate fi 0'))
        .test(
          'max-allowed',
          t(
            'dispensingModal.quantityExceedsAvailableError',
            `Cantitatea este mai mare ca cea disponibila`,
            {},
          ),
          (val: any) => {
            if (!maxQuantity) {
              return true;
            }
            return !(!!maxQuantity && val > maxQuantity);
          },
        )
        // .test(
        //   'min-allowed',
        //   t(
        //     'dispensingModal.minNotAllowedError',
        //     `Cantitatea nu este mai mica decit cantitatea minima permisa`,
        //     {},
        //   ),
        //   (val: any) => {
        //     if (!division) {
        //       return true;
        //     }
        //     return !(val < division);
        //   },
        // )
        // .test(
        //   'division-allowed',
        //   t(
        //     'dispensingModal.divisionNotAllowedError',
        //     `Cantitatea nu este divizibila cu cantitatea minima`,
        //     {},
        //   ),
        //   (val: any) => {
        //     return !(val % division);
        //   },
        // )
        .min(1, t('dispensingModal.noZeroError', 'Cantitatea eliberata nu poate fi 0'))
        .max(
          getQuantityValidationSchema(),
          t(
            checkRouteOfAdministration()
              ? 'dispensingModal.maxQuantityErrorDevice'
              : 'dispensingModal.maxQuantityErrorDrug',
            `Cantitatea eliberata nu poate fi mai mare ca cantitatea prescrisa ${
              checkRouteOfAdministration() ? '' : ' +10'
            }`,
            { plusMaxQuantity: checkRouteOfAdministration() ? '' : '+10' },
          ),
        )
        .required(
          t('dispensingModal.quantityIsMandatoryError', 'Cantitatea eliberata este obligatorie'),
        ),
      retailPrice: Yup.number()
        .required(t('dispensingModal.priceIsMandatoryError', 'Pretul obligator'))
        .min(0.001, t('dispensingModal.noZeroError', 'Cantitatea eliberata nu poate fi 0', {}))
        .test(
          'is-decimal',
          t(
            'dispensingModal.maxDecimalsError',
            `Suma trebuie să fie o zecimală cu maximum două cifre după virgulă.`,
            {},
          ),
          (val: any) => {
            if (val != undefined) {
              return patternTwoDigitsAfterComma.test(val);
            }
            return true;
          },
        )
        .test({
          name: 'max',
          exclusive: false,
          params: {},
          message: () =>
            t(
              'dispensingModal.maxPriceError',
              `Pretul introdus este mai mare decât prețul maxim permis în farmacii`,
              {},
            ),
          test: function (value) {
            const maxPrice =
              (currentProduct?.productType === 'device'
                ? currentProduct?.maximRetailPriceWithVAT
                : currentProduct?.coPayment) || 0;
            if (!maxPrice) {
              return true;
            }
            const bigTotal = this.parent.quantity * maxPrice;
            return value <= Number(bigTotal.toFixed(2));
          },
        }),
      compensatedAmount: Yup.number()
        .required(
          t('dispensingModal.compensatedPriceIsMandatoryError', 'Pretul compensat este obligator'),
        )
        .min(
          0.001,
          t('dispensingModal.noZeroCompensatedError', 'Suma compensata nu poate fi 0', {}),
        ),
    }),
  });

  useEffect(() => {
    const product = devices.concat(drugs).find((d) => {
      return formik.values.externalId
        ? d.externalId === formik.values.externalId
        : d.commercialCod?.toString() === formik.values.commercialCod?.toString();
    });
    setCurrentProduct(product);
    if (product?.pricePerUnit) {
      const retailPrice = product?.pricePerUnit * parseFloat(formik.values.quantity!);
      if (isNaN(retailPrice)) {
        formik.setFieldValue('retailPrice', '0');
        return;
      }
      formik.setFieldValue('retailPrice', retailPrice?.toFixed(2));
    }
  }, [formik.values.commercialCod, formik.values.quantity, formik.values.externalId]);

  useEffect(() => {
    if (!formik.values.externalId) {
      return;
    }
    if (currentProduct?.availableUnits) {
      setMaxQuantity(currentProduct?.availableUnits);
    }
    // if (currentProduct?.minQuantity) {
    //   setDivision(currentProduct?.minQuantity);
    // }
    let toDispense = quantity;

    if (currentProduct?.minQuantity) {
      toDispense = Math.ceil(quantity / currentProduct.minQuantity) * currentProduct.minQuantity;
    }

    if (currentProduct?.availableUnits && currentProduct?.availableUnits < quantity) {
      toDispense = currentProduct?.availableUnits;
    }

    formik.setFieldValue('quantity', toDispense);
  }, [currentProduct, quantity, formik.values.externalId]);

  const changeQuantity = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const value = e.target.value ? parseFloat(e.target.value) : undefined;
    formik.setFieldValue('quantity', value);
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement> | undefined) => {
    formik.validateForm(formik.values);
    formik.handleSubmit(e);
    if (!formik.values.quantity) {
      formik.setFieldTouched('quantity', true);
    }
    if (!formik.values.retailPrice) {
      formik.setFieldTouched('retailPrice', true);
    }
    if (!formik.values.retailPrice) {
      formik.setFieldTouched('commercialCod', true);
    }
  };

  useEffect(() => {
    const buttons = document.querySelectorAll('cor-button button');

    const handleKeyPress = (event: any) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        const button = buttons[3] as HTMLButtonElement | null;
        button?.click();
      }
    };

    document.addEventListener('keypress', handleKeyPress);

    return () => {
      document.removeEventListener('keypress', handleKeyPress);
    };
  }, [isOpen]);

  const getPharmacy = useCallback(async () => {
    try {
      const company = user?.activeCompany;
      if (company?.code) {
        const { data } = await PharmacyService.getPharmacy(company?.code);
        setPharmacy(data);
      }
    } catch (error) {
      isAxiosError(error) && handleError(error);
    }
  }, [user?.activeCompany]);

  useEffect(() => {
    getPharmacy();
    getPatient();
  }, []);

  const getPatient = useCallback(async () => {
    try {
      if (idnp) {
        const { data } = await PharmacyService.getPatient({ basicOnly: true, idnp });
        setPatient(data);
      }
    } catch (error) {
      isAxiosError(error) && handleError(error);
    }
  }, [idnp]);

  const calculatePrice = useCallback(async () => {
    if (
      !formik?.values?.quantity ||
      !formik?.values?.retailPrice ||
      isNaN(formik?.values?.retailPrice)
    ) {
      return;
    }

    const compensatedAmount = pharmacy?.vatPayer
      ? currentProduct?.compensatedSumWithVAT! || currentProduct?.compensatedSumWithVATPerUnit!
      : currentProduct?.compensatedSumWithoutVAT! ||
        currentProduct?.compensatedSumWithoutVATPerUnit!;
    const retailPrice = parseFloat(formik?.values?.retailPrice!);

    let compensatedStatus = currentProduct?.compensatedStatus;
    if (currentProduct?.productType === 'drug') {
      compensatedStatus = checkIfHave18(new Date(patient?.birthday!))
        ? currentProduct?.compensatedStatusAdult
        : currentProduct?.compensatedStatusChildren;
    }

    if (compensatedStatus === CompensatedStatusTypesEnum.FULLY_COMPENSATED) {
      formik.setFieldValue('compensatedAmount', retailPrice?.toFixed(2));
      formik.setFieldValue('amountToBePaid', 0);
    } else if (compensatedStatus === CompensatedStatusTypesEnum.PARTIALLY_COMPENSATED) {
      const amount = compensatedAmount * parseFloat(formik?.values?.quantity!);
      const amountToBePaidReal = retailPrice - amount;
      formik.setFieldValue(
        'compensatedAmount',
        (amount < retailPrice ? amount : retailPrice)?.toFixed(2),
      );
      formik.setFieldValue(
        'amountToBePaid',
        (amountToBePaidReal < 0 ? 0 : amountToBePaidReal)?.toFixed(2),
      );
    } else {
      formik.setFieldValue('compensatedAmount', 0);
      formik.setFieldValue('amountToBePaid', retailPrice?.toFixed(2));
    }
  }, [
    currentProduct?.compensatedStatus,
    currentProduct?.compensatedSumWithVATPerUnit,
    currentProduct?.compensatedSumWithoutVATPerUnit,
    formik,
    patient,
    pharmacy,
    currentProduct?.compensatedStatusAdult,
    currentProduct?.compensatedStatusChildren,
  ]);

  useEffect(() => {
    if (patient && pharmacy && currentProduct) {
      calculatePrice();
    }
  }, [currentProduct, formik.values.quantity, formik.values.retailPrice, patient, pharmacy]);

  const getWarningMessage = () => {
    const product = devices.concat(drugs).find((d) => {
      return d.commercialCod?.toString() === formik.values.commercialCod?.toString();
    });
    if (DeviceOrDrug.DRUG === type) {
      return product?.warningsDC;
    }
    if (DeviceOrDrug.DEVICE === type) {
      return product?.warningMessage;
    }
  };

  const isSelected = (item: Product) => {
    if (!formik.values.externalId) {
      return formik.values.commercialCod?.toString() === item.commercialCod?.toString();
    }
    return (
      formik.values.commercialCod?.toString() === item.commercialCod?.toString() &&
      formik.values.externalId === item.externalId
    );
  };

  return isOpen ? (
    <Modal>
      <div className="absolute inset-0 w-screen h-screen max-w-screen max-h-screen flex items-center justify-center z-50 bg-modal-background">
        <form
          onSubmit={onSubmit}
          className="flex flex-col bg-white mx-4 px-4 sm:px-8 w-full max-w-[1000px] rounded-3xl"
        >
          <header className="pt-5 sm:pt-7 pb-2 sm:pb-4 flex items-center justify-between">
            <CorText
              variant={renderResponsiveClasses(
                isMobile,
                isTablet,
                TextVariant.HEADING_4_BOLD,
                TextVariant.HEADING_3_BOLD,
                TextVariant.HEADING_2_BOLD,
              )}
            >
              <span>
                <T keyName={'dispensingModal.addText'}>Adaugare</T>
                {type === DeviceOrDrug.DRUG
                  ? t('dispensingModal.drugText', type)
                  : t('dispensingModal.deviceText', type)}
              </span>
            </CorText>
            <CorIcon name={IconName.REFRESH} className="cursor-pointer" onClick={onRefresh} />
          </header>
          <main className="flex flex-col gap-4 sm:gap-8 pt-2 sm:pt-4 pb-3 sm:pb-6">
            <div className="flex flex-col border-0 border-b border-solid border-b-border-default">
              <CorText variant={isMobile ? TextVariant.LABEL_2_MEDIUM : TextVariant.LABEL_1_MEDIUM}>
                <span>
                  {type === DeviceOrDrug.DRUG
                    ? t('dispensingModal.drugText', type)
                    : t('dispensingModal.deviceText', type)}
                  <T keyName={'dispensingModal.prescribedText'}>Prescris</T>
                </span>
              </CorText>
              <div className="flex flex-col justify-between py-3 gap-2">
                {type === DeviceOrDrug.DEVICE ? (
                  <div className="grid grid-cols-12 gap-x-8">
                    <div className="col-span-9 flex gap-2">
                      <CorText
                        variant={
                          isMobile ? TextVariant.BODY_1_REGULAR : TextVariant.LABEL_2_REGULAR
                        }
                      >
                        <span className="text-content-muted whitespace-nowrap">
                          <T keyName={'dispensingModal.deviceName'}>Denumire dispozitiv</T>:
                        </span>
                      </CorText>
                      <CorText
                        variant={
                          isMobile ? TextVariant.BODY_1_REGULAR : TextVariant.LABEL_2_REGULAR
                        }
                      >
                        <span>{deviceName}</span>
                      </CorText>
                    </div>
                    <div className="col-span-3 flex gap-2 items-end">
                      <CorText
                        variant={
                          isMobile ? TextVariant.BODY_1_REGULAR : TextVariant.LABEL_2_REGULAR
                        }
                      >
                        <span className="text-content-muted">
                          <T keyName={'dispensingModal.quantity'}>Cantitatea</T>:
                        </span>
                      </CorText>
                      <CorText
                        variant={
                          isMobile ? TextVariant.BODY_1_REGULAR : TextVariant.LABEL_2_REGULAR
                        }
                      >
                        <span>{quantity}</span>
                      </CorText>
                    </div>
                  </div>
                ) : null}
                {type === DeviceOrDrug.DRUG ? (
                  <div className="grid grid-cols-12 gap-x-8">
                    <div className="col-span-9 flex gap-2">
                      <CorText
                        variant={
                          isMobile ? TextVariant.BODY_1_REGULAR : TextVariant.LABEL_2_REGULAR
                        }
                      >
                        <span className="text-content-muted">
                          <T keyName={'dispensingModal.activeSubstance'}>Substanta activa</T>:
                        </span>
                      </CorText>
                      <CorText
                        variant={
                          isMobile ? TextVariant.BODY_1_REGULAR : TextVariant.LABEL_2_REGULAR
                        }
                      >
                        <span>{internationalName}</span>
                      </CorText>
                    </div>
                    <div className="col-span-3 flex gap-2">
                      <CorText
                        variant={
                          isMobile ? TextVariant.BODY_1_REGULAR : TextVariant.LABEL_2_REGULAR
                        }
                      >
                        <span className="text-content-muted">
                          <T keyName={'dispensingModal.dosage'}>Doza</T>:
                        </span>
                      </CorText>
                      <CorText
                        variant={
                          isMobile ? TextVariant.BODY_1_REGULAR : TextVariant.LABEL_2_REGULAR
                        }
                      >
                        <span>{dose}</span>
                      </CorText>
                    </div>
                    <div className="col-span-9 flex gap-2">
                      <CorText
                        variant={
                          isMobile ? TextVariant.BODY_1_REGULAR : TextVariant.LABEL_2_REGULAR
                        }
                      >
                        <span className="text-content-muted whitespace-nowrap">
                          <T keyName={'dispensingModal.administrationMode'}>Mod de administrare</T>:
                        </span>
                      </CorText>
                      <CorText
                        variant={
                          isMobile ? TextVariant.BODY_1_REGULAR : TextVariant.LABEL_2_REGULAR
                        }
                      >
                        <span>{routeOfAdministration}</span>
                      </CorText>
                    </div>
                    <div className="col-span-3 flex gap-2 items-end">
                      <CorText
                        variant={
                          isMobile ? TextVariant.BODY_1_REGULAR : TextVariant.LABEL_2_REGULAR
                        }
                      >
                        <span className="text-content-muted">
                          <T keyName={'dispensingModal.quantity'}>Cantitatea</T>:
                        </span>
                      </CorText>
                      <CorText
                        variant={
                          isMobile ? TextVariant.BODY_1_REGULAR : TextVariant.LABEL_2_REGULAR
                        }
                      >
                        <span>{quantity}</span>
                      </CorText>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="flex flex-col gap-3">
              {formik.values.commercialCod && getWarningMessage() ? (
                <Alert status={AlertStatus.WARNING} text={getWarningMessage()} />
              ) : null}

              <CorText variant={isMobile ? TextVariant.LABEL_2_MEDIUM : TextVariant.LABEL_1_MEDIUM}>
                <span>
                  {t(`dispensingModal.${type}`, type)}
                  <T keyName={'dispensingModal.releaseText'}>catre eliberare</T>
                </span>
              </CorText>
              <div className="flex flex-col sm:grid sm:grid-cols-6 gap-2">
                <div className="sm:col-span-5 flex flex-col gap-1">
                  <CorText
                    variant={isMobile ? TextVariant.BODY_1_REGULAR : TextVariant.LABEL_2_REGULAR}
                  >
                    <span className="text-content-muted">
                      <T keyName={'dispensingModal.comercialName'}>Denumirea comerciala</T>
                    </span>
                  </CorText>

                  <CorInput error={!!(formik.touched.commercialCod && formik.errors.commercialCod)}>
                    <CorDropdown
                      className="w-full"
                      name="commercialCod"
                      placeholder={t(
                        'dispensingModal.selectComercialName',
                        'Selectati denumirea comerciala',
                      )}
                      callback={(e) => setName(e?.detail?.value)}
                      searchEnabled={true}
                      hasSelectedValue={true}
                      searchPlaceholder={t(
                        'dispensingModal.searchComercialNamePlaceholder',
                        'Cauta denumirea comerciala',
                      )}
                      isFlipped={false}
                      error={!!(formik.touched.commercialCod && formik.errors.commercialCod)}
                    >
                      {drugs?.map((item) => {
                        return (
                          <CorDropdownOption
                            key={hash(item.commercialCod) + Math.random()}
                            value={JSON.stringify({
                              commercialCod: item.commercialCod,
                              commercialName: item.commercialName,
                              externalId: item.externalId,
                            })}
                            label={getLabel(item)}
                            selected={isSelected(item)}
                          />
                        );
                      })}
                      {devices?.map((item) => {
                        return (
                          <CorDropdownOption
                            key={hash(item.commercialCod) + Math.random()}
                            value={JSON.stringify(item)}
                            label={getLabel(item)}
                            selected={isSelected(item)}
                          />
                        );
                      })}
                    </CorDropdown>
                    {formik.touched.commercialCod && formik.errors.commercialCod ? (
                      <span className="bottom-helper">
                        {formik.errors.commercialCod.toString()}
                      </span>
                    ) : null}
                  </CorInput>
                </div>
                <div className="flex flex-col gap-1">
                  <CorText
                    variant={isMobile ? TextVariant.BODY_1_REGULAR : TextVariant.LABEL_2_REGULAR}
                  >
                    <span className="text-content-muted">
                      <T keyName={'dispensingModal.quantity'}>Cantitatea</T>
                    </span>
                  </CorText>
                  <CorInput error={!!(formik.touched.quantity && formik.errors.quantity)}>
                    <input
                      type="number"
                      name="quantity"
                      placeholder={t('dispensingModal.insertQuantity', 'Introduceti cantitatea')}
                      value={formik?.values.quantity}
                      onChange={changeQuantity}
                      onWheel={(e) => disableScrollOnInput(e)}
                      onKeyDown={(e) => notIntegerSymbols.includes(e.key) && e.preventDefault()}
                    />
                    {formik.touched.quantity && formik.errors.quantity ? (
                      <span className="bottom-helper">{formik.errors.quantity.toString()}</span>
                    ) : null}
                  </CorInput>
                </div>
              </div>
              <div className="flex flex-col gap-1">
                <CorText
                  variant={isMobile ? TextVariant.BODY_1_REGULAR : TextVariant.LABEL_2_REGULAR}
                >
                  <span className="text-content-muted">
                    <T keyName={'dispensingModal.additionalInfo'}>Informatii aditionale</T>
                  </span>
                </CorText>
                <CorInput>
                  <textarea
                    name="additionalInfo"
                    placeholder={t(
                      'dispensingModal.additionalInfoPlaceholder',
                      'Introduceți informații adiționale',
                    )}
                    value={formik?.values.additionalInfo}
                    onChange={(event) => formik?.handleChange(event)}
                    rows={4}
                  ></textarea>
                </CorInput>
              </div>
              <div className="flex flex-col sm:grid sm:grid-cols-2 md:grid-cols-3 gap-3">
                <RetailInputPrice
                  label={t('dispensingModal.priceLabel', 'Pretul')}
                  type="number"
                  placeholder=""
                  name="retailPrice"
                  value={formik?.values.retailPrice}
                  isError={formik.touched.retailPrice && formik.errors.retailPrice}
                  error={formik?.errors?.retailPrice?.toString()}
                  onWheel={(e) => disableScrollOnInput(e)}
                  formik={formik}
                  calculatePrice={calculatePrice}
                  isDisabled={!!currentProduct?.externalId}
                />
                <InputWithAmount
                  label={t('dispensingModal.compensatedAmount', 'Suma compensata')}
                  type="number"
                  placeholder=""
                  name="compensatedAmount"
                  value={formik?.values.compensatedAmount}
                  isDisabled={true}
                  onWheel={(e) => disableScrollOnInput(e)}
                  isError={formik.touched.compensatedAmount && formik.errors.compensatedAmount}
                  error={formik?.errors?.compensatedAmount?.toString()}
                />
                <InputWithAmount
                  label={t('dispensingModal.amountToBePaid', 'Suma Co-plata Pacient')}
                  type="number"
                  placeholder=""
                  name="amountToBePaid"
                  value={formik?.values.amountToBePaid}
                  isDisabled={true}
                  onWheel={(e) => disableScrollOnInput(e)}
                />
              </div>
            </div>
          </main>
          <nav className="flex items-center justify-end gap-2 py-3 sm:py-6">
            <CorButton
              variant={ButtonVariant.SECONDARY}
              onClick={() => {
                onCancel();
                formik.resetForm();
              }}
            >
              <button className="flex items-center justify-center gap-2 w-fit text-action-primary-default">
                <CorText variant={TextVariant.LABEL_2_MEDIUM}>
                  <span className="flex gap-2 items-center justify-center">
                    <T keyName={'dispensingModal.cancelButton'}>Anuleaza</T>
                    <Close width={16} height={16} color="currentColor" />
                  </span>
                </CorText>
              </button>
            </CorButton>
            <CorButton>
              <button className="flex items-center justify-center gap-2 w-fit" type="submit">
                <CorText variant={TextVariant.LABEL_2_MEDIUM}>
                  <span className="flex gap-2 items-center justify-center">
                    <T keyName={'dispensingModal.addButton'}>Adauga</T>
                    <Check width={16} height={16} color="currentColor" />
                  </span>
                </CorText>
              </button>
            </CorButton>
          </nav>
        </form>
      </div>
    </Modal>
  ) : null;
};

export default memo(DispensingModal);
