import { CorChips, CorDropdown, CorInput, CorText, CorToggle } from '@e-reteta/react-design-system';
import { FC, memo, useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import Select from 'react-select';
import hash from 'object-hash';
import { T, useTranslate } from '@tolgee/react';

import { ChipSize, ChipVariant, TextVariant } from '../../../../enums/design-system.enum';
import PlusInput from '../../PlusInput';
import { colourStyles } from '../../../CountryDropdown/DropDownStyles';
import { DropDownSingleValue } from '../../../CountryDropdown/SingleValue';
import { DropDownOption } from '../../../CountryDropdown/DropDownOption';
import { COUNTRIES } from '../../../CountryDropdown/countries.constant';
import type { Drug, Intersection } from '../../../../types/Drug';
import { CorDropdownOption } from '../../../index';
import { CompensatedStatusTypesEnum } from '../../../../enums/CompensatedStatusTypesEnum';
import { ModalContentProps } from '../interface';
import { DiagnosticServices } from '../../../../services/diagnostics.services';
import { Disease } from '../../../../types/Disease';
import PriceInput from '../PriceInput';
import disableScrollOnInput from '../../../../utils/disableScrollOnInput';
import { CountryData } from '../../../CountryDropdown/interface';
import { DropdownEvent } from '../../../../types/CommonTypes';

const DrugModal: FC<ModalContentProps> = ({ isEdit }) => {
  const formik = useFormikContext<Drug>();
  const { t } = useTranslate();

  const [diagnosticChips, setDiagnosticChips] = useState<string[]>([]);
  const [intersections, setIntersections] = useState<string[]>([]);
  const [selectedCountry, selectCountry] = useState<CountryData>();
  const [valuesLoaded, setValuesLoaded] = useState(false);
  const [diagnostics, setDiagnostics] = useState<Disease[]>([]);

  useEffect(() => {
    if (!valuesLoaded && formik.values.country?.name) {
      selectCountry(COUNTRIES.find((c) => c.label === formik.values.country?.name));
      setValuesLoaded(true);
    }
  }, [formik.values]);

  const setCountry = (data: CountryData) => {
    formik.setFieldValue('country.name', data.label);
    selectCountry(data);
  };

  const addNewDiagnostic = (value: string) => {
    if (!diagnosticChips.includes(value)) {
      if (value && value.trim().length !== 0) {
        const values = [...diagnosticChips, value];
        setDiagnosticChips(values);
        formik.setFieldValue('cimCodes', values);
      }
    }
  };

  const removeDiagnostic = (value: string) => {
    const values = diagnosticChips.filter((diagnostic) => diagnostic !== value);
    setDiagnosticChips(values);
    formik.setFieldValue('cimCodes', values);
  };

  const addIntersections = (value: string) => {
    if (value && value.trim().length !== 0) {
      const values = [...intersections, value];
      setIntersections(values);
      formik.setFieldValue('intersections', values);
    }
  };

  const removeIntersections = (value: string) => {
    const values = intersections.filter((intersection) => intersection !== value);
    setIntersections(values);
    formik.setFieldValue('intersections', values);
  };

  const onSelectDropdown = async (e: DropdownEvent, id: string) => {
    await formik.setFieldValue(id, e?.detail?.value);
  };

  const handleToggle = async (id: string, value: boolean) => {
    await formik.setFieldValue(id, value);
  };

  useEffect(() => {
    if (formik.values.cimCodes?.length) {
      setDiagnosticChips(formik.values.cimCodes);
    }
    if (formik.values.intersections?.length && !intersections.length) {
      const values = formik.values.intersections.map(
        (intersection: Intersection) => intersection.destination?.atcCod as string,
      );
      setIntersections(values);
    }
  }, [formik]);

  const handleSearchDiagnostics = async (value: string) => {
    const diseases = await DiagnosticServices.getDiagnostics('admin', { code: value });
    if (value) {
      setDiagnostics(diseases.data.items);
    } else {
      setDiagnostics([]);
    }
  };

  return (
    <div className="px-8 mb-6 overflow-auto">
      <div className="flex flex-col gap-4">
        <div className="grid grid-cols-2 gap-4">
          <div className="flex flex-col gap-1">
            <CorText variant={TextVariant.LABEL_2_REGULAR}>
              <div className="text-content-muted">
                <T keyName={'drugModal.commonInternationalName'}>
                  Denumirea Comuna Internationala (DCI)
                </T>
              </div>
            </CorText>
            <CorInput error={!!formik.errors?.internationalName}>
              <input
                autoComplete="off"
                type="text"
                placeholder={t('drugModal.internationalNamePlaceholder', 'Introduceti DCI')}
                name="internationalName"
                value={formik.values.internationalName}
                onChange={formik.handleChange}
              />
              {formik.errors?.internationalName ? (
                <span className="bottom-helper">{formik.errors?.internationalName}</span>
              ) : null}
            </CorInput>
          </div>
          <div className="flex flex-col gap-1">
            <CorText variant={TextVariant.LABEL_2_REGULAR}>
              <div className="text-content-muted">
                <T keyName={'drugModal.dosage'}>Doza</T>
              </div>
            </CorText>
            <CorInput error={!!formik.errors?.dose}>
              <input
                autoComplete="off"
                type="string"
                placeholder={t('drugModal.dosagePlaceholder', 'Introduceti doza')}
                name="dose"
                value={formik.values.dose}
                onChange={formik.handleChange}
              />
              {formik.errors?.dose ? (
                <span className="bottom-helper">{formik.errors?.dose}</span>
              ) : null}
            </CorInput>
          </div>
        </div>
        <div className="flex flex-col gap-1">
          <CorText variant={TextVariant.LABEL_2_REGULAR}>
            <div className="text-content-muted">
              <T keyName={'drugModal.form'}>Forma farmaceutică</T>
            </div>
          </CorText>
          <CorInput error={!!formik.errors.form?.name}>
            <input
              autoComplete="off"
              type="text"
              placeholder={t('drugModal.formPlaceholder', 'Introduceti forma faramceutică')}
              name="form.name"
              value={formik.values?.form?.name}
              onChange={formik.handleChange}
            />
            {formik.errors?.form?.name ? (
              <span className="bottom-helper">{formik.errors?.form?.name}</span>
            ) : null}
          </CorInput>
        </div>
        <div className="flex flex-col">
          <PlusInput
            className="mb-1.5"
            placeholder="F320"
            name="cimCodes"
            label={t('drugModal.cimCodes', 'Cod CIM de diagnostic')}
            addNewChip={addNewDiagnostic}
            searchDiagnostics={handleSearchDiagnostics}
            diagnostics={diagnostics}
            setSelectedDiagnostics={setDiagnostics}
            selectedDiagnostics={diagnosticChips}
            error={formik.errors.cimCodes}
            isDiagnosticInput={true}
          />
          <div className="flex flex-wrap gap-1">
            {diagnosticChips.map((item) => (
              <CorChips
                key={hash(item)}
                closeButton={true}
                size={ChipSize.MD}
                variant={ChipVariant.OUTLINE}
                onOnClose={() => removeDiagnostic(item)}
              >
                <div>{item}</div>
              </CorChips>
            ))}
          </div>
        </div>
        <div className="flex flex-col gap-1">
          <CorText variant={TextVariant.LABEL_2_REGULAR}>
            <div className="text-content-muted">
              <T keyName={'drugModal.group'}>Grupa farmacoterapeutică</T>
            </div>
          </CorText>
          <CorInput error={!!formik.errors.group?.name}>
            <input
              autoComplete="off"
              type="text"
              name="group.name"
              placeholder={t('drugModal.groupPlaceholder', 'Introduceti grupa farmacoterapeutică')}
              value={formik.values?.group?.name}
              onChange={formik.handleChange}
            />
            {formik.errors?.group?.name ? (
              <span className="bottom-helper">{formik.errors?.group?.name}</span>
            ) : null}
          </CorInput>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div className="flex flex-col gap-1">
            <CorText variant={TextVariant.LABEL_2_REGULAR}>
              <div className="text-content-muted">
                <T keyName={'drugModal.atcCod'}>Cod ATC</T>
              </div>
            </CorText>
            <CorInput error={!!formik.errors?.atcCod} disabled={isEdit}>
              <input
                autoComplete="off"
                type="text"
                placeholder={t('drugModal.atcCodPlaceholder', 'Introduceti cod ATC')}
                name="atcCod"
                value={formik.values.atcCod}
                onChange={formik.handleChange}
              />
              {formik.errors?.atcCod ? (
                <span className="bottom-helper">{formik.errors?.atcCod}</span>
              ) : null}
            </CorInput>
          </div>
          <div className="flex flex-col gap-1">
            <CorText variant={TextVariant.LABEL_2_REGULAR}>
              <div className="text-content-muted">
                <T keyName={'drugModal.treatmentDuration'}>Durata tratamentului</T>
              </div>
            </CorText>
            <CorInput error={!!formik.errors?.treatmentDuration}>
              <input
                autoComplete="off"
                type="number"
                min={1}
                max={90}
                placeholder={t(
                  'drugModal.treatmentDurationPlaceholder',
                  'Introduceti durata tratamentului',
                )}
                name="treatmentDuration"
                value={formik.values.treatmentDuration}
                onChange={formik.handleChange}
                onWheel={(e) => disableScrollOnInput(e)}
              />
              {formik.errors?.treatmentDuration ? (
                <span className="bottom-helper">{formik.errors?.treatmentDuration}</span>
              ) : null}
            </CorInput>
          </div>
        </div>
        <div className="flex items-center">
          <CorToggle
            value={formik.values.isAllowedForAdult}
            className="text-sm"
            label={t('drugModal.isAllowedForAdult', 'Prescrierea pentru adulți')}
            onClick={() => handleToggle('isAllowedForAdult', !formik.values.isAllowedForAdult)}
          >
            <input
              autoComplete="off"
              type="checkbox"
              value={String(formik.values.isAllowedForAdult)}
            />
          </CorToggle>
        </div>
        <div className="flex items-center">
          <CorToggle
            value={formik.values.isAllowedForChildren}
            className="text-sm"
            label={t('drugModal.isAllowedForChildren', 'Prescrierea pentru copii 0-18 ani')}
            onClick={() =>
              handleToggle('isAllowedForChildren', !formik.values.isAllowedForChildren)
            }
          >
            <input
              autoComplete="off"
              type="checkbox"
              value={String(formik.values.isAllowedForChildren)}
            />
          </CorToggle>
        </div>
        <div className="flex items-center">
          <CorToggle
            value={formik.values.isAllowedUninsured}
            className="text-sm"
            label={t('drugModal.isAllowedUninsured', 'Statut prescriere pentru adulti neasigurați')}
            onClick={() => handleToggle('isAllowedUninsured', !formik.values.isAllowedUninsured)}
          >
            <input
              autoComplete="off"
              type="checkbox"
              value={String(formik.values.isAllowedUninsured)}
            />
          </CorToggle>
        </div>
        <div className="flex flex-col">
          <PlusInput
            className="mb-1.5"
            placeholder={t('drugModal.intersectionPlaceholder', 'Cod ATC ale altor medicamente')}
            name="intersection"
            label={t(
              'drugModal.intersectionLabel',
              'Interacțiuni inadmisibile cu alte coduri ATC (filtre și informare)',
            )}
            addNewChip={addIntersections}
          />
          <div className="flex flex-wrap gap-1">
            {intersections.map((item) => (
              <CorChips
                key={hash(item)}
                closeButton={true}
                size={ChipSize.MD}
                variant={ChipVariant.OUTLINE}
                onOnClose={() => removeIntersections(item)}
              >
                <div>{item}</div>
              </CorChips>
            ))}
          </div>
        </div>
        <div className="flex flex-col gap-1">
          <CorText variant={TextVariant.LABEL_2_REGULAR}>
            <div className="text-content-muted">
              <T keyName={'drugModal.expiration'}>Valabilitatea rețetei</T>
            </div>
          </CorText>
          <CorInput error={!!formik.errors?.expiration}>
            <input
              autoComplete="off"
              type="number"
              min={1}
              placeholder={t(
                'drugModal.expirationPlaceholder',
                'Introduceti valabilitatea retetei',
              )}
              name="expiration"
              value={formik.values.expiration}
              onChange={formik.handleChange}
              onWheel={(e) => disableScrollOnInput(e)}
            />
            {formik.errors?.expiration ? (
              <span className="bottom-helper">{formik.errors?.expiration}</span>
            ) : null}
          </CorInput>
        </div>
        <div className="flex flex-col gap-1">
          <CorText variant={TextVariant.LABEL_2_REGULAR}>
            <div className="text-content-muted">
              <T keyName={'drugModal.commercialCod'}>
                Cod DC = Cod medicamentului conform Nomenclatorului de stat
              </T>
            </div>
          </CorText>
          <CorInput error={!!formik.errors?.commercialCod}>
            <input
              autoComplete="off"
              type="text"
              placeholder={t('drugModal.commercialCodPlaceholder', 'Introduceti codDC')}
              name="commercialCod"
              value={formik.values.commercialCod}
              onChange={formik.handleChange}
            />
            {formik.errors?.commercialCod ? (
              <span className="bottom-helper">{formik.errors?.commercialCod}</span>
            ) : null}
          </CorInput>
        </div>
        <div className="flex flex-col gap-1">
          <CorText variant={TextVariant.LABEL_2_REGULAR}>
            <div className="text-content-muted">
              <T keyName={'drugModal.commercialName'}>Denumirea comercială (DC)</T>
            </div>
          </CorText>
          <CorInput error={!!formik.errors?.commercialName}>
            <input
              autoComplete="off"
              type="text"
              placeholder={t(
                'drugModal.commercialNamePlaceholder',
                'Introduceti denumirea comerciala',
              )}
              name="commercialName"
              value={formik.values.commercialName}
              onChange={formik.handleChange}
            />
            {formik.errors?.commercialName ? (
              <span className="bottom-helper">{formik.errors?.commercialName}</span>
            ) : null}
          </CorInput>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div className="flex flex-col gap-1">
            <CorText variant={TextVariant.LABEL_2_REGULAR}>
              <div className="text-content-muted">
                <T keyName={'drugModal.division'}>Divizarea</T>
              </div>
            </CorText>
            <CorInput error={!!formik.errors?.division}>
              <input
                autoComplete="off"
                type="text"
                placeholder={t('drugModal.divisionPlaceholder', 'Introduceti divizarea')}
                name="division"
                value={formik.values.division}
                onChange={formik.handleChange}
              />
              {formik.errors?.division ? (
                <span className="bottom-helper">{formik.errors?.division}</span>
              ) : null}
            </CorInput>
          </div>
          <div className="flex flex-col gap-1">
            <CorText variant={TextVariant.LABEL_2_REGULAR}>
              <div className="text-content-muted">
                <T keyName={'drugModal.volume'}>Volum</T>
              </div>
            </CorText>
            <CorInput>
              <input
                autoComplete="off"
                type="text"
                placeholder={t('drugModal.volumePlaceholder', 'Introduceti volumul')}
                name="volume"
                value={formik.values.volume}
                onChange={formik.handleChange}
              />
            </CorInput>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div className="flex flex-col gap-1">
            <CorText variant={TextVariant.LABEL_2_REGULAR}>
              <div className="text-content-muted">
                <T keyName={'drugModal.compensatedStatusAdult'}>
                  Statut de compensare pentru adulti
                </T>
              </div>
            </CorText>
            <CorInput error={!!formik.errors.compensatedStatusAdult}>
              <CorDropdown
                placeholder={t(
                  'drugModal.compensatedStatusAdultPlceholder',
                  'Statut de compensare pentru adulti',
                )}
                hasSelectedValue={false}
                searchEnabled={false}
                callback={(e) => onSelectDropdown(e, 'compensatedStatusAdult')}
              >
                <CorDropdownOption
                  label={t('drugModal.optionNotCompensated', 'Nu este compensat')}
                  selected={
                    formik.values.compensatedStatusAdult ===
                    CompensatedStatusTypesEnum.NOT_COMPENSATED
                  }
                  value={CompensatedStatusTypesEnum.NOT_COMPENSATED}
                />
                <CorDropdownOption
                  label={t('drugModal.optionPartialyCompensated', 'Partial compensat')}
                  selected={
                    formik.values.compensatedStatusAdult ===
                    CompensatedStatusTypesEnum.PARTIALLY_COMPENSATED
                  }
                  value={CompensatedStatusTypesEnum.PARTIALLY_COMPENSATED}
                />
                <CorDropdownOption
                  label={t('drugModal.optionTotalyCompensated', 'Total compensat')}
                  selected={
                    formik.values.compensatedStatusAdult ===
                    CompensatedStatusTypesEnum.FULLY_COMPENSATED
                  }
                  value={CompensatedStatusTypesEnum.FULLY_COMPENSATED}
                />
              </CorDropdown>
              {formik.errors?.compensatedStatusAdult ? (
                <span className="bottom-helper">{formik.errors?.compensatedStatusAdult}</span>
              ) : null}
            </CorInput>
          </div>
          <div className="flex flex-col gap-1">
            <CorText variant={TextVariant.LABEL_2_REGULAR}>
              <div className="text-content-muted">
                <T keyName={'drugModal.compensatedStatusChildren'}>
                  Statut de compensare pentru copii
                </T>
              </div>
            </CorText>
            <CorInput error={!!formik.errors.compensatedStatusChildren}>
              <CorDropdown
                placeholder={t(
                  'drugModal.compensatedStatusChildrenPlaceholder',
                  'Statut de compensare pentru copii',
                )}
                hasSelectedValue={false}
                searchEnabled={false}
                callback={(e) => onSelectDropdown(e, 'compensatedStatusChildren')}
              >
                <CorDropdownOption
                  label={t('drugModal.optionNotCompensated', 'Nu este compensat')}
                  selected={
                    formik.values.compensatedStatusChildren ===
                    CompensatedStatusTypesEnum.NOT_COMPENSATED
                  }
                  value={CompensatedStatusTypesEnum.NOT_COMPENSATED}
                />
                <CorDropdownOption
                  label={t('drugModal.optionPartialyCompensated', 'Partial compenat')}
                  selected={
                    formik.values.compensatedStatusChildren ===
                    CompensatedStatusTypesEnum.PARTIALLY_COMPENSATED
                  }
                  value={CompensatedStatusTypesEnum.PARTIALLY_COMPENSATED}
                />
                <CorDropdownOption
                  label={t('drugModal.optionTotalyCompensated', 'Total compensat')}
                  selected={
                    formik.values.compensatedStatusChildren ===
                    CompensatedStatusTypesEnum.FULLY_COMPENSATED
                  }
                  value={CompensatedStatusTypesEnum.FULLY_COMPENSATED}
                />
              </CorDropdown>
              {formik.errors?.compensatedStatusChildren ? (
                <span className="bottom-helper">{formik.errors?.compensatedStatusChildren}</span>
              ) : null}
            </CorInput>
          </div>
        </div>
        <div className="flex flex-col gap-1">
          <CorText variant={TextVariant.LABEL_2_REGULAR}>
            <div className="text-content-muted">
              <T keyName={'drugModal.compensatedSumWithVATPerUnit'}>
                Suma fixă compensată per unitate de măsură inclusiv TVA
              </T>{' '}
            </div>
          </CorText>
          <PriceInput
            autoComplete="off"
            type="number"
            min={0}
            placeholder={t('drugModal.compensatedSumWithVATPerUnitPlaceholder', 'Introduceti suma')}
            name="compensatedSumWithVATPerUnit"
            value={formik.values?.compensatedSumWithVATPerUnit}
            step=".01"
            formik={formik}
            onWheel={(e) => disableScrollOnInput(e)}
          ></PriceInput>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div className="flex flex-col gap-1">
            <CorText variant={TextVariant.LABEL_2_REGULAR}>
              <div className="text-content-muted">
                <T keyName={'drugModal.compensatedSumWithoutVATPerUnit'}>
                  Suma fixă compensată per unitate de măsură fără TVA
                </T>
              </div>
            </CorText>
            <PriceInput
              autoComplete="off"
              type="number"
              min={0}
              placeholder={t(
                'drugModal.compensatedSumWithoutVATPerUnitPlaceholder',
                'Introduceti suma',
              )}
              name="compensatedSumWithoutVATPerUnit"
              value={formik.values?.compensatedSumWithoutVATPerUnit}
              step=".01"
              formik={formik}
              onWheel={(e) => disableScrollOnInput(e)}
            ></PriceInput>
          </div>
          <div className="flex flex-col gap-1">
            <CorText variant={TextVariant.LABEL_2_REGULAR}>
              <div className="text-content-muted">
                <T keyName={'drugModal.coPayment'}>
                  Preț maxim cu amănuntul în farmacii per unitate, MDL
                </T>
              </div>
            </CorText>
            <PriceInput
              autoComplete="off"
              type="number"
              min={0}
              placeholder={t('drugModal.coPaymentPlaceholder', 'Introduceti prețul')}
              name="coPayment"
              value={formik.values?.coPayment}
              step=".01"
              formik={formik}
              onWheel={(e) => disableScrollOnInput(e)}
            ></PriceInput>
          </div>
        </div>

        <div className="flex flex-col gap-1">
          <CorText variant={TextVariant.LABEL_2_REGULAR}>
            <div className="text-content-muted">
              <T keyName={'drugModal.tvaAmount'}>TVA %</T>
            </div>
          </CorText>
          <CorInput>
            <input
              autoComplete="off"
              type="number"
              min={0}
              max={100}
              placeholder={t('drugModal.tvaAmountPlaceholder', 'Introduceti TVA in %')}
              name="vat"
              value={formik.values?.vat}
              onChange={formik.handleChange}
              onWheel={(e) => disableScrollOnInput(e)}
            />
          </CorInput>
        </div>

        <div className="flex flex-col gap-1">
          <CorText variant={TextVariant.LABEL_2_REGULAR}>
            <div className="text-content-muted">
              <T keyName={'drugModal.country'}>Tara</T>
            </div>
          </CorText>
          <Select
            placeholder={t('drugModal.countryPlaceholder', 'Selectati Tara')}
            onChange={setCountry}
            options={COUNTRIES}
            menuPlacement="top"
            components={{
              Option: (options) => DropDownOption(options, formik.values?.country?.name),
              SingleValue: (value) => DropDownSingleValue(value),
            }}
            classNamePrefix="dropdown"
            value={selectedCountry}
            styles={colourStyles}
          />
        </div>
        <div className="flex flex-col gap-1">
          <CorText variant={TextVariant.LABEL_2_REGULAR}>
            <div className="text-content-muted">
              <T keyName={'drugModal.producer'}>Firma producătoare</T>
            </div>
          </CorText>
          <CorInput>
            <input
              autoComplete="off"
              type="text"
              placeholder={t('drugModal.producerPlaceholder', 'Introduceti firma producatore')}
              name="producer.name"
              value={formik.values.producer?.name}
              onChange={formik.handleChange}
            />
          </CorInput>
        </div>
        <div className="flex flex-col gap-1">
          <CorText variant={TextVariant.LABEL_2_REGULAR}>
            <div className="text-content-muted">
              <T keyName={'drugModal.administrationMode'}>Mod de administrare</T>
            </div>
          </CorText>
          <CorInput>
            <textarea
              autoComplete="off"
              placeholder={t(
                'drugModal.administrationModePlaceholder',
                'Introduceti modul de administrare',
              )}
              rows={4}
              name="routeOfAdministration"
              value={formik.values.routeOfAdministration}
              onChange={formik.handleChange}
            />
          </CorInput>
        </div>
        <div className="flex flex-col gap-1">
          <CorText variant={TextVariant.LABEL_2_REGULAR}>
            <div className="text-content-muted">
              <T keyName={'drugModal.warningsDCI'}>Avertizari/Comentarii DCI</T>
            </div>
          </CorText>
          <CorInput>
            <textarea
              autoComplete="off"
              placeholder={t(
                'drugModal.warningsDCIPlaceholder',
                'Introduceti Avertizari/Comentarii DCI',
              )}
              rows={4}
              name="warningsDCI"
              value={formik.values.warningsDCI}
              onChange={formik.handleChange}
            />
          </CorInput>
        </div>
        <div className="flex flex-col gap-1">
          <CorText variant={TextVariant.LABEL_2_REGULAR}>
            <div className="text-content-muted">
              <T keyName={'drugModal.warningsDC'}>Avertizari/Comentarii DC</T>
            </div>
          </CorText>
          <CorInput>
            <textarea
              autoComplete="off"
              placeholder={t(
                'drugModal.warningsDCPlaceholder',
                'Introduceti Avertizari/Comentarii DC',
              )}
              rows={4}
              name="warningsDC"
              value={formik.values.warningsDC}
              onChange={formik.handleChange}
            />
          </CorInput>
        </div>
        <div className="flex items-center">
          <CorToggle
            value={formik.values.active}
            className="text-sm"
            label={t('drugModal.active', 'Active')}
            onClick={() => handleToggle('active', !formik.values.active)}
          >
            <input autoComplete="off" type="checkbox" value={String(formik.values.active)} />
          </CorToggle>
        </div>
      </div>
    </div>
  );
};

export default memo(DrugModal);
