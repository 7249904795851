export const checkIfHave18 = (birthday: Date) => {
  const todayDate = new Date(Date.now());
  if (todayDate.getFullYear() - birthday.getFullYear() > 18) {
    return true;
  }
  if (todayDate.getFullYear() - birthday.getFullYear() === 18) {
    if (todayDate.getMonth() < birthday.getMonth()) {
      return false;
    }
    if (todayDate.getMonth() > birthday.getMonth()) {
      return true;
    }
    if (todayDate.getMonth() === birthday.getMonth()) {
      if (todayDate.getUTCDate() >= birthday.getUTCDate()) {
        return true;
      }
    }
  }
  return false;
};
