import { CorText } from '@e-reteta/react-design-system';
import { memo } from 'react';
import hash from 'object-hash';

import { TextVariant } from '../../../enums/design-system.enum';
import type { TableHeaderProps } from './interface';
import { T } from '@tolgee/react';

const TableHeader = (props: TableHeaderProps<any>) => {
  const { fullWidth, widthType, columns, tableWidth } = props;
  const keys = Object.keys(columns);

  return (
    <header
      className="pt-3 pb-2 box-content bg-surface-default"
      style={{
        width: `${tableWidth ? tableWidth : '100%'}`,
        maxWidth: `${tableWidth ? tableWidth : '100%'}`,
      }}
    >
      <div className="px-4 max-h-[52px] min-h-[16px] flex flex-row items-end gap-4">
        {keys.map((key) => (
          <CorText
            key={hash(key)}
            style={{
              width: fullWidth ? `100%` : `${columns[key]?.width}${widthType}`,
              minWidth: `${columns[key]?.width}${widthType}`,
              maxWidth: `${columns[key]?.width}${widthType}`,
            }}
            variant={TextVariant.BODY_1_MEDIUM}
          >
            <span className="text-content-muted flex items-end justify-start">
              <T keyName={columns[key]?.translationKey!}>{columns[key]?.value}</T>
            </span>
          </CorText>
        ))}
      </div>
    </header>
  );
};

export default memo(TableHeader);
