import type { ModalWindowOpen } from '../TableRow/enums';

export enum WidthType {
  PERCENTAGE = '%',
  PIXEL = 'px',
}

export type TableColumnType = 'text' | 'chip' | 'toggle' | 'range' | 'number' | 'date' | 'boolean';

export interface TableColumnProps {
  type: TableColumnType;
  value: string;
  translationKey: string;
  width: number;
  modalType?: ModalWindowOpen;
  compareValues?: string[];
}

export interface TableHeaderProps<T extends {}> {
  fullWidth: boolean;
  widthType: WidthType;
  tableWidth?: string;
  columns: Partial<Record<keyof T, TableColumnProps>>;
}
