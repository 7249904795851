import Api from '../axios/api';
import { QueryParams } from './doctorsPrescription.services';
import { Patient } from '../types/Patient';

export interface GetPatientResponse {
  data: Patient;
}

export class PatientServices {
  static getPatient(
    target: string,
    query: QueryParams,
    basicOnly?: boolean,
  ): Promise<GetPatientResponse> {
    return Api.get(`/v1/${target}/patients/${query.patientId}`, {
      params: { basicOnly, limit: query.limit, orderBy: query.orderBy, skip: query.skip },
    });
  }
}
