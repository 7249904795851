import React, { FC, memo, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { CorBox, CorButton, CorText } from '@e-reteta/react-design-system';
import hash from 'object-hash';
import { T, useTranslate } from '@tolgee/react';

import { ButtonVariant, TextVariant } from '../../enums/design-system.enum';
import { ViewDrugProps } from './interface';
import useWindowWidth from '../../hooks/use-window-width';
import Accordion from '../Accordion';
import Plus from '../../icons/Plus';
import DispensingModal from '../DispensingModal';
import { DeviceOrDrug } from '../DeviceAndDrugDropdown/enum';
import { Edit } from '../../icons';
import Trash from '../../icons/Trash';
import { PrescriptionRelease } from '../../services/pharmacy.services';
import { Dispense, PrescriptionReleaseData } from '../../types/CommonTypes';
import { Alert } from '../index';
import { AlertStatus } from '../Alert/enum';

const ViewDrug: FC<ViewDrugProps> = ({
  onRefresh,
  activeSubstance,
  treatmentDuration,
  administrationRoute,
  dosage,
  prescribedQuantity,
  additionalInfo,
  hasBorderBottom,
  role,
  dPrescriptionId,
  setIsDisabled,
  dispenses,
  diagnosticsCode,
  products,
  expired,
}) => {
  const { isMobile } = useWindowWidth();
  const location = useLocation();
  const { t } = useTranslate();

  const [totallyDispensed, setTotallyDispensed] = useState<boolean>(false);
  const [isOpenModal, setModalOpen] = useState<boolean>(false);
  const [localDispenses, setLocalDispenses] = useState<Dispense[]>([]);
  const [dispense, setDispense] = useState<Dispense>();
  const [countDispenses, setCountDispenses] = useState<number>(0);
  const [countAlreadyDispensed, setCountAlreadyDispensed] = useState<number>(0);
  const [filteredReleases, setFilteredReleases] = useState<PrescriptionRelease[]>([]);
  const [idDispense, setIdDispense] = useState<number>(1);

  useEffect(() => {
    const filteredReleases =
      dispenses?.reduce((accum: PrescriptionRelease[], dispense: Dispense) => {
        return [
          ...accum,
          ...dispense.prescriptionReleases?.filter(
            (release) => release.dPrescriptionId === dPrescriptionId,
          ),
        ];
      }, []) || [];
    setFilteredReleases(filteredReleases);
    let count: number = 0;

    let prescriptionReleasesData = localStorage.getItem('prescriptionReleases');
    let prescriptionReleases = prescriptionReleasesData ? JSON.parse(prescriptionReleasesData) : [];

    if (filteredReleases.length) {
      filteredReleases.forEach((item: PrescriptionRelease) => {
        if (item.dPrescriptionId === dPrescriptionId) {
          count += Number(item.quantity);
        }
      });
    }
    if (prescriptionReleases && prescriptionReleases.length) {
      prescriptionReleases.forEach((item: PrescriptionRelease) => {
        if (item.dPrescriptionId === dPrescriptionId) {
          count += Number(item.quantity);
        }
      });
    }
    setCountDispenses(count);
    setCountAlreadyDispensed(count);
  }, [dispenses]);

  useEffect(() => {
    if (['perorale solide', 'perorale solide cu cedare prelungită'].includes(administrationRoute)) {
      const restQuantity = Number(prescribedQuantity) - countDispenses;
      setTotallyDispensed(!!countDispenses && restQuantity <= 0);
    } else {
      setTotallyDispensed(
        !!countDispenses && Math.abs(Number(prescribedQuantity) - countDispenses) === 0,
      );
    }
  }, [countDispenses]);

  const saveDispenses = ({ values, product }: any) => {
    const currentCount = countDispenses + Number(values?.quantity);
    setModalOpen(false);
    setCountDispenses(currentCount);
    setIdDispense((prevState) => prevState + 1);

    let prescriptionReleasesData = localStorage.getItem('prescriptionReleases');
    let prescriptionReleases = prescriptionReleasesData ? JSON.parse(prescriptionReleasesData) : [];

    if (!prescriptionReleases) {
      prescriptionReleases = [];
    }
    prescriptionReleases = [
      ...prescriptionReleases,
      {
        ...values,
        externalId: product.externalId,
        id: `${idDispense}-${activeSubstance}`,
        drugCommercialCod: values.commercialCod,
        drugCommercialName: values.commercialName,
        dPrescriptionId,
      },
    ];
    setLocalDispenses(
      prescriptionReleases?.filter(
        (item: PrescriptionRelease) => item.dPrescriptionId === dPrescriptionId,
      ),
    );
    localStorage.setItem('prescriptionReleases', JSON.stringify(prescriptionReleases));
    if (prescriptionReleases.length > 0) {
      setIsDisabled(false);
    }
  };

  const editDispense = ({ values, product }: any) => {
    let prescriptionReleases: any = localStorage.getItem('prescriptionReleases');
    prescriptionReleases = JSON.parse(prescriptionReleases);
    const updatedDispensesLocalStorage: any = [...prescriptionReleases];

    let localSavedDispense = updatedDispensesLocalStorage.find(
      ({ id }: PrescriptionReleaseData) => id === values.id,
    );

    const currentCount =
      countDispenses - Number(localSavedDispense?.quantity) + Number(values?.quantity);
    if (localSavedDispense) {
      localSavedDispense.quantity = values.quantity;
      localSavedDispense.internationalName = values.internationalName;
      localSavedDispense.drugCommercialCod = values.commercialCod;
      localSavedDispense.commercialCod = values.commercialCod;
      localSavedDispense.commercialName = values.commercialName;
      localSavedDispense.drugCommercialName = values.commercialName;
      localSavedDispense.additionalInfo = values.additionalInfo;
      localSavedDispense.retailPrice = values.retailPrice;
      localSavedDispense.compensatedAmount = values.compensatedAmount;
      localSavedDispense.amountToBePaid = values.amountToBePaid;
      localSavedDispense.externalId = product.externalId;
      localSavedDispense.status =
        Number(prescribedQuantity) == currentCount ? 'released' : 'partial_released';
      localStorage.setItem('prescriptionReleases', JSON.stringify(updatedDispensesLocalStorage));
      setLocalDispenses(
        updatedDispensesLocalStorage?.filter(
          (item: PrescriptionRelease) => item.dPrescriptionId === dPrescriptionId,
        ),
      );
    }
    setCountDispenses(currentCount);
    setModalOpen(false);
    setDispense(undefined);
  };

  const deleteDispense = (values: Dispense) => {
    let prescriptionReleasesData = localStorage.getItem('prescriptionReleases');
    let prescriptionReleases = prescriptionReleasesData ? JSON.parse(prescriptionReleasesData) : [];

    let filterDispense = prescriptionReleases.filter(
      ({ id }: PrescriptionRelease) => id !== values.id,
    );
    const ids = filterDispense.map(({ id }: PrescriptionRelease) => id);
    const dispense = prescriptionReleases.filter(({ id }: PrescriptionRelease) => {
      if (id?.includes(activeSubstance)) {
        return ids.includes(id);
      } else {
        return true;
      }
    });

    setCountDispenses((prevState) => Number(prevState) - Number(values?.quantity));
    localStorage.setItem('prescriptionReleases', JSON.stringify(dispense));
    setLocalDispenses(
      dispense?.filter((item: PrescriptionRelease) => item.dPrescriptionId === dPrescriptionId),
    );
    if (dispense.length === 0) {
      setIsDisabled(true);
    }
  };

  useEffect(() => {
    if (role === 'doctor') {
      return;
    }
    let prescriptionReleases: any = localStorage.getItem('prescriptionReleases');
    prescriptionReleases = JSON.parse(prescriptionReleases);
    prescriptionReleases = prescriptionReleases?.filter(
      (item: PrescriptionRelease) => item.dPrescriptionId === dPrescriptionId,
    );
    if (prescriptionReleases?.length > 0) {
      setIsDisabled(false);
    }
    setLocalDispenses(prescriptionReleases);
  }, []);

  const renderDispense = (dispense: Dispense) => {
    return (
      <div
        key={hash(dispense)}
        className="flex flex-col 2md:grid 2md:grid-cols-12 gap-3 sm:border sm:border-solid sm:border-border-default sm:rounded-2xl sm:px-4 sm:py-3.5"
      >
        <CorText
          variant={isMobile ? TextVariant.LABEL_1_MEDIUM : TextVariant.LABEL_1_REGULAR}
          className="col-span-4"
        >
          <span className="text-content-default">
            <T keyName={'viewDrug.drugToBeReleased'}>Medicament catre eliberare</T>
          </span>
        </CorText>
        <div className="pl-2 2md:pl-0 border-0 border-l-[1px] md:border-0 border-solid border-l-border-default col-span-8 flex flex-col gap-3 2md:gap-2">
          <div className="flex flex-col sm:flex-row gap-x-2">
            <CorText
              variant={isMobile ? TextVariant.BODY_1_REGULAR : TextVariant.LABEL_2_REGULAR}
              className="col-span-4 w-[172px] flex-none"
            >
              <span className="text-content-muted">
                <T keyName={'viewDrug.commercialName'}>Denumire comerciala</T>
              </span>
            </CorText>
            <CorText
              variant={isMobile ? TextVariant.BODY_1_MEDIUM : TextVariant.LABEL_2_MEDIUM}
              className="col-span-4"
            >
              <span className="text-content-default">{dispense.commercialName}</span>
            </CorText>
          </div>
          <div className="border-image-circle" />
          <div className="flex flex-col sm:flex-row gap-x-2">
            <CorText
              variant={isMobile ? TextVariant.BODY_1_REGULAR : TextVariant.LABEL_2_REGULAR}
              className="col-span-4 w-[172px] flex-none"
            >
              <span className="text-content-muted">
                <T keyName={'viewDrug.releasedQuantity'}>Cantitatea eliberata</T>
              </span>
            </CorText>
            <CorText
              variant={isMobile ? TextVariant.BODY_1_MEDIUM : TextVariant.LABEL_2_MEDIUM}
              className="col-span-4"
            >
              <span className="text-content-default">{dispense.quantity}</span>
            </CorText>
          </div>
          <div className="border-image-circle" />
          <div className="flex flex-col sm:flex-row gap-x-2">
            <CorText
              variant={isMobile ? TextVariant.BODY_1_REGULAR : TextVariant.LABEL_2_REGULAR}
              className="col-span-4 w-[172px] flex-none"
            >
              <span className="text-content-muted">
                <T keyName={'viewDrug.additionalInfo'}>Informatii aditionale</T>
              </span>
            </CorText>
            <CorText
              variant={isMobile ? TextVariant.BODY_1_MEDIUM : TextVariant.LABEL_2_MEDIUM}
              className="col-span-4"
            >
              <span className="text-content-default">{dispense.additionalInfo}</span>
            </CorText>
          </div>
          <div className="border-image-circle" />
          <div className="flex flex-col sm:flex-row gap-x-2">
            <CorText
              variant={isMobile ? TextVariant.BODY_1_REGULAR : TextVariant.LABEL_2_REGULAR}
              className="col-span-4 w-[172px] flex-none"
            >
              <span className="text-content-muted">
                <T keyName={'viewDrug.retailPrice'}>Pretul cu amanuntul</T>
              </span>
            </CorText>
            <CorText
              variant={isMobile ? TextVariant.BODY_1_MEDIUM : TextVariant.LABEL_2_MEDIUM}
              className="col-span-4"
            >
              <span className="text-content-default">
                {Number(dispense.retailPrice).toFixed(2)}
              </span>
            </CorText>
          </div>
          <div className="border-image-circle" />
          <div className="flex flex-col sm:flex-row gap-x-2">
            <CorText
              variant={isMobile ? TextVariant.BODY_1_REGULAR : TextVariant.LABEL_2_REGULAR}
              className="col-span-4 w-[172px] flex-none"
            >
              <span className="text-content-muted">
                <T keyName={'viewDrug.compensatedAmount'}>Suma compensata</T>
              </span>
            </CorText>
            <CorText
              variant={isMobile ? TextVariant.BODY_1_MEDIUM : TextVariant.LABEL_2_MEDIUM}
              className="col-span-4"
            >
              <span className="text-content-default">{dispense.compensatedAmount}</span>
            </CorText>
          </div>
          <div className="border-image-circle" />
          <div className="flex flex-col sm:flex-row gap-x-2">
            <CorText
              variant={isMobile ? TextVariant.BODY_1_REGULAR : TextVariant.LABEL_2_REGULAR}
              className="col-span-4 w-[172px] flex-none"
            >
              <span className="text-content-muted">
                <T keyName={'viewDrug.amountToBePaid'}>Suma Co-plata Pacient</T>
              </span>
            </CorText>
            <CorText
              variant={isMobile ? TextVariant.BODY_1_MEDIUM : TextVariant.LABEL_2_MEDIUM}
              className="col-span-4"
            >
              <span className="text-content-default">{dispense.amountToBePaid}</span>
            </CorText>
          </div>
        </div>
        <div className="flex items-center justify-end gap-2 sm:col-span-12">
          <CorButton variant={ButtonVariant.SECONDARY}>
            <button
              className="flex items-center justify-center gap-2 w-fit h-10 text-action-primary-default"
              onClick={() => deleteDispense(dispense)}
            >
              <CorText variant={TextVariant.LABEL_2_MEDIUM}>
                <span className="flex gap-2 items-center justify-center">
                  <T keyName={'viewDrug.deleteButton'}>Sterge</T>
                  <Trash width={16} height={16} />
                </span>
              </CorText>
            </button>
          </CorButton>
          <CorButton>
            <button
              className="flex items-center justify-center gap-2 w-fit h-10"
              onClick={() => {
                setDispense(dispense);
                setModalOpen(true);
              }}
            >
              <CorText variant={TextVariant.LABEL_2_MEDIUM}>
                <span className="flex gap-2 items-center justify-center">
                  <T keyName={'viewDrug.editButton'}>Editeaza</T>{' '}
                  <Edit width={16} height={16} color="currentColor" />
                </span>
              </CorText>
            </button>
          </CorButton>
        </div>
      </div>
    );
  };

  const renderAlerts = (releasesCount: number) => {
    if (releasesCount === 1) {
      return (
        <Alert
          text={t(
            'viewDrug.secondVisitWarningText',
            'Va aflati la penultima vizita la farmacie pentru eliberarea retetei dumneavoastra.',
          )}
          status={AlertStatus.WARNING}
        />
      );
    }
    if (releasesCount === 2) {
      return (
        <Alert
          text={t(
            'viewDrug.lastVisitWarningText',
            'Va aflati la ultima vizita la farmacie pentru eliberarea retetei dumneavoastra.',
          )}
          status={AlertStatus.WARNING}
        />
      );
    }
    if (releasesCount === 3) {
      return (
        <Alert
          text={t('viewDrug.noVisitsLeft', 'Toate trei eliberari posibila au fost efectuate')}
          status={AlertStatus.WARNING}
        />
      );
    }

    return null;
  };
  const renderAddButton = () => {
    if (expired || !(role === 'pharmacy' && location?.pathname?.includes('dispenses'))) {
      return null;
    }

    const dispensesCount: Record<string, number> = {};
    dispenses?.forEach((dispense) => {
      const localMemo: Record<string, boolean> = {};
      dispense.prescriptionReleases?.forEach((release: PrescriptionRelease) => {
        if (localMemo[release.dPrescriptionId]) {
          return;
        }
        localMemo[release.dPrescriptionId] = true;
        if (!dispensesCount[release.dPrescriptionId]) {
          dispensesCount[release.dPrescriptionId] = 0;
        }
        dispensesCount[release.dPrescriptionId]++;
      });
    });

    if (dispensesCount[dPrescriptionId] > 2) {
      return renderAlerts(3);
    }

    if (['perorale solide', 'perorale solide cu cedare prelungită'].includes(administrationRoute)) {
      const differenceQuantity = countAlreadyDispensed - Number(prescribedQuantity);
      if (filteredReleases?.length && differenceQuantity >= 0 && differenceQuantity <= 10) {
        return;
      }
    } else {
      if (
        filteredReleases?.length &&
        Math.abs(countAlreadyDispensed - Number(prescribedQuantity)) === 0
      ) {
        return;
      }
    }

    let showAddButton = Number(prescribedQuantity) - countDispenses > 0;

    if (showAddButton) {
      return (
        <>
          {renderAlerts(dispensesCount[dPrescriptionId])}
          <div
            className="flex w-full gap-2 rounded-2xl h-8 bg-decorative-surface-1 justify-center items-center cursor-pointer"
            onClick={() => setModalOpen(true)}
          >
            <CorText variant={TextVariant.LABEL_2_MEDIUM}>
              <span className="text-content-default">
                <T keyName="viewDrug.addDrug">Adaugă medicament</T>
              </span>
            </CorText>
            <Plus width={16} height={16} color="currentColor" />
          </div>
        </>
      );
    }
  };

  const renderReleases = () => {
    if (filteredReleases?.length === 0) {
      return null;
    }
    return (
      <div className="pt-5 my-5 border-solid border-t border-0 border-border-inverted-selected">
        <div className="grid grid-cols-12">
          <CorText variant={TextVariant.LABEL_1_REGULAR} className="col-span-4">
            <span>
              <T keyName="viewDrug.releasedDrug">Medicament Eliberat</T>
            </span>
          </CorText>
          <div className="col-span-8 flex flex-col gap-2">
            {filteredReleases?.map((item: PrescriptionRelease) => (
              <div
                key={hash(item)}
                className="grid grid-cols-10 w-full p-4 bg-decorative-neutral-1 rounded-xl gap-2"
              >
                <CorText variant={TextVariant.LABEL_2_REGULAR} className="col-span-4">
                  <span className="text-content-muted">
                    <T keyName="viewDrug.drugCommercialName">Denumirea comerciala</T>
                  </span>
                </CorText>
                <CorText variant={TextVariant.LABEL_2_MEDIUM} className="col-span-6">
                  <span>{item?.drugCommercialName}</span>
                </CorText>
                <div className="col-span-10 border-image-circle" />
                <CorText variant={TextVariant.LABEL_2_REGULAR} className="col-span-4">
                  <span className="text-content-muted">
                    <T keyName="viewDrug.quantity">Cantitatea eliberata</T>
                  </span>
                </CorText>
                <CorText variant={TextVariant.LABEL_2_MEDIUM} className="col-span-6">
                  <span>{item.quantity}</span>
                </CorText>
                <div className="col-span-10 border-image-circle" />
                <CorText variant={TextVariant.LABEL_2_REGULAR} className="col-span-4">
                  <span className="text-content-muted">
                    <T keyName="viewDrug.additionalInfo">Informatii aditionale</T>
                  </span>
                </CorText>
                <CorText variant={TextVariant.LABEL_2_MEDIUM} className="col-span-6">
                  <span>{item.additionalInfo}</span>
                </CorText>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  return (
    <Accordion
      hasBorderBottom={hasBorderBottom}
      name={activeSubstance}
      count={
        Number(prescribedQuantity) - countDispenses <= 0
          ? 0
          : Number(prescribedQuantity) - countDispenses
      }
      quantity={prescribedQuantity}
      totallyDispensed={totallyDispensed}
      expired={expired}
    >
      <CorBox boxStyle="none" className="flex flex-col gap-5 md:px-12 md:pb-6 pt-2">
        <div className="flex flex-col 2md:grid 2md:grid-cols-12 gap-3">
          <CorText
            variant={isMobile ? TextVariant.LABEL_1_MEDIUM : TextVariant.LABEL_1_REGULAR}
            className="col-span-4"
          >
            <span className="text-content-default">
              <T keyName="viewPrescription.prescribed">Medicament prescris</T>
            </span>
          </CorText>
          <div className="pl-2 2md:pl-0 border-0 border-l-[1px] md:border-0 border-solid border-l-border-default col-span-8 flex flex-col gap-3 2md:gap-2">
            <div className="flex flex-col sm:flex-row gap-x-2">
              <CorText
                variant={isMobile ? TextVariant.BODY_1_REGULAR : TextVariant.LABEL_2_REGULAR}
                className="col-span-4 w-[172px] flex-none"
              >
                <span className="text-content-muted">
                  <T keyName="viewDrug.activeSubstance">Substanta activa</T>
                </span>
              </CorText>
              <CorText
                variant={isMobile ? TextVariant.BODY_1_MEDIUM : TextVariant.LABEL_2_MEDIUM}
                className="col-span-4"
              >
                <span className="text-content-default">{activeSubstance}</span>
              </CorText>
            </div>
            <div className="border-image-circle" />
            <div className="flex flex-col sm:flex-row gap-x-2">
              <CorText
                variant={isMobile ? TextVariant.BODY_1_REGULAR : TextVariant.LABEL_2_REGULAR}
                className="col-span-4 w-[172px] flex-none"
              >
                <span className="text-content-muted">
                  <T keyName="viewDrug.routeOfAdministration">Calea de administrare</T>
                </span>
              </CorText>
              <CorText
                variant={isMobile ? TextVariant.BODY_1_MEDIUM : TextVariant.LABEL_2_MEDIUM}
                className="col-span-4"
              >
                <span className="text-content-default">{administrationRoute}</span>
              </CorText>
            </div>
            <div className="border-image-circle" />
            <div className="flex sm:flex-col gap-3 2md:gap-2">
              <div className="flex-1 sm:flex-none flex flex-col sm:flex-row gap-x-2">
                <CorText
                  variant={isMobile ? TextVariant.BODY_1_REGULAR : TextVariant.LABEL_2_REGULAR}
                  className="col-span-4 w-[172px] flex-none"
                >
                  <span className="text-content-muted">
                    <T keyName="viewDrug.dosage">Doza</T>
                  </span>
                </CorText>
                <CorText
                  variant={isMobile ? TextVariant.BODY_1_MEDIUM : TextVariant.LABEL_2_MEDIUM}
                  className="col-span-4"
                >
                  <span className="text-content-default">{dosage}</span>
                </CorText>
              </div>
              {!isMobile ? <div className="border-image-circle" /> : null}
              <div className="flex-1 sm:flex-none flex flex-col sm:flex-row gap-x-2">
                <CorText
                  variant={isMobile ? TextVariant.BODY_1_REGULAR : TextVariant.LABEL_2_REGULAR}
                  className="col-span-4 w-[172px] flex-none"
                >
                  <span className="text-content-muted">
                    <T keyName="viewDrug.prescribedQuantity">Cantitatea</T>
                  </span>
                </CorText>
                <CorText
                  variant={isMobile ? TextVariant.BODY_1_MEDIUM : TextVariant.LABEL_2_MEDIUM}
                  className="col-span-4"
                >
                  <span className="text-content-default">{prescribedQuantity}</span>
                </CorText>
              </div>
            </div>
            <div className="border-image-circle" />
            <div className="flex flex-col sm:flex-row gap-x-2">
              <CorText
                variant={isMobile ? TextVariant.BODY_1_REGULAR : TextVariant.LABEL_2_REGULAR}
                className="col-span-4 w-[172px] flex-none"
              >
                <span className="text-content-muted">
                  <T keyName="viewDrug.treatmentDuration">Durata Tratamentului</T>
                </span>
              </CorText>
              <CorText
                variant={isMobile ? TextVariant.BODY_1_MEDIUM : TextVariant.LABEL_2_MEDIUM}
                className="col-span-4"
              >
                <span className="text-content-default">{treatmentDuration}</span>
              </CorText>
            </div>
            <div className="border-image-circle" />
            <div className="flex flex-col sm:flex-row gap-x-2">
              <CorText
                variant={isMobile ? TextVariant.BODY_1_REGULAR : TextVariant.LABEL_2_REGULAR}
                className="col-span-4 w-[172px] flex-none"
              >
                <span className="text-content-muted">
                  <T keyName="viewDrug.signature">Signatura</T>
                </span>
              </CorText>
              <CorText
                variant={isMobile ? TextVariant.BODY_1_MEDIUM : TextVariant.LABEL_2_MEDIUM}
                className="col-span-4"
              >
                <span className="text-content-default">{additionalInfo}</span>
              </CorText>
            </div>
          </div>
        </div>
        {renderReleases()}
        {localDispenses && localDispenses.length
          ? localDispenses.map((dispense) => renderDispense(dispense))
          : null}
        {renderAddButton()}
        {role == 'pharmacy' && isOpenModal ? (
          <DispensingModal
            onRefresh={onRefresh}
            products={products}
            isOpen={isOpenModal}
            onAdd={(values) => {
              saveDispenses(values);
            }}
            onEdit={(values) => {
              editDispense(values);
            }}
            onCancel={() => {
              setModalOpen(false);
              setDispense(undefined);
            }}
            type={DeviceOrDrug.DRUG}
            quantity={
              dispense
                ? Number(prescribedQuantity) - countDispenses + Number(dispense.quantity)
                : Number(prescribedQuantity) - countDispenses
            }
            dose={dosage}
            routeOfAdministration={administrationRoute}
            internationalName={activeSubstance}
            dispense={dispense}
            diagnosticsCode={diagnosticsCode}
          />
        ) : null}
      </CorBox>
    </Accordion>
  );
};

export default memo(ViewDrug);
