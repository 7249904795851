export enum ClinicRolesEnum {
  IMS_BRANCH = 'IMS_ADMIN_FILIALA',
  IMS_HEAD = 'IMS_ADMIN_CENTRAL',
}

export enum PharmacyRolesEnum {
  PHARMACIST_SUB = 'FARMACIST_SUB_FARM',
  PHARMACIST = 'FARMACIST_FARM',
  OPERATOR = 'OPERATOR_SUB_FARM',
  PSF_BRANCH = 'PSF_FILIALA',
  PSF_HEAD = 'PSF_CENTRAL',
  PSF_TECH = 'INTEGRATIONS_EDIT',
}

export enum AdminRolesEnum {
  CNAM_DEM = 'CNAM_DEM',
  CNAM_CONTROL_UNIT = 'UTILIZATOR_AUTORITATEA_DE_CONTROL',
  ADMIN_CNAM = 'ADMIN_CNAM',
  OPERATOR_AT = 'OPERATOR_AT',
}
