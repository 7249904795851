import hash from 'object-hash';
import { useState } from 'react';
import { CorIcon, CorInput, CorText } from '@e-reteta/react-design-system';

import { TextVariant } from '../../../enums/design-system.enum';
import { IconColor, IconName } from '../../../enums/design-system-icons.enum';
import { PlusInputReportProps } from './plus-input-report.interface';

const PlusInputReport = (props: PlusInputReportProps) => {
  const {
    placeholder,
    name,
    label,
    addNewChip,
    className,
    autoComplete,
    error,
    items,
    setItems,
    searchItem,
    selectedItem,
    displayKey,
  } = props;

  const [value, setValue] = useState<string>('');

  const handleChange = (value: string) => {
    setValue(value);
    if (searchItem) {
      searchItem(value);
    }
  };

  const handleAddChip = () => {
    addNewChip(value);
    setValue('');
  };

  const handleClickItem = (value: string) => {
    if (!selectedItem?.includes(value)) {
      addNewChip(value);
    }
    if (setItems) {
      setItems([]);
    }
    setValue('');
  };

  return (
    <div className={className}>
      <CorText variant={TextVariant.LABEL_1_MEDIUM} className="mb-1">
        <div className="text-content-default">{label}</div>
      </CorText>
      <div className="relative">
        <CorInput error={error}>
          <input
            id={name}
            autoComplete={autoComplete}
            name={name}
            placeholder={placeholder}
            value={value}
            onChange={(event) => handleChange(event.target.value)}
          />
          {error ? <span className="bottom-helper">{error}</span> : null}
        </CorInput>
        <CorIcon
          className="absolute top-3 right-4"
          name={IconName.PLUS}
          color={IconColor.CONTENT_MUTED}
          onClick={handleAddChip}
        />
        {items?.length ? (
          <div className="absolute top-12 border-border-default border border-solid shadow-elevation-1 w-full rounded-lg py-2 overflow-hidden bg-white z-50">
            {items.map((item) => (
              <div
                key={hash(item)}
                className="flex justify-between items-center py-2 px-4 hover:bg-surface-hover active:bg-surface-pressed"
                onClick={() => handleClickItem(item[displayKey])}
              >
                <CorText
                  variant={TextVariant.LABEL_2_REGULAR}
                  className="text-content-muted whitespace-nowrap"
                >
                  <div>{item[displayKey]}</div>
                </CorText>
                {selectedItem?.includes(item[displayKey]) ? (
                  <CorIcon name={IconName.CHECK} />
                ) : null}
              </div>
            ))}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default PlusInputReport;
