import { FC, SVGProps } from 'react';

const Calendar: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 1C8.82843 1 9.5 1.67157 9.5 2.5V3.49854H22.5V2.5C22.5 1.67157 23.1716 1 24 1C24.8284 1 25.5 1.67157 25.5 2.5V3.49854H26C28.4853 3.49854 30.5 5.51325 30.5 7.99853V25.9985C30.5 28.4838 28.4853 30.4985 26 30.4985H6C3.51472 30.4985 1.5 28.4838 1.5 25.9985V7.99853C1.5 5.51325 3.51472 3.49854 6 3.49854H6.5V2.5C6.5 1.67157 7.17157 1 8 1ZM4.5 13.6481V25.9985C4.5 26.827 5.17157 27.4985 6 27.4985H26C26.8284 27.4985 27.5 26.827 27.5 25.9985V13.6483C27.312 12.7783 26.4637 12 25.6154 12H6.38459C5.53636 12 4.68814 12.7782 4.5 13.6481Z"
        fill="#343841"
      />
      <g opacity="0.3">
        <path
          d="M18.668 16.6668C18.668 15.9305 19.2649 15.3335 20.0013 15.3335C20.7377 15.3335 21.3346 15.9305 21.3346 16.6668C21.3346 17.4032 20.7377 18.0002 20.0013 18.0002C19.2649 18.0002 18.668 17.4032 18.668 16.6668Z"
          fill="#343841"
        />
        <path
          d="M22.668 16.6668C22.668 15.9305 23.2649 15.3335 24.0013 15.3335C24.7377 15.3335 25.3346 15.9305 25.3346 16.6668C25.3346 17.4032 24.7377 18.0002 24.0013 18.0002C23.2649 18.0002 22.668 17.4032 22.668 16.6668Z"
          fill="#343841"
        />
        <path
          d="M24.0013 19.3335C23.2649 19.3335 22.668 19.9305 22.668 20.6668C22.668 21.4032 23.2649 22.0002 24.0013 22.0002C24.7377 22.0002 25.3346 21.4032 25.3346 20.6668C25.3346 19.9305 24.7377 19.3335 24.0013 19.3335Z"
          fill="#343841"
        />
        <path
          d="M18.668 20.6668C18.668 19.9305 19.2649 19.3335 20.0013 19.3335C20.7377 19.3335 21.3346 19.9305 21.3346 20.6668C21.3346 21.4032 20.7377 22.0002 20.0013 22.0002C19.2649 22.0002 18.668 21.4032 18.668 20.6668Z"
          fill="#343841"
        />
        <path
          d="M16.0013 19.3335C15.2649 19.3335 14.668 19.9305 14.668 20.6668C14.668 21.4032 15.2649 22.0002 16.0013 22.0002C16.7377 22.0002 17.3346 21.4032 17.3346 20.6668C17.3346 19.9305 16.7377 19.3335 16.0013 19.3335Z"
          fill="#343841"
        />
        <path
          d="M10.668 20.6668C10.668 19.9305 11.2649 19.3335 12.0013 19.3335C12.7377 19.3335 13.3346 19.9305 13.3346 20.6668C13.3346 21.4032 12.7377 22.0002 12.0013 22.0002C11.2649 22.0002 10.668 21.4032 10.668 20.6668Z"
          fill="#343841"
        />
        <path
          d="M8.0013 19.3335C7.26492 19.3335 6.66797 19.9305 6.66797 20.6668C6.66797 21.4032 7.26492 22.0002 8.0013 22.0002C8.73768 22.0002 9.33464 21.4032 9.33464 20.6668C9.33464 19.9305 8.73768 19.3335 8.0013 19.3335Z"
          fill="#343841"
        />
        <path
          d="M22.668 24.6668C22.668 23.9305 23.2649 23.3335 24.0013 23.3335C24.7377 23.3335 25.3346 23.9305 25.3346 24.6668C25.3346 25.4032 24.7377 26.0002 24.0013 26.0002C23.2649 26.0002 22.668 25.4032 22.668 24.6668Z"
          fill="#343841"
        />
        <path
          d="M20.0013 23.3335C19.2649 23.3335 18.668 23.9305 18.668 24.6668C18.668 25.4032 19.2649 26.0002 20.0013 26.0002C20.7377 26.0002 21.3346 25.4032 21.3346 24.6668C21.3346 23.9305 20.7377 23.3335 20.0013 23.3335Z"
          fill="#343841"
        />
        <path
          d="M14.668 24.6668C14.668 23.9305 15.2649 23.3335 16.0013 23.3335C16.7377 23.3335 17.3346 23.9305 17.3346 24.6668C17.3346 25.4032 16.7377 26.0002 16.0013 26.0002C15.2649 26.0002 14.668 25.4032 14.668 24.6668Z"
          fill="#343841"
        />
        <path
          d="M12.0013 23.3335C11.2649 23.3335 10.668 23.9305 10.668 24.6668C10.668 25.4032 11.2649 26.0002 12.0013 26.0002C12.7377 26.0002 13.3346 25.4032 13.3346 24.6668C13.3346 23.9305 12.7377 23.3335 12.0013 23.3335Z"
          fill="#343841"
        />
        <path
          d="M6.66797 24.6668C6.66797 23.9305 7.26492 23.3335 8.0013 23.3335C8.73768 23.3335 9.33464 23.9305 9.33464 24.6668C9.33464 25.4032 8.73768 26.0002 8.0013 26.0002C7.26492 26.0002 6.66797 25.4032 6.66797 24.6668Z"
          fill="#343841"
        />
      </g>
    </svg>
  );
};

export default Calendar;
