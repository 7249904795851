import { SVGProps } from 'react';

const Close = (props: SVGProps<any>) => {
  const { color = 'black' } = props;

  return (
    <svg
      width="40"
      height="40"
      {...props}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.929 10.5718C12.2781 9.92096 11.2228 9.92096 10.5719 10.5718C9.92105 11.2227 9.92106 12.278 10.5719 12.9289L17.6429 19.9999L10.5718 27.071C9.9209 27.7219 9.9209 28.7772 10.5718 29.428C11.2226 30.0789 12.2779 30.0789 12.9288 29.428L20 22.3569L27.0711 29.428C27.722 30.0789 28.7772 30.0789 29.4281 29.428C30.079 28.7771 30.079 27.7219 29.4281 27.071L22.357 19.9999L29.428 12.9289C30.0788 12.278 30.0788 11.2227 29.428 10.5719C28.7771 9.92098 27.7218 9.92098 27.0709 10.5719L20 17.6428L12.929 10.5718Z"
        fill={color}
      />
    </svg>
  );
};

export default Close;
