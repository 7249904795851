import { FC } from 'react';
import { CorIcon, CorText } from '@e-reteta/react-design-system';
import { useTolgee } from '@tolgee/react';

import type { LinkButtonProps } from './interface';
import { IconColor, IconSize } from '../../enums/design-system-icons.enum';
import { TextVariant } from '../../enums/design-system.enum';
import useWindowWidth from '../../hooks/use-window-width';

const LinkButton: FC<LinkButtonProps> = ({ text, iconName, onClick }) => {
  const { isTablet } = useWindowWidth();
  const tolgee = useTolgee(['pendingLanguage']);

  return (
    <button
      onClick={onClick}
      className={`flex 2md:flex-col flex-1 2md:flex-none p-2 2md:p-2.5 2lg:px-2 2lg:py-1 gap-1 2md:gap-0.5 items-center justify-center border-none outline-none bg-background-secondary
      cursor-pointer rounded-[8px] ${tolgee.getPendingLanguage() !== 'ru' ? 'w-[63px]' : ''}`}
    >
      <CorText className="flex sm:hidden 2lg:flex" variant={TextVariant.BODY_1_MEDIUM}>
        <span
          className={`text-content-muted ${
            tolgee.getPendingLanguage() === 'ru' ? '!text-[10px]' : ''
          }`}
        >
          {text}
        </span>
      </CorText>
      <CorIcon
        name={iconName}
        size={isTablet ? IconSize.MD : IconSize.SM}
        color={IconColor.CONTENT_MUTED}
      />
    </button>
  );
};

export default LinkButton;
