import { Outlet, Route } from 'react-router-dom';
import React, { useContext } from 'react';

import AdminGuardedRoute from '../Guards/AdminGuardedRoute';
import Navigation from '../../components/ManagementTool/Navigation';
import ManagementTool from '../../pages/ManagementTool';
import AdminReports from '../../pages/Reports/AdminReports';
import { NavigationItemProps } from '../../components/ManagementTool/Navigation/NavigationItem/navigation-item.interface';
import { AddressBook, Diagnostic, HardDrive, Patch, Receipt } from '../../icons';
import { NavigationEnum } from '../../enums/navigation.enum';
import Heart from '../../icons/Heart';
import Building from '../../icons/Building';
import Integrations from '../../icons/Integrations';
import { AdminRolesEnum } from '../../constants/roles.enum';
import { AuthContext } from '../../context/context';

const AdminWrapper = () => {
  const { hasRole } = useContext(AuthContext);

  const navigationItems: NavigationItemProps[] = [];

  if (hasRole(AdminRolesEnum.ADMIN_CNAM) || hasRole(AdminRolesEnum.OPERATOR_AT)) {
    navigationItems.push(
      {
        icon: Patch,
        itemKey: NavigationEnum.DRUGS,
        url: 'drugs',
      },
      {
        icon: HardDrive,
        itemKey: NavigationEnum.DEVICES,
        url: 'devices',
      },
      {
        icon: Diagnostic,
        itemKey: NavigationEnum.DIAGNOSTIC,
        url: 'diagnostics',
      },
      {
        icon: AddressBook,
        itemKey: NavigationEnum.SPECIALITIES,
        url: 'specialities',
      },
      {
        icon: Heart,
        itemKey: NavigationEnum.CLINICS,
        url: 'clinics',
      },
      {
        icon: Building,
        itemKey: NavigationEnum.PHARMACIES,
        url: 'pharmacies',
      },
      {
        icon: Integrations,
        itemKey: NavigationEnum.INTEGRATIONS,
        url: 'integrations',
      },
    );
  }
  navigationItems.push({
    icon: Receipt,
    itemKey: NavigationEnum.REPORTS,
    url: 'reports',
  });

  return (
    <AdminGuardedRoute>
      <Navigation items={navigationItems}>
        <Outlet />
      </Navigation>
    </AdminGuardedRoute>
  );
};
export const AdminRoutes = (
  <Route path="" element={<AdminWrapper />}>
    <Route path="/management-tool/reports" element={<AdminReports />} />
    <Route path="/management-tool/:type" element={<ManagementTool />} />
  </Route>
);
