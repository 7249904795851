import { CorInput, CorText } from '@e-reteta/react-design-system';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslate } from '@tolgee/react';
import { useFormikContext } from 'formik';
import { isAxiosError } from 'axios';
import hash from 'object-hash';

import { PharmaciesServices, PharmacyDto } from '../../../services/pharmacies.services';
import { InputIconDisplay, TextVariant } from '../../../enums/design-system.enum';
import { IconName } from '../../../enums/design-system-icons.enum';
import { handleError } from '../../../utils/handleRequestErrors';
import { Report126FormikProps } from './report126.interface';
import Check from '../../../icons/Check';

const Report126 = () => {
  const { t } = useTranslate();

  const formik = useFormikContext<Report126FormikProps>();
  const ref = useRef<HTMLDivElement>(null);
  const [isVisible, setVisible] = useState<boolean>(false);
  const [pharmacies, setPharmacies] = useState<PharmacyDto[]>([]);

  const handleSearchPharmacyByCode = (code: string) => {
    formik.setFieldValue('report126Code', code);
  };

  const handleSelectPharmacyByCode = (pharmacy: PharmacyDto) => {
    formik.setFieldValue('report126Code', pharmacy.code);
  };

  const handleClickOutside = (event: any) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const getPharmacies = async () => {
    const inst = await PharmaciesServices.getAll('admin', {
      codes: formik?.values?.report126Code ? [formik?.values?.report126Code] : [],
    });
    setPharmacies(inst?.data?.items || []);
  };

  useEffect(() => {
    if (!pharmacies?.length && formik?.values?.report126Code !== '') {
      getPharmacies();
    }
    const delayInputTimeoutId = setTimeout(async () => {
      try {
        await getPharmacies();
      } catch (error) {
        isAxiosError(error) && handleError(error);
      }
    }, 500);
    return () => clearTimeout(delayInputTimeoutId);
  }, [formik?.values?.report126Code]);

  return (
    <div className="mb-4 px-4">
      <div className="relative md:w-1/2" ref={ref}>
        <CorInput iconName={IconName.SEARCH} iconDisplay={InputIconDisplay.LEFT}>
          <input
            autoComplete="off"
            type="text"
            name="report126Code"
            placeholder={t('report126.branchCode', 'Introduceti cod filiala')}
            value={formik?.values?.report126Code}
            onClick={() => setVisible(true)}
            onChange={(event: any) => {
              handleSearchPharmacyByCode(event?.target?.value);
            }}
          />
        </CorInput>
        {isVisible && pharmacies && pharmacies?.length ? (
          <div className="absolute w-full max-h-[296px] overflow-y-scroll z-50 mt-1 py-2 rounded-[8px] border border-solid border-border-default bg-decorative-neutral-1">
            {pharmacies.map((item: PharmacyDto) => {
              return (
                <div
                  className="relative flex gap-2 justify-between w-full px-4 py-2 cursor-pointer group hover:bg-surface-hover"
                  key={hash(item)}
                  onClick={() => {
                    handleSelectPharmacyByCode(item);
                    setVisible(false);
                  }}
                >
                  <CorText variant={TextVariant.LABEL_2_REGULAR}>
                    <p className="text-content-muted group-hover:text-content-default">
                      {item.code}
                    </p>
                  </CorText>
                  {formik?.values?.report126Code === item.code ? <Check /> : null}
                </div>
              );
            })}
          </div>
        ) : null}
      </div>
      <div className="w-full border-0 border-t border-solid border-border-disabled mx-4 my-6" />
    </div>
  );
};

export default Report126;
