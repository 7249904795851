import { CorInput, CorRadioButton, CorText } from '@e-reteta/react-design-system';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { T, useTranslate } from '@tolgee/react';
import { useFormikContext } from 'formik';
import { isAxiosError } from 'axios';
import hash from 'object-hash';

import { Annex10DCFormikProps } from './annex10-dc.interface';
import { InputIconDisplay, TextVariant } from '../../../enums/design-system.enum';
import { IconName } from '../../../enums/design-system-icons.enum';
import { Drug } from '../../../types/Drug';
import Check from '../../../icons/Check';
import { DeviceDto, DeviceService } from '../../../services/device.service';
import { DrugService } from '../../../services/drug.service';
import { handleError } from '../../../utils/handleRequestErrors';
import { ReportSearchType } from '../report-search-type.enum';

const Annex10DC = () => {
  const formik = useFormikContext<Annex10DCFormikProps>();
  const ref = useRef<HTMLDivElement>(null);

  const { t } = useTranslate();

  const [isVisible, setVisible] = useState<boolean>(false);
  const [drugs, setDrugs] = useState<Drug[]>([]);
  const [devices, setDevices] = useState<DeviceDto[]>([]);

  const handleClickOutside = (event: any) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const getDrugs = async () => {
    let searchQuery = {};
    if (formik.values.annex10DCSearchBy === ReportSearchType.COMMERCIAL_COD) {
      searchQuery = {
        commercialCodes: formik?.values?.annex10DCInputValue
          ? [formik?.values?.annex10DCInputValue]
          : [],
      };
    }
    if (formik.values.annex10DCSearchBy === ReportSearchType.COMMERCIAL_NAME) {
      searchQuery = {
        commercialName: formik?.values?.annex10DCInputValue || undefined,
      };
    }
    const d = await DrugService.getAll('admin', searchQuery);
    let uniqueDrugs: Drug[] = [];

    const commercialCodes: number[] = [];
    const commercialNames: string[] = [];
    if (formik.values.annex10DCSearchBy === ReportSearchType.COMMERCIAL_COD) {
      if (d?.data?.items?.length) {
        uniqueDrugs = d?.data?.items?.filter((item) => {
          if (item.commercialCod) {
            if (!commercialCodes.includes(item.commercialCod)) {
              commercialCodes.push(item.commercialCod);
              return item;
            }
          }
        });
      }
    }
    if (formik.values.annex10DCSearchBy === ReportSearchType.COMMERCIAL_NAME) {
      if (d?.data?.items?.length) {
        uniqueDrugs = d?.data?.items?.filter((item) => {
          if (item.commercialName) {
            if (!commercialNames.includes(item.commercialName)) {
              commercialNames.push(item.commercialName);
              return item;
            }
          }
        });
      }
    }
    setDrugs(uniqueDrugs || []);
  };

  const getDevices = async () => {
    let searchQuery = {};
    if (formik.values.annex10DCSearchBy === ReportSearchType.COMMERCIAL_COD) {
      searchQuery = {
        commercialCodes: formik?.values?.annex10DCInputValue
          ? [formik?.values?.annex10DCInputValue]
          : [],
      };
    }
    if (formik.values.annex10DCSearchBy === ReportSearchType.COMMERCIAL_NAME) {
      searchQuery = {
        commercialName: formik?.values?.annex10DCInputValue || undefined,
      };
    }
    const d = await DeviceService.getAll('admin', searchQuery);
    let uniqueDevices: DeviceDto[] = [];

    const commercialCodes: string[] = [];
    const commercialNames: string[] = [];
    if (formik.values.annex10DCSearchBy === ReportSearchType.COMMERCIAL_COD) {
      if (d?.data?.items?.length) {
        uniqueDevices = d?.data?.items?.filter((item) => {
          if (item.commercialCod) {
            if (!commercialCodes.includes(item.commercialCod)) {
              commercialCodes.push(item.commercialCod);
              return item;
            }
          }
        });
      }
    }
    if (formik.values.annex10DCSearchBy === ReportSearchType.COMMERCIAL_NAME) {
      if (d?.data?.items?.length) {
        uniqueDevices = d?.data?.items?.filter((item) => {
          if (item.commercialName) {
            if (!commercialNames.includes(item.commercialName)) {
              commercialNames.push(item.commercialName);
              return item;
            }
          }
        });
      }
    }
    setDevices(uniqueDevices || []);
  };

  useEffect(() => {
    if (!(devices?.length || drugs?.length) && formik?.values?.annex10DCInputValue !== '') {
      if (formik.values.annex10DCProductType === ReportSearchType.DRUG) {
        setDevices([]);
        getDrugs();
      } else {
        setDrugs([]);
        getDevices();
      }
    }
    const delayInputTimeoutId = setTimeout(async () => {
      try {
        if (formik.values.annex10DCProductType === ReportSearchType.DRUG) {
          setDevices([]);
          getDrugs();
        } else {
          setDrugs([]);
          getDevices();
        }
      } catch (error) {
        isAxiosError(error) && handleError(error);
      }
    }, 500);
    return () => clearTimeout(delayInputTimeoutId);
  }, [formik?.values?.annex10DCInputValue, formik?.values?.annex10DCSearchBy]);

  const handleSearch = (value: string) => {
    formik.setFieldValue('annex10DCCommercialCod', undefined);
    formik.setFieldValue('annex10DCInputValue', value);
  };

  const handleSelect = (value: string, commercialCod?: string) => {
    formik.setFieldValue('annex10DCInputValue', value);
    if (commercialCod) {
      formik.setFieldValue('annex10DCCommercialCod', commercialCod);
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>, type?: string) => {
    formik.handleChange(event);
    if (type == 'annex10DCProductType') {
      formik.setFieldValue('annex10DCSearchBy', undefined);
      formik.setFieldValue('annex10DCReportType', undefined);
    }
    if (type == 'annex10DCSearchBy') {
      formik.setFieldValue('annex10DCReportType', undefined);
    }
    formik.setFieldValue('annex10DCInputValue', undefined);
  };

  const renderSearchBy = () => {
    if (formik.values.annex10DCProductType === undefined) {
      return null;
    }
    return (
      <>
        <CorText variant={TextVariant.LABEL_1_MEDIUM} className="px-4">
          <div className="text-content-default">
            <T keyName={'annex.searchBy'}>Cauta Dupa</T>
          </div>
        </CorText>
        <div className="flex flex-col px-4 pt-4 mb-4">
          <div className="flex gap-8">
            <CorRadioButton
              isChecked={ReportSearchType.COMMERCIAL_COD === formik?.values?.annex10DCSearchBy}
              disabled={false}
            >
              <label className="whitespace-nowrap">
                <T keyName={'annex.dcCode'}>{ReportSearchType.COMMERCIAL_COD}</T>
              </label>
              <input
                type="radio"
                name="annex10DCSearchBy"
                value={ReportSearchType.COMMERCIAL_COD}
                checked={ReportSearchType.COMMERCIAL_COD === formik?.values?.annex10DCSearchBy}
                onChange={(event) => handleChange(event, 'annex10DCSearchBy')}
                className="bg-background-secondary"
              />
            </CorRadioButton>
            <CorRadioButton
              isChecked={ReportSearchType.COMMERCIAL_NAME === formik?.values?.annex10DCSearchBy}
              disabled={false}
            >
              <label className="whitespace-nowrap">
                <T keyName={'annex.commercialName'}>{ReportSearchType.COMMERCIAL_NAME}</T>
              </label>
              <input
                type="radio"
                name="annex10DCSearchBy"
                value={ReportSearchType.COMMERCIAL_NAME}
                checked={ReportSearchType.COMMERCIAL_NAME === formik?.values?.annex10DCSearchBy}
                onChange={(event) => handleChange(event, 'annex10DCSearchBy')}
                className="bg-background-secondary"
              />
            </CorRadioButton>
          </div>
        </div>
      </>
    );
  };

  const renderDropDown = () => {
    if (formik.values.annex10DCSearchBy === undefined) {
      return null;
    }
    return (
      <>
        <div className="px-4 mt-4 mb-4">
          <div className="relative md:w-1/2" ref={ref}>
            <CorInput iconName={IconName.SEARCH} iconDisplay={InputIconDisplay.LEFT}>
              <input
                autoComplete="off"
                type="text"
                name="annex10DCInputValue"
                placeholder={
                  formik.values.annex10DCSearchBy === ReportSearchType.COMMERCIAL_COD
                    ? t('annex10.commercialCodePlaceholder', 'Introduceti cod comercial')
                    : t('annex10.institutionCodePlaceholder', 'Introduceti cod institutie')
                }
                value={formik?.values?.annex10DCInputValue || ''}
                onClick={() => setVisible(true)}
                onChange={(event: any) => {
                  handleSearch(event?.target?.value);
                }}
              />
            </CorInput>
            {isVisible && drugs && drugs?.length ? (
              <div className="absolute w-full max-h-[296px] overflow-y-scroll z-50 mt-1 py-2 rounded-[8px] border border-solid border-border-default bg-decorative-neutral-1">
                {drugs.map((item: Drug) => {
                  return formik.values.annex10DCSearchBy === ReportSearchType.COMMERCIAL_COD ? (
                    <div
                      className="relative flex gap-2 justify-between w-full px-4 py-2 cursor-pointer group hover:bg-surface-hover"
                      key={hash(item)}
                      onClick={() => {
                        handleSelect(item?.commercialCod?.toString() || '');
                        setVisible(false);
                      }}
                    >
                      <CorText variant={TextVariant.LABEL_2_REGULAR}>
                        <p className="text-content-muted group-hover:text-content-default">
                          {item?.commercialCod}
                        </p>
                      </CorText>
                      {formik?.values?.annex10DCInputValue === item?.commercialCod?.toString() ? (
                        <Check />
                      ) : null}
                    </div>
                  ) : (
                    <div
                      className="relative flex gap-2 justify-between w-full px-4 py-2 cursor-pointer group hover:bg-surface-hover"
                      key={hash(item)}
                      onClick={() => {
                        handleSelect(
                          item?.commercialName || '',
                          item?.commercialCod?.toString() || '',
                        );
                        setVisible(false);
                      }}
                    >
                      <CorText variant={TextVariant.LABEL_2_REGULAR}>
                        <p className="text-content-muted group-hover:text-content-default">
                          {item.commercialName}
                        </p>
                      </CorText>
                      {formik?.values?.annex10DCInputValue === item.commercialName ? (
                        <Check />
                      ) : null}
                    </div>
                  );
                })}
              </div>
            ) : null}
            {isVisible && devices && devices?.length ? (
              <div className="absolute w-full max-h-[296px] overflow-y-scroll z-50 mt-1 py-2 rounded-[8px] border border-solid border-border-default bg-decorative-neutral-1">
                {devices.map((item: DeviceDto) => {
                  return formik.values.annex10DCSearchBy === ReportSearchType.COMMERCIAL_COD ? (
                    <div
                      className="relative flex gap-2 justify-between w-full px-4 py-2 cursor-pointer group hover:bg-surface-hover"
                      key={hash(item)}
                      onClick={() => {
                        handleSelect(item?.commercialCod || '');
                        setVisible(false);
                      }}
                    >
                      <CorText variant={TextVariant.LABEL_2_REGULAR}>
                        <p className="text-content-muted group-hover:text-content-default">
                          {item.commercialCod}
                        </p>
                      </CorText>
                      {formik?.values?.annex10DCInputValue === item.commercialCod ? (
                        <Check />
                      ) : null}
                    </div>
                  ) : (
                    <div
                      className="relative flex gap-2 justify-between w-full px-4 py-2 cursor-pointer group hover:bg-surface-hover"
                      key={hash(item)}
                      onClick={() => {
                        handleSelect(
                          item?.commercialName || '',
                          item?.commercialCod?.toString() || '',
                        );
                        setVisible(false);
                      }}
                    >
                      <CorText variant={TextVariant.LABEL_2_REGULAR}>
                        <p className="text-content-muted group-hover:text-content-default">
                          {item.commercialName}
                        </p>
                      </CorText>
                      {formik?.values?.annex10DCInputValue === item.commercialName ? (
                        <Check />
                      ) : null}
                    </div>
                  );
                })}
              </div>
            ) : null}
          </div>
        </div>
        <div className="w-full border-0 border-t border-solid border-border-disabled mx-4 my-6" />
      </>
    );
  };

  const renderFields = () => {
    if (formik.values.annex10DCInputValue === undefined) {
      return null;
    }
    return (
      <>
        <CorText variant={TextVariant.LABEL_1_MEDIUM} className="px-4">
          <div className="text-content-default">
            <T keyName={'annex.reportType'}>Tip Raport</T>
          </div>
        </CorText>
        <div className="flex flex-col px-4 pt-4">
          <div className="flex gap-8 flex-wrap">
            <CorRadioButton
              isChecked={ReportSearchType.IS_DETAILED === formik?.values?.annex10DCReportType}
              disabled={false}
            >
              <label className="whitespace-nowrap">
                <T keyName={'annex.detailedReport'}>{ReportSearchType.IS_DETAILED}</T>
              </label>
              <input
                type="radio"
                name="annex10DCReportType"
                value={ReportSearchType.IS_DETAILED}
                checked={ReportSearchType.IS_DETAILED === formik?.values?.annex10DCReportType}
                onChange={formik?.handleChange}
                className="bg-background-secondary"
              />
            </CorRadioButton>
            <CorRadioButton
              isChecked={ReportSearchType.IS_GENERALIZED === formik?.values?.annex10DCReportType}
              disabled={false}
            >
              <label className="whitespace-nowrap">
                <T keyName={'annex.generalizedReport'}>{ReportSearchType.IS_GENERALIZED}</T>
              </label>
              <input
                type="radio"
                name="annex10DCReportType"
                value={ReportSearchType.IS_GENERALIZED}
                checked={ReportSearchType.IS_GENERALIZED === formik?.values?.annex10DCReportType}
                onChange={formik.handleChange}
                className="bg-background-secondary"
              />
            </CorRadioButton>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="flex flex-col">
      <CorText variant={TextVariant.LABEL_1_MEDIUM} className="px-4">
        <div className="text-content-default">
          <T keyName={'anex2.medicalProduct'}>Produsul medical</T>
        </div>
      </CorText>
      <div className="flex flex-col px-4 pt-4 mb-4">
        <div className="flex gap-8 flex-wrap">
          <CorRadioButton
            isChecked={ReportSearchType.DRUG === formik?.values?.annex10DCProductType}
            disabled={false}
          >
            <label className="whitespace-nowrap">
              <T keyName={'annex10.drug'}>{ReportSearchType.DRUG}</T>
            </label>
            <input
              type="radio"
              name="annex10DCProductType"
              value={ReportSearchType.DRUG}
              checked={ReportSearchType.DRUG === formik?.values?.annex10DCProductType}
              onChange={(event) => handleChange(event, 'annex10DCProductType')}
              className="bg-background-secondary"
            />
          </CorRadioButton>
          <CorRadioButton
            isChecked={ReportSearchType.DEVICE === formik?.values?.annex10DCProductType}
            disabled={false}
          >
            <label className="whitespace-nowrap">
              <T keyName={'annex10.device'}>{ReportSearchType.DEVICE}</T>
            </label>
            <input
              type="radio"
              name="annex10DCProductType"
              value={ReportSearchType.DEVICE}
              checked={ReportSearchType.DEVICE === formik?.values?.annex10DCProductType}
              onChange={(event) => handleChange(event, 'annex10DCProductType')}
              className="bg-background-secondary"
            />
          </CorRadioButton>
        </div>
      </div>
      {renderSearchBy()}
      {renderDropDown()}
      {renderFields()}
      <div className="w-full border-0 border-t border-solid border-border-disabled mx-4 my-6" />
    </div>
  );
};

export default Annex10DC;
