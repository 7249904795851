import { Disease } from '../types/Disease';
import { DeviceDto } from '../services/device.service';
import { DrugData, SpecialityData } from '../types/CommonTypes';
import { InstitutionDto } from '../services/institution.service';
import { PharmacyDto } from '../services/pharmacies.services';
import { Drug } from '../types/Drug';
import { Patient } from '../types/Patient';
import { PharmacySettingsDto } from '../services/pharmacy-settings.services';

export const isDrugValues = (values: any): values is DrugData => {
  return true;
};

export const isDeviceValues = (values: any): values is DeviceDto => {
  return true;
};

export const isDiagnosticValues = (values: any): values is Disease => {
  return true;
};

export const isSpecialityValues = (values: any): values is SpecialityData => {
  return true;
};

export const isClinicValues = (values: any): values is InstitutionDto => {
  return true;
};

export const isPharmacyValues = (values: any): values is PharmacyDto => {
  return true;
};

export const isIntegrationValues = (values: any): values is PharmacySettingsDto => {
  return true;
};

export const isDrug = (values: any): values is Drug => {
  return true;
};

export const isPatient = (value: any): value is Patient => {
  return (
    typeof value === 'object' &&
    value !== null &&
    'id' in value &&
    'isInsured' in value &&
    'firstName' in value &&
    'lastName' in value
  );
};
