import { CorText } from '@e-reteta/react-design-system';
import { useState } from 'react';
import classNames from 'classnames';

import { TextVariant } from '../../../../enums/design-system.enum';
import { AccordionProps } from './accordion.interface';
import { ChevronDown } from '../../../../icons';

const Accordion = (props: AccordionProps) => {
  const { title, description } = props;
  const [displayDescription, setDisplayDescription] = useState(false);
  return (
    <div
      className={classNames('border-0 border-b border-solid border-border-default py-4 px-6', {
        'bg-background-secondary': displayDescription,
      })}
    >
      <div
        className="flex gap-10 items-center justify-between cursor-pointer"
        onClick={() => {
          setDisplayDescription((prev) => !prev);
        }}
      >
        <CorText variant={TextVariant.HEADING_3_BOLD}>
          <div
            className={classNames('text-content-default', {
              'hover:text-action-primary-default': !displayDescription,
            })}
          >
            {title}
          </div>
        </CorText>
        <ChevronDown
          className={classNames('flex-none w-6 h-6 fill-action-primary-default cursor-pointer', {
            'fill-content-muted rotate-180': displayDescription,
          })}
        />
      </div>
      {displayDescription ? (
        <CorText variant={TextVariant.LABEL_2_MEDIUM}>
          <div className="text-content-default mt-5">{description}</div>
        </CorText>
      ) : null}
    </div>
  );
};

export default Accordion;
