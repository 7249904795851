import { useNavigate, useParams } from 'react-router-dom';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { CorBadge, CorIcon, CorText } from '@e-reteta/react-design-system';
import { T, useTranslate } from '@tolgee/react';

import { useGetPatient } from '../../hooks/use-get-patient';
import {
  GetPrescriptionResponse,
  PrescriptionServices,
} from '../../services/prescription.services';
import { IconName, IconSize } from '../../enums/design-system-icons.enum';
import { TextVariant } from '../../enums/design-system.enum';
import { getPrescriptionStatusColor } from '../../lib/getPrescriptionStatusColor';
import { ReceiptStatus } from '../../enums/ReceiptStatus';
import {
  getPrescriptionStatusTranslation,
  getPrescriptionStatusTranslationKey,
} from '../../lib/getPrescriptionStatusTranslation';
import { Alert, PatientMedicCard, PrescriptionForm } from '../../components';
import { AlertStatus } from '../../components/Alert/enum';
import useWindowWidth from '../../hooks/use-window-width';
import { AuthContext } from '../../context/context';
import { renderResponsiveClasses } from '../../utils/render-responsive-classes.utils';
import { PatientData } from '../../components/PatientMedicCard/interface';
import useQuery from '../../hooks/use-query';
import { HolographPrescription } from '../../types/HolographPrescription';

const Prescription = () => {
  //TODO: EC-209 update patient interface, add medicalUnit, remove id
  const { idnp, prescriptionId, action } = useParams();
  const navigate = useNavigate();
  const { isTablet, isMobile } = useWindowWidth();
  const { user, role } = useContext(AuthContext);
  const query = useQuery();

  const [isSigned] = useState<boolean>(false);
  const [prescription, setPrescription] = useState<GetPrescriptionResponse>();
  const [hPrescription, setHPrescription] = useState<HolographPrescription>();
  const [loadingQueue, setLoadingQueue] = useState<boolean[]>([]);

  const [patient] = useGetPatient({ idnp: idnp });
  const { t } = useTranslate();

  // useEffect(() => {
  //   if (loadingQueue?.length) {
  //     setLoading(true);
  //     return;
  //   }
  //   setLoading(false);
  // }, [loadingQueue.length]);

  const onGoBack = () => {
    const searchParams = new URLSearchParams(window.location.search);
    if (role === 'doctor') {
      if (action === 'edit' || action === 'clone') {
        navigate(`/patient/${idnp}/prescription/${prescriptionId}`);
      }
      if (!prescriptionId) {
        navigate(`/patient/${idnp}`);
      }
    }
    if (role === 'pharmacy') {
      navigate(`/holographic-recipe/${searchParams.get('holographId')}`);
    }
  };

  const getPrescription = useCallback(async () => {
    if (!prescriptionId || !role) {
      return;
    }
    try {
      loadingQueue.push(true);
      setLoadingQueue(loadingQueue.slice());
      const resp = await PrescriptionServices.getPrescriptionById(prescriptionId, role);
      setPrescription(resp);
    } finally {
      loadingQueue.pop();
      setLoadingQueue(loadingQueue.slice());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role, prescriptionId, user, loadingQueue]);

  const getHPrescription = async (id: string) => {
    try {
      loadingQueue.push(true);
      setLoadingQueue(loadingQueue.slice());
      const resp = await PrescriptionServices.getHolographPrescriptionById('pharmacy', id);
      setHPrescription(resp.data);
    } finally {
      loadingQueue.pop();
      setLoadingQueue(loadingQueue.slice());
    }
  };

  useEffect(() => {
    if (prescriptionId) {
      getPrescription();
    }
    const hPrescriptionId = query.get('holographId');
    if (hPrescriptionId) {
      getHPrescription(hPrescriptionId);
    }
    if (!role) {
      return;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prescriptionId, role, user, patient]);

  return (
    <div className="relative h-full w-full sm:p-4 lg:pl-6 lg:p-0">
      <div className="flex flex-col">
        <div className="flex flex-row justify-between">
          <div className="flex flex-row items-center gap-2 mb-4">
            <CorIcon
              size={IconSize.MD}
              name={IconName.ARROW_LEFT}
              className="cursor-pointer"
              onClick={onGoBack}
            />
            <div className="flex flex-col sm:flex-row sm:items-center justify-start gap-x-2">
              {!prescriptionId ? (
                <CorText
                  variant={renderResponsiveClasses(
                    isMobile,
                    isTablet,
                    TextVariant.HEADING_4_BOLD,
                    TextVariant.HEADING_3_BOLD,
                    TextVariant.HEADING_2_BOLD,
                  )}
                >
                  <h2>
                    <T keyName={'prescription.createNewPrescription'}>Creare rețeta noua</T>
                  </h2>
                </CorText>
              ) : (
                <CorText
                  variant={renderResponsiveClasses(
                    isMobile,
                    isTablet,
                    TextVariant.LABEL_1_REGULAR,
                    TextVariant.HEADING_3_BOLD,
                    TextVariant.HEADING_2_BOLD,
                  )}
                >
                  <h2>
                    {action === 'edit'
                      ? t('prescription.titleModifyText', 'Modificare')
                      : t('prescription.titleCloneText', 'Clonare')}
                    <T keyName={'prescription.recepieText'}>Rețeta</T>
                  </h2>
                </CorText>
              )}

              <CorText
                variant={renderResponsiveClasses(
                  isMobile,
                  isTablet,
                  TextVariant.LABEL_1_BOLD,
                  TextVariant.HEADING_3_BOLD,
                  TextVariant.HEADING_2_BOLD,
                )}
              >
                <h2>{prescriptionId ? prescriptionId : ''}</h2>
              </CorText>
            </div>
            {action !== 'clone' && prescription?.data?.status ? (
              <CorBadge
                color={getPrescriptionStatusColor(prescription?.data.status as ReceiptStatus)}
              >
                <span className="capitalize">
                  <T keyName={getPrescriptionStatusTranslationKey(prescription?.data.status)}>
                    {getPrescriptionStatusTranslation(prescription?.data.status as ReceiptStatus)}
                  </T>
                </span>
              </CorBadge>
            ) : null}
          </div>
        </div>
        {isSigned ? (
          <Alert
            text={t('prescription.signSuccessText', 'Semnarea a fost efectuata cu succes')}
            className="h-fit w-full"
            status={AlertStatus.SUCCESS}
          />
        ) : null}
        <PatientMedicCard
          patient={patient as PatientData}
          patientIdentifier={idnp}
          doctor={hPrescription?.doctor}
          user={user}
          status="prescribed"
        />
        <PrescriptionForm role={role ? role : ''} limit={hPrescription ? 1 : undefined} />
      </div>
    </div>
  );
};

export default Prescription;
