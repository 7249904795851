import { CorDatepicker, CorInput, CorText, CorToggle } from '@e-reteta/react-design-system';
import { T, useTolgee, useTranslate } from '@tolgee/react';
import { FC, memo, useCallback, useEffect, useRef, useState } from 'react';
import { useFormikContext } from 'formik';
import dayjs from 'dayjs';

import { TextVariant } from '../../../../enums/design-system.enum';
import { ModalContentProps } from '../interface';
import { PharmacyDto } from '../../../../services/pharmacies.services';
import { CorDatepickerCustomEvent } from '../../../../types/CommonTypes';
import { getLocale } from '../../../../utils/getLocale';

const PharmacyModal: FC<ModalContentProps> = ({ isEdit }) => {
  const { t } = useTranslate();
  const tolgee = useTolgee(['pendingLanguage']);

  const formik = useFormikContext<PharmacyDto>();

  const [datePickerInfo, setDatePickerInfo] = useState({
    licenseValidity: {
      distance: 0,
      position: false,
    },
    validUntilTheDate: {
      distance: 0,
      position: false,
    },
  });

  const parentModal = useRef<HTMLInputElement>(null);

  const updateDatePickerDistance = useCallback(
    (pickerName: string) => {
      const parentRect = parentModal.current?.getBoundingClientRect();
      const datePickerRect = document.querySelector(`#${pickerName}`)?.getBoundingClientRect();

      if (datePickerRect && parentRect) {
        const distanceTop = datePickerRect?.top - parentRect.top;
        const distanceBottom = datePickerRect?.bottom - parentRect.bottom;

        const position = distanceTop > distanceBottom ? true : false;

        setDatePickerInfo({
          ...datePickerInfo,
          [pickerName]: {
            distanceTop,
            position,
          },
        });
      }
    },
    [datePickerInfo],
  );

  useEffect(() => {
    const handleResize = () => {
      updateDatePickerDistance('licenseValidity');
      updateDatePickerDistance('validUntilTheDate');
    };

    window.addEventListener('resize', handleResize);

    updateDatePickerDistance('licenseValidity');
    updateDatePickerDistance('validUntilTheDate');

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const callback = useCallback(
    (event: CorDatepickerCustomEvent<string>, field: string) => {
      event.preventDefault();
      formik.setFieldValue(field, dayjs(event.detail).toISOString());
    },
    [formik],
  );

  const handleToggle = async (id: string, value: boolean) => {
    await formik.setFieldValue(id, value);
  };

  return (
    <div ref={parentModal} className="px-8 mb-6 overflow-auto scrollbar-hide">
      <div className="flex flex-col gap-4">
        <div className="grid grid-cols-2 gap-4">
          <div className="flex flex-col gap-1">
            <CorText variant={TextVariant.LABEL_2_REGULAR}>
              <div className="text-content-muted">
                <T keyName={'pharmacyModal.code'}>Codul filealei</T>
              </div>
            </CorText>
            <CorInput disabled={isEdit} error={!!formik.errors.code}>
              <input
                autoComplete="off"
                type="text"
                name="code"
                placeholder={t('pharmacyModal.codePlaceholder', 'Introduceti codul')}
                value={formik.values.code}
                onChange={formik.handleChange}
              />
              {formik.errors.code ? (
                <span className="bottom-helper">{formik.errors.code}</span>
              ) : null}
            </CorInput>
          </div>
          <div className="flex flex-col gap-1">
            <CorText variant={TextVariant.LABEL_2_REGULAR}>
              <div className="text-content-muted">
                <T keyName={'pharmacyModal.atAscribed'}>AT arondată</T>
              </div>
            </CorText>
            <CorInput error={!!formik.errors?.atAscribed}>
              <input
                autoComplete="off"
                name="atAscribed"
                placeholder={t('pharmacyModal.atAscribedPlaceholder', 'Introduceti AT arondata')}
                value={formik.values?.atAscribed}
                onChange={formik.handleChange}
              />
              {formik.errors?.atAscribed ? (
                <span className="bottom-helper">{formik.errors?.atAscribed}</span>
              ) : null}
            </CorInput>
          </div>
        </div>

        <div className="grid grid-cols-2 gap-4">
          <div className="flex flex-col gap-1">
            <CorText variant={TextVariant.LABEL_2_REGULAR}>
              <div className="text-content-muted">
                <T keyName={'pharmacyModal.PSFName'}>Denumirea PSF</T>
              </div>
            </CorText>
            <CorInput error={!!formik.errors.name}>
              <input
                autoComplete="off"
                name="name"
                placeholder={t('pharmacyModal.PSFNamePlaceholder', 'Introduceti Denumirea PSF')}
                value={formik.values.name}
                onChange={formik.handleChange}
              />
              {formik.errors.name ? (
                <span className="bottom-helper">{formik.errors.name}</span>
              ) : null}
            </CorInput>
          </div>

          <div className="flex flex-col gap-1">
            <CorText variant={TextVariant.LABEL_2_REGULAR}>
              <div className="text-content-muted">
                <T keyName={'pharmacyModal.district'}>Raion</T>
              </div>
            </CorText>
            <CorInput error={!!formik.errors.district}>
              <input
                autoComplete="off"
                name="district"
                placeholder={t('pharmacyModal.districtPlaceholder', 'Introduceti raionul')}
                value={formik.values.district}
                onChange={formik.handleChange}
              />
              {formik.errors.district ? (
                <span className="bottom-helper">{formik.errors.district}</span>
              ) : null}
            </CorInput>
          </div>
        </div>

        <div className="flex flex-col gap-1">
          <CorText variant={TextVariant.LABEL_2_REGULAR}>
            <div className="text-content-muted">
              <T keyName={'pharmacyModal.address'}>Adresa amplasării</T>
            </div>
          </CorText>
          <CorInput error={!!formik.errors.address}>
            <input
              autoComplete="off"
              name="address"
              placeholder={t('pharmacyModal.addressPlaceholder', 'Introduceti adresa')}
              value={formik.values.address}
              onChange={formik.handleChange}
            />
            {formik.errors.address ? (
              <span className="bottom-helper">{formik.errors.address}</span>
            ) : null}
          </CorInput>
        </div>

        <div className="grid grid-cols-2 gap-4">
          <div className="flex flex-col gap-1">
            <CorText variant={TextVariant.LABEL_2_REGULAR}>
              <div className="text-content-muted">
                <T keyName={'pharmacyModal.series'}>Seria, Nr. licenței</T>
              </div>
            </CorText>
            <CorInput error={!!formik.errors.series}>
              <input
                autoComplete="off"
                name="series"
                placeholder={t('pharmacyModal.seriesPlaceholder', 'Introduceti seria')}
                value={formik.values.series}
                onChange={formik.handleChange}
              />
              {formik.errors.series ? (
                <span className="bottom-helper">{formik.errors.series}</span>
              ) : null}
            </CorInput>
          </div>

          <div className="flex flex-col gap-1">
            <CorText variant={TextVariant.LABEL_2_REGULAR}>
              <div className="text-content-muted">
                <T keyName={'pharmacyModal.noLicense'}>Nr licentei</T>
              </div>
            </CorText>
            <CorInput error={!!formik.errors.noLicense}>
              <input
                autoComplete="off"
                name="noLicense"
                placeholder={t(
                  'pharmacyModal.noLicensePlaceholder',
                  'Introduceti numarul licentei',
                )}
                value={formik.values.noLicense}
                onChange={formik.handleChange}
              />
              {formik.errors.noLicense ? (
                <span className="bottom-helper">{formik.errors.noLicense}</span>
              ) : null}
            </CorInput>
          </div>
        </div>

        <div className="grid grid-cols-2 gap-4">
          <div className="flex flex-col gap-1">
            <CorText variant={TextVariant.LABEL_2_REGULAR}>
              <div className="text-content-muted">
                <T keyName={'pharmacyModal.id'}>IDNO</T>
              </div>
            </CorText>
            <CorInput error={!!formik.errors.idno}>
              <input
                autoComplete="off"
                name="idno"
                placeholder={t('pharmacyModal.idPlaceholder', 'Introduceti IDNO')}
                value={formik.values.idno}
                onChange={formik.handleChange}
              />
              {formik.errors.idno ? (
                <span className="bottom-helper">{formik.errors.idno}</span>
              ) : null}
            </CorInput>
          </div>

          <div className="flex flex-col gap-1">
            <CorText variant={TextVariant.LABEL_2_REGULAR}>
              <div className="text-content-muted">
                <T keyName={'pharmacyModal.licenseValidity'}>Valabilitatea (zi/luna/anul)</T>
              </div>
            </CorText>
            <CorInput error={!!formik.errors.licenseValidity}>
              <CorDatepicker
                id="licenseValidity"
                value={formik.values.licenseValidity}
                label="Date"
                name="licenseValidity"
                placeholder={t('pharmacyModal.licenseValidityPlaceholder', 'Select date')}
                onCallback={(e) => callback(e, 'licenseValidity')}
                language={getLocale(tolgee)}
                positionTop={datePickerInfo.licenseValidity.position}
              />
              {formik.errors.idno ? (
                <span className="bottom-helper">{formik.errors.licenseValidity}</span>
              ) : null}
            </CorInput>
          </div>
        </div>

        <div className="grid grid-cols-2 gap-4">
          <div className="flex flex-col gap-1">
            <CorText variant={TextVariant.LABEL_2_REGULAR}>
              <div className="text-content-muted">
                <T keyName={'pharmacyModal.type'}>Tipul farmaciei</T>
              </div>
            </CorText>
            <CorInput error={!!formik.errors.type}>
              <input
                autoComplete="off"
                name="type"
                placeholder={t('pharmacyModal.typePlaceholder', 'Introduceti tipul farmaciei')}
                value={formik.values.type}
                onChange={formik.handleChange}
              />
              {formik.errors.type ? (
                <span className="bottom-helper">{formik.errors.type}</span>
              ) : null}
            </CorInput>
          </div>

          <div className="flex flex-col gap-1">
            <CorText variant={TextVariant.LABEL_2_REGULAR}>
              <div className="text-content-muted">
                <T keyName={'pharmacyModal.accreditationCertificate'}>Certificat de acreditare</T>
              </div>
            </CorText>
            <CorInput>
              <input
                autoComplete="off"
                name="accreditationCertificate"
                placeholder={t(
                  'pharmacyModal.accreditationCertificatePlaceholder',
                  'Introduceti certificatul de acreditare',
                )}
                value={formik.values.accreditationCertificate}
                onChange={formik.handleChange}
              />
            </CorInput>
          </div>
        </div>

        <div className="grid grid-cols-2 gap-4">
          <div className="flex flex-col gap-1">
            <CorText variant={TextVariant.LABEL_2_REGULAR}>
              <div className="text-content-muted">
                <T keyName={'pharmacyModal.validUntilTheDate'}>Valabil pînă la</T>
              </div>
            </CorText>
            <CorDatepicker
              id="validUntilTheDate"
              value={formik.values.validUntilTheDate}
              label="Date"
              name="validUntilTheDate"
              placeholder={t('pharmacyModal.validUntilTheDatePlaceholder', 'Select date')}
              onCallback={(e) => callback(e, 'validUntilTheDate')}
              language={getLocale(tolgee)}
              positionTop={datePickerInfo.validUntilTheDate.position}
            />
          </div>

          <div className="flex flex-col gap-1">
            <CorText variant={TextVariant.LABEL_2_REGULAR}>
              <div className="text-content-muted">
                <T keyName={'pharmacyModal.branchCategory'}>Categoria filialei</T>
              </div>
            </CorText>
            <CorInput>
              <input
                autoComplete="off"
                name="branchCategory"
                placeholder={t(
                  'pharmacyModal.branchCategoryPlaceholder',
                  'Introduceti categoria filialei',
                )}
                value={formik.values.branchCategory}
                onChange={formik.handleChange}
              />
            </CorInput>
          </div>
        </div>

        <div className="grid grid-cols-2 gap-4">
          <div className="flex flex-col gap-1">
            <CorText variant={TextVariant.LABEL_2_REGULAR}>
              <div className="text-content-muted">
                <T keyName={'pharmacyModal.internalCodeOfTheBranch'}>Codul intern al filialei</T>
              </div>
            </CorText>
            <CorInput>
              <input
                autoComplete="off"
                name="internalCodeOfTheBranch"
                placeholder={t(
                  'pharmacyModal.internalCodeOfTheBranchPlaceholder',
                  'Introduceti codul intern al filialei',
                )}
                value={formik.values.internalCodeOfTheBranch}
                onChange={formik.handleChange}
              />
            </CorInput>
          </div>

          <div className="flex flex-col gap-1">
            <CorText variant={TextVariant.LABEL_2_REGULAR}>
              <div className="text-content-muted">
                <T keyName={'pharmacyModal.branchManager'}>Farmacist diriginte/Șef filială</T>
              </div>
            </CorText>
            <CorInput>
              <input
                autoComplete="off"
                name="branchManager"
                placeholder={t(
                  'pharmacyModal.branchManagerPlaceholder',
                  'Introduceti farmacist diriginte',
                )}
                value={formik.values.branchManager}
                onChange={formik.handleChange}
              />
            </CorInput>
          </div>
        </div>

        <div className="grid grid-cols-2 gap-4">
          <div className="flex flex-col gap-1">
            <CorText variant={TextVariant.LABEL_2_REGULAR}>
              <div className="text-content-muted">
                <T keyName={'pharmacyModal.workingHours'}>Orar de lucru</T>
              </div>
            </CorText>
            <CorInput>
              <input
                autoComplete="off"
                name="workingHours"
                placeholder={t(
                  'pharmacyModal.workingHoursPlaceholder',
                  'Introduceti orele de lucru',
                )}
                value={formik.values.workingHours}
                onChange={formik.handleChange}
              />
            </CorInput>
          </div>

          <div className="flex flex-col gap-1">
            <CorText variant={TextVariant.LABEL_2_REGULAR}>
              <div className="text-content-muted">
                <T keyName={'pharmacyModal.phone'}>Telefon</T>
              </div>
            </CorText>
            <CorInput>
              <input
                autoComplete="off"
                name="phone"
                placeholder={t('pharmacyModal.phonePlaceholder', 'Introduceti telefonul')}
                value={formik.values.phone}
                onChange={formik.handleChange}
              />
            </CorInput>
          </div>
        </div>

        <div className="grid grid-cols-2 gap-4">
          <div className="flex flex-col gap-1">
            <CorText variant={TextVariant.LABEL_2_REGULAR}>
              <div className="text-content-muted">
                <T keyName={'pharmacyModal.email'}>E-mail</T>
              </div>
            </CorText>
            <CorInput>
              <input
                autoComplete="off"
                name="email"
                placeholder={t('pharmacyModal.emailPlaceholder', 'Introduceti email-ul')}
                value={formik.values.email}
                onChange={formik.handleChange}
              />
            </CorInput>
          </div>

          <div className="flex flex-col gap-1">
            <CorText variant={TextVariant.LABEL_2_REGULAR}>
              <div className="text-content-muted">
                <T keyName={'pharmacyModal.contractNr'}>Contractul nr.</T>
              </div>
            </CorText>
            <CorInput>
              <input
                autoComplete="off"
                name="contractNr"
                placeholder={t(
                  'pharmacyModal.contractNrPlaceholder',
                  'Introduceti numarul contractului',
                )}
                value={formik.values.contractNr}
                onChange={formik.handleChange}
              />
            </CorInput>
          </div>

          <div className="flex flex-col gap-1">
            <CorToggle
              value={formik.values.vatPayer}
              className="text-sm"
              label={t('pharmacyModal.vatPayer', 'Plătitor TVA')}
              onClick={() => handleToggle('vatPayer', !formik.values.vatPayer)}
            >
              <input autoComplete="off" type="checkbox" value={String(formik.values.vatPayer)} />
            </CorToggle>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(PharmacyModal);
