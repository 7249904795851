import { FC, useCallback, useState } from 'react';
import { CorTabButton } from '@e-reteta/react-design-system';

import type { ToggleButtonProps } from './interface';

const ToggleButton: FC<ToggleButtonProps> = ({ value1, value2, onChange, defaultSelected }) => {
  const [active, setActive] = useState<string | undefined>(defaultSelected);

  const handleToggle = useCallback(
    (value: string) => {
      onChange(value);
      setActive(value);
    },
    [onChange],
  );

  return (
    <div className="flex flex-row rounded-lg p-1 border border-solid border-border-default relative overflow-hidden gap-1">
      <CorTabButton
        className="flex-1"
        label={value1}
        buttonId={value1}
        isActive={active === value1}
        onClick={() => handleToggle(value1)}
      />
      <CorTabButton
        className="flex-1"
        label={value2}
        buttonId={value2}
        isActive={active === value2}
        onClick={() => handleToggle(value2)}
      />
    </div>
  );
};

export default ToggleButton;
