import { CorInput, CorRadioButton, CorText } from '@e-reteta/react-design-system';
import React, { ChangeEvent, useContext, useEffect, useRef, useState } from 'react';
import { T, useTranslate } from '@tolgee/react';
import { isAxiosError } from 'axios';
import { useFormikContext } from 'formik';
import hash from 'object-hash';

import { InputIconDisplay, TextVariant } from '../../../enums/design-system.enum';
import { Annex11FormikProps } from './annex11.interface';
import { IconName } from '../../../enums/design-system-icons.enum';
import { Drug } from '../../../types/Drug';
import Check from '../../../icons/Check';
import { DeviceDto, DeviceService } from '../../../services/device.service';
import { DrugService } from '../../../services/drug.service';
import { handleError } from '../../../utils/handleRequestErrors';
import { ReportSearchType } from '../report-search-type.enum';
import { AuthContext } from '../../../context/context';

const Annex11 = () => {
  const formik = useFormikContext<Annex11FormikProps>();
  const { t } = useTranslate();
  const ref = useRef<HTMLDivElement>(null);
  const { role, user } = useContext(AuthContext);

  const [isVisible, setVisible] = useState<boolean>(false);
  const [drugs, setDrugs] = useState<Drug[]>([]);
  const [devices, setDevices] = useState<DeviceDto[]>([]);

  const handleClickOutside = (event: any) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const getDrugs = async () => {
    if (role) {
      let searchQuery = {};
      if (formik.values.annex11ReportType === ReportSearchType.PER_COMMERCIAL_COD) {
        searchQuery = {
          commercialCodes: formik?.values?.annex11InputValue
            ? [formik?.values?.annex11InputValue]
            : [],
        };
      }
      if (formik.values.annex11ReportType === ReportSearchType.PER_COMMERCIAL_NAME) {
        searchQuery = {
          commercialName: formik?.values?.annex11InputValue,
        };
      }
      let uniqueDrugs: Drug[] = [];

      const commercialCodes: number[] = [];
      const commercialNames: string[] = [];
      const d = await DrugService.getAll(role, searchQuery);
      if (formik.values.annex11ReportType === ReportSearchType.PER_COMMERCIAL_COD) {
        if (d?.data?.items?.length) {
          uniqueDrugs = d?.data?.items?.filter((item) => {
            if (item.commercialCod) {
              if (!commercialCodes.includes(item.commercialCod)) {
                commercialCodes.push(item.commercialCod);
                return item;
              }
            }
          });
        }
      }
      if (formik.values.annex11ReportType === ReportSearchType.PER_COMMERCIAL_NAME) {
        if (d?.data?.items?.length) {
          uniqueDrugs = d?.data?.items?.filter((item) => {
            if (item.commercialName) {
              if (!commercialNames.includes(item.commercialName)) {
                commercialNames.push(item.commercialName);
                return item;
              }
            }
          });
        }
      }
      setDrugs(uniqueDrugs || []);
    }
  };

  const getDevices = async () => {
    if (role) {
      let searchQuery = {};
      if (formik.values.annex11ReportType === ReportSearchType.PER_COMMERCIAL_COD) {
        searchQuery = {
          commercialCodes: formik?.values?.annex11InputValue
            ? [formik?.values?.annex11InputValue]
            : [],
        };
      }
      if (formik.values.annex11ReportType === ReportSearchType.PER_COMMERCIAL_NAME) {
        searchQuery = {
          commercialName: formik?.values?.annex11InputValue,
        };
      }
      let uniqueDevices: DeviceDto[] = [];

      const commercialCodes: string[] = [];
      const commercialNames: string[] = [];
      const d = await DeviceService.getAll(role, searchQuery);
      if (formik.values.annex11ReportType === ReportSearchType.PER_COMMERCIAL_COD) {
        if (d?.data?.items?.length) {
          uniqueDevices = d?.data?.items?.filter((item) => {
            if (item.commercialCod) {
              if (!commercialCodes.includes(item.commercialCod)) {
                commercialCodes.push(item.commercialCod);
                return item;
              }
            }
          });
        }
      }
      if (formik.values.annex11ReportType === ReportSearchType.PER_COMMERCIAL_NAME) {
        if (d?.data?.items?.length) {
          uniqueDevices = d?.data?.items?.filter((item) => {
            if (item.commercialName) {
              if (!commercialNames.includes(item.commercialName)) {
                commercialNames.push(item.commercialName);
                return item;
              }
            }
          });
        }
      }
      setDevices(uniqueDevices || []);
    }
  };

  useEffect(() => {
    if (role === 'pharmacy') {
      formik?.setFieldValue('annex11PharmacyIDNO', user?.activeCompany?.idno);
    }
    if (!(devices?.length || drugs?.length) && formik?.values?.annex11InputValue !== '') {
      if (formik.values.annex11ProductType === ReportSearchType.DRUGS) {
        setDevices([]);
        getDrugs();
      } else {
        setDrugs([]);
        getDevices();
      }
    }
    const delayInputTimeoutId = setTimeout(async () => {
      try {
        if (formik.values.annex11ProductType === ReportSearchType.DRUGS) {
          setDevices([]);
          getDrugs();
        } else {
          setDrugs([]);
          getDevices();
        }
      } catch (error) {
        isAxiosError(error) && handleError(error);
      }
    }, 500);
    return () => clearTimeout(delayInputTimeoutId);
  }, [formik?.values?.annex11InputValue, formik?.values?.annex11ReportType]);

  const handleSearch = (value: string) => {
    formik.setFieldValue('annex11InputValue', value);
    formik.setFieldValue('annex11CommercialCod', undefined);
  };

  const handleSelect = (value: string, commercialCod?: string) => {
    formik.setFieldValue('annex11InputValue', value);
    if (commercialCod) {
      formik.setFieldValue('annex11CommercialCod', commercialCod);
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    formik.handleChange(event);
    formik.setFieldValue('annex11ReportType', undefined);
    formik.setFieldValue('annex11InputValue', undefined);
  };

  const renderDropdownPlaceholder = () => {
    if (ReportSearchType.PER_COMMERCIAL_COD === formik?.values?.annex11ReportType) {
      return 'Introduceto codul comercial';
    }
    if (ReportSearchType.PER_COMMERCIAL_NAME === formik?.values?.annex11ReportType) {
      return 'Introduceto denumirea comerciala';
    }
  };

  const renderTranslationKeys = () => {
    if (ReportSearchType.PER_COMMERCIAL_COD === formik?.values?.annex11ReportType) {
      return 'annex11InputValue.PER_COMMERCIAL_COD';
    }
    if (ReportSearchType.PER_COMMERCIAL_NAME === formik?.values?.annex11ReportType) {
      return 'annex11InputValue.PER_COMMERCIAL_NAME';
    }
  };

  const renderDropDown = () => {
    if (formik.values.annex11ReportType === undefined) {
      return null;
    }
    return (
      <>
        <div className="px-4 mt-4 mb-4">
          <div className="relative md:w-1/2" ref={ref}>
            <CorInput iconName={IconName.SEARCH} iconDisplay={InputIconDisplay.LEFT}>
              <input
                autoComplete="off"
                type="text"
                name="annex11InputValue"
                placeholder={t(renderTranslationKeys()!, renderDropdownPlaceholder())}
                value={formik?.values?.annex11InputValue || ''}
                onClick={() => setVisible(true)}
                onChange={(event: any) => {
                  handleSearch(event?.target?.value);
                }}
              />
            </CorInput>
            {isVisible && drugs && drugs?.length ? (
              <div className="absolute w-full max-h-[296px] overflow-y-scroll z-50 mt-1 py-2 rounded-[8px] border border-solid border-border-default bg-decorative-neutral-1">
                {drugs.map((item: Drug) => {
                  return formik.values.annex11ReportType === ReportSearchType.PER_COMMERCIAL_COD ? (
                    <div
                      className="relative flex gap-2 justify-between w-full px-4 py-2 cursor-pointer group hover:bg-surface-hover"
                      key={hash(item)}
                      onClick={() => {
                        handleSelect(item?.commercialCod?.toString() || '');
                        setVisible(false);
                      }}
                    >
                      <CorText variant={TextVariant.LABEL_2_REGULAR}>
                        <p className="text-content-muted group-hover:text-content-default">
                          {item?.commercialCod}
                        </p>
                      </CorText>
                      {formik?.values?.annex11InputValue === item?.commercialCod?.toString() ? (
                        <Check />
                      ) : null}
                    </div>
                  ) : (
                    <div
                      className="relative flex gap-2 justify-between w-full px-4 py-2 cursor-pointer group hover:bg-surface-hover"
                      key={hash(item)}
                      onClick={() => {
                        handleSelect(
                          item?.commercialName || '',
                          item?.commercialCod?.toString() || '',
                        );
                        setVisible(false);
                      }}
                    >
                      <CorText variant={TextVariant.LABEL_2_REGULAR}>
                        <p className="text-content-muted group-hover:text-content-default">
                          {item.commercialName}
                        </p>
                      </CorText>
                      {formik?.values?.annex11InputValue === item.commercialName ? <Check /> : null}
                    </div>
                  );
                })}
              </div>
            ) : null}
            {isVisible && devices && devices?.length ? (
              <div className="absolute w-full max-h-[296px] overflow-y-scroll z-50 mt-1 py-2 rounded-[8px] border border-solid border-border-default bg-decorative-neutral-1">
                {devices.map((item: DeviceDto) => {
                  return formik.values.annex11ReportType === ReportSearchType.PER_COMMERCIAL_COD ? (
                    <div
                      className="relative flex gap-2 justify-between w-full px-4 py-2 cursor-pointer group hover:bg-surface-hover"
                      key={hash(item)}
                      onClick={() => {
                        handleSelect(item?.commercialCod || '');
                        setVisible(false);
                      }}
                    >
                      <CorText variant={TextVariant.LABEL_2_REGULAR}>
                        <p className="text-content-muted group-hover:text-content-default">
                          {item.commercialCod}
                        </p>
                      </CorText>
                      {formik?.values?.annex11InputValue === item.commercialCod ? <Check /> : null}
                    </div>
                  ) : (
                    <div
                      className="relative flex gap-2 justify-between w-full px-4 py-2 cursor-pointer group hover:bg-surface-hover"
                      key={hash(item)}
                      onClick={() => {
                        handleSelect(
                          item?.commercialName || '',
                          item?.commercialCod?.toString() || '',
                        );
                        setVisible(false);
                      }}
                    >
                      <CorText variant={TextVariant.LABEL_2_REGULAR}>
                        <p className="text-content-muted group-hover:text-content-default">
                          {item.commercialName}
                        </p>
                      </CorText>
                      {formik?.values?.annex11InputValue === item.commercialName ? <Check /> : null}
                    </div>
                  );
                })}
              </div>
            ) : null}
          </div>
        </div>
      </>
    );
  };

  const handleChangeProductType = (event: ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue('annex11InputValue', undefined);
    formik.handleChange(event);
  };

  const renderReportType = () => {
    if (
      formik.values.annex11ProductType === undefined ||
      formik.values.annex11ProductType === ReportSearchType.ALL_PRODUCTS
    ) {
      return null;
    }
    return (
      <>
        <CorText variant={TextVariant.LABEL_1_MEDIUM} className="px-4">
          <div className="text-content-default">
            <T keyName={'annex.searchBy'}>Cauta Dupa</T>
          </div>
        </CorText>
        <div className="flex flex-col px-4 pt-4 mb-4">
          <div className="flex gap-8 flex-wrap">
            <CorRadioButton
              isChecked={ReportSearchType.PER_COMMERCIAL_COD === formik?.values?.annex11ReportType}
              disabled={false}
            >
              <label className="whitespace-nowrap">
                <T keyName={'annex.PerDCCode'}>{ReportSearchType.PER_COMMERCIAL_COD}</T>
              </label>
              <input
                type="radio"
                name="annex11ReportType"
                value={ReportSearchType.PER_COMMERCIAL_COD}
                checked={ReportSearchType.PER_COMMERCIAL_COD === formik?.values?.annex11ReportType}
                onChange={(event) => handleChangeProductType(event)}
                className="bg-background-secondary"
              />
            </CorRadioButton>
            <CorRadioButton
              isChecked={ReportSearchType.PER_COMMERCIAL_NAME === formik?.values?.annex11ReportType}
              disabled={false}
            >
              <label className="whitespace-nowrap">
                <T keyName={'annex.PerCommercialName'}>{ReportSearchType.PER_COMMERCIAL_NAME}</T>
              </label>
              <input
                type="radio"
                name="annex11ReportType"
                value={ReportSearchType.PER_COMMERCIAL_NAME}
                checked={ReportSearchType.PER_COMMERCIAL_NAME === formik?.values?.annex11ReportType}
                onChange={(event) => handleChangeProductType(event)}
                className="bg-background-secondary"
              />
            </CorRadioButton>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="flex flex-col">
      <CorText variant={TextVariant.LABEL_1_MEDIUM} className="px-4">
        <div className="text-content-default">
          <T keyName={'annex.reportType'}>Tip raport</T>
        </div>
      </CorText>
      <div className="flex flex-col px-4 pt-4 mb-4">
        <div className="flex gap-8 flex-wrap">
          <CorRadioButton
            isChecked={ReportSearchType.ALL_PRODUCTS === formik?.values?.annex11ProductType}
            disabled={false}
          >
            <label className="whitespace-nowrap">
              <T keyName={'annex.allProducts'}>{ReportSearchType.ALL_PRODUCTS}</T>
            </label>
            <input
              type="radio"
              name="annex11ProductType"
              value={ReportSearchType.ALL_PRODUCTS}
              checked={ReportSearchType.ALL_PRODUCTS === formik?.values?.annex11ProductType}
              onChange={(event) => handleChange(event)}
              className="bg-background-secondary"
            />
          </CorRadioButton>
          <CorRadioButton
            isChecked={ReportSearchType.DRUGS === formik?.values?.annex11ProductType}
            disabled={false}
          >
            <label className="whitespace-nowrap">
              <T keyName={'annex2.drugs'}>{ReportSearchType.DRUGS}</T>
            </label>
            <input
              type="radio"
              name="annex11ProductType"
              value={ReportSearchType.DRUGS}
              checked={ReportSearchType.DRUGS === formik?.values?.annex11ProductType}
              onChange={(event) => handleChange(event)}
              className="bg-background-secondary"
            />
          </CorRadioButton>
          <CorRadioButton
            isChecked={ReportSearchType.DEVICES === formik?.values?.annex11ProductType}
            disabled={false}
          >
            <label className="whitespace-nowrap">
              <T keyName={'annex2.devices'}>{ReportSearchType.DEVICES}</T>
            </label>
            <input
              type="radio"
              name="annex11ProductType"
              value={ReportSearchType.DEVICES}
              checked={ReportSearchType.DEVICES === formik?.values?.annex11ProductType}
              onChange={(event) => handleChange(event)}
              className="bg-background-secondary"
            />
          </CorRadioButton>
        </div>
      </div>
      {renderReportType()}
      {renderDropDown()}
      <div className="w-full border-0 border-t border-solid border-border-disabled mx-4 my-6" />
    </div>
  );
};

export default Annex11;
