import { memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { T } from '@tolgee/react';
import { CorText } from '@e-reteta/react-design-system';

import TableHeader from '../TableHeader';
import { ManagementTableProps } from '../interface';
import { ManagementTableContext } from '../context';
import { useDragScroll } from '../../../hooks/use-drag-scroll';
import { DeviceDto, DeviceService } from '../../../services/device.service';
import {
  deviceHeaders,
  diagnosticHeaders,
  drugHeader,
  institutionsHeaders,
  integrationsHeaders,
  pharmaciesHeaders,
  specialityHeaders,
} from '../constants';
import { DrugService } from '../../../services/drug.service';
import TableRow from '../TableRow';
import { DiagnosticServices } from '../../../services/diagnostics.services';
import { SpecialityDto, SpecialityService } from '../../../services/speciality.service';
import { InstitutionDto, InstitutionService } from '../../../services/institution.service';
import { PharmaciesServices, PharmacyDto } from '../../../services/pharmacies.services';
import { Drug } from '../../../types/Drug';
import { Disease } from '../../../types/Disease';
import { TextVariant } from '../../../enums/design-system.enum';
import { renderResponsiveClasses } from '../../../utils/render-responsive-classes.utils';
import useWindowWidth from '../../../hooks/use-window-width';

const Table = (props: ManagementTableProps) => {
  const { editItem } = props;
  const { type } = useParams();
  const { isMobile, isTablet } = useWindowWidth();
  const { fetchItems, items } = useContext(ManagementTableContext);
  const [showNotFoundScreen, setShowNotFoundScreen] = useState(false);
  const dragRef = useDragScroll<HTMLDivElement>({
    onX: true,
    onY: false,
    disabled: false,
    withGrabbingCursor: true,
    withGrabCursor: false,
  });

  const noItemsFound = items?.length ? items?.length < 1 : true;
  const onlyOneItem = items?.length === 1;

  const handleDelete = useCallback(
    async (item: Disease | PharmacyDto | Drug | DeviceDto | SpecialityDto | InstitutionDto) => {
      switch (type) {
        case 'devices':
          await DeviceService.delete((item as DeviceDto)?.id);
          break;
        case 'drugs':
          await DrugService.delete((item as Drug)?.id);
          break;
        case 'diagnostics':
          await DiagnosticServices.delete((item as Disease)?.code);
          break;
        case 'specialities':
          await SpecialityService.delete((item as SpecialityDto)?.name);
          break;
        case 'clinics':
          await InstitutionService.delete((item as InstitutionDto)?.codIms);
          break;
        case 'pharmacies':
          await PharmaciesServices.delete((item as PharmacyDto)?.code!);
          break;
      }
      fetchItems();
    },
    [type, fetchItems],
  );

  const getProps = useCallback(() => {
    switch (type) {
      case 'devices':
        return deviceHeaders;
      case 'drugs':
        return drugHeader;
      case 'diagnostics':
        return diagnosticHeaders;
      case 'specialities':
        return specialityHeaders;
      case 'clinics':
        return institutionsHeaders;
      case 'pharmacies':
        return pharmaciesHeaders;
      case 'integrations':
        return integrationsHeaders;
      default:
        return deviceHeaders;
    }
  }, [type]);

  const getTableRow = useCallback(
    (
      item: Disease | PharmacyDto | Drug | DeviceDto | SpecialityDto | InstitutionDto,
      lastItem: boolean,
      index: number,
    ) => {
      const headers = getProps();
      return (
        <TableRow
          key={index}
          item={item}
          editItem={editItem}
          isLast={lastItem}
          onDelete={() => handleDelete(item)}
          headers={headers}
          index={index}
        />
      );
    },
    [getProps, editItem, handleDelete],
  );

  const memorizedTableContent: JSX.Element = useMemo<JSX.Element>(
    () => (
      <>
        <TableHeader {...getProps()} />
        <main className="flex flex-col divide-x-0 divide-solid divide-y divide-border-default bg-background-primary">
          {items?.map((item, index) =>
            getTableRow(item, index === items?.length - 1 || onlyOneItem, index),
          )}
        </main>
      </>
    ),
    [getProps, items, getTableRow, onlyOneItem],
  );

  const notFoundScreen: JSX.Element = useMemo(() => {
    return (
      <div className="flex-1 flex items-center justify-center overflow-auto gap-4 p-4 my-6 flex-col h-[calc(100vh-148px)]">
        <div className="w-[350px] text-center">
          <CorText
            variant={renderResponsiveClasses(
              isMobile,
              isTablet,
              TextVariant.HEADING_4_BOLD,
              TextVariant.HEADING_3_BOLD,
              TextVariant.HEADING_2_BOLD,
            )}
          >
            <span className="text-content-default">
              <T keyName="table.notFoundScreen">
                Nu există rezultate care să se potrivească căutării tale.
              </T>
            </span>
          </CorText>
        </div>
        <img src="/not-found.png" alt="not found loop" className="w-[200px] sm:w-[400px]" />
      </div>
    );
  }, [isMobile, isTablet]);

  useEffect(() => {
    const delayTimeout = setTimeout(() => {
      setShowNotFoundScreen(true);
    }, 1000);

    return () => clearTimeout(delayTimeout);
  }, []);

  return (
    <div className="relative overflow-y-auto overflow-x-auto hide-scrollbar bg-background-primary select-none rounded-lg border-solid border border-border-default">
      <div ref={dragRef} className="overflow-x-auto overflow-y-hidden hide-scrollbar">
        {showNotFoundScreen && noItemsFound ? notFoundScreen : memorizedTableContent}
      </div>
    </div>
  );
};

export default memo(Table);
