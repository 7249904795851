import { CorInput, CorRadioButton, CorText } from '@e-reteta/react-design-system';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useFormikContext } from 'formik';
import { isAxiosError } from 'axios';
import { T, useTranslate } from '@tolgee/react';
import hash from 'object-hash';

import { Annex1Props } from './annex1.interface';
import { InstitutionDto, InstitutionService } from '../../../services/institution.service';
import { IconName } from '../../../enums/design-system-icons.enum';
import Check from '../../../icons/Check';
import { InputIconDisplay, TextVariant } from '../../../enums/design-system.enum';
import { handleError } from '../../../utils/handleRequestErrors';
import { AuthContext } from '../../../context/context';
import { ReportSearchType } from '../report-search-type.enum';
import GroupByContainer from '../GroupBy';

const Annex1 = (props: Annex1Props) => {
  const { report } = props;
  const { t } = useTranslate();
  const { role, user } = useContext(AuthContext);

  const formik = useFormikContext<any>();

  const [institutions, setInstitutions] = useState<InstitutionDto[]>([]);

  const [isVisible, setVisible] = useState<boolean>(false);

  const ref = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: any) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const getInstitutions = async () => {
    const inst = await InstitutionService.getAll('admin', {
      codIms: formik?.values[`${report}InstitutionCode`] || formik?.values[`${report}Institution`],
    });
    setInstitutions(inst?.data?.items || []);
  };

  useEffect(() => {
    formik.setFieldValue(`${report}ReportType`, ReportSearchType.TOTALIZER_REPORT);
  }, []);

  useEffect(() => {
    if (role !== 'admin') {
      return;
    }
    if (!institutions?.length && formik?.values?.institution !== '') {
      getInstitutions();
    }
    const delayInputTimeoutId = setTimeout(async () => {
      try {
        await getInstitutions();
      } catch (error) {
        isAxiosError(error) && handleError(error);
      }
    }, 500);
    return () => clearTimeout(delayInputTimeoutId);
  }, [formik?.values[`${report}Institution`], user?.activeCompany?.code]);

  const handleSearchInstitution = (code: string) => {
    formik.setFieldValue(`${report}Institution`, code);
    formik.setFieldValue(`${report}InstitutionCode`, undefined);
  };

  const handleSelectInstitution = (institution: InstitutionDto) => {
    formik.setFieldValue(`${report}Institution`, `${institution.codIms} - ${institution.imsp}`);
    formik.setFieldValue(`${report}InstitutionCode`, `${institution.codIms}`);
  };

  const handleGroupByInstitution = (fields: string[]) => {
    const groupBy: Record<string, '0' | '1'> = {};
    groupByFields.forEach((field) => {
      groupBy[field] = fields.indexOf(field) === -1 ? '0' : '1';
    });
    formik.setFieldValue(`${report}GroupBy`, groupBy);
  };

  let groupByFields = [
    'diagnosticCode',
    'diagnosticGroup',
    'diagnosticSubGroup',
    'internationalName',
    'dose',
  ];

  if (report === 'annex1ForDevices') {
    groupByFields = ['internationalName', 'producerName', 'internationalCode'];
  }
  if (report === 'annex4') {
    groupByFields = [
      'doctorSpeciality',
      'drugGroup',
      'institutionAt',
      'institutionCode',
      'institutionDistrict',
      'diagnosticCode',
      'diagnosticGroup',
      'diagnosticSubGroup',
    ];
  }
  const renderInstitutionsDropdown = () => {
    return (
      <>
        {role === 'admin' && (
          <>
            <div>
              <CorText variant={TextVariant.LABEL_1_MEDIUM} className="px-4">
                <div className="text-content-default">
                  <T keyName={'annex.reportType'}>Tipul raportului</T>
                </div>
              </CorText>
              <div className="flex flex-col px-4 py-4">
                <div className="flex gap-8">
                  <CorRadioButton
                    isChecked={
                      ReportSearchType.TOTALIZER_REPORT === formik?.values[`${report}ReportType`]
                    }
                    disabled={false}
                  >
                    <label className="whitespace-nowrap">
                      <T keyName={'annex.totalizerReport'}>{ReportSearchType.TOTALIZER_REPORT}</T>
                    </label>
                    <input
                      type="radio"
                      name={`${report}ReportType`}
                      value={ReportSearchType.TOTALIZER_REPORT}
                      checked={
                        ReportSearchType.TOTALIZER_REPORT === formik?.values[`${report}ReportType`]
                      }
                      onChange={formik.handleChange}
                      className="bg-background-secondary"
                    />
                  </CorRadioButton>
                  <CorRadioButton
                    isChecked={
                      ReportSearchType.PER_INSTITUTION_NAME ===
                      formik?.values[`${report}ReportType`]
                    }
                    disabled={false}
                  >
                    <label className="whitespace-nowrap">
                      <T keyName={'annex.perInstitution'}>{ReportSearchType.PER_INSTITUTION}</T>
                    </label>
                    <input
                      type="radio"
                      name={`${report}ReportType`}
                      value={ReportSearchType.PER_INSTITUTION}
                      checked={
                        ReportSearchType.PER_INSTITUTION === formik?.values[`${report}ReportType`]
                      }
                      onChange={formik.handleChange}
                      className="bg-background-secondary"
                    />
                  </CorRadioButton>
                </div>
              </div>
            </div>
            {formik?.values[`${report}ReportType`] === ReportSearchType.PER_INSTITUTION ? (
              <div className="relative md:w-1/2" ref={ref}>
                <CorInput iconName={IconName.SEARCH} iconDisplay={InputIconDisplay.LEFT}>
                  <input
                    autoComplete="off"
                    type="text"
                    name={`${report}Institution`}
                    placeholder={t('annex.institutionPlaceholder', 'Introduceti cod institutie')}
                    value={formik?.values[`${report}Institution`]}
                    onClick={() => setVisible(true)}
                    onChange={(event: any) => {
                      handleSearchInstitution(event?.target?.value);
                    }}
                  />
                </CorInput>
                {isVisible && institutions && institutions?.length ? (
                  <div className="absolute w-full max-h-[296px] overflow-y-scroll z-50 mt-1 py-2 rounded-[8px] border border-solid border-border-default bg-decorative-neutral-1">
                    {institutions.map((item: InstitutionDto) => {
                      return (
                        <div
                          className="relative flex gap-2 justify-between w-full px-4 py-2 cursor-pointer group hover:bg-surface-hover"
                          key={hash(item)}
                          onClick={() => {
                            handleSelectInstitution(item);
                            setVisible(false);
                          }}
                        >
                          <CorText variant={TextVariant.LABEL_2_REGULAR}>
                            <p className="text-content-muted group-hover:text-content-default">
                              {item.codIms} - {item.imsp}
                            </p>
                          </CorText>
                          {formik?.values[`${report}InstitutionCode`] === item.codIms ? (
                            <Check />
                          ) : null}
                        </div>
                      );
                    })}
                  </div>
                ) : null}
              </div>
            ) : null}
            <div className="w-full border-0 border-t border-solid border-border-disabled mx-4 my-6" />
          </>
        )}
        <div>
          <GroupByContainer
            name={t('annex1.groupBy', 'Grupeaza dupa')}
            fields={groupByFields.map((field) => {
              return {
                label: t(`annex1.${field}`, field),
                value: field,
              };
            })}
            preSelected={groupByFields}
            onChange={handleGroupByInstitution}
          />
        </div>
        <div className="w-full border-0 border-t border-solid border-border-disabled mx-4 my-6" />
      </>
    );
  };

  return <div className="flex flex-col">{renderInstitutionsDropdown()}</div>;
};

export default Annex1;
