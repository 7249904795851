import { CorBadge, CorBox, CorButton, CorInput, CorText } from '@e-reteta/react-design-system';
import React, { useState } from 'react';
import { isAxiosError } from 'axios';
import { SpinnerCircularFixed } from 'spinners-react';

import { AiResponseDto, AiService, Document } from '../../../services/ai.services';
import { handleError } from '../../../utils/handleRequestErrors';
import { InteractionTabProps } from './interaction-tab.interface';
import {
  BadgeColor,
  BadgeSize,
  ButtonVariant,
  TextVariant,
} from '../../../enums/design-system.enum';
import { T, useTranslate } from '@tolgee/react';
import Plus from '../../../icons/Plus';
import Dots from '../../../icons/Dots';
import { useFormik } from 'formik';
import Like from '../../../icons/Like';
import Dislike from '../../../icons/Dislike';
import { ChevronDown, Link } from '../../../icons';
import Alert from '../../Alert';
import { AlertStatus } from '../../Alert/enum';
import { formatDate } from '../../../utils/formatDate';

const InteractionTab = (props: InteractionTabProps) => {
  const { description, setNotification } = props;
  const [insight, setInsight] = useState<AiResponseDto>();
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setError] = useState<string>();
  const [notFound, setNotFound] = useState<boolean>(false);
  const [selectedCard, setSelectedCard] = useState<Document>();
  const { t } = useTranslate();

  const formik = useFormik({
    initialValues: {
      activeSubstance1: '',
      activeSubstance2: '',
    },
    onSubmit: (values) => {
      handleSearch(values);
    },
    isInitialValid: true,
  });

  const handleSearch = async (values: { activeSubstance1: string; activeSubstance2: string }) => {
    if (values.activeSubstance1 && values.activeSubstance2) {
      setLoading(true);
      const language = localStorage.getItem('saved_language');
      try {
        const response = await AiService.check({
          drugs: [values.activeSubstance1, values.activeSubstance2],
          language: language || 'ro',
        });
        setInsight(response.data);
        if (!response.data) {
          setNotFound(true);
        } else {
          setNotFound(false);
        }
        setNotification(true);
        setLoading(false);
        setError('');
      } catch (error) {
        if (error instanceof Error) {
          setError(error.toString());
        }
        isAxiosError(error) && handleError(error);
        setLoading(false);
        setNotFound(true);
      } finally {
        setLoading(false);
      }
    }
  };

  const openInNewTab = (url: string) => {
    window.open(url, '_blank');
  };

  const handleSelect = (doc: Document) => {
    setSelectedCard(doc);
  };

  return (
    <>
      {!!errorMessage?.length && (
        <div className="mx-6">
          <Alert text={errorMessage} className="h-fit w-full mt-5" status={AlertStatus.DANGER} />
        </div>
      )}
      <form onSubmit={formik.handleSubmit}>
        <div className="flex flex-col gap-5">
          <CorText className="pt-5 px-6">
            <div className="text-sm text-surface-neutral-inverted-hover">{description}</div>
          </CorText>
          <div className="px-6 flex flex-col gap-3 relative">
            <div>
              <CorInput className="w-[376px]">
                <input
                  className="rounded-2.5xl"
                  name="activeSubstance1"
                  type="text"
                  value={formik.values.activeSubstance1}
                  placeholder={t(
                    'einsteinDrawer.activeSubstance1',
                    'Introduceti substanta activa 1',
                  )}
                  onChange={formik.handleChange}
                />
              </CorInput>
            </div>
            <div className="absolute top-[calc(50%-30px)] right-9">
              <Dots className="fill-action-neutral-default"></Dots>
            </div>
            <div className="absolute top-[calc(50%-10px)] right-5">
              <Plus className="w-6 h-6" color="#A0A3A9"></Plus>
            </div>
            <div className="absolute rotate-90 top-[calc(50%+16px)] right-9">
              <Dots className="fill-action-neutral-default"></Dots>
            </div>
            <CorInput className="w-[376px]">
              <input
                className="rounded-2.5xl"
                name="activeSubstance2"
                type="text"
                value={formik.values.activeSubstance2}
                placeholder={t('einsteinDrawer.activeSubstance2', 'Introduceti substanta activa 2')}
                onChange={formik.handleChange}
              />
            </CorInput>
          </div>
          <CorButton
            variant={
              !formik.values.activeSubstance1 || !formik.values.activeSubstance2
                ? ButtonVariant.SECONDARY
                : ButtonVariant.PRIMARY
            }
            className="w-full px-6"
          >
            <button
              className="w-full disabled:text-action-neutral-default disabled:border-action-neutral-default"
              disabled={!formik.values.activeSubstance1 || !formik.values.activeSubstance2}
            >
              <T keyName="einsteinDrawer.interactionSearchButton">Verifica interactiunea</T>
            </button>
          </CorButton>
        </div>
      </form>
      {loading ? (
        <div className="flex-none w-full h-[calc(100vh-408px)] flex flex-col gap-8 justify-center items-center px-6">
          <SpinnerCircularFixed
            size={56}
            thickness={100}
            speed={100}
            color="#80C080"
            secondaryColor="#E6E6EA"
          />
          <CorText variant={TextVariant.HEADING_4_MEDIUM}>
            <h5 className="text-center text-surface-neutral-inverted-hover">
              <T keyName={'einsteinDrawer.loadingText'}>
                Vă rugăm să așteptați, raspunsul se încarcă...
              </T>
            </h5>
          </CorText>
        </div>
      ) : null}
      {!loading && notFound ? (
        <div className="flex-none w-full  h-[calc(100vh-408px)] flex flex-col gap-8 justify-center items-center px-6">
          <img
            src="/not-found-qa.png"
            alt="not-found"
            className="rounded-2xl cursor-pointer"
            height={127}
          />
          <CorText variant={TextVariant.HEADING_4_MEDIUM}>
            <h5 className="text-center">
              <T keyName={'einsteinDrawer.notFound'}>Nu a fost gasit nici un rezultat</T>
            </h5>
          </CorText>
        </div>
      ) : null}
      {!loading && !notFound && insight && !selectedCard && (
        <div className="bg-background-secondary px-6 h-[calc(100vh-408px)] pt-5 mt-5 border-t border-b-0 border-x-0 border-solid border-border-default overflow-x-auto">
          <CorText variant={TextVariant.BODY_1_REGULAR}>
            <p>{insight?.answer}</p>
          </CorText>
          <div className="flex gap-5 pt-5 pb-[30px]">
            <Like className="fill-transparent stroke-action-neutral-default hover:stroke-action-primary-default" />
            <Dislike className="fill-transparent stroke-action-neutral-default hover:stroke-action-primary-default" />
          </div>
          <div className="flex flex-col gap-5 mb-5">
            {insight?.documents?.map((doc) => {
              return (
                <CorBox
                  key={`art_${doc.id}`}
                  boxStyle="none"
                  flexDirection="column"
                  className="bg-white cursor-pointer p-4 border border-solid border-border-hover rounded-[18px] overflow-hidden h-fit"
                  onClick={() => handleSelect(doc)}
                >
                  <div className="flex justify-between mb-5">
                    <CorBadge color={BadgeColor.GREY} size={BadgeSize.SM}>
                      <span>{doc.index_name}</span>
                    </CorBadge>
                    <div className="flex gap-8 text-content-muted">
                      <CorText>
                        <div>{formatDate(doc.pubdate)}</div>
                      </CorText>
                      <CorText>
                        <div>{doc.no_citations}</div>
                      </CorText>
                    </div>
                  </div>
                  <CorText variant={TextVariant.BODY_1_BOLD}>
                    <p>{doc.title}</p>
                  </CorText>
                  <CorText variant={TextVariant.BODY_1_REGULAR}>
                    <p className="line-clamp-3">{doc.abs}</p>
                  </CorText>
                </CorBox>
              );
            })}
          </div>
        </div>
      )}
      {!loading && selectedCard ? (
        <div className="bg-background-secondary px-6 h-[calc(100vh-408px)] pt-5 mt-5 border-t border-b-0 border-x-0 border-solid border-border-default flex flex-col gap-5">
          <div className="flex justify-between">
            <div className="flex gap-5">
              <ChevronDown
                className="w-5 h-5 fill-action-primary-default rotate-90"
                onClick={() => setSelectedCard(undefined)}
              />
              <CorBadge color={BadgeColor.GREY} size={BadgeSize.SM}>
                <span>{selectedCard.index_name}</span>
              </CorBadge>
            </div>
            <div className="flex gap-8 text-content-muted">
              <CorText>
                <div>12.09.24</div>
              </CorText>
              <CorText>
                <div>13K</div>
              </CorText>
            </div>
          </div>
          <CorText variant={TextVariant.HEADING_4_BOLD}>
            <p className="text-content-default"> {selectedCard.title}</p>
          </CorText>
          <CorText variant={TextVariant.BODY_1_REGULAR}>
            <p className="text-content-default"> {selectedCard.abs}</p>
          </CorText>
          <CorText>
            <div className="text-content-muted">
              Authors:
              {selectedCard.authors.map(
                (author, index) =>
                  `${author.lastname} ${author.forename}${
                    index !== selectedCard.authors.length - 1 ? ', ' : ''
                  }`,
              )}
            </div>
          </CorText>
          <div className="flex flex-col gap-1.5">
            <div
              className="flex gap-2 border-b border-t-0 border-x-0 border-border-success-default border-solid items-center w-fit"
              onClick={() => openInNewTab(selectedCard?.source)}
            >
              <Link className="fill-white stroke-action-primary-default" />
              <CorText>
                <p className="text-content-success line-clamp-1"> {selectedCard.title}</p>
              </CorText>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default InteractionTab;
