import React, { FC, memo, useContext, MouseEvent } from 'react';
import { CorBox, CorCheckbox, CorText } from '@e-reteta/react-design-system';
import { useNavigate } from 'react-router-dom';
// import dayjs from 'dayjs';
import { T } from '@tolgee/react';

import { CheckboxSize, TextVariant } from '../../enums/design-system.enum';
import { HandwrittenTableProps } from './interface';
import { AuthContext } from '../../context/context';
import { RecepieData } from '../../types/CommonTypes';
import { formatDate } from '../../utils/formatDate';
// import { formatTimeDifference } from '../../utils/formatTimeLeft';

const HandwrittenTable: FC<HandwrittenTableProps> = (props) => {
  const navigate = useNavigate();
  const { role } = useContext(AuthContext);

  const navigateTo = (patientId: string, prescriptionId: string, dispenseId?: string) => {
    if (role === 'pharmacy') {
      navigate(`/patient/${patientId}/prescription/${prescriptionId}/sign-dispense/${dispenseId}`);
    }
    if (role === 'doctor') {
      navigate(`/patient/${patientId}/prescription/${prescriptionId}/sign`);
    }
  };

  const { recipes, hasCheckbox, isChecked, toggleAll, checkboxClick } = props;

  const isCheckbox = (e: MouseEvent<HTMLDivElement>) => {
    const target = e.target as Element;
    return !(target.matches && target.matches('input[type="checkbox"]'));
  };

  return (
    <CorBox className="border border-solid border-border-hover rounded-[18px] overflow-hidden h-fit">
      <header className="grid gap-4 h-fit lg:h-11 lg:max-h-[44px] bg-surface-default grid-cols-9 items-center content-center px-6">
        <div className="col-span-4 flex items-center justify-start">
          {hasCheckbox ? (
            <CorCheckbox size={CheckboxSize.MD} className="pr-3">
              <input type="checkbox" id="pers" name="pers" onChange={toggleAll} />
            </CorCheckbox>
          ) : null}
          <CorText variant={TextVariant.LABEL_2_REGULAR}>
            <span className="text-content-muted">
              <T keyName="handWrittenTable.patientName">Nume Prenume pacient</T>
            </span>
          </CorText>
        </div>
        <div className="col-span-3 flex items-center justify-start">
          <CorText variant={TextVariant.LABEL_2_REGULAR}>
            <span className="text-content-muted">
              <T keyName="handWrittenTable.diagnostic">Diagnosticul</T>
            </span>
          </CorText>
        </div>
        <div className="col-span-2 flex items-center justify-start">
          <CorText variant={TextVariant.LABEL_2_REGULAR}>
            <span className="text-content-muted">
              <T keyName="handWrittenTable.date">Data Introducerii in Sistem</T>
            </span>
          </CorText>
        </div>
        {/*<div className="col-span-2 flex items-center justify-start">*/}
        {/*  <CorText variant={TextVariant.LABEL_2_REGULAR}>*/}
        {/*    <span className="text-content-muted">*/}
        {/*      <T keyName="handWrittenTable.signTime">Timpul ramas de semnare</T>*/}
        {/*    </span>*/}
        {/*  </CorText>*/}
        {/*</div>*/}
      </header>
      <CorBox className="bg-background-primary divide-y divide-x-0 divide-solid divide-border-hover overflow-auto overflow-x-hidden rounded-none flex-1">
        {recipes?.map((receipt: RecepieData, index: number) => {
          const key = `${Math.random()}-${receipt.patientIdentifier}`;
          const prescription = receipt.prescription ? receipt.prescription : receipt;

          // const formattedTime = formatTimeDifference(dayjs(receipt.createdAt).add(5, 'd'));

          return (
            <div
              key={key}
              className="grid grid-cols-9 w-full items-center hover:bg-surface-hover gap-4 cursor-pointer py-3 px-6 z-10"
              onClick={(e) =>
                isCheckbox(e)
                  ? navigateTo(prescription.patientIdentifier, prescription.id, receipt.id)
                  : null
              }
            >
              <section className="flex col-span-4 relative">
                {hasCheckbox ? (
                  <CorCheckbox className="pr-3" size={CheckboxSize.MD}>
                    <input
                      onChange={() => (checkboxClick ? checkboxClick(receipt.id) : null)}
                      type="checkbox"
                      id={index.toString()}
                      name="pers"
                      checked={isChecked?.includes(receipt.id)}
                    />
                  </CorCheckbox>
                ) : null}
                <CorText
                  variant={TextVariant.LABEL_2_REGULAR}
                  className="whitespace-nowrap text-ellipsis overflow-hidden w-full"
                >
                  <span className="text-content-default">
                    {prescription.patient.lastName + ' ' + prescription.patient.firstName}
                  </span>
                </CorText>
              </section>
              <section className="col-span-3">
                <CorText
                  variant={TextVariant.LABEL_2_REGULAR}
                  className="whitespace-nowrap text-ellipsis overflow-hidden"
                >
                  <span className="text-content-default truncate">
                    {prescription.diagnosticCode}
                  </span>
                </CorText>
              </section>
              <section className="col-span-2">
                <CorText
                  variant={TextVariant.LABEL_2_REGULAR}
                  className="whitespace-nowrap text-ellipsis overflow-hidden"
                >
                  <span className="text-content-default">{formatDate(receipt.createdAt)}</span>
                </CorText>
              </section>
              {/*<section className="col-span-2">*/}
              {/*  <CorText*/}
              {/*    variant={TextVariant.LABEL_2_REGULAR}*/}
              {/*    className="whitespace-nowrap text-ellipsis overflow-hidden"*/}
              {/*  >*/}
              {/*    <span className="text-content-default">{formattedTime}</span>*/}
              {/*  </CorText>*/}
              {/*</section>*/}
            </div>
          );
        })}
      </CorBox>
    </CorBox>
  );
};

export default memo(HandwrittenTable);
