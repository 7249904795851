import { SVGProps } from 'react';

const Download = (props: SVGProps<any>) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g opacity="0.3">
        <path d="M13.3327 5.83332C13.3327 6.29356 13.7058 6.66665 14.166 6.66665H14.9993C15.9198 6.66665 16.666 7.41285 16.666 8.33332V15C16.666 15.9205 15.9198 16.6667 14.9993 16.6667H4.99935C4.07887 16.6667 3.33268 15.9205 3.33268 15V8.32754C3.33268 7.40706 4.07887 6.66087 4.99935 6.66087H5.83269C6.29292 6.66087 6.66602 6.28777 6.66602 5.82754C6.66602 5.3673 6.29292 4.9942 5.83269 4.9942H4.99935C3.1584 4.9942 1.66602 6.48659 1.66602 8.32754V15C1.66602 16.8409 3.1584 18.3333 4.99935 18.3333H14.9993C16.8403 18.3333 18.3327 16.8409 18.3327 15V8.33332C18.3327 6.49237 16.8403 4.99999 14.9993 4.99999H14.166C13.7058 4.99999 13.3327 5.37308 13.3327 5.83332Z" />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9101 8.57741C12.2355 8.25197 12.7632 8.25197 13.0886 8.57741C13.414 8.90284 13.414 9.43048 13.0886 9.75592L10.5886 12.2559C10.2632 12.5814 9.73553 12.5814 9.41009 12.2559L6.91009 9.75592C6.58466 9.43048 6.58466 8.90284 6.91009 8.57741C7.23553 8.25197 7.76317 8.25197 8.0886 8.57741L9.99935 10.4882L11.9101 8.57741Z"
      />
      <path d="M9.99935 10.8333C10.4596 10.8333 10.8327 10.4602 10.8327 10V1.66666C10.8327 1.20642 10.4596 0.833328 9.99935 0.833328C9.53911 0.833328 9.16602 1.20642 9.16602 1.66666V10C9.16602 10.4602 9.53911 10.8333 9.99935 10.8333Z" />
    </svg>
  );
};

export default Download;
