import React, { FC } from 'react';
import { useParams } from 'react-router-dom';

import { PatientAssignmentPage } from '../../components';

const HolographicRecipe: FC = () => {
  const { recipeId } = useParams();

  return (
    <div className="flex flex-col sm:px-4 relative overflow-auto h-full">
      <div className="flex flex-col w-full h-full gap-6 rounded-3xl overflow-auto hide-scrollbar">
        <PatientAssignmentPage idPrescription={recipeId} />
      </div>
    </div>
  );
};

export default HolographicRecipe;
