export enum IconName {
  ADDRESS_BOOK = 'address-book',
  ARROW_DOWN = 'arrow-down',
  ARROW_LEFT = 'arrow-left',
  ARROW_RIGHT = 'arrow-right',
  ARROW_UP = 'arrow-up',
  BOOK = 'book',
  BRIEFCASE = 'briefcase',
  BUILDING = 'building',
  BULLET = 'bullet',
  CALENDAR = 'calendar',
  CALL = 'call',
  CARET_DOWN = 'caret-down',
  CARET_LEFT = 'caret-left',
  CARET_RIGHT = 'caret-right',
  CARET_UP = 'caret-up',
  CHAT = 'chat',
  CHECK = 'check',
  CHECK_CIRCLE = 'check-circle',
  CHEVRON_LEFT = 'chevron-left',
  CHEVRON_RIGHT = 'chevron-right',
  CLIP = 'clip',
  CLIPBOARD_LIST = 'clipboard-list',
  CLOCK = 'clock',
  CLOSE = 'close',
  DOC = 'doc',
  DOUBLE_CHECK = 'double-check',
  DOWNLOAD = 'download',
  DOWNLOADED_FILE = 'downloaded-file',
  DOWNLOADS_FOLDER = 'downloads-folder',
  DUPLICATE = 'duplicate',
  EDIT = 'edit',
  EMPTY_FOLDER = 'empty-folder',
  EYE = 'eye',
  FILE = 'file',
  FILTER = 'filter',
  FLAG = 'flag',
  FOLDER = 'folder',
  FOLDER_SOLID = 'folder-solid',
  FORWARD = 'forward',
  GROUP_CHAT = 'group-chat',
  HOME = 'home',
  INCOME = 'income',
  INFO_CIRCLE = 'info-circle',
  JPEG = 'jpeg',
  KEY = 'key',
  LAYOUT_GRID = 'layout-grid',
  LIST = 'list',
  LOAD = 'load',
  LOCK = 'lock',
  MAIL = 'mail',
  MAIL_NOTIFICATION = 'mail-notification',
  MENU = 'menu',
  MINUS_CIRCLE = 'minus-circle',
  MORE_CIRCLE = 'more-circle',
  MORE_HORIZONTAL = 'more-horizontal',
  MORE_VERTICAL = 'more-vertical',
  MUTE = 'mute',
  PAUSE = 'pause',
  PDF = 'pdf',
  PERCENT = 'percent',
  PIN = 'pin',
  PLAY = 'play',
  PLAY_CIRCLE = 'play-circle',
  PLUS = 'plus',
  PLUS_CIRCLE = 'plus-circle',
  PRINTER = 'printer',
  PROGRESS_COMPLETED = 'progress-completed',
  PROGRESS_PROCEEDING = 'progress-proceeding',
  PROGRESS_INCOMPLETE = 'progress-incomplete',
  QUESTION_CIRCLE = 'question-circle',
  QUOTE = 'quote',
  RECEIPT = 'receipt',
  REFRESH = 'refresh',
  SAVE = 'save',
  SEARCH = 'search',
  SELECTED_FILE = 'selected-file',
  SEND = 'send',
  SETTINGS = 'settings',
  SHARE = 'share',
  SIGN_IN = 'sign-in',
  SIGN_Out = 'sign-out',
  SMILE = 'smile',
  SORTER_DOWN = 'sorter-down',
  SORTER_UP = 'sorter-up',
  STAR_FILLED = 'star filled',
  TEAM = 'team',
  TEXT_EDIT = 'text edit',
  TIME_SCHEDULE = 'time-schedule',
  TRASH = 'trash',
  UNDO = 'undo',
  UNLOCK = 'unlock',
  UPLOAD = 'upload',
  UPLOAD_FOLDER = 'upload-folder',
  UPLOADED_FILE = 'uploaded-file',
  USER = 'user',
  VIDEO = 'video',
  VOLUME = 'volume',
  WALLET = 'wallet',
  Warning_1_CIRCLE = 'warning-1-circle',
  ZOOM = 'zoom',
  ZOOM_OUT = 'zoom out',
  MINUS = 'minus',
}

export enum IconColor {
  GRAY = 'gray',
  DARK_VIOLET = 'darkViolet',
  YELLOW = 'yellow',
  RED = 'red',
  LIGHT_BLUE = 'lightBlue',
  WHITE = 'white',
  ORANGE = 'orange',
  GREEN = 'green',
  CONTENT_MUTED = 'content-muted',
  CONTENT_DEFAULT = 'content-default',
  CONTENT_WARNING = 'content-warning',
  CONTENT_SUCCESS = 'content-success',
  CONTENT_CRITICAL = 'content-critical',
}

export enum IconSize {
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
}
