import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { T } from '@tolgee/react';
import { isAxiosError } from 'axios';
import classNames from 'classnames';

import { CorBox, CorNavItem, CorText } from '@e-reteta/react-design-system';

import useWindowWidth from '../../../hooks/use-window-width';
import { AuthContext } from '../../../context/context';
import { PharmacyService } from '../../../services/pharmacy.services';
import { DispensesStatusEnum } from '../../../enums/dispenses-status.enum';
import { handleError } from '../../../utils/handleRequestErrors';
import { SidebarItems } from '../../../enums/sidebar-items.enum';
import eventBus from '../../../events/event-bus';
import { NavItemVariant, TextVariant } from '../../../enums/design-system.enum';
import { LanguagePicker } from '../../index';
import SignOut from '../../../icons/SignOut';
import { MenuItemInterface } from '../../../router/Routes/menuItem.interface';
import { ClinicRolesEnum, PharmacyRolesEnum } from '../../../constants/roles.enum';
import { SideNavProps } from './interface';
import {
  handwrittenLocations,
  searchPatientLocations,
  unsignedDispensesLocations,
  variableLocations,
} from '../../../constants/locations';
import { HolographPrescriptionService } from '../../../services/holograph-prescription.service';

const SideNav: FC<SideNavProps> = ({
  lastName,
  firstName,
  subtitle,
  organization,
  items,
  onMobileClick,
}) => {
  const { isTablet, isSmallTablet } = useWindowWidth();
  const { logout, role, user, hasRole } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();

  const [badge, setBadge] = useState<number>();

  const getDispensesCount = useCallback(async () => {
    if (
      user?.activeCompany?.roles?.includes(PharmacyRolesEnum.PSF_HEAD) ||
      user?.activeCompany?.roles?.includes(PharmacyRolesEnum.PSF_BRANCH)
    ) {
      return;
    }
    try {
      const { data } = await PharmacyService.getMyDispenses({
        statuses: [DispensesStatusEnum.NOT_SIGNED],
        limit: 0,
      });
      setBadge(data.count);
    } catch (error) {
      isAxiosError(error) && handleError(error);
    }
  }, [role]);

  const getHolographPrescriptions = useCallback(async () => {
    if (
      user?.activeCompany?.speciality === ClinicRolesEnum.IMS_HEAD ||
      user?.activeCompany?.speciality === ClinicRolesEnum.IMS_BRANCH
    ) {
      return;
    }
    try {
      const { data } = await HolographPrescriptionService.getMyHolographPrescriptions({
        isHolograph: true,
        isDoctorUnsigned: true,
        createdAtRangeInDays: 5,
        limit: 0,
      });
      setBadge(data.count);
    } catch (error) {
      isAxiosError(error) && handleError(error);
    }
  }, []);

  // get depending on route not role
  useEffect(() => {
    if (!role) {
      return;
    }
    if (
      role === 'pharmacy' &&
      (hasRole(PharmacyRolesEnum.PHARMACIST_SUB) || hasRole(PharmacyRolesEnum.PHARMACIST))
    ) {
      getDispensesCount();
    }
    if (role === 'doctor') {
      getHolographPrescriptions();
    }
  }, [role]);

  const updateCount = (e: { detail: string }) => {
    if (e.detail === SidebarItems.UNSIGNED_DISPENSES) {
      getDispensesCount();
    }
  };

  useEffect(() => {
    eventBus.on('updateCount', updateCount);
    return () => {
      eventBus.remove('updateCount', updateCount);
    };
  }, []);

  const isActive = (route: MenuItemInterface) => {
    const pathsToCheck = [route.path, ...(route.additionalPaths || [])];

    for (const path of pathsToCheck) {
      const currentPath = location.pathname;

      if (currentPath === path) {
        return true;
      }

      const pathSegments = path.split('/');
      const currentPathSegments = currentPath.split('/');

      if (pathSegments.length === currentPathSegments.length) {
        const isMatch = pathSegments.every((segment, index) => {
          return (
            segment === currentPathSegments[index] ||
            (segment.startsWith(':') && currentPathSegments[index] !== '')
          );
        });

        if (isMatch) {
          switch (true) {
            case route.path.includes('search'):
              searchPatientLocations.push(...variableLocations);
              break;
            case route.path.includes('unsigned-dispenses'):
              unsignedDispensesLocations.push(...variableLocations);
              break;
            case route.path.includes('handwrite-prescription'):
              handwrittenLocations.push(...variableLocations);
              break;
          }

          return true;
        }
      }
    }

    return false;
  };

  return (
    <CorBox className="border-none flex flex-col h-full flex-1 sm:px-3 lg:px-4 sm:shadow-2xl sm:rounded-3xl lg:rounded-2xl sm:bg-white">
      <section className="hidden sm:flex flex-col py-4 sm:py-2 lg:py-4">
        <div className="flex flex-row gap-2 truncate" title={firstName + ' ' + lastName}>
          <CorText
            variant={
              !isTablet && !isSmallTablet ? TextVariant.HEADING_2_BOLD : TextVariant.LABEL_1_BOLD
            }
          >
            <span>{firstName}</span>
          </CorText>
          <CorText
            variant={
              !isTablet && !isSmallTablet ? TextVariant.HEADING_2_BOLD : TextVariant.LABEL_1_BOLD
            }
          >
            <span>{isTablet || isSmallTablet ? `${lastName?.slice(0, 1)}.` : lastName}</span>
          </CorText>
        </div>
        <div
          className={classNames({
            'pb-4 border-solid border-b border-0 border-border-default truncate': !organization,
          })}
        >
          <CorText
            variant={
              !isTablet && !isSmallTablet ? TextVariant.LABEL_2_MEDIUM : TextVariant.BODY_1_MEDIUM
            }
          >
            <span
              title={subtitle}
              className={`capitalize ${
                organization ? 'text-content-default' : 'text-content-muted'
              }`}
            >
              {subtitle}
            </span>
          </CorText>
        </div>
        {organization ? (
          <div className="pb-4 border-solid border-b border-0 border-border-default">
            <CorText variant={TextVariant.LABEL_2_REGULAR}>
              <span className="text-content-disabled">{organization}</span>
            </CorText>
          </div>
        ) : null}
      </section>
      <section className="flex flex-1 flex-col gap-2">
        {items.map((route) => {
          return (
            <CorNavItem
              key={route.path}
              variant={isTablet || isSmallTablet ? NavItemVariant.TABLET : NavItemVariant.DESKTOP}
              text={route.name}
              active={isActive(route)}
              iconName={route.iconName}
              onClick={() => {
                navigate(route.path);
                onMobileClick();
              }}
              badgeText={route.hasBadge ? badge?.toString() : undefined}
            />
          );
        })}
      </section>
      <section className="py-0 sm:py-3 lg:py-4">
        <LanguagePicker />
        <button
          onClick={() => logout(true)}
          className="w-full flex flex-row items-center justify-between sm:justify-center lg:justify-between bg-transparent border-solid border-action-neutral-default
            border rounded-[24px] pl-4 pr-3 py-3 sm:p-3 lg:pl-4 lg:pr-3 lg:py-3 cursor-pointer"
        >
          {!isTablet && !isSmallTablet ? (
            <CorText variant={TextVariant.LABEL_2_MEDIUM}>
              <span className="text-action-neutral-default">
                <T keyName="medicInfoCard.signOut">Delogare</T>
              </span>
            </CorText>
          ) : null}
          <SignOut width={16} height={16} />
        </button>
      </section>
    </CorBox>
  );
};

export default SideNav;
