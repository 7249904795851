import React, { FC, useState, useEffect, useRef, useCallback } from 'react';
import { useTolgee } from '@tolgee/react';

import useWindowWidth from '../../hooks/use-window-width';

interface LanguageDropdownProps {
  isOpen: boolean;
  onClose: () => void;
  availableLanguages: string[];
  currentLanguage: string;
  onLanguageChange: (locale: string) => void;
}

const LanguageDropdown: FC<LanguageDropdownProps> = ({
  isOpen,
  onClose,
  availableLanguages,
  currentLanguage,
  onLanguageChange,
}) => {
  const { isMobile } = useWindowWidth();

  const handleSetActiveLocale = (locale: string) => {
    if (locale === currentLanguage) {
      onClose();
      return;
    }

    onLanguageChange(locale);
    onClose();
  };

  const filteredLanguages = availableLanguages.filter((locale) => locale !== currentLanguage);

  return (
    <div
      className={`absolute top-0 left-0 w-full h-full bg-gray-500 bg-opacity-50 -z-[1] ${
        isOpen ? 'flex' : 'hidden'
      }`}
    >
      <div
        className={`absolute -top-12 ${
          isMobile ? 'left-14' : 'left-11'
        } transform -translate-x-1/2 -translate-y-1/2 bg-white p-4 rounded-md shadow-md flex flex-col-reverse`}
      >
        {filteredLanguages.map((locale) => (
          <div
            key={locale}
            onClick={() => handleSetActiveLocale(locale)}
            className="flex items-center gap-2 py-1 pl-1 pr-3 rounded-full hover:cursor-pointer hover:border-border-default border-solid border border-transparent"
          >
            <img src={`/${locale}.svg`} alt={locale.toUpperCase()} className="w-5 h-5" />
            <span>{locale.toUpperCase()}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

const LanguagePicker: FC = () => {
  const tolgee = useTolgee(['pendingLanguage']);
  const size = useWindowWidth();

  const [currentLanguage, setCurrentLanguage] = useState(tolgee.getPendingLanguage());
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isArrowRotated, setIsArrowRotated] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const availableLanguages = ['ro', 'en', 'ru'];

  const handleToggleDropdown = () => {
    setIsDropdownOpen((prevState) => !prevState);
    setIsArrowRotated((prevRotation) => !prevRotation);
  };

  const handleCloseDropdown = () => {
    setIsDropdownOpen(false);
    setIsArrowRotated(false);
  };

  const handleDocumentClick = useCallback((event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      handleCloseDropdown();
    }
  }, []);

  useEffect(() => {
    document.addEventListener('click', handleDocumentClick);

    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [handleDocumentClick]);

  const handleLanguageChange = (locale: string) => {
    tolgee.changeLanguage(locale);
    localStorage.setItem('saved_language', tolgee.getPendingLanguage()!);

    setCurrentLanguage(tolgee.getPendingLanguage());
  };

  useEffect(() => {
    if (localStorage.getItem('saved_language')) {
      const getFromStorage = localStorage.getItem('saved_language') || 'ro';
      tolgee.changeLanguage(getFromStorage);
      setCurrentLanguage(getFromStorage);
    }
  }, [tolgee]);

  const renderPickerMobile = () => {
    return (
      <div
        onClick={handleToggleDropdown}
        className="flex items-center gap-2 w-fit py-1 pl-1 pr-3 rounded-full hover:cursor-pointer mb-4"
      >
        <img
          src={`/${currentLanguage}.svg`}
          alt={currentLanguage?.toUpperCase()}
          className="w-5 h-5"
        />
        <span>{currentLanguage?.toUpperCase()}</span>
        <img
          src="/cheveron.svg"
          alt="arrow"
          className={`w-4 h-4 transition-transform transform ${isArrowRotated ? 'rotate-180' : ''}`}
        />
      </div>
    );
  };

  const renderPickerDesktop = () => {
    return (
      <div className="flex items-center gap-2 w-fit py-1 mb-4">
        {availableLanguages.map((locale) => (
          <div
            key={locale}
            onClick={() => handleLanguageChange(locale)}
            className={`flex items-center gap-2 py-1 pl-1 pr-3 rounded-full hover:cursor-pointer hover:border-border-default border-solid border border-transparent ${
              currentLanguage === locale
                ? 'bg-surface-success-default hover:border-transparent'
                : ''
            }`}
          >
            <img src={`/${locale}.svg`} alt={locale.toUpperCase()} className="w-5 h-5" />
            <span>{locale.toUpperCase()}</span>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div ref={dropdownRef} className="relative opacity-[.99]">
      {size.isMobile || size.isTablet || size.isSmallTablet ? (
        <>{renderPickerMobile()}</>
      ) : (
        <>{renderPickerDesktop()}</>
      )}
      {size.isMobile || size.isTablet || size.isSmallTablet ? (
        <LanguageDropdown
          isOpen={isDropdownOpen}
          onClose={handleCloseDropdown}
          availableLanguages={availableLanguages}
          currentLanguage={currentLanguage || 'ro'}
          onLanguageChange={handleLanguageChange}
        />
      ) : null}
    </div>
  );
};

export default LanguagePicker;
