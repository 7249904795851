import dayjs from 'dayjs';

var customParseFormat = require('dayjs/plugin/customParseFormat');
dayjs.extend(customParseFormat);

export const formatDate = (inputDate: string | Date, currentFormat?: string) => {
  if (currentFormat) {
    return dayjs(inputDate, currentFormat).format('DD.MM.YYYY');
  }

  return dayjs(inputDate).format('DD.MM.YYYY');
};
