import { CorText } from '@e-reteta/react-design-system';
import { useParams } from 'react-router-dom';
import { useFormikContext } from 'formik';
import { isAxiosError } from 'axios';
import { useContext } from 'react';

import { TextVariant } from '../../enums/design-system.enum';
import { Download, File } from '../../icons';
import { ExportMenuProps } from './export-menu.interface';
import { DiagnosticServices } from '../../services/diagnostics.services';
import { DrugService } from '../../services/drug.service';
import { DeviceService } from '../../services/device.service';
import { InstitutionService } from '../../services/institution.service';
import { SpecialityService } from '../../services/speciality.service';
import { PharmaciesServices } from '../../services/pharmacies.services';
import { ReportType } from '../Reports/report-type.enum';
import { ReportService } from '../../services/report.service';
import { DisplayFieldsEnum } from './display-fields.enum';
import { ReportSearchType } from '../Reports/report-search-type.enum';
import { handleError } from '../../utils/handleRequestErrors';
import { toast, ToastContainer } from 'react-toastify';
import { LoaderContext } from '../../context/loader/context';

const ExportMenu = (props: ExportMenuProps) => {
  const { setLoading } = useContext(LoaderContext);
  const { expanded, setExpanded, reportType } = props;
  const { type = 'reports' } = useParams();
  const formik = useFormikContext<any>();

  const download = (response: any, fileType: string) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;

    link.setAttribute('download', `${reportType || type}.${fileType}`);

    document.body.appendChild(link);
    link.click();

    link?.parentNode?.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  const exportFile = async (fileType: string) => {
    setLoading(true);
    try {
      switch (type) {
        case 'diagnostics': {
          const resp = await DiagnosticServices.download(fileType);
          download(resp, fileType);
          break;
        }
        case 'drugs': {
          const resp = await DrugService.download(fileType);
          download(resp, fileType);
          break;
        }
        case 'devices': {
          const resp = await DeviceService.download(fileType);
          download(resp, fileType);
          break;
        }
        case 'clinics': {
          const resp = await InstitutionService.download(fileType);
          download(resp, fileType);
          break;
        }
        case 'specialities': {
          const resp = await SpecialityService.download(fileType);
          download(resp, fileType);
          break;
        }
        case 'pharmacies': {
          const resp = await PharmaciesServices.download(fileType);
          download(resp, fileType);
          break;
        }
        case 'reports': {
          let query: any = {};
          switch (reportType) {
            case ReportType.ANNEX1_FOR_DRUGS: {
              if (formik?.values?.annex1ForDrugsFromDate && formik?.values?.annex1ForDrugsToDate) {
                query = {
                  startDate: formik.values.annex1ForDrugsFromDate,
                  endDate: formik.values.annex1ForDrugsToDate,
                };
              }
              if (formik?.values?.annex1ForDrugsReportType === ReportSearchType.PER_INSTITUTION) {
                query.institutionCode = formik?.values?.annex1ForDrugsInstitutionCode;
              }
              query.groupBy = formik.values.annex1ForDrugsGroupBy;
              const resp = await ReportService.downloadAnnex1ForDrugs('admin', fileType, query);
              download(resp, fileType);
              break;
            }
            case ReportType.ANNEX1_FOR_DEVICES: {
              if (
                formik?.values?.annex1ForDevicesFromDate &&
                formik?.values?.annex1ForDevicesToDate
              ) {
                query = {
                  startDate: formik.values.annex1ForDevicesFromDate,
                  endDate: formik.values.annex1ForDevicesToDate,
                };
              }
              if (formik?.values?.annex1ForDevicesReportType === ReportSearchType.PER_INSTITUTION) {
                query.institutionCode = formik?.values?.annex1ForDevicesInstitutionCode;
              }
              query.groupBy = formik.values.annex1ForDevicesGroupBy;

              const resp = await ReportService.downloadAnnex1ForDevices('admin', fileType, query);
              download(resp, fileType);
              break;
            }
            case ReportType.ANNEX2: {
              if (formik?.values?.annex2FromDate && formik?.values?.annex2ToDate) {
                query = {
                  startDate: formik.values.annex2FromDate,
                  endDate: formik.values.annex2ToDate,
                };
              }
              if (formik?.values?.annex2MedicalProduct === ReportSearchType.DRUGS) {
                query.internationalNames = formik?.values?.drugInternationalNames;
              }
              if (formik?.values?.annex2MedicalProduct === ReportSearchType.DEVICES) {
                query.internationalNames = formik?.values?.deviceInternationalNames;
              }
              if (formik?.values?.annex2MedicalProduct === ReportSearchType.ALL_DRUGS) {
                query.allDrugs = true;
              }
              if (formik?.values?.annex2MedicalProduct === ReportSearchType.ALL_DEVICES) {
                query.allDevices = true;
              }
              const resp = await ReportService.downloadAnnex2(fileType, query);
              download(resp, fileType);
              break;
            }
            case ReportType.ANNEX3_FOR_DRUGS: {
              if (formik?.values?.annex3ForDrugsFromDate && formik?.values?.annex3ForDrugsToDate) {
                query = {
                  startDate: formik.values.annex3ForDrugsFromDate,
                  endDate: formik.values.annex3ForDrugsToDate,
                };
              }
              query.groupBy = formik.values.annex3ForDrugsGroupBy;

              const resp = await ReportService.downloadAnnex3ForDrugs(fileType, query);
              download(resp, fileType);
              break;
            }
            case ReportType.ANNEX3_FOR_DEVICES: {
              if (
                formik?.values?.annex3ForDevicesFromDate &&
                formik?.values?.annex3ForDevicesToDate
              ) {
                query = {
                  startDate: formik.values.annex3ForDevicesFromDate,
                  endDate: formik.values.annex3ForDevicesToDate,
                };
              }
              query.groupBy = formik.values.annex3ForDevicesGroupBy;
              const resp = await ReportService.downloadAnnex3ForDevices(fileType, query);
              download(resp, fileType);
              break;
            }
            case ReportType.ANNEX4: {
              if (formik?.values?.annex4FromDate && formik?.values?.annex4ToDate) {
                query = {
                  startDate: formik.values.annex4FromDate,
                  endDate: formik.values.annex4ToDate,
                };
              }
              if (formik?.values?.annex4ReportType === ReportSearchType.PER_INSTITUTION) {
                query.institutionCode = formik?.values?.annex4InstitutionCode;
              }
              query.groupBy = formik.values.annex4GroupBy;

              const resp = await ReportService.downloadAnnex4('admin', fileType, query);
              download(resp, fileType);
              break;
            }
            case ReportType.ANNEX5: {
              if (formik?.values?.annex5FromDate && formik?.values?.annex5ToDate) {
                query = {
                  startDate: formik.values.annex5FromDate,
                  endDate: formik.values.annex5ToDate,
                };
              }
              const resp = await ReportService.downloadAnnex5(fileType, query);
              download(resp, fileType);
              break;
            }
            case ReportType.ANNEX6: {
              if (formik?.values?.annex6FromDate && formik?.values?.annex6ToDate) {
                query = {
                  startDate: formik.values.annex6FromDate,
                  endDate: formik.values.annex6ToDate,
                };
              }
              if (formik?.values?.annex6MedicalProductType === ReportSearchType.DRUGS) {
                query.display = 'drugs';
              }
              if (formik?.values?.annex6MedicalProductType === ReportSearchType.DEVICES) {
                query.display = 'devices';
              }
              if (formik?.values?.annex6SearchBy) {
                query.pharmacyCode = formik?.values?.annex6Code;
              }
              const resp = await ReportService.downloadAnnex6('admin', fileType, query);
              download(resp, fileType);
              break;
            }
            case ReportType.ANNEX7: {
              if (formik.values.annex7reportType === ReportSearchType.DRUGS_REPORT) {
                query = { display: DisplayFieldsEnum.DISPLAY_ONLY_DRUGS };
              }
              if (formik.values.annex7reportType === ReportSearchType.DEVICES_REPORT) {
                query = { display: DisplayFieldsEnum.DISPLAY_ONLY_DEVICES };
              }
              if (formik.values.annex7FromDate && formik.values.annex7ToDate) {
                query = {
                  ...query,
                  startDate: formik.values.annex7FromDate,
                  endDate: formik.values.annex7ToDate,
                };
              }
              if (formik?.values?.annex7InstitutionCode) {
                query.institutionCode = formik?.values?.annex7InstitutionCode;
              }
              const resp = await ReportService.downloadAnnex7('admin', fileType, query);
              download(resp, fileType);
              break;
            }
            case ReportType.ANNEX8_FOR_CNAM: {
              if (formik.values.annex8ForCnamFromDate && formik.values.annex8ForCnamToDate) {
                query = {
                  ...query,
                  startDate: formik.values.annex8ForCnamFromDate,
                  endDate: formik.values.annex8ForCnamToDate,
                };
              }
              if (formik?.values?.annex8InputValue !== undefined) {
                if (formik?.values?.annex8Institution === ReportSearchType.INSTITUTION) {
                  query.institutionCode = formik?.values?.annex8InputValue;
                }
                if (formik?.values?.annex8Institution === ReportSearchType.PHARMACY) {
                  query.pharmacyIDNO = formik?.values?.annex8InputValue;
                }
                if (formik?.values?.annex8Institution === ReportSearchType.PHARMACY_BRANCH) {
                  query.pharmacyCode = formik?.values?.annex8InputValue;
                }
              }
              if (formik.values.annex8SearchInput !== undefined) {
                if (formik?.values?.annex8ReportType === ReportSearchType.PER_DOCTOR) {
                  query.doctorIDNP = formik?.values?.annex8SearchInput;
                }
                if (formik?.values?.annex8ReportType === ReportSearchType.PER_PHARMACIST) {
                  query.pharmacistIDNP = formik?.values?.annex8SearchInput;
                }
                if (formik?.values?.annex8ReportType === ReportSearchType.PER_PATIENT) {
                  query.patientIdentifier = formik?.values?.annex8SearchInput;
                }
                if (formik?.values?.annex8ReportType === ReportSearchType.PER_PRESCRIPTION) {
                  query.prescriptionId = formik?.values?.annex8SearchInput;
                }
              }
              query.isHolographicPrescription =
                formik.values.annex8PrescriptionType === ReportSearchType.HOLOGRAPH_PRESCRIPTIONS;
              query.groupBy = formik.values.annex8GroupBy;

              const resp = await ReportService.downloadAnnex8ForCNAM(fileType, query);
              download(resp, fileType);
              break;
            }
            case ReportType.ANNEX8_FOR_INSTITUTION: {
              if (
                formik.values.annex8ForInstitutionFromDate &&
                formik.values.annex8ForInstitutionToDate
              ) {
                query = {
                  ...query,
                  startDate: formik.values.annex8ForInstitutionFromDate,
                  endDate: formik.values.annex8ForInstitutionToDate,
                };
              }
              if (formik.values.annex8InstitutionsSearchInput !== undefined) {
                if (formik?.values?.annex8InstitutionReportType === ReportSearchType.PER_DOCTOR) {
                  query.doctorIDNP = formik?.values?.annex8InstitutionsSearchInput;
                }
                if (formik?.values?.annex8InstitutionReportType === ReportSearchType.PER_PATIENT) {
                  query.patientIdentifier = formik?.values?.annex8InstitutionsSearchInput;
                }
                if (
                  formik?.values?.annex8InstitutionReportType === ReportSearchType.PER_PRESCRIPTION
                ) {
                  query.prescriptionId = formik?.values?.annex8InstitutionsSearchInput;
                }
              }
              query.isHolographicPrescription =
                formik.values.annex8InstitutionsPrescriptionType ===
                ReportSearchType.HOLOGRAPH_PRESCRIPTIONS;
              const resp = await ReportService.downloadAnnex8ForInstitution(fileType, query);
              download(resp, fileType);
              break;
            }
            case ReportType.ANNEX8_FOR_PHARMACY: {
              if (
                formik.values.annex8ForPharmacyFromDate &&
                formik.values.annex8ForPharmacyToDate
              ) {
                query = {
                  ...query,
                  startDate: formik.values.annex8ForPharmacyFromDate,
                  endDate: formik.values.annex8ForPharmacyToDate,
                };
              }
              if (formik.values.annex8PharmacySelectInstitution !== undefined) {
                if (formik?.values?.annex8PharmacySelectInstitution === ReportSearchType.PHARMACY) {
                  query.pharmacyIDNO = formik?.values?.annex8PharmacyInputValue;
                }
                if (
                  formik?.values?.annex8PharmacySelectInstitution ===
                  ReportSearchType.PHARMACY_BRANCH
                ) {
                  query.pharmacyCode = formik?.values?.annex8PharmacyCode;
                }
              }
              if (formik.values.annex8PharmacyReportType !== undefined) {
                if (formik?.values?.annex8PharmacyReportType === ReportSearchType.PER_PHARMACIST) {
                  query.pharmacistIDNP = formik?.values?.annex8PharmacySearchInput;
                }
                if (
                  formik?.values?.annex8PharmacyReportType === ReportSearchType.PER_PRESCRIPTION
                ) {
                  query.prescriptionId = formik?.values?.annex8PharmacySearchInput;
                }
              }
              const resp = await ReportService.downloadAnnex8ForPharmacy(fileType, query);
              download(resp, fileType);
              break;
            }
            case ReportType.ANNEX9: {
              if (formik.values.annex9FromDate && formik.values.annex9ToDate) {
                query = {
                  startDate: formik.values.annex9FromDate,
                  endDate: formik.values.annex9ToDate,
                };
              }
              if (formik.values.annex9MedicalProduct === ReportSearchType.DRUGS) {
                query.display = 'drugs';
              }
              if (formik.values.annex9MedicalProduct === ReportSearchType.MEDICAL_DEVICES) {
                query.display = 'devices';
              }
              if (formik.values.annex9reportType === ReportSearchType.PER_INSTITUTION) {
                query.institutionCode = formik?.values?.annex9InstitutionCode;
              }
              const resp = await ReportService.downloadAnnex9(fileType, query);
              download(resp, fileType);
              break;
            }
            case ReportType.ANNEX10_COMMERCIAL_CODE: {
              if (
                formik.values.annex10CommercialCodeFromDate &&
                formik.values.annex10CommercialCodeToDate
              ) {
                query = {
                  startDate: formik.values.annex10CommercialCodeFromDate,
                  endDate: formik.values.annex10CommercialCodeToDate,
                };
              }
              if (formik.values.annex10DCSearchBy === ReportSearchType.COMMERCIAL_COD) {
                query.commercialCode = formik?.values?.annex10DCInputValue;
              }
              if (formik.values.annex10DCSearchBy === ReportSearchType.COMMERCIAL_NAME) {
                query.commercialCode = formik?.values?.annex10DCCommercialCod;
              }
              if (!query.commercialCode) {
                toast.error(
                  'Selectati una din variantele posibile, cod DC sau denumire comerciala',
                  {
                    position: toast.POSITION.TOP_RIGHT,
                  },
                );
                break;
              }
              query.isGeneralized =
                formik.values.annex10DCReportType === ReportSearchType.IS_GENERALIZED;
              const resp = await ReportService.downloadAnnex10DC(fileType, query);
              download(resp, fileType);
              break;
            }
            case ReportType.ANNEX10_INTERNATIONAL_CODE: {
              if (
                formik.values.annex10InternationalCodeFromDate &&
                formik.values.annex10InternationalCodeToDate
              ) {
                query = {
                  startDate: formik.values.annex10InternationalCodeFromDate,
                  endDate: formik.values.annex10InternationalCodeToDate,
                };
              }
              if (formik.values.annex10DCISearchBy === ReportSearchType.DCI) {
                query.drugInternationalName = formik?.values?.annex10DCIInputValue;
              }
              if (formik.values.annex10DCISearchBy === ReportSearchType.DEVICE_NAME) {
                query.deviceInternationalCode = formik?.values?.annex10DCIInternationalCode;
              }
              if (!query.deviceInternationalCode && !query.drugInternationalName) {
                toast.error(
                  'Selectati una din variantele posibile, cod DCI sau denumire dispozitv medical',

                  {
                    position: toast.POSITION.TOP_RIGHT,
                  },
                );
                break;
              }
              query.isGeneralized =
                formik.values.annex10DCIReportType === ReportSearchType.IS_GENERALIZED;
              const resp = await ReportService.downloadAnnex10DCI(fileType, query);
              download(resp, fileType);
              break;
            }
            case ReportType.ANNEX11: {
              if (formik.values.annex11FromDate && formik.values.annex11ToDate) {
                query = {
                  startDate: formik.values.annex11FromDate,
                  endDate: formik.values.annex11ToDate,
                };
              }
              if (formik.values.annex11ProductType !== ReportSearchType.ALL_PRODUCTS) {
                if (formik.values.annex11ReportType === ReportSearchType.PER_COMMERCIAL_COD) {
                  query.commercialCode = formik?.values?.annex11InputValue;
                }
                if (formik.values.annex11ReportType === ReportSearchType.PER_COMMERCIAL_NAME) {
                  query.commercialCode = formik?.values?.annex11CommercialCod;
                }
                if (!query.commercialCode) {
                  toast.error(
                    'Selectati una din variantele posibile, denumirea comerciala sau cod comercial',
                    {
                      position: toast.POSITION.TOP_RIGHT,
                    },
                  );
                  break;
                }
              }
              const resp = await ReportService.downloadAnnex11('admin', fileType, query);
              download(resp, fileType);
              break;
            }
            case ReportType.ANNEX1_FOR_DRUGS_PER_IMS: {
              if (formik.values.annex1ForDrugsFromDate && formik.values.annex1ForDrugsToDate) {
                query = {
                  startDate: formik.values.annex1ForDrugsFromDate,
                  endDate: formik.values.annex1ForDrugsToDate,
                };
              }
              query.groupBy = formik.values.annex1ForDrugsGroupBy;
              const resp = await ReportService.downloadAnnex1ForDrugs('doctor', fileType, query);
              download(resp, fileType);
              break;
            }
            case ReportType.ANNEX1_FOR_DEVICES_PER_IMS: {
              if (formik.values.annex1ForDrugsFromDate && formik.values.annex1ForDrugsToDate) {
                query = {
                  startDate: formik.values.annex1ForDrugsFromDate,
                  endDate: formik.values.annex1ForDrugsToDate,
                };
              }
              if (formik?.values?.annex1ForDrugsInstitution) {
                query = {
                  institutionCode: formik.values.annex1ForDrugsInstitution,
                };
              }
              query.groupBy = formik.values.annex1ForDevicesGroupBy;
              const resp = await ReportService.downloadAnnex1ForDevices('doctor', fileType, query);
              download(resp, fileType);
              break;
            }
            case ReportType.ANNEX4_PER_IMS: {
              if (formik?.values?.annex4FromDate && formik?.values?.annex4ToDate) {
                query = {
                  startDate: formik.values.annex4FromDate,
                  endDate: formik.values.annex4ToDate,
                };
              }
              query.groupBy = formik.values.annex4GroupBy;
              const resp = await ReportService.downloadAnnex4('doctor', fileType, query);
              download(resp, fileType);
              break;
            }
            case ReportType.ANNEX7_PER_IMS: {
              if (formik.values.annex7reportType === ReportSearchType.DRUGS_REPORT) {
                query = { display: DisplayFieldsEnum.DISPLAY_ONLY_DRUGS };
              }
              if (formik.values.annex7reportType === ReportSearchType.DEVICES_REPORT) {
                query = { display: DisplayFieldsEnum.DISPLAY_ONLY_DEVICES };
              }
              if (formik.values.annex7FromDate && formik.values.annex7ToDate) {
                query = {
                  ...query,
                  startDate: formik.values.annex7FromDate,
                  endDate: formik.values.annex7ToDate,
                };
              }
              const resp = await ReportService.downloadAnnex7('doctor', fileType, query);
              download(resp, fileType);
              break;
            }
            case ReportType.ANNEX6_PER_PSF: {
              if (formik?.values?.annex6FromDate && formik?.values?.annex6ToDate) {
                query = {
                  startDate: formik.values.annex6FromDate,
                  endDate: formik.values.annex6ToDate,
                };
              }
              if (formik?.values?.annex6MedicalProductType === ReportSearchType.DRUGS) {
                query.display = 'drugs';
              }
              if (formik?.values?.annex6MedicalProductType === ReportSearchType.DEVICES) {
                query.display = 'devices';
              }
              if (formik?.values?.annex6SearchBy) {
                query.pharmacyCode = formik?.values?.annex6Code;
              }
              const resp = await ReportService.downloadAnnex6('pharmacy', fileType, query);
              download(resp, fileType);
              break;
            }
            case ReportType.ANNEX11_PER_PHARMACY: {
              if (formik.values.annex11FromDate && formik.values.annex11ToDate) {
                query = {
                  startDate: formik.values.annex11FromDate,
                  endDate: formik.values.annex11ToDate,
                };
              }
              if (formik.values.annex11ProductType !== ReportSearchType.ALL_PRODUCTS) {
                if (formik.values.annex11ReportType === ReportSearchType.PER_COMMERCIAL_COD) {
                  query.commercialCode = formik?.values?.annex11InputValue;
                }
                if (formik.values.annex11ReportType === ReportSearchType.PER_COMMERCIAL_NAME) {
                  query.commercialCode = formik?.values?.annex11CommercialCod;
                }
                if (!query.commercialCode) {
                  toast.error(
                    'Selectati una din variantele posibile, denumirea comerciala sau cod comercial',
                    {
                      position: toast.POSITION.TOP_RIGHT,
                    },
                  );
                  break;
                }
              }
              const resp = await ReportService.downloadAnnex11('pharmacy', fileType, query);
              download(resp, fileType);
              break;
            }
            case ReportType.REPORT126: {
              if (formik.values.report126FromDate && formik.values.report126ToDate) {
                query = {
                  startDate: formik.values.report126FromDate,
                  endDate: formik.values.report126ToDate,
                };
              }
              if (formik?.values?.report126Code) {
                query.pharmacyCode = formik?.values?.report126Code;
              }
              const resp = await ReportService.downloadReport126('admin', fileType, query);
              download(resp, fileType);
              break;
            }
            case ReportType.ANNEX13: {
              if (formik.values.annex13FromDate && formik.values.annex13ToDate) {
                query = {
                  startDate: formik.values.annex13FromDate,
                  endDate: formik.values.annex13ToDate,
                };
              }
              if (
                formik.values.annex13StartReferenceDate &&
                formik.values.annex13EndReferenceDate
              ) {
                query.startReferenceDate = formik?.values?.annex13StartReferenceDate;
                query.endReferenceDate = formik?.values?.annex13EndReferenceDate;
              }
              query.groupBy = formik.values.annex13GroupBy;
              const resp = await ReportService.downloadAnnex13(fileType, query);
              download(resp, fileType);
              break;
            }
            case ReportType.ANNEX12: {
              if (formik.values.annex12FromDate && formik.values.annex12ToDate) {
                query = {
                  startDate: formik.values.annex12FromDate,
                  endDate: formik.values.annex12ToDate,
                };
              }
              if (formik.values.includedColumns) {
                query.includedColumns = formik?.values?.includedColumns;
              }
              const resp = await ReportService.downloadAnnex12(fileType, query);
              download(resp, fileType);
              break;
            }
            case ReportType.REPORT126_PER_PSF: {
              if (formik.values.report126PsfHeadFromDate && formik.values.report126PsfHeadToDate) {
                query = {
                  startDate: formik.values.report126PsfHeadFromDate,
                  endDate: formik.values.report126PsfHeadToDate,
                };
              }
              if (formik.values.report126HeadReportType === ReportSearchType.PER_INSTITUTION_NAME) {
                query.pharmacyCode = formik?.values?.report126Code;
              }
              const resp = await ReportService.downloadReport126('pharmacy', fileType, query);
              download(resp, fileType);
              break;
            }
            case ReportType.ANNEX8_FOR_PHARMACY_HEAD: {
              if (
                formik.values.annex8ForPharmacyFromDate &&
                formik.values.annex8ForPharmacyToDate
              ) {
                query = {
                  startDate: formik.values.annex8ForPharmacyFromDate,
                  endDate: formik.values.annex8ForPharmacyToDate,
                };
              }
              if (
                formik.values.annex8PharmacyHeadSelectInstitution ===
                ReportSearchType.PER_INSTITUTION_NAME
              ) {
                query.pharmacyCode = formik?.values?.annex8PharmacyHeadCode;
              }
              if (formik.values.annex8PharmacyHeadReportType === ReportSearchType.PER_PHARMACIST) {
                query.pharmacistIDNP = formik?.values?.annex8PharmacyHeadSearchInput;
              }
              if (
                formik.values.annex8PharmacyHeadReportType === ReportSearchType.PER_PRESCRIPTION
              ) {
                query.prescriptionId = formik?.values?.annex8PharmacyHeadSearchInput;
              }
              const resp = await ReportService.downloadAnnex8ForPharmacy(fileType, query);
              download(resp, fileType);
              break;
            }
            case ReportType.ANNEX8_FOR_PHARMACY_PSF: {
              if (
                formik.values.annex8ForPharmacyPsfFromDate &&
                formik.values.annex8ForPharmacyPsfToDate
              ) {
                query = {
                  startDate: formik.values.annex8ForPharmacyPsfFromDate,
                  endDate: formik.values.annex8ForPharmacyPsfToDate,
                };
              }
              if (
                formik.values.annex8PharmacyPPsfReportType === ReportSearchType.PER_PRESCRIPTION
              ) {
                query.prescriptionId = formik?.values?.annex8PharmacyPsfSearchInput;
              }
              const resp = await ReportService.downloadAnnex8ForPharmacy(fileType, query);
              download(resp, fileType);
              break;
            }
            case ReportType.ANNEX6_PER_PSF_BRANCH: {
              if (
                formik.values.annex6PharmacyBranchFromDate &&
                formik.values.annex6PharmacyBranchToDate
              ) {
                query = {
                  startDate: formik.values.annex6PharmacyBranchFromDate,
                  endDate: formik.values.annex6PharmacyBranchToDate,
                };
              }
              if (formik?.values?.annex6MedicalProductType === ReportSearchType.DRUGS) {
                query.display = 'drugs';
              }
              if (formik?.values?.annex6MedicalProductType === ReportSearchType.DEVICES) {
                query.display = 'devices';
              }
              const resp = await ReportService.downloadAnnex6('pharmacy', fileType, query);
              download(resp, fileType);
              break;
            }
            case ReportType.ANNEX8_PHARMACY_BRANCH: {
              if (
                formik.values.annex8ForPharmacyBranchFromDate &&
                formik.values.annex8ForPharmacyBranchToDate
              ) {
                query = {
                  startDate: formik.values.annex8ForPharmacyBranchFromDate,
                  endDate: formik.values.annex8ForPharmacyBranchToDate,
                };
              }
              if (
                formik.values.annex8PharmacyBranchReportType === ReportSearchType.PER_PHARMACIST
              ) {
                query.pharmacistIDNP = formik?.values?.annex8PharmacyBranchSearchInput;
              }
              if (
                formik.values.annex8PharmacyBranchReportType === ReportSearchType.PER_PRESCRIPTION
              ) {
                query.prescriptionId = formik?.values?.annex8PharmacyBranchSearchInput;
              }
              const resp = await ReportService.downloadAnnex8ForPharmacy(fileType, query);
              download(resp, fileType);
              break;
            }
            case ReportType.REPORT126_PER_PSF_BRANCH: {
              if (
                formik.values.report126PharmacyBranchFromDate &&
                formik.values.report126PharmacyBranchToDate
              ) {
                query = {
                  startDate: formik.values.report126PharmacyBranchFromDate,
                  endDate: formik.values.report126PharmacyBranchToDate,
                };
              }

              const resp = await ReportService.downloadReport126('pharmacy', fileType, query);
              download(resp, fileType);
              break;
            }
          }
        }
      }
    } catch (e) {
      isAxiosError(e) && handleError(e);
    } finally {
      setLoading(false);
      setExpanded(false);
    }
  };

  const exportVariants = [
    {
      text: 'xls',
      onClick: () => exportFile('xlsx'),
    },
    {
      text: 'pdf',
      onClick: () => exportFile('pdf'),
    },
    {
      text: 'csv',
      onClick: () => exportFile('csv'),
    },
  ];
  return (
    <div
      className={`${
        expanded ? 'block' : 'hidden'
      } absolute top-12 py-2 border border-solid border-border-default bg-primary rounded-md w-fit h-fit`}
    >
      {exportVariants.map(({ text, onClick }) => (
        <div
          className="flex gap-2 px-4 py-2 hover:bg-surface-hover active:bg-surface-pressed cursor-pointer"
          key={text}
          onClick={onClick}
        >
          <File className="fill-content-success" />
          <CorText variant={TextVariant.LABEL_2_REGULAR} className="w-10">
            <div className="text-content-default uppercase">{text}</div>
          </CorText>
          <Download className="fill-content-success" />
        </div>
      ))}
      <ToastContainer />
    </div>
  );
};

export default ExportMenu;
