import { SVGProps } from 'react';

const OpenPanel = (props: SVGProps<any>) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect opacity="0.3" x="1.5" y="2.5" width="17" height="15" rx="2.5" stroke="black" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3719 7.73162C13.5427 7.56425 13.5427 7.29289 13.3719 7.12553C13.201 6.95816 12.924 6.95816 12.7531 7.12553L10.1281 9.69695C9.96251 9.8592 9.95672 10.1205 10.115 10.2896L12.5212 12.861C12.6845 13.0355 12.9613 13.0473 13.1394 12.8873C13.3175 12.7274 13.3295 12.4563 13.1663 12.2818L11.0431 10.0129L13.3719 7.73162Z"
        fill="black"
      />
      <path opacity="0.3" d="M6 3V17" stroke="black" />
    </svg>
  );
};

export default OpenPanel;
