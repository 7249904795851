export enum Keys {
  nameSurname = 'Nume Prenume',
  dateOfBirth = 'Data Nasterii',
  medicalUnit = 'Unitatea Medicala',
  branchLocation = 'Locatia Filialei',
  pharmacistIdno = 'IDNO Agentului Economic',
  receiptId = 'ID Reteta',
  patientNameSurname = 'Nume Prenume pacient',
  doctorName = 'Nume Doctor',
  introductionDate = 'Data Introducerii in Sistem',
  timeForSigning = 'Timpul ramas de semnare',
  phone = 'Numar de contact',
}
