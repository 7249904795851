import React, { FC, useContext } from 'react';
import { Route, Routes } from 'react-router-dom';
import { T } from '@tolgee/react';
import { CorBox, CorText } from '@e-reteta/react-design-system';
import { SpinnerCircularFixed } from 'spinners-react';
import classNames from 'classnames';

import { Login, NotFound } from '../../pages';
import SelectInstitution from '../../pages/SelectInstitution';
import { AuthContext } from '../../context/context';
import { AdminRoutes } from './admin.routes';
import { DoctorRoutes } from './doctor.routes';
import { PharmacyRoutes } from './pharmacy.routes';
import { TextVariant } from '../../enums/design-system.enum';
import { LoaderContext } from '../../context/loader/context';

const AppRouter: FC = () => {
  const { user } = useContext(AuthContext);
  const { loading } = useContext(LoaderContext);

  const renderLoader = () => {
    if (!loading) {
      return;
    }
    return (
      <div
        className="bg-white absolute h-screen w-screen flex z-[51] opacity-80 pointer-events-none"
        onPointerUp={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <CorBox className="flex flex-col gap-2 justify-center items-center w-full h-[calc(100vh-24px)] m-4">
          <div>
            <SpinnerCircularFixed
              size={56}
              thickness={100}
              speed={100}
              color="#80C080"
              secondaryColor="#E6E6EA"
            />
          </div>
          <CorText variant={TextVariant.HEADING_4_MEDIUM}>
            <h5 className="text-center">
              <T keyName={'routes.loadingText'}>Vă rugăm să așteptați, datele se încarcă...</T>
            </h5>
          </CorText>
        </CorBox>
      </div>
    );
  };

  return (
    <>
      {renderLoader()}
      <div
        className={classNames({
          'pointer-events-none': loading,
        })}
      >
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login-success" element={<SelectInstitution />} />
          {user?.activeCompany?.type === 'clinic' && DoctorRoutes}
          {user?.activeCompany?.type === 'pharmacy' && PharmacyRoutes}
          {user?.activeCompany?.type === 'admin' && AdminRoutes}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </>
  );
};

export default AppRouter;
