import { FC, SVGProps } from 'react';

const Edit: FC<SVGProps<SVGSVGElement>> = (props) => {
  const { color = 'white' } = props;

  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.3"
        d="M4 11.5C4 11.2239 4.22386 11 4.5 11C4.77614 11 5 11.2239 5 11.5C5 11.7761 4.77614 12 4.5 12C4.22386 12 4 11.7761 4 11.5Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.93 4.85835C13.5158 5.44414 13.5158 6.39389 12.93 6.97968L7.79971 12.11C7.51479 12.3949 7.12722 12.5532 6.72429 12.5492L5.06821 12.5329C4.2602 12.525 3.6037 11.8784 3.58344 11.0706L3.54363 9.48296C3.53333 9.07222 3.69197 8.67523 3.9825 8.3847L9.15876 3.20844C9.74454 2.62265 10.6943 2.62265 11.2801 3.20844L12.93 4.85835Z"
        fill={color}
      />
    </svg>
  );
};

export default Edit;
