import Api from '../axios/api';
import type { Disease } from '../types/Disease';
import { PaginationQuery } from '../types/PaginationQuery';

export interface QueryParams extends PaginationQuery {
  code?: string;
  name?: string;
  search?: string;
  specialityName?: string;
}

export interface GetDiseaseResponse {
  data: {
    items: Disease[];
    count: number;
    search?: string;
  };
}

export interface MessageResponse {
  message: string;
}

export interface CreateDiagnosticResponse {
  data: Disease;
}

export interface UpdateDiagnosticResponse {
  data: Disease;
}

export interface GetQueryParams {
  code?: string;
  internationalNames?: string[];
  routesOfAdministration?: string[];
  doses?: string[];
  limit?: number;
  search?: string;
  dose?: string;
  routeOfAdministration?: string;
  internationalName?: string;
  isAllowedUninsured?: boolean;
  isAllowedForAdult?: boolean;
  isAllowedForChildren?: boolean;
}

interface GetDevicesParams {
  code?: string;
  ids?: string[];
  names?: string[];
  limit?: number;
  isAllowedUninsured?: boolean;
}

export class DiagnosticServices {
  static getDiagnostics(
    target: string = 'doctor',
    query?: QueryParams,
  ): Promise<GetDiseaseResponse> {
    return Api.get(`/v1/${target}/diagnostics`, {
      params: query,
    });
  }

  static getDiagnosticPartialData(
    target: string,
    query?: QueryParams,
  ): Promise<GetDiseaseResponse> {
    return Api.get(`/v1/${target}/diagnostics/partial-data`, {
      params: query,
    });
  }

  static getDrugsByCode(target: string, query?: GetQueryParams): Promise<any> {
    return Api.get(`/v1/${target}/diagnostics/${query?.code}/drugs`, {
      params: query,
    });
  }

  static getDevicesByCode(target: string, params: GetDevicesParams): Promise<any> {
    return Api.get(`/v1/${target}/diagnostics/${params?.code}/devices`, {
      params: {
        commercialCodes: params.ids,
        limit: params.limit,
        names: params.names,
        isAllowedUninsured: params?.isAllowedUninsured,
      },
    });
  }

  static delete(code: string): Promise<MessageResponse> {
    return Api.delete(`/v1/admin/diagnostics/${code}`);
  }

  static bulkUpload(formData: FormData): Promise<MessageResponse> {
    return Api.post(`/v1/admin/diagnostics/upload`, formData);
  }

  static create(body: Partial<Disease>): Promise<CreateDiagnosticResponse> {
    return Api.post(`/v1/admin/diagnostics`, body);
  }

  static update(code: string, body: Partial<Disease>): Promise<UpdateDiagnosticResponse> {
    return Api.patch(`/v1/admin/diagnostics/${code}`, body);
  }

  static download(type: string) {
    return Api.get(`/v1/admin/diagnostics/download.${type}`, {
      responseType: 'blob',
    });
  }
}
