import { TableHeaderProps, WidthType } from './TableHeader/interface';
import { ModalWindowOpen } from './TableRow/enums';
import type { Drug } from '../../types/Drug';
import { DeviceDto } from '../../services/device.service';
import { PharmacyDto } from '../../services/pharmacies.services';
import { InstitutionDto } from '../../services/institution.service';
import { SpecialityDto } from '../../services/speciality.service';
import { Disease } from '../../types/Disease';
import { PharmacySettingsDto } from '../../services/pharmacy-settings.services';

export const deviceHeaders: TableHeaderProps<DeviceDto> = {
  fullWidth: true,
  widthType: WidthType.PIXEL,
  tableWidth: '3195px',
  columns: {
    cimCodes: {
      value: 'Cod Maladie (CIM 10)',
      width: 168,
      type: 'chip',
      modalType: ModalWindowOpen.CIM_CODES,
      translationKey: 'tableHeader.deviceHeadersCimCodes',
    },
    name: {
      value: 'Denumirea dispozitivului medical',
      width: 188,
      type: 'text',
      translationKey: 'tableHeader.deviceHeadersName',
    },
    isAllowedUninsured: {
      value: 'Statut prescriere pentru neasigurați',
      width: 106,
      type: 'toggle',
      translationKey: 'tableHeader.deviceHeadersIsAllowedUninsured',
    },
    internationalCod: {
      value: 'Cod DCI',
      width: 56,
      type: 'text',
      translationKey: 'tableHeader.deviceHeadersInternationalCod',
    },
    treatmentDuration: {
      value: 'Durata tratamentului',
      width: 80,
      type: 'text',
      translationKey: 'tableHeader.deviceHeadersTreatmentDuration',
    },
    expiration: {
      value: 'Valabilitatea rețetei',
      width: 75,
      type: 'text',
      translationKey: 'tableHeader.deviceHeadersExpiration',
    },
    threeMonthPrescriptionNorm: {
      value: 'Norma de prescriere pentru 3 luni',
      width: 120,
      type: 'text',
      translationKey: 'tableHeader.deviceHeadersThreeMonthPrescriptionNorm',
    },
    commercialCod: {
      value: 'Cod DC',
      width: 72,
      type: 'text',
      translationKey: 'tableHeader.deviceHeadersCommercialCod',
    },
    commercialName: {
      value: 'Denumire comercială/Model',
      width: 186,
      type: 'text',
      translationKey: 'tableHeader.deviceHeadersCommercialName',
    },
    measurementUnit: {
      value: 'Unități de măsură',
      width: 65,
      type: 'text',
      translationKey: 'tableHeader.deviceHeadersMeasurementUnit',
    },
    dividing: {
      value: 'Divizarea',
      width: 58,
      type: 'text',
      translationKey: 'tableHeader.deviceHeadersDividing',
    },
    producer: {
      value: 'Producător',
      width: 186,
      type: 'text',
      translationKey: 'tableHeader.deviceHeadersProducer',
    },
    registryName: {
      value: 'Nr. de înregistrare în Registrul de stat al dispozitivelor medicale',
      width: 186,
      type: 'text',
      translationKey: 'tableHeader.deviceHeadersRegistryName',
    },
    compensatedStatus: {
      value: 'Statut de compensare',
      width: 130,
      type: 'text',
      translationKey: 'tableHeader.deviceHeadersCompensatedStatus',
    },
    compensatedSumWithVAT: {
      value: 'Suma fixă compensată per unitate de măsură inclusiv TVA',
      width: 173,
      type: 'number',
      translationKey: 'tableHeader.deviceHeadersCompensatedSumWithVAT',
    },
    compensatedSumWithoutVAT: {
      value: 'Suma fixă compensată per unitate de măsură fără TVA',
      width: 154,
      type: 'number',
      translationKey: 'tableHeader.deviceHeadersCompensatedSumWithoutVAT',
    },
    vat: {
      value: 'TVA %',
      width: 75,
      type: 'text',
      translationKey: 'tableHeader.deviceHeadersVatAmount',
    },
    maximRetailPriceWithVAT: {
      value: 'Preț maxim cu amănuntul, MDL',
      width: 98,
      type: 'number',
      translationKey: 'tableHeader.deviceHeadersMaximRetailPriceWithVAT',
    },
    coPayment: {
      value: 'Valoarea maximă a co-plății pacientului (inclusiv TVA), MDL',
      width: 174,
      type: 'number',
      translationKey: 'tableHeader.deviceHeadersCoPayment',
    },
    warningMessage: {
      value: 'Mesaj de avertizare (la prescriere si eliberare)',
      width: 366,
      type: 'text',
      translationKey: 'tableHeader.deviceHeadersWarningMessage',
    },
    active: {
      value: 'Active',
      width: 80,
      type: 'toggle',
      translationKey: 'tableHeader.deviceHeadersActive',
    },
  },
};

export const drugHeader: TableHeaderProps<Drug> = {
  fullWidth: true,
  widthType: WidthType.PIXEL,
  tableWidth: '4329px',
  columns: {
    internationalName: {
      type: 'text',
      value: 'Denumirea Comuna Internationala (DCI)',
      width: 164,
      translationKey: 'tableHeader.drugHeaderInternationalName',
    },
    dose: {
      type: 'text',
      value: 'Doza',
      width: 72,
      translationKey: 'tableHeader.drugHeaderDose',
    },
    form: {
      type: 'text',
      value: 'Forma farmaceutică',
      width: 144,
      translationKey: 'tableHeader.drugHeaderForm',
    },
    cimCodes: {
      type: 'chip',
      value: 'Cod CIM de diagnostic',
      width: 160,
      modalType: ModalWindowOpen.CIM_CODES,
      translationKey: 'tableHeader.drugHeaderCimCodes',
    },
    groups: {
      type: 'text',
      value: 'Grupa farmacoterapeutică',
      width: 228,
      translationKey: 'tableHeader.drugHeaderGroups',
    },
    atcCod: {
      value: 'Cod ATC',
      type: 'text',
      width: 80,
      translationKey: 'tableHeader.drugHeaderAtcCod',
    },
    treatmentDuration: {
      type: 'text',
      value: 'Durata tratamentului',
      width: 80,
      translationKey: 'tableHeader.drugHeaderTreatmentDuration',
    },
    isAllowedForAdult: {
      type: 'toggle',
      value: 'Prescrierea pentru adulți',
      width: 80,
      translationKey: 'tableHeader.drugHeaderIsAllowedForAdult',
    },
    isAllowedForChildren: {
      type: 'toggle',
      value: 'Prescrierea pentru copii 0-18 ani',
      width: 111,
      translationKey: 'tableHeader.drugHeaderIsAllowedForChildren',
    },
    isAllowedUninsured: {
      type: 'toggle',
      value: 'Statut prescriere pentru adulti neasigurați',
      width: 97,
      translationKey: 'tableHeader.drugHeaderIsAllowedUninsured',
    },
    intersections: {
      type: 'chip',
      value: 'Interacțiuni inadmisibile cu alte coduri ATC (mesaj de alertă)',
      width: 260,
      modalType: ModalWindowOpen.FORBIDDEN_INTERSECTIONS,
      translationKey: 'tableHeader.drugHeaderIntersections',
    },
    expiration: {
      type: 'text',
      value: 'Valabilitatea rețetei',
      width: 73,
      translationKey: 'tableHeader.drugHeaderExpiration',
    },
    commercialCod: {
      value: 'Cod DC = Cod medicamentului conform Nomenclatorului de stat',
      type: 'text',
      width: 186,
      translationKey: 'tableHeader.drugHeaderCommercialCod',
    },
    commercialName: {
      value: 'Denumirea comercială (DC)',
      type: 'text',
      width: 158,
      translationKey: 'tableHeader.drugHeaderCommercialName',
    },
    division: {
      type: 'text',
      value: 'Divizarea',
      width: 72,
      translationKey: 'tableHeader.drugHeaderDivision',
    },
    volume: {
      type: 'text',
      value: 'Volum',
      width: 80,
      translationKey: 'tableHeader.drugHeaderVolume',
    },
    compensatedStatusAdult: {
      type: 'text',
      value: 'Statut de compensare pentru adulti',
      width: 130,
      translationKey: 'tableHeader.drugHeaderCompensatedStatusAdult',
    },
    compensatedStatusChildren: {
      type: 'text',
      value: 'Statut de compensare pentru copii',
      width: 130,
      translationKey: 'tableHeader.drugHeaderCompensatedStatusChildren',
    },
    compensatedSumWithVATPerUnit: {
      type: 'number',
      value: 'Suma fixă compensată per unitate de măsură inclusiv TVA',
      width: 173,
      translationKey: 'tableHeader.drugHeaderCompensatedSumWithVATPerUnit',
    },
    compensatedSumWithoutVATPerUnit: {
      type: 'number',
      value: 'Suma fixă compensată per unitate de măsură fără TVA',
      width: 154,
      translationKey: 'tableHeader.drugHeaderCompensatedSumWithoutVATPerUnit',
    },
    vat: {
      value: 'TVA %',
      width: 75,
      type: 'text',
      translationKey: 'tableHeader.drugHeaderVatAmount',
    },
    coPayment: {
      type: 'number',
      value: 'Preț maxim cu amănuntul în farmacii per unitate, MDL',
      width: 159,
      translationKey: 'tableHeader.drugHeaderCoPayment',
    },
    country: {
      type: 'text',
      value: 'Ţara',
      width: 144,
      translationKey: 'tableHeader.drugHeaderCountry',
    },
    producer: {
      type: 'text',
      value: 'Firma producătoare',
      width: 144,
      translationKey: 'tableHeader.drugHeaderProducer',
    },
    routeOfAdministration: {
      type: 'text',
      value: 'Modul de administrare',
      width: 218,
      translationKey: 'tableHeader.drugHeaderAdministrationMethod',
    },

    warningsDCI: {
      type: 'text',
      value: 'Avertizari/Comentarii DCI',
      width: 218,
      translationKey: 'tableHeader.drugHeaderWarningsDCI',
    },
    warningsDC: {
      type: 'text',
      value: 'Avertizari/Comentarii DC',
      width: 200,
      translationKey: 'tableHeader.drugHeaderWarningsDC',
    },
    active: {
      type: 'toggle',
      value: 'Active',
      width: 80,
      translationKey: 'tableHeader.drugHeaderActive',
    },
  },
};

export const diagnosticHeaders: TableHeaderProps<Disease> = {
  fullWidth: true,
  widthType: WidthType.PERCENTAGE,
  columns: {
    code: {
      type: 'text',
      value: 'Cod',
      width: 8,
      translationKey: 'tableHeader.diagnosticHeadersCimCodes',
    },
    name: {
      type: 'text',
      value: 'Denumire',
      width: 42,
      translationKey: 'tableHeader.diagnosticHeadersName',
    },
    group: {
      type: 'text',
      value: 'Subclasa',
      width: 21,
      translationKey: 'tableHeader.diagnosticHeadersGroup',
    },
    subGroup: {
      type: 'text',
      value: 'Clasa',
      width: 21,
      translationKey: 'tableHeader.diagnosticHeadersSubGroup',
    },
  },
} as TableHeaderProps<Disease>;

export const specialityHeaders: TableHeaderProps<SpecialityDto> = {
  fullWidth: false,
  widthType: WidthType.PERCENTAGE,
  columns: {
    name: {
      type: 'text',
      value: 'Specialitatea medicului',
      width: 20,
      translationKey: 'tableHeader.specialityHeadersName',
    },
    prescribedDisease: {
      type: 'chip',
      value: 'Diagnostice pentru prescriere',
      width: 37,
      modalType: ModalWindowOpen.DIAGNOSTICS_FOR_PRESCRIPTION,
      translationKey: 'tableHeader.specialityHeadersPrescribedDisease',
    },
    rePrescribedDisease: {
      type: 'chip',
      value: 'Diagnostice pentru re-prescriere',
      width: 37,
      modalType: ModalWindowOpen.DIAGNOSTICS_FOR_REPRESCRIPTION,
      translationKey: 'tableHeader.specialityHeadersRePrescribedDisease',
    },
  },
};

export const institutionsHeaders: TableHeaderProps<InstitutionDto> = {
  fullWidth: false,
  widthType: WidthType.PERCENTAGE,
  columns: {
    codIms: {
      type: 'text',
      value: 'Cod Ims',
      width: 8,
      translationKey: 'tableHeader.institutionsHeadersCodIms',
    },
    idno: {
      type: 'text',
      value: 'IDNO',
      width: 13,
      translationKey: 'tableHeader.institutionsHeadersIdno',
    },
    imsp: {
      type: 'text',
      value: 'IMSP',
      width: 52,
      translationKey: 'tableHeader.institutionsHeadersImsp',
    },
    codAt: {
      type: 'text',
      value: 'Cod AT',
      width: 6,
      translationKey: 'tableHeader.institutionsHeadersCodAt',
    },
    district: {
      type: 'text',
      value: 'Raion',
      width: 12,
      translationKey: 'tableHeader.institutionsHeadersDistrict',
    },
  },
};

export const pharmaciesHeaders: TableHeaderProps<PharmacyDto> = {
  widthType: WidthType.PIXEL,
  fullWidth: false,
  tableWidth: '2700px',
  columns: {
    atAscribed: {
      type: 'text',
      value: 'AT arondată',
      width: 80,
      translationKey: 'tableHeader.pharmaciesHeadersAtAscribed',
    },
    name: {
      type: 'text',
      value: 'Denumirea PSF',
      width: 200,
      translationKey: 'tableHeader.pharmaciesHeadersName',
    },
    district: {
      type: 'text',
      value: 'Raion',
      width: 100,
      translationKey: 'tableHeader.pharmaciesHeadersDistrict',
    },
    address: {
      type: 'text',
      value: 'Adresa amplasării',
      width: 250,
      translationKey: 'tableHeader.pharmaciesHeadersAddress',
    },
    idno: {
      type: 'text',
      value: 'IDNO',
      width: 100,
      translationKey: 'tableHeader.pharmaciesHeadersIdno',
    },
    code: {
      type: 'text',
      value: 'Codul Filealei',
      width: 120,
      translationKey: 'tableHeader.pharmaciesHeadersCode',
    },
    series: {
      type: 'text',
      value: 'Seria, Nr. licenței',
      width: 120,
      translationKey: 'tableHeader.pharmaciesHeadersSeries',
    },
    licenseValidity: {
      type: 'date',
      value: 'Valabilitatea (zi/luna/anul)',
      width: 80,
      translationKey: 'tableHeader.pharmaciesHeadersNoLicense',
    },
    type: {
      type: 'text',
      value: 'Tipul farmaciei',
      width: 80,
      translationKey: 'tableHeader.pharmaciesHeadersType',
    },
    accreditationCertificate: {
      type: 'text',
      value: 'Certificat de acreditare',
      width: 80,
      translationKey: 'tableHeader.pharmaciesHeadersAccreditationCertificate',
    },
    validUntilTheDate: {
      type: 'date',
      value: 'Valabil pînă la',
      width: 200,
      translationKey: 'tableHeader.pharmaciesHeadersLicenseValidity',
    },
    branchCategory: {
      type: 'text',
      value: 'Categoria filialei',
      width: 120,
      translationKey: 'tableHeader.pharmaciesHeadersBranchCategory',
    },
    internalCodeOfTheBranch: {
      type: 'text',
      value: 'Codul intern al filialei',
      width: 120,
      translationKey: 'tableHeader.pharmaciesHeadersInternalCodeOfTheBranch',
    },
    branchManager: {
      type: 'text',
      value: 'Farmacist diriginte/Șef filială',
      width: 120,
      translationKey: 'tableHeader.pharmaciesHeadersBranchManager',
    },
    workingHours: {
      type: 'text',
      value: 'Orar de lucru',
      width: 120,
      translationKey: 'tableHeader.pharmaciesHeadersWorkingHours',
    },
    phone: {
      type: 'text',
      value: 'Telefon',
      width: 80,
      translationKey: 'tableHeader.pharmaciesHeadersPhone',
    },
    email: {
      type: 'text',
      value: 'E-mail',
      width: 80,
      translationKey: 'tableHeader.pharmaciesHeadersEmail',
    },
    contractNr: {
      type: 'text',
      value: 'Contractul nr.',
      width: 200,
      translationKey: 'tableHeader.pharmaciesHeadersContractNr',
    },
    vatPayer: {
      type: 'text',
      value: 'Platitor TVA',
      width: 150,
      translationKey: 'tableHeader.pharmaciesHeadersVatPayer',
    },
  },
};

export const integrationsHeaders: TableHeaderProps<PharmacySettingsDto> = {
  fullWidth: true,
  widthType: WidthType.PERCENTAGE,
  columns: {
    idno: {
      type: 'text',
      value: 'IDNO',
      width: 13,
      translationKey: 'tableHeader.integrationsHeadersIdno',
    },
    isActive: {
      type: 'boolean',
      value: 'Statutul Integrării',
      compareValues: ['Conectat', 'Dezactivat'],
      width: 13,
      translationKey: 'tableHeader.integrationsHeadersIsActive',
    },
    pullProductsURL: {
      type: 'text',
      value: 'Link extragere medicamente/dispozitive',
      width: 30,
      translationKey: 'tableHeader.integrationsHeadersPullProductsURL',
    },
    pushDispensesURL: {
      type: 'text',
      value: 'Link transmitere eliberări',
      width: 22,
      translationKey: 'tableHeader.integrationsHeadersPushDispensesURL',
    },
    integrationStatus: {
      type: 'text',
      value: 'Statutul Conexiunii',
      width: 13,
      translationKey: 'tableHeader.integrationsHeadersIntegrationStatus',
    },
  },
};
