import React, { useContext, useState } from 'react';
import { CorText } from '@e-reteta/react-design-system';
import { useNavigate } from 'react-router-dom';
import { isAxiosError } from 'axios';
import { T } from '@tolgee/react';

import SearchInput from '../../components/SearchInput';
import { TextVariant } from '../../enums/design-system.enum';
import { PatientServices } from '../../services/patient.services';
import useWindowWidth from '../../hooks/use-window-width';
import { QueryParams } from '../../services/doctorsPrescription.services';
import EinsteinDrawer from '../../components/EinsteinDrawer';
import { AuthContext } from '../../context/context';
import { handleError } from '../../utils/handleRequestErrors';
import { renderResponsiveClasses } from '../../utils/render-responsive-classes.utils';
import { LoaderContext } from '../../context/loader/context';

const DoctorSearch = () => {
  const navigate = useNavigate();
  const { isTablet, isMobile } = useWindowWidth();
  const [notFound, setNotFound] = useState(false);
  const { role } = useContext(AuthContext);
  const { setLoading } = useContext(LoaderContext);

  const searchPatient = async (value: string) => {
    try {
      if (role) {
        const query: QueryParams = {
          patientId: value,
        };
        setLoading(true);
        let patient = await PatientServices.getPatient(role, query);
        setLoading(false);
        if (!patient) {
          setNotFound(true);
          return;
        }
        navigate(`/patient/${patient.data.id}`);
      }
    } catch (error) {
      setLoading(false);
      isAxiosError(error) && handleError(error);
      setNotFound(true);
    }
  };

  return (
    <div className="flex flex-col overflow-auto p-0 sm:pl-6 sm:p-4 lg:p-0 lg:pl-6 overflow-y-auto h-full">
      <div>
        <SearchInput
          translationKey1="doctorSearch.identifierPatientsOrTemporaryDoc"
          placeholder1={'IDNP sau Nr. actului provizoriu'}
          onSubmit={(value) => searchPatient(value)}
          withButton
        />
      </div>
      {!notFound ? (
        <div
          className={`flex w-full h-full justify-center items-center p-4 rounded-2xl mt-6 ${
            !notFound ? 'block' : 'hidden'
          }`}
        >
          <div className="flex flex-col gap-8 w-[301px] sm:w-[401px] items-center text-center">
            <CorText
              variant={renderResponsiveClasses(
                isMobile,
                isTablet,
                TextVariant.HEADING_4_BOLD,
                TextVariant.HEADING_3_BOLD,
                TextVariant.HEADING_2_BOLD,
              )}
            >
              <span className="text-content-default text-center">
                <T keyName="doctorSearch.searchContent">
                  Incepeti cautarea unui pacient dupa IDNP sau Nr. actului provizoriu
                </T>
              </span>
            </CorText>
            <img className="w-[200px] sm:w-[400px]" src="/search.png" alt="search" />
          </div>
        </div>
      ) : null}
      {notFound ? (
        <div className="flex-1 flex items-center justify-center overflow-auto gap-4 p-4 my-6 flex-col">
          <div className="w-[350px] text-center">
            <CorText
              variant={renderResponsiveClasses(
                isMobile,
                isTablet,
                TextVariant.HEADING_4_BOLD,
                TextVariant.HEADING_3_BOLD,
                TextVariant.HEADING_2_BOLD,
              )}
            >
              <span className="text-content-default">
                <T keyName="doctorSearch.notFoundPacient">
                  Nu a fost gasit nici un pacient in baza informatiei introduse
                </T>
              </span>
            </CorText>
          </div>

          <img src="/not-found.png" alt="not found loop" className="w-[200px] sm:w-[400px]" />
        </div>
      ) : null}
      <EinsteinDrawer toBottom={true} />
    </div>
  );
};

export default DoctorSearch;
