import { CorButton, CorIcon, CorText } from '@e-reteta/react-design-system';
import { FC } from 'react';
import { T } from '@tolgee/react';

import Modal from '../Modal';
import { TextVariant } from '../../enums/design-system.enum';
import { IconColor, IconSize } from '../../enums/design-system-icons.enum';
import { ConfirmModalProps } from './interface';

const ConfirmModal: FC<ConfirmModalProps> = ({
  title,
  description,
  onClose,
  onAccept,
  acceptButton,
  closeButton,
}) => {
  return (
    <Modal>
      <div className="absolute inset-0 w-screen h-screen max-w-screen max-h-screen flex items-center justify-center z-50 bg-modal-background">
        <div className="h-auto bg-background-primary p-4 rounded-lg w-4/5 max-w-md">
          <div className="flex flex-row w-full">
            <div>
              <CorIcon
                name="warning-1-circle"
                size={IconSize.LG}
                color={IconColor.CONTENT_WARNING}
                className="pr-4"
              />
            </div>
            <div className="flex flex-col justify-end ml-4 w-full">
              <CorText variant={TextVariant.HEADING_4_BOLD}>
                <span>{title}</span>
              </CorText>
              <CorText variant={TextVariant.LABEL_1_REGULAR} className="mt-1">
                <span>{description}</span>
              </CorText>
              <div className="flex flex-row justify-end mt-4">
                <CorButton onClick={onClose}>
                  <button>{closeButton || <T keyName="confirmModal.cancel">Anuleaza</T>}</button>
                </CorButton>
                <CorButton onClick={onAccept} className="ml-2">
                  <button>{acceptButton || <T keyName="confirmModal.sign">Semneaza</T>}</button>
                </CorButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmModal;
