import { calculateDaysUntilDate } from './calculateDaysUntilDate.util';
import { User } from '../services/auth.services';

export const getActiveCompanyUtil = ({ companies, customAttributes }: User) => {
  const activeCompany = companies?.find((company) => company.isActive);
  if (activeCompany?.type === 'pharmacy') {
    const licenceExpires = customAttributes?.find((attr) => attr.key === activeCompany?.code);
    activeCompany.licenceExpiresInDays = calculateDaysUntilDate(licenceExpires?.value);
  }

  return activeCompany;
};
