import { CorDatepicker, CorInput, CorText } from '@e-reteta/react-design-system';
import React, { useCallback } from 'react';
import { useFormikContext } from 'formik';
import { T, useTranslate } from '@tolgee/react';
import dayjs from 'dayjs';

import { Annex13FormikProps } from './annex13.interface';
import { TextVariant } from '../../../enums/design-system.enum';
import GroupByContainer from '../GroupBy';

const Annex13 = () => {
  const formik = useFormikContext<Annex13FormikProps>();
  const { t } = useTranslate();

  const callback = useCallback(
    (event: any, field: string) => {
      event.preventDefault();
      formik.setFieldValue(field, dayjs(event.detail).toISOString());
    },
    [formik],
  );
  const handleGroupByInstitution = (fields: string[]) => {
    const groupBy: Record<string, '0' | '1'> = {};
    groupByFields.forEach((field) => {
      groupBy[field] = fields.indexOf(field) === -1 ? '0' : '1';
    });
    formik.setFieldValue(`annex13GroupBy`, groupBy);
  };

  let groupByFields = [
    'diagnosticGroup',
    'diagnosticSubGroup',
    'internationalName',
    'institutionDistrict',
    'institutionName',
    'pharmacyName',
    'pharmacyAddress',
  ];
  return (
    <>
      <CorText variant={TextVariant.LABEL_1_MEDIUM} className="xs:px-4 mb-4">
        <div className="text-content-default">
          <T keyName={'annex12.referenceDate'}>Perioada de referinta</T>
        </div>
      </CorText>
      <div className="flex gap-4 items-center xs:px-4 pb-6">
        <CorInput>
          <CorDatepicker
            id="annex13StartReferenceDate"
            value={formik?.values?.annex13StartReferenceDate}
            label="Data de referinta"
            name="annex13StartReferenceDate"
            placeholder={t('acordion.selectDatePlaceholder', 'Select date')}
            onCallback={(e: any) => callback(e, 'annex13StartReferenceDate')}
            positionTop={true}
          />
        </CorInput>
        <CorText>
          <div className="text-sm leading-4 text-content-muted">-</div>
        </CorText>
        <CorInput>
          <CorDatepicker
            id="annex13EndReferenceDate"
            value={formik?.values?.annex13EndReferenceDate}
            label="Data de referinta"
            name="annex13EndReferenceDate"
            placeholder={t('acordion.selectDatePlaceholder', 'Select date')}
            onCallback={(e: any) => callback(e, 'annex13EndReferenceDate')}
            positionTop={true}
          />
        </CorInput>
      </div>
      <div className="w-full border-0 border-t border-solid border-border-disabled mx-4 my-6" />

      <GroupByContainer
        name={t('annex13.groupBy', 'Grupeaza dupa')}
        fields={groupByFields.map((field) => {
          return {
            label: t(`annex13.${field}`, field),
            value: field,
          };
        })}
        preSelected={groupByFields}
        onChange={handleGroupByInstitution}
      />
      <div className="w-full border-0 border-t border-solid border-border-disabled mx-4 my-6" />
    </>
  );
};

export default Annex13;
