import { CorInput, CorRadioButton, CorText } from '@e-reteta/react-design-system';
import React, { useEffect, useRef, useState } from 'react';
import { useFormikContext } from 'formik';
import { T, useTranslate } from '@tolgee/react';
import { isAxiosError } from 'axios';
import hash from 'object-hash';

import { InputIconDisplay, TextVariant } from '../../../enums/design-system.enum';
import { Annex9FormikProps } from './annex9.interface';
import { IconName } from '../../../enums/design-system-icons.enum';
import { InstitutionDto, InstitutionService } from '../../../services/institution.service';
import Check from '../../../icons/Check';
import { handleError } from '../../../utils/handleRequestErrors';
import { ReportSearchType } from '../report-search-type.enum';

const Annex9 = () => {
  const formik = useFormikContext<Annex9FormikProps>();
  const ref = useRef<HTMLDivElement>(null);
  const { t } = useTranslate();

  const [institutions, setInstitutions] = useState<InstitutionDto[]>([]);
  const [isVisible, setVisible] = useState<boolean>(false);

  const handleClickOutside = (event: any) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const getInstitutions = async () => {
    const inst = await InstitutionService.getAll('admin', {
      codIms: formik?.values?.annex9Institution,
    });
    setInstitutions(inst?.data?.items || []);
  };

  useEffect(() => {
    if (!institutions?.length && formik?.values?.annex9Institution !== '') {
      getInstitutions();
    }
    const delayInputTimeoutId = setTimeout(async () => {
      try {
        await getInstitutions();
      } catch (error) {
        isAxiosError(error) && handleError(error);
      }
    }, 500);
    return () => clearTimeout(delayInputTimeoutId);
  }, [formik?.values?.annex9Institution]);

  const handleSearchInstitution = (code: string) => {
    formik.setFieldValue('annex9Institution', code);
    formik.setFieldValue('annex9InstitutionCode', undefined);
  };

  const handleSelectInstitution = (institution: InstitutionDto) => {
    formik.setFieldValue('annex9Institution', `${institution.codIms} - ${institution.imsp}`);
    formik.setFieldValue('annex9InstitutionCode', institution.codIms);
  };

  const renderInstitutionsDropdown = () => {
    if (
      formik?.values?.annex9reportType === undefined ||
      formik?.values?.annex9reportType === ReportSearchType.TOTALIZER_REPORT
    ) {
      return null;
    }
    return (
      <div className="px-4 mt-4">
        <div className="relative md:w-1/2" ref={ref}>
          <CorInput iconName={IconName.SEARCH} iconDisplay={InputIconDisplay.LEFT}>
            <input
              autoComplete="off"
              type="text"
              name="annex9Institution"
              placeholder={t('annex9.institutionCodePlaceholder', 'Introduceti cod institutie')}
              value={formik?.values?.annex9Institution}
              onClick={() => setVisible(true)}
              onChange={(event: any) => {
                handleSearchInstitution(event?.target?.value);
              }}
            />
          </CorInput>
          {isVisible && institutions && institutions?.length ? (
            <div className="absolute w-full max-h-[296px] overflow-y-scroll z-50 mt-1 py-2 rounded-[8px] border border-solid border-border-default bg-decorative-neutral-1">
              {institutions.map((item: InstitutionDto) => {
                return (
                  <div
                    className="relative flex gap-2 justify-between w-full px-4 py-2 cursor-pointer group hover:bg-surface-hover"
                    key={hash(item)}
                    onClick={() => {
                      handleSelectInstitution(item);
                      setVisible(false);
                    }}
                  >
                    <CorText variant={TextVariant.LABEL_2_REGULAR}>
                      <p className="text-content-muted group-hover:text-content-default">
                        {item.codIms} - {item.imsp}
                      </p>
                    </CorText>
                    {formik?.values?.annex9InstitutionCode === item.codIms ? <Check /> : null}
                  </div>
                );
              })}
            </div>
          ) : null}
        </div>
      </div>
    );
  };

  const renderReportType = () => {
    if (formik?.values?.annex9MedicalProduct === undefined) {
      return null;
    }
    return (
      <>
        <CorText variant={TextVariant.LABEL_1_MEDIUM} className="px-4">
          <div className="text-content-default">
            <T keyName={'annex.reportType'}>Tipul raportului</T>
          </div>
        </CorText>
        <div className="flex flex-col px-4 pt-4">
          <div className="flex gap-8">
            <CorRadioButton
              isChecked={ReportSearchType.TOTALIZER_REPORT === formik?.values?.annex9reportType}
              disabled={false}
            >
              <label className="whitespace-nowrap">
                <T keyName={'annex.totalizerReport'}>{ReportSearchType.TOTALIZER_REPORT}</T>
              </label>
              <input
                type="radio"
                name="annex9reportType"
                value={ReportSearchType.TOTALIZER_REPORT}
                checked={ReportSearchType.TOTALIZER_REPORT === formik?.values?.annex9reportType}
                onChange={formik.handleChange}
                className="bg-background-secondary"
              />
            </CorRadioButton>
            <CorRadioButton
              isChecked={ReportSearchType.PER_INSTITUTION === formik?.values?.annex9reportType}
              disabled={false}
            >
              <label className="whitespace-nowrap">
                <T keyName={'annex.perInstitutionReport'}>{ReportSearchType.PER_INSTITUTION}</T>
              </label>
              <input
                type="radio"
                name="annex9reportType"
                value={ReportSearchType.PER_INSTITUTION}
                checked={ReportSearchType.PER_INSTITUTION === formik?.values?.annex9reportType}
                onChange={formik.handleChange}
                className="bg-background-secondary"
              />
            </CorRadioButton>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="flex flex-col">
      <CorText variant={TextVariant.LABEL_1_MEDIUM} className="px-4">
        <div className="text-content-default">
          <T keyName={'anex2.medicalProduct'}>Produsul Medical</T>
        </div>
      </CorText>
      <div className="flex flex-col px-4 pt-4 mb-4">
        <div className="flex gap-8 flex-wrap">
          <CorRadioButton
            isChecked={ReportSearchType.ALL === formik?.values?.annex9MedicalProduct}
            disabled={false}
          >
            <label className="whitespace-nowrap">
              <T keyName={'annex9.all'}>{ReportSearchType.ALL}</T>
            </label>
            <input
              type="radio"
              name="annex9MedicalProduct"
              value={ReportSearchType.ALL}
              checked={ReportSearchType.ALL === formik?.values?.annex9MedicalProduct}
              onChange={formik.handleChange}
              className="bg-background-secondary"
            />
          </CorRadioButton>
          <CorRadioButton
            isChecked={ReportSearchType.DRUGS === formik?.values?.annex9MedicalProduct}
            disabled={false}
          >
            <label className="whitespace-nowrap">
              <T keyName={'annex9.allDrugs'}>{ReportSearchType.DRUGS}</T>
            </label>
            <input
              type="radio"
              name="annex9MedicalProduct"
              value={ReportSearchType.DRUGS}
              checked={ReportSearchType.DRUGS === formik?.values?.annex9MedicalProduct}
              onChange={formik.handleChange}
              className="bg-background-secondary"
            />
          </CorRadioButton>
          <CorRadioButton
            isChecked={ReportSearchType.MEDICAL_DEVICES === formik?.values?.annex9MedicalProduct}
            disabled={false}
          >
            <label className="whitespace-nowrap">
              <T keyName={'annex9.allDevices'}>{ReportSearchType.MEDICAL_DEVICES}</T>
            </label>
            <input
              type="radio"
              name="annex9MedicalProduct"
              value={ReportSearchType.MEDICAL_DEVICES}
              checked={ReportSearchType.MEDICAL_DEVICES === formik?.values?.annex9MedicalProduct}
              onChange={formik.handleChange}
              className="bg-background-secondary"
            />
          </CorRadioButton>
        </div>
      </div>
      {renderReportType()}
      {renderInstitutionsDropdown()}
      <div className="w-full border-0 border-t border-solid border-border-disabled mx-4 my-6" />
    </div>
  );
};

export default Annex9;
