import { CorBadge, CorButton, CorIcon, CorText } from '@e-reteta/react-design-system';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { T, useTranslate } from '@tolgee/react';

import { IconName, IconSize } from '../../enums/design-system-icons.enum';
import { BadgeSize, ButtonVariant, TextVariant } from '../../enums/design-system.enum';
import { Alert, ContainerViewPrescription, ModalWindow, PatientMedicCard } from '../../components';
import { AlertStatus } from '../../components/Alert/enum';
import { CreateDPrescriptions, PrescriptionServices } from '../../services/prescription.services';
import { DiagnosticServices } from '../../services/diagnostics.services';
import { Duplicate, Edit } from '../../icons';
import { getPrescriptionStatusColor } from '../../lib/getPrescriptionStatusColor';
import { ReceiptStatus } from '../../enums/ReceiptStatus';
import {
  getPrescriptionStatusTranslation,
  getPrescriptionStatusTranslationKey,
} from '../../lib/getPrescriptionStatusTranslation';
import useWindowWidth from '../../hooks/use-window-width';
import { SignServices } from '../../services/doctorsSign.services';
import ConfirmModal from '../../components/ConfirmModal';
import useQuery from '../../hooks/use-query';
import PrescriptionPrint from '../../components/Prints/PrescriptionPrint';
import { PharmacyService, PrescriptionRelease } from '../../services/pharmacy.services';
import { Doctor } from '../../types/Doctor';
import { AuthContext } from '../../context/context';
import { AxiosError } from 'axios';
import { renderResponsiveClasses } from '../../utils/render-responsive-classes.utils';
import { Disease } from '../../types/Disease';
import { Prescription } from '../../types/Prescription';
import { Dispense } from '../../types/CommonTypes';
import { PatientData } from '../../components/PatientMedicCard/interface';
import { LoaderContext } from '../../context/loader/context';
import { toast } from 'react-toastify';
import { SpecialityService } from '../../services/speciality.service';

const ViewPrescription = () => {
  const { idnp, prescriptionId } = useParams();
  let query = useQuery();
  const navigate = useNavigate();
  const location = useLocation();
  const { isTablet, isMobile } = useWindowWidth();
  const { user, role } = useContext(AuthContext);
  const { setLoading } = useContext(LoaderContext);

  const [isSigned, setIsSigned] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [drugs, setDrugs] = useState<CreateDPrescriptions[]>();
  const [devices, setDevices] = useState<CreateDPrescriptions[]>();
  const [disease, setDisease] = useState<Disease>();
  const [prescription, setPrescription] = useState<Prescription>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [confirmSign, setConfirmSign] = useState<boolean>(false);
  const [errorMessage, setError] = useState<string>();
  const [releases, setReleases] = useState<PrescriptionRelease[]>([]);
  const [dispenses, setDispenses] = useState<Dispense[]>([]);
  const [rePrescribedCodes, setRePrescribedCodes] = useState<string[]>([]);
  const [secondVisit, setSecondVisit] = useState<boolean>(false);
  const [lastVisit, setLastVisit] = useState<boolean>(false);
  const [loadingQueue, setLoadingQueue] = useState<boolean[]>([]);

  const { t } = useTranslate();

  useEffect(() => {
    if (loadingQueue?.length) {
      setLoading(true);
      return;
    }
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingQueue.length]);

  const deletePrescription = async () => {
    if (!prescriptionId) {
      return;
    }
    try {
      loadingQueue.push(true);
      setLoadingQueue(loadingQueue.slice());
      await PrescriptionServices.deletePrescription(prescriptionId);
    } finally {
      loadingQueue.pop();
      setLoadingQueue(loadingQueue.slice());
    }
  };

  const onSubmit = () => {
    navigate(`/patient/${idnp}/payment/${prescriptionId}`);
  };

  const onGoBack = () => {
    if (location?.pathname?.includes('prescription') && location?.pathname?.includes('sign')) {
      if (role === 'doctor') {
        navigate(`/doctor/handwrite-prescription`);
      }
      if (role === 'pharmacy') {
        navigate(`/unsigned-dispenses`);
      }
    } else if (location?.pathname?.includes('dispenses')) {
      localStorage.setItem('prescriptionReleases', JSON.stringify([]));
      navigate(`/patient/${idnp}/prescription/${prescriptionId}`);
    } else {
      navigate(`/patient/${idnp}`);
    }
  };

  const onCancel = () => {
    setShowModal(true);
  };

  const onDecline = () => {
    setShowModal(false);
  };

  const onAccept = async () => {
    loadingQueue.push(true);
    setLoadingQueue(loadingQueue);
    setShowModal(false);
    try {
      await deletePrescription();
      loadingQueue.pop();
      setLoadingQueue(loadingQueue);

      navigate(`/patient/${idnp}`);
    } catch (e: any) {
      loadingQueue.pop();
      setLoadingQueue(loadingQueue);
      toast.error(
        t('drugFields.error', 'Eroare la verificarea disponibilitatii', {
          error: e?.response?.data?.message || e?.toString(),
        }),
      );
    }
  };

  const onClone = () => {
    navigate(`/patient/${idnp}/prescription/${prescriptionId}/clone`);
  };
  const onEditBtn = () => {
    navigate(`/patient/${idnp}/prescription/${prescriptionId}/edit`);
  };

  const getDevices = useCallback(
    async (code: string, dPrescriptions: CreateDPrescriptions[]) => {
      if (role) {
        const fullDevices = dPrescriptions
          ?.filter((dPrescription: CreateDPrescriptions) => dPrescription.deviceName)
          .map((dPrescription: CreateDPrescriptions) => dPrescription);

        setDevices(fullDevices);
      }
    },
    [role],
  );

  const getDrugs = useCallback(
    async (dPrescriptions: CreateDPrescriptions[]) => {
      if (role) {
        const fullDrugs = dPrescriptions
          ?.filter(
            (dp: CreateDPrescriptions) =>
              dp.drugInternationalName && dp.drugRouteOfAdministration && dp.drugDose,
          )
          .map((dp: CreateDPrescriptions) => dp);
        setDrugs(fullDrugs);
      }
    },
    [role],
  );

  const getDisease = useCallback(
    async (code: string) => {
      if (role) {
        const disease = await DiagnosticServices.getDiagnostics(role, {
          code,
        });
        setDisease(disease?.data?.items?.at(0)!);
      }
    },
    [role],
  );

  const getPrescription = useCallback(async () => {
    if (!prescriptionId || !role) {
      return;
    }
    try {
      loadingQueue.push(true);
      setLoadingQueue(loadingQueue.slice());
      let query = {};
      if (role === 'pharmacy') {
        query = {
          statuses: [
            ReceiptStatus.SIGNED,
            ReceiptStatus.RELEASED,
            ReceiptStatus.PARTIAL_RELEASED,
            ReceiptStatus.SIGNED_BY_PHARMACIST,
          ],
        };
      }
      const { data } = await PrescriptionServices.getPrescriptionById(prescriptionId, role, query);

      setPrescription(data);
      await Promise.all([
        getRePrescribedDiseases(),
        getDisease(data.diagnosticCode),
        getDevices(data.diagnosticCode, data.dPrescriptions!),
        getDrugs(data.dPrescriptions!),
        getDispenses(),
      ]);
    } finally {
      loadingQueue.pop();
      setLoadingQueue(loadingQueue.slice());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prescriptionId, role, getDevices, getDrugs, getDisease]);

  const getDispenses = useCallback(async () => {
    if (!prescriptionId || !role) {
      return;
    }
    const { data } = await PharmacyService.getDispensesByPrescriptionId(role, prescriptionId);
    let prescriptionReleases: PrescriptionRelease[] = [];
    data?.items?.forEach((d: { prescriptionReleases: PrescriptionRelease[] }) => {
      d.prescriptionReleases?.forEach((pr: PrescriptionRelease) => prescriptionReleases.push(pr));
    });
    if (data?.items?.length) {
      setDispenses(data?.items);
    }
    if (data?.items?.length === 1) {
      setSecondVisit(true);
    }
    if (data?.items?.length === 2) {
      setLastVisit(true);
    }
    if (prescriptionReleases.length) {
      setReleases(prescriptionReleases);
    }
  }, [prescriptionId, role]);

  useEffect(() => {
    if (!role) {
      return;
    }
    getPrescription();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role, prescriptionId]);

  const checkSignPrescription = () => {
    setConfirmSign(true);
  };

  const closeCheckSign = () => {
    setError(undefined);
    setConfirmSign(false);
  };

  const signPrescription = async () => {
    try {
      loadingQueue.push(true);
      setLoadingQueue(loadingQueue.slice());
      if (
        location.pathname.includes('prescription') &&
        location.pathname.includes('sign-dispense')
      ) {
        const dispensesToSign = dispenses
          .filter((dispense) => dispense.addedBy === user?.idnp && dispense.status === 'not_signed')
          .map((dispense) => dispense.id);
        const { data } = await PharmacyService.signDispenses({ items: dispensesToSign });
        if (data?.redirectUrl) {
          window.location.assign(data?.redirectUrl);
        }
      } else {
        if (prescriptionId) {
          const { data } = await SignServices.sign(
            { prescriptionId: prescriptionId },
            role ? role : '',
          );
          if (data?.redirectUrl) {
            window.location.assign(data?.redirectUrl);
          }
        }
      }
    } catch (error) {
      if (error instanceof Error || error instanceof AxiosError) {
        if ((error as AxiosError).response?.status === 409) {
          setError(
            t(
              'viewPrescription.signConflict',
              'Unul sau mai multe pozitii s-au prescris in alta reteta',
            ),
          );
        } else {
          setError(error && error?.message);
        }
      }
      setConfirmSign(false);
    } finally {
      loadingQueue.pop();
      setLoadingQueue(loadingQueue.slice());
    }
  };

  useEffect(() => {
    if (query && query.get('sign-status') === 'Success') {
      setIsSigned(true);
    }
    if (query && query.get('sign-status') === 'Error') {
      setIsError(true);
    }
  }, [query]);

  const checkDisabled = useCallback(
    (dispenses?: PrescriptionRelease[]) => {
      let totalQuantity: number = 0;
      let dispensedQuantity: number = 0;
      prescription?.dPrescriptions?.forEach((dp: CreateDPrescriptions) => {
        totalQuantity += Number(dp.quantity);
      });
      if (dispenses?.length) {
        dispenses.forEach((dispense: PrescriptionRelease) => {
          dispensedQuantity += Number(dispense.quantity);
        });
      }
      if (releases?.length) {
        releases.forEach((release: PrescriptionRelease) => {
          dispensedQuantity += Number(release.quantity);
        });
      }

      return totalQuantity === dispensedQuantity;
    },
    [releases, prescription],
  );

  const getRePrescribedDiseases = async () => {
    const selectedCompany = user?.companies?.find((company) => company.isActive === true);
    if (selectedCompany?.speciality) {
      const pr = await SpecialityService.getByName(selectedCompany?.speciality);
      const rePrescribedDiseases: string[] =
        pr?.data?.rePrescribedDisease?.map((item: { code: string }) => item.code) || [];
      const prescribedDisease: string[] =
        pr?.data?.prescribedDisease?.map((item: { code: string }) => item.code) || [];
      setRePrescribedCodes([...rePrescribedDiseases, ...prescribedDisease]);
    }
  };

  const ifNoReleasesAndNoDoctorSign = !prescription?.isSignedByDoctor && !releases.length;
  const ifDoctorSignAndStatusSigned =
    prescription?.isSignedByDoctor && prescription?.status === ReceiptStatus.SIGNED;

  const showCancelButton =
    role === 'doctor' &&
    user?.idnp === prescription?.doctorIDNP &&
    user?.activeCompany?.speciality === prescription?.doctor?.speciality &&
    user?.activeCompany?.code === prescription?.doctor?.medicalUnit &&
    (ifNoReleasesAndNoDoctorSign || ifDoctorSignAndStatusSigned);

  const displaySignButton = () => {
    const dispensesToSign = dispenses.filter(
      (dispense) => dispense.addedBy === user?.idnp && dispense.status === 'not_signed',
    );
    const displayPharmacist =
      role === 'pharmacy' && dispensesToSign.length && location.pathname.includes('sign-dispense');
    const displayDoctor =
      role === 'doctor' &&
      prescription?.doctorIDNP === user?.idnp &&
      user?.activeCompany?.speciality === prescription?.doctor?.speciality &&
      user?.activeCompany?.code === prescription?.doctor.medicalUnit &&
      !prescription?.isSignedByDoctor;

    return displayPharmacist || displayDoctor ? (
      <CorButton variant={ButtonVariant.PRIMARY}>
        <button onClick={checkSignPrescription}>
          <T keyName={'viewPrescription.signButton'}>Semneaza</T>
        </button>
      </CorButton>
    ) : null;
  };

  return (
    <div className="h-full sm:pl-6 sm:p-4 lg:pl-6 lg:p-0">
      <div className="flex flex-row justify-between items-center mb-4">
        <div className="flex flex-row items-center gap-2">
          <CorIcon
            size={IconSize.MD}
            name={IconName.ARROW_LEFT}
            className="cursor-pointer"
            onClick={onGoBack}
          />
          <div className="flex flex-col sm:flex-row sm:items-center justify-start gap-x-2">
            {location?.pathname?.includes('dispenses') ? (
              <CorText
                variant={renderResponsiveClasses(
                  isMobile,
                  isTablet,
                  TextVariant.LABEL_1_REGULAR,
                  TextVariant.HEADING_3_BOLD,
                  TextVariant.HEADING_2_BOLD,
                )}
              >
                <h2>
                  <T keyName={'viewPrescription.releasePrescription'}>Eliberare reteta</T>
                </h2>
              </CorText>
            ) : (
              <>
                <CorText
                  variant={renderResponsiveClasses(
                    isMobile,
                    isTablet,
                    TextVariant.LABEL_1_REGULAR,
                    TextVariant.HEADING_3_BOLD,
                    TextVariant.HEADING_2_BOLD,
                  )}
                >
                  <h2>
                    <T keyName="viewPrescription.id">ID reteta</T>:
                  </h2>
                </CorText>
                <CorText
                  variant={renderResponsiveClasses(
                    isMobile,
                    isTablet,
                    TextVariant.LABEL_1_BOLD,
                    TextVariant.HEADING_3_BOLD,
                    TextVariant.HEADING_2_BOLD,
                  )}
                >
                  <h2>{prescription?.hPrescriptionId || prescription?.id}</h2>
                </CorText>
              </>
            )}
          </div>
          <CorBadge
            size={BadgeSize.MD}
            color={getPrescriptionStatusColor(prescription?.status as ReceiptStatus)}
          >
            <span className="capitalize">
              <T keyName={getPrescriptionStatusTranslationKey(prescription?.status!)}>
                {getPrescriptionStatusTranslation(prescription?.status as ReceiptStatus)}
              </T>
            </span>
          </CorBadge>
        </div>
        <>{displaySignButton()}</>
      </div>
      {secondVisit && location.pathname.includes('dispenses') ? (
        <Alert
          text={t(
            'viewPrescription.secondVisitWarningText',
            'Va aflati la penultima vizita la farmacie pentru eliberarea retetei dumneavoastra.',
          )}
          className="mb-4"
          status={AlertStatus.WARNING}
        />
      ) : null}
      {lastVisit && location.pathname.includes('sign-dispense') ? (
        <Alert
          text={t(
            'viewPrescription.lastVisitWarningText',
            'Va aflati la ultima vizita la farmacie pentru eliberarea retetei dumneavoastra.',
          )}
          className="mb-4"
          status={AlertStatus.WARNING}
        />
      ) : null}
      {isSigned ? (
        <Alert
          label={t('viewPrescription.signSuccess', 'Semnarea a fost efectuata cu succes')}
          className="h-fit w-full mb-4"
          status={AlertStatus.SUCCESS}
        />
      ) : null}
      {isError ? (
        <Alert
          label={t('viewPrescription.signFailed', 'Semnarea a esuat')}
          className="h-fit w-full mb-4"
          status={AlertStatus.DANGER}
        />
      ) : null}
      {errorMessage?.length && (
        <Alert text={errorMessage} className="h-fit w-full mb-4" status={AlertStatus.DANGER} />
      )}
      <PatientMedicCard
        patient={prescription?.patient! as unknown as PatientData}
        patientIdentifier={idnp}
        user={user}
        doctor={prescription?.doctor as Doctor}
        status="prescribed"
        role={role}
      />
      {location?.pathname?.includes('dispenses') ? (
        <CorText
          className="mb-3 mt-6"
          variant={isMobile ? TextVariant.HEADING_4_BOLD : TextVariant.HEADING_3_BOLD}
        >
          <span>
            <T keyName={'viewPrescription.releasedDrugText'}>Medicamente</T>/
            <T keyName={'viewPrescription.releasedDeviceText'}>Dispozitive</T>
          </span>
        </CorText>
      ) : null}
      <div>
        <ContainerViewPrescription
          drugs={drugs}
          devices={devices}
          thirdPersonIDNP={prescription?.thirdPerson?.idnp}
          thirdPersonName={`${prescription?.thirdPerson?.lastName} ${prescription?.thirdPerson?.firstName}`}
          code={prescription?.diagnosticCode}
          name={disease?.name}
          role={role}
          dispenses={dispenses}
          prescribedAt={prescription?.prescribedAt as unknown as Date}
          setIsDisabled={setDisabled}
        />
      </div>
      {showModal ? (
        <ModalWindow>
          <div className="h-fit w-[520px] p-4">
            <Alert
              label={t('viewPrescription.confirmModalLabel', 'Atentie')}
              text={t(
                'viewPrescription.confirmModalText',
                'Esti sigur ca doresti sa anulezi reteta?',
              )}
              firstButton={t('viewPrescription.noButtonText', 'Nu')}
              secondButton={t('viewPrescription.yesButtonText', 'Da')}
              status={AlertStatus.WARNING}
              withButton
              firstButtonClick={onDecline}
              secondButtonClick={onAccept}
            />
          </div>
        </ModalWindow>
      ) : null}
      <div
        className={`flex flex-row gap-4 mt-4 sm:pb-4 lg:pb-0 ${
          showCancelButton ? 'justify-between' : 'justify-end'
        }`}
      >
        <div className="flex gap-2 sm:gap-4 w-full sm:w-fit">
          {showCancelButton ? (
            <CorButton variant={ButtonVariant.SECONDARY} onClick={onCancel}>
              <button>
                <T keyName={'viewPrescription.cancelButtonText'}>Anuleaza </T>
                <CorIcon color="currentColor" name={IconName.CLOSE} />
              </button>
            </CorButton>
          ) : null}
        </div>

        <div className="flex gap-2 sm:gap-4 w-full sm:w-fit">
          {role === 'doctor' && rePrescribedCodes.includes(prescription?.diagnosticCode!) ? (
            <CorButton variant={ButtonVariant.SECONDARY} onClick={onClone}>
              <button className="gap-1.5 pr-3" type="button">
                <T keyName={'viewPrescription.cloneButtonText'}>Cloneaza </T>
                <Duplicate width={16} height={16} />
              </button>
            </CorButton>
          ) : null}
          {role === 'doctor' &&
            user?.idnp === prescription?.doctorIDNP &&
            user?.activeCompany?.speciality === prescription?.doctor?.speciality &&
            user?.activeCompany?.code === prescription?.doctor?.medicalUnit &&
            (![ReceiptStatus.RELEASED, ReceiptStatus.PARTIAL_RELEASED].includes(
              prescription?.status!,
            ) ? (
              <CorButton variant={ButtonVariant.SECONDARY} onClick={onEditBtn}>
                <button className="gap-1.5 pr-3" type="button">
                  <T keyName={'viewPrescription.modifyButtonText'}>Modifica </T>
                  <Edit width={16} height={16} color={'#008000'} className="fill-[#008000]" />
                </button>
              </CorButton>
            ) : null)}
          {!(
            !prescription?.isSignedByDoctor && prescription?.status === ReceiptStatus.PRESCRIBED
          ) ? (
            <PrescriptionPrint prescription={prescription!} buttonForPrint={'view'} />
          ) : null}

          {role === 'pharmacy' && location?.pathname?.includes('dispenses') ? (
            <CorButton className="flex-1 sm:flex-auto">
              <button disabled={disabled} className="w-full" onClick={onSubmit}>
                <T keyName={'viewPrescription.releaseButtonText'}>Elibereaza </T>
                <Edit width={16} height={16} className="fill-[#FFFFFF]" />
              </button>
            </CorButton>
          ) : null}
          {role === 'pharmacy' &&
          location?.pathname?.includes('prescription') &&
          !location?.pathname?.includes('sign') ? (
            <CorButton className="flex-1 sm:flex-auto">
              <button
                type="button"
                disabled={checkDisabled()}
                className="w-full"
                onClick={() => navigate(`/patient/${idnp}/dispenses/${prescriptionId}`)}
              >
                <T keyName={'viewPrescription.releaseButtonText'}>Elibereaza </T>{' '}
                <Edit width={16} height={16} className="fill-[#FFFFFF]" />
              </button>
            </CorButton>
          ) : null}
        </div>
      </div>
      {confirmSign && (
        <ConfirmModal
          title={t('viewPrescription.confirmSignModalTitle', 'Atentie')}
          description={t(
            'viewPrescription.confirmSignDescription',
            'Ești sigur că semnezi rețeta?',
          )}
          onClose={closeCheckSign}
          onAccept={signPrescription}
        />
      )}
    </div>
  );
};

export default ViewPrescription;
