import { T } from '@tolgee/react';
import { CorChips, CorText } from '@e-reteta/react-design-system';
import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import hash from 'object-hash';

import { ChipSize, ChipVariant, TextVariant } from '../../../enums/design-system.enum';
import { Annex12FormikProps } from './annex12.interface';

const columnLabels = {
  producerName: 'Producător',
  pharmacyName: 'Denumire farmacie',
  pharmacyAddress: 'Filiala farmacie (adresa)',
  internationalName: 'DCI/Denumirea Dispozitivului Medical',
  commercialCode: 'Cod DC',
  commercialName: 'DC',
};
const Annex12 = () => {
  const formik = useFormikContext<Annex12FormikProps>();

  const selectLabel = (label: string) => {
    const values = formik?.values?.includedColumns || [];
    if (values?.includes(label)) {
      const filteredValues = values?.filter((name) => name !== label);
      formik.setFieldValue('includedColumns', filteredValues);
    } else {
      values?.push(label);
      formik.setFieldValue('includedColumns', values);
    }
  };

  useEffect(() => {
    formik.setFieldValue('includedColumns', Object.keys(columnLabels));
  }, []);

  return (
    <div className="mb-4">
      <CorText variant={TextVariant.LABEL_1_MEDIUM} className="xs:px-4 mb-4">
        <div className="text-content-default">
          <T keyName={'annex12.includedColumns'}>Coloana include in raport</T>
        </div>
      </CorText>
      <div className="flex gap-2 px-4">
        {Object.keys(columnLabels).map((key) => (
          <div onClick={() => selectLabel(key)}>
            <CorChips
              key={hash(key)}
              variant={ChipVariant.OUTLINE}
              isSelected={formik?.values?.includedColumns?.includes(key)}
              size={ChipSize.MD}
            >
              {Object.values(columnLabels)[Object.keys(columnLabels).indexOf(key)]}
            </CorChips>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Annex12;
