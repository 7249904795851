import { FC, memo, useCallback, useEffect, useRef, useState } from 'react';
import { CorDropdown, CorInput, CorText } from '@e-reteta/react-design-system';
import { T, useTranslate } from '@tolgee/react';
import dayjs from 'dayjs';

import type { DeviceFieldsProps } from './interface';
import { TextVariant } from '../../enums/design-system.enum';
import Alert from '../Alert';
import { AlertStatus } from '../Alert/enum';
import CorDropdownOption from '../CorDropdownOption';
import { DeviceDto, DeviceService } from '../../services/device.service';
import { DropdownEvent } from '../../types/CommonTypes';
import disableScrollOnInput from '../../utils/disableScrollOnInput';
import { RestrictionsEnum } from '../../enums/restrictions.enum';
import { useParams } from 'react-router-dom';
import InputWithAmount from '../InputWithAmount';
import { notIntegerSymbols } from '../../constants/not-integer';

const DeviceFields: FC<DeviceFieldsProps> = ({
  count,
  devices,
  setDisabled,
  disabled,
  formik,
  selectedDevices,
  restriction,
  setRestriction,
}) => {
  const [deviceNotAllowed] = useState(false);
  const { idnp = '', action } = useParams();

  const { t } = useTranslate();

  const inputRefs = useRef<(HTMLInputElement | null)[]>(Array(2).fill(null));
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const [threeMonthError, setThreeMonthError] = useState<number | null>(null);
  const [warningMessage, setWarningMessage] = useState<string>();

  const exceptThisSymbols = ['e', 'E', '+', '-', '.', ','];

  const checkRestrictionsAndWarnings = async (internationalName: string): Promise<boolean> => {
    setDisabled && setDisabled(true);
    const resp = await DeviceService.checkAvailable({ idnp, internationalName });

    if (!resp?.data?.isAvailable) {
      setRestriction({ type: RestrictionsEnum.RESTRICTION, details: resp?.data });
      setWarningMessage(undefined);
      return true;
    }

    setRestriction({ type: RestrictionsEnum.NONE });

    const device: DeviceDto | undefined = devices?.find(
      (device: DeviceDto) => internationalName === device?.name?.toString(),
    );
    if (device?.warningMessage) {
      setWarningMessage(device?.warningMessage.replaceAll('\\n', ''));
    } else {
      setWarningMessage(undefined);
    }

    return false;
  };

  useEffect(() => {
    setDisabled && setDisabled(true);
    if (!formik.values.dPrescriptions[count].deviceName) {
      return;
    }
    if (
      action !== 'clone' ||
      (action === 'clone' && formik.values.dPrescriptions[count].deviceName === '')
    ) {
      return;
    }
    const internationalName = formik.values.dPrescriptions[count].deviceName;
    checkRestrictionsAndWarnings(internationalName).then((hasRestrictions) => {
      if (hasRestrictions) {
        formik.setFieldValue(`dPrescriptions[${count}].quantity`, '');
        formik.setFieldValue(`dPrescriptions[${count}].validity`, '');
        formik.setFieldValue(`dPrescriptions[${count}].signature`, '');
      }
    });
  }, []);

  const onSelectDropdown = (e: DropdownEvent) => {
    const deviceName = e?.detail?.value;
    const expiration = devices?.find((device) => device.name === deviceName)?.expiration;
    formik.setFieldValue(`dPrescriptions[${count}].deviceName`, deviceName);
    formik.setFieldValue(`dPrescriptions[${count}].expiration`, expiration);
    formik.setFieldValue(`dPrescriptions[${count}].quantity`, '');
    formik.setFieldValue(`dPrescriptions[${count}].validity`, '');
    formik.setFieldValue(`dPrescriptions[${count}].signature`, '');
    if (!deviceName) {
      return;
    }
    checkRestrictionsAndWarnings(deviceName);
  };

  useEffect(() => {
    setThreeMonthError(null);
    if (!setDisabled) {
      return;
    }
    if (!formik?.values?.dPrescriptions[count]?.quantity) {
      return;
    }
    const hasRestrictions = has3MonthNormRestrictions(
      formik?.values?.dPrescriptions[count]?.quantity,
    );
    if (hasRestrictions) {
      setDisabled(true);
      return;
    }
    if (!formik?.values?.dPrescriptions[count]?.validity) {
      return;
    }
    const hasTreatmentRestrictions = hasTreatmentDurationRestrictions();
    if (hasTreatmentRestrictions) {
      setDisabled(true);
      return;
    }

    setDisabled(false);
  }, [
    formik?.values?.dPrescriptions[count]?.validity,
    formik?.values?.dPrescriptions[count]?.quantity,
  ]);

  const has3MonthNormRestrictions = (value: string) => {
    if (!selectedDevices?.length) {
      return false;
    }
    const checkDevice = devices?.find(
      (device: DeviceDto) => device.name === selectedDevices[selectedDevices.length - 1],
    );
    if (Number(value) > checkDevice?.threeMonthPrescriptionNorm!) {
      setThreeMonthError(checkDevice?.threeMonthPrescriptionNorm!);
      return true;
    }
    setThreeMonthError(null);
    return false;
  };

  const getNames = () => {
    const deviceNames = devices?.map((device) => device.name);
    const seen = new Set<string | undefined>();

    if (deviceNames?.length) {
      for (const item of deviceNames) {
        if (!seen.has(item)) {
          seen.add(item);
        }
      }

      return Array.from(seen);
    }
  };

  const checkQuantity = () => {
    if (typeof formik?.values?.dPrescriptions[count]?.quantity == 'string') {
      return false;
    }
    const quantity = Number(formik?.values?.dPrescriptions[count]?.quantity);
    return quantity < 1;
  };

  const renderAlertNotCompensated = () => {
    if (devices && devices?.length > 0) {
      return;
    }
    return (
      <Alert
        status={AlertStatus.WARNING}
        label={t(
          'deviceFields.noCompensatedDrugsForSelecting',
          'Nu sunt dispozitive medicale compensate pentru diagnostic selectat!',
        )}
        className="col-span-7 col-start-6"
      />
    );
  };

  const getTreatmentDuration = useCallback(
    (name: string) => {
      return devices?.find((device) => device.name === name)?.treatmentDuration || 90;
    },
    [devices],
  );

  const hasTreatmentDurationRestrictions = () => {
    if (typeof formik?.values?.dPrescriptions[count]?.validity === 'string') {
      return false;
    }
    const validity = Number(formik?.values?.dPrescriptions[count]?.validity);
    const maxDuration = Number(
      getTreatmentDuration(formik?.values?.dPrescriptions[count]?.deviceName),
    );

    return validity < 1 || validity > maxDuration;
  };

  const formatDaysText =
    getTreatmentDuration(formik?.values?.dPrescriptions[count]?.deviceName) >= 20
      ? t('deviceFields.daysAbove20', 'de zile')
      : t('deviceFields.days', 'zile');

  const treatmentDurationErrorMessage =
    formik?.values?.dPrescriptions[count].validity <= 0
      ? t(
          'deviceFields.defaultTreatmentDurationErrorMessage',
          'Durata tratamentului nu poate fi o valoare negativa sau 0',
        )
      : `${t(
          'deviceFields.customTreatmentDurationErrorMessage',
          'Durata tratamentului nu poate depasi',
        )} ${getTreatmentDuration(
          formik?.values?.dPrescriptions[count]?.deviceName,
        )} ${formatDaysText}`;

  return (
    <>
      {formik?.values?.dPrescriptions[count]?.type ? (
        <div className="flex flex-col sm:grid sm:grid-cols-12 gap-5">
          <CorText variant={TextVariant.LABEL_2_REGULAR} className="col-span-5">
            <span className="text-content-muted">
              <T keyName={'deviceFields.deviceName'}>Denumirea dispozitivului</T>
            </span>
          </CorText>
          <div className="col-span-7">
            <CorDropdown
              placeholder={t('deviceFields.selectDevicePlaceholder', 'Selectati dispozitivul')}
              searchEnabled={true}
              searchPlaceholder={t('deviceFields.searchDevicePlaceholder', 'Cauta dispozitivul')}
              hasSelectedValue={!!formik?.values?.dPrescriptions[count]?.deviceName}
              callback={(e) => onSelectDropdown(e)}
              disabled={!devices?.length || disabled}
            >
              {getNames()?.length
                ? getNames()?.map((name) => {
                    return (
                      <CorDropdownOption
                        disabled={selectedDevices?.includes(name!)}
                        key={name}
                        value={name!}
                        label={name!}
                        selected={name === formik?.values?.dPrescriptions[count]?.deviceName}
                      />
                    );
                  })
                : null}
            </CorDropdown>
            {formik?.values?.dPrescriptions[count]?.deviceName && warningMessage ? (
              <Alert className="mt-1" text={warningMessage} status={AlertStatus.WARNING} />
            ) : null}
          </div>
          <div className="col-span-12">
            {formik?.values?.dPrescriptions[count]?.deviceName &&
            restriction.type !== RestrictionsEnum.NONE ? (
              <Alert
                className="mt-1"
                label={<T keyName={'deviceFields.restrictionAlertLabel'}>Restrictionare</T>}
                text={
                  <T
                    keyName={'deviceFields.warningMessageRestriction'}
                    params={{
                      blockedUntil: dayjs(restriction.details?.blockedUntil).format(
                        'DD/MM/YYYY hh:mm',
                      ),
                      prescriptionId: restriction.details?.prescriptionId || '',
                    }}
                  >
                    Dispozitivul selectat a fost deja prescrisă în ultimele 80 de zile
                  </T>
                }
                status={AlertStatus.DANGER}
              />
            ) : null}
          </div>
          {renderAlertNotCompensated()}
          {deviceNotAllowed && (
            <Alert
              text={t(
                'deviceFields.alertDeviceNotCompatibleWithAge',
                'Dispozitivul nu este compatibil cu virsta pacientului',
              )}
              className="col-span-7"
              status={AlertStatus.WARNING}
            />
          )}
        </div>
      ) : null}

      {formik?.values?.dPrescriptions[count]?.deviceName &&
      restriction.type === RestrictionsEnum.NONE ? (
        <div className="flex flex-col sm:grid sm:grid-cols-12 gap-1">
          <CorText variant={TextVariant.LABEL_2_REGULAR} className="col-span-5">
            <span className="text-content-muted">
              <T keyName={'deviceFields.quantityText'}>Cantitatea</T>
            </span>
          </CorText>
          <div className="col-span-7">
            <CorInput error={checkQuantity()}>
              <input
                type="number"
                name={`dPrescriptions.[${count}].quantity`}
                placeholder={t('deviceFields.quantityPlaceholder', 'Introduceti cantitatea')}
                value={formik?.values?.dPrescriptions[count]?.quantity}
                onChange={formik.handleChange}
                ref={(el) => (inputRefs.current[1] = el)}
                onWheel={(e) => disableScrollOnInput(e)}
                className={threeMonthError !== null ? 'border-border-critical-default' : ''}
                onKeyDown={(e) => notIntegerSymbols.includes(e.key) && e.preventDefault()}
              />
              {checkQuantity() ? (
                <span className="bottom-helper">
                  <T keyName={'deviceFields.quantityErrorMessage'}>
                    Cantitatea nu poate fi mai mică de 1
                  </T>
                </span>
              ) : null}
            </CorInput>
            {threeMonthError !== null ? (
              <Alert
                text={
                  <T
                    keyName={'deviceFields.threeMonthErrorWarningMessage'}
                    params={{ threeMonthError: threeMonthError }}
                  >
                    Ați depășit limita de prescriere pentru 3 luni
                  </T>
                }
                status={AlertStatus.DANGER}
                label={t('deviceFields.threeMonthErrorWarningLabel', 'Atentie!')}
                className="mt-1"
              />
            ) : null}
          </div>
        </div>
      ) : null}
      {formik?.values?.dPrescriptions[count]?.quantity && threeMonthError === null ? (
        <div className="flex flex-col sm:grid sm:grid-cols-12 gap-1 relative">
          <CorText variant={TextVariant.LABEL_2_REGULAR} className="col-span-5">
            <span className="text-content-muted">
              <T keyName={'deviceFields.treatmentDuration'}>Durata tratamentului</T>
            </span>
          </CorText>
          <div className="col-span-7">
            <InputWithAmount
              type="number"
              placeholder={t(
                'deviceFields.treatmentDurationPlaceholder',
                'Introduce durata tratamentului',
              )}
              name={`dPrescriptions.[${count}].validity`}
              value={formik?.values?.dPrescriptions[count].validity}
              isError={hasTreatmentDurationRestrictions()}
              onChange={formik.handleChange}
              error={treatmentDurationErrorMessage}
              currency={t('deviceFields.inputCurrency', 'Zile')}
              onWheel={(e) => disableScrollOnInput(e)}
              onKeyDown={(e) => exceptThisSymbols.includes(e.key) && e.preventDefault()}
            />
          </div>
        </div>
      ) : null}
      {formik?.values?.dPrescriptions[count]?.validity ? (
        <div className="flex flex-col sm:grid sm:grid-cols-12 gap-1 relative">
          <CorText variant={TextVariant.LABEL_2_REGULAR} className="col-span-5">
            <span className="text-content-muted">
              <T keyName={'deviceFields.insertSignature'}>Insereaza signatura</T>
            </span>
          </CorText>
          <CorInput
            className="col-span-7"
            error={formik?.values?.dPrescriptions[count]?.signature?.length > 50}
          >
            <textarea
              name={`dPrescriptions.[${count}].signature`}
              placeholder={t(
                'deviceFields.signaturePlaceholder',
                'Precizati modul de utilizare a dispozitivului prescris',
              )}
              value={formik?.values?.dPrescriptions[count]?.signature}
              onChange={formik.handleChange}
              ref={textareaRef}
              rows={4}
            />
          </CorInput>
          <CorText variant={TextVariant.LABEL_3_MEDIUM} className="col-span-5">
            <div className="absolute bottom-2 right-2 text-content-muted">
              {formik?.values?.dPrescriptions[count]?.signature?.length}/50
            </div>
          </CorText>
        </div>
      ) : null}
    </>
  );
};

export default memo(DeviceFields);
