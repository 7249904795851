import { useTranslate } from '@tolgee/react';
import { Outlet, Route } from 'react-router-dom';
import React, { FC, useContext } from 'react';

import DoctorGuardedRoute from '../Guards/DoctorGuardedRoute';
import { DoctorSearch, Prescription, ViewPrescription } from '../../pages';
import DoctorHandwrite from '../../pages/DoctorHandwrite';
import Patient from '../../pages/Patient';
import LeftSideManu from '../../components/LeftSideMenu';
import { MenuItemInterface } from './menuItem.interface';
import { IconName } from '../../enums/design-system-icons.enum';
import DoctorReports from '../../pages/Reports/DoctorReports';
import { AuthContext } from '../../context/context';
import { ClinicRolesEnum } from '../../constants/roles.enum';
import DoctorInstitutionalReports from '../../pages/Reports/DoctorInstitutionalReports';
import { handwrittenLocations, searchPatientLocations } from '../../constants/locations';

const DoctorWrapper: FC = () => {
  const { t } = useTranslate();
  const { hasRole, user } = useContext(AuthContext);

  const items: MenuItemInterface[] = [];

  if (
    user?.activeCompany?.speciality !== ClinicRolesEnum.IMS_HEAD &&
    user?.activeCompany?.speciality !== ClinicRolesEnum.IMS_BRANCH
  ) {
    items.push(
      {
        path: '/doctor/search',
        name: t('doctorWrapper.search', 'Caută Pacient'),
        iconName: IconName.SEARCH,
        additionalPaths: searchPatientLocations,
      },
      {
        path: '/doctor/handwrite-prescription',
        name: t('doctorWrapper.handwritten', 'Retete Olografe'),
        iconName: IconName.RECEIPT,
        hasBadge: true,
        additionalPaths: handwrittenLocations,
      },
    );
  }

  if (hasRole(ClinicRolesEnum.IMS_HEAD) || hasRole(ClinicRolesEnum.IMS_BRANCH)) {
    items.push({
      path: '/clinic/institutional-reports',
      name: t('doctorWrapper.reports', 'Rapoarte'),
      iconName: IconName.TIME_SCHEDULE,
    });
  } else {
    items.push({
      path: '/clinic/reports',
      name: t('doctorWrapper.myReports', 'Rapoartele mele'),
      iconName: IconName.TIME_SCHEDULE,
    });
  }

  return (
    <DoctorGuardedRoute>
      <LeftSideManu items={items}>
        <Outlet />
      </LeftSideManu>
    </DoctorGuardedRoute>
  );
};
export const DoctorRoutes = (
  <Route path="" element={<DoctorWrapper />}>
    <Route path="/patient/:idnp/prescription/:prescriptionId/sign" element={<ViewPrescription />} />

    <Route path="/doctor/search" element={<DoctorSearch />} />
    <Route path="/patient/:idnp/prescription/:prescriptionId" element={<ViewPrescription />} />
    <Route path="/patient/:idnp/prescription/new" element={<Prescription />} />
    <Route path="/patient/:idnp/prescription/:prescriptionId/:action" element={<Prescription />} />
    <Route path="/doctor/handwrite-prescription" element={<DoctorHandwrite />} />
    <Route path="/patient/:idnp" element={<Patient />} />
    <Route path="/clinic/reports" element={<DoctorReports />} />
    <Route path="/clinic/institutional-reports" element={<DoctorInstitutionalReports />} />
  </Route>
);
