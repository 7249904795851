import React, { useContext, useEffect, useState } from 'react';
import { CorBox, CorButton, CorInput, CorText } from '@e-reteta/react-design-system';
import { T, useTranslate } from '@tolgee/react';

import { ButtonVariant, TextVariant } from '../../enums/design-system.enum';
import { AuthContext } from '../../context/context';
import {
  PharmaciesSettingsServices,
  PharmacySettingsDto,
} from '../../services/pharmacy-settings.services';
import EditIntegrationModal from './EditIntegrationModal';
import { Alert } from '../../components';
import { AlertStatus } from '../../components/Alert/enum';
import disableScrollOnInput from '../../utils/disableScrollOnInput';
import { LoaderContext } from '../../context/loader/context';
import { SettingsStatusEnum } from '../../enums/settings-status.enum';

const PharmacyIntegration = () => {
  const { t } = useTranslate();
  const { user } = useContext(AuthContext);
  const { setLoading } = useContext(LoaderContext);
  const [integration, setIntegration] = useState<PharmacySettingsDto>();
  const [displayModal, setDisplayModal] = useState<boolean>(false);
  const [displayGeneratedCode, setDisplayGeneratedCode] = useState<boolean>(false);
  const [generatedCode, setGeneratedCode] = useState<boolean>(false);
  const [code, setCode] = useState<string>();

  const loadIntegration = async () => {
    if (user?.activeCompany?.idno) {
      try {
        const rs = await PharmaciesSettingsServices.getByIDNOForPharmacy();
        if (rs) {
          setIntegration({
            ...rs.data,
            integration: rs.data?.integrationStatus === SettingsStatusEnum.ACTIVE,
          });
        }
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (!displayModal) {
      setLoading(true);
      loadIntegration();
    }
  }, [displayModal]);

  const getNewGeneratedCode = async () => {
    if (integration?.idno) {
      const resp = await PharmaciesSettingsServices.generateCode();
      if (resp?.data?.secretToken) {
        setCode(resp?.data?.secretToken);
      }
    }
  };

  useEffect(() => {
    if (generatedCode) {
      getNewGeneratedCode();
      setDisplayGeneratedCode(true);
    }
  }, [generatedCode]);

  const renderGeneratedCode = () => {
    if (!displayGeneratedCode) {
      return null;
    }
    return (
      <>
        <Alert
          label={t('pharmacyIntegrations.warning', 'Atenție!')}
          className="mt-1"
          text={t(
            'pharmacyIntegrations.alertText',
            'Salvați acest cod acum, el nu va mai fi vizibil la reîncărcarea paginii',
          )}
          status={AlertStatus.WARNING}
        />
        <CorInput>
          <input
            autoComplete="off"
            type="text"
            value={code}
            disabled={true}
            onWheel={(e) => disableScrollOnInput(e)}
          />
        </CorInput>
      </>
    );
  };

  return (
    <div className="flex flex-col flex-1 overflow-auto ml-6">
      <div className="flex justify-between items-center gap-4">
        <CorText variant={TextVariant.HEADING_2_BOLD}>
          <h2>
            <T keyName={'pharmacyIntegrations.integrations'}>Integrari</T>
          </h2>
        </CorText>
      </div>
      <CorBox className="mt-4 overflow-auto flex  flex-col gap-6 p-6 bg-white">
        {!integration ? (
          <Alert
            className="mt-1"
            text={t(
              'pharmacyIntegration.noIntegration',
              `Nu aveti o integratie setata, contactati administratorul de system pentru a initita. Comunicati codul ${user?.activeCompany?.idno}`,
              { idno: user?.activeCompany?.idno },
            )}
            status={AlertStatus.WARNING}
          />
        ) : (
          <>
            {!integration.isActive ? (
              <Alert
                className="mt-1"
                text={t(
                  'pharmacyIntegration.disabledIntegration',
                  `Integrarea este dezactivata, contactati administratorul de system pentru a activa. Comunicati codul ${user?.activeCompany?.idno}`,
                  { idno: user?.activeCompany?.idno },
                )}
                status={AlertStatus.WARNING}
              />
            ) : null}
            <div className="grid grid-cols-2 xl:grid-cols-6 2xl:grid-cols-3">
              <div>
                <CorText variant={TextVariant.LABEL_2_MEDIUM}>
                  <h2>
                    <T keyName={'pharmacyIntegrations.id'}>IDNO</T>
                  </h2>
                </CorText>
                <CorText variant={TextVariant.LABEL_2_REGULAR}>
                  <h2>{integration?.idno}</h2>
                </CorText>
              </div>
            </div>
            <div>
              <CorText variant={TextVariant.LABEL_2_MEDIUM}>
                <h2>
                  <T keyName={'pharmacyIntegrations.pullProductsURL'}>
                    Link extragere medicamente/dispozitive
                  </T>
                </h2>
              </CorText>
              <CorText variant={TextVariant.LABEL_2_REGULAR}>
                <h2>{integration?.pullProductsURL}</h2>
              </CorText>
            </div>

            <div>
              <CorText variant={TextVariant.LABEL_2_MEDIUM}>
                <h2>
                  <T keyName={'pharmacyIntegrations.pushDispensesURL'}>
                    Link transmitere eliberări
                  </T>
                </h2>
              </CorText>
              <CorText variant={TextVariant.LABEL_2_REGULAR}>
                <h2>{integration?.pushDispensesURL}</h2>
              </CorText>
            </div>
            <div>
              <CorText variant={TextVariant.LABEL_2_MEDIUM}>
                <h2>
                  <T keyName={'pharmacyIntegrations.connectionStatusText'}>Statutul Conexiunii</T>
                </h2>
              </CorText>
              <CorText variant={TextVariant.LABEL_2_REGULAR}>
                <h2>
                  <T keyName={`pharmacyIntegrationsStatus.${integration?.integrationStatus}`}>
                    {integration?.integrationStatus}
                  </T>
                </h2>
              </CorText>
            </div>
            <div className="flex gap-2 mt-10">
              <div className="flex justify-end">
                <CorButton variant={ButtonVariant.SECONDARY} onClick={() => setDisplayModal(true)}>
                  <button type="button" disabled={!integration?.isActive}>
                    <T keyName={'pharmacyIntegrations.modify'}>Modifica</T>
                  </button>
                </CorButton>
              </div>

              <div className="flex justify-end">
                <CorButton variant={ButtonVariant.PRIMARY} onClick={() => setGeneratedCode(true)}>
                  <button type="submit" disabled={!integration?.isActive}>
                    <T keyName={'pharmacyIntegrations.generateCod'}>Generează Cod</T>
                  </button>
                </CorButton>
              </div>
            </div>
          </>
        )}

        {renderGeneratedCode()}
      </CorBox>
      {displayModal ? (
        <EditIntegrationModal integration={integration} setDisplayModal={setDisplayModal} />
      ) : null}
    </div>
  );
};

export default PharmacyIntegration;
