/* eslint-disable react-hooks/exhaustive-deps */
import { CorBadge, CorButton, CorIcon, CorText } from '@e-reteta/react-design-system';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { T, useTranslate } from '@tolgee/react';
import { AxiosError } from 'axios';

import { IconName, IconSize } from '../../enums/design-system-icons.enum';
import { BadgeSize, ButtonVariant, TextVariant } from '../../enums/design-system.enum';
import { Alert, PatientMedicCard } from '../../components';
import { AlertStatus } from '../../components/Alert/enum';
import { PrescriptionServices } from '../../services/prescription.services';
import { DiagnosticServices } from '../../services/diagnostics.services';
import { Edit } from '../../icons';
import { getPrescriptionStatusColor } from '../../lib/getPrescriptionStatusColor';
import { ReceiptStatus } from '../../enums/ReceiptStatus';
import {
  getPrescriptionStatusTranslation,
  getPrescriptionStatusTranslationKey,
} from '../../lib/getPrescriptionStatusTranslation';
import useWindowWidth from '../../hooks/use-window-width';
import { SignServices } from '../../services/doctorsSign.services';
import ConfirmModal from '../../components/ConfirmModal';
import useQuery from '../../hooks/use-query';
import { PharmacyService, PrescriptionRelease } from '../../services/pharmacy.services';
import { Doctor } from '../../types/Doctor';
import { AuthContext } from '../../context/context';
import { renderResponsiveClasses } from '../../utils/render-responsive-classes.utils';
import PharmacistViewPrescription from '../../components/ContainerViewPrescription/Pharmacist';
import { PatientData } from '../../components/PatientMedicCard/interface';
import { checkExpiredProduct } from '../../utils/checkExpiredProduct.util';
import { ViewPrescriptionPharmacistProps } from './view-prescription-pharmacist.interface';
import PrescriptionPrint from '../../components/Prints/PrescriptionPrint';
import { LoaderContext } from '../../context/loader/context';
import { TargetUrlEnum } from '../../enums/target-url.enum';

const ViewPrescriptionPharmacist = ({ dispense }: ViewPrescriptionPharmacistProps) => {
  const { idnp, prescriptionId } = useParams();
  let query = useQuery();
  const navigate = useNavigate();
  const location = useLocation();
  const { isTablet, isMobile } = useWindowWidth();
  const { user, role } = useContext(AuthContext);
  const { setLoading } = useContext(LoaderContext);

  const [isSigned, setIsSigned] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [disease, setDisease] = useState<any>();
  const [prescription, setPrescription] = useState<any>();
  const [, setDisabled] = useState<boolean>(true);
  const [confirmSign, setConfirmSign] = useState<boolean>(false);
  const [errorMessage, setError] = useState<string>();
  const [releases, setReleases] = useState<any[]>([]);
  const [dispenses, setDispenses] = useState<any[]>([]);

  const { t } = useTranslate();

  const onGoBack = () => {
    if (location?.pathname?.includes('prescription') && location?.pathname?.includes('sign')) {
      navigate(`/unsigned-dispenses`);
    } else if (location?.pathname?.includes('dispenses')) {
      localStorage.setItem('prescriptionReleases', JSON.stringify([]));
      navigate(`/patient/${idnp}/prescription/${prescriptionId}`);
    } else {
      navigate(`/patient/${idnp}`);
    }
  };

  const getDisease = useCallback(
    async (code: string) => {
      if (role) {
        const disease = await DiagnosticServices.getDiagnostics(role, {
          code,
        });
        setDisease(disease?.data?.items?.at(0));
      }
    },
    [role],
  );

  const getPrescription = useCallback(async () => {
    if (prescriptionId && role) {
      try {
        let filter = {};
        if (role === 'pharmacy') {
          const queryStatuses = [
            ReceiptStatus.SIGNED,
            ReceiptStatus.RELEASED,
            ReceiptStatus.PARTIAL_RELEASED,
            ReceiptStatus.SIGNED_BY_PHARMACIST,
          ];
          if (query && query.get('sign-status') === 'Error') {
            queryStatuses.push(ReceiptStatus.PRESCRIBED);
          }
          filter = {
            statuses: queryStatuses,
          };
        }
        const { data } = await PrescriptionServices.getPrescriptionById(
          prescriptionId,
          role,
          filter,
        );
        setPrescription({ data });
        await getDisease(data.diagnosticCode);
        getDispenses();
      } catch (e) {
        navigate(`/patient/${idnp}`);
      }
    }
  }, [prescriptionId, role, getDisease, query, navigate, idnp]);

  const getDispenses = useCallback(async () => {
    if (!role || !prescriptionId) {
      return;
    }
    const { data }: any = await PharmacyService.getDispensesByPrescriptionId(role, prescriptionId);
    let prescriptionReleases: any[] = [];
    data?.items?.forEach((d: any) => {
      d.prescriptionReleases?.forEach((pr: any) => prescriptionReleases.push(pr));
    });
    if (data?.items?.length) {
      setDispenses(data?.items);
    }

    if (prescriptionReleases.length) {
      setReleases(prescriptionReleases);
    }
  }, [prescriptionId, role]);

  useEffect(() => {
    setLoading(true);
    if (prescription && disease) {
      setLoading(false);
    }
  }, [disease, prescription]);

  const checkSignPrescription = () => {
    setConfirmSign(true);
  };

  const closeCheckSign = () => {
    setError(undefined);
    setConfirmSign(false);
  };

  const signPrescription = async () => {
    setConfirmSign(false);
    setLoading(true);
    try {
      if (
        location.pathname.includes('prescription') &&
        location.pathname.includes('sign-dispense')
      ) {
        const dispensesToSign = dispenses
          .filter(
            (dispense) =>
              dispense.addedBy === user?.idnp &&
              dispense.status === 'not_signed' &&
              dispense.institutionCode === user?.activeCompany?.code,
          )
          .map((dispense) => dispense.id);
        const { data } = await PharmacyService.signDispenses({
          items: dispensesToSign,
          targetUrl: TargetUrlEnum.PRESCRIPTION_URL,
        });
        if (data?.redirectUrl) {
          window.location.assign(data?.redirectUrl);
        }
      } else {
        if (prescriptionId) {
          const { data } = await SignServices.sign(
            { prescriptionId: prescriptionId },
            role ? role : '',
          );
          if (data?.redirectUrl) {
            window.location.assign(data?.redirectUrl);
          }
        }
      }
    } catch (error) {
      if (error instanceof Error || error instanceof AxiosError) {
        setError(error && error?.message);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (query && query.get('sign-status') === 'Success') {
      setIsSigned(true);
    }
    if (query && query.get('sign-status') === 'Error') {
      setIsError(true);
    }
  }, [query]);

  const checkDisabled = useCallback(
    (dispenses?: any) => {
      let expiredDPrescriptions: number = 0;
      prescription?.data?.dPrescriptions?.forEach((dPrescription: any) => {
        if (dPrescription?.expiration) {
          const expired = checkExpiredProduct(
            dPrescription.expiration,
            prescription?.data?.prescribedAt,
          );

          if (expired) {
            expiredDPrescriptions++;
          }
        }
      });

      if (expiredDPrescriptions === prescription?.data?.dPrescriptions?.length) {
        return true;
      }

      let dispensedQuantity: Record<string, number> = {};
      if (releases?.length) {
        releases.forEach((release: PrescriptionRelease) => {
          if (!dispensedQuantity[release.dPrescriptionId]) {
            dispensedQuantity[release.dPrescriptionId] = Number(release.quantity);
            return;
          }
          dispensedQuantity[release.dPrescriptionId] += Number(release.quantity);
        });
      }
      for (let i = 0; i < prescription?.data?.dPrescriptions.length; i++) {
        const dp: any = prescription?.data?.dPrescriptions[i];
        if (!dispensedQuantity[dp.id]) {
          return false;
        }
        if (dispensedQuantity[dp.id] < dp.quantity) {
          return false;
        }
      }

      return true;
    },
    [releases, prescription],
  );

  useEffect(() => {
    localStorage.setItem('prescriptionReleases', JSON.stringify([]));
    if (user) {
      getPrescription();
    }
  }, []);

  const displaySignButton = () => {
    const dispensesToSign = dispenses.filter(
      (dispense) => dispense.addedBy === user?.idnp && dispense.status === 'not_signed',
    );
    const displayPharmacist =
      role === 'pharmacy' && dispensesToSign.length && location.pathname.includes('sign-dispense');

    return displayPharmacist ? (
      <CorButton variant={ButtonVariant.PRIMARY}>
        <button onClick={checkSignPrescription}>
          <T keyName={'viewPrescription.signButton'}>Semneaza</T>
        </button>
      </CorButton>
    ) : null;
  };

  return (
    <div className="h-full sm:pl-6 sm:p-4 lg:pl-6 lg:p-0">
      <div className="flex flex-row justify-between items-center mb-4">
        <div className="flex flex-row items-center gap-2">
          <CorIcon
            size={IconSize.MD}
            name={IconName.ARROW_LEFT}
            className="cursor-pointer"
            onClick={onGoBack}
          />
          <div className="flex flex-col sm:flex-row sm:items-center justify-start gap-x-2">
            {location?.pathname?.includes('dispenses') ? (
              <CorText
                variant={renderResponsiveClasses(
                  isMobile,
                  isTablet,
                  TextVariant.LABEL_1_REGULAR,
                  TextVariant.HEADING_3_BOLD,
                  TextVariant.HEADING_2_BOLD,
                )}
              >
                <h2>
                  <T keyName={'viewPrescription.releasePrescription'}>Eliberare reteta</T>
                </h2>
              </CorText>
            ) : (
              <>
                <CorText
                  variant={renderResponsiveClasses(
                    isMobile,
                    isTablet,
                    TextVariant.LABEL_1_REGULAR,
                    TextVariant.HEADING_3_BOLD,
                    TextVariant.HEADING_2_BOLD,
                  )}
                >
                  <h2>
                    <T keyName="viewPrescription.id">ID reteta</T>:
                  </h2>
                </CorText>
                <CorText
                  variant={renderResponsiveClasses(
                    isMobile,
                    isTablet,
                    TextVariant.LABEL_1_BOLD,
                    TextVariant.HEADING_3_BOLD,
                    TextVariant.HEADING_2_BOLD,
                  )}
                >
                  <h2>{prescription?.data?.id}</h2>
                </CorText>
              </>
            )}
          </div>
          <CorBadge
            size={BadgeSize.MD}
            color={getPrescriptionStatusColor(prescription?.data.status as ReceiptStatus)}
          >
            <span className="capitalize">
              <T keyName={getPrescriptionStatusTranslationKey(prescription?.data.status)}>
                {getPrescriptionStatusTranslation(prescription?.data.status as ReceiptStatus)}
              </T>
            </span>
          </CorBadge>
        </div>
        <>{displaySignButton()}</>
      </div>
      {isSigned ? (
        <Alert
          label={t('viewPrescriptionPharmacist.signSuccess', 'Semnarea a fost efectuata cu succes')}
          className="h-fit w-full mb-4"
          status={AlertStatus.SUCCESS}
        />
      ) : null}
      {isError ? (
        <Alert
          label={t('viewPrescriptionPharmacist.signFailed', 'Semnarea a esuat')}
          className="h-fit w-full mb-4"
          status={AlertStatus.DANGER}
        />
      ) : null}
      {errorMessage?.length && (
        <Alert text={errorMessage} className="h-fit w-full mb-4" status={AlertStatus.DANGER} />
      )}
      <PatientMedicCard
        patient={prescription?.data?.patient as PatientData}
        patientIdentifier={idnp}
        user={user}
        doctor={prescription?.data?.doctor as Doctor}
        status="prescribed"
        role={role}
      />
      {location?.pathname?.includes('dispenses') ? (
        <CorText
          className="mb-3 mt-6"
          variant={isMobile ? TextVariant.HEADING_4_BOLD : TextVariant.HEADING_3_BOLD}
        >
          <span>
            <T keyName={'viewPrescriptionPharmacist.drugText'}>Medicamente</T>/
            <T keyName={'viewPrescriptionPharmacist.deviceText'}>Dispozitive</T>
          </span>
        </CorText>
      ) : null}
      <div>
        <PharmacistViewPrescription
          onRefresh={() => {}}
          items={prescription?.data?.dPrescriptions}
          thirdPersonIDNP={prescription?.data?.thirdPerson?.idnp}
          thirdPersonName={`${prescription?.data?.thirdPerson?.lastName} ${prescription?.data?.thirdPerson?.firstName}`}
          code={prescription?.data?.diagnosticCode}
          name={disease?.name}
          role={role}
          dispenses={dispenses}
          setIsDisabled={setDisabled}
          prescribedAt={prescription?.data?.prescribedAt}
        />
      </div>
      <div className={`flex flex-row gap-4 mt-4 sm:pb-4 lg:pb-0 justify-end`}>
        <div className="flex gap-2 sm:gap-4 w-full sm:w-fit">
          {prescription?.status !== ReceiptStatus.PRESCRIBED ? (
            <PrescriptionPrint prescription={prescription?.data} buttonForPrint={'view'} />
          ) : null}
          {dispense ? (
            <CorButton className="flex-1 sm:flex-auto">
              <button
                type="button"
                disabled={checkDisabled()}
                className="w-full"
                onClick={() => navigate(`/patient/${idnp}/dispenses/${prescriptionId}`)}
              >
                <T keyName={'viewPrescriptionPharmacist.releaseButtonText'}>Elibereaza</T>
                <Edit width={16} height={16} className="fill-[#FFFFFF]" />
              </button>
            </CorButton>
          ) : null}
        </div>
      </div>
      {confirmSign && (
        <ConfirmModal
          title={t('viewPrescriptionPharmacist.warningCancelModalText', 'Atentie')}
          description={t(
            'viewPrescriptionPharmacist.confirmSignDescription',
            'Ești sigur că semnezi rețeta?',
          )}
          onClose={closeCheckSign}
          onAccept={signPrescription}
        />
      )}
    </div>
  );
};

export default ViewPrescriptionPharmacist;
