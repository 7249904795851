import { SVGProps } from 'react';

const File = (props: SVGProps<any>) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g opacity="0.3">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.88063 1.66667H11.3551C11.7098 1.66667 12.053 1.79236 12.3238 2.02141L16.135 5.24506C16.472 5.53006 16.6663 5.94899 16.6663 6.39032V16.7361C16.6663 18.2282 16.6493 18.3333 15.1187 18.3333H4.88063C3.35005 18.3333 3.33301 18.2282 3.33301 16.7361V3.26389C3.33301 1.77178 3.35005 1.66667 4.88063 1.66667Z"
        />
      </g>
      <path d="M5.83333 9.16656C5.3731 9.16656 5 9.53966 5 9.9999C5 10.4601 5.3731 10.8332 5.83333 10.8332H11.6667C12.1269 10.8332 12.5 10.4601 12.5 9.9999C12.5 9.53966 12.1269 9.16656 11.6667 9.16656H5.83333Z" />
      <path d="M5.83333 12.5C5.3731 12.5 5 12.8731 5 13.3333C5 13.7936 5.3731 14.1667 5.83333 14.1667H8.33333C8.79357 14.1667 9.16667 13.7936 9.16667 13.3333C9.16667 12.8731 8.79357 12.5 8.33333 12.5H5.83333Z" />
    </svg>
  );
};

export default File;
