import React from 'react';
import { DevTools, FormatSimple, Tolgee, TolgeeProvider } from '@tolgee/react';
import { InContextTools } from '@tolgee/web/tools';
import 'react-phone-number-input/style.css';

import GlobalProvider from './context/provider';
import AppRouter from './router/Routes';
import { ToastContainer } from 'react-toastify';
import LoaderProvider from './context/loader/provider';

function App() {
  const tolgee = Tolgee()
    .use(process.env.REACT_APP_TOLGEE_API_KEY ? InContextTools() : DevTools())
    .use(FormatSimple())
    .init({
      language: localStorage.getItem('lang') || 'ro',
      availableLanguages: ['ro', 'en', 'ru'],
      fallbackLanguage: 'ro',
      apiUrl: process.env.REACT_APP_TOLGEE_API_URL,
      apiKey: process.env.REACT_APP_TOLGEE_API_KEY,
      staticData: {
        en: () => import('./i18n/en.json'),
        ro: () => import('./i18n/ro.json'),
        ru: () => import('./i18n/ru.json'),
      },
    });
  return (
    <LoaderProvider>
      <GlobalProvider>
        <TolgeeProvider
          tolgee={tolgee}
          fallback="Loading..." // loading fallback
        >
          <AppRouter />
          <ToastContainer />
        </TolgeeProvider>
      </GlobalProvider>
    </LoaderProvider>
  );
}

export default App;
