import React, { FC } from 'react';
import { CorText } from '@e-reteta/react-design-system';
import { useLocation } from 'react-router-dom';
import { T } from '@tolgee/react';

import { TextVariant } from '../../enums/design-system.enum';
import { PharmacistViewPrescriptionProps } from './interface';
import useWindowWidth from '../../hooks/use-window-width';
import { DPrescriptions } from '../../services/prescription.services';
import { ViewDevice, ViewDrug } from '../index';
import { checkExpiredProduct } from '../../utils/checkExpiredProduct.util';

const PharmacistViewPrescription: FC<PharmacistViewPrescriptionProps> = (props) => {
  const location = useLocation();
  const {
    thirdPersonName,
    thirdPersonIDNP,
    role,
    code,
    name,
    items,
    products,
    setIsDisabled,
    prescribedAt,
    onRefresh,
    dispenses,
  } = props;
  const { isMobile } = useWindowWidth();

  const displayDrug = (item: DPrescriptions, isLastItem = false) => {
    let expired;
    if (item?.expiration && prescribedAt) {
      expired = checkExpiredProduct(item?.expiration, prescribedAt);
    }
    return (
      <ViewDrug
        onRefresh={onRefresh}
        key={item.id}
        products={products || []}
        tradeName={item.drugInternationalName}
        treatmentDuration={item.validity!}
        prescribedQuantity={item.quantity}
        dosage={item.drugDose!}
        administrationRoute={item.drugRouteOfAdministration!}
        activeSubstance={item.drugInternationalName!}
        additionalInfo={item.signature!}
        hasBorderBottom={isLastItem}
        role={role}
        dPrescriptionId={item.id}
        setIsDisabled={setIsDisabled}
        diagnosticsCode={code!}
        expired={expired}
        dispenses={dispenses}
      />
    );
  };

  const displayDevice = (item: DPrescriptions, isLastItem = false) => {
    let expired;
    if (item?.expiration && prescribedAt) {
      expired = checkExpiredProduct(item?.expiration, prescribedAt);
    }
    return (
      <ViewDevice
        onRefresh={onRefresh}
        products={products || []}
        key={item.id}
        name={item.deviceName!}
        commercialName={item.commercialName!}
        prescribedQuantity={item.quantity}
        treatmentDuration={item.validity!}
        quantity={item.quantity}
        signature={item.signature!}
        additionalInfo={''}
        hasBorderBottom={isLastItem}
        dPrescriptionId={item.id}
        role={role}
        setIsDisabled={setIsDisabled}
        diagnosticsCode={code!}
        expired={expired}
        dispenses={dispenses}
      />
    );
  };

  return (
    <div className="flex flex-col gap-4 sm:gap-6 rounded-2xl bg-white py-3 px-4 sm:py-5 sm:px-6">
      {location?.pathname?.includes('prescription') ? (
        <CorText variant={isMobile ? TextVariant.LABEL_1_BOLD : TextVariant.HEADING_4_BOLD}>
          <span>
            <T keyName={'pharmacist.prescriptionDetails'}>Detalii reteta</T>
          </span>
        </CorText>
      ) : null}
      <div className="grid 2md:grid-cols-2 2md:gap-2">
        <div className="rounded-bl-none rounded-br-none rounded-2xl 2md:rounded-2xl py-3 px-4 sm:py-5 sm:px-6 flex flex-col gap-2 w-full bg-white border border-solid border-border-default">
          <CorText variant={TextVariant.LABEL_1_BOLD}>
            <span>
              <T keyName={'containerViewPrescription.diagnosticText'}>Diagnosticul</T>
            </span>
          </CorText>
          <CorText variant={TextVariant.LABEL_2_MEDIUM}>
            <span className="text-content-default">
              {code} - {name}
            </span>
          </CorText>
        </div>
        {thirdPersonIDNP && thirdPersonName ? (
          <div className="rounded-tl-none rounded-tr-none rounded-2xl 2md:rounded-2xl py-3 px-4 sm:py-5 sm:px-6 flex flex-col gap-2 w-full max-2md:border-t-0 bg-white border border-solid border-border-default">
            <CorText variant={TextVariant.LABEL_1_BOLD}>
              <span>
                <T keyName={'pharmacist.thirdPartyText'}>Persoana Terta</T>
              </span>
            </CorText>
            <div className="flex flex-row gap-10">
              <div className="flex flex-col gap-2">
                <CorText variant={TextVariant.LABEL_2_REGULAR}>
                  <span className="text-content-muted">
                    <T keyName={'pharmacist.thirdPartyID'}>IDNP</T>
                  </span>
                </CorText>
                <CorText variant={TextVariant.LABEL_2_REGULAR}>
                  <span className="text-content-muted">
                    <T keyName={'pharmacist.nameSurname'}>Nume, Prenume</T>
                  </span>
                </CorText>
              </div>
              <div className="flex flex-col gap-2">
                <CorText variant={TextVariant.LABEL_2_MEDIUM}>
                  <span className="text-content-default">{thirdPersonIDNP}</span>
                </CorText>
                <div className="flex flex-col gap-1">
                  <CorText variant={TextVariant.LABEL_2_MEDIUM}>
                    <span className="text-content-default">{thirdPersonName}</span>
                  </CorText>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
      {items?.map((item) => {
        if (item.deviceName) {
          return displayDevice(item);
        }
        return displayDrug(item);
      })}
    </div>
  );
};

export default PharmacistViewPrescription;
