import { SVGProps } from 'react';

const Heart = (props: SVGProps<any>) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.75 3.75C12.4087 3.75 10.8402 5.27053 10 6.25C9.15979 5.27053 7.59125 3.75 6.25 3.75C3.87583 3.75 2.5 5.60182 2.5 7.95867C2.5 11.875 10 16.25 10 16.25C10 16.25 17.5 11.875 17.5 8.125C17.5 5.76814 16.1242 3.75 13.75 3.75Z"
      />
    </svg>
  );
};

export default Heart;
