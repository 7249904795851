import {
  forwardRef,
  memo,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { ManagementTableContext } from './context';
import { ManagementTableProps } from './interface';
import ManagementTableMainComponent from './MainComponent';
import { PaginationData } from '../Pagination/interface';
import { DEFAULT_PAGINATION_DATA } from '../Pagination/constants';
import { CurrentTableRef } from '../../pages/ManagementTool/interface';
import { DeviceDto, DeviceService, GetDevicesResponse } from '../../services/device.service';
import { DrugService, GetDrugsResponse } from '../../services/drug.service';
import { DiagnosticServices, GetDiseaseResponse } from '../../services/diagnostics.services';
import {
  GetSpecialitiesResponse,
  SpecialityDto,
  SpecialityService,
} from '../../services/speciality.service';
import {
  GetInstitutionsResponse,
  InstitutionDto,
  InstitutionService,
} from '../../services/institution.service';
import { PharmaciesServices, PharmacyDto } from '../../services/pharmacies.services';
import { GetPharmaciesResponse } from '../../types/CommonTypes';
import { Drug } from '../../types/Drug';
import { Disease } from '../../types/Disease';
import {
  GetPharmacySettingsResponseDto,
  PharmaciesSettingsServices,
  PharmacySettingsDto,
} from '../../services/pharmacy-settings.services';
import { LoaderContext } from '../../context/loader/context';

const ManagementTable = forwardRef<CurrentTableRef, ManagementTableProps>((props, ref) => {
  type itemType =
    | DeviceDto[]
    | PharmacyDto[]
    | Drug[]
    | Disease[]
    | SpecialityDto[]
    | InstitutionDto[]
    | PharmacySettingsDto[];

  type requestType =
    | GetDevicesResponse
    | GetDrugsResponse
    | GetDiseaseResponse
    | GetSpecialitiesResponse
    | GetInstitutionsResponse
    | GetPharmaciesResponse
    | GetPharmacySettingsResponseDto;

  const { type } = useParams();
  const [items, setItems] = useState<itemType>();
  const [paginationData, setPaginationData] = useState<PaginationData>(DEFAULT_PAGINATION_DATA);
  const [overwrites, setOverwrites] = useState<Record<string, any>>({});
  const { setLoading } = useContext(LoaderContext);
  const [search, setSearch] = useState<string | undefined>();
  const [firstLoad, setFirstLoad] = useState<boolean>(true);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const limitQueryParam = queryParams.get('limit');

  const fetchItems = useCallback(
    async (localOverwrites?: Record<string, any>): Promise<void> => {
      if (localOverwrites) {
        setPaginationData(DEFAULT_PAGINATION_DATA);
      }
      localOverwrites = localOverwrites || overwrites;

      const limit = limitQueryParam ? parseInt(limitQueryParam, 10) : paginationData.itemsPerPage;
      let req: requestType = { data: { count: 0, items: [] } };

      switch (type) {
        case 'devices':
          req = await DeviceService.getAll('admin', {
            skip: paginationData.page * limit,
            limit: limit,
            orderBy: { name: 'ASC' },
            search: search,
            ...localOverwrites,
          });
          break;
        case 'drugs':
          req = await DrugService.getAll('admin', {
            skip: paginationData.page * limit,
            limit: limit,
            orderBy: { internationalName: 'ASC' },
            search: search,
            ...localOverwrites,
          });
          break;
        case 'diagnostics':
          req = await DiagnosticServices.getDiagnostics('admin', {
            skip: paginationData.page * limit,
            limit: limit,
            orderBy: { code: 'ASC' },
            search: search,
            ...localOverwrites,
          });
          break;
        case 'specialities':
          req = await SpecialityService.getAll(
            {
              skip: paginationData.page * limit,
              limit: limit,
              orderBy: { name: 'ASC' },
              ...localOverwrites,
            },
            search,
          );
          break;
        case 'clinics':
          req = await InstitutionService.getAll(
            'admin',
            {
              skip: paginationData.page * limit,
              limit: limit,
              orderBy: { codAt: 'ASC' },
              ...localOverwrites,
            },
            search,
          );
          break;
        case 'pharmacies':
          req = await PharmaciesServices.getAll('admin', {
            skip: paginationData.page * limit,
            limit: limit,
            orderBy: { name: 'ASC' },
            search: search,
            ...localOverwrites,
          });
          break;
        case 'integrations':
          req = await PharmaciesSettingsServices.getAll({
            skip: paginationData.page * paginationData.itemsPerPage,
            limit: paginationData.itemsPerPage,
            orderBy: { idno: 'DESC' },
            search: search,
            includePharmacy: false,
            ...localOverwrites,
          });
          break;
      }

      setPaginationData((data: PaginationData) => ({
        ...data,
        totalItems: req?.data?.count,
        totalPages: Math.ceil(req?.data?.count / data?.itemsPerPage),
      }));
      setItems(req?.data?.items || []);
      setFirstLoad(false);
      if (props.onFitchData) {
        props.onFitchData(req);
      }
    },
    [
      limitQueryParam,
      type,
      paginationData.page,
      paginationData.itemsPerPage,
      search,
      overwrites,
      props.onFitchData,
    ],
  );

  useEffect(() => {
    setLoading(true);
    fetchItems();

    if (items?.length || items?.length === 0) {
      setLoading(false);
    }

    const debounce = (func: () => Promise<itemType | void>, delay: number) => {
      let timeoutId: ReturnType<typeof setTimeout>;
      return () => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
          func();
        }, delay);
      };
    };

    const debouncedResizeHandler = debounce(fetchItems, 300);
    if (type) {
      if (type === 'specialities') {
        window.addEventListener('resize', debouncedResizeHandler);
        return () => {
          window.removeEventListener('resize', debouncedResizeHandler);
        };
      }
    }
  }, [type, search, items?.length]);

  useEffect(() => {
    if (!firstLoad) {
      setSearch(props.searchQuery);
      setPaginationData({ ...paginationData, page: 0 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.searchQuery]);

  useImperativeHandle(
    ref,
    () => {
      return {
        refetchFunction(overwrites) {
          if (overwrites) {
            setOverwrites(overwrites);
          }
          fetchItems(overwrites);
        },
      };
    },
    [fetchItems],
  );
  const contextValue = useMemo(() => {
    return {
      items,
      setItems,
      paginationData,
      setPaginationData,
      fetchItems,
    };
  }, [fetchItems, paginationData, items]);

  return (
    <ManagementTableContext.Provider value={contextValue}>
      <ManagementTableMainComponent {...props} />
    </ManagementTableContext.Provider>
  );
});

export default memo(ManagementTable);
